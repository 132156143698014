import React, { useCallback, useState } from 'react';
import { Drawer, Flex } from 'antd';
import styled from 'styled-components';
import { useInvoiceStore } from 'store/invoiceStore';
import { InvoiceType } from 'services/api/data-contracts';
import { DocumentItem } from './columns';
import { HeaderButtons } from './HeaderButtons';
import { convertBytesToReadableSize } from 'pages/widgets/MessagesWidget/components/NewMessage/helpers/convertBytesToReadableSize';
import { usePaymentStore } from 'store/paymentStore';
import { DrawerContent } from './DrawerContent';
import { DocumentsErrorBoundary } from './DocumentsErrorBoundary';

const StyledDrawer = styled(Drawer)`
  .ant-drawer-header {
    padding: 12px 8px 12px 16px;
    box-shadow:
      0 6px 6px 0 #00000014,
      0 3px 6px -4px #0000001f;
    border: none;
  }
  .ant-drawer-body {
    padding: 8px;
  }
`;

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export const DocumentsDrawer = ({ isOpen, onClose }: IProps) => {
  const [selectedDocuments, setSelectedDocuments] = useState<DocumentItem[]>([]);
  const [hasError, setHasError] = useState(false);

  const handleError = useCallback(() => {
    setHasError(true);
  }, []);

  const { invoiceCounterpartyId, invoiceCounterpartyName, invoice } = useInvoiceStore(
    ({ current }) => ({
      invoice: current,
      invoiceCounterpartyId:
        current?.type === InvoiceType.Issued ? current?.payerId : current?.supplierId,
      invoiceCounterpartyName:
        current?.type === InvoiceType.Issued ? current?.payer.fullName : current?.supplier.fullName
    })
  );

  const { creditnoteCounterpartyId, creditnoteCounterpartyName } = usePaymentStore(
    ({ current }) => ({
      creditnoteCounterpartyId: current?.payerId,
      creditnoteCounterpartyName: current?.payer?.fullName
    })
  );

  const counterpartyName = invoice ? invoiceCounterpartyName : creditnoteCounterpartyName;

  const handleRowSelect = (record: DocumentItem, selected: boolean) => {
    setSelectedDocuments((prevState) =>
      selected && record.fileId
        ? [...prevState, record]
        : prevState.filter((doc) => doc.fileId !== record.fileId)
    );
  };

  const footer = hasError ? null : (
    <Flex>
      {selectedDocuments.length} {selectedDocuments.length === 1 ? 'document' : 'documents'}:{' '}
      {convertBytesToReadableSize(
        selectedDocuments.reduce((acc, f) => acc + (f?.fileSize || 0), 0)
      )}
    </Flex>
  );

  return (
    <StyledDrawer
      open={isOpen}
      onClose={onClose}
      width="100%"
      title={`Choosing file for ${counterpartyName ?? ''}`}
      closable={false}
      style={{ borderRadius: '8px' }}
      extra={
        <HeaderButtons
          onClose={onClose}
          selectedDocuments={selectedDocuments}
          counterpartyName={counterpartyName}
          hasError={hasError}
        />
      }
      footer={footer}
      getContainer={false}>
      <DocumentsErrorBoundary onError={handleError}>
        <DrawerContent
          invoice={invoice}
          invoiceCounterpartyId={invoiceCounterpartyId}
          creditnoteCounterpartyId={creditnoteCounterpartyId}
          onRowSelect={handleRowSelect}
          selectedDocuments={selectedDocuments}
        />
      </DocumentsErrorBoundary>
    </StyledDrawer>
  );
};
