import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import { DeleteOutlined, EditFilled } from '@ant-design/icons';
import { Button, Flex, Tooltip } from 'antd';
import { PaymentPurposeHydrated } from 'services/api/data-contracts';
import { getAmountValue, getUTCDate, splitCamelCase } from 'helpers';

export const getColumns = (
  onDelete: (paymentPurpose: PaymentPurposeHydrated) => void,
  onOpen: (paymentPurpose: PaymentPurposeHydrated) => void
): ColumnsType<PaymentPurposeHydrated> => [
  {
    title: 'Type',
    dataIndex: 'transactionType',
    key: 'transactionType',
    render: (_, record) => splitCamelCase(record.payment.transactionType),
    width: 100
  },
  {
    title: 'Number',
    dataIndex: 'paymentId',
    key: 'paymentId',
    render: (_, record) => record.payment.creditNoteNumber || record.payment.id
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (_, record) =>
      record.createdAt ? getUTCDate(record.createdAt).format(DATE_FORMAT) : '-',
    width: 100
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    render: (_, record) => record.payment.currency,
    width: 76
  },
  {
    title: 'Paid amount',
    dataIndex: 'paidAmount',
    key: 'paidAmount',
    align: 'right',
    render: (value) => getAmountValue(value),
    width: 180
  },
  {
    title: 'Inv currency',
    dataIndex: 'invoiceCurrency',
    key: 'invoiceCurrency',
    render: (_, record) => record.invoice.currency,
    width: 96
  },
  {
    title: 'Paid amount in invoice currency',
    dataIndex: 'paidAmountInInvoiceCurrency',
    key: 'paidAmountInInvoiceCurrency',
    align: 'right',
    width: 230,
    render: (value, record) => (
      <Flex
        align="center"
        justify="end"
        style={{
          overflow: 'hidden'
        }}>
        <Tooltip title={getAmountValue(value)}>
          <span
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            }}>
            {getAmountValue(value || record.paidAmount)}
          </span>
        </Tooltip>

        <div id="actions">
          <Tooltip title={`Unlink the ${splitCamelCase(record.payment.transactionType)}`}>
            <Button
              size={CONTROL_SIZE}
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => onDelete(record)}
            />
          </Tooltip>
          <Tooltip title="Edit linked">
            <Button
              size={CONTROL_SIZE}
              type="primary"
              shape="circle"
              data-testid="edit-btn"
              icon={<EditFilled />}
              onClick={() => onOpen(record)}
            />
          </Tooltip>
        </div>
      </Flex>
    )
  }
];
