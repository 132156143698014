import { Button, Dropdown, MenuProps } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { PlusOutlined } from '@ant-design/icons';
import React from 'react';
import { getOrderFromFlights, getNextFlight } from 'helpers';
import { useFlightsDashboardStore } from 'store/flightsDashboardStore';
import { useFlightStore } from 'store/flightStore';
import { Flight } from 'services/api/data-contracts';
import { useOrderStore } from 'store/orderStore';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { useDrawerStore } from 'store/drawerStore';

interface IProps {
  isLoading?: boolean;
}

export const CreateBasedOnButton = ({ isLoading }: IProps) => {
  const { setFlightEditMode, setOpenFlightId } = useFlightsDashboardStore(
    ({ setFlightEditMode, setOpenFlightId }) => ({
      setFlightEditMode,
      setOpenFlightId
    })
  );
  const { setDrawerOpen, setDrawerClosed } = useDrawerStore(
    ({ setDrawerOpen, setDrawerClosed }) => ({ setDrawerOpen, setDrawerClosed })
  );

  const { flight, setCurrent } = useFlightStore(({ current, setCurrent }) => ({
    flight: current as Flight,
    setCurrent
  }));
  const { setCurrent: setOrder } = useOrderStore(({ setCurrent }) => ({
    setCurrent
  }));
  const { setOrderEditMode, setOpenOrder } = useOrdersDashboardStore(
    ({ setOrderEditMode, setOpenOrder }) => ({
      setOrderEditMode,
      setOpenOrder
    })
  );

  const items: MenuProps['items'] = [
    {
      key: '1',
      type: 'group',
      label: 'Create',
      children: [
        {
          key: '1-1',
          label: 'Next flight',
          onClick: () => {
            setCurrent(getNextFlight(flight));
            setOpenFlightId(undefined);
            setDrawerOpen('flight');
            setFlightEditMode(true);
          }
        },
        {
          key: '1-2',
          label: 'Order',
          onClick: () => {
            setOpenOrder(undefined);
            setOrder(getOrderFromFlights(flight, []));
            setOrderEditMode(true);
            setDrawerClosed('flight');
            setDrawerOpen('order');
          }
        }
      ]
    }
  ];

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <Button size={CONTROL_SIZE} loading={isLoading} icon={<PlusOutlined />} />
    </Dropdown>
  );
};
