import React, { useContext, useMemo } from 'react';
import { Flight, PermitOrder } from 'services/api/data-contracts';
import { Legs } from './Legs';
import { FormInstance } from 'antd/es/form';
import { FormContext } from '../../../OrderDrawer';

interface IProps {
  legs: Flight[];
  selectedFlights: number[];
}

export const LegsForm = ({ legs, selectedFlights }: IProps) => {
  const { form: formInstance } = useContext(FormContext);
  const form = formInstance as FormInstance<PermitOrder>;

  const existingFlights = useMemo(() => legs.map(({ id }) => id) || [], [legs]);

  return (
    <Legs
      existingFlights={existingFlights}
      selectedFlights={selectedFlights}
      apiFilter={{
        operatorId: form?.getFieldValue('operatorId'),
        aircraftId: form?.getFieldValue('aircraftId')
      }}
      form={form}
    />
  );
};
