import React from 'react';
import {
  FilePdfOutlined,
  FileExcelOutlined,
  FileImageOutlined,
  FileWordOutlined,
  FilePptOutlined,
  FileZipOutlined,
  FileOutlined
} from '@ant-design/icons';

export type FileExtension =
  | 'pdf'
  | 'xls'
  | 'xlsx'
  | 'png'
  | 'jpg'
  | 'jpeg'
  | 'gif'
  | 'img'
  | 'bpm'
  | 'doc'
  | 'docx'
  | 'ppt'
  | 'pptx'
  | 'zip'
  | 'rar'
  | '7z'
  | 'tar';

const extensionGroups: Record<string, FileExtension[]> = {
  pdf: ['pdf'],
  excel: ['xls', 'xlsx'],
  image: ['png', 'jpg', 'jpeg', 'gif', 'img', 'bpm'],
  word: ['doc', 'docx'],
  powerpoint: ['ppt', 'pptx'],
  archive: ['zip', 'rar', '7z', 'tar']
};

const getFileExtension = (filename: string): string | undefined => {
  const parts = filename.split('.');
  if (parts.length > 1) {
    return parts[parts.length - 1].toLowerCase();
  }
  return undefined;
};

export const renderFileIcon = (filename: string) => {
  const defaultIcon = <FileOutlined />;

  const fileExtension = getFileExtension(filename);

  if (!fileExtension) {
    return defaultIcon;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [group] = Object.entries(extensionGroups).find(([_, extensions]) =>
    extensions.includes((fileExtension || '').toLowerCase() as FileExtension)
  ) || [null];

  if (!group) {
    return defaultIcon;
  }

  const iconMap: Record<string, JSX.Element> = {
    pdf: <FilePdfOutlined />,
    excel: <FileExcelOutlined />,
    image: <FileImageOutlined />,
    word: <FileWordOutlined />,
    powerpoint: <FilePptOutlined />,
    archive: <FileZipOutlined />
  };

  return iconMap[group] || defaultIcon;
};
