import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const formatRelativeTime = (date: string) => {
  const now = dayjs();
  const inputDate = dayjs.utc(date);

  const diffInMinutes = now.diff(inputDate, 'minute');
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInMinutes / (24 * 60));

  switch (true) {
    case diffInMinutes < 1:
      return 'less than a minute ago';
    case diffInMinutes === 1:
      return '1 minute ago';
    case diffInMinutes < 60:
      return `${diffInMinutes} minutes ago`;
    case diffInHours === 1:
      return '1 hour ago';
    case diffInHours < 24:
      return `${diffInHours} hours ago`;
    case diffInDays === 1:
      return '1 day ago';
    case diffInDays > 1:
      return `${diffInDays} days ago`;
    default:
      return 'unknown time';
  }
};
