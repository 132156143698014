import {
  Aircraft,
  Airport,
  City,
  Contract,
  Counterparty,
  Country,
  FlightPurpose,
  FlightType,
  OrderType,
  SpecialParameter,
  SpecialParameterNames
} from 'services/api/data-contracts';
import { getOrderTerm, getUTCDate } from 'helpers';
import { TypedOrder } from 'store/orderStore';

export const getEntityDisplayValue = (
  entityName: SpecialParameterNames,
  parameter: SpecialParameter
) => {
  switch (entityName) {
    case SpecialParameterNames.FuelOrder:
    case SpecialParameterNames.AdditionalServicesOrder:
    case SpecialParameterNames.GroundHandlingOrder:
    case SpecialParameterNames.PermitOrder:
      let orderType = '';

      if (entityName === SpecialParameterNames.FuelOrder) {
        orderType = OrderType.FUEL;
      } else if (entityName === SpecialParameterNames.AdditionalServicesOrder) {
        orderType = OrderType.ADD;
      } else if (entityName === SpecialParameterNames.GroundHandlingOrder) {
        orderType = OrderType.GH;
      } else if (entityName === SpecialParameterNames.PermitOrder) {
        orderType = OrderType.PMT;
      }

      const startDate = getUTCDate((parameter?.entity as TypedOrder)?.startDatetime);
      const endDate = getUTCDate((parameter?.entity as TypedOrder)?.endDatetime);

      return `${orderType} | ${getOrderTerm(startDate, endDate)}`;

    case SpecialParameterNames.Airport:
      return ['Airport', (parameter?.entity as Airport)?.code].filter((i) => !!i).join(' | ');

    case SpecialParameterNames.Aircraft:
      const aircraft = parameter?.entity as Aircraft;
      return [aircraft?.tailNumber, aircraft?.manufacturer].filter((i) => !!i).join(' | ');

    case SpecialParameterNames.FlightType:
      return (parameter?.entity as FlightType)?.name;

    case SpecialParameterNames.FlightPurpose:
      return (parameter?.entity as FlightPurpose)?.name;

    case SpecialParameterNames.City:
      return ['City', (parameter?.entity as City)?.name].filter((i) => !!i).join(' | ');

    case SpecialParameterNames.Country:
      return ['Country', (parameter?.entity as Country)?.name].filter((i) => !!i).join(' | ');

    case SpecialParameterNames.Counterparty:
    case SpecialParameterNames.Operator:
      return (parameter?.entity as Counterparty)?.fullName;

    case SpecialParameterNames.Contract:
      return (parameter?.entity as Contract)?.description;

    default:
      return entityName;
  }
};
