import { ColumnsType } from 'antd/es/table';
import { CurrencyRateWithId } from 'store/currencyRateStore';
import { DATE_FORMAT } from 'consts/common';
import { getUTCDate } from 'helpers';

export const getCurrencyRateColumns = (
  mainCurrency: string,
  allowedCurrencies: string[],
  sourceCurrency?: string
) => {
  const columns: ColumnsType<CurrencyRateWithId> = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (value) => getUTCDate(value).format(DATE_FORMAT)
    }
  ];

  const selectedCurrency = sourceCurrency || mainCurrency;

  allowedCurrencies
    .filter((curr) => curr !== selectedCurrency)
    .forEach((currency) =>
      columns.push({
        title: currency,
        dataIndex: currency,
        key: currency,
        render: (_, record) => record.exchangeRates[currency] || '-'
      })
    );

  return columns;
};
