import { ConfigProvider, Empty, Table } from 'antd';
import React, { useMemo } from 'react';
import {
  OrderService,
  OrderServiceWithInvoiceItems,
  SettlementOrder
} from 'services/api/data-contracts';
import { getColumns } from './columns';
import { CONTROL_SIZE } from 'consts/common';
import { InvoiceItemsExpandableTable } from '../InvoiceItemsExpandableTable/InvoiceItemsExpandableTable';
import { OrderRow, RowState, useSettlementDashboardStore } from 'store/settlementDashboardStore';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import styled from 'styled-components';
import { getUTCDate } from 'helpers';
import { useOrderStore } from 'store/orderStore';

const StyledTable = styled(Table<OrderServiceWithInvoiceItems>)`
  #actions {
    display: none;
    position: absolute;
    right: 0;
  }

  #actions > button {
    margin-right: 10px;
  }

  .ant-table-cell-row-hover #actions {
    display: block;
  }
`;

interface IProps {
  order: SettlementOrder;
  onOpenService: (service: OrderService) => void;
  isFullScreenMode: boolean;
}

export const OrderServicesExpandableTable = ({
  order,
  onOpenService,
  isFullScreenMode
}: IProps) => {
  const { orderRowsState, setOrderRowsState } = useSettlementDashboardStore(
    ({ orderRowsState, setOrderRowsState }) => ({
      orderRowsState,
      setOrderRowsState
    })
  );
  const { selectedServices, setSelectedServices } = useOrderStore(
    ({ selectedServices, setSelectedServices }) => ({
      selectedServices,
      setSelectedServices
    })
  );

  const rowState = useMemo(
    () => orderRowsState.find((orderRow) => orderRow.number === order.number) as OrderRow,
    [orderRowsState, order.number]
  );

  const isServiceSelected = (serviceId: number) => {
    const serviceState = rowState?.services.find((service) => service.id === serviceId);
    return serviceState?.state === RowState.SELECTED;
  };

  const handleSelectService = ({ target: { value: serviceId, checked } }: CheckboxChangeEvent) => {
    const newRowState = {
      ...rowState,
      services: rowState.services.map((service) => {
        if (service.id === serviceId) {
          return { ...service, state: checked ? RowState.SELECTED : RowState.EMPTY };
        }

        return service;
      })
    };

    if (newRowState.services.every((service) => service.state === RowState.SELECTED)) {
      newRowState.state = RowState.SELECTED;
    } else if (newRowState.services.every((service) => service.state === RowState.EMPTY)) {
      newRowState.state = RowState.EMPTY;
    } else {
      newRowState.state = RowState.INDETERMINATE;
    }

    const newRowsState = [...orderRowsState].map((row) => {
      if (row.number === order.number) {
        return newRowState;
      }

      return row;
    });

    setOrderRowsState(newRowsState);

    // Services selection for bulk actions popup
    setSelectedServices(
      checked ? [...selectedServices, serviceId] : selectedServices.filter((id) => id !== serviceId)
    );
  };

  const dataSource = order.orderServices.map(
    (service) =>
      ({
        ...service,
        key: service?.id,
        order: {
          ...order,
          createdAt: getUTCDate().format(),
          updatedAt: getUTCDate().format()
        }
      }) as OrderServiceWithInvoiceItems
  );
  const columns = getColumns(isServiceSelected, handleSelectService, onOpenService);

  return (
    <ConfigProvider
      renderEmpty={() => (
        <Empty description="Services not found" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}>
      <StyledTable
        dataSource={dataSource}
        columns={columns}
        size={CONTROL_SIZE}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => (
            <InvoiceItemsExpandableTable
              invoiceItems={record.invoiceItems}
              isFullScreenMode={isFullScreenMode}
              orderNumber={order.number}
              serviceId={record.id}
            />
          ),
          rowExpandable: (record) => record.invoiceItems?.length > 0,
          columnWidth: 32
        }}
      />
    </ConfigProvider>
  );
};
