import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Flex, Form, Input, Typography } from 'antd';
import { CounterpartyInput } from 'components/CounterpartyInput/CounterpartyInput';
import { CONTROL_SIZE, DATE_TIME_FORMAT } from 'consts/common';
import { AircraftInput } from 'components/AircraftInput/AircraftInput';
import { AirportsInput } from 'components/AirportsInput/AirportsInput';
import { Airport, Flight, FlightStatus } from 'services/api/data-contracts';
import { StyledDatePicker } from 'components/common/StyledDatePicker';
import { CountriesInput } from 'components/CountriesInput/CountriesInput';
import { AirportInput } from 'components/AirportsInput/AirportInput';
import { FormContext } from '../../FlightDrawer';
import { FlightTypeInput } from 'components/FlightTypeInput/FlightTypeInput';
import { FlightPurposeInput } from 'components/FlightPurposeInput/FlightPurposeInput';
import { useWatch } from 'antd/es/form/Form';
import { getUTCDate } from 'helpers';
import { FormInstance } from 'antd/es/form';
import { FlightWithDayJs } from '../../utils';

interface IProps {
  flight: Flight;
}

export const FlightDetailsForm = ({ flight }: IProps) => {
  const [departureAirport, setDepartureAirport] = useState<Airport | undefined>(undefined);
  const [arrivalAirport, setArrivalAirport] = useState<Airport | undefined>(undefined);
  const [operatorId, setOperatorId] = useState<number | undefined>(undefined);

  const { form: formInstance } = useContext(FormContext);
  const form = formInstance as FormInstance<FlightWithDayJs>;

  const flightStatus = useWatch('status', form);
  const ETD = useWatch('estimatedTimeDeparture', form);
  const updatedOperatorId = useWatch('operatorId', form);

  useEffect(() => {
    setDepartureAirport(flight.departureAirport);
    setArrivalAirport(flight.arrivalAirport);
    setOperatorId(flight.operatorId);
  }, [flight]);

  useEffect(() => {
    if (updatedOperatorId && updatedOperatorId !== operatorId) {
      form.resetFields(['aircraftId']);
    }
  }, [updatedOperatorId]);

  const showActualDates = useMemo(
    () => [FlightStatus.CNLD, FlightStatus.AIR, FlightStatus.DONE].includes(flightStatus),
    [flightStatus]
  );

  return (
    <div data-testid="flight-details-form">
      <Flex gap={8} justify="space-between" align="start">
        <div style={{ flexBasis: '50%' }}>
          <CounterpartyInput
            label="Operator"
            name="operatorId"
            isOperator
            required
            onChange={(counterparty) => form.setFieldValue('operatorId', counterparty?.id)}
          />
        </div>
        <div style={{ flexBasis: '50%' }}>
          <Form.Item
            label="Flight Number"
            name="flightNumber"
            rules={[{ required: true }, { max: 12 }]}
            normalize={(val) => val.toUpperCase().replace(/ /g, '')}>
            <Input size={CONTROL_SIZE} placeholder="Enter flight number" />
          </Form.Item>
        </div>
      </Flex>

      <AircraftInput
        initialAircraftId={flight?.aircraftId}
        required
        withExtraInfo
        onChange={(value) => form.setFieldValue('aircraftId', value)}
        apiFilter={{ operatorId: updatedOperatorId }}
      />

      <Typography style={{ fontWeight: 500, margin: '20px 0 8px', display: 'flex', gap: 10 }}>
        <span>Departure</span>
        <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
          {departureAirport?.city?.country?.name}
        </span>
      </Typography>

      <Flex gap={8} justify="space-between" align="start">
        <div style={{ flexGrow: 1 }}>
          <AirportInput
            name="departureAirportId"
            label="Airport"
            required
            onSetAirport={setDepartureAirport}
            placeholder="Select aiport"
            initialFilter={flight.departureAirport?.name}
            onChange={(value) => form.setFieldValue('departureAirportId', value)}
          />
        </div>
        <Form.Item
          name="estimatedTimeDeparture"
          label="Estimated D&T"
          rules={[{ required: true }]}
          style={{ width: 173 }}>
          <StyledDatePicker
            id="estimatedTimeDeparture"
            format={DATE_TIME_FORMAT}
            showTime={{ defaultValue: getUTCDate() }}
            placeholder="Select estimated D&T"
            onSetDate={(value) => {
              form.setFieldValue('estimatedTimeDeparture', value);
            }}
          />
        </Form.Item>
        {showActualDates && (
          <Form.Item name="actualTimeDeparture" label="Actual D&T" style={{ width: 173 }}>
            <StyledDatePicker
              id="actualTimeDeparture"
              format={DATE_TIME_FORMAT}
              showTime={{ defaultValue: getUTCDate() }}
              placeholder="Select actual D&T"
              onSetDate={(value) => {
                form.setFieldValue('actualTimeDeparture', value);
              }}
            />
          </Form.Item>
        )}
      </Flex>

      <Typography style={{ fontWeight: 500, margin: '8px 0', display: 'flex', gap: 10 }}>
        <span>Arrival</span>
        <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>{arrivalAirport?.city?.country?.name}</span>
      </Typography>

      <Flex gap={8} justify="space-between" align="start">
        <div style={{ flexGrow: 1 }}>
          <AirportInput
            name="arrivalAirportId"
            label="Airport"
            required
            onSetAirport={setArrivalAirport}
            placeholder="Select aiport"
            initialFilter={flight.arrivalAirport?.name}
            onChange={(value) => form.setFieldValue('arrivalAirportId', value)}
          />
        </div>
        <Form.Item
          name="estimatedTimeArrival"
          label="Estimated D&T"
          style={{ width: 173 }}
          rules={[
            { required: true },
            {
              validator: async (_, value) => {
                if (ETD && value <= ETD) {
                  return Promise.reject('ETA should be later than ETD');
                }
              }
            }
          ]}>
          <StyledDatePicker
            id="estimatedTimeArrival"
            format={DATE_TIME_FORMAT}
            showTime={{ defaultValue: getUTCDate() }}
            placeholder="Select estimated D&T"
            onSetDate={(value) => {
              form.setFieldValue('estimatedTimeArrival', value);
            }}
          />
        </Form.Item>
        {showActualDates && (
          <Form.Item name="actualTimeArrival" label="Actual D&T" style={{ width: 173 }}>
            <StyledDatePicker
              id="actualTimeArrival"
              format={DATE_TIME_FORMAT}
              showTime={{ defaultValue: getUTCDate() }}
              placeholder="Select actual D&T"
              onSetDate={(value) => {
                form.setFieldValue('actualTimeArrival', value);
              }}
            />
          </Form.Item>
        )}
      </Flex>

      <Form.Item name="route" label="Route">
        <Input.TextArea size={CONTROL_SIZE} placeholder="Input route" />
      </Form.Item>

      <CountriesInput
        name="overflightCountryIds"
        label="Overflight Countries"
        placeholder="Select overflight countries"
        isMultiple
        initialList={flight?.overflightCountries}
      />

      <AirportsInput
        name="alternativeAirportIds"
        label="Alternate Airports"
        placeholder="Select alternative airports"
        initialList={flight?.alternativeAirports}
      />

      <Flex gap={8} justify="space-between" align="start">
        <div style={{ flexBasis: '50%' }}>
          <FlightTypeInput onChange={(value) => form.setFieldValue('flightTypeId', value)} />
        </div>
        <div style={{ flexBasis: '50%' }}>
          <FlightPurposeInput onChange={(value) => form.setFieldValue('flightPurposeId', value)} />
        </div>
      </Flex>
    </div>
  );
};
