import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Flex, Input } from 'antd';
import { IFilter } from 'queries/orderServices';
import { getSelectOptions, useCheckboxGroupAutoFocus } from 'helpers';
import { OrderType } from 'services/api/data-contracts';
import { isEqual, omit } from 'lodash';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import { orderTypes } from 'consts/order';
import { StyledDateRangePicker } from 'components/common/StyledDatePicker';
import { RangeValue } from 'store/helpers/types';

interface IProps {
  appliedFilter: IFilter;
  applyFilter: (filter: IFilter) => void;
  isLoading: boolean;
}

export const Filters = ({ appliedFilter, applyFilter, isLoading }: IProps) => {
  const [filter, setFilter] = useState<IFilter>({});

  const checkboxRef = useCheckboxGroupAutoFocus();

  useEffect(() => {
    setFilter(appliedFilter);
  }, [appliedFilter]);

  const handleChangeFilter = (name: string, value: string | RangeValue | OrderType[] | boolean) => {
    if (value) {
      setFilter((filter) => ({ ...filter, [name]: value }));
    } else {
      setFilter((filter) => omit(filter, name));
    }
  };

  return (
    <Flex vertical gap={12} data-testid="services-filters">
      <Flex gap={8} style={{ flexGrow: 1 }}>
        <Checkbox.Group
          options={getSelectOptions(orderTypes)}
          data-testid="order-types-select"
          value={filter.types}
          onChange={(value) => handleChangeFilter('types', value as OrderType[])}
          ref={checkboxRef}
        />
      </Flex>

      <Flex gap={8}>
        <Input
          size={CONTROL_SIZE}
          placeholder="All customers"
          name="customerNames"
          onChange={({ target: { name, value } }) => handleChangeFilter(name, value)}
          value={filter.customerNames}
          allowClear
        />

        <Input
          size={CONTROL_SIZE}
          placeholder="All suppliers"
          name="supplierNames"
          onChange={({ target: { name, value } }) => handleChangeFilter(name, value)}
          value={filter.supplierNames}
          allowClear
        />
      </Flex>

      <Flex gap={8}>
        <Input
          size={CONTROL_SIZE}
          placeholder="All providers"
          name="providerNames"
          onChange={({ target: { name, value } }) => handleChangeFilter(name, value)}
          value={filter.providerNames}
          allowClear
        />

        <Input
          size={CONTROL_SIZE}
          placeholder="All locations"
          name="locationNames"
          onChange={({ target: { name, value } }) => handleChangeFilter(name, value)}
          value={filter.locationNames}
          allowClear
        />
      </Flex>

      <Flex gap={8}>
        <StyledDateRangePicker
          placeholder={['Start from', 'To']}
          value={filter.startDatetime}
          name="startDatetime"
          onChange={(value) => {
            handleChangeFilter('startDatetime', value);
          }}
          format={DATE_FORMAT}
          onSetDate={(value) => {
            handleChangeFilter('startDatetime', value);
          }}
        />

        <StyledDateRangePicker
          placeholder={['End from', 'To']}
          value={filter.endDatetime}
          name="endDatetime"
          onChange={(value) => {
            handleChangeFilter('endDatetime', value);
          }}
          format={DATE_FORMAT}
          onSetDate={(value) => {
            handleChangeFilter('endDatetime', value);
          }}
        />
      </Flex>

      <Flex justify="end">
        <Button
          size={CONTROL_SIZE}
          type="primary"
          onClick={() => applyFilter(filter)}
          disabled={isEqual(appliedFilter, filter)}
          loading={isLoading}>
          Apply
        </Button>
      </Flex>
    </Flex>
  );
};
