import { ColumnsType } from 'antd/es/table';
import { City } from 'services/api/data-contracts';

export const getCityColumns = (): ColumnsType<City> => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
    render: (_, record: City) => record.country?.name || '-',
    ellipsis: true
  },
  {
    title: 'IATA',
    dataIndex: 'iata',
    key: 'iata',
    width: 60
  },
  {
    title: 'Time zone',
    dataIndex: 'timeZone',
    key: 'timeZone',
    width: 105
  }
];
