import { create } from 'zustand';
import { PaymentFullyHydrated, PaymentHydrated } from 'services/api/data-contracts';
import { devtools } from 'zustand/middleware';
import { sortByDate } from './helpers';

export enum PaymentCreationMode {
  FROM_INVOICE = 'fromInvoice'
}

type PaymentStore = {
  current?: Partial<PaymentFullyHydrated>;
  setCurrent: (current?: Partial<PaymentFullyHydrated>) => void;
  list: PaymentHydrated[];
  setList: (list: PaymentHydrated[]) => void;
  isPaymentEditMode: boolean;
  setPaymentEditMode: (isPaymentEditMode: boolean) => void;
  paymentCreationMode?: PaymentCreationMode;
  setPaymentCreationMode: (paymentCreationMode?: PaymentCreationMode) => void;
};

export const usePaymentStore = create<PaymentStore>()(
  devtools((set) => ({
    current: undefined,
    setCurrent: (current) => {
      set({ current });
    },
    list: [],
    setList: (list) => {
      const sortedList = sortByDate(list, 'createdAt');
      set({ list: sortedList });
    },
    isPaymentEditMode: false,
    setPaymentEditMode: (isPaymentEditMode) => {
      set({ isPaymentEditMode });
    },
    paymentCreationMode: undefined,
    setPaymentCreationMode: (paymentCreationMode) => {
      set({ paymentCreationMode });
    }
  }))
);
