import { Dayjs } from 'dayjs';

export const computeValidTill = (uom: string, validFrom: Dayjs, validity: number) => {
  const unit = uom === 'hrs' ? 'hour' : 'day';
  return validFrom.add(validity, unit);
};

export const computeValidity = (validFrom: Dayjs, validTill: Dayjs) =>
  validTill.diff(validFrom, 'hour');

export const computeEstimatedParkingTime = (startDate?: Dayjs, endDate?: Dayjs) => {
  if (!startDate || !endDate) {
    return '';
  }

  const diffInMinutes = endDate.diff(startDate, 'minute');
  const hours = Math.trunc(+diffInMinutes / 60);
  const minutesLeft = +diffInMinutes % 60;
  const minutes = minutesLeft < 10 ? '0' + minutesLeft : minutesLeft;

  return `${hours}:${minutes}`;
};
