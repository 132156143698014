import { Button, Tooltip } from 'antd';
import { MdOpenInNew } from 'react-icons/md';
import { CONTROL_SIZE, SERVER_DATE_FORMAT } from 'consts/common';
import React from 'react';
import { InvoiceType } from 'services/api/data-contracts';
import { useInvoiceStore } from 'store/invoiceStore';
import { getUTCDate } from 'helpers';

interface IProps {
  isLoading?: boolean;
}

export const OpenBalanceButton = ({ isLoading }: IProps) => {
  const { current } = useInvoiceStore(({ current }) => ({
    current
  }));

  const handleOpenBalance = () => {
    if (!current) return;

    const url = new URL(window.location.origin + '/balance');
    const params = new URLSearchParams({
      currency: current.currency,
      counterpartyId: (current.type === InvoiceType.Issued
        ? current.payer.id
        : current.supplier.id
      ).toString(),
      contractIds: current.contractId.toString(),
      contractDate: [
        getUTCDate(current.invoiceDate).set('date', 1),
        getUTCDate(current.invoiceDate).endOf('month')
      ]
        .map((date) => date.format(SERVER_DATE_FORMAT))
        .join(',')
    });
    url.search = params.toString();

    window.open(url.toString(), '_blank');
  };

  return (
    <Tooltip title="Show in the Balance dashboard">
      <Button
        icon={<MdOpenInNew style={{ top: '2px', position: 'relative' }} />}
        onClick={handleOpenBalance}
        size={CONTROL_SIZE}
        loading={isLoading}
      />
    </Tooltip>
  );
};
