import { useQuery } from '@tanstack/react-query';
import { flightProgramsApi } from 'services/api';
import { QUERY_KEY } from './consts';
import { useFlightProgramStore } from 'store/flightProgramStore';
import { DEFAULT_LIMIT, SERVER_DATE_FORMAT } from 'consts/common';
import { getJoinedProps, OptionPropName } from 'helpers/dataTransformations/getJoinedProps';
import { OrdersFilter, useOrdersDashboardStore } from 'store/ordersDashboardStore';

interface FetchListProps {
  filter: OrdersFilter;
}

const fetchList = async ({ filter }: FetchListProps) =>
  await flightProgramsApi.getPrograms({
    limit: DEFAULT_LIMIT,
    programStatuses: filter.orderStatuses?.join(','),
    startDatetime: filter.orderStartDate?.map((date) => date?.format(SERVER_DATE_FORMAT)).join(','),
    endDatetime: filter.orderEndDate?.map((date) => date?.format(SERVER_DATE_FORMAT)).join(','),
    types: filter.orderTypes?.join(','),
    orderNumbers: filter.orderNumbers,
    customerIds: getJoinedProps(filter.customers, OptionPropName.Value),
    payerIds: getJoinedProps(filter.payers, OptionPropName.Value),
    locationIds: getJoinedProps(filter.locations, OptionPropName.Value),
    supplierIds: getJoinedProps(filter.suppliers, OptionPropName.Value),
    providerIds: getJoinedProps(filter.providers, OptionPropName.Value),
    aircraftIds: getJoinedProps(filter.ordersAircrafts, OptionPropName.Value)
  });

export const useGetFlightPrograms = () => {
  const { setList } = useFlightProgramStore(({ setList }) => ({
    setList
  }));
  const { ordersFilter } = useOrdersDashboardStore(({ ordersFilter }) => ({ ordersFilter }));

  return useQuery({
    queryKey: [QUERY_KEY, ordersFilter],
    queryFn: () =>
      fetchList({
        filter: ordersFilter
      }),
    onSuccess: (response) => {
      setList(response?.data.items || []);
    }
  });
};
