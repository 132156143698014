import { getUTCDate } from 'helpers';
import { FlightWidgetItem } from 'services/api/data-contracts';
import { sortBy } from 'lodash';

export const validateSelectedFlights = (selectedFlights: FlightWidgetItem[]) => {
  if (selectedFlights.length > 2) {
    throw new Error("It's possible to create order only for 1 or 2 flights");
  }

  if (selectedFlights.length < 2) return;

  const [arrivalFlight, departureFlight] = sortBy(selectedFlights, (flight) =>
    getUTCDate(flight.estimatedTimeArrival)
  );

  if (arrivalFlight.operatorId !== departureFlight.operatorId) {
    throw new Error('Flights must have the same operator');
  }

  if (arrivalFlight.aircraft.id !== departureFlight.aircraft.id) {
    throw new Error('Flights must have the same aircraft');
  }

  if (
    getUTCDate(arrivalFlight.estimatedTimeArrival).isAfter(
      getUTCDate(departureFlight.estimatedTimeDeparture)
    )
  ) {
    throw new Error('Arrival flight must arrive before departure flight departs');
  }

  if (arrivalFlight.arrivalAirport.id !== departureFlight.departureAirport.id) {
    throw new Error('Arrival and departure airports must match');
  }
};
