import { useOptimisticUpdateMessage } from 'queries/messages/useOptimisticUpdateMessage';
import { FlagList } from 'services/emailEngineApi/data-contracts';
import { useMailboxConfig } from '../../helpers/useMailboxConfig';

export const useCustomTagUpdate = (id: string, flags?: FlagList) => {
  const flagMutation = useOptimisticUpdateMessage();

  return async (flagName: string) => {
    const { customFlags } = useMailboxConfig();
    const flag = Object.keys(customFlags).find((item) => item === flagName) as string;
    const isFlagIncluded = flags?.includes(flag);

    if (flag) {
      try {
        flagMutation.mutate({
          messageId: id,
          data: {
            flags: {
              [isFlagIncluded ? 'delete' : 'add']: [flag]
            }
          }
        });
      } catch (error) {
        console.error(`Failed to ${isFlagIncluded ? 'delete' : 'add'} flag`, error);
      }
    }
  };
};
