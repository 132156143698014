import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { flightTypesApi } from 'services/api';
import { ENTITY_NAME, QUERY_KEY } from './consts';
import { MESSAGE_DURATION } from 'consts/common';

const deleteItem = async (id: number) => await flightTypesApi.deleteFlightType(id);

export const useDeleteFlightType = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteItem,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });

      message.success(`${ENTITY_NAME} has been successfully removed`, MESSAGE_DURATION);
    }
  });
};
