import { Flex, Form, Input, InputNumber } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { OrderService } from 'services/api/data-contracts';
import React, { useContext, useEffect, useMemo } from 'react';
import { useServiceStore } from 'store/serviceStore';
import { useOrderStore } from 'store/orderStore';
import { FormInstance } from 'antd/es/form';
import { DashboardContext, DashboardType } from '../../../consts';
import { convertKgToGallons, convertKgToLitres, convertKgToTonnes } from 'helpers';
import { useGetServices } from 'queries/service';
import { OwnPriceForm } from './components/OwnPriceForm';
import { ForecastAndActualFields } from './components/ForecastAndActualFields';
import { FuelQuantityFields } from './components/FuelQuantityFields';
import { ServiceDetailsForm } from './components/ServiceDetailsForm';
import { useWatch } from 'antd/es/form/Form';
import { LinkedOrderTag } from './components/LinkedOrderTag';
import { FUEL_SERVICE_NAME } from 'consts/order';

type FuelOrderService = OrderService & {
  quantityGallons: number;
  quantityLitres: number;
  quantityTonnes: number;
};

const TON_COEFFICIENT = 1000;
const GAL_COEFFICIENT = 3.78541;

interface IProps {
  form: FormInstance<OrderService | FuelOrderService>;
  isTouched: boolean;
  setTouched?: React.Dispatch<boolean>;
}

export const OrderServiceForm = ({ form, setTouched, isTouched }: IProps) => {
  const { type: dashboardType } = useContext(DashboardContext);

  const { service } = useOrderStore(({ service }) => ({ service }));
  const { dictionaryServices } = useServiceStore(({ list }) => ({
    dictionaryServices: list.filter((i) => {
      const orderType = service?.order?.type;
      return orderType && i.orderTypes.includes(orderType);
    })
  }));

  const serviceId = useWatch('serviceId', form);
  const selectedService = useMemo(
    () => dictionaryServices.find((service) => service.id === serviceId),
    [dictionaryServices, serviceId]
  );
  const isFuel = useMemo(() => selectedService?.name === FUEL_SERVICE_NAME, [selectedService]);

  const { isLoading } = useGetServices();

  const setFuelProps = (density: number, kg?: number) => {
    form.setFieldsValue({
      quantity: kg ? +kg.toFixed() : 0,
      quantityGallons: kg ? convertKgToGallons(kg, density) : 0,
      quantityLitres: kg ? convertKgToLitres(kg, density) : 0,
      quantityTonnes: kg ? convertKgToTonnes(kg) : 0
    });
  };

  useEffect(() => {
    if (service) {
      form.setFieldsValue({
        ...service,
        costInSalesCurrency: service.costInSalesCurrency ? service.costInSalesCurrency : undefined
      });
    } else {
      form.resetFields();
    }
  }, [service]);

  useEffect(() => {
    const density = form.getFieldValue(['additionalData', 'density']);
    const quantity = form.getFieldValue('quantity');

    if (isFuel && density) {
      setFuelProps(density, quantity);
    }
  }, [isFuel]);

  const handleValuesChange = (
    updated: Partial<OrderService | FuelOrderService>,
    data: Partial<OrderService | FuelOrderService>
  ) => {
    const didChange = form.isFieldsTouched() !== isTouched;

    if (didChange && form) {
      setTouched && setTouched(form.isFieldsTouched());
    }

    if (updated.serviceId) {
      const isFuelService =
        dictionaryServices.find((s) => s.id === updated.serviceId)?.name === FUEL_SERVICE_NAME;

      form.setFieldValue('isOwnService', isFuelService);
    }

    if (data.forecastSalesAmount && data.forecastCostAmount) {
      form.setFieldValue('forecastProfit', data.forecastSalesAmount - data.forecastCostAmount);
    }

    let kg = updated.quantity || data.quantity;
    const density = data.additionalData?.density;

    if ('quantityTonnes' in updated) {
      kg = (updated.quantityTonnes || 0) * TON_COEFFICIENT;
    } else if ('quantityLitres' in updated && density) {
      kg = (updated.quantityLitres || 0) * density;
    } else if ('quantityGallons' in updated && density) {
      kg = (updated.quantityGallons || 0) * density * GAL_COEFFICIENT;
    }

    if (isFuel && data.additionalData?.density) {
      setFuelProps(data.additionalData.density, kg);
      form.setFieldsValue({
        ...('quantityLitres' in updated && { quantityLitres: updated.quantityLitres }),
        ...('quantityGallons' in updated && { quantityGallons: updated.quantityGallons })
      });
    }
  };

  return (
    <Form
      layout="vertical"
      requiredMark={false}
      form={form}
      autoComplete="off"
      colon={false}
      initialValues={{ isOwnService: false, quantity: 0, additionalData: { density: 0.8 } }}
      className="styled-form"
      onValuesChange={handleValuesChange}>
      <LinkedOrderTag service={service} />

      <ServiceDetailsForm form={form} service={service} isLoading={isLoading} />

      {isFuel ? (
        <FuelQuantityFields />
      ) : (
        <Flex gap={8} style={{ width: 'calc(100% - 133px)' }}>
          <Form.Item label="UOM">
            <Input
              size={CONTROL_SIZE}
              value={selectedService?.serviceName?.unitOfMeasure?.shortName}
              style={{ width: 85 }}
              disabled
            />
          </Form.Item>

          <Form.Item name="quantity" label="Quantity" style={{ flexGrow: 1 }}>
            <InputNumber type="number" size={CONTROL_SIZE} style={{ width: '100%' }} />
          </Form.Item>
        </Flex>
      )}

      {dashboardType === DashboardType.Settlement && <ForecastAndActualFields />}

      <Form.Item name="notes" label="Notes">
        <Input.TextArea size={CONTROL_SIZE} autoSize={{ minRows: 2, maxRows: 20 }} />
      </Form.Item>

      {dashboardType === DashboardType.Settlement && <OwnPriceForm form={form} />}
    </Form>
  );
};
