export function sortByDate<T>(arr: Array<T>, propName: keyof T) {
  return arr.sort(
    (a, b) => new Date(b[propName] as string).getTime() - new Date(a[propName] as string).getTime()
  );
}

export function sortByNestedDate<T, K extends keyof T>(
  arr: Array<T>,
  outerProp: K,
  innerProp: keyof T[K]
) {
  return arr.sort((a, b) => {
    const dateA = new Date(a[outerProp][innerProp] as string).getTime();
    const dateB = new Date(b[outerProp][innerProp] as string).getTime();
    return dateB - dateA;
  });
}
