import React, { useEffect, useRef, useState } from 'react';
import { Flex } from 'antd';
import { styled } from 'styled-components';

interface IProps {
  children: React.ReactNode;
}

export const FiltersContainer = styled(Flex)<{ showAfter: boolean }>`
  max-width: calc(100% - 111px);
  overflow-x: hidden;
  flex-wrap: nowrap;
  position: relative;

  ${({ showAfter }) =>
    showAfter &&
    `
    &:after {
      content: '';
      width: 30px;
      height: 100%;
      position: absolute;
      right: 0;
      background: linear-gradient(90deg, transparent, #f5f5f5 10px);
    }
  `}
`;

export const AppliedFiltersContainer = ({ children }: IProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [showAfter, setShowAfter] = useState(false);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const handleResize = () => {
        const containerWidth = container.clientWidth;
        const contentWidth = container.scrollWidth;
        setShowAfter(contentWidth > containerWidth);
      };

      handleResize();
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [children]);

  return (
    <FiltersContainer ref={containerRef} gap={4} showAfter={showAfter}>
      {children}
    </FiltersContainer>
  );
};
