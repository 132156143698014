import { useQuery } from '@tanstack/react-query';
import { orderServicesApi } from 'services/api';
import { QUERY_KEY } from './consts';
import { OrderType } from 'services/api/data-contracts';
import { PAGE_SIZE_20, SERVER_DATE_FORMAT } from 'consts/common';
import { RangeValue } from 'store/helpers/types';

export interface IFilter {
  locationNames?: string;
  customerNames?: string;
  providerNames?: string;
  supplierNames?: string;
  types?: OrderType[];
  payerNames?: string;
  startDatetime?: RangeValue;
  endDatetime?: RangeValue;
  invoiceId?: number;
  page?: number;
  pageSize?: number;
}

const fetchList = async (filter: IFilter) =>
  await orderServicesApi.getOrderServices({
    ...filter,
    types: filter.types ? filter.types.join(',') : undefined,
    startDatetime: filter.startDatetime?.map((date) => date?.format(SERVER_DATE_FORMAT)).join(','),
    endDatetime: filter.endDatetime?.map((date) => date?.format(SERVER_DATE_FORMAT)).join(','),
    pageSize: PAGE_SIZE_20,
    page: 1
  });

export const useGetOrderServices = (filter: IFilter, enabled: boolean) =>
  useQuery({
    queryKey: [QUERY_KEY, filter],
    queryFn: () => fetchList(filter),
    enabled
  });
