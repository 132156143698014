import { Payment, PaymentStatus, TransactionType } from 'services/api/data-contracts';
import { Dayjs } from 'dayjs';
import { getUTCDate } from 'helpers';

export type PaymentWithDayjs = Omit<Payment, 'sentDate' | 'receivedDate' | 'plannedDate'> & {
  sentDate?: Dayjs;
  receivedDate?: Dayjs;
  plannedDate?: Dayjs;
  advance: number;
};

export const paymentInitialData: Partial<PaymentWithDayjs> = {
  status: PaymentStatus.PLAN,
  sentDate: getUTCDate(),
  receivedDate: getUTCDate(),
  transactionType: TransactionType.Payment
};
