import { BaseFlightWithAirportCodes, Flight } from 'services/api/data-contracts';
import { getUTCDate } from 'helpers';
import { DATE_TIME_FORMAT } from 'consts/common';

export const getFlightLabel = ({
  flightNumber,
  departureAirport,
  actualTimeDeparture,
  estimatedTimeDeparture,
  arrivalAirport,
  actualTimeArrival,
  estimatedTimeArrival
}: Flight | BaseFlightWithAirportCodes) =>
  [
    flightNumber,
    departureAirport?.code +
      ' ' +
      getUTCDate(actualTimeDeparture || estimatedTimeDeparture).format(DATE_TIME_FORMAT),
    arrivalAirport?.code +
      ' ' +
      getUTCDate(actualTimeArrival || estimatedTimeArrival).format(DATE_TIME_FORMAT)
  ]
    .filter((i) => !!i)
    .join(' | ');
