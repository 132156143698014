import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { OwnPriceHydrated } from 'services/api/data-contracts';
import { Dayjs } from 'dayjs';
import { getUTCDate } from 'helpers';

export type OwnPriceHydratedWithDayJs = Omit<
  OwnPriceHydrated,
  'endDate' | 'startDate' | 'createdAt' | 'updatedAt'
> & {
  endDate: Dayjs | null;
  startDate: Dayjs | null;
  createdAt: Dayjs | null;
  updatedAt: Dayjs | null;
};

type OwnPriceStore = {
  current?: Partial<OwnPriceHydratedWithDayJs>;
  setCurrent: (current?: Partial<OwnPriceHydrated>) => void;
  list: OwnPriceHydrated[];
  setList: (data: { items: OwnPriceHydrated[]; total: number }) => void;
  page: number;
  setPage: (page: number) => void;
  total: number;
};

export const useOwnPriceStore = create<OwnPriceStore>()(
  devtools((set) => ({
    current: undefined,
    setCurrent: (curr) => {
      const current = curr
        ? {
            ...curr,
            endDate: curr?.endDate ? getUTCDate(curr?.endDate) : null,
            startDate: curr?.startDate ? getUTCDate(curr?.startDate) : null,
            createdAt: curr?.createdAt ? getUTCDate(curr?.createdAt) : null,
            updatedAt: curr?.updatedAt ? getUTCDate(curr?.updatedAt) : null
          }
        : undefined;
      set({ current });
    },
    list: [],
    setList: ({ items: list, total }) => {
      set({ list, total });
    },
    page: 1,
    setPage: (page) => {
      set({ page });
    },
    total: 0
  }))
);
