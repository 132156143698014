import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { paymentsApi } from 'services/api';
import { CreditNoteInput, Payment, PaymentInput } from 'services/api/data-contracts';
import { PAYMENT_QUERY_KEY } from './consts';
import { INVOICE_QUERY_KEY } from '../invoice/consts';
import { BALANCE_WIDGET_QUERY_KEY } from 'queries/balance/consts';
import { useBalanceDashboardStore } from 'store/balanceDashboardStore';
import { MESSAGE_DURATION } from 'consts/common';
import { splitCamelCase } from 'helpers';
import { useInvoiceStore } from 'store/invoiceStore';
import { SETTLEMENT_WIDGET_QUERY_KEY } from '../settlement/consts';

const updateItem = async (data: PaymentInput | CreditNoteInput, id: number) =>
  await paymentsApi.updatePayment(id, data);

export const useUpdatePayment = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const { shouldUpdateBalanceView, setShouldUpdateBalanceView, filter } = useBalanceDashboardStore(
    ({ shouldUpdateBalanceView, setShouldUpdateBalanceView, filter }) => ({
      shouldUpdateBalanceView,
      setShouldUpdateBalanceView,
      filter
    })
  );
  const { current: invoice } = useInvoiceStore(({ current }) => ({ current }));

  return useMutation({
    mutationFn: async (
      data: (Pick<Payment, 'id'> & PaymentInput) | (Pick<Payment, 'id'> & CreditNoteInput)
    ) => await updateItem(data, data.id),
    onSuccess: async (response) => {
      if (response.data) {
        await queryClient.refetchQueries([PAYMENT_QUERY_KEY, response.data.id]);
        await queryClient.refetchQueries([INVOICE_QUERY_KEY, invoice?.id]);
        shouldUpdateBalanceView &&
          (await queryClient.refetchQueries([BALANCE_WIDGET_QUERY_KEY, filter]));
        await queryClient.refetchQueries([SETTLEMENT_WIDGET_QUERY_KEY]);

        message.success(
          `${splitCamelCase(response.data.transactionType)} has been successfully updated`,
          MESSAGE_DURATION
        );

        onSuccess && onSuccess();
        shouldUpdateBalanceView && setShouldUpdateBalanceView(false);
      }
    }
  });
};
