import { Tag, Tooltip } from 'antd';
import React from 'react';
import { capitalize } from 'lodash';

interface IFilterTagProps {
  name: string;
  value: string;
  onClose: () => void;
  icon?: React.ReactNode;
}

export const FilterTag = ({ name, value, onClose, icon }: IFilterTagProps) => {
  const getTitle = (title: string) => capitalize(title?.replace(/([a-z])([A-Z])/g, '$1 $2'));

  return (
    <Tooltip title={getTitle(name)}>
      <Tag closable onClose={onClose} icon={icon}>
        {value}
      </Tag>
    </Tooltip>
  );
};
