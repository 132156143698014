import React, { useMemo } from 'react';
import { Table } from 'antd';
import { CreditNoteItemHydrated } from 'services/api/data-contracts';
import { CONTROL_SIZE } from 'consts/common';
import { getColumns } from './columns';
import { styled } from 'styled-components';

const StyledTable = styled(Table<CreditNoteItemHydrated>)`
  .even {
    background-color: #fafafa;
  }
`;

interface IProps {
  items: CreditNoteItemHydrated[];
}

export const CreditNoteItemsTable = ({ items }: IProps) => {
  const dataSource = items.map((i) => ({ ...i, key: i.id })) as CreditNoteItemHydrated[];
  const columns = useMemo(getColumns, []);

  return (
    <StyledTable
      dataSource={dataSource}
      columns={columns}
      size={CONTROL_SIZE}
      pagination={false}
      rowClassName={(_, idx) => (idx % 2 ? 'even' : '')}
    />
  );
};
