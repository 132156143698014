import React, { useCallback, useEffect } from 'react';
import { Grid, Layout } from 'antd';
import { styled } from 'styled-components';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AppHeader } from './AppHeader/AppHeader';
import { ErrorBoundary } from './ErrorBoundary/ErrorBoundary';
import { queryClient } from '../queries';
import { QueryClientProvider } from '@tanstack/react-query';
import { hasCurrentUserRole, UserRole } from 'services/auth/hasUserRole';
import { idTokenStore } from 'services/auth/IdTokenStore';
import { settingsStore } from 'services/settings/SettingsStore';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { defaultOrdersFilterForDispatcher, defaultOrdersFilterForSettlement } from 'consts/order';
import { useFlightsDashboardStore } from 'store/flightsDashboardStore';
import { useMessageStore } from 'store/messageStore';
import { defaultFlightsFilter } from 'consts/flight';

const { Content } = Layout;

const { useBreakpoint } = Grid;

const StyledLayout = styled(Layout)`
  min-height: 100vh;
  min-width: 1366px;

  .ant-layout-header,
  .ant-menu-item,
  .ant-menu-submenu-horizontal {
    line-height: 48px !important;
    height: 48px !important;
  }
`;

export const AppLayout: React.FC = () => {
  const screens = useBreakpoint();

  const navigate = useNavigate();
  const location = useLocation();

  const { availableTenants } = idTokenStore.getUserData();
  const currentTenantId = settingsStore.getCurrentTenantId();

  const { setOrdersFilter } = useOrdersDashboardStore(({ setOrdersFilter }) => ({
    setOrdersFilter
  }));
  const { setFlightsFilter } = useFlightsDashboardStore(({ setFlightsFilter }) => ({
    setFlightsFilter
  }));
  const { setMessagesFilter } = useMessageStore(({ setMessagesFilter }) => ({ setMessagesFilter }));

  useEffect(() => {
    if (availableTenants.length > 0 && !settingsStore.getCurrentTenantId()) {
      settingsStore.setAvailableTenants(availableTenants);
    }
  }, []);

  const handleGoHome = useCallback(() => {
    const isDispatcher = hasCurrentUserRole([UserRole.Dispatcher]);
    const isSettlement = hasCurrentUserRole([UserRole.Settlement]);

    if (isDispatcher || isSettlement) {
      setOrdersFilter(
        isDispatcher ? defaultOrdersFilterForDispatcher : defaultOrdersFilterForSettlement
      );
      setFlightsFilter(defaultFlightsFilter);
      setMessagesFilter({});
    }

    const homepage = isDispatcher
      ? '/dashboard/dispatcher'
      : isSettlement
        ? '/dashboard/settlement'
        : '/dictionaries';

    navigate(homepage);
  }, [currentTenantId]);

  useEffect(() => {
    if (location.pathname === '/') {
      handleGoHome();
    }
  }, [location.pathname]);

  const isDashboard = location.pathname.startsWith('/dashboard');
  const pageName = location.pathname
    .split('/')
    // First element is always empty string because of the leading slash
    .slice(0, isDashboard ? 3 : 2)
    .join('/');

  return (
    <StyledLayout>
      <AppHeader onGoHome={handleGoHome} />

      <ErrorBoundary onGoBack={handleGoHome} key={pageName}>
        <QueryClientProvider client={queryClient}>
          <Content
            style={{
              padding: screens.xxl ? '12px 20px' : '12px',
              flexGrow: 1,
              overflowX: 'clip'
            }}>
            <Outlet />
          </Content>
        </QueryClientProvider>
      </ErrorBoundary>
    </StyledLayout>
  );
};
