import { useQuery } from '@tanstack/react-query';
import { countriesApi } from 'services/api';
import { QUERY_KEY } from './consts';
import { useCountryStore } from 'store/countryStore';

interface IFilter {
  page: number;
  pageSize?: number;
  name?: string;
}

const fetchList = async (filter: IFilter) => await countriesApi.getCountries(filter);

export const useGetCountries = (filter: IFilter, shouldUpdateStore?: boolean) => {
  const { setList } = useCountryStore(({ setList }) => ({ setList }));

  return useQuery({
    queryKey: [QUERY_KEY, filter],
    queryFn: () => fetchList(filter),
    onSuccess: (response) => {
      if (shouldUpdateStore) {
        setList(response.data);
      }
    }
  });
};
