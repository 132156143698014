import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import { Descriptions, DescriptionsProps, Flex, Spin } from 'antd';
import React from 'react';
import { InvoiceItemHydratedWithRolledUp, OwnPriceHydrated } from 'services/api/data-contracts';
import { getAmountValue, getUTCDate } from 'helpers';

interface IProps {
  invoiceItem: InvoiceItemHydratedWithRolledUp;
  ownPrice?: OwnPriceHydrated;
  isLoading: boolean;
}

export const OwnPriceDescriptions = ({ ownPrice, isLoading, invoiceItem }: IProps) => {
  if (isLoading || !ownPrice) {
    return (
      <Flex style={{ width: '100%', height: 100 }} align="center" justify="center">
        <Spin />
      </Flex>
    );
  }

  const descriptions: DescriptionsProps['items'] = [
    {
      label: 'Name',
      children: ownPrice.name,
      span: 2
    },
    {
      label: 'Currency',
      children: ownPrice.currency
    },
    {
      label: 'Price',
      children: getAmountValue(ownPrice.price, 2, ownPrice.currency)
    },
    {
      label: 'UOM',
      children: ownPrice.unitOfMeasure.shortName
    },
    {
      label: 'Special price',
      children: ownPrice.isSpecialPrice ? 'Yes' : 'No'
    },
    {
      label: 'Start date',
      children: getUTCDate(ownPrice.startDate).format(DATE_FORMAT)
    },
    {
      label: 'End date',
      children: ownPrice.endDate ? getUTCDate(ownPrice.endDate).format(DATE_FORMAT) : '-'
    }
  ];

  return (
    <>
      <Descriptions items={descriptions} size={CONTROL_SIZE} column={2} bordered />

      <Flex align="center" justify="space-between" style={{ marginTop: 20 }}>
        <span>
          <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>Total: </span>
          {`${invoiceItem.quantity} ${invoiceItem.unitOfMeasure.shortName}`}
        </span>

        <span style={{ fontWeight: 500, marginTop: 4 }}>{`${ownPrice.currency} ${
          invoiceItem.rolledUpTotal ? getAmountValue(invoiceItem.rolledUpTotal) : ''
        }`}</span>
      </Flex>
    </>
  );
};
