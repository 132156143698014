import { useQuery } from '@tanstack/react-query';
import { invoicesApi } from 'services/api';
import { QUERY_KEY } from './consts';
import { useInvoiceStore } from 'store/invoiceStore';

const fetchList = async (paymentId: number) => await invoicesApi.getLinkableInvoices({ paymentId });

export const useGetLinkableInvoices = (id?: number) => {
  const { setLinkableInvoices } = useInvoiceStore(({ setLinkableInvoices }) => ({
    setLinkableInvoices
  }));

  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => {
      if (id) {
        return await fetchList(id);
      }
    },
    onSuccess: async (response) => {
      setLinkableInvoices(response?.data || []);
    }
  });
};
