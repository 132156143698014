import { useQuery } from '@tanstack/react-query';
import { Aircraft } from 'services/api/data-contracts';
import { aircraftsApi } from 'services/api';
import { useAircraftStore } from 'store/aircraftStore';
import { QUERY_KEY } from './consts';

const fetchItem = async (id: number) => await aircraftsApi.getAircraftById(id);

export const useGetAircraft = (
  enabled: boolean,
  id?: number,
  onSuccess?: (item?: Aircraft) => void
) => {
  const { setCurrent } = useAircraftStore(({ setCurrent }) => ({
    setCurrent
  }));

  return useQuery({
    queryKey: [QUERY_KEY, id],
    queryFn: async () => {
      if (id) {
        return await fetchItem(id);
      }
    },
    onSuccess: (response) => {
      setCurrent(response?.data);

      onSuccess && onSuccess(response?.data);
    },
    enabled
  });
};
