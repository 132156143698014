import { ColumnsType } from 'antd/es/table';
import { Airport } from 'services/api/data-contracts';

export const getAirportColumns = (): ColumnsType<Airport> => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true
  },
  {
    title: 'ICAO',
    dataIndex: 'icao',
    key: 'icao',
    width: 84
  },
  {
    title: 'IATA',
    dataIndex: 'iata',
    key: 'iata',
    width: 84
  },
  {
    title: 'Local code',
    dataIndex: 'localCode',
    key: 'localCode',
    width: 98
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
    render: (value) => value.name,
    ellipsis: true
  }
];
