import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { contractsApi } from 'services/api';
import { SupplierService } from 'services/api/data-contracts';
import { SERVICE_ENTITY_NAME, SERVICE_QUERY_KEY } from './consts';
import { MESSAGE_DURATION } from 'consts/common';

const createItem = async (data: SupplierService) =>
  await contractsApi.addContractService(data.contractId, data);

export const useCreateContractService = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createItem,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [SERVICE_QUERY_KEY] });

      message.success(`${SERVICE_ENTITY_NAME} has been successfully created`, MESSAGE_DURATION);

      onSuccess && onSuccess();
    }
  });
};
