import React, { useState, useEffect, useMemo } from 'react';
import { Select } from 'antd';
import { useGetAirports } from 'queries/airport';
import { Airport } from 'services/api/data-contracts';
import { useDebounce } from 'helpers';
import { CONTROL_SIZE, PAGE_SIZE_50 } from 'consts/common';

interface IProps {
  disabled?: boolean;
  placeholder?: string;
  initialFilter?: string;
  onSetAirport?: (airport: Airport | undefined) => void;
  onChange?: (value: number) => void;
  id?: string;
  value?: number;
}

export const AirportSelect = ({
  disabled,
  placeholder,
  initialFilter = '',
  onSetAirport,
  onChange,
  id,
  value
}: IProps) => {
  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(1);
  const [list, setList] = useState<Airport[]>([]);
  const [total, setTotal] = useState(0);

  const debouncedFilter = useDebounce<string>(filter, 200);

  useEffect(() => {
    setFilter(initialFilter);
  }, [initialFilter]);

  const { isFetching, data } = useGetAirports({
    page,
    name: debouncedFilter,
    pageSize: PAGE_SIZE_50
  });

  useEffect(() => {
    const newList = data?.data.items || [];

    if (page > 1) {
      newList.unshift(...list);
    }

    setList(newList);
    setTotal(data?.data.total || 0);
  }, [data]);

  const options = useMemo(
    () =>
      list.map(({ code, id }) => ({
        label: code,
        value: id
      })),
    [list]
  );

  const handleBlur = () => {
    if (options.length === 1 && onChange) {
      onChange(options[0].value);
    }
  };

  return (
    <Select
      allowClear
      style={{ width: '100%' }}
      placeholder={placeholder}
      options={options}
      size={CONTROL_SIZE}
      disabled={disabled}
      showSearch
      filterOption={false}
      loading={isFetching}
      onSearch={(value) => {
        setFilter(value);
        setPage(1);
      }}
      onClear={() => {
        setFilter('');
        setPage(1);
      }}
      onPopupScroll={(e) => {
        e.persist();
        const target = e.target as HTMLDivElement;

        if (target.scrollTop + target.offsetHeight === target.scrollHeight && list.length < total) {
          setPage(page + 1);
        }
      }}
      onChange={(value) => {
        const airport = data?.data.items.find(({ id }) => id === value);
        onSetAirport && onSetAirport(airport);
        onChange && onChange(value);
      }}
      onBlur={handleBlur}
      id={id}
      value={value}
    />
  );
};
