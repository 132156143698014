import { InputRef } from 'antd';
import { useEffect, useRef } from 'react';

export const useInputAutoFocus = () => {
  const domNodeRef = useRef<InputRef | null>(null);

  useEffect(() => {
    const node = domNodeRef.current;
    // second condition is for jsdom environment
    if (node && typeof node.focus === 'function') {
      requestAnimationFrame(() => {
        // relevant issue for modals https://github.com/ant-design/ant-design/issues/41239
        // ensures the focus() method is called after the browser finished updating the layout
        node.focus();
      });
    }
  }, []);

  return domNodeRef;
};
