import { create } from 'zustand';
import { Location } from 'services/api/data-contracts';
import { devtools } from 'zustand/middleware';

type LocationStore = {
  list: Location[];
  setList: (data: { items: Location[]; total: number }) => void;
  page: number;
  setPage: (page: number) => void;
  total: number;
};

export const useLocationStore = create<LocationStore>()(
  devtools((set) => ({
    list: [],
    setList: ({ items: list = [], total }) => {
      set({ list, total });
    },
    page: 1,
    setPage: (page) => {
      set({ page });
    },
    total: 0
  }))
);
