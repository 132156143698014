import React from 'react';
import { Flex, Input, Tooltip } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { InvoiceFilter } from 'store/settlementDashboardStore';

interface IProps {
  onChange: (name: string, value: string) => void;
  filter: InvoiceFilter;
}

export const InvoiceFilters = ({ onChange, filter }: IProps) => (
  <Flex gap={20} justify="flex-start" style={{ marginBottom: '12px', width: '1110px' }}>
    <Tooltip trigger={['focus', 'hover']} title="Invoice number">
      <Input
        size={CONTROL_SIZE}
        placeholder="All invoice numbers"
        name="invoiceNumbers"
        onChange={({ target: { name, value } }) => onChange(name, value)}
        value={filter.invoiceNumbers}
        style={{ width: '220px' }}
        allowClear
        autoComplete="off"
      />
    </Tooltip>
  </Flex>
);
