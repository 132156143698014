import React from 'react';
import { Form, Select } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { SpecialParameter, SpecialParameterAlias } from 'services/api/data-contracts';

interface IProps {
  name: (string | number)[];
  disabled?: boolean;
  required?: boolean;
  specialParameters?: SpecialParameter[];
  isFirst?: boolean;
  typesFilter: (alias: SpecialParameterAlias) => boolean;
}

export const SpecialParameterTypeSelect = ({
  name,
  disabled,
  required,
  specialParameters,
  isFirst,
  typesFilter
}: IProps) => {
  const selected = (specialParameters || [])
    ?.filter(Boolean)
    .flatMap((entity) => (entity.alias ? entity.alias : []));
  const isOrderSelected = selected?.includes(SpecialParameterAlias.Order);
  const isCustomerSelected =
    selected?.includes(SpecialParameterAlias.Customer) &&
    !!specialParameters?.find((entity) => entity.alias === SpecialParameterAlias.Customer)
      ?.entityId;

  const options = Object.values(SpecialParameterAlias)
    .filter(typesFilter)
    .map((i) => ({
      label: i,
      value: i,
      disabled:
        (!!selected?.length &&
          ((isOrderSelected && i !== SpecialParameterAlias.Order) ||
            (!isOrderSelected && i === SpecialParameterAlias.Order))) ||
        (i === SpecialParameterAlias.Contract && !isCustomerSelected) ||
        (i === SpecialParameterAlias.Customer && isCustomerSelected)
    }));

  return (
    <Form.Item
      name={name}
      rules={[{ required }]}
      style={{ minWidth: 130 }}
      label={isFirst ? 'Type' : ''}>
      <Select
        allowClear
        style={{ width: '100%' }}
        options={options}
        size={CONTROL_SIZE}
        disabled={disabled}
        showSearch
      />
    </Form.Item>
  );
};
