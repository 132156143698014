import React, { useEffect, useMemo, useState } from 'react';
import { Empty, Form, Select } from 'antd';
import { CONTROL_SIZE, DATE_FORMAT, PAGE_SIZE_15 } from 'consts/common';
import { ContractPaymentType, PaymentHydrated, TransactionType } from 'services/api/data-contracts';
import { useDebounce, getUTCDate, getAmountValue } from 'helpers';
import { useGetPayments } from 'queries/payment/useGetPayments';

interface IProps {
  name: string;
  invoiceId?: number;
  onChange: (value: number, option: PaymentHydrated) => void;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  initialFilter?: string;
}

export const PaymentsInput = ({
  invoiceId,
  onChange,
  disabled,
  label,
  name,
  placeholder,
  required,
  initialFilter
}: IProps) => {
  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(1);
  const [list, setList] = useState<PaymentHydrated[]>([]);
  const [total, setTotal] = useState(0);
  const [initialFilterDisabled, setInitialFilterDisabled] = useState(false);

  const debouncedSearch = useDebounce<string>(filter, 200);
  const searchValue = useMemo(
    () => (initialFilterDisabled ? debouncedSearch : debouncedSearch || initialFilter),
    [debouncedSearch, initialFilter, initialFilterDisabled]
  );

  const { isFetching, data } = useGetPayments({
    page,
    pageSize: PAGE_SIZE_15,
    ...(searchValue && { search: searchValue }),
    invoiceId
  });

  useEffect(() => {
    if (data) {
      setTotal(data.data.total || 0);

      if (page === 1) {
        setList(data.data.items);
      } else {
        setList((prev) => [...prev, ...data.data.items]);
      }
    }
  }, [data, filter]);

  const getPaymentLabel = (payment: PaymentHydrated) => (
    <span>
      {[
        payment.creditNoteNumber || payment.id,
        payment.currency,
        getAmountValue(payment.amount),
        getAmountValue(payment.advance),
        payment.type,
        payment.type === ContractPaymentType.Incoming ? payment.payer.name : payment.supplier.name,
        payment.status,
        payment.date && getUTCDate(payment.date).format(DATE_FORMAT),
        payment.transactionType === TransactionType.CreditNote && 'Credit note'
      ]
        .filter((i) => !!i)
        .join(' | ')}
    </span>
  );

  const options = useMemo(
    () => list.map((payment) => ({ label: getPaymentLabel(payment), value: payment.id })),
    [list]
  );

  return (
    <Form.Item name={name} label={label} rules={[{ required }]}>
      <Select
        allowClear
        placeholder={placeholder}
        options={options}
        filterOption={() => true}
        size={CONTROL_SIZE}
        disabled={disabled}
        showSearch
        loading={isFetching}
        popupMatchSelectWidth={false}
        notFoundContent={
          <Empty description="Payments not found" image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }
        onSearch={(value: string) => {
          setFilter(value);
          setPage(1);
        }}
        onClear={() => {
          setFilter('');
          setPage(1);
          setInitialFilterDisabled(true);
        }}
        onPopupScroll={(e) => {
          e.persist();
          const target = e.target as HTMLDivElement;

          if (
            target.scrollTop + target.offsetHeight === target.scrollHeight &&
            list.length < total
          ) {
            setPage(page + 1);
          }
        }}
        onChange={(value) => {
          const payment = list?.find((item) => item.id === value);
          onChange(value, payment as PaymentHydrated);
        }}
      />
    </Form.Item>
  );
};
