import { Dropdown, FloatButton, MenuProps, notification } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, PlusOutlined } from '@ant-design/icons';
import React from 'react';
import { validateServicesToBeInvoiced } from './helpers/validateServicesToBeInvoiced';
import { InvoiceType, SettlementOrder } from 'services/api/data-contracts';
import { MESSAGE_DURATION } from 'consts/common';
import { useSelectedOrdersAndServicesData } from './helpers/useSelectedOrdersAndServicesData';
import { getUTCDate } from 'helpers';
import { getInvoiceData } from './helpers/getInvoiceData';
import { useInvoiceStore } from 'store/invoiceStore';
import { useSettlementDashboardStore } from 'store/settlementDashboardStore';
import { useDrawerStore } from 'store/drawerStore';

export const AddNewButton = () => {
  const { setInvoiceEditMode, setInvoice } = useInvoiceStore(
    ({ setInvoiceEditMode, setCurrent }) => ({
      setInvoiceEditMode,
      setInvoice: setCurrent
    })
  );
  const { selectedOrders, indeterminateOrders } = useSettlementDashboardStore(
    ({ selectedOrders, indeterminateOrders }) => ({
      selectedOrders,
      indeterminateOrders
    })
  );
  const { setDrawerOpen } = useDrawerStore(({ setDrawerOpen }) => ({ setDrawerOpen }));

  const isSomeServiceSelected = selectedOrders.length > 0 || indeterminateOrders.length > 0;
  const selectedOrdersWithServices = useSelectedOrdersAndServicesData(isSomeServiceSelected);

  const setInvoiceData = (invoiceType: InvoiceType, isSomeServiceSelected?: boolean) => {
    const data = {
      type: invoiceType,
      isSomeServiceSelected,
      orders: selectedOrdersWithServices as SettlementOrder[],
      supplyDate: getUTCDate(selectedOrdersWithServices[0]?.endDatetime)
    };

    setInvoice(getInvoiceData(data));
  };

  const items: MenuProps['items'] = [
    {
      key: 'Invoice',
      type: 'group',
      label: 'Invoice',
      children: [
        {
          key: 'Invoice-Issued',
          label: 'Issued',
          icon: <ArrowLeftOutlined />,
          onClick: () => {
            try {
              validateServicesToBeInvoiced(
                InvoiceType.Issued,
                isSomeServiceSelected,
                selectedOrdersWithServices as SettlementOrder[]
              );

              setInvoiceData(InvoiceType.Issued, isSomeServiceSelected);
              setInvoiceEditMode(true);
              setDrawerOpen('invoice');
            } catch (e: unknown) {
              if (e instanceof Error) {
                notification.warning({
                  message: 'Service validation',
                  description: e.message,
                  placement: 'topLeft',
                  duration: MESSAGE_DURATION,
                  style: {
                    width: 320
                  }
                });
              }
            }
          }
        },
        {
          key: 'Invoice-Received',
          label: 'Received',
          icon: <ArrowRightOutlined />,
          onClick: () => {
            try {
              validateServicesToBeInvoiced(
                InvoiceType.Received,
                isSomeServiceSelected,
                selectedOrdersWithServices as SettlementOrder[]
              );

              setInvoiceData(InvoiceType.Received, isSomeServiceSelected);
              setInvoiceEditMode(true);
              setDrawerOpen('invoice');
            } catch (e: unknown) {
              if (e instanceof Error) {
                notification.warning({
                  message: 'Service validation',
                  description: e.message,
                  placement: 'topLeft',
                  duration: MESSAGE_DURATION,
                  style: {
                    width: 320
                  }
                });
              }
            }
          }
        }
      ]
    }
  ];

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <FloatButton
        type="primary"
        style={{ zIndex: 800, bottom: 70, right: 30 }}
        icon={<PlusOutlined />}
        data-testid="add-new-btn"
      />
    </Dropdown>
  );
};
