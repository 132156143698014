import { Descriptions, DescriptionsProps } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import React, { useContext } from 'react';
import { DataContext } from '../DictionaryDetails';
import { VatRate } from 'services/api/data-contracts';
import { getAmountValue } from 'helpers';

export const VatRateDictionaryView = () => {
  const data = useContext(DataContext) as VatRate;

  if (!data) {
    return null;
  }

  const descriptions: DescriptionsProps['items'] = [
    {
      label: 'Active',
      span: 2,
      children: data.isActive ? 'Yes' : 'No'
    },
    {
      label: 'Name',
      children: data.name
    },
    {
      label: 'Rate',
      children: data.rate ? getAmountValue(data.rate) : '-'
    },
    {
      label: 'Notes',
      span: 2,
      children: <span style={{ whiteSpace: 'pre-line' }}>{data.notes || '-'}</span>
    }
  ];

  return <Descriptions size={CONTROL_SIZE} items={descriptions} column={2} bordered />;
};
