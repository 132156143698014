/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ContractType,
  Counterparty,
  CounterpartyInput,
  CounterpartyListResponse,
  Error,
  ValidationError
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Counterparties<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Get a list of counterparties
   *
   * @tags counterparties
   * @name GetCounterparties
   * @summary Get a list of counterparties
   * @request GET:/counterparties
   * @secure
   */
  getCounterparties = (
    query?: {
      /**
       * Comma-separated list of fields to sort by, prefix with '-' for descending order. Example - https://api.epam.com/examples?sortBy=name,-createdAt
       * @pattern ^(id|createdAt|updatedAt|isSelf|isActive|isOperator|icao|iata|name|fullName|legalForm|legalAddress|actualAddress|notes|contract|countryId|,-id|,-createdAt|,-updatedAt|,-isSelf|,-isActive|,-isOperator|,-icao|,-iata|,-name|,-fullName|,-legalForm|,-legalAddress|,-actualAddress|,-notes|,-contract|,-countryId)*$
       */
      sortBy?: string;
      /** Filter by isOperator property */
      isOperator?: boolean;
      /** Filter by isSelf property */
      isSelf?: boolean;
      /** Filter by types of contracts associated with Counterparty. We use field type of Contract entity here. Ex - With Supplier, With Customer etc. */
      contractType?: ContractType;
      /**
       * Parameter to search by one or more values in used endpoint entities
       * @pattern ^[^%]*$
       */
      search?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<CounterpartyListResponse, Error>({
      path: `/counterparties`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Create a new counterparty
   *
   * @tags counterparties
   * @name AddCounterparty
   * @summary Create a new counterparty
   * @request POST:/counterparties
   * @secure
   */
  addCounterparty = (data: CounterpartyInput, params: RequestParams = {}) =>
    this.http.request<Counterparty, ValidationError | Error>({
      path: `/counterparties`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Get a specific counterparty
   *
   * @tags counterparties
   * @name GetCounterpartyById
   * @summary Get a specific counterparty
   * @request GET:/counterparties/{counterpartyId}
   * @secure
   */
  getCounterpartyById = (counterpartyId: number, params: RequestParams = {}) =>
    this.http.request<Counterparty, Error>({
      path: `/counterparties/${counterpartyId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Update a specific counterparty
   *
   * @tags counterparties
   * @name UpdateCounterparty
   * @summary Update a specific counterparty
   * @request PUT:/counterparties/{counterpartyId}
   * @secure
   */
  updateCounterparty = (
    counterpartyId: number,
    data: CounterpartyInput,
    params: RequestParams = {}
  ) =>
    this.http.request<Counterparty, ValidationError | Error>({
      path: `/counterparties/${counterpartyId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Delete a specific counterparty
   *
   * @tags counterparties
   * @name DeleteCounterparty
   * @summary Delete a specific counterparty
   * @request DELETE:/counterparties/{counterpartyId}
   * @secure
   */
  deleteCounterparty = (counterpartyId: number, params: RequestParams = {}) =>
    this.http.request<void, ValidationError | Error>({
      path: `/counterparties/${counterpartyId}`,
      method: 'DELETE',
      secure: true,
      ...params
    });
}
