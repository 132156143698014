import { ColumnsType } from 'antd/es/table';
import { Aircraft } from 'services/api/data-contracts';
import { numericSorter, stringSorter } from '../utils/tableSorters';

export const getAircraftColumns = (): ColumnsType<Aircraft> => [
  {
    title: 'Tail number',
    dataIndex: 'tailNumber',
    key: 'tailNumber',
    sorter: (a, b) => stringSorter(a.tailNumber, b.tailNumber),
    ellipsis: true
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    sorter: (a, b) => stringSorter(a.type, b.type),
    width: 94
  },
  {
    title: 'Modification',
    dataIndex: 'modification',
    key: 'modification',
    render: (value) => value || '-',
    sorter: (a, b) => stringSorter(a.modification || '-', b.modification || '-'),
    ellipsis: true
  },
  {
    title: 'MSN',
    dataIndex: 'serialNumber',
    key: 'serialNumber',
    sorter: (a, b) => stringSorter(a.serialNumber, b.serialNumber),
    ellipsis: true
  },
  {
    title: 'MTOW kg',
    dataIndex: 'maxTakeoffWeight',
    key: 'maxTakeoffWeight',
    render: (value) => (value ? value.toLocaleString('en-US') : '-'),
    sorter: (a, b) => numericSorter(a.maxTakeoffWeight, b.maxTakeoffWeight)
  },
  {
    title: 'Operator',
    dataIndex: 'operator',
    key: 'operator',
    render: (_, record) => record.operator?.name || '-',
    sorter: (a, b) => stringSorter(a.operator?.name || '-', b.operator?.name || '-'),
    ellipsis: true
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
    render: (_, record) => record.country?.name || '-',
    sorter: (a, b) => stringSorter(a.country?.name || '-', b.country?.name || '-'),
    ellipsis: true
  }
];
