import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from './consts';
import { ContractType } from 'services/api/data-contracts';
import { fetchList } from './useGetCounterparties';

interface IFilter {
  isSelf?: boolean;
  isOperator?: boolean;
  contractType?: ContractType;
}

export const useGetCounterpartiesForDropdown = (filter: IFilter) =>
  useQuery({
    queryKey: [QUERY_KEY, filter],
    queryFn: () => fetchList(filter)
  });
