import React, { useMemo } from 'react';
import { StyledCollapse } from 'components/common/StyledCollapse';
import { CollapseProps } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { InvoiceDetailsView } from './InvoiceDetailsView';
import { InvoiceHydrated, InvoiceType, Order, OrderType } from 'services/api/data-contracts';
import { InvoiceDetailsForm } from './InvoiceDetailsForm';
import { useInvoiceStore } from 'store/invoiceStore';
import { getUTCDate } from 'helpers';
import { isEmpty } from 'lodash';

export const InvoiceDetailsBlock = () => {
  const { current, isInvoiceEditMode } = useInvoiceStore(({ current, isInvoiceEditMode }) => ({
    current,
    isInvoiceEditMode
  }));

  const generalProps = useMemo(() => {
    if (!current) return 'Invoice details';

    const name =
      current.type === InvoiceType.Received ? current.supplier?.name : current.payer?.name;
    const data = [name, current.contract?.number];

    const mappedOrders = (current.orderServices || []).map(({ order }) => order as Order);
    const firstOrder =
      mappedOrders.length > 0
        ? mappedOrders.reduce((prev, curr) =>
            getUTCDate(prev.startDatetime) < getUTCDate(curr.startDatetime) ? prev : curr
          )
        : undefined;

    if (firstOrder && !isEmpty(firstOrder)) {
      const flightNumber =
        firstOrder.type === OrderType.PMT
          ? firstOrder.flights?.[0]?.flightNumber
          : firstOrder.arrivalFlight?.flightNumber;

      const locationName = firstOrder.location
        ? 'code' in firstOrder.location
          ? firstOrder.location.code
          : firstOrder.location.name
        : undefined;

      data.push(firstOrder.aircraftTailNumber, flightNumber, locationName);
    }

    return data.filter(Boolean).join(', ');
  }, [current]);

  if (!current) {
    return null;
  }

  const collapseContent: CollapseProps['items'] = [
    {
      key: 'general',
      label: !current?.id || isInvoiceEditMode ? 'Invoice details' : generalProps,
      children: isInvoiceEditMode ? (
        <InvoiceDetailsForm />
      ) : (
        <InvoiceDetailsView invoice={current as InvoiceHydrated} />
      )
    }
  ];

  return (
    <StyledCollapse
      items={collapseContent}
      expandIconPosition="end"
      size={CONTROL_SIZE}
      defaultActiveKey="general"
      ghost
    />
  );
};
