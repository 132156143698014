import { useQuery } from '@tanstack/react-query';
import { ownPricesApi } from 'services/api';
import { useOwnPriceStore } from 'store/ownPriceStore';
import { QUERY_KEY } from './consts';

const fetchItem = async (id: number) => await ownPricesApi.getOwnPriceById(id);

export const useGetOwnPrice = (id?: number, enabled?: boolean) => {
  const { setCurrent } = useOwnPriceStore(({ setCurrent }) => ({
    setCurrent
  }));

  return useQuery({
    queryKey: [QUERY_KEY, id],
    queryFn: async () => {
      if (id) {
        return await fetchItem(id);
      }
    },
    onSuccess: (response) => {
      setCurrent(response?.data);
    },
    enabled: enabled
  });
};
