import { ColumnsType } from 'antd/es/table';
import { Checkbox, Tooltip } from 'antd';
import React from 'react';
import { FlightPurpose } from 'services/api/data-contracts';
import { stringSorter } from '../utils/tableSorters';

export const getFlightPurposeColumns = (): ColumnsType<FlightPurpose> => [
  {
    title: '',
    dataIndex: 'isActive',
    key: 'isActive',
    render: (value) => (
      <Tooltip title={value ? 'Active' : 'Inactive'}>
        <Checkbox checked={value} disabled />
      </Tooltip>
    ),
    width: 32
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => stringSorter(a.name, b.name)
  }
];
