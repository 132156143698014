import { useQuery } from '@tanstack/react-query';
import { unitsOfMeasureApi } from 'services/api';
import { QUERY_KEY } from './consts';
import { useUnitOfMeasureStore } from 'store/unitOfMeasureStore';

const fetchList = async () => await unitsOfMeasureApi.getAllUnitOfMeasures();

export const useGetUnitsOfMeasure = () => {
  const { setList } = useUnitOfMeasureStore(({ setList }) => ({
    setList
  }));

  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: () => fetchList(),
    onSuccess: (response) => {
      setList(response.data.items);
    },
    keepPreviousData: true
  });
};
