export const CONTROL_SIZE = 'small';
export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm';
export const TIME_FORMAT = 'HH:mm';
export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_FORMAT_SHORT = 'DD.MM.YY';
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const SERVER_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const SERVER_DATE_FORMAT_CURRENCY_RATE = 'ddd, DD MMM YYYY HH:mm:ss [GMT]';
export const EMAIL_TEMPLATE_DATE_TIME_FORMAT = 'DDMMM HHmm';
export const EMAIL_LIST_DATE_TIME_FORMAT = 'HH:mm DD.MM.YY';

export const MAX_LATITUDE = (Math.atan(Math.sinh(Math.PI)) * 180) / Math.PI;

export const MAX_LONGITUDE = 180;

export const PAGE_SIZE_50 = 50;
export const PAGE_SIZE_35 = 35;
export const PAGE_SIZE_20 = 20;
export const PAGE_SIZE_15 = 15;

export const DEFAULT_LIMIT = 50;
export const DEFAULT_DOCUMENTS_LIST_LIMIT = 100;

export const validateMessages = {
  required: '${label} is required',
  string: {
    min: '${label} cannot be less than ${min} characters',
    max: '${label} cannot be longer than ${max} characters'
  },
  number: {
    range: '${label} must be between ${min} and ${max}'
  }
};

export const MESSAGE_DURATION = 8;
