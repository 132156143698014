import { supportedExtensionsForPreviewer } from '../constants';

export const getFileExtension = (filename?: string, contentType?: string): string => {
  if (filename) {
    const parts = filename.split('.');
    return parts[parts.length - 1];
  } else if (contentType) {
    const parts = contentType.split('/');
    return parts[parts.length - 1];
  }
  return '';
};

export const isExtensionSupportedForPreviewer = (extension: string) => {
  return supportedExtensionsForPreviewer.includes(extension);
};
