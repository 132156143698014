import React, { useMemo } from 'react';
import { Flex, Table } from 'antd';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { Order } from 'services/api/data-contracts';
import { getColumns } from './columns';
import { CONTROL_SIZE, DEFAULT_LIMIT } from 'consts/common';
import styled from 'styled-components';
import { DASHBOARD_TOP_PADDING } from '../../../../consts';
import { useNavigate } from 'react-router-dom';
import { useDrawerStore } from 'store/drawerStore';

const StyledTable = styled(Table<Order>)`
  .ant-table-placeholder > .ant-table-cell {
    border-bottom: none;
  }

  .table-row {
    cursor: pointer;
  }
`;

const TABLE_HEADER_HEIGHT = 39;

interface IProps {
  headerHeight: number;
  isLoading?: boolean;
}

export const OrdersTable = ({ headerHeight, isLoading }: IProps) => {
  const { ordersList, selectedOrders, setSelectedOrders, setOpenOrder } = useOrdersDashboardStore(
    ({ ordersList, selectedOrders, setSelectedOrders, setOpenOrder }) => ({
      ordersList,
      selectedOrders,
      setSelectedOrders,
      setOpenOrder
    })
  );
  const { setDrawerOpen } = useDrawerStore(({ setDrawerOpen }) => ({ setDrawerOpen }));

  const navigate = useNavigate();

  const tableHeight = useMemo(
    () => window.innerHeight - DASHBOARD_TOP_PADDING - headerHeight - TABLE_HEADER_HEIGHT,
    [window.innerHeight, headerHeight]
  );

  const dataSource = useMemo(
    () => ordersList.map((order) => ({ ...order, key: order.number })),
    [ordersList]
  );
  const columns = useMemo(getColumns, []);

  return (
    <StyledTable
      dataSource={dataSource}
      columns={columns}
      size={CONTROL_SIZE}
      loading={isLoading}
      data-testid={isLoading ? 'table-loading' : 'table'}
      pagination={false}
      scroll={{ y: tableHeight }}
      rowSelection={{
        onSelect: (order, isSelected) => {
          setSelectedOrders(
            isSelected
              ? [...selectedOrders, order]
              : selectedOrders.filter(({ number }) => number !== order.number)
          );
        },
        selectedRowKeys: selectedOrders.map(({ number }) => number),
        hideSelectAll: true
      }}
      rowClassName="table-row"
      onRow={(record) => ({
        onClick: (e) => {
          const isCheckbox = 'checked' in e.target;

          if (!isCheckbox) {
            navigate(
              `/dashboard/dispatcher/order/${record.type}-${record.id}${window.location.search}`
            );
            setOpenOrder({ id: record.id, type: record.type });
            setDrawerOpen('order');
          }
        }
      })}
      summary={(orders) => {
        if (orders.length === DEFAULT_LIMIT) {
          return (
            <Table.Summary>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={9}>
                  <Flex
                    align="center"
                    justify="center"
                    style={{ height: 100, width: '100%', textAlign: 'center', color: '#00000073' }}>
                    {DEFAULT_LIMIT} orders are displayed. <br />
                    Change the filters to find the one you are looking for.
                  </Flex>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }
      }}
    />
  );
};
