/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Error,
  ValidationError,
  VatRate,
  VatRateInput,
  VatRateListResponse
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class VatRates<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Get a list of VAT Rate records
   *
   * @tags VAT Rates
   * @name GetAllVatRates
   * @summary Get a list of VAT Rate records
   * @request GET:/vat-rates
   * @secure
   */
  getAllVatRates = (params: RequestParams = {}) =>
    this.http.request<VatRateListResponse, Error>({
      path: `/vat-rates`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Create a new VAT Rate record
   *
   * @tags VAT Rates
   * @name AddVatRate
   * @summary Create a new VAT Rate record
   * @request POST:/vat-rates
   * @secure
   */
  addVatRate = (data: VatRateInput, params: RequestParams = {}) =>
    this.http.request<VatRate, ValidationError | Error>({
      path: `/vat-rates`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Get a specific VAT Rate record by id
   *
   * @tags VAT Rates
   * @name GetVatRateById
   * @summary Get a specific VAT Rate record
   * @request GET:/vat-rates/{vatRateId}
   * @secure
   */
  getVatRateById = (vatRateId: number, params: RequestParams = {}) =>
    this.http.request<VatRate, Error>({
      path: `/vat-rates/${vatRateId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Update a specific VAT Rate document by id
   *
   * @tags VAT Rates
   * @name UpdateVatRate
   * @summary Update a specific VAT Rate document
   * @request PUT:/vat-rates/{vatRateId}
   * @secure
   */
  updateVatRate = (vatRateId: number, data: VatRateInput, params: RequestParams = {}) =>
    this.http.request<VatRate, ValidationError | Error>({
      path: `/vat-rates/${vatRateId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Delete a specific VAT Rate record by id
   *
   * @tags VAT Rates
   * @name DeleteVatRate
   * @summary Delete a specific VAT Rate record
   * @request DELETE:/vat-rates/{vatRateId}
   * @secure
   */
  deleteVatRate = (vatRateId: number, params: RequestParams = {}) =>
    this.http.request<void, ValidationError | Error>({
      path: `/vat-rates/${vatRateId}`,
      method: 'DELETE',
      secure: true,
      ...params
    });
}
