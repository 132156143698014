import { DATE_FORMAT } from 'consts/common';
import { PaymentPurposeHydrated } from 'services/api/data-contracts';
import { PaymentPurposeEditModeBase } from 'store/paymentPurposeStore';
import { getUTCDate } from 'helpers';
import { PaymentPurposeWithContract } from '../../PaymentDrawer/PaymentDetails/PaymentPurposeBlock/columns';

export const getTitle = (
  editModeBase: PaymentPurposeEditModeBase,
  paymentPurpose: PaymentPurposeHydrated | PaymentPurposeWithContract
) => {
  switch (editModeBase) {
    case PaymentPurposeEditModeBase.INVOICE:
      const paymentPurposeHydrated = paymentPurpose as PaymentPurposeHydrated;
      return `Payment purpose | ${paymentPurposeHydrated.id} | ${
        paymentPurposeHydrated.payment.posted ? 'Posted' : 'Not posted'
      } | ${getUTCDate(paymentPurpose.createdAt).format(DATE_FORMAT)}`;

    case PaymentPurposeEditModeBase.PAYMENT:
      const paymentPurposeWithContract = paymentPurpose as PaymentPurposeWithContract;
      return `Payment purpose | ${paymentPurposeWithContract.id} | ${
        paymentPurposeWithContract.invoice?.isPosted ? 'Posted' : 'Not posted'
      } | ${getUTCDate(paymentPurposeWithContract.createdAt).format(DATE_FORMAT)}`;

    default:
      return 'Payment purpose';
  }
};
