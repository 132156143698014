import { useQuery } from '@tanstack/react-query';
import { ordersApi } from 'services/api';
import { QUERY_KEY } from './consts';
import { useOrderStore } from 'store/orderStore';
import { OrderType } from 'services/api/data-contracts';

const fetchList = async (orderId: number, orderType: OrderType) =>
  await ordersApi.getOrderServicesByOrder(orderId, orderType);

export const useGetOrderServicesByOrder = (orderId: number, orderType: OrderType) => {
  const { setServices } = useOrderStore(({ setServices }) => ({
    setServices
  }));

  return useQuery({
    queryKey: [QUERY_KEY, orderId],
    queryFn: () => fetchList(orderId, orderType),
    onSuccess: async (response) => {
      setServices(response.data.items.map((item) => ({ ...item, orderType, orderId })));
    },
    keepPreviousData: true
  });
};
