import React, { useEffect } from 'react';
import { OrderService } from 'services/api/data-contracts';
import { getColumns } from './columns';
import { Button, Empty, Table } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import styled from 'styled-components';
import { useOrderStore } from 'store/orderStore';
import { useGetServices } from 'queries/service';
import { OrderServicesBulkOperationsPopover } from '../../../../../components/ServicesBulkOperationsPopover/OrderServicesBulkOperationsPopover';
import { PlusOutlined } from '@ant-design/icons';

const StyledTable = styled(Table<OrderService>)`
  #actions {
    display: none;
    position: absolute;
    right: 0;
  }

  #actions > button {
    margin-right: 4px;
  }

  .ant-table-cell-row-hover #actions {
    display: block;
  }

  .ant-table-summary .ant-table-cell {
    padding-left: 0 !important;
    border-bottom: none;
  }
`;

interface IProps {
  services: OrderService[];
  setAddServiceDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InvoiceServicesView = ({ services, setAddServiceDrawerOpen }: IProps) => {
  const { setService, selectedServices, setSelectedServices } = useOrderStore(
    ({ setService, selectedServices, setSelectedServices }) => ({
      setService,
      selectedServices,
      setSelectedServices
    })
  );

  useEffect(() => () => setSelectedServices([]), []);

  const dictionaryServicesListQuery = useGetServices();

  const handleOpenDetails = (service: OrderService) => {
    setService(service);
  };

  const columns = getColumns(handleOpenDetails);
  const data = services.map((service) => ({ ...service, key: service.id }));

  const addNewButton = (type: 'link' | 'default') => (
    <Button
      type={type}
      size={CONTROL_SIZE}
      onClick={() => setAddServiceDrawerOpen(true)}
      icon={type === 'link' && <PlusOutlined />}>
      Add a new one
    </Button>
  );

  if (!data.length) {
    return (
      <Empty description="Services have not been added yet" image={Empty.PRESENTED_IMAGE_SIMPLE}>
        {addNewButton('default')}
      </Empty>
    );
  }

  return (
    <>
      <OrderServicesBulkOperationsPopover placement="bottomLeft" align={{ offset: [-374, 0] }} />

      <StyledTable
        columns={columns}
        dataSource={data}
        size={CONTROL_SIZE}
        pagination={false}
        data-testid="invoice-services-view"
        summary={() => (
          <Table.Summary fixed="bottom">
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={9} index={0}>
                {addNewButton('link')}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
        loading={dictionaryServicesListQuery.isLoading}
        rowSelection={{
          onSelect: ({ id }, isSelected) => {
            setSelectedServices(
              isSelected
                ? [...selectedServices, id]
                : selectedServices.filter((serviceId) => serviceId !== id)
            );
          },
          selectedRowKeys: selectedServices,
          hideSelectAll: true
        }}
      />
    </>
  );
};
