import { create } from 'zustand';
import { Airport } from 'services/api/data-contracts';
import { devtools } from 'zustand/middleware';

type AirportStore = {
  current?: Airport;
  setCurrent: (current?: Airport) => void;
  list: Airport[];
  setList: (data: { items: Airport[]; total: number }) => void;
  page: number;
  setPage: (page: number) => void;
  total: number;
};

export const useAirportStore = create<AirportStore>()(
  devtools((set) => ({
    current: undefined,
    setCurrent: (current) => {
      set({ current });
    },
    list: [],
    setList: ({ items: list, total }) => {
      set({ list, total });
    },
    page: 1,
    setPage: (page) => {
      set({ page });
    },
    total: 0
  }))
);
