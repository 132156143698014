import { useQuery } from '@tanstack/react-query';
import { messagesApi } from 'services/emailEngineApi';
import { useMessageStore } from 'store/messageStore';
import { MESSAGE_QUERY_KEY } from './consts';
import { settingsStore } from 'services/settings/SettingsStore';

const fetchMessage = async (id: string) => {
  try {
    return await messagesApi.getV1AccountAccountMessageMessage(
      settingsStore.getMailboxSettings()?.mailbox_name || '',
      id,
      {
        textType: '*',
        webSafeHtml: true,
        embedAttachedImages: true,
        documentStore: true
      }
    );
  } catch (error) {
    console.error('Error fetching message:', error);
    throw error;
  }
};

export const useGetMessage = (id?: string, enabled = true) => {
  const { setCurrentMessage } = useMessageStore(({ setCurrentMessage }) => ({
    setCurrentMessage
  }));

  return useQuery({
    queryKey: [MESSAGE_QUERY_KEY, id],
    queryFn: async () => {
      if (id) {
        return await fetchMessage(id);
      }
    },
    onSuccess: (response) => {
      setCurrentMessage(response?.data ? response.data : undefined);
    },
    onError: (error) => {
      console.log(error);
    },
    enabled
  });
};
