import React, { useContext } from 'react';
import { useOrderStore } from 'store/orderStore';
import { GroundHandlingOrder, OrderType } from 'services/api/data-contracts';
import { Flex, Form } from 'antd';
import { GeneralInfoBlock, OrderDetailsBlock, OrderServicesBlock } from './accordions';
import { FormInstance } from 'antd/es/form';
import { OrderStatusSelect } from './common';
import { FormContext } from '../OrderDrawer';
import { GHOrderInputWithDayJs } from '../../../DispatcherOrdersWidget/components/utils/ghOrderUtils';
import { useFormUpdate } from '../../../DispatcherOrdersWidget/helpers/useFormUpdate';

export const GHOrderDetails = () => {
  const { form: formInstance } = useContext(FormContext);
  const form = formInstance as FormInstance<GHOrderInputWithDayJs>;

  const { current } = useOrderStore(({ current }) => ({
    current: current as GroundHandlingOrder
  }));

  const handleUpdateForm = useFormUpdate<GHOrderInputWithDayJs>(form, OrderType.GH);

  return (
    <Form
      style={{ justifyContent: 'center' }}
      layout="vertical"
      requiredMark="optional"
      form={form}
      autoComplete="off"
      colon={false}
      onValuesChange={handleUpdateForm}
      className="styled-form">
      <Flex gap={8} vertical>
        <OrderStatusSelect />
        <GeneralInfoBlock />
        <OrderDetailsBlock orderType={OrderType.GH} />
        <OrderServicesBlock order={current} orderType={OrderType.GH} />
      </Flex>
    </Form>
  );
};
