import { create } from 'zustand';
import { Country } from 'services/api/data-contracts';
import { devtools } from 'zustand/middleware';

type CountryStore = {
  current?: Country;
  setCurrent: (current?: Country) => void;
  list: Country[];
  setList: (data: { items: Country[]; total: number }) => void;
  page: number;
  setPage: (page: number) => void;
  total: number;
};

export const useCountryStore = create<CountryStore>()(
  devtools((set) => ({
    current: undefined,
    setCurrent: (current) => {
      set({ current });
    },
    list: [],
    setList: ({ items: list, total }) => {
      set({ list, total });
    },
    page: 1,
    setPage: (page) => {
      set({ page });
    },
    total: 0
  }))
);
