import React, { useMemo, useState } from 'react';
import { Button, Row, Table } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { useDeleteContractService, useGetContractServices } from 'queries/contract';
import { useContractStore } from 'store/contractStore';
import { getColumns } from './columns';
import { ContractServiceView } from './ContractServiceView';
import { SupplierService } from 'services/api/data-contracts';
import { PlusOutlined } from '@ant-design/icons';

interface IProps {
  contractId: number;
}

export const ContractServices = ({ contractId }: IProps) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const { services, setService } = useContractStore(({ services, setService }) => ({
    services,
    setService
  }));

  const serviceListQuery = useGetContractServices(contractId);
  const deleteMutation = useDeleteContractService(contractId);

  const columns = useMemo(() => getColumns(deleteMutation.mutate), [deleteMutation.mutate]);
  const dataSource = useMemo(
    () =>
      services.map((contractService) => ({
        ...contractService,
        key: contractService.id
      })),
    [services]
  );

  const handleAdd = () => {
    setService({
      contractId
    });
    setDrawerOpen(true);
  };

  const handleOpenDetails = (service: SupplierService) => {
    setService(service);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setService(undefined);
  };

  return (
    <>
      <Row justify="end" style={{ marginBottom: 12 }}>
        <Button
          size={CONTROL_SIZE}
          type="dashed"
          onClick={handleAdd}
          loading={serviceListQuery.isLoading}
          icon={<PlusOutlined />}>
          Add
        </Button>
      </Row>
      <Table
        columns={columns}
        dataSource={dataSource}
        onRow={(record) => ({
          onClick: ({ target }) => {
            const isButton = (target as HTMLElement).closest('button');

            if (!isButton) {
              handleOpenDetails(record);
            }
          }
        })}
        size={CONTROL_SIZE}
        loading={serviceListQuery.isLoading || deleteMutation.isLoading}
        pagination={false}
      />

      <ContractServiceView isOpen={isDrawerOpen} onClose={handleCloseDrawer} />
    </>
  );
};
