import React from 'react';
import { Button, Flex, Form, Input } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { FormInstance } from 'antd/es/form';
import { useInvoiceStore } from 'store/invoiceStore';
import { CreditNoteInput, PaymentHydrated } from 'services/api/data-contracts';
import { PlusOutlined } from '@ant-design/icons';
import { CreditNoteItemsForm } from './CreditNoteItemsForm';

interface IProps {
  form: FormInstance<Partial<PaymentHydrated> & { creditNoteItems: CreditNoteInput[] }>;
}

export const CreditNoteForm = ({ form }: IProps) => {
  const { currentCreditNote: creditNote } = useInvoiceStore(({ currentCreditNote }) => ({
    currentCreditNote
  }));

  return (
    <Form
      form={form}
      colon={false}
      size={CONTROL_SIZE}
      requiredMark={false}
      layout="vertical"
      initialValues={creditNote}
      autoComplete="off"
      className="styled-form">
      <Form.List name="creditNoteItems">
        {(rows, { add, remove }) => {
          return (
            <Flex vertical gap={CONTROL_SIZE}>
              {rows.map(({ key, name }) => (
                <CreditNoteItemsForm formListItemKey={name} key={key} remove={remove} form={form} />
              ))}
              <Button
                type="link"
                style={{ alignSelf: 'flex-start' }}
                onClick={() => add()}
                icon={<PlusOutlined />}>
                Add a new one
              </Button>
            </Flex>
          );
        }}
      </Form.List>

      <Form.Item label="Note" name="notes" style={{ flexGrow: 1, marginTop: '20px' }}>
        <Input.TextArea size={CONTROL_SIZE} autoSize={{ minRows: 1, maxRows: 30 }} />
      </Form.Item>
    </Form>
  );
};
