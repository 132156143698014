import React from 'react';
import { Descriptions, DescriptionsProps, Flex } from 'antd';
import { CONTROL_SIZE, DATE_TIME_FORMAT } from 'consts/common';
import { Flight } from 'services/api/data-contracts';
import { getUTCDate } from 'helpers';

interface IProps {
  flight: Flight;
}

export const FlightDetailsView = ({ flight }: IProps) => {
  const generalDescriptions: DescriptionsProps['items'] = [
    {
      label: 'Operator',
      children: flight.operator?.name
    },
    {
      label: 'Flight №',
      children: flight.flightNumber
    },
    {
      label: 'Aircraft',
      children: (
        <span>
          <span>{flight.aircraft?.tailNumber}</span>
          <span style={{ color: '#bfbfbf' }}>
            {' | '}
            {[
              flight.aircraft?.type,
              flight.aircraft?.maxTakeoffWeight + ' kg',
              flight.aircraft?.country?.name
            ]
              .filter((i) => !!i)
              .join(' | ')}
          </span>
        </span>
      )
    }
  ];

  const departureDescriptions: DescriptionsProps['items'] = [
    {
      label: 'Airport',
      children: flight.departureAirport?.code
    },
    {
      label: 'Country',
      children: flight.departureAirport?.city?.country?.name
    },
    {
      label: 'Estimated D&T',
      children: getUTCDate(flight.estimatedTimeDeparture).format(DATE_TIME_FORMAT)
    },
    {
      label: 'Actual D&T',
      children: flight.actualTimeDeparture
        ? getUTCDate(flight.actualTimeDeparture).format(DATE_TIME_FORMAT)
        : '-'
    }
  ];

  const arrivalDescriptions: DescriptionsProps['items'] = [
    {
      label: 'Airport',
      children: flight.arrivalAirport?.code
    },
    {
      label: 'Country',
      children: flight.arrivalAirport?.city?.country?.name
    },
    {
      label: 'Estimated D&T',
      children: getUTCDate(flight.estimatedTimeArrival).format(DATE_TIME_FORMAT)
    },
    {
      label: 'Actual D&T',
      children: flight.actualTimeArrival
        ? getUTCDate(flight.actualTimeArrival).format(DATE_TIME_FORMAT)
        : '-'
    }
  ];

  const additionalDescriptions: DescriptionsProps['items'] = [
    {
      label: 'Route',
      children: flight.route || '-',
      span: 2
    },
    {
      label: 'Overflight countries',
      children: flight.overflightCountries?.map((country) => country.name).join(', ') || '-',
      span: 2
    },
    {
      label: 'Alternative airports',
      children: flight.alternativeAirports?.map((airport) => airport.code).join(', ') || '-',
      span: 2
    },
    {
      label: 'Flight type',
      children: flight.flightType?.name || '-'
    },
    {
      label: 'Flight purpose',
      children: flight.flightPurpose?.name || '-'
    }
  ];

  return (
    <Flex gap={8} vertical data-testid="flight-details-view">
      <Descriptions items={generalDescriptions} size={CONTROL_SIZE} column={2} bordered />
      <Descriptions
        title="Departure"
        items={departureDescriptions}
        size={CONTROL_SIZE}
        column={2}
        bordered
      />
      <Descriptions
        title="Arrival"
        items={arrivalDescriptions}
        size={CONTROL_SIZE}
        column={2}
        bordered
      />
      <Descriptions
        items={additionalDescriptions}
        size={CONTROL_SIZE}
        column={2}
        bordered
        style={{ marginTop: '8px' }}
      />
    </Flex>
  );
};
