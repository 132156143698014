import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ConfigProvider, Empty, List } from 'antd';
import styled from 'styled-components';
import { MessagesListItem } from './MessagesListItem';
import { EmailAccountSignatures, useMessageStore } from 'store/messageStore';
import { HeaderWithRef } from '../components/Header';
import { useDashboardStore } from 'store/dashboardStore';
import { AccountTemplateResponse, MessageListEntry } from 'services/emailEngineApi/data-contracts';
import { useFlightsDashboardStore } from 'store/flightsDashboardStore';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import VirtualList from 'rc-virtual-list';
import { useGetMessages } from 'queries/messages/useGetMessages';
import { debounce } from 'lodash';
import { useOptimisticUpdateMessage } from 'queries/messages/useOptimisticUpdateMessage';
import { FLAG, CONTENT_HEIGHT_FOR_REFETCH, FILTER_LINKS } from './consts';
import {
  DASHBOARD_TOP_PADDING,
  DashboardContext,
  LIST_FOOTER_BULK_BLOCK_HEIGHT
} from 'pages/consts';
import { useGetLinks } from 'queries/links/useGetLinks';
import { MessagesListFooter } from './components/MessagesListFooter/MessagesListFooter';
import { getBackgroundColor } from './helpers/getBackgroundColor';
import { CONTROL_SIZE } from 'consts/common';
import { useGetMessageTemplates } from 'queries/messages/useGetMessageTemplates';
import { useGetMessageSignaturesContent } from 'queries/messages/useGetMessageSignaturesContent';
import { AxiosResponse } from 'axios';
import useBulkSelection from './helpers/useBulkSelection';
import { useGetAllMailPath } from 'queries/messages/useGetAllMailPath';
import { useFlightProgramStore } from 'store/flightProgramStore';
import { useOrderStore } from 'store/orderStore';
import { useDrawerStore } from 'store/drawerStore';

interface IProps {
  isDrawerVisible: boolean;
}

interface StyledListItemProps {
  $isExpanded: boolean;
  $isLinked: boolean;
}

const StyledList = styled(List)`
  height: 100%;
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .ant-list-item {
    padding: 8px 0;
  }

  .ant-list-header {
    padding: 0 12px 8px !important;
  }

  .ant-list-footer {
    background-color: #fafafa;
    border-top: 1px solid #d9d9d9;
    z-index: 100;
    padding: 8px 12px;
  }

  .ant-spin-nested-loading:has(.ant-spin-container > .ant-list-empty-text) {
    flex-grow: 1;
  }

  .ant-spin-container.ant-spin-blur {
    height: calc(100vh - 169px);
  }

  .ant-spin-container.ant-spin-blur > div {
    min-height: 0 !important;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

const StyledListItem = styled(List.Item)<StyledListItemProps>`
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    background-color: ${({ $isExpanded, $isLinked }) =>
      $isExpanded && $isLinked ? '#d9f7be !important' : '#fafafa !important'};
  }
`;

export const MessagesList = ({ isDrawerVisible }: IProps) => {
  const { type: dashboardType } = useContext(DashboardContext);
  const [shouldMessagesUpdate, setMessagesUpdate] = useState(true);

  const { selectedMessages, handleSelectMessage } = useBulkSelection();
  const {
    messages,
    setSelectedMessages,
    messagesListItemsCount,
    setCurrentMessage,
    setOpenMessageId,
    openMessageId,
    setList,
    messagesFilter,
    setSignatures,
    newMessageMode
  } = useMessageStore(
    ({
      list,
      setSelectedMessages,
      messagesListItemsCount,
      setCurrentMessage,
      setOpenMessageId,
      openMessageId,
      setList,
      messagesFilter,
      setSignatures,
      newMessageMode
    }) => ({
      messages: list,
      setSelectedMessages,
      messagesListItemsCount,
      setCurrentMessage,
      setOpenMessageId,
      openMessageId,
      setList,
      messagesFilter,
      setSignatures,
      newMessageMode
    })
  );
  const { selectedFlights, setSelectedFlights } = useFlightsDashboardStore(
    ({ selectedFlights, setSelectedFlights }) => ({
      selectedFlights,
      setSelectedFlights
    })
  );
  const { selectedOrders, setSelectedOrders } = useOrdersDashboardStore(
    ({ selectedOrders, setSelectedOrders }) => ({ selectedOrders, setSelectedOrders })
  );
  const { selectedPrograms } = useFlightProgramStore(({ selectedPrograms }) => ({
    selectedPrograms
  }));
  const { orderProgramId } = useOrderStore(({ current }) => ({
    orderProgramId: current?.programId
  }));
  const { isProgramDrawerOpen } = useDrawerStore(({ openDrawers }) => ({
    isProgramDrawerOpen: openDrawers.includes('flightProgram')
  }));
  const {
    isFilterByFlights,
    isFilterByOrders,
    isFilterByPrograms,
    isFilterByLinkedProgram,
    setLinkFilters
  } = useDashboardStore(({ linkFilters, setLinkFilters }) => ({
    isFilterByFlights: linkFilters.includes('flight-message') && selectedFlights.length > 0,
    isFilterByOrders: linkFilters.includes('order-message') && selectedOrders.length > 0,
    isFilterByPrograms: linkFilters.includes('program-message') && selectedPrograms.length > 0,
    isFilterByLinkedProgram:
      !!orderProgramId && linkFilters.includes('order-message') && selectedOrders.length > 0,
    setLinkFilters
  }));
  const { setLinkedMessagesIdsFilter } = useMessageStore(({ setLinkedMessagesIdsFilter }) => ({
    setLinkedMessagesIdsFilter
  }));

  const footerHeight = useMemo(
    () => (selectedMessages.length > 0 ? LIST_FOOTER_BULK_BLOCK_HEIGHT : 0),
    [selectedMessages]
  );

  const [containerHeight, setContainerHeight] = useState(
    window.innerHeight - DASHBOARD_TOP_PADDING - footerHeight
  );

  useEffect(() => {
    setList([]);
    setSelectedMessages([]);

    setSelectedFlights([]);
    setSelectedOrders([]);
    setLinkFilters([]);
  }, [dashboardType]);

  useEffect(() => {
    setMessagesUpdate(!newMessageMode);
  }, [newMessageMode]);

  useEffect(() => {
    const resize = debounce(setListHeight, 100);
    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, []);

  useEffect(() => {
    setListHeight();
  }, [
    selectedMessages,
    selectedOrders,
    selectedFlights,
    isFilterByOrders,
    isFilterByFlights,
    isFilterByPrograms,
    isDrawerVisible
  ]);

  const { isSuccess, isLoading: areTemplatesLoading, data } = useGetMessageTemplates();
  const signaturesContentQuery = useGetMessageSignaturesContent(
    isSuccess && data.data && !!data.data.templates?.length
  );
  const isLoading = areTemplatesLoading || signaturesContentQuery.some((query) => query.isLoading);

  useEffect(() => {
    // Signatures reset for super admin when changing from dispatcher to settlement
    if (isSuccess && data.data.templates?.length === 0) {
      setSignatures([]);
    }

    if (data && !!data.data.templates?.length && !isLoading) {
      const updatedSignatures = signaturesContentQuery
        .filter(({ isSuccess }) => isSuccess)
        .map(({ data: axiosData }) => {
          const {
            data: { id, name, format, created, updated, description, content }
          } = axiosData as AxiosResponse<AccountTemplateResponse, unknown>;

          return { id, name, format, created, updated, description, text: content?.text };
        });

      setSignatures(updatedSignatures as EmailAccountSignatures);
    }
  }, [isLoading, data, isSuccess]);

  useGetAllMailPath();

  useGetLinks(
    {
      ...(isFilterByOrders ? { orderNumbers: selectedOrders.map((o) => o.number).join(',') } : {}),
      ...(isFilterByFlights ? { flightIds: selectedFlights.map((f) => f.id).join(',') } : {}),
      ...(isFilterByPrograms
        ? {
            programIds: selectedPrograms.map((p) => p.id).join(',')
          }
        : {}),
      ...(isFilterByLinkedProgram && isProgramDrawerOpen
        ? {
            programIds: orderProgramId?.toString()
          }
        : {})
    },
    isFilterByOrders || isFilterByFlights || isFilterByPrograms
  );
  const { isFetching, fetchNextPage, hasNextPage } = useGetMessages(shouldMessagesUpdate);
  const updateMessageMutation = useOptimisticUpdateMessage();

  useEffect(() => {
    if (!isFilterByFlights && !isFilterByOrders && !isFilterByPrograms) {
      setLinkedMessagesIdsFilter([]);
    }
  }, [isFilterByFlights, isFilterByOrders, isFilterByPrograms]);

  const headerRef = useRef<HTMLDivElement | null>(null);

  const setListHeight = () => {
    const headerHeight = headerRef.current?.clientHeight ? headerRef.current?.clientHeight + 9 : 0;
    const newMessageDrawerHeight = isDrawerVisible ? 326 : 0;
    setContainerHeight(
      window.innerHeight -
        DASHBOARD_TOP_PADDING -
        headerHeight -
        newMessageDrawerHeight -
        footerHeight
    );
  };

  const handleExpandAndMarkRead = (id?: string) => {
    setCurrentMessage(undefined);
    setOpenMessageId(id);

    const message = messages.find((m) => m.id === id);
    const hasSeenStatus = message?.flags?.includes(FLAG.SEEN);

    if (!message || hasSeenStatus) {
      return;
    }

    if (id) {
      updateMessageMutation.mutate({
        messageId: id,
        data: {
          flags: {
            add: [FLAG.SEEN]
          }
        }
      });
    }
  };

  const handleScroll = ({
    currentTarget: { scrollHeight, scrollTop }
  }: React.UIEvent<HTMLElement, UIEvent>) => {
    const isOnBottom = Math.floor(scrollHeight - scrollTop) === containerHeight;

    setMessagesUpdate(scrollTop <= CONTENT_HEIGHT_FOR_REFETCH);

    if (isOnBottom && !isFetching && hasNextPage) {
      fetchNextPage();
    }
  };

  const header =
    isFilterByFlights || isFilterByOrders || isFilterByPrograms ? (
      <HeaderWithRef
        ref={headerRef}
        showFlightTags={isFilterByFlights}
        showOrderTags={isFilterByOrders}
        showProgramTags={isFilterByPrograms}
        total={messagesListItemsCount}
      />
    ) : null;

  if (!messages.length && !isLoading) {
    return (
      <ConfigProvider
        renderEmpty={() => (
          <Empty
            description={
              messagesFilter?.links === FILTER_LINKS.WITHOUT_LINKS ? (
                <>
                  <div>Well done!</div>
                  <div>There are no more unprocessed messages left!</div>
                </>
              ) : (
                'Messages not found'
              )
            }
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}>
        <StyledList
          dataSource={messages}
          size={CONTROL_SIZE}
          header={header}
          footer={<MessagesListFooter />}
          bordered
          loading={isFetching}
          data-testid="list"
        />
      </ConfigProvider>
    );
  }

  return (
    <StyledList
      footer={<MessagesListFooter />}
      header={header}
      bordered
      loading={!messages.length && (isFetching || isLoading)}
      style={{ paddingTop: isDrawerVisible ? '326px' : 0 }}>
      <VirtualList
        data={messages}
        height={containerHeight}
        itemHeight={82}
        itemKey={(message: MessageListEntry) => `message-${message.id}`}
        onScroll={handleScroll}
        data-testid="virtual-list">
        {(message: MessageListEntry) => (
          <StyledListItem
            key={`message-${message.id}`}
            $isExpanded={openMessageId === message.id}
            $isLinked={message.flags?.includes(FLAG.LINKED) ?? false}
            style={{
              backgroundColor: getBackgroundColor(
                !!selectedMessages.find((selected) => selected.id === message.id),
                message.flags?.includes(FLAG.LINKED) ?? false
              )
            }}>
            <MessagesListItem
              message={message}
              isExpanded={openMessageId === message.id}
              onExpand={handleExpandAndMarkRead}
              onSelect={handleSelectMessage}
              selectedMessages={selectedMessages}
            />
          </StyledListItem>
        )}
      </VirtualList>
    </StyledList>
  );
};
