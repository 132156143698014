import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { contractsApi } from 'services/api';
import { Contract } from 'services/api/data-contracts';
import { ENTITY_NAME, QUERY_KEY } from './consts';
import { MESSAGE_DURATION, SERVER_DATE_FORMAT } from 'consts/common';
import dayjs from 'dayjs';

const createItem = async (data: Contract) =>
  await contractsApi.addContract({
    ...data,
    startDate: dayjs(data.startDate).format(SERVER_DATE_FORMAT),
    endDate: data.endDate ? dayjs(data.endDate).format(SERVER_DATE_FORMAT) : undefined
  });

export const useCreateContract = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createItem,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });

      message.success(`${ENTITY_NAME} has been successfully created`, MESSAGE_DURATION);

      onSuccess && onSuccess();
    }
  });
};
