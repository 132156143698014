import React from 'react';
import { Button, Flex, Typography } from 'antd';
import { DownloadOutlined as Download } from '@ant-design/icons';

interface IProps {
  handleDownload?: () => void;
}

export const UnsupportedFileMessage = ({ handleDownload }: IProps) => (
  <Flex vertical style={{ height: '100%' }} justify="center" align="center">
    <Flex>
      <Typography.Text>Current file format is not supported for preview.</Typography.Text>
    </Flex>
    <Flex style={{ textAlign: 'center' }}>
      <Typography.Text>
        Please download <Button onClick={handleDownload} size="middle" icon={<Download />} /> the
        file.
      </Typography.Text>
    </Flex>
  </Flex>
);
