import { Descriptions, DescriptionsProps } from 'antd';
import React, { useContext } from 'react';
import { DataContext } from '../DictionaryDetails';
import { CONTROL_SIZE } from 'consts/common';
import { Airport } from 'services/api/data-contracts';

export const AirportDictionaryView = () => {
  const data = useContext(DataContext) as Airport;

  if (!data) {
    return null;
  }

  const descriptions: DescriptionsProps['items'] = [
    {
      label: 'Name',
      span: 2,
      children: data.name
    },
    {
      label: 'IATA',
      children: data.iata || '-'
    },
    {
      label: 'ICAO',
      children: data.icao || '-'
    },
    {
      label: 'Local code',
      children: data.localCode || '-'
    },
    {
      label: 'City',
      children: data.city?.name
    },
    {
      label: 'Latitude',
      children: data.latitude || '-'
    },
    {
      label: 'Longitude',
      children: data.longitude || '-'
    },
    {
      label: 'Working hours',
      span: 2,
      children: data.workingHours || '-'
    }
  ];

  return <Descriptions size={CONTROL_SIZE} items={descriptions} column={2} bordered />;
};
