import { Rule } from 'antd/es/form';

export const FORM_FIELD_NAMES = {
  to: 'to',
  copy: 'copy',
  subject: 'subject',
  body: 'body'
};

export const ACTION = {
  REPLY: 'reply',
  FORWARD: 'forward',
  REPLY_ALL: 'replyAll',
  NEW: 'new',
  FROM_ORDER: 'fromOrder',
  WITH_INVOICE_ATTACHMENT: 'withInvoiceAttachment',
  WITH_PAYMENT_ATTACHMENT: 'withPaymentAttachment'
};

export type AutoPrefix = (typeof AUTO_PREFIX)[keyof typeof AUTO_PREFIX];

export const AUTO_PREFIX = {
  REPLY: 'Re:',
  FORWARD: 'Fwd:'
} as const;

const emailRule: Rule = {
  validator: (_, value) =>
    value === undefined ||
    (Array.isArray(value) &&
      value.every((email) => /^[\w-.]+@([\w -]+\.)+[\w -]{2,4}$/.test(email)))
      ? Promise.resolve()
      : Promise.reject(new Error('Please enter a valid email address'))
};

export const FORM_VALIDATION_RULES: Record<string, Rule[]> = {
  to: [
    {
      required: true,
      message: 'Please provide recipient e-mail'
    },
    emailRule
  ],
  copy: [emailRule],
  subject: [{ required: true, message: 'Subject is required' }]
};
