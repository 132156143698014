import React, { useContext, useMemo } from 'react';
import { CONTROL_SIZE } from 'consts/common';
import styled from 'styled-components';
import { Flex, Skeleton } from 'antd';
import { GroundHandlingOrder, OrderType } from 'services/api/data-contracts';
import { getOrderIcon } from 'helpers';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { useOrderStore } from 'store/orderStore';
import { BsCalendarWeek } from 'react-icons/bs';
import { useFlightProgramStore } from 'store/flightProgramStore';
import { useDrawerStore } from 'store/drawerStore';
import { useNavigate } from 'react-router-dom';
import { OrderStatusTag } from '../../DispatcherOrdersWidget/components/OrderStatusTag';
import { DashboardContext, DashboardType } from '../../../consts';

const TitleWrapper = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const OrderDrawerTitle = () => {
  const { isOrderEditMode } = useOrdersDashboardStore(({ isOrderEditMode }) => ({
    isOrderEditMode
  }));
  const { current } = useOrderStore(({ current }) => ({ current }));
  const { setOpenProgramId } = useFlightProgramStore();
  const { setDrawerOpen, setDrawerClosed } = useDrawerStore();

  const { type: dashboardType } = useContext(DashboardContext);
  const navigate = useNavigate();

  return useMemo(() => {
    if (!current) return <Skeleton.Input size={CONTROL_SIZE} style={{ width: 250 }} />;

    if (isOrderEditMode) {
      return (
        <TitleWrapper>
          {getOrderIcon(current.type as OrderType, '#00000073')}
          <span>Order {current?.id ? 'editing' : 'creation'}</span>
        </TitleWrapper>
      );
    }

    const programId = (current as GroundHandlingOrder).programId;

    return (
      <TitleWrapper>
        <OrderStatusTag status={current.status} />
        <Flex style={{ marginLeft: '10px' }} align="center">
          {getOrderIcon(current.type as OrderType, '#00000073')}
        </Flex>
        <span>{current.type}</span>
        <span>{current.number}</span>

        {programId && (
          <Flex
            gap={8}
            align="center"
            style={{ color: '#00000073', fontSize: 14, fontWeight: 400 }}>
            <span>|</span>
            <BsCalendarWeek />
            <a
              style={{ color: '#00000073', textDecoration: 'underline' }}
              onClick={() => {
                if (dashboardType !== DashboardType.Dispatcher) return;

                setDrawerClosed('order');
                navigate(
                  `/dashboard/dispatcher/flightProgram/${programId}${window.location.search}`
                );
                setOpenProgramId(programId);
                setDrawerOpen('flightProgram');
              }}>
              Program {programId}
            </a>
          </Flex>
        )}
      </TitleWrapper>
    );
  }, [current, isOrderEditMode]);
};
