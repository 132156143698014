import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { ServiceName } from 'services/api/data-contracts';

type ServiceNameStore = {
  current?: ServiceName;
  setCurrent: (current?: ServiceName) => void;
  list: ServiceName[];
  setList: (current: ServiceName[]) => void;
};

export const useServiceNameStore = create<ServiceNameStore>()(
  devtools((set) => ({
    current: undefined,
    setCurrent: (current) => {
      set({ current });
    },
    list: [],
    setList: (list) => {
      set({ list });
    }
  }))
);
