import React from 'react';
import { Result } from 'antd';
import { isAxiosError } from 'axios';

type IProps = {
  message: string;
  children: React.ReactElement;
};

type IState = {
  hasError: boolean;
  message?: string;
  errorCode?: string;
  errorStatus?: number;
};

export class DetailsErrorBoundary extends React.Component<IProps, IState> {
  static handledErrors = new Set();

  constructor(props: IProps) {
    super(props);
    this.state = {
      hasError: false,
      errorCode: undefined,
      message: undefined,
      errorStatus: undefined
    };
  }

  static getDerivedStateFromError(error: Error) {
    if (isAxiosError(error)) {
      return {
        hasError: true,
        errorStatus: error.response?.status,
        errorCode:
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          error.response?.headers?.['X-Amzn-Trace-Id'] || error.response?.data?.correlationId,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        message: error.response?.data?.message
      };
    }
  }

  componentDidCatch(error: Error) {
    DetailsErrorBoundary.handledErrors.add(error);
  }

  componentWillUnmount() {
    this.setState({
      hasError: false,
      errorCode: undefined,
      message: undefined,
      errorStatus: undefined
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Result
          status={this.state.errorStatus === 404 ? '404' : 'error'}
          title={this.state.message}
          subTitle={
            <>
              {this.state.errorStatus === 404 && (
                <>
                  <span>{this.props.message}</span>
                  <br />
                </>
              )}
              <span>{this.state.errorCode?.replace('Root=', '')}</span>
            </>
          }
        />
      );
    }

    return this.props.children;
  }
}
