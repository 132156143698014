/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AccountResponse,
  AccountTemplateResponse,
  AccountTemplatesResponse,
  AccountTokenResponse,
  AccountsFilterResponse,
  AccountsTokensResponse,
  ApplicationResponse,
  BlocklistListAddPayload,
  BlocklistListAddResponse,
  BlocklistListResponse,
  BlocklistsResponse,
  CreateAccount,
  CreateAccountResponse,
  CreateAppResponse,
  CreateGateway,
  CreateGatewayResponse,
  CreateMailbox,
  CreateMailboxResponse,
  CreateOAuth2App,
  CreateTemplate,
  CreateTemplateResponse,
  CreateToken,
  CreateTokenResponse,
  DeleteAppRequestResponse,
  DeleteBlocklistResponse,
  DeleteMailboxResponse,
  DeleteOutboxEntryResponse,
  DeleteRequestResponse,
  DeleteTemplateRequestResponse,
  DeleteTokenRequestResponse,
  DeliveryCheckResponse,
  DeliveryStartRequest,
  DeliveryStartResponse,
  DiscoveredEmailSettings,
  EmptyLicenseResponse,
  GatewayResponse,
  GatewaysFilterResponse,
  LicenseResponse,
  MailboxesFilterResponse,
  MessageDeleteResponse,
  MessageList,
  MessageMove,
  MessageMoveResponse,
  MessageUpdate,
  MessageUpdateResponse,
  MessageUpload,
  MessageUploadResponse,
  MessagesDeleteRequest,
  MessagesDeleteResponse,
  MessagesMoveRequest,
  MessagesMoveResponse,
  MessagesUpdateRequest,
  Model12,
  Model22,
  Model28,
  Model29,
  Model32,
  Model35,
  Model8,
  OAuth2FilterResponse,
  OutboxListResponse,
  RegisterLicense,
  RenameMailbox,
  RenameMailboxResponse,
  RequestAuthForm,
  RequestAuthFormResponse,
  RequestChat,
  RequestFlush,
  RequestFlushResponse,
  RequestReconnect,
  RequestReconnectResponse,
  RequestSync,
  RequestSyncResponse,
  ReturnChatResponse,
  RootTokensResponse,
  Settings,
  SettingsPutQueuePayload,
  SettingsPutQueueResponse,
  SettingsQueryResponse,
  SettingsQueueResponse,
  SettingsResponse,
  SubmitMessage,
  SubmitMessageResponse,
  TextResponse,
  UnifiedSearchQuery,
  UpdateAccount,
  UpdateGateway,
  UpdateGatewayResponse,
  UpdateTemplate,
  UpdateTemplateResponse,
  VerifyAccount,
  VerifyAccountResponse,
  WebhookRouteResponse,
  WebhookRoutesListResponse
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class V1<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Lists registered accounts
   *
   * @tags Account
   * @name GetV1Accounts
   * @summary List accounts
   * @request GET:/v1/accounts
   * @secure
   */
  getV1Accounts = (
    query?: {
      /**
       * Page number (zero indexed, so use 0 for first page)
       * @min 0
       * @max 1048576
       * @default 0
       */
      page?: number;
      /**
       * How many entries per page
       * @min 1
       * @max 1000
       * @default 20
       */
      pageSize?: number;
      /** Filter accounts by state */
      state?:
        | 'init'
        | 'syncing'
        | 'connecting'
        | 'connected'
        | 'authenticationError'
        | 'connectError'
        | 'unset'
        | 'disconnected';
      /** Filter accounts by string match */
      query?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<AccountsFilterResponse, any>({
      path: `/v1/accounts`,
      method: 'GET',
      query: query,
      secure: true,
      ...params
    });
  /**
   * @description Try to discover IMAP and SMTP settings for an email account
   *
   * @tags Settings
   * @name GetV1Autoconfig
   * @summary Discover Email settings
   * @request GET:/v1/autoconfig
   * @secure
   */
  getV1Autoconfig = (
    query: {
      /** Email address to discover email settings for */
      email: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<DiscoveredEmailSettings, any>({
      path: `/v1/autoconfig`,
      method: 'GET',
      query: query,
      secure: true,
      ...params
    });
  /**
   * @description List blocklists with blocked addresses
   *
   * @tags Blocklists
   * @name GetV1Blocklists
   * @summary List blocklists
   * @request GET:/v1/blocklists
   * @secure
   */
  getV1Blocklists = (
    query?: {
      /**
       * Page number (zero indexed, so use 0 for first page)
       * @min 0
       * @max 1048576
       * @default 0
       */
      page?: number;
      /**
       * How many entries per page
       * @min 1
       * @max 1000
       * @default 20
       */
      pageSize?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<BlocklistsResponse, any>({
      path: `/v1/blocklists`,
      method: 'GET',
      query: query,
      secure: true,
      ...params
    });
  /**
   * @description Stream account state changes as an EventSource
   *
   * @tags Account
   * @name GetV1Changes
   * @summary Stream state changes
   * @request GET:/v1/changes
   * @secure
   */
  getV1Changes = (params: RequestParams = {}) =>
    this.http.request<any, string>({
      path: `/v1/changes`,
      method: 'GET',
      secure: true,
      ...params
    });
  /**
   * @description Lists registered gateways
   *
   * @tags SMTP Gateway
   * @name GetV1Gateways
   * @summary List gateways
   * @request GET:/v1/gateways
   * @secure
   */
  getV1Gateways = (
    query?: {
      /**
       * Page number (zero indexed, so use 0 for first page)
       * @min 0
       * @max 1048576
       * @default 0
       */
      page?: number;
      /**
       * How many entries per page
       * @min 1
       * @max 1000
       * @default 20
       */
      pageSize?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<GatewaysFilterResponse, any>({
      path: `/v1/gateways`,
      method: 'GET',
      query: query,
      secure: true,
      ...params
    });
  /**
   * @description Get active license information
   *
   * @tags License
   * @name GetV1License
   * @summary Request license info
   * @request GET:/v1/license
   * @secure
   */
  getV1License = (params: RequestParams = {}) =>
    this.http.request<LicenseResponse, any>({
      path: `/v1/license`,
      method: 'GET',
      secure: true,
      ...params
    });
  /**
   * @description Set up a license for EmailEngine to unlock all features
   *
   * @tags License
   * @name PostV1License
   * @summary Register a license
   * @request POST:/v1/license
   * @secure
   */
  postV1License = (body: RegisterLicense, params: RequestParams = {}) =>
    this.http.request<LicenseResponse, any>({
      path: `/v1/license`,
      method: 'POST',
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Remove registered active license
   *
   * @tags License
   * @name DeleteV1License
   * @summary Remove license
   * @request DELETE:/v1/license
   * @secure
   */
  deleteV1License = (params: RequestParams = {}) =>
    this.http.request<EmptyLicenseResponse, any>({
      path: `/v1/license`,
      method: 'DELETE',
      secure: true,
      ...params
    });
  /**
   * @description Lists registered OAuth2 applications
   *
   * @tags OAuth2 Applications
   * @name GetV1Oauth2
   * @summary List OAuth2 applications
   * @request GET:/v1/oauth2
   * @secure
   */
  getV1Oauth2 = (
    query?: {
      /**
       * Page number (zero indexed, so use 0 for first page)
       * @min 0
       * @max 1048576
       * @default 0
       */
      page?: number;
      /**
       * How many entries per page
       * @min 1
       * @max 1000
       * @default 20
       */
      pageSize?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<OAuth2FilterResponse, any>({
      path: `/v1/oauth2`,
      method: 'GET',
      query: query,
      secure: true,
      ...params
    });
  /**
   * @description Registers a new OAuth2 application for a specific provider
   *
   * @tags OAuth2 Applications
   * @name PostV1Oauth2
   * @summary Register OAuth2 application
   * @request POST:/v1/oauth2
   * @secure
   */
  postV1Oauth2 = (body: CreateOAuth2App, params: RequestParams = {}) =>
    this.http.request<CreateAppResponse, any>({
      path: `/v1/oauth2`,
      method: 'POST',
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Lists messages in the Outbox
   *
   * @tags Outbox
   * @name GetV1Outbox
   * @summary List queued messages
   * @request GET:/v1/outbox
   * @secure
   */
  getV1Outbox = (
    query?: {
      /**
       * Page number (zero indexed, so use 0 for first page)
       * @min 0
       * @max 1048576
       * @default 0
       */
      page?: number;
      /**
       * How many entries per page
       * @min 1
       * @max 1000
       * @default 20
       */
      pageSize?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<OutboxListResponse, any>({
      path: `/v1/outbox`,
      method: 'GET',
      query: query,
      secure: true,
      ...params
    });
  /**
   * @description List setting values for specific keys
   *
   * @tags Settings
   * @name GetV1Settings
   * @summary List specific settings
   * @request GET:/v1/settings
   * @secure
   */
  getV1Settings = (
    query?: {
      /** @default false */
      eventTypes?: boolean;
      /** @default false */
      webhooksEnabled?: boolean;
      /** @default false */
      webhooks?: boolean;
      /** @default false */
      webhookEvents?: boolean;
      /** @default false */
      webhooksCustomHeaders?: boolean;
      /** @default false */
      notifyHeaders?: boolean;
      /** @default false */
      serviceUrl?: boolean;
      /** @default false */
      trackSentMessages?: boolean;
      /** @default false */
      resolveGmailCategories?: boolean;
      /** @default false */
      ignoreMailCertErrors?: boolean;
      /** @default false */
      generateEmailSummary?: boolean;
      /** @default false */
      generateRiskAssessment?: boolean;
      /** @default false */
      openAiAPIKey?: boolean;
      /** @default false */
      openAiModel?: boolean;
      /** @default false */
      openAiAPIUrl?: boolean;
      /** @default false */
      documentStoreChatModel?: boolean;
      /** @default false */
      openAiTemperature?: boolean;
      /** @default false */
      openAiTopP?: boolean;
      /** @default false */
      openAiPrompt?: boolean;
      /** @default false */
      openAiGenerateEmbeddings?: boolean;
      /** @default false */
      inboxNewOnly?: boolean;
      /** @default false */
      serviceSecret?: boolean;
      /** @default false */
      authServer?: boolean;
      /** @default false */
      proxyEnabled?: boolean;
      /** @default false */
      proxyUrl?: boolean;
      /** @default false */
      smtpEhloName?: boolean;
      /** @default false */
      notifyText?: boolean;
      /** @default false */
      notifyWebSafeHtml?: boolean;
      /** @default false */
      notifyTextSize?: boolean;
      /** @default false */
      notifyCalendarEvents?: boolean;
      /** @default false */
      gmailEnabled?: boolean;
      /** @default false */
      gmailClientId?: boolean;
      /** @default false */
      gmailClientSecret?: boolean;
      /** @default false */
      gmailRedirectUrl?: boolean;
      /** @default false */
      gmailExtraScopes?: boolean;
      /** @default false */
      outlookEnabled?: boolean;
      /** @default false */
      outlookClientId?: boolean;
      /** @default false */
      outlookClientSecret?: boolean;
      /** @default false */
      outlookRedirectUrl?: boolean;
      /** @default false */
      outlookAuthority?: boolean;
      /** @default false */
      outlookExtraScopes?: boolean;
      /** @default false */
      mailRuEnabled?: boolean;
      /** @default false */
      mailRuClientId?: boolean;
      /** @default false */
      mailRuClientSecret?: boolean;
      /** @default false */
      mailRuRedirectUrl?: boolean;
      /** @default false */
      mailRuExtraScopes?: boolean;
      /** @default false */
      serviceClient?: boolean;
      /** @default false */
      serviceKey?: boolean;
      /** @default false */
      serviceExtraScopes?: boolean;
      /** @default false */
      logs?: boolean;
      /** @default false */
      imapStrategy?: boolean;
      /** @default false */
      smtpStrategy?: boolean;
      /** @default false */
      localAddresses?: boolean;
      /** @default false */
      smtpServerEnabled?: boolean;
      /** @default false */
      smtpServerPort?: boolean;
      /** @default false */
      smtpServerHost?: boolean;
      /** @default false */
      smtpServerProxy?: boolean;
      /** @default false */
      smtpServerAuthEnabled?: boolean;
      /** @default false */
      smtpServerPassword?: boolean;
      /** @default false */
      smtpServerTLSEnabled?: boolean;
      /** @default false */
      imapProxyServerEnabled?: boolean;
      /** @default false */
      imapProxyServerPort?: boolean;
      /** @default false */
      imapProxyServerHost?: boolean;
      /** @default false */
      imapProxyServerProxy?: boolean;
      /** @default false */
      imapProxyServerPassword?: boolean;
      /** @default false */
      imapProxyServerTLSEnabled?: boolean;
      /** @default false */
      queueKeep?: boolean;
      /** @default false */
      deliveryAttempts?: boolean;
      /** @default false */
      templateHeader?: boolean;
      /** @default false */
      scriptEnv?: boolean;
      /** @default false */
      enableApiProxy?: boolean;
      /** @default false */
      documentStoreEnabled?: boolean;
      /** @default false */
      documentStoreUrl?: boolean;
      /** @default false */
      documentStoreIndex?: boolean;
      /** @default false */
      documentStoreAuthEnabled?: boolean;
      /** @default false */
      documentStoreUsername?: boolean;
      /** @default false */
      documentStorePassword?: boolean;
      /** @default false */
      documentStoreGenerateEmbeddings?: boolean;
      /** @default false */
      documentStorePreProcessingEnabled?: boolean;
      /** @default false */
      locale?: boolean;
      /** @default false */
      timezone?: boolean;
      /** @default false */
      openAiPreProcessingFn?: boolean;
      /** @default false */
      documentStorePreProcessingFn?: boolean;
      /** @default false */
      documentStorePreProcessingMap?: boolean;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<SettingsQueryResponse, any>({
      path: `/v1/settings`,
      method: 'GET',
      query: query,
      secure: true,
      ...params
    });
  /**
   * @description Set setting values for specific keys
   *
   * @tags Settings
   * @name PostV1Settings
   * @summary Set setting values
   * @request POST:/v1/settings
   * @secure
   */
  postV1Settings = (body: Settings, params: RequestParams = {}) =>
    this.http.request<Model12, any>({
      path: `/v1/settings`,
      method: 'POST',
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * No description
   *
   * @tags Stats
   * @name GetV1Stats
   * @summary Return server stats
   * @request GET:/v1/stats
   * @secure
   */
  getV1Stats = (
    query?: {
      /**
       * Duration for counters
       * @min 0
       * @max 604800
       * @default 3600
       */
      seconds?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<SettingsResponse, any>({
      path: `/v1/stats`,
      method: 'GET',
      query: query,
      secure: true,
      ...params
    });
  /**
   * @description Lists stored templates for the account
   *
   * @tags Templates
   * @name GetV1Templates
   * @summary List account templates
   * @request GET:/v1/templates
   * @secure
   */
  getV1Templates = (
    query?: {
      /**
       * Account ID to list the templates for
       * @maxLength 256
       */
      account?: string;
      /**
       * Page number (zero indexed, so use 0 for first page)
       * @min 0
       * @max 1048576
       * @default 0
       */
      page?: number;
      /**
       * How many entries per page
       * @min 1
       * @max 1000
       * @default 20
       */
      pageSize?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<AccountTemplatesResponse, any>({
      path: `/v1/templates`,
      method: 'GET',
      query: query,
      secure: true,
      ...params
    });
  /**
   * @description Lists access tokens registered for root access
   *
   * @tags Access Tokens
   * @name GetV1Tokens
   * @summary List root tokens
   * @request GET:/v1/tokens
   * @secure
   */
  getV1Tokens = (params: RequestParams = {}) =>
    this.http.request<RootTokensResponse, any>({
      path: `/v1/tokens`,
      method: 'GET',
      secure: true,
      ...params
    });
  /**
   * @description List custom webhook routes
   *
   * @tags Webhooks
   * @name GetV1Webhookroutes
   * @summary List webhook routes
   * @request GET:/v1/webhookRoutes
   * @secure
   */
  getV1Webhookroutes = (
    query?: {
      /**
       * Page number (zero indexed, so use 0 for first page)
       * @min 0
       * @max 1048576
       * @default 0
       */
      page?: number;
      /**
       * How many entries per page
       * @min 1
       * @max 1000
       * @default 20
       */
      pageSize?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<WebhookRoutesListResponse, any>({
      path: `/v1/webhookRoutes`,
      method: 'GET',
      query: query,
      secure: true,
      ...params
    });
  /**
   * @description Returns stored information about the account. Passwords are not included.
   *
   * @tags Account
   * @name GetV1AccountAccount
   * @summary Get account info
   * @request GET:/v1/account/{account}
   * @secure
   */
  getV1AccountAccount = (account: string, params: RequestParams = {}) =>
    this.http.request<AccountResponse, any>({
      path: `/v1/account/${account}`,
      method: 'GET',
      secure: true,
      ...params
    });
  /**
   * @description Stop syncing IMAP account and delete cached values
   *
   * @tags Account
   * @name DeleteV1AccountAccount
   * @summary Remove synced account
   * @request DELETE:/v1/account/{account}
   * @secure
   */
  deleteV1AccountAccount = (account: string, params: RequestParams = {}) =>
    this.http.request<DeleteRequestResponse, any>({
      path: `/v1/account/${account}`,
      method: 'DELETE',
      secure: true,
      ...params
    });
  /**
   * @description Updates account information
   *
   * @tags Account
   * @name PutV1AccountAccount
   * @summary Update account info
   * @request PUT:/v1/account/{account}
   * @secure
   */
  putV1AccountAccount = (account: string, body: UpdateAccount, params: RequestParams = {}) =>
    this.http.request<Model32, any>({
      path: `/v1/account/${account}`,
      method: 'PUT',
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description List blocked addresses for a list
   *
   * @tags Blocklists
   * @name GetV1BlocklistListid
   * @summary List blocklist entries
   * @request GET:/v1/blocklist/{listId}
   * @secure
   */
  getV1BlocklistListid = (
    listId: string,
    query?: {
      /**
       * Page number (zero indexed, so use 0 for first page)
       * @min 0
       * @max 1048576
       * @default 0
       */
      page?: number;
      /**
       * How many entries per page
       * @min 1
       * @max 1000
       * @default 20
       */
      pageSize?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<BlocklistListResponse, any>({
      path: `/v1/blocklist/${listId}`,
      method: 'GET',
      query: query,
      secure: true,
      ...params
    });
  /**
   * @description Add an email address to a blocklist
   *
   * @tags Blocklists
   * @name PostV1BlocklistListid
   * @summary Add to blocklist
   * @request POST:/v1/blocklist/{listId}
   * @secure
   */
  postV1BlocklistListid = (
    listId: string,
    body: BlocklistListAddPayload,
    params: RequestParams = {}
  ) =>
    this.http.request<BlocklistListAddResponse, any>({
      path: `/v1/blocklist/${listId}`,
      method: 'POST',
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Delete a blocked email address from a list
   *
   * @tags Blocklists
   * @name DeleteV1BlocklistListid
   * @summary Remove from blocklist
   * @request DELETE:/v1/blocklist/{listId}
   * @secure
   */
  deleteV1BlocklistListid = (
    listId: string,
    query: {
      /** Email address to remove from the list */
      recipient: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<DeleteBlocklistResponse, any>({
      path: `/v1/blocklist/${listId}`,
      method: 'DELETE',
      query: query,
      secure: true,
      ...params
    });
  /**
   * @description Returns stored information about the gateway. Passwords are not included.
   *
   * @tags SMTP Gateway
   * @name GetV1GatewayGateway
   * @summary Get gateway info
   * @request GET:/v1/gateway/{gateway}
   * @secure
   */
  getV1GatewayGateway = (gateway: string, params: RequestParams = {}) =>
    this.http.request<GatewayResponse, any>({
      path: `/v1/gateway/${gateway}`,
      method: 'GET',
      secure: true,
      ...params
    });
  /**
   * @description Delete SMTP gateway data
   *
   * @tags SMTP Gateway
   * @name DeleteV1GatewayGateway
   * @summary Remove SMTP gateway
   * @request DELETE:/v1/gateway/{gateway}
   * @secure
   */
  deleteV1GatewayGateway = (gateway: string, params: RequestParams = {}) =>
    this.http.request<Model28, any>({
      path: `/v1/gateway/${gateway}`,
      method: 'DELETE',
      secure: true,
      ...params
    });
  /**
   * @description Output is a downloadable text file
   *
   * @tags Logs
   * @name GetV1LogsAccount
   * @summary Return IMAP logs for an account
   * @request GET:/v1/logs/{account}
   * @secure
   */
  getV1LogsAccount = (account: string, params: RequestParams = {}) =>
    this.http.request<any, string>({
      path: `/v1/logs/${account}`,
      method: 'GET',
      secure: true,
      ...params
    });
  /**
   * @description Returns stored information about an OAuth2 application. Secrets are not included.
   *
   * @tags OAuth2 Applications
   * @name GetV1Oauth2App
   * @summary Get application info
   * @request GET:/v1/oauth2/{app}
   * @secure
   */
  getV1Oauth2App = (app: string, params: RequestParams = {}) =>
    this.http.request<ApplicationResponse, any>({
      path: `/v1/oauth2/${app}`,
      method: 'GET',
      secure: true,
      ...params
    });
  /**
   * @description Delete OAuth2 application data
   *
   * @tags OAuth2 Applications
   * @name DeleteV1Oauth2App
   * @summary Remove OAuth2 application
   * @request DELETE:/v1/oauth2/{app}
   * @secure
   */
  deleteV1Oauth2App = (app: string, params: RequestParams = {}) =>
    this.http.request<DeleteAppRequestResponse, any>({
      path: `/v1/oauth2/${app}`,
      method: 'DELETE',
      secure: true,
      ...params
    });
  /**
   * @description Updates OAuth2 application information
   *
   * @tags OAuth2 Applications
   * @name PutV1Oauth2App
   * @summary Update OAuth2 application
   * @request PUT:/v1/oauth2/{app}
   * @secure
   */
  putV1Oauth2App = (app: string, body: UpdateGateway, params: RequestParams = {}) =>
    this.http.request<UpdateGatewayResponse, any>({
      path: `/v1/oauth2/${app}`,
      method: 'PUT',
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Get the active OAuth2 access token for an account. NB! This endpoint is disabled by default and needs activation on the Service configuration page.
   *
   * @tags Account
   * @name GetV1AccountAccountOauthtoken
   * @summary Get OAuth2 access token
   * @request GET:/v1/account/{account}/oauth-token
   * @secure
   */
  getV1AccountAccountOauthtoken = (account: string, params: RequestParams = {}) =>
    this.http.request<AccountTokenResponse, any>({
      path: `/v1/account/${account}/oauth-token`,
      method: 'GET',
      secure: true,
      ...params
    });
  /**
   * @description Lists messages in a mailbox folder
   *
   * @tags Message
   * @name GetV1AccountAccountMessages
   * @summary List messages in a folder
   * @request GET:/v1/account/{account}/messages
   * @secure
   */
  getV1AccountAccountMessages = (
    account: string,
    query: {
      /** Mailbox folder path */
      path: string;
      /**
       * Page number (zero indexed, so use 0 for first page)
       * @min 0
       * @max 1048576
       * @default 0
       */
      page?: number;
      /**
       * How many entries per page
       * @min 1
       * @max 1000
       * @default 20
       */
      pageSize?: number;
      /**
       * If enabled then fetch the data from the Document Store instead of IMAP
       * @default false
       */
      documentStore?: boolean;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<MessageList, any>({
      path: `/v1/account/${account}/messages`,
      method: 'GET',
      query: query,
      secure: true,
      ...params
    });
  /**
   * @description Update message information for matching emails
   *
   * @tags Multi Message Actions
   * @name PutV1AccountAccountMessages
   * @summary Update messages
   * @request PUT:/v1/account/{account}/messages
   * @secure
   */
  putV1AccountAccountMessages = (
    account: string,
    query: {
      /** Mailbox folder path */
      path: string;
    },
    body: MessagesUpdateRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MessageUpdateResponse, any>({
      path: `/v1/account/${account}/messages`,
      method: 'PUT',
      query: query,
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Lists all available mailboxes
   *
   * @tags Mailbox
   * @name GetV1AccountAccountMailboxes
   * @summary List mailboxes
   * @request GET:/v1/account/{account}/mailboxes
   * @secure
   */
  getV1AccountAccountMailboxes = (
    account: string,
    query?: {
      /**
       * If true, then includes message counters in the response
       * @default false
       */
      counters?: boolean;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<MailboxesFilterResponse, any>({
      path: `/v1/account/${account}/mailboxes`,
      method: 'GET',
      query: query,
      secure: true,
      ...params
    });
  /**
   * @description Check delivery test status
   *
   * @tags Delivery Test
   * @name GetV1DeliverytestCheckDeliverytest
   * @summary Check test status
   * @request GET:/v1/delivery-test/check/{deliveryTest}
   * @secure
   */
  getV1DeliverytestCheckDeliverytest = (deliveryTest: string, params: RequestParams = {}) =>
    this.http.request<DeliveryCheckResponse, any>({
      path: `/v1/delivery-test/check/${deliveryTest}`,
      method: 'GET',
      secure: true,
      ...params
    });
  /**
   * @description Show queue status and current state
   *
   * @tags Settings
   * @name GetV1SettingsQueueQueue
   * @summary Show queue information
   * @request GET:/v1/settings/queue/{queue}
   * @secure
   */
  getV1SettingsQueueQueue = (
    queue: 'notify' | 'submit' | 'documents',
    params: RequestParams = {}
  ) =>
    this.http.request<SettingsQueueResponse, any>({
      path: `/v1/settings/queue/${queue}`,
      method: 'GET',
      secure: true,
      ...params
    });
  /**
   * @description Set queue settings
   *
   * @tags Settings
   * @name PutV1SettingsQueueQueue
   * @summary Set queue settings
   * @request PUT:/v1/settings/queue/{queue}
   * @secure
   */
  putV1SettingsQueueQueue = (
    queue: 'notify' | 'submit' | 'documents',
    body: SettingsPutQueuePayload,
    params: RequestParams = {}
  ) =>
    this.http.request<SettingsPutQueueResponse, any>({
      path: `/v1/settings/queue/${queue}`,
      method: 'PUT',
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Retrieve template content and information
   *
   * @tags Templates
   * @name GetV1TemplatesTemplateTemplate
   * @summary Get template information
   * @request GET:/v1/templates/template/{template}
   * @secure
   */
  getV1TemplatesTemplateTemplate = (template: string, params: RequestParams = {}) =>
    this.http.request<AccountTemplateResponse, any>({
      path: `/v1/templates/template/${template}`,
      method: 'GET',
      secure: true,
      ...params
    });
  /**
   * @description Delete a stored template
   *
   * @tags Templates
   * @name DeleteV1TemplatesTemplateTemplate
   * @summary Remove a template
   * @request DELETE:/v1/templates/template/{template}
   * @secure
   */
  deleteV1TemplatesTemplateTemplate = (template: string, params: RequestParams = {}) =>
    this.http.request<Model29, any>({
      path: `/v1/templates/template/${template}`,
      method: 'DELETE',
      secure: true,
      ...params
    });
  /**
   * @description Update a stored template.
   *
   * @tags Templates
   * @name PutV1TemplatesTemplateTemplate
   * @summary Update a template
   * @request PUT:/v1/templates/template/{template}
   * @secure
   */
  putV1TemplatesTemplateTemplate = (
    template: string,
    body: UpdateTemplate,
    params: RequestParams = {}
  ) =>
    this.http.request<UpdateTemplateResponse, any>({
      path: `/v1/templates/template/${template}`,
      method: 'PUT',
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Lists access tokens registered for an account
   *
   * @tags Access Tokens
   * @name GetV1TokensAccountAccount
   * @summary List account tokens
   * @request GET:/v1/tokens/account/{account}
   * @secure
   */
  getV1TokensAccountAccount = (account: string, params: RequestParams = {}) =>
    this.http.request<AccountsTokensResponse, any>({
      path: `/v1/tokens/account/${account}`,
      method: 'GET',
      secure: true,
      ...params
    });
  /**
   * @description Retrieve webhook route content and information
   *
   * @tags Webhooks
   * @name GetV1WebhookroutesWebhookrouteWebhookroute
   * @summary Get webhook route information
   * @request GET:/v1/webhookRoutes/webhookRoute/{webhookRoute}
   * @secure
   */
  getV1WebhookroutesWebhookrouteWebhookroute = (webhookRoute: string, params: RequestParams = {}) =>
    this.http.request<WebhookRouteResponse, any>({
      path: `/v1/webhookRoutes/webhookRoute/${webhookRoute}`,
      method: 'GET',
      secure: true,
      ...params
    });
  /**
   * @description Returns details of a specific message. By default text content is not included, use textType value to force retrieving text
   *
   * @tags Message
   * @name GetV1AccountAccountMessageMessage
   * @summary Get message information
   * @request GET:/v1/account/{account}/message/{message}
   * @secure
   */
  getV1AccountAccountMessageMessage = (
    account: string,
    message: string,
    query?: {
      /**
       * Max length of text content. This setting is ignored if `documentStore` is `true`.
       * @min 0
       * @max 1073741824
       */
      maxBytes?: number;
      /** Which text content to return, use * for all. By default text content is not returned. */
      textType?: 'html' | 'plain' | '*';
      /**
       * Shorthand option to fetch and preprocess HTML and inlined images. Overrides `textType`, `preProcessHtml`, and `preProcessHtml` options.
       * @default false
       */
      webSafeHtml?: boolean;
      /**
       * If true, then fetches attached images and embeds these in the HTML as data URIs
       * @default false
       */
      embedAttachedImages?: boolean;
      /**
       * If true, then pre-processes HTML for compatibility
       * @default false
       */
      preProcessHtml?: boolean;
      /**
       * If true, then marks unseen email as seen while returning the message
       * @default false
       */
      markAsSeen?: boolean;
      /**
       * If enabled then fetch the data from the Document Store instead of IMAP
       * @default false
       */
      documentStore?: boolean;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Model8, any>({
      path: `/v1/account/${account}/message/${message}`,
      method: 'GET',
      query: query,
      secure: true,
      ...params
    });
  /**
   * @description Move message to Trash or delete it if already in Trash
   *
   * @tags Message
   * @name DeleteV1AccountAccountMessageMessage
   * @summary Delete message
   * @request DELETE:/v1/account/{account}/message/{message}
   * @secure
   */
  deleteV1AccountAccountMessageMessage = (
    account: string,
    message: string,
    query?: {
      /**
       * Delete message even if not in Trash
       * @default false
       */
      force?: boolean;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<MessageDeleteResponse, any>({
      path: `/v1/account/${account}/message/${message}`,
      method: 'DELETE',
      query: query,
      secure: true,
      ...params
    });
  /**
   * @description Update message information. Mainly this means changing message flag values
   *
   * @tags Message
   * @name PutV1AccountAccountMessageMessage
   * @summary Update message
   * @request PUT:/v1/account/{account}/message/{message}
   * @secure
   */
  putV1AccountAccountMessageMessage = (
    account: string,
    message: string,
    body: MessageUpdate,
    params: RequestParams = {}
  ) =>
    this.http.request<MessageUpdateResponse, any>({
      path: `/v1/account/${account}/message/${message}`,
      method: 'PUT',
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Fetches attachment file as a binary stream
   *
   * @tags Message
   * @name GetV1AccountAccountAttachmentAttachment
   * @summary Download attachment
   * @request GET:/v1/account/{account}/attachment/{attachment}
   * @secure
   */
  getV1AccountAccountAttachmentAttachment = (
    account: string,
    attachment: string,
    params: RequestParams = {}
  ) =>
    this.http.request<any, string>({
      path: `/v1/account/${account}/attachment/${attachment}`,
      method: 'GET',
      secure: true,
      ...params
    });
  /**
   * @description Retrieves message text
   *
   * @tags Message
   * @name GetV1AccountAccountTextText
   * @summary Retrieve message text
   * @request GET:/v1/account/{account}/text/{text}
   * @secure
   */
  getV1AccountAccountTextText = (
    account: string,
    text: string,
    query?: {
      /**
       * Max length of text content. This setting is ignored if `documentStore` is `true`.
       * @min 0
       * @max 1073741824
       */
      maxBytes?: number;
      /**
       * Which text content to return, use * for all. By default all contents are returned.
       * @default "*"
       */
      textType?: 'html' | 'plain' | '*';
      /**
       * If enabled then fetch the data from the Document Store instead of IMAP
       * @default false
       */
      documentStore?: boolean;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<TextResponse, any>({
      path: `/v1/account/${account}/text/${text}`,
      method: 'GET',
      query: query,
      secure: true,
      ...params
    });
  /**
   * @description Fetches raw message as a stream
   *
   * @tags Message
   * @name GetV1AccountAccountMessageMessageSource
   * @summary Download raw message
   * @request GET:/v1/account/{account}/message/{message}/source
   * @secure
   */
  getV1AccountAccountMessageMessageSource = (
    account: string,
    message: string,
    params: RequestParams = {}
  ) =>
    this.http.request<any, string>({
      path: `/v1/account/${account}/message/${message}/source`,
      method: 'GET',
      secure: true,
      ...params
    });
  /**
   * @description Registers new IMAP account to be synced
   *
   * @tags Account
   * @name PostV1Account
   * @summary Register new account
   * @request POST:/v1/account
   * @secure
   */
  postV1Account = (body: CreateAccount, params: RequestParams = {}) =>
    this.http.request<CreateAccountResponse, any>({
      path: `/v1/account`,
      method: 'POST',
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Registers a new SMP gateway
   *
   * @tags SMTP Gateway
   * @name PostV1Gateway
   * @summary Register new gateway
   * @request POST:/v1/gateway
   * @secure
   */
  postV1Gateway = (body: CreateGateway, params: RequestParams = {}) =>
    this.http.request<CreateGatewayResponse, any>({
      path: `/v1/gateway`,
      method: 'POST',
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Provisions a new access token for an account
   *
   * @tags Access Tokens
   * @name PostV1Token
   * @summary Provision an access token
   * @request POST:/v1/token
   * @secure
   */
  postV1Token = (body: CreateToken, params: RequestParams = {}) =>
    this.http.request<CreateTokenResponse, any>({
      path: `/v1/token`,
      method: 'POST',
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Checks if can connect and authenticate using provided account info
   *
   * @tags Account
   * @name PostV1Verifyaccount
   * @summary Verify IMAP and SMTP settings
   * @request POST:/v1/verifyAccount
   * @secure
   */
  postV1Verifyaccount = (body: VerifyAccount, params: RequestParams = {}) =>
    this.http.request<VerifyAccountResponse, any>({
      path: `/v1/verifyAccount`,
      method: 'POST',
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Generates a redirect link to the hosted authentication form
   *
   * @tags Account
   * @name PostV1AuthenticationForm
   * @summary Generate authentication link
   * @request POST:/v1/authentication/form
   * @secure
   */
  postV1AuthenticationForm = (body: RequestAuthForm, params: RequestParams = {}) =>
    this.http.request<RequestAuthFormResponse, any>({
      path: `/v1/authentication/form`,
      method: 'POST',
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Use OpenAI API and embeddings stored in the Document Store to "chat" with account emails. Requires Document Store indexing and the "Chat with emails" feature to be enabled.
   *
   * @tags Chat
   * @name PostV1ChatAccount
   * @summary Chat with emails
   * @request POST:/v1/chat/{account}
   * @secure
   */
  postV1ChatAccount = (account: string, body: RequestChat, params: RequestParams = {}) =>
    this.http.request<ReturnChatResponse, any>({
      path: `/v1/chat/${account}`,
      method: 'POST',
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Create a new stored template. Templates can be used when sending emails as the content of the message.
   *
   * @tags Templates
   * @name PostV1TemplatesTemplate
   * @summary Create template
   * @request POST:/v1/templates/template
   * @secure
   */
  postV1TemplatesTemplate = (body: CreateTemplate, params: RequestParams = {}) =>
    this.http.request<CreateTemplateResponse, any>({
      path: `/v1/templates/template`,
      method: 'POST',
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Filter messages from the Document Store for multiple accounts or paths. Document Store must be enabled for the unified search to work.
   *
   * @tags Message
   * @name PostV1UnifiedSearch
   * @summary Unified search for messages
   * @request POST:/v1/unified/search
   * @secure
   */
  postV1UnifiedSearch = (
    body: UnifiedSearchQuery,
    query?: {
      /**
       * Page number (zero indexed, so use 0 for first page)
       * @min 0
       * @max 1048576
       * @default 0
       */
      page?: number;
      /**
       * How many entries per page
       * @min 1
       * @max 1000
       * @default 20
       */
      pageSize?: number;
      /** If enabled then returns the ElasticSearch query for debugging as part of the response */
      exposeQuery?: boolean;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<MessageList, any>({
      path: `/v1/unified/search`,
      method: 'POST',
      query: query,
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Submit message for delivery. If reference message ID is provided then EmailEngine adds all headers and flags required for a reply/forward automatically.
   *
   * @tags Submit
   * @name PostV1AccountAccountSubmit
   * @summary Submit message for delivery
   * @request POST:/v1/account/{account}/submit
   * @secure
   */
  postV1AccountAccountSubmit = (account: string, body: SubmitMessage, params: RequestParams = {}) =>
    this.http.request<SubmitMessageResponse, any>({
      path: `/v1/account/${account}/submit`,
      method: 'POST',
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Filter messages from a mailbox folder by search options. Search is performed against a specific folder and not for the entire account.
   *
   * @tags Message
   * @name PostV1AccountAccountSearch
   * @summary Search for messages
   * @request POST:/v1/account/{account}/search
   * @secure
   */
  postV1AccountAccountSearch = (
    account: string,
    body: Model22,
    query?: {
      /** Mailbox folder path. Not required if `documentStore` is `true` */
      path?: string;
      /**
       * Page number (zero indexed, so use 0 for first page)
       * @min 0
       * @max 1048576
       * @default 0
       */
      page?: number;
      /**
       * How many entries per page
       * @min 1
       * @max 1000
       * @default 20
       */
      pageSize?: number;
      /**
       * If enabled then fetch the data from the Document Store instead of IMAP
       * @default false
       */
      documentStore?: boolean;
      /** If enabled then returns the ElasticSearch query for debugging as part of the response */
      exposeQuery?: boolean;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<MessageList, any>({
      path: `/v1/account/${account}/search`,
      method: 'POST',
      query: query,
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Create new mailbox folder
   *
   * @tags Mailbox
   * @name PostV1AccountAccountMailbox
   * @summary Create mailbox
   * @request POST:/v1/account/{account}/mailbox
   * @secure
   */
  postV1AccountAccountMailbox = (
    account: string,
    body: CreateMailbox,
    params: RequestParams = {}
  ) =>
    this.http.request<CreateMailboxResponse, any>({
      path: `/v1/account/${account}/mailbox`,
      method: 'POST',
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Delete existing mailbox folder
   *
   * @tags Mailbox
   * @name DeleteV1AccountAccountMailbox
   * @summary Delete mailbox
   * @request DELETE:/v1/account/{account}/mailbox
   * @secure
   */
  deleteV1AccountAccountMailbox = (
    account: string,
    query: {
      /** Mailbox folder path to delete */
      path: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<DeleteMailboxResponse, any>({
      path: `/v1/account/${account}/mailbox`,
      method: 'DELETE',
      query: query,
      secure: true,
      ...params
    });
  /**
   * @description Rename an existing mailbox folder
   *
   * @tags Mailbox
   * @name PutV1AccountAccountMailbox
   * @summary Rename mailbox
   * @request PUT:/v1/account/{account}/mailbox
   * @secure
   */
  putV1AccountAccountMailbox = (account: string, body: RenameMailbox, params: RequestParams = {}) =>
    this.http.request<RenameMailboxResponse, any>({
      path: `/v1/account/${account}/mailbox`,
      method: 'PUT',
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Upload a message structure, compile it into an EML file and store it into selected mailbox.
   *
   * @tags Message
   * @name PostV1AccountAccountMessage
   * @summary Upload message
   * @request POST:/v1/account/{account}/message
   * @secure
   */
  postV1AccountAccountMessage = (
    account: string,
    body: MessageUpload,
    params: RequestParams = {}
  ) =>
    this.http.request<MessageUploadResponse, any>({
      path: `/v1/account/${account}/message`,
      method: 'POST',
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Initiate a delivery test
   *
   * @tags Delivery Test
   * @name PostV1DeliverytestAccountAccount
   * @summary Create delivery test
   * @request POST:/v1/delivery-test/account/{account}
   * @secure
   */
  postV1DeliverytestAccountAccount = (
    account: string,
    body: DeliveryStartRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<DeliveryStartResponse, any>({
      path: `/v1/delivery-test/account/${account}`,
      method: 'POST',
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Remove a message from the outbox
   *
   * @tags Outbox
   * @name DeleteV1OutboxQueueid
   * @summary Remove a message
   * @request DELETE:/v1/outbox/{queueId}
   * @secure
   */
  deleteV1OutboxQueueid = (queueId: string, params: RequestParams = {}) =>
    this.http.request<DeleteOutboxEntryResponse, any>({
      path: `/v1/outbox/${queueId}`,
      method: 'DELETE',
      secure: true,
      ...params
    });
  /**
   * @description Delete an access token
   *
   * @tags Access Tokens
   * @name DeleteV1TokenToken
   * @summary Remove a token
   * @request DELETE:/v1/token/{token}
   * @secure
   */
  deleteV1TokenToken = (token: string, params: RequestParams = {}) =>
    this.http.request<DeleteTokenRequestResponse, any>({
      path: `/v1/token/${token}`,
      method: 'DELETE',
      secure: true,
      ...params
    });
  /**
   * @description Delete all stored templates for an account
   *
   * @tags Templates
   * @name DeleteV1TemplatesAccountAccount
   * @summary Flush templates
   * @request DELETE:/v1/templates/account/{account}
   * @secure
   */
  deleteV1TemplatesAccountAccount = (
    account: string,
    query?: {
      /**
       * Must be true in order to flush templates
       * @default false
       */
      force?: true;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<DeleteTemplateRequestResponse, any>({
      path: `/v1/templates/account/${account}`,
      method: 'DELETE',
      query: query,
      secure: true,
      ...params
    });
  /**
   * @description Deletes all email indexes from Redis and ElasticSearch and re-creates the index for that account. You can only run a single flush operation at a time, so you must wait until the previous flush has finished before initiating a new one.
   *
   * @tags Account
   * @name PutV1AccountAccountFlush
   * @summary Request account flush
   * @request PUT:/v1/account/{account}/flush
   * @secure
   */
  putV1AccountAccountFlush = (account: string, body: RequestFlush, params: RequestParams = {}) =>
    this.http.request<RequestFlushResponse, any>({
      path: `/v1/account/${account}/flush`,
      method: 'PUT',
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Requests connection to be reconnected
   *
   * @tags Account
   * @name PutV1AccountAccountReconnect
   * @summary Request reconnect
   * @request PUT:/v1/account/{account}/reconnect
   * @secure
   */
  putV1AccountAccountReconnect = (
    account: string,
    body: RequestReconnect,
    params: RequestParams = {}
  ) =>
    this.http.request<RequestReconnectResponse, any>({
      path: `/v1/account/${account}/reconnect`,
      method: 'PUT',
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Requests account syncing to be run immediatelly
   *
   * @tags Account
   * @name PutV1AccountAccountSync
   * @summary Request syncing
   * @request PUT:/v1/account/{account}/sync
   * @secure
   */
  putV1AccountAccountSync = (account: string, body: RequestSync, params: RequestParams = {}) =>
    this.http.request<RequestSyncResponse, any>({
      path: `/v1/account/${account}/sync`,
      method: 'PUT',
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Updates gateway information
   *
   * @tags SMTP Gateway
   * @name PutV1GatewayEditGateway
   * @summary Update gateway info
   * @request PUT:/v1/gateway/edit/{gateway}
   * @secure
   */
  putV1GatewayEditGateway = (gateway: string, body: Model35, params: RequestParams = {}) =>
    this.http.request<UpdateGatewayResponse, any>({
      path: `/v1/gateway/edit/${gateway}`,
      method: 'PUT',
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Move messages to Trash or delete these if already in Trash
   *
   * @tags Multi Message Actions
   * @name PutV1AccountAccountMessagesDelete
   * @summary Delete messages
   * @request PUT:/v1/account/{account}/messages/delete
   * @secure
   */
  putV1AccountAccountMessagesDelete = (
    account: string,
    query: {
      /** Mailbox folder path */
      path: string;
      /**
       * Delete messages even if not in Trash
       * @default false
       */
      force?: boolean;
    },
    body: MessagesDeleteRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MessagesDeleteResponse, any>({
      path: `/v1/account/${account}/messages/delete`,
      method: 'PUT',
      query: query,
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Move messages matching to a search query to another folder
   *
   * @tags Multi Message Actions
   * @name PutV1AccountAccountMessagesMove
   * @summary Move messages
   * @request PUT:/v1/account/{account}/messages/move
   * @secure
   */
  putV1AccountAccountMessagesMove = (
    account: string,
    query: {
      /** Source mailbox folder path */
      path: string;
    },
    body: MessagesMoveRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MessagesMoveResponse, any>({
      path: `/v1/account/${account}/messages/move`,
      method: 'PUT',
      query: query,
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Move message to another folder
   *
   * @tags Message
   * @name PutV1AccountAccountMessageMessageMove
   * @summary Move message
   * @request PUT:/v1/account/{account}/message/{message}/move
   * @secure
   */
  putV1AccountAccountMessageMessageMove = (
    account: string,
    message: string,
    body: MessageMove,
    params: RequestParams = {}
  ) =>
    this.http.request<MessageMoveResponse, any>({
      path: `/v1/account/${account}/message/${message}/move`,
      method: 'PUT',
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params
    });
}
