import { Button, Checkbox, Popover, Row, Space, Tooltip } from 'antd';
import { ExportOutlined, RightOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import styled from 'styled-components';
import { OrderService, OrderStatus, SettlementOrder } from 'services/api/data-contracts';
import { OrderStatusTag } from '../DispatcherOrdersWidget/components/OrderStatusTag';
import { RowState, useSettlementDashboardStore } from 'store/settlementDashboardStore';
import { OrderServicesExpandableTable } from './OrderServicesExpandableTable/OrderServicesExpandableTable';
import { getAmountValue } from 'helpers';
import { useOrderStore } from 'store/orderStore';
import { OrderPopoverContent } from '../components/OrderPopoverContent';
import { orderTypeFullName } from 'consts/order';
import { useInvoiceStore } from 'store/invoiceStore';
import { getOrderInfo } from '../DispatcherOrdersWidget/helpers/getOrderInfo';
import { CONTROL_SIZE } from 'consts/common';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { useDrawerStore } from 'store/drawerStore';
import { useNavigate } from 'react-router-dom';
import { green, red } from '@ant-design/colors';

export const ListItem = styled('div')`
  padding: 0;
  width: 100%;
`;

const Header = styled(Row)`
  width: 100%;
  flex-flow: row nowrap;
  padding: 4px 16px;
  cursor: pointer;

  .action-btn {
    display: none;
  }

  &:hover {
    background-color: #fafafa;

    .action-btn {
      display: block;
    }
  }
`;

const RowWrapper = styled('div')`
  display: flex;
  gap: 8px;
  overflow: hidden;
  align-items: center;
  margin-right: 16px;
  padding: 4px 0;
`;

interface DecoratedText {
  $isCancelled: boolean;
}

const DecoratedText = styled('span')<DecoratedText>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: ${(props) => (props.$isCancelled ? 'line-through' : 'none')};
  color: ${(props) => (props.$isCancelled ? '#00000073' : 'inherit')};
`;

const Divider = styled('span')`
  color: #bfbfbf;
  margin: 0 8px;
`;

interface IProps {
  order: SettlementOrder;
  isExpanded: boolean;
  onExpand: (id?: string) => void;
  onOpenService: (service: OrderService) => void;
  isFullScreenMode: boolean;
}

export const SettlementOrdersListItem = ({
  order,
  onExpand,
  isExpanded,
  onOpenService,
  isFullScreenMode
}: IProps) => {
  const { selectedOrders, indeterminateOrders, orderRowsState, setOrderRowsState } =
    useSettlementDashboardStore(
      ({ selectedOrders, indeterminateOrders, orderRowsState, setOrderRowsState }) => ({
        selectedOrders,
        indeterminateOrders,
        orderRowsState,
        setOrderRowsState
      })
    );
  const { selectedServices, setSelectedServices } = useOrderStore(
    ({ selectedServices, setSelectedServices }) => ({
      selectedServices,
      setSelectedServices
    })
  );
  const { setOpenOrder } = useOrdersDashboardStore(({ setOpenOrder }) => ({ setOpenOrder }));
  const { setEditingInvoiceItemId } = useInvoiceStore(({ setEditingInvoiceItemId }) => ({
    setEditingInvoiceItemId
  }));
  const { setDrawerOpen } = useDrawerStore(({ setDrawerOpen }) => ({ setDrawerOpen }));

  const navigate = useNavigate();

  const handleSelectOrder = (e: CheckboxChangeEvent) => {
    const {
      target: { checked, value }
    } = e;

    const newRowsState = orderRowsState.map((row) => {
      if (row.number === value) {
        return {
          ...row,
          state: checked ? RowState.SELECTED : RowState.EMPTY,
          services: row.services.map((service) => ({
            ...service,
            state: checked ? RowState.SELECTED : RowState.EMPTY
          }))
        };
      }

      return row;
    });

    setOrderRowsState(newRowsState);

    // Services selection for bulk actions popup
    const updatedRow = orderRowsState.find((row) => row.number === value);

    if (updatedRow) {
      const orderServicesIds = updatedRow.services.map(({ id }) => id);
      setSelectedServices(
        checked
          ? [...selectedServices, ...orderServicesIds]
          : selectedServices.filter((id) => !orderServicesIds.includes(id))
      );
    }
  };

  const handleClickRow = ({ target }: React.MouseEvent<HTMLDivElement>) => {
    const isCheckbox = 'checked' in target;
    const isButton = (target as HTMLElement).closest('button');

    if (!isCheckbox && !isButton) {
      onExpand(isExpanded ? undefined : order.number);
      setEditingInvoiceItemId(undefined);
    }
  };

  const getPricingInfo = (info: { cost: number; sale: number; currency: string; profit: number }) =>
    [
      info.currency + (order.totalSales.length > 1 ? ` + ${order.totalSales.length - 1}` : ''),
      getAmountValue(info.sale),
      getAmountValue(info.cost),
      getAmountValue(info.profit)
    ].filter((i) => !!i);

  const orderInfo = useMemo(() => getOrderInfo(order), [order]);

  const pricingInfo = useMemo(
    () => (order.totalSales?.length > 0 ? getPricingInfo(order.totalSales[0]) : []),
    [order]
  );

  const popoverContent = useMemo(() => <OrderPopoverContent order={order} />, [order]);

  return (
    <ListItem>
      <Header justify="space-between" onClick={handleClickRow}>
        <RowWrapper>
          <Checkbox
            value={order.number}
            checked={selectedOrders.includes(order.number)}
            indeterminate={indeterminateOrders.includes(order.number)}
            onChange={handleSelectOrder}
            style={{ marginRight: '8px' }}
            id={`order-${order.number}`}
          />

          <OrderStatusTag status={order.status} />

          <Popover
            content={popoverContent}
            title={`${orderTypeFullName[order.type]} ${order.number}`}>
            <DecoratedText $isCancelled={order.status === OrderStatus.CNLD}>
              {orderInfo.map((i, idx) => (
                <span key={idx}>
                  {i}
                  {idx !== orderInfo.length - 1 && <Divider>|</Divider>}
                </span>
              ))}
              {pricingInfo.length > 0 &&
                pricingInfo.map((i, idx) => {
                  const isProfit = idx === pricingInfo.length - 1;
                  const style =
                    isProfit && i !== undefined
                      ? {
                          color: parseInt(i) > 0 ? green[6] : parseInt(i) < 0 ? red[5] : 'inherit',
                          fontWeight: '500'
                        }
                      : { color: 'inherit', fontWeight: 'normal' };

                  return (
                    <span key={idx} style={style}>
                      <Divider>|</Divider>
                      {i}
                    </span>
                  );
                })}
            </DecoratedText>
          </Popover>
        </RowWrapper>

        <Space>
          <Tooltip title="Open the order">
            <Button
              shape="circle"
              icon={<ExportOutlined />}
              size={CONTROL_SIZE}
              type="primary"
              className="action-btn"
              onClick={() => {
                setOpenOrder({ id: order.id, type: order.type });
                setDrawerOpen('order');
                navigate(`/dashboard/settlement/order/${order.type}-${order.id}`);
              }}
            />
          </Tooltip>

          <RightOutlined
            style={{
              transform: `rotate(${isExpanded ? 90 : 0}deg)`,
              transition: '0.2s ease-in-out all',
              fontSize: '12px'
            }}
          />
        </Space>
      </Header>

      <Row
        style={{
          maxHeight: isExpanded ? 'fit-content' : 0,
          transform: `scaleY(${isExpanded ? 1 : 0})`,
          transformOrigin: 'top',
          transition: 'transform 0.2s ease-out'
        }}
        id={order.number}>
        {isExpanded && (
          <OrderServicesExpandableTable
            order={order}
            onOpenService={onOpenService}
            isFullScreenMode={isFullScreenMode}
          />
        )}
      </Row>
    </ListItem>
  );
};
