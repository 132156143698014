export const getLoginUrl = () => {
  const url = new URL((process.env.REACT_APP_COGNITO_HOSTED_UI || '') + '/login');
  url.searchParams.set('response_type', process.env.REACT_APP_COGNITO_RESPONSE_TYPE || '');
  url.searchParams.set('client_id', process.env.REACT_APP_COGNITO_CLIENT_ID || '');
  url.searchParams.set('redirect_uri', process.env.REACT_APP_COGNITO_REDIRECT_URI || '');

  return url.toString();
};

export const getLogoutUrl = () => {
  const url = new URL((process.env.REACT_APP_COGNITO_HOSTED_UI || '') + '/logout');
  url.searchParams.set('response_type', process.env.REACT_APP_COGNITO_RESPONSE_TYPE || '');
  url.searchParams.set('client_id', process.env.REACT_APP_COGNITO_CLIENT_ID || '');
  url.searchParams.set('redirect_uri', process.env.REACT_APP_COGNITO_REDIRECT_URI || '');

  return url.toString();
};
