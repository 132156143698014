import { InvoiceType, SettlementOrder } from 'services/api/data-contracts';
import { VALIDATION_NOTIFICATION } from './constants';

const isReceivedInvoiceRequired = (orders: SettlementOrder[]) => {
  return orders.some((order) =>
    order.orderServices
      .filter((service) => !service.isOwnService)
      .some(
        (service) =>
          service.invoiceItems.length === 0 ||
          service.invoiceItems.every((item) => item.invoice.type === InvoiceType.Issued)
      )
  );
};

const isAllReceivedInvoicesWithOwnPrice = (orders: SettlementOrder[]) => {
  return orders.some((order) =>
    order.orderServices.every(
      (service) =>
        !service.isOwnService &&
        service.invoiceItems
          .filter((item) => item.invoice.type === InvoiceType.Received)
          .every((item) => item.isOwnPrice === true)
    )
  );
};

const areServicesForDifferentCounterparties = (
  orders: SettlementOrder[],
  invoiceType: InvoiceType
) => {
  if (invoiceType === InvoiceType.Issued) {
    const payerIds = orders.map((order: SettlementOrder) => order.payer.id).filter(Boolean);

    const uniquePayers = [...new Set(payerIds)];
    return uniquePayers.length > 1;
  }

  if (invoiceType === InvoiceType.Received) {
    const services = orders.flatMap((order) => order.orderServices);
    const firstServiceSupplierId = services[0]?.supplier?.id;

    if (!firstServiceSupplierId) {
      return true;
    }

    const areAllSupplierIdsSame = services.every(
      (service) => service.supplier && service.supplier?.id === firstServiceSupplierId
    );

    return !areAllSupplierIdsSame;
  }
};

export const validateServicesToBeInvoiced = (
  invoiceType: InvoiceType,
  isSomeServiceSelected: boolean,
  orders: SettlementOrder[]
): void | undefined => {
  const validationNotRequired = !isSomeServiceSelected || !orders;

  if (validationNotRequired) {
    return;
  }

  if (invoiceType === InvoiceType.Issued && isReceivedInvoiceRequired(orders)) {
    throw new Error(VALIDATION_NOTIFICATION.RECEIVED_INVOICE_REQUIRED);
  }

  if (invoiceType === InvoiceType.Issued && isAllReceivedInvoicesWithOwnPrice(orders)) {
    throw new Error(VALIDATION_NOTIFICATION.NO_DATA_FOR_REINVOICING);
  }

  if (areServicesForDifferentCounterparties(orders, invoiceType)) {
    throw new Error(
      invoiceType === InvoiceType.Issued
        ? VALIDATION_NOTIFICATION.SERVICE_PAYER
        : VALIDATION_NOTIFICATION.SERVICE_SUPPLIER
    );
  }
};
