import React from 'react';
import { Tag } from 'antd';
import styled from 'styled-components';
import { getOrderTerm, getUTCDate, getOrderIcon } from 'helpers';
import { EnrichedOrderService } from 'store/orderStore';
import { orange } from '@ant-design/colors';

const StyledTag = styled(Tag)`
  margin-bottom: 16px;
  display: inline-flex;
  align-items: center;
  max-width: 100%;

  span:has(.text) {
    flex-grow: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    margin-left: 4px;
  }
`;

interface LinkedOrderTagProps {
  service?: Partial<EnrichedOrderService>;
}

export const LinkedOrderTag = ({ service }: LinkedOrderTagProps) => {
  if (!service?.order) return null;

  const { type, location, number, startDatetime, endDatetime } = service.order;

  return (
    <StyledTag color="orange" icon={getOrderIcon(type, orange[6])} key={number} closeIcon={false}>
      <span className="text">
        {[
          type,
          location && 'code' in location ? location.code : location?.name,
          getOrderTerm(getUTCDate(startDatetime), getUTCDate(endDatetime))
        ]
          .filter((i) => !!i)
          .join(' | ')}
      </span>
    </StyledTag>
  );
};
