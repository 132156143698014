import React from 'react';
import { usePaymentStore } from 'store/paymentStore';
import { PaymentFullyHydrated, TransactionType } from 'services/api/data-contracts';
import { Flex } from 'antd';
import { PaymentView } from './PaymentView/PaymentView';
import { PaymentForm } from './PaymentForm/PaymentForm';
import { PaymentPurposeBlock } from './PaymentPurposeBlock/PaymentPurposeBlock';
import { CreditNoteItemsBlock } from './PaymentPurposeBlock/CreditNoteItemsBlock';

interface IProps {
  payment?: PaymentFullyHydrated;
}

export const PaymentDetails = ({ payment }: IProps) => {
  const { isPaymentEditMode } = usePaymentStore(({ isPaymentEditMode }) => ({ isPaymentEditMode }));

  if (isPaymentEditMode) {
    return <PaymentForm />;
  }

  if (!payment) {
    return null;
  }

  return (
    <Flex gap={8} vertical>
      <PaymentView payment={payment} />

      {payment.transactionType === TransactionType.CreditNote && payment.creditNoteItems && (
        <CreditNoteItemsBlock items={payment.creditNoteItems} />
      )}

      <PaymentPurposeBlock payment={payment} />
    </Flex>
  );
};
