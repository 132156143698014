import { Button, CollapseProps, ConfigProvider, Empty, Flex, Table } from 'antd';
import { useGetFlightProgramOrders } from 'queries/flightProgram';
import { CONTROL_SIZE, DATE_TIME_FORMAT } from 'consts/common';
import { StyledCollapse } from 'components/common/StyledCollapse';
import React, { useMemo } from 'react';
import { StyledBadge } from 'components/common/StyledBadge';
import { blue } from '@ant-design/colors';
import dayjs from 'dayjs';
import { getColumns } from './ordersTableColumns';
import { getUTCDate } from 'helpers';
import { PlusOutlined } from '@ant-design/icons';
import { ExtendProgramForm } from './ExtendProgramForm';
import { useFlightProgramStore } from 'store/flightProgramStore';
import { useNavigate } from 'react-router-dom';
import { useDrawerStore } from 'store/drawerStore';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import styled from 'styled-components';
import { Order } from 'services/api/data-contracts';

const StyledTable = styled(Table<Order>)`
  .table-row {
    cursor: pointer;
  }
`;

export const ProgramOrders = ({ programId }: { programId: number }) => {
  const { setOrdersCloneMode, isOrdersCloneMode, orders: programOrders } = useFlightProgramStore();
  const { setOpenOrder } = useOrdersDashboardStore();
  const { setDrawerOpen, setDrawerClosed } = useDrawerStore();

  const { isLoading } = useGetFlightProgramOrders(programId);

  const navigate = useNavigate();

  const orders = useMemo(
    () => programOrders.map((order) => ({ ...order, key: order.number })) || [],
    [programOrders]
  );

  const programDates = useMemo(() => {
    const orderStartDates = orders.map((order) => getUTCDate(order.startDatetime));
    const orderEndDates = orders.map((order) => getUTCDate(order.endDatetime));

    return (
      dayjs.min(orderStartDates)?.format(DATE_TIME_FORMAT) +
      ' - ' +
      dayjs.max(orderEndDates)?.format(DATE_TIME_FORMAT)
    );
  }, [orders]);

  const columns = useMemo(getColumns, []);

  const collapseContent: CollapseProps['items'] = [
    {
      key: 'orders',
      label: orders?.length ? (
        <Flex gap={4} align="center">
          {programDates}
          <StyledBadge count={orders.length} color={blue[0]} />
        </Flex>
      ) : (
        'Orders'
      ),
      extra: !isOrdersCloneMode && (
        <Button
          type="link"
          size={CONTROL_SIZE}
          onClick={(e) => {
            e.stopPropagation();
            setOrdersCloneMode(true);
          }}
          icon={<PlusOutlined />}>
          Add a new one
        </Button>
      ),
      children: (
        <ConfigProvider
          renderEmpty={() => (
            <Empty description="Orders not found" image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}>
          {isOrdersCloneMode && <ExtendProgramForm />}

          <StyledTable
            loading={isLoading}
            dataSource={orders}
            pagination={false}
            size={CONTROL_SIZE}
            columns={columns}
            rowClassName="table-row"
            onRow={(record) => ({
              onClick: () => {
                setDrawerClosed('order');

                navigate(
                  `/dashboard/dispatcher/order/${record.type}-${record.id}${window.location.search}`
                );
                setOpenOrder({ id: record.id, type: record.type });
                setDrawerOpen('order');
              }
            })}
          />
        </ConfigProvider>
      )
    }
  ];

  return (
    <StyledCollapse
      items={collapseContent}
      expandIconPosition="end"
      size={CONTROL_SIZE}
      defaultActiveKey="orders"
      ghost
    />
  );
};
