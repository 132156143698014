import React, { useCallback } from 'react';
import { ExportPopover } from '../../../../components/OrderDrawer/OrderDetails/common/ExportPopover';
import { CloseButton } from 'components/Drawer';
import { useFlightProgramStore } from 'store/flightProgramStore';
import { useDrawerStore } from 'store/drawerStore';
import { Space } from 'antd';
import { ChangeStatusPopover } from './ChangeStatusPopover';

export const HeaderButtons = () => {
  const { setCurrent, setOpenProgramId, setOrdersCloneMode } = useFlightProgramStore();
  const { setDrawerClosed } = useDrawerStore(({ setDrawerClosed }) => ({
    setDrawerClosed
  }));

  const handleCloseDrawer = useCallback(() => {
    setDrawerClosed('flightProgram');

    setCurrent(undefined);
    setOrdersCloneMode(false);
    setOpenProgramId(undefined);
  }, [setCurrent, setDrawerClosed]);

  return (
    <Space>
      <ExportPopover isProgramView />
      <ChangeStatusPopover />
      <CloseButton onClick={handleCloseDrawer} />
    </Space>
  );
};
