import { OrderType } from 'services/api/data-contracts';
import { faker } from '@faker-js/faker';
import { OrdersFilter } from 'store/ordersDashboardStore';
import { getUTCDate } from 'helpers';

const pastDate = getUTCDate(faker.date.past());
const recentDate = getUTCDate(faker.date.recent());
const futureDate = getUTCDate(faker.date.future());

export const getMockDispatcherOrderFilters = (): OrdersFilter => {
  const orderTypes = Object.values(OrderType);
  const randomIndex = faker.number.int({ min: 0, max: orderTypes.length - 1 });

  return {
    locations: [{ label: 'airport', value: 'city_1' }],
    customers: [{ label: 'customer', value: 1 }],
    payers: [{ label: 'payer', value: 1 }],
    providers: [{ label: 'provider', value: 1 }],
    suppliers: [{ label: 'supplier', value: 1 }],
    orderTypes: [orderTypes[randomIndex]],
    orderStartDate: [pastDate, recentDate],
    orderEndDate: [futureDate, futureDate]
  };
};

export const getMockSettlementOrderFilters = (): OrdersFilter => {
  const orderTypes = Object.values(OrderType);
  const randomIndex = faker.number.int({ min: 0, max: orderTypes.length - 1 });

  return {
    locations: [{ label: 'airport', value: 'city_1' }],
    payers: [{ label: 'payer', value: 1 }],
    ordersAircrafts: [{ label: 'aircraft', value: 1 }],
    suppliers: [{ label: 'supplier', value: 1 }],
    orderTypes: [orderTypes[randomIndex]],
    orderStartDate: [pastDate, recentDate],
    orderEndDate: [futureDate, futureDate]
  };
};
