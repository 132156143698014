import React from 'react';
import { Descriptions, DescriptionsProps } from 'antd';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import { ContractPaymentType, PaymentHydrated } from 'services/api/data-contracts';
import { getUTCDate, getAmountValue } from 'helpers';

interface IProps {
  payment: PaymentHydrated;
}

export const PaymentView = ({ payment }: IProps) => {
  const generalDescriptions: DescriptionsProps['items'] = [
    {
      label: payment.type === ContractPaymentType.Outgoing ? 'Supplier' : 'Payer',
      children:
        payment.type === ContractPaymentType.Outgoing ? payment.supplier?.name : payment.payer?.name
    },
    {
      label: 'Bank Acc',
      children:
        payment.type === ContractPaymentType.Outgoing
          ? payment.supplierBankAccount
          : payment.payerBankAccount,
      span: 2
    },
    {
      label: 'Planned',
      children: payment.plannedDate ? getUTCDate(payment.plannedDate).format(DATE_FORMAT) : '-'
    },
    {
      label: 'Received',
      children: payment.receivedDate ? getUTCDate(payment.receivedDate).format(DATE_FORMAT) : '-',
      span: 2
    },
    { label: 'Currency', children: payment.currency },
    {
      label: 'Amount',
      children: getAmountValue(payment.amount)
    },
    {
      label: 'Advance',
      children: getAmountValue(payment.advance)
    },
    {
      label: 'Notes',
      children: <span style={{ whiteSpace: 'pre-line' }}>{payment.notes || '-'}</span>,
      span: 3
    }
  ];

  return (
    <Descriptions
      items={generalDescriptions}
      size={CONTROL_SIZE}
      column={3}
      bordered
      data-testid="payment-details-view"
    />
  );
};
