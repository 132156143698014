/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Error,
  FlightPurpose,
  FlightPurposeInput,
  FlightPurposeListResponse,
  ValidationError
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class FlightPurposes<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Get a list of flight purposes
   *
   * @tags flight purposes
   * @name GetFlightPurposes
   * @summary Get a list of flight purposes
   * @request GET:/flight-purposes
   * @secure
   */
  getFlightPurposes = (params: RequestParams = {}) =>
    this.http.request<FlightPurposeListResponse, Error>({
      path: `/flight-purposes`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Create a new flight purpose
   *
   * @tags flight purposes
   * @name AddFlightPurpose
   * @summary Create a new flight purpose
   * @request POST:/flight-purposes
   * @secure
   */
  addFlightPurpose = (data: FlightPurposeInput, params: RequestParams = {}) =>
    this.http.request<FlightPurpose, ValidationError | Error>({
      path: `/flight-purposes`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Get a specific flight purpose
   *
   * @tags flight purposes
   * @name GetFlightPurposeById
   * @summary Get a specific flight purpose
   * @request GET:/flight-purposes/{flightPurposeId}
   * @secure
   */
  getFlightPurposeById = (flightPurposeId: number, params: RequestParams = {}) =>
    this.http.request<FlightPurpose, Error>({
      path: `/flight-purposes/${flightPurposeId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Update a specific flight purpose
   *
   * @tags flight purposes
   * @name UpdateFlightPurpose
   * @summary Update a specific flight purpose
   * @request PUT:/flight-purposes/{flightPurposeId}
   * @secure
   */
  updateFlightPurpose = (
    flightPurposeId: number,
    data: FlightPurposeInput,
    params: RequestParams = {}
  ) =>
    this.http.request<FlightPurpose, ValidationError | Error>({
      path: `/flight-purposes/${flightPurposeId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Delete a specific flight purpose
   *
   * @tags flight purposes
   * @name DeleteFlightPurpose
   * @summary Delete a specific flight purpose
   * @request DELETE:/flight-purposes/{flightPurposeId}
   * @secure
   */
  deleteFlightPurpose = (flightPurposeId: number, params: RequestParams = {}) =>
    this.http.request<void, ValidationError | Error>({
      path: `/flight-purposes/${flightPurposeId}`,
      method: 'DELETE',
      secure: true,
      ...params
    });
}
