import React from 'react';
import { Form } from 'antd';
import { Airport } from 'services/api/data-contracts';
import { AirportSelect } from './AirportSelect';

interface IProps {
  name: string;
  label: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  className?: string;
  onSetAirport?: (airport?: Airport) => void;
  onChange?: (value: number) => void;
  initialFilter?: string;
}

export const AirportInput = ({
  disabled,
  label,
  name,
  placeholder,
  required,
  className,
  onSetAirport,
  initialFilter = '',
  onChange
}: IProps) => (
  <Form.Item name={name} label={label} rules={[{ required }]} className={className}>
    <AirportSelect
      id={name}
      disabled={disabled}
      placeholder={placeholder}
      initialFilter={initialFilter}
      onSetAirport={onSetAirport}
      onChange={onChange}
    />
  </Form.Item>
);
