import { bake_cookie, delete_cookie, read_cookie } from 'sfcookies';

export interface ITokenStore {
  extractToken: () => string | null;
  setToken: (token: string) => void;
  getToken: () => string | undefined;
  clearToken: () => void;
}

export class TokenStore implements ITokenStore {
  tokenName = '';

  constructor(tokenName: string) {
    this.tokenName = tokenName;
  }

  extractToken() {
    const hash = window.location.hash
      .replace('#', '')
      .split('&')
      .reduce(function (res, item) {
        const parts = item.split('=');
        Object.assign(res, { [parts[0]]: parts[1] });
        return res;
      }, {});

    return this.tokenName in hash ? hash[this.tokenName as keyof typeof hash] : null;
  }

  clearToken() {
    delete_cookie(this.tokenName);
  }

  getToken() {
    const cookie = read_cookie(this.tokenName);

    if (Array.isArray(cookie) && !cookie.length) {
      return;
    }

    return cookie.toString();
  }

  setToken(token: string) {
    bake_cookie(this.tokenName, token);
  }
}

export const accessTokenStore = new TokenStore('access_token');
