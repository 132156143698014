import React from 'react';
import { Order, OrderStatus, Program } from 'services/api/data-contracts';
import { ColumnsType } from 'antd/es/table';
import { Flex, Tooltip } from 'antd';
import { OrderStatusTag } from '../../components/OrderStatusTag';
import { DATE_FORMAT, TIME_FORMAT } from 'consts/common';
import { getUTCDate } from 'helpers';
import styled from 'styled-components';

interface DecoratedText {
  $isCancelled: boolean;
}

const DecoratedText = styled('span')<DecoratedText>`
  text-decoration: ${(props) => (props.$isCancelled ? 'line-through' : 'none')};
  color: ${(props) => (props.$isCancelled ? '#00000073' : 'inherit')};
`;

export const getColumns = (): ColumnsType<
  Program & {
    mainOrder?: Order;
  }
> => [
  {
    title: '',
    dataIndex: 'status',
    key: 'status',
    render: (value) => <OrderStatusTag status={value} />,
    width: 52
  },
  {
    title: '',
    dataIndex: 'type',
    key: 'type',
    width: 35,
    render: (_, record) => (
      <DecoratedText $isCancelled={record.status === OrderStatus.CNLD}>
        {`${record.mainOrder?.type[0]}p`}
      </DecoratedText>
    ),
    align: 'center'
  },
  {
    title: 'N',
    dataIndex: 'programNumber',
    key: 'programNumber',
    render: (value, record) => (
      <Tooltip title={value}>
        <DecoratedText $isCancelled={record.status === OrderStatus.CNLD}>{value}</DecoratedText>
      </Tooltip>
    ),
    ellipsis: true
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    render: (_, record) => {
      const location = record.mainOrder?.location;

      if (!location) return '-';

      return (
        <Tooltip title={location.searchName}>
          <DecoratedText $isCancelled={record.status === OrderStatus.CNLD}>
            {'code' in location ? location.code : location.name}
          </DecoratedText>
        </Tooltip>
      );
    },
    width: 70,
    ellipsis: true
  },
  {
    title: 'Customer',
    dataIndex: 'customer',
    key: 'customer',
    render: (_, record) => {
      const customer = record.mainOrder?.customer;

      if (!customer) return '-';

      return (
        <Tooltip title={customer.name}>
          <DecoratedText $isCancelled={record.status === OrderStatus.CNLD}>
            {customer.name}
          </DecoratedText>
        </Tooltip>
      );
    },
    width: 80,
    ellipsis: true
  },
  {
    title: 'Period',
    dataIndex: 'period',
    key: 'period',
    width: 190,
    render: (_, record) => {
      const startDate = getUTCDate(record.startDatetime);
      const endDate = getUTCDate(record.endDatetime);

      const dateRange = startDate.format(DATE_FORMAT) + ' - ' + endDate.format(DATE_FORMAT);
      const timeRange = startDate.format(TIME_FORMAT) + ' - ' + endDate.format(TIME_FORMAT);

      return (
        <Flex vertical>
          <DecoratedText $isCancelled={record.status === OrderStatus.CNLD}>
            {dateRange}
          </DecoratedText>
          <DecoratedText $isCancelled={record.status === OrderStatus.CNLD}>
            {timeRange}
          </DecoratedText>
        </Flex>
      );
    }
  }
];
