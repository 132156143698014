import { Button, DatePicker, Flex, Form, Typography } from 'antd';
import { CONTROL_SIZE, DATE_FORMAT, SERVER_DATE_FORMAT } from 'consts/common';
import React from 'react';
import { useFlightProgramStore } from 'store/flightProgramStore';
import styled from 'styled-components';
import { useCloneFlightProgramOrders } from 'queries/flightProgram';
import { Dayjs } from 'dayjs';

const StyledForm = styled(Form<{ dates: Dayjs[] }>)`
  .ant-form-item {
    margin-bottom: 20px;
  }

  .ant-form-item-label {
    padding-bottom: 0 !important;
    white-space: nowrap !important;
  }
`;

export const ExtendProgramForm = () => {
  const [form] = Form.useForm<{ dates: Dayjs[] }>();

  const { setOrdersCloneMode, openProgramId } = useFlightProgramStore();

  const mutation = useCloneFlightProgramOrders();

  const handleSubmit = async () => {
    await form.validateFields();

    const { dates } = form.getFieldsValue();

    if (openProgramId && dates) {
      await mutation.mutateAsync({
        programId: openProgramId,
        dates: dates.map((date: Dayjs) => date.format(SERVER_DATE_FORMAT))
      });
      setOrdersCloneMode(false);
    }
  };

  return (
    <StyledForm form={form} layout="vertical">
      <Flex vertical style={{ margin: '20px 0' }}>
        <Typography.Title
          level={5}
          style={{ fontSize: 14, lineHeight: '22px', fontWeight: 700, marginTop: 0 }}>
          Orders adding
        </Typography.Title>

        <Form.Item label="Arrival dates" name="dates" required>
          <DatePicker
            multiple
            size={CONTROL_SIZE}
            format={DATE_FORMAT}
            autoFocus
            onChange={(value) => form.setFieldValue('dates', value)}
            disabled={mutation.isLoading}
          />
        </Form.Item>

        <Flex justify="end" gap={8}>
          <Button
            size={CONTROL_SIZE}
            onClick={() => {
              setOrdersCloneMode(false);
            }}
            loading={mutation.isLoading}>
            Cancel
          </Button>
          <Button
            size={CONTROL_SIZE}
            type="primary"
            onClick={handleSubmit}
            loading={mutation.isLoading}>
            Add
          </Button>
        </Flex>
      </Flex>
    </StyledForm>
  );
};
