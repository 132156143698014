import React from 'react';
import { RiReplyLine as Reply, RiReplyAllLine as ReplyAll } from 'react-icons/ri';
import { NewMessageMode } from 'store/messageStore';
import { ACTION } from '../constants';

export const getTitle = (newMessageMode: NewMessageMode) => {
  if (newMessageMode === ACTION.REPLY) {
    return <Reply />;
  }

  if (newMessageMode === ACTION.REPLY_ALL) {
    return <ReplyAll />;
  }

  if (newMessageMode === ACTION.FORWARD) {
    return <Reply style={{ transform: 'scaleX(-1)' }} />;
  }

  return 'New message';
};
