import React, { useMemo, useState } from 'react';
import { Form, Select } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { useFlightPurposeStore } from 'store/flightPurposeStore';
import { useGetFlightPurposes } from 'queries/flightPurpose';
import { sortBy } from 'lodash';

interface IProps {
  label?: string;
  name?: string | (string | number)[];
  required?: boolean;
  onChange?: (value: number) => void;
}

export const FlightPurposeInput = ({
  label = 'Flight Purpose',
  name = 'flightPurposeId',
  required,
  onChange
}: IProps) => {
  const [filter, setFilter] = useState('');

  const { list } = useFlightPurposeStore(({ list }) => ({ list }));
  const { isLoading } = useGetFlightPurposes();

  const options = useMemo(
    () =>
      sortBy(list, (o) => [!o.isActive, o.name]).map((purpose) => ({
        label: purpose.name,
        value: purpose.id,
        disabled: !purpose.isActive
      })),
    [list]
  );

  const handleBlur = () => {
    const filteredOptions = options.filter((o) =>
      o.label.toLowerCase().includes(filter.toLowerCase())
    );

    if (filteredOptions.length === 1 && onChange) {
      onChange(filteredOptions[0].value);
    }
  };

  return (
    <Form.Item label={label} name={name} rules={[{ required }]}>
      <Select
        showSearch
        optionFilterProp="label"
        onSearch={setFilter}
        onBlur={handleBlur}
        style={{ width: '100%' }}
        placeholder="Select flight purpose"
        options={options}
        size={CONTROL_SIZE}
        loading={isLoading}
      />
    </Form.Item>
  );
};
