import React, { useContext, useMemo } from 'react';
import { ConfigProvider, Drawer, Form } from 'antd';
import styled from 'styled-components';
import { useInvoiceStore } from 'store/invoiceStore';
import { CreditNoteInput, PaymentHydrated } from 'services/api/data-contracts';
import { DashboardContext, DashboardType } from '../../../consts';
import { useCreatePayment, useUpdatePayment } from 'queries/payment';
import { transformForRequest } from 'pages/widgets/components/CreditNotesDrawer/helpers/transformForRequest';
import { HeaderButtons } from '../HeaderButtons';

import { CreditNoteForm } from './CreditNoteForm';

const StyledDrawer = styled(Drawer)`
  .ant-drawer-header {
    box-shadow:
      0 6px 6px 0 #00000014,
      0 3px 6px -4px #0000001f;
    padding: 8px;
  }

  .ant-drawer-body {
    padding: 24px 8px 8px;
  }

  .ant-drawer-header {
    border: none;
  }
`;

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  isFullScreenMode?: boolean;
}

export const CreditNoteDrawer = ({ isOpen, onClose, isFullScreenMode }: IProps) => {
  const [form] = Form.useForm<Partial<PaymentHydrated> & { creditNoteItems: CreditNoteInput[] }>();

  const { type: dashboardType } = useContext(DashboardContext);

  const { current: invoice, currentCreditNote: creditNote } = useInvoiceStore(
    ({ current, currentCreditNote }) => ({
      current,
      currentCreditNote
    })
  );

  const createMutation = useCreatePayment(onClose);
  const updateMutation = useUpdatePayment(onClose);

  const handleSubmit = async () => {
    try {
      await form.validateFields();

      if (!creditNote?.id && invoice) {
        createMutation.mutate({
          ...transformForRequest(form.getFieldsValue(true), invoice)
        });
      } else if (creditNote?.correctedInvoiceId) {
        updateMutation.mutate({
          ...(creditNote as PaymentHydrated),
          items: form.getFieldValue('creditNoteItems')
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const drawerTitle = useMemo(
    () =>
      creditNote?.id
        ? `${creditNote.correctedInvoiceId ? 'CN: Invoice correction' : 'Credit note'} editing`
        : 'Credit note creation',
    [creditNote]
  );

  const isRenderInContainer = dashboardType === DashboardType.Settlement && !isFullScreenMode;

  return (
    <>
      <StyledDrawer
        open={isOpen}
        onClose={() => onClose()}
        width={700}
        title={drawerTitle}
        closable={false}
        extra={
          <HeaderButtons
            onCancel={() => onClose()}
            onSubmit={handleSubmit}
            isLoading={updateMutation.isLoading || createMutation.isLoading}
          />
        }
        {...(isRenderInContainer ? { getContainer: false } : {})}>
        <ConfigProvider form={{ validateMessages: { required: 'Is required' } }}>
          <CreditNoteForm form={form} />
        </ConfigProvider>
      </StyledDrawer>
    </>
  );
};
