import React, { useContext, useState } from 'react';
import { Space } from 'antd';
import { useInvoiceStore } from 'store/invoiceStore';
import { ButtonWithConfirm } from './ButtonWithConfirm';
import { CancelButtonWithConfirm, CloseButton, EditButton } from 'components/Drawer';
import { OpenBalanceButton } from './OpenBalanceButton';
import { useMessageStore } from 'store/messageStore';
import { useCloseOnEsc, useCtrlEnterShortcut, useCtrlSShortcut } from 'helpers';
import { useDrawerStore } from 'store/drawerStore';
import { ChangeStatusButton } from './ChangeStatusButton';
import { DashboardContext, DashboardType } from 'pages/consts';
import { ExportDocumentsButton } from 'pages/widgets/components/ExportDocumentsButton';
import { InvoiceStatus, InvoiceType } from 'services/api/data-contracts';

interface IProps {
  isLoading: boolean;
  onSubmit: () => Promise<void>;
  onClose: () => void;
  isWarning?: boolean;
  isFormTouched?: boolean;
  isFullScreenMode?: boolean;
}

export const HeaderButtons = ({
  isLoading,
  onSubmit,
  onClose,
  isWarning,
  isFormTouched,
  isFullScreenMode
}: IProps) => {
  const [isReadyForSendingInProgress, setReadyForSendingInProgress] = useState(false);

  const { isInvoiceEditMode, setInvoiceEditMode, current } = useInvoiceStore(
    ({ isInvoiceEditMode, setInvoiceEditMode, current }) => ({
      isInvoiceEditMode,
      setInvoiceEditMode,
      current
    })
  );
  const { newMessageMode } = useMessageStore(({ newMessageMode }) => ({ newMessageMode }));
  const { isPaymentDrawerOpen } = useDrawerStore(({ openDrawers }) => ({
    isPaymentDrawerOpen: openDrawers.includes('payment')
  }));

  useCtrlEnterShortcut(() => handleSubmit(true), !newMessageMode);
  useCtrlSShortcut(() => handleSubmit(false), !newMessageMode);
  useCloseOnEsc(onClose, (!isInvoiceEditMode || !isFormTouched) && !isPaymentDrawerOpen);

  const { type: dashboardType } = useContext(DashboardContext);

  const handleSubmit = async (shouldClose?: boolean) => {
    try {
      await onSubmit();
      shouldClose && handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  const handleClose = () => {
    setInvoiceEditMode(false);
    onClose();
  };

  if (isInvoiceEditMode) {
    return (
      <Space>
        <CancelButtonWithConfirm
          onConfirm={handleClose}
          isLoading={isLoading}
          isFormTouched={isFormTouched}
        />

        <ButtonWithConfirm
          onSubmit={() => handleSubmit(false)}
          isLoading={isLoading}
          isWarning={isWarning}
          title={current?.id ? 'Save' : 'Create'}
        />

        <ButtonWithConfirm
          onSubmit={() => handleSubmit(true)}
          isLoading={isLoading}
          isWarning={isWarning}
          title={`${current?.id ? 'Save' : 'Create'} and close`}
          isPrimaryButton
        />
      </Space>
    );
  }

  return (
    <Space align="center">
      {dashboardType === DashboardType.Settlement &&
        !isFullScreenMode &&
        current?.type === InvoiceType.Issued &&
        current?.status === InvoiceStatus.RFSN && (
          <ExportDocumentsButton isLoading={isReadyForSendingInProgress} />
        )}

      <OpenBalanceButton isLoading={isLoading} />

      <ChangeStatusButton setReadyForSendingInProgress={setReadyForSendingInProgress} />

      <EditButton
        onClick={() => setInvoiceEditMode(true)}
        isLoading={isLoading}
        dataTestId={'edit-invoice-btn'}
      />

      <CloseButton onClick={onClose} />
    </Space>
  );
};
