import { ColumnsType } from 'antd/es/table';
import { numericSorter, stringSorter } from 'pages/Dictionaries/utils/tableSorters';
import { Aircraft } from 'services/api/data-contracts';

export const getColumns = (): ColumnsType<Aircraft> => [
  {
    title: 'Tail №',
    dataIndex: 'tailNumber',
    key: 'tailNumber',
    render: (value) => value || '-',
    sorter: (a, b) => stringSorter(a.tailNumber || '-', b.tailNumber || '-')
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (value) => value || '-',
    sorter: (a, b) => stringSorter(a.type || '-', b.type || '-')
  },
  {
    title: 'Modification',
    dataIndex: 'modification',
    key: 'modification',
    render: (value) => value || '-',
    ellipsis: true
  },
  {
    title: 'MTOW kg',
    dataIndex: 'maxTakeoffWeight',
    key: 'maxTakeoffWeight',
    render: (value) => value?.toLocaleString('en-US') || '-',
    sorter: (a, b) => numericSorter(a.maxTakeoffWeight || 0, b.maxTakeoffWeight || 0)
  }
];
