import { useFlightProgramStore } from 'store/flightProgramStore';
import { CollapseProps, Descriptions, DescriptionsProps, Spin } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { StyledCollapse } from 'components/common/StyledCollapse';
import React from 'react';
import styled from 'styled-components';
import { ProgramNotes } from './ProgramNotes';

const SpinWrapper = styled('div')`
  height: 126px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProgramDetails = () => {
  const { current } = useFlightProgramStore();

  const descriptions: DescriptionsProps['items'] = [
    {
      label: 'Customer',
      children: current?.mainOrder.customer.name
    },
    {
      label: 'Operator',
      children: current?.mainOrder.operator?.name
    },
    {
      label: 'Payer',
      children: current?.mainOrder.payer?.name
    },
    ...(current?.mainOrder.location
      ? [
          {
            label:
              'code' in current.mainOrder.location
                ? 'Airport'
                : 'country' in current.mainOrder.location
                  ? 'City'
                  : 'Country',
            children: current.mainOrder.location.searchName
          }
        ]
      : []),
    {
      label: 'Notes',
      children: <ProgramNotes value={current?.notes || undefined} programId={Number(current?.id)} />
    }
  ];

  const collapseContent: CollapseProps['items'] = [
    {
      key: 'general',
      label: current
        ? [
            current.mainOrder.customer.name,
            current?.mainOrder.operator?.name,
            current.mainOrder.payer?.name
          ]
            .filter(Boolean)
            .join(', ')
        : 'Program details',
      children: current ? (
        <Descriptions size={CONTROL_SIZE} items={descriptions} column={2} bordered />
      ) : (
        <SpinWrapper>
          <Spin />
        </SpinWrapper>
      )
    }
  ];

  return (
    <StyledCollapse
      items={collapseContent}
      expandIconPosition="end"
      size={CONTROL_SIZE}
      defaultActiveKey="general"
      ghost
    />
  );
};
