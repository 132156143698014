import { create } from 'zustand';
import { Contract, SupplierService } from 'services/api/data-contracts';
import { devtools } from 'zustand/middleware';
import { Dayjs } from 'dayjs';
import { getUTCDate } from 'helpers';

export type ContractWithDayJs = Omit<
  Contract,
  'endDate' | 'startDate' | 'createdAt' | 'updatedAt'
> & {
  endDate: Dayjs | null;
  startDate: Dayjs | null;
  createdAt: Dayjs | null;
  updatedAt: Dayjs | null;
};

type ContractStore = {
  current?: Partial<ContractWithDayJs>;
  setCurrent: (current?: Partial<Contract>) => void;
  list: Contract[];
  setList: (list: Contract[]) => void;
  service?: Partial<SupplierService>;
  setService: (service?: Partial<SupplierService>) => void;
  services: SupplierService[];
  setServices: (services: SupplierService[]) => void;
};

export const useContractStore = create<ContractStore>()(
  devtools((set) => ({
    current: undefined,
    setCurrent: (current) => {
      const currentWithDayJs = current
        ? {
            ...current,
            endDate: current?.endDate ? getUTCDate(current?.endDate) : null,
            startDate: current?.startDate ? getUTCDate(current?.startDate) : null,
            createdAt: current?.createdAt ? getUTCDate(current?.createdAt) : null,
            updatedAt: current?.updatedAt ? getUTCDate(current?.updatedAt) : null
          }
        : undefined;

      set({ current: currentWithDayJs });
    },
    list: [],
    setList: (list) => {
      set({ list });
    },
    service: undefined,
    setService: (service) => {
      set({ service });
    },
    services: [],
    setServices: (services) => {
      set({ services });
    }
  }))
);
