import { Form, Select } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { CONTROL_SIZE } from 'consts/common';
import { useContractStore } from 'store/contractStore';

interface IProps {
  contractId: number;
  onSetCurrency: (currency: string) => void;
}

export const InvoiceCurrencyInput = ({ contractId, onSetCurrency }: IProps) => {
  const { contracts } = useContractStore(({ list }) => ({ contracts: list }));

  const currencies = useMemo(
    () => contracts.find((contract) => contract.id === contractId)?.currencies || [],
    [contracts, contractId]
  );

  useEffect(() => {
    if (currencies?.length === 1) {
      onSetCurrency(currencies[0]);
    }
  }, [currencies]);

  const contractCurrencies = useMemo(
    () =>
      currencies.map((curr) => ({
        label: curr,
        value: curr
      })),
    [currencies]
  );

  return (
    <Form.Item name="currency" label="Currency" rules={[{ required: true }]}>
      <Select
        style={{ width: '100%' }}
        options={contractCurrencies}
        size={CONTROL_SIZE}
        placeholder="Select currency"
      />
    </Form.Item>
  );
};
