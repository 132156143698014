import { Descriptions, DescriptionsProps } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import React, { useContext } from 'react';
import { DataContext } from '../DictionaryDetails';
import { UnitOfMeasure } from 'services/api/data-contracts';

export const UnitOfMeasureDictionaryView = () => {
  const data = useContext(DataContext) as UnitOfMeasure;

  if (!data) {
    return null;
  }

  const descriptions: DescriptionsProps['items'] = [
    {
      label: 'Active',
      span: 2,
      children: data.isActive ? 'Yes' : 'No'
    },
    {
      label: 'Name',
      children: data.name
    },
    {
      label: 'Symbol',
      children: data.shortName
    },
    {
      label: 'Notes',
      span: 2,
      children: <span style={{ whiteSpace: 'pre-line' }}>{data.notes || '-'}</span>
    }
  ];

  return <Descriptions size={CONTROL_SIZE} items={descriptions} column={2} bordered />;
};
