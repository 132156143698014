import React from 'react';
import { CreditNoteStatus, InvoiceStatus } from 'services/api/data-contracts';
import { Tag, Tooltip } from 'antd';
import { geekblue, gold, gray, green, purple, red } from '@ant-design/colors';
import styled from 'styled-components';

export type DocumentStatus = InvoiceStatus | CreditNoteStatus | 'P' | 'NP';

const StyledTag = styled(Tag)`
  margin-inline-end: 0;
`;

const documentStatusColors: Record<DocumentStatus, { tag: string; border: string; font: string }> =
  {
    [InvoiceStatus.RFSN]: { tag: purple[1], border: purple[5], font: purple[5] },
    [InvoiceStatus.SENT]: { tag: green[7], border: green[8], font: '#FFF' },
    [InvoiceStatus.CNLD]: { tag: red[0], border: red[7], font: red[7] },
    [InvoiceStatus.RINV]: { tag: gold[4], border: gold[6], font: 'inherit' },
    P: { tag: geekblue[1], border: geekblue[5], font: geekblue[5] },
    NP: { tag: gray[2], border: gray[7], font: gray[7] }
  };

export const documentStatusTitles: Record<DocumentStatus, string> = {
  [InvoiceStatus.RFSN]: 'Ready for sending',
  [InvoiceStatus.SENT]: 'Is sent',
  [InvoiceStatus.CNLD]: 'Canceled',
  [InvoiceStatus.RINV]: 'Reinvoiced',
  P: 'Posted',
  NP: 'Not posted'
};

interface IProps {
  status: DocumentStatus;
}

export const DocumentStatusTag = ({ status }: IProps) => (
  <Tooltip title={documentStatusTitles[status]}>
    <StyledTag
      color={documentStatusColors[status].tag}
      style={{
        color: documentStatusColors[status].font,
        borderColor: documentStatusColors[status].border
      }}>
      {status === InvoiceStatus.RFSN ? 'RFS' : status === InvoiceStatus.SENT ? 'IS' : status}
    </StyledTag>
  </Tooltip>
);
