import React from 'react';
import { createBrowserRouter, createRoutesFromElements, redirect, Route } from 'react-router-dom';
import { DispatcherDashboard } from 'pages/DispatcherDashboard';
import { AppLayout } from 'components/AppLayout';
import { accessTokenStore } from 'services/auth/TokenStore';
import { getLoginUrl } from 'services/auth/endpointHelpers';
import { Dictionaries } from 'pages/Dictionaries/Dictionaries';
import { SettlementDashboard } from '../pages/SettlementDashboard/SettlementDashboard';
import { BalanceDashboard } from 'pages/BalanceDashboard/BalanceDashboard';

const loader = async () => {
  const newToken = accessTokenStore.extractToken();
  const storedToken = accessTokenStore.getToken();

  if (storedToken) {
    return null;
  }

  if (newToken && !storedToken) {
    accessTokenStore.setToken(newToken);
  }

  if (!newToken && !storedToken) {
    return redirect(getLoginUrl());
  }

  return null;
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="*" element={<AppLayout />} loader={loader}>
      <Route path="dashboard">
        <Route element={<DispatcherDashboard />} path="dispatcher/:entityType?/:entityId?" />
        <Route element={<SettlementDashboard />} path="settlement/:entityType?/:entityId?" />
      </Route>

      <Route element={<BalanceDashboard />} path="balance/:entityType?/:entityId?" />

      <Route element={<Dictionaries />} path="dictionaries/:dictionaryName?/:entityId?" />
    </Route>
  )
);
