import { DictionaryName, DictionaryTypesMap, TDictionaryColumns } from '../models';
import {
  getFlightTypeColumns,
  getFlightPurposeColumns,
  getServiceColumns,
  getCityColumns,
  getCountryColumns,
  getAirportColumns,
  getUnitOfMeasureColumns,
  getServiceNameColumns,
  getAircraftColumns,
  getCounterpartyColumns,
  getContractColumns,
  getCurrencyRateColumns,
  getVatRateColumns,
  getOwnPriceColumns,
  getRebateColumns
} from '../columnConfigs';
import { settingsStore } from 'services/settings/SettingsStore';

export const useDictionaryColumns = (name: DictionaryName, filterByName?: string) => {
  const currentTenant = settingsStore.getCurrentTenant();

  switch (name) {
    case 'aircraft':
      return getAircraftColumns() as TDictionaryColumns<DictionaryTypesMap['aircraft']>;
    case 'airport':
      return getAirportColumns() as TDictionaryColumns<DictionaryTypesMap['airport']>;
    case 'city':
      return getCityColumns() as TDictionaryColumns<DictionaryTypesMap['city']>;
    case 'contract':
      return getContractColumns() as TDictionaryColumns<DictionaryTypesMap['contract']>;
    case 'counterparty':
      return getCounterpartyColumns() as TDictionaryColumns<DictionaryTypesMap['counterparty']>;
    case 'country':
      return getCountryColumns() as TDictionaryColumns<DictionaryTypesMap['country']>;
    case 'currency-rate':
      return getCurrencyRateColumns(
        currentTenant.mainCurrency || '',
        currentTenant.allowedCurrencies || [],
        filterByName
      ) as TDictionaryColumns<DictionaryTypesMap['currency-rate']>;
    case 'flight-type':
      return getFlightTypeColumns() as TDictionaryColumns<DictionaryTypesMap['flight-type']>;
    case 'flight-purpose':
      return getFlightPurposeColumns() as TDictionaryColumns<DictionaryTypesMap['flight-purpose']>;
    case 'own-price':
      return getOwnPriceColumns() as TDictionaryColumns<DictionaryTypesMap['own-price']>;
    case 'rebate':
      return getRebateColumns() as TDictionaryColumns<DictionaryTypesMap['rebate']>;
    case 'service':
      return getServiceColumns() as TDictionaryColumns<DictionaryTypesMap['service']>;
    case 'service-name':
      return getServiceNameColumns() as TDictionaryColumns<DictionaryTypesMap['service-name']>;
    case 'unit-of-measure':
      return getUnitOfMeasureColumns() as TDictionaryColumns<DictionaryTypesMap['unit-of-measure']>;
    case 'vat-rate':
      return getVatRateColumns() as TDictionaryColumns<DictionaryTypesMap['vat-rate']>;
  }
};
