import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { BalanceViewResponse, Invoice, Payment } from 'services/api/data-contracts';
import { RangeValue } from 'store/helpers/types';

export interface BalanceFilter {
  currency?: string;
  contractDate?: RangeValue;
  counterpartyId?: number;
  contractIds?: string;
}

type BalanceDashboardStore = {
  data?: BalanceViewResponse;
  setData: (data?: BalanceViewResponse) => void;
  filter: BalanceFilter;
  setFilter: (filter: BalanceFilter) => void;
  selectedEntities: (Invoice | Payment)[];
  setSelectedEntities: (selectedEntities: (Invoice | Payment)[]) => void;
  isContractViewMode: boolean;
  setContractViewMode: (isContractViewMode: boolean) => void;
  shouldUpdateBalanceView: boolean;
  setShouldUpdateBalanceView: (shouldUpdateBalanceView: boolean) => void;
};

export const useBalanceDashboardStore = create<BalanceDashboardStore>()(
  devtools((set) => ({
    data: undefined,
    setData: (data) => {
      set({ data });
    },
    filter: {},
    setFilter: (filter) => {
      set({ filter });
    },
    selectedEntities: [],
    setSelectedEntities: (selectedEntities) => {
      set({ selectedEntities });
    },
    isContractViewMode: false,
    setContractViewMode: (isContractViewMode) => {
      set({ isContractViewMode });
    },
    shouldUpdateBalanceView: false,
    setShouldUpdateBalanceView: (shouldBalanceWidgetUpdate) => {
      set({ shouldUpdateBalanceView: shouldBalanceWidgetUpdate });
    }
  }))
);
