import React, { useEffect, useMemo, useState } from 'react';
import { Button, Dropdown, Empty, MenuProps, Table } from 'antd';
import { CONTROL_SIZE, PAGE_SIZE_50 } from 'consts/common';
import { getColumns } from './columns';
import styled from 'styled-components';
import { sortBy } from 'lodash';
import { PaymentPurposeEditModeBase, usePaymentPurposeStore } from 'store/paymentPurposeStore';
import {
  ContractPaymentType,
  Payment,
  PaymentPurposeHydrated,
  InvoiceType
} from 'services/api/data-contracts';
import { useGetPaymentPurposes } from 'queries/paymentPurpose/useGetPaymentPurposes';
import { useDeletePaymentPurpose } from 'queries/paymentPurpose/useDeletePaymentPurpose';
import { PaymentCreationMode, usePaymentStore } from 'store/paymentStore';
import { paymentInitialData } from 'consts/payment';
import { useInvoiceStore } from 'store/invoiceStore';
import { PlusOutlined } from '@ant-design/icons';
import { getAmountValue } from 'helpers';
import { useNavigate } from 'react-router-dom';
import { useDrawerStore } from 'store/drawerStore';

const StyledTableWrapper = styled('div')`
  .ant-spin-container {
    height: fit-content !important;
  }

  .ant-table-tbody {
    padding: 6px !important;
  }

  .ant-table-tbody > tr {
    height: 35px;
  }

  .ant-table-tbody > tr > td {
    padding: 6px !important;
  }

  .ant-table-thead > tr > th {
    padding: 6px !important;
    background: #fafafa;
  }

  #actions {
    display: none;
    position: absolute;
    right: 0;
  }

  #actions > button {
    margin-right: 4px;
  }

  .ant-table-cell-row-hover #actions {
    display: block;
  }

  .ant-table-summary tr:last-of-type .ant-table-cell {
    padding-left: 0 !important;
    border-bottom: none;
  }
`;

export const PaymentPurposesTable = () => {
  const [page, setPage] = useState(1);

  const navigate = useNavigate();

  const { current: invoice } = useInvoiceStore(({ current }) => ({
    current
  }));
  const { paymentPurposes, total, isCreationMode } = usePaymentPurposeStore(
    ({ list, total, isCreationMode }) => ({
      paymentPurposes: list,
      total,
      isCreationMode
    })
  );
  const { setCreationMode, setCurrent, setShowRevertActionPopUp, setEditModeBase } =
    usePaymentPurposeStore(
      ({ setCreationMode, setCurrent, setShowRevertActionPopUp, setEditModeBase }) => ({
        setCreationMode,
        setCurrent,
        setShowRevertActionPopUp,
        setEditModeBase
      })
    );
  const { setPaymentEditMode, setCurrentPayment, setPaymentCreationMode } = usePaymentStore(
    ({ setPaymentEditMode, setCurrent: setCurrentPayment, setPaymentCreationMode }) => ({
      setPaymentEditMode,
      setCurrentPayment,
      setPaymentCreationMode
    })
  );
  const { isPaymentDrawerOpen, setDrawerOpen } = useDrawerStore(
    ({ openDrawers, setDrawerOpen }) => ({
      isPaymentDrawerOpen: openDrawers.includes('payment'),
      setDrawerOpen
    })
  );

  useEffect(() => {
    if (!isPaymentDrawerOpen) {
      const path = window.location.pathname.startsWith('/balance')
        ? '/balance'
        : '/dashboard/settlement';
      navigate(`${path}/invoice/${invoice?.id}${window.location.search}`);
    }
  }, [isPaymentDrawerOpen]);

  const { isLoading } = useGetPaymentPurposes({
    page,
    pageSize: PAGE_SIZE_50,
    invoiceId: invoice?.id
  });

  const deleteMutation = useDeletePaymentPurpose();

  const dataSource = useMemo(
    () =>
      paymentPurposes?.map((purpose: PaymentPurposeHydrated) => ({
        ...purpose,
        key: purpose.id
      })),
    [paymentPurposes]
  );

  const handleOpenDetails = (paymentPurpose: PaymentPurposeHydrated) => {
    setCurrentPayment({ id: paymentPurpose.paymentId });
    setDrawerOpen('payment');

    const path = window.location.pathname.startsWith('/balance')
      ? '/balance'
      : '/dashboard/settlement';
    navigate(`${path}/payment/${paymentPurpose.paymentId}${window.location.search}`);
  };

  const handleDelete = (paymentPurpose: PaymentPurposeHydrated) => {
    setCurrent(paymentPurpose);
    deleteMutation.mutate(paymentPurpose.id);
    setShowRevertActionPopUp('unlink');
  };

  const handleOpen = (paymentPurpose: PaymentPurposeHydrated) => {
    setCurrent(paymentPurpose);
    setDrawerOpen('paymentPurpose');
    setEditModeBase(PaymentPurposeEditModeBase.INVOICE);
  };

  const columns = useMemo(() => getColumns(handleDelete, handleOpen), []);
  const rows = sortBy(dataSource, 'createdAt');

  const items: MenuProps['items'] = [
    {
      label: 'Find an existing one',
      key: 'Find',
      onClick: () => {
        setCreationMode(true);
      }
    },
    {
      label: 'Create a new one',
      key: 'create',
      onClick: () => {
        setDrawerOpen('payment');
        setCurrentPayment({
          ...paymentInitialData,
          type:
            invoice?.type === InvoiceType.Issued
              ? ContractPaymentType.Incoming
              : ContractPaymentType.Outgoing,
          ...(invoice?.type === InvoiceType.Issued
            ? { payerId: invoice?.payerId }
            : { supplierId: invoice?.supplierId })
        } as Payment);
        setPaymentEditMode(true);
        setPaymentCreationMode(PaymentCreationMode.FROM_INVOICE);
      }
    }
  ];

  const addNewButton = (type: 'link' | 'default') => {
    if (type === 'link' && (invoice?.amountDue === 0 || isCreationMode)) {
      return null;
    }

    return (
      <Dropdown
        menu={{ items }}
        trigger={['click']}
        disabled={!invoice?.id || invoice?.amountDue === 0 || !invoice?.isPosted}>
        <Button type={type} size={CONTROL_SIZE} icon={type === 'link' && <PlusOutlined />}>
          Add a new one
        </Button>
      </Dropdown>
    );
  };

  if (!paymentPurposes?.length) {
    return (
      !isCreationMode && (
        <Empty description="Payments have not been added yet" image={Empty.PRESENTED_IMAGE_SIMPLE}>
          {addNewButton('default')}
        </Empty>
      )
    );
  }

  return (
    <StyledTableWrapper>
      <Table
        columns={columns}
        dataSource={rows}
        size={CONTROL_SIZE}
        loading={!!invoice?.id && isLoading}
        pagination={
          total > PAGE_SIZE_50
            ? {
                size: CONTROL_SIZE,
                total,
                pageSize: PAGE_SIZE_50,
                showSizeChanger: false,
                onChange: setPage,
                current: page,
                position: ['bottomCenter']
              }
            : false
        }
        summary={(rows) => {
          const totalAmount = rows
            .map((purpose) => purpose.paidAmountInInvoiceCurrency || purpose.paidAmount || 0)
            .reduce((acc, curr) => acc + curr);

          return (
            <Table.Summary fixed="bottom">
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={6} index={0} />
                <Table.Summary.Cell index={1} align="right">
                  <span style={{ fontWeight: 500 }}>{getAmountValue(totalAmount)}</span>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={7} index={0}>
                  {addNewButton('link')}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
        onRow={(record) => ({
          onClick: ({ target }) => {
            const isButton = (target as HTMLElement).closest('button');

            if (!isButton) {
              handleOpenDetails(record);
            }
          }
        })}
      />
    </StyledTableWrapper>
  );
};
