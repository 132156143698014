import { Descriptions, DescriptionsProps, Flex, Tabs, TabsProps } from 'antd';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import React, { useContext } from 'react';
import { DataContext } from '../DictionaryDetails';
import { ContractWithDayJs } from 'store/contractStore';
import { ContractServices } from './components/ContractServices/ContractServices';
import { ContractType } from 'services/api/data-contracts';
import { getAmountValue } from 'helpers';

export const ContractDictionaryView = () => {
  const data = useContext(DataContext) as unknown as ContractWithDayJs;

  const descriptions: DescriptionsProps['items'] = [
    {
      label: 'Type',
      children: data.type
    },
    {
      label: 'Number',
      children: data.number || '-'
    },
    {
      label: 'Start date',
      children: data.startDate ? data.startDate.format(DATE_FORMAT) : '-'
    },
    {
      label: 'End date',
      children: data.endDate ? data.endDate.format(DATE_FORMAT) : '-'
    },
    {
      label: 'Currencies',
      span: 2,
      children: data.currencies?.join(', ') || '-'
    },
    {
      label: 'Description',
      span: 2,
      children: data.description || '-'
    },
    {
      label: 'Customer',
      children: data.customer?.name || '-'
    },
    {
      label: 'Supplier',
      children: data.supplier?.name || '-'
    },
    ...(data.type === ContractType.WithCustomer
      ? [{ label: 'Agent fee', children: getAmountValue(data.agentFee || 0) }]
      : []),
    {
      label: 'Payment term',
      children: data.paymentTerm || '-'
    },
    {
      label: 'Days',
      children: data.dueDateCount || '-'
    },
    {
      label: 'after',
      children: data.dueDateCondition || '-'
    }
  ];

  const tabs: TabsProps['items'] = [
    {
      key: '1',
      label: 'Services',
      children: <ContractServices contractId={data.id} />
    }
  ];

  if (!data) {
    return null;
  }

  return (
    <Flex vertical gap={20}>
      <Descriptions size={CONTROL_SIZE} items={descriptions} column={2} bordered />

      {data.type === ContractType.WithSupplier && (
        <Tabs defaultActiveKey="1" items={tabs} style={{ marginBottom: 20 }} size={CONTROL_SIZE} />
      )}
    </Flex>
  );
};
