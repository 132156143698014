import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query';
import { messagesApi } from 'services/emailEngineApi';
import { MESSAGES_QUERY_KEY, MESSAGE_QUERY_KEY } from './consts';
import {
  MessageList,
  MessageListEntry,
  MessageUpdate
} from 'services/emailEngineApi/data-contracts';
import { useMessageStore } from 'store/messageStore';
import { FLAG } from 'pages/widgets/MessagesWidget/consts';
import { updateMessageFlags } from './helpers/updateMessageFlags';
import { settingsStore } from 'services/settings/SettingsStore';

const getMessageWithUpdatedFlags = (message: MessageListEntry, data: MessageUpdate) => {
  const updatedMessage = { ...message };

  updatedMessage.flags = updateMessageFlags(updatedMessage.flags, data.flags);

  if (updatedMessage.flags?.includes(FLAG.SEEN)) {
    delete updatedMessage.unseen;
  } else if (!updatedMessage.flags?.includes(FLAG.SEEN) && !updatedMessage.unseen) {
    updatedMessage.unseen = true;
  }

  return updatedMessage;
};

const updateMessage = async ({ messageId, data }: { messageId: string; data: MessageUpdate }) => {
  try {
    const response = await messagesApi.putV1AccountAccountMessageMessage(
      settingsStore.getMailboxSettings()?.mailbox_name || '',
      messageId,
      data
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching message:', error);
    throw error;
  }
};

export const useOptimisticUpdateMessage = (shouldRefetchMessage = true) => {
  const queryClient = useQueryClient();

  const {
    setList,
    list: listBeforeUpdate,
    setOptimisticUpdateMessage
  } = useMessageStore(({ setList, list, setOptimisticUpdateMessage }) => ({
    setList,
    list,
    setOptimisticUpdateMessage
  }));

  return useMutation({
    mutationFn: updateMessage,
    onMutate: async ({ messageId: updatedMessageId, data }) => {
      shouldRefetchMessage && (await queryClient.cancelQueries([MESSAGES_QUERY_KEY]));

      const messageForUpdate = listBeforeUpdate.find(
        (message: MessageListEntry) => message.id === updatedMessageId
      );

      if (messageForUpdate) {
        setOptimisticUpdateMessage(getMessageWithUpdatedFlags(messageForUpdate, data));
      }

      if (shouldRefetchMessage) {
        const messagesSnapshot = queryClient.getQueryData([MESSAGES_QUERY_KEY]);

        const listWithOptimisticUpdate = listBeforeUpdate.map((message: MessageListEntry) =>
          message.id === updatedMessageId ? getMessageWithUpdatedFlags(message, data) : message
        );
        setList(listWithOptimisticUpdate);

        queryClient.setQueryData(
          [MESSAGES_QUERY_KEY],
          (previousCachedMessages?: InfiniteData<MessageList>) => {
            if (!previousCachedMessages) {
              return;
            }

            return {
              ...previousCachedMessages,
              pages: previousCachedMessages?.pages?.map((page) => ({
                ...page,
                messages: page.messages?.map((message: MessageListEntry) =>
                  message.id === updatedMessageId
                    ? getMessageWithUpdatedFlags(message, data)
                    : message
                )
              }))
            };
          }
        );

        return { messagesSnapshot, previousList: listBeforeUpdate };
      }
    },
    onSettled: async () => {
      setOptimisticUpdateMessage(null);
    },
    onSuccess: async () => {
      if (shouldRefetchMessage) {
        await queryClient.refetchQueries([MESSAGE_QUERY_KEY]);
        setTimeout(async () => await queryClient.invalidateQueries([MESSAGES_QUERY_KEY]), 1000);
      }
    },
    onError: (error, _, context) => {
      if (context?.messagesSnapshot && context?.previousList) {
        setList(context.previousList);
        queryClient.setQueryData([MESSAGES_QUERY_KEY], context.messagesSnapshot);
      }

      console.log('Error message update', error);
    }
  });
};
