import { create } from 'zustand';
import { FlightPurpose } from 'services/api/data-contracts';
import { devtools } from 'zustand/middleware';

type FlightPurposeStore = {
  current?: FlightPurpose;
  setCurrent: (current?: FlightPurpose) => void;
  list: FlightPurpose[];
  setList: (list: FlightPurpose[]) => void;
};

export const useFlightPurposeStore = create<FlightPurposeStore>()(
  devtools((set) => ({
    current: undefined,
    setCurrent: (current) => {
      set({ current });
    },
    list: [],
    setList: (list) => {
      set({ list });
    }
  }))
);
