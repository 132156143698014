import { ConfigProvider, Empty, Form, Popover, Select } from 'antd';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import React, { useMemo } from 'react';
import { useGetRebatesForInvoiceItem } from 'queries/rebate/useGetRebatesForInvoiceItem';
import { getRebateSign, isAgentFeeRebate, RebateTypeMap } from 'store/rebateStore';
import { getUTCDate } from 'helpers';
import { InfoCircleOutlined } from '@ant-design/icons';
import { RebateTooltip } from './RebateTooltip';
import { useInvoiceStore } from 'store/invoiceStore';
import { InvoiceType } from 'services/api/data-contracts';

interface IProps {
  name: string | (string | number)[];
  label?: string;
  serviceNameId: number;
  orderServiceId?: number;
}

export const RebateInput = ({ label, name, serviceNameId, orderServiceId }: IProps) => {
  const { isIssuedInvoice, payerId, invoiceSupplyDate } = useInvoiceStore(({ current }) => ({
    isIssuedInvoice: current?.type === InvoiceType.Issued,
    invoiceSupplyDate: current?.supplyDate,
    payerId: current?.payerId
  }));

  const { isLoading, data } = useGetRebatesForInvoiceItem(
    {
      serviceNameId,
      orderServiceId,
      invoiceSupplyDate,
      invoicePayerId: Number(payerId)
    },
    !!(isIssuedInvoice && serviceNameId && payerId)
  );

  const options = useMemo(
    () =>
      (data?.data.items || []).map(
        ({ id, isActive, type, value, currency, unitOfMeasure, startDate, endDate }) => {
          const rebateSign = getRebateSign(type);

          return {
            label: `${rebateSign}${value}`,
            dropdownLabel:
              (isAgentFeeRebate(type)
                ? `${rebateSign}${value}%`
                : `${currency} ${rebateSign}${value} per ${unitOfMeasure.shortName}`) +
              ` ${RebateTypeMap[type].toLowerCase()} ${getUTCDate(startDate).format(DATE_FORMAT)} - ${
                endDate ? getUTCDate(endDate).format(DATE_FORMAT) : '∞'
              }`,
            value: id,
            disabled: !isActive
          };
        }
      ),
    [data?.data.items]
  );

  return (
    <ConfigProvider
      renderEmpty={() => <Empty description="No data" image={Empty.PRESENTED_IMAGE_SIMPLE} />}>
      <Form.Item
        name={name}
        label={
          label ? (
            <>
              <span style={{ marginRight: 4 }}>{label}</span>
              <Popover content={<RebateTooltip />} trigger="hover" placement="topLeft">
                <InfoCircleOutlined style={{ color: '#00000073' }} />
              </Popover>
            </>
          ) : null
        }
        style={{ margin: 0 }}>
        <Select
          allowClear
          style={{ width: '100%' }}
          placeholder="0.00"
          options={options}
          size={CONTROL_SIZE}
          showSearch
          optionFilterProp="dropdownLabel"
          optionRender={(option) => option.data.dropdownLabel}
          loading={isLoading}
          popupMatchSelectWidth={false}
          disabled={!data?.data.items?.length}
        />
      </Form.Item>
    </ConfigProvider>
  );
};
