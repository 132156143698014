import { Descriptions, DescriptionsProps } from 'antd';
import React, { useContext } from 'react';
import { DataContext } from '../DictionaryDetails';
import { CONTROL_SIZE } from 'consts/common';
import { Aircraft } from 'services/api/data-contracts';

export const AircraftDictionaryView = () => {
  const data = useContext(DataContext) as Aircraft;

  if (!data) {
    return null;
  }

  const descriptions: DescriptionsProps['items'] = [
    {
      label: 'Tail number',
      children: data.tailNumber
    },
    {
      label: 'Reg country',
      children: data.country?.name || '-'
    },
    {
      label: 'Type',
      children: data.type || '-'
    },
    {
      label: 'MTOW, kg',
      children: data.maxTakeoffWeight ? data.maxTakeoffWeight.toLocaleString('en-US') : '-'
    },
    {
      label: 'Operator',
      children: data.operator?.fullName || '-',
      span: 2
    },
    {
      label: 'Modification',
      children: data.modification || '-',
      span: 2
    },
    {
      label: 'Manufacturer',
      children: data.manufacturer || '-'
    },
    {
      label: 'Serial №',
      children: data.serialNumber || '-'
    }
  ];

  return <Descriptions size={CONTROL_SIZE} items={descriptions} column={2} bordered />;
};
