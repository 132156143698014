import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { paymentsApi } from 'services/api';
import { ENTITY_NAME, PAYMENT_QUERY_KEY } from './consts';
import { MESSAGE_DURATION } from 'consts/common';
import { SETTLEMENT_WIDGET_QUERY_KEY } from '../settlement/consts';

const deleteItem = async (id: number) => await paymentsApi.deletePayment(id);

export const useDeletePayment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteItem,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [PAYMENT_QUERY_KEY] });
      await queryClient.refetchQueries([SETTLEMENT_WIDGET_QUERY_KEY]);

      message.success(`${ENTITY_NAME} has been successfully removed`, MESSAGE_DURATION);
    }
  });
};
