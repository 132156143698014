import { message } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { accessTokenStore } from 'services/auth/TokenStore';
import { idTokenStore } from 'services/auth/IdTokenStore';
import { getLogoutUrl } from 'services/auth/endpointHelpers';
import { settingsStore } from 'services/settings/SettingsStore';
import { MESSAGE_DURATION } from 'consts/common';

export const validateResponse = (response?: AxiosResponse) => {
  if ([401, 403].includes(Number(response?.status))) {
    throw response;
  }
};

export const handleError = async (error: unknown) => {
  if (!(error instanceof AxiosError)) return;

  if (error.response?.status === 403 && error.request.method === 'GET') return;

  const mailboxSettings = settingsStore.getMailboxSettings();

  if (
    error.response?.status === 401 &&
    !error.config?.baseURL?.includes(`${mailboxSettings?.mailbox_api_url}`)
  ) {
    accessTokenStore.clearToken();
    idTokenStore.clearCookies();
    window.location.href = getLogoutUrl();
    return;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const data = error.response?.data;
  const correlationId = data?.correlationId
    ? data.correlationId.toString().replace('Root=', '')
    : undefined;

  const errorMessage = data
    ? `${data.message}${correlationId ? ' (' + correlationId + ')' : ''}`
    : error.message;

  await message.error(errorMessage, MESSAGE_DURATION);
};
