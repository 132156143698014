import React, { useContext, useState } from 'react';
import { Button, Dropdown, message } from 'antd';
import { CONTROL_SIZE, MESSAGE_DURATION } from 'consts/common';
import { InvoiceStatus, InvoiceType, ReportFormat } from 'services/api/data-contracts';
import { useInvoiceStore } from 'store/invoiceStore';
import type { MenuProps } from 'antd';
import { useUpdateInvoice } from 'queries/invoice';
import { DashboardContext, DashboardType, DEFAULT_DOCUMENT_TEMPLATE_NAME } from 'pages/consts';
import { invoicesApi } from 'services/api';
import { InvoiceStatusLabel } from 'pages/SettlementDashboard/helpers/constants';

interface IProps {
  setReadyForSendingInProgress: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ChangeStatusButton = ({ setReadyForSendingInProgress }: IProps) => {
  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false);

  const { current } = useInvoiceStore(({ current }) => ({
    current
  }));

  const { type: dashboardType } = useContext(DashboardContext);

  const updateMutation = useUpdateInvoice(
    undefined,
    dashboardType === DashboardType.Settlement,
    dashboardType === DashboardType.Balance
  );

  if (!current) {
    return null;
  }

  const handleNotPostedClick = async () => {
    await updateMutation.mutateAsync({
      ...current,
      isPosted: false,
      // TODO: update swagger
      status: null
    });
  };

  const handlePostedClick = async () => {
    await updateMutation.mutateAsync({
      ...current,
      isPosted: true,
      ...(current.status === InvoiceStatus.CNLD ? { status: null } : {})
    });
  };

  const handleReadyForSendingClick = async () => {
    setIsUpdateInProgress(true);
    setReadyForSendingInProgress(true);

    try {
      const templatesQueryResult = await invoicesApi.getInvoiceTemplates();
      const defaultTemplate = (templatesQueryResult.data?.items || []).find(
        // TODO: remove t?.name?.includes('SubTotal') when template name updated
        (t) => t?.name?.includes(DEFAULT_DOCUMENT_TEMPLATE_NAME) || t?.name?.includes('SubTotal')
      );

      if (!defaultTemplate) {
        message.error('Failed to get default template for file generation.');
        return;
      }

      // TODO: replace this endpoint with generate when BE is ready
      // where instead of data fileId is returned
      const response = await invoicesApi.generateInvoiceReport(
        current?.id as number,
        { templateId: defaultTemplate.id, format: ReportFormat.Pdf },
        {
          format: 'arraybuffer',
          headers: {
            Accept: 'application/pdf, application/json, application/text'
          }
        }
      );

      if (response.data) {
        message.success(
          `File for invoice "${current?.invoiceNumber}" has been successfully generated.`,
          MESSAGE_DURATION
        );

        await updateMutation.mutateAsync({
          ...current,
          isPosted: true,
          status: InvoiceStatus.RFSN
        });
      }
    } catch (error) {
      console.error(error);
      message.error('Error generating report or fetching default template. ');
    } finally {
      setIsUpdateInProgress(false);
      setReadyForSendingInProgress(false);
    }
  };

  const handleIsSentClick = async () => {
    await updateMutation.mutateAsync({
      ...current,
      isPosted: true,
      status: InvoiceStatus.SENT
    });
  };

  const handleCanceledClick = async () => {
    await updateMutation.mutateAsync({
      ...current,
      isPosted: false,
      status: InvoiceStatus.CNLD
    });
  };

  const getMenuItem = (label: string, key: string, onClick: () => void) => ({
    label,
    key,
    onClick
  });

  const documentStatusMenuOptions = [
    ...(current.status !== InvoiceStatus.RFSN
      ? [getMenuItem(InvoiceStatusLabel.READY_FOR_SENDING, '3', handleReadyForSendingClick)]
      : []),
    ...(current.status !== InvoiceStatus.SENT
      ? [getMenuItem(InvoiceStatusLabel.IS_SENT, '4', handleIsSentClick)]
      : [])
  ];

  const items: MenuProps['items'] = [
    ...(current?.isPosted
      ? [getMenuItem(InvoiceStatusLabel.NOT_POSTED, '1', handleNotPostedClick)]
      : [getMenuItem(InvoiceStatusLabel.POSTED, '2', handlePostedClick)]),
    ...(current?.type === InvoiceType.Issued ? documentStatusMenuOptions : []),
    ...(current.status !== InvoiceStatus.CNLD
      ? [getMenuItem(InvoiceStatusLabel.CANCELED, '5', handleCanceledClick)]
      : [])
  ];

  const isLoading = isUpdateInProgress || updateMutation.isLoading;

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <Button size={CONTROL_SIZE} loading={isLoading}>
        Change the status
      </Button>
    </Dropdown>
  );
};
