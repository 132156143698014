import React, { forwardRef } from 'react';
import { Form, Select } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { settingsStore } from 'services/settings/SettingsStore';
import { getSelectOptions } from 'helpers';

interface IProps {
  disabled?: boolean;
  required?: boolean;
  name?: string;
  label?: string;
  isHidden?: boolean;
}

export const CurrencyInput = forwardRef<React.ElementRef<typeof Select>, IProps>(
  ({ disabled, required, name = 'currency', label = 'Currency', isHidden }, ref) => {
    const { allowedCurrencies = [] } = settingsStore.getCurrentTenant();
    const options = getSelectOptions(allowedCurrencies);

    return (
      <Form.Item name={name} label={label} rules={[{ required }]} hidden={isHidden}>
        <Select
          allowClear
          style={{ width: '100%' }}
          options={options}
          size={CONTROL_SIZE}
          disabled={disabled}
          showSearch
          ref={ref}
        />
      </Form.Item>
    );
  }
);

CurrencyInput.displayName = 'CurrencyInput';
