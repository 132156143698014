import { Flight, FlightStatus } from 'services/api/data-contracts';

export const getNextFlight = (flight: Flight) => ({
  status: FlightStatus.PLND,
  flightNumber: flight.flightNumber,
  operatorId: flight.operatorId,
  aircraftId: flight.aircraftId,
  departureAirportId: flight.arrivalAirportId,
  departureAirport: flight.arrivalAirport,
  flightTypeId: flight.flightTypeId,
  flightPurposeId: flight.flightPurposeId,
  arrivalAirportId: undefined,
  arrivalAirport: undefined,
  estimatedTimeDeparture: undefined,
  estimatedTimeArrival: undefined,
  actualTimeArrival: undefined,
  actualTimeDeparture: undefined,
  route: undefined,
  overflightCountryIds: [],
  alternativeAirportIds: [],
  crew: undefined,
  pax: undefined,
  load: undefined,
  version: 0
});
