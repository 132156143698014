import React, { useState } from 'react';
import { ConfigProvider, Empty, Select } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { useGetAircraftsForDropdown } from 'queries/aircraft/useGetAircraftsForDropdown';

interface IProps {
  values?: { value: number; label: string }[];
  onChange: (values?: { value: number; label: string }[]) => void;
}

export const AircraftMultipleInput = ({ onChange, values }: IProps) => {
  const [filter, setFilter] = useState('');

  const { isLoading, data: { data: { items = [] } = {} } = {} } = useGetAircraftsForDropdown();

  const options = items.map(({ tailNumber, id }) => ({
    label: tailNumber,
    value: id
  }));

  const handleBlur = () => {
    const filteredOptions = options.filter((o) =>
      o.label.toLowerCase().includes(filter.toLowerCase())
    );

    if (filteredOptions.length === 1 && onChange) {
      const singleAutoSetOption = items?.find(({ id }) => id === filteredOptions[0].value);

      if (singleAutoSetOption) {
        const newOption = {
          value: singleAutoSetOption.id as number,
          label: singleAutoSetOption.tailNumber as string
        };

        if (!Array.isArray(values) || !values.some((option) => option.value === newOption.value)) {
          onChange([...(Array.isArray(values) ? values : []), newOption]);
        }
      }
    }

    setFilter('');
  };

  return (
    <ConfigProvider
      renderEmpty={() => (
        <Empty description="Aircrafts not found" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}>
      <Select
        style={{ width: '100%' }}
        mode="multiple"
        maxTagCount="responsive"
        labelInValue
        placeholder="Select aircrafts"
        options={options}
        size={CONTROL_SIZE}
        showSearch
        allowClear
        optionFilterProp="label"
        onSearch={setFilter}
        onBlur={handleBlur}
        loading={isLoading}
        popupMatchSelectWidth={false}
        listHeight={180}
        value={values}
        onChange={(values) => onChange(values)}
        data-testid="aircraft-multiselect"
      />
    </ConfigProvider>
  );
};
