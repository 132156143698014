import React from 'react';
import { Descriptions, DescriptionsProps } from 'antd';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import { InvoiceHydrated, InvoiceType } from 'services/api/data-contracts';
import { getAmountValue, getUTCDate } from 'helpers';
import { green, red } from '@ant-design/colors';

interface IProps {
  invoice: InvoiceHydrated;
}

export const InvoiceDetailsView = ({ invoice }: IProps) => {
  const generalDescriptions: DescriptionsProps['items'] = [
    {
      label: invoice.type === InvoiceType.Received ? 'Supplier' : 'Payer',
      children:
        invoice.type === InvoiceType.Received ? invoice.supplier?.name : invoice.payer?.name,
      span: 2
    },
    {
      label: 'Contract',
      children: invoice.contract?.number
    },
    {
      label: 'Invoice date',
      children: getUTCDate(invoice.invoiceDate).format(DATE_FORMAT)
    },
    {
      label: 'Supply date',
      children: getUTCDate(invoice.supplyDate).format(DATE_FORMAT)
    },
    {
      label: invoice.type === InvoiceType.Received ? 'Receiving' : 'Sending' + ' date',
      children: getUTCDate(invoice.receivingOrSendingDate).format(DATE_FORMAT)
    },
    {
      label: 'Due date',
      children: getUTCDate(invoice.dueDate).format(DATE_FORMAT)
    },
    {
      label: 'Overdue days',
      children: invoice.overdueDays ? (
        <span style={{ fontWeight: 500, color: invoice.overdueDays < 0 ? green[6] : red[5] }}>
          {invoice.overdueDays}
        </span>
      ) : (
        '-'
      )
    },
    { label: 'Currency', children: invoice.currency },
    {
      label: 'Amount due',
      children: getAmountValue(invoice.amountDue)
    },
    {
      label: 'Invoice total',
      children: getAmountValue(invoice.invoiceTotal),
      span: 2
    },
    {
      label: 'Notes',
      children: <span style={{ whiteSpace: 'pre-line' }}>{invoice.notes || '-'}</span>,
      span: 4
    }
  ];

  return (
    <Descriptions
      items={generalDescriptions}
      size={CONTROL_SIZE}
      column={4}
      bordered
      data-testid="invoice-details-view"
    />
  );
};
