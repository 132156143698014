import { useMutation, useQueryClient } from '@tanstack/react-query';
import { flightProgramsApi } from 'services/api';
import { useFlightProgramStore } from 'store/flightProgramStore';
import { message } from 'antd';
import { MESSAGE_DURATION } from 'consts/common';
import { BulkProgramOrderServices } from 'services/api/data-contracts';
import {
  QUERY_KEY as ORDER_SERVICES_QUERY_KEY,
  WIDGET_QUERY_KEY as SERVICES_WIDGET_QUERY_KEY
} from '../orderServices/consts';
import { QUERY_KEY } from './consts';

const updateMutation = async (data: BulkProgramOrderServices & { programId: number }) =>
  await flightProgramsApi.bulkUpdateOrderService(data.programId, data);

export const useUpdateFlightProgramServices = () => {
  const { openProgramId, current } = useFlightProgramStore();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateMutation,
    onSuccess: async (response) => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY, openProgramId] });
      await queryClient.invalidateQueries({ queryKey: [SERVICES_WIDGET_QUERY_KEY] });
      await queryClient.invalidateQueries({
        queryKey: [ORDER_SERVICES_QUERY_KEY, current?.nearestOrder.id]
      });

      const servicesCount = response.data.updatedOrderServiceIdsCount || 0;

      if (servicesCount === 0) {
        message.warning('No services have been updated in program orders', MESSAGE_DURATION);
      } else {
        message.success(
          `${servicesCount} ${servicesCount > 1 ? 'services have' : 'service has'} been successfully updated in program orders`,
          MESSAGE_DURATION
        );
      }
    }
  });
};
