export const VALIDATION_NOTIFICATION = {
  SERVICE_SUPPLIER:
    'Select services with the dedicated and same suppliers to create a received invoice',
  SERVICE_PAYER: 'Select services with the dedicated and same payers to create an issued invoice',
  RECEIVED_INVOICE_CREATED: 'For selected services found created received invoices',
  ISSUED_INVOICE_CREATED: 'For selected services found created issued invoices',
  RECEIVED_INVOICE_REQUIRED: 'Received invoice should be created first',
  NO_DATA_FOR_REINVOICING: 'No data for re-invoicing'
};

export enum InvoiceStatusLabel {
  NOT_POSTED = 'Not posted',
  POSTED = 'Posted',
  READY_FOR_SENDING = 'Ready for sending',
  IS_SENT = 'Is sent',
  CANCELED = 'Canceled'
}

export enum CreditNoteStatusLabel {
  READY_FOR_SENDING = 'Ready for sending',
  IS_SENT = 'Is sent'
}
