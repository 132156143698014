import { OrdersWidgetMode } from 'store/ordersDashboardStore';

export const getFilterTagName = (key: string, widgetMode: OrdersWidgetMode) => {
  const sharedKeys = ['orderStartDate', 'orderEndDate', 'orderStatuses'];

  if (sharedKeys.includes(key) && widgetMode === OrdersWidgetMode.Programs) {
    return key.replace('order', 'program');
  }

  return key;
};
