import React from 'react';
import { Button, Dropdown, MenuProps, Tooltip } from 'antd';
import { TagOutlined } from '@ant-design/icons';
import { FlagList } from 'services/emailEngineApi/data-contracts';
import { useCustomTagUpdate } from '../ActionsDropdown/useCustomTagUpdate';
import { CONTROL_SIZE } from 'consts/common';
import { useMailboxConfig } from '../../helpers/useMailboxConfig';
import { getColoredTags } from '../../helpers/getColoredTags';

interface IProps {
  id: string;
  flags?: FlagList;
}

export const TagsDropdown = ({ id, flags }: IProps) => {
  const toggleFlag = useCustomTagUpdate(id, flags);

  const { customFlags } = useMailboxConfig();
  const coloredTags = getColoredTags(customFlags);

  const handleClick: MenuProps['onClick'] = async (e) => {
    await toggleFlag(e.key);
  };

  return (
    <Dropdown
      menu={{ items: coloredTags, onClick: handleClick }}
      placement="bottomRight"
      trigger={['click']}>
      <Tooltip title="Label or unlabel">
        <Button icon={<TagOutlined />} size={CONTROL_SIZE} shape="circle" type="text" />
      </Tooltip>
    </Dropdown>
  );
};
