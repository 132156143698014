import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { handleError, validateResponse } from '../helpers/handleError';
import { AxiosResponse } from 'axios';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: true,
      refetchOnWindowFocus: false
    }
  },
  queryCache: new QueryCache({
    onError: handleError,
    onSuccess: (response) => validateResponse(response as AxiosResponse)
  }),
  mutationCache: new MutationCache({
    onError: handleError,
    onSuccess: (response) => validateResponse(response as AxiosResponse)
  })
});
