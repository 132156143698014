import { useQuery } from '@tanstack/react-query';
import { contractsApi } from 'services/api';
import { QUERY_KEY } from './consts';
import { useContractStore } from 'store/contractStore';

const fetchList = async (search?: string) => await contractsApi.getContracts({ search });

export const useGetContracts = (filter?: string) => {
  const { setList } = useContractStore(({ setList }) => ({
    setList
  }));

  return useQuery({
    queryKey: [QUERY_KEY, filter],
    queryFn: async () => await fetchList(filter),
    onSuccess: (response) => {
      setList(response.data.items || []);
    }
  });
};
