export const convertBytesToReadableSize = (bytes?: number) => {
  if (!bytes) {
    return '0 B';
  }

  const byteFactor = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  const sizeIndex = Math.floor(Math.log(bytes) / Math.log(byteFactor));
  return parseFloat((bytes / Math.pow(byteFactor, sizeIndex)).toFixed(2)) + ' ' + sizes[sizeIndex];
};
