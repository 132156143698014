import { useQuery } from '@tanstack/react-query';
import { paymentsApi } from 'services/api';
import { PAYMENT_QUERY_KEY } from './consts';
import { usePaymentStore } from 'store/paymentStore';

interface IFilter {
  page: number;
  pageSize?: number;
  search?: string;
  invoiceId?: number;
  correctedInvoiceId?: number;
}

const fetchList = async (filter: IFilter) => await paymentsApi.getAllPayments(filter);

export const useGetPayments = (filter: IFilter) => {
  const { setList } = usePaymentStore(({ setList }) => ({
    setList
  }));

  return useQuery({
    queryKey: [PAYMENT_QUERY_KEY, filter],
    queryFn: () => fetchList(filter),
    onSuccess: (response) => {
      setList(response.data.items);
    }
  });
};
