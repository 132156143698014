import { TypedOrder } from 'store/orderStore';
import { EMAIL_TEMPLATE_DATE_TIME_FORMAT } from 'consts/common';
import { CustomerStatus, OrderService, OrderType } from 'services/api/data-contracts';
import { getUTCDate, getOrderTerm } from 'helpers';

export const getOrderForTemplate = (order: Partial<TypedOrder>, services: OrderService[]) => {
  const flights = [];

  if ('flights' in order && order.flights?.length) flights.push(...order.flights);

  if ('arrivalFlight' in order) flights.push(order.arrivalFlight);

  if ('departureFlight' in order) flights.push(order.departureFlight);

  const mappedFlights = flights.map((flight) =>
    flight
      ? {
          ...flight,
          estimatedTimeDeparture: getUTCDate(flight.estimatedTimeDeparture).format(
            EMAIL_TEMPLATE_DATE_TIME_FORMAT
          ),
          estimatedTimeArrival: getUTCDate(flight.estimatedTimeArrival).format(
            EMAIL_TEMPLATE_DATE_TIME_FORMAT
          )
        }
      : undefined
  );

  const location =
    order.location && ('code' in order.location || order.type === OrderType.PMT)
      ? order.location
      : flights[0]?.arrivalAirport;

  const filteredServices = services.filter(
    ({ customerStatus, supplierStatus }) =>
      !!supplierStatus &&
      (!customerStatus || ![CustomerStatus.MIST, CustomerStatus.NN].includes(customerStatus))
  );

  return {
    ...order,
    location,
    flights: mappedFlights.filter((i) => !!i),
    services: filteredServices,
    orderTerm: getOrderTerm(getUTCDate(order.startDatetime), getUTCDate(order.endDatetime))
  };
};
