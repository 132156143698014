import { create } from 'zustand';
import { Aircraft } from 'services/api/data-contracts';
import { devtools } from 'zustand/middleware';

type AircraftStore = {
  current?: Aircraft;
  setCurrent: (current?: Aircraft) => void;
  list: Aircraft[];
  setList: (list: Aircraft[]) => void;
};

export const useAircraftStore = create<AircraftStore>()(
  devtools((set) => ({
    current: undefined,
    setCurrent: (current) => {
      set({ current });
    },
    list: [],
    setList: (list) => {
      set({ list });
    }
  }))
);
