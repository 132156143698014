import React, { useMemo } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { LIST_FOOTER_BULK_BLOCK_HEIGHT } from 'pages/consts';
import { useMessageStore } from 'store/messageStore';

interface IProps {
  onOpenNewMessageDrawer: () => void;
}

export const NewMessageButton = ({ onOpenNewMessageDrawer }: IProps) => {
  const { selectedMessages } = useMessageStore(({ selectedMessages }) => ({
    selectedMessages
  }));
  const footerHeight = useMemo(
    () => (selectedMessages.length > 0 ? LIST_FOOTER_BULK_BLOCK_HEIGHT : 0),
    [selectedMessages]
  );

  return (
    <Button
      shape="circle"
      icon={<EditOutlined />}
      style={{
        position: 'absolute',
        bottom: `${52 + footerHeight}px`,
        right: '12px',
        border: 'none',
        zIndex: '100',
        color: 'white'
      }}
      type="primary"
      data-testid="start-new-message-icon"
      onClick={onOpenNewMessageDrawer}
    />
  );
};
