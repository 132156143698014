import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { CurrencyRate, CurrencyRateListResponse } from 'services/api/data-contracts';
import { Dayjs } from 'dayjs';
import { getUTCDate } from 'helpers';

export type CurrencyRateWithId = Omit<CurrencyRate, 'date'> & {
  id: string;
  date: Dayjs;
};

type CurrencyRateStore = {
  current?: CurrencyRateWithId;
  setCurrent: (current?: CurrencyRate) => void;
  list: CurrencyRateWithId[];
  setList: (data: CurrencyRateListResponse) => void;
  total: number;
};

export const useCurrencyRateStore = create<CurrencyRateStore>()(
  devtools((set) => ({
    current: undefined,
    setCurrent: (current) => {
      set({
        current: current
          ? ({
              ...current,
              id: current?.date,
              date: getUTCDate(current?.date)
            } as unknown as CurrencyRateWithId)
          : undefined
      });
    },
    list: [],
    setList: ({ items, total }) => {
      set({ list: items.map((i) => ({ ...i, id: i.date, date: getUTCDate(i?.date) })), total });
    },
    total: 0
  }))
);
