import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import { DeleteOutlined, EditFilled } from '@ant-design/icons';
import { Button, Flex, Tooltip } from 'antd';
import { PaymentHydrated } from 'services/api/data-contracts';
import { getAmountValue, formatTextWithLineBreaks, getUTCDate } from 'helpers';

export const getColumns = (
  onDelete: (id: number) => void,
  onOpen: (creditNote: PaymentHydrated) => void
): ColumnsType<PaymentHydrated & { key: number }> => [
  {
    title: '№',
    dataIndex: 'creditNoteNumber',
    key: 'creditNoteNumber',
    width: 80
  },
  {
    title: 'Received date',
    dataIndex: 'receivedDate',
    key: 'receivedDate',
    render: (_, record) =>
      record.receivedDate ? getUTCDate(record.receivedDate).format(DATE_FORMAT) : '-',
    width: 125
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (value) => (
      <div
        style={{ display: 'flex', overflow: 'hidden', maxWidth: '130px', justifyContent: 'end' }}>
        <Tooltip title={getAmountValue(value)}>
          <span
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            }}>
            {getAmountValue(value)}
          </span>
        </Tooltip>
      </div>
    ),
    width: 130,
    align: 'end'
  },
  {
    title: 'Note',
    dataIndex: 'note',
    key: 'note',
    render: (_, record) => (
      <Flex align="center" style={{ maxWidth: '450px' }}>
        <Tooltip title={record.notes && formatTextWithLineBreaks(record.notes)}>
          <span
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            }}>
            {record.notes}
          </span>
        </Tooltip>

        <div id="actions">
          <Tooltip title="Delete credit note">
            <Button
              size={CONTROL_SIZE}
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => onDelete(record.id)}
            />
          </Tooltip>
          <Tooltip title="Edit credit note">
            <Button
              size={CONTROL_SIZE}
              type="primary"
              shape="circle"
              data-testid="edit-btn"
              icon={<EditFilled />}
              onClick={() => onOpen(record)}
            />
          </Tooltip>
        </div>
      </Flex>
    )
  }
];
