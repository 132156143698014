export const TON_COEFFICIENT = 1000;
export const GAL_COEFFICIENT = 3.78541;

export const convertKgToGallons = (kg: number, density: number, digits = 2) =>
  +(kg / (density * GAL_COEFFICIENT)).toFixed(digits);

export const convertKgToLitres = (kg: number, density: number, digits = 0) =>
  +(kg / density).toFixed(digits);

export const convertKgToTonnes = (kg: number, digits = 4) =>
  +(kg / TON_COEFFICIENT).toFixed(digits);

export const convertGallonsToKg = (gal: number, density: number, digits = 4) =>
  +(gal * GAL_COEFFICIENT * density).toFixed(digits);

export const convertLitresToKg = (litres: number, density: number, digits = 4) =>
  +(litres * density).toFixed(digits);

export const convertTonnesToKg = (tonnes: number, digits = 4) =>
  +(tonnes * TON_COEFFICIENT).toFixed(digits);
