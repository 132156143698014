import { Spin } from 'antd';
import { PaymentDetails } from './PaymentDetails/PaymentDetails';
import React from 'react';
import { useGetPayment } from 'queries/payment';
import styled from 'styled-components';
import { usePaymentStore } from 'store/paymentStore';
import { PaymentFullyHydrated } from 'services/api/data-contracts';

const SpinWrapper = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IProps {
  isLoading: boolean;
}

export const PaymentDrawerContent = ({ isLoading }: IProps) => {
  const { current } = usePaymentStore(({ current }) => ({
    current: current as PaymentFullyHydrated
  }));

  const { isFetching } = useGetPayment(current?.id);

  if (isLoading || isFetching) {
    return (
      <SpinWrapper>
        <Spin />
      </SpinWrapper>
    );
  }

  return <PaymentDetails payment={current} />;
};
