import React, { useContext, useMemo } from 'react';
import { Flex, Form, FormInstance, InputNumber } from 'antd';
import { CONTROL_SIZE, DATE_TIME_FORMAT } from 'consts/common';
import { useParams } from 'react-router-dom';
import { DictionaryName } from '../../models';
import { DataContext, FormContext } from '../DictionaryDetails';
import { CurrencyInput } from 'components/CurrencyInput/CurrencyInput';
import { StyledDatePicker } from 'components/common/StyledDatePicker';
import { CurrencyRateWithId } from 'store/currencyRateStore';
import { useSelectAutoFocus } from 'helpers';
import { settingsStore } from 'services/settings/SettingsStore';

export const CurrencyRateDictionaryForm = () => {
  const { dictionaryName } = useParams<{ dictionaryName: DictionaryName }>();
  const form = useContext(FormContext);
  const data = useContext(DataContext);
  const sourceCurrency = Form.useWatch('sourceCurrency', form as FormInstance<CurrencyRateWithId>);

  const { mainCurrency = '', exchangeCurrencies } = settingsStore.getCurrentTenant();

  const sourceCurrencyRef = useSelectAutoFocus();

  const targetCurrencies = useMemo(
    () => (sourceCurrency === mainCurrency ? exchangeCurrencies : [mainCurrency]),
    [sourceCurrency, mainCurrency, exchangeCurrencies]
  );

  if (!form) {
    return null;
  }

  return (
    <Form
      name={dictionaryName}
      layout="vertical"
      form={form}
      initialValues={{ sourceCurrency: mainCurrency }}
      colon={false}
      requiredMark={false}
      className="styled-form">
      <Flex gap={8} style={{ width: 'calc(50% - 4px)' }}>
        <div style={{ flexGrow: 1 }}>
          <CurrencyInput name="sourceCurrency" isHidden={!!data} ref={sourceCurrencyRef} />
        </div>

        <Form.Item
          name="date"
          label="Date"
          rules={[{ required: true }]}
          style={{ flexGrow: 1 }}
          hidden={!!data}>
          <StyledDatePicker
            id="date"
            format={DATE_TIME_FORMAT}
            placeholder="Select date"
            onSetDate={(value) => {
              form && form.setFieldValue('date', value);
            }}
          />
        </Form.Item>
      </Flex>

      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '8px' }}>
        {targetCurrencies.map((currency, index) => (
          <Form.Item name={['exchangeRates', currency]} key={currency} data-testid={currency}>
            <InputNumber
              type="number"
              addonBefore={currency}
              size={CONTROL_SIZE}
              style={{ width: '100%' }}
              autoFocus={!!data && index === 0}
            />
          </Form.Item>
        ))}
      </div>
    </Form>
  );
};
