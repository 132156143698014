import { Button, CollapseProps, Flex, Form, FormInstance, Input, InputNumber, Select } from 'antd';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { DictionaryName } from '../../models';
import { FormContext } from '../DictionaryDetails';
import { RebateHydrated, RebateType, SpecialParameterAlias } from 'services/api/data-contracts';
import { ServiceNameInput } from 'components/ServiceNameInput/ServiceNameInput';
import { CounterpartyInput } from 'components/CounterpartyInput/CounterpartyInput';
import { StyledDatePicker } from 'components/common/StyledDatePicker';
import { useWatch } from 'antd/es/form/Form';
import { CurrencyInput } from 'components/CurrencyInput/CurrencyInput';
import { UnitsOfMeasureInput } from 'components/UnitsOfMeasureInput/UnitsOfMeasureInput';
import { isAgentFeeRebate, RebateTypeMap } from 'store/rebateStore';
import { SpecialParameterForm } from './components/SpecialParameters/SpecialParameterForm';
import { PlusOutlined } from '@ant-design/icons';
import { StyledBadge } from 'components/common/StyledBadge';
import { blue } from '@ant-design/colors';
import { StyledCollapse } from 'components/common/StyledCollapse';

const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
`;

export const RebateDictionaryForm = () => {
  const { dictionaryName } = useParams<{ dictionaryName: DictionaryName }>();
  const form = useContext(FormContext);

  const type = useWatch('type', form as FormInstance<RebateHydrated>);
  const specialParameters = Form.useWatch(
    'specialParameters',
    form as FormInstance<RebateHydrated>
  );
  const startDate = Form.useWatch('startDate', form as FormInstance<RebateHydrated>);

  if (!form) {
    return null;
  }

  const collapseContent: CollapseProps['items'] = [
    {
      key: 'specialParameters',
      label: (
        <Flex gap={4} align="center">
          Special parameters
          <StyledBadge count={specialParameters?.filter(Boolean).length} color={blue[0]} />
        </Flex>
      ),
      children: (
        <Form.List name="specialParameters">
          {(list, { add, remove }) => (
            <Flex vertical>
              {list?.map((item) => (
                <SpecialParameterForm
                  listItemKey={item.name}
                  specialParameters={specialParameters}
                  key={item.key}
                  remove={remove}
                  typesFilter={(alias) =>
                    [
                      SpecialParameterAlias.Operator,
                      SpecialParameterAlias.Aircraft,
                      SpecialParameterAlias.Location
                    ].includes(alias)
                  }
                />
              ))}

              <Button
                type="dashed"
                size={CONTROL_SIZE}
                onClick={() => add()}
                style={{ width: '30%' }}
                icon={<PlusOutlined />}>
                Add parameter
              </Button>
            </Flex>
          )}
        </Form.List>
      )
    }
  ];

  return (
    <Form
      name={dictionaryName}
      layout="vertical"
      form={form}
      colon={false}
      requiredMark={false}
      className="styled-form"
      initialValues={{ type: RebateType.InvoiceItemExtra }}>
      <RowWrapper>
        <Form.Item name="type" label="" rules={[{ required: true }]}>
          <Select
            size={CONTROL_SIZE}
            options={Object.values(RebateType).map((type) => ({
              label: RebateTypeMap[type],
              value: type
            }))}
          />
        </Form.Item>
      </RowWrapper>

      <RowWrapper>
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input size={CONTROL_SIZE} placeholder="Enter name" autoFocus />
        </Form.Item>

        <ServiceNameInput name="serviceNameId" label="Service name" required />
      </RowWrapper>

      <RowWrapper>
        <CounterpartyInput label="Customer" name="customerId" required />

        <RowWrapper>
          <Form.Item name="startDate" label="Start date" rules={[{ required: true }]}>
            <StyledDatePicker
              onSetDate={(value) => form.setFieldValue('startDate', value)}
              format={DATE_FORMAT}
              placeholder="Select start date"
              id="startDate"
            />
          </Form.Item>

          <Form.Item
            name="endDate"
            label="End date"
            rules={[
              {
                validator: async (_, value) => {
                  if (value && startDate && value <= startDate) {
                    return Promise.reject('End date should be later than a start date');
                  }
                }
              }
            ]}>
            <StyledDatePicker
              onSetDate={(value) => form.setFieldValue('endDate', value)}
              onChange={(value) => {
                form.setFieldValue('endDate', value);
              }}
              format={DATE_FORMAT}
              placeholder="Select end date"
              id="endDate"
            />
          </Form.Item>
        </RowWrapper>
      </RowWrapper>

      {isAgentFeeRebate(type) ? (
        <RowWrapper>
          <Form.Item name="value" label="Percentage Amount" rules={[{ required: true }]}>
            <InputNumber addonAfter="%" size={CONTROL_SIZE} style={{ width: '100%' }} />
          </Form.Item>
        </RowWrapper>
      ) : (
        <RowWrapper>
          <RowWrapper>
            <CurrencyInput required />

            <Form.Item name="value" label="Amount" rules={[{ required: true }]}>
              <InputNumber size={CONTROL_SIZE} style={{ width: '100%' }} />
            </Form.Item>
          </RowWrapper>

          <UnitsOfMeasureInput name="unitOfMeasureId" label="UOM" required />
        </RowWrapper>
      )}

      <Form.Item label="Notes" name="notes">
        <Input.TextArea size={CONTROL_SIZE} autoSize={{ minRows: 2, maxRows: 10 }} />
      </Form.Item>

      <StyledCollapse
        items={collapseContent}
        expandIconPosition="end"
        size={CONTROL_SIZE}
        defaultActiveKey="specialParameters"
        ghost
      />
    </Form>
  );
};
