import React, { Key, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ConfigProvider, Empty, Flex, Space, Table, TableProps } from 'antd';
import styled, { css } from 'styled-components';
import { getColumns } from './utils/columns';
import { CONTROL_SIZE } from 'consts/common';
import { debounce } from 'lodash';
import { useBalanceDashboardStore } from 'store/balanceDashboardStore';
import { TableRowSelection } from 'antd/es/table/interface';
import {
  BalanceViewContract,
  BalanceViewResponse,
  Invoice,
  InvoiceHydrated,
  Payment,
  PaymentHydrated
} from 'services/api/data-contracts';
import { BalanceCalculationView } from 'pages/BalanceDashboard/components/BalanceCalculationView/BalanceCalculationView';
import { useInvoiceStore } from 'store/invoiceStore';
import { usePaymentStore } from 'store/paymentStore';
import { hasSameCurrencyEntitiesToLink, isInvoice } from './utils/helpers';
import { PaymentLinkConfirmPopup } from './components/PaymentLinkConfirmPopup';
import { useNavigate } from 'react-router-dom';
import { useInvoiceDrawerRouting, usePaymentDrawerRouting } from 'routing';
import { useDrawerStore } from 'store/drawerStore';

interface IProps {
  entitiesList?: BalanceViewResponse;
  contract?: BalanceViewContract;
  isLoading?: boolean;
}

interface StyledTableProps extends TableProps<Invoice | Payment> {
  showLinkPaymentButton?: boolean;
}

const TableContainer = styled.div`
  height: 100%;
  width: 100%;

  .ant-table-wrapper {
    height: 95%;

    .ant-spin-nested-loading {
      height: 100%;

      .ant-spin-container {
        height: 100%;
        display: flex;
        flex-flow: column nowrap;

        .ant-table {
          .ant-table-body {
            height: 100%;
            flex: auto;
            overflow-y: scroll;
            overflow-x: hidden;
          }
        }
      }
    }
  }
`;

const StyledTable = styled(Table)<StyledTableProps>`
  .ant-table {
    border: 1px solid #d9d9d9;
    border-radius: 8px;
  }

  .float-btn {
    display: none;
    position: absolute;
    bottom: 6px;
    right: 12px;
  }

  ${({ showLinkPaymentButton }) =>
    showLinkPaymentButton &&
    css`
      .ant-table-tbody > .ant-table-row:hover .float-btn {
        display: block;
      }
    `}
`;

export const BalanceWidget = ({ entitiesList, contract, isLoading = false }: IProps) => {
  const [tableContentHeight, setTableContentHeight] = useState(0);
  const [shouldShowLinkPaymentButton, setShowLinkPaymentButton] = useState(false);
  const [openPopconfirm, setOpenPopconfirm] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const navigate = useNavigate();

  useInvoiceDrawerRouting();
  usePaymentDrawerRouting();

  const elementRef = useRef<HTMLDivElement | null>(null);

  const tableRef = useCallback((element: HTMLDivElement | null) => {
    if (element !== null) {
      elementRef.current = element;
      setTableContentHeight(element.clientHeight - 38 - 38);
    }
  }, []);

  const setContentHeight = () => {
    if (elementRef.current) {
      setTableContentHeight(elementRef.current.clientHeight - 38 - 38);
    }
  };

  useEffect(() => {
    setContentHeight();

    const resize = debounce(setContentHeight, 100);
    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, []);

  const { setSelectedEntities, isContractViewMode, setShouldUpdateBalanceView, selectedEntities } =
    useBalanceDashboardStore(
      ({
        setSelectedEntities,
        isContractViewMode,
        setShouldUpdateBalanceView,
        selectedEntities
      }) => ({
        setSelectedEntities,
        isContractViewMode,
        setShouldUpdateBalanceView,
        selectedEntities
      })
    );
  const { setPayment } = usePaymentStore(({ setCurrent }) => ({ setPayment: setCurrent }));
  const { setInvoice } = useInvoiceStore(({ setCurrent }) => ({ setInvoice: setCurrent }));
  const { setDrawerOpen } = useDrawerStore(({ setDrawerOpen }) => ({ setDrawerOpen }));

  const handlePaymentLinkPopoverOpen = (
    payment: Payment & { balance?: number; advance: number }
  ) => {
    setPayment(payment as PaymentHydrated);
    setOpenPopconfirm(true);
  };

  const clearRowsSelection = () => {
    setSelectedRowKeys([]);
    setSelectedEntities([]);
  };

  const selectedPayment = selectedEntities.filter((entity) => !isInvoice(entity))[0];
  const columns = getColumns(handlePaymentLinkPopoverOpen, (selectedPayment as Payment)?.id);

  const dataSource = useMemo(() => {
    const items = entitiesList?.items || contract?.items || [];

    return items.map((item) => ({
      ...item,
      key: `${isInvoice(item) ? 'invoice' : 'payment'}-${item.id}`
    }));
  }, [contract, entitiesList]);

  const rowSelection: TableRowSelection<
    (Payment & { balance?: number; advance: number }) | (Invoice & { balance?: number })
  > = {
    onChange: (
      selectedRowKeys: Key[],
      selectedRows: ((Payment & { balance?: number; advance: number }) | Invoice)[]
    ) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedEntities(selectedRows);
      const hasRelevantEntitiesSelected = hasSameCurrencyEntitiesToLink(selectedRows);
      setShowLinkPaymentButton(hasRelevantEntitiesSelected);
    },
    getCheckboxProps: (record: Payment | Invoice) => ({
      name: 'invoiceNumber' in record ? record.invoiceNumber?.toString() : record.id?.toString()
    }),
    hideSelectAll: true,
    selectedRowKeys
  };

  const footer = () => (
    <div>
      Total:
      {entitiesList ? ` ${entitiesList?.items?.length ?? 0}` : ` ${contract?.items?.length ?? 0}`}
    </div>
  );

  const contractHeader = () => (
    <Flex>
      <span style={{ marginRight: '30px' }}>{contract?.contractNumber}</span>
      <BalanceCalculationView balance={contract?.balance} />
    </Flex>
  );

  const title = useMemo(() => {
    return (
      <Flex gap={6}>
        {!isContractViewMode ? (
          <Space>
            All contracts
            <BalanceCalculationView balance={entitiesList?.balance} />
          </Space>
        ) : (
          contractHeader()
        )}
      </Flex>
    );
  }, [isContractViewMode, entitiesList, contractHeader]);

  if (!entitiesList && !contract && !isLoading) {
    return (
      <Flex align="center" justify="center" style={{ width: '100%' }}>
        <Empty description="Select сustomer or supplier to see the balance" />
      </Flex>
    );
  }

  return (
    <ConfigProvider
      renderEmpty={() => (
        <Empty description="Items not found" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}>
      {openPopconfirm && (
        <PaymentLinkConfirmPopup
          onClose={() => setOpenPopconfirm(false)}
          onClearRowsSelection={clearRowsSelection}
        />
      )}
      <TableContainer ref={tableRef}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore*/}
        <StyledTable
          rowSelection={rowSelection}
          dataSource={dataSource}
          columns={columns}
          title={() => title}
          footer={footer}
          loading={isLoading}
          pagination={false}
          size={CONTROL_SIZE}
          scroll={entitiesList && { y: tableContentHeight }}
          showLinkPaymentButton={shouldShowLinkPaymentButton}
          onRow={(record) => ({
            onClick: ({ target }) => {
              const isCheckbox = 'checked' in target;
              const isButton = (target as HTMLElement).closest('button');
              const isDropdown = (target as HTMLElement).closest('.ant-dropdown');
              const isInteractiveElement = isCheckbox || isButton || isDropdown;

              if (!isInteractiveElement && 'invoiceNumber' in record) {
                setInvoice(record as InvoiceHydrated);
                setDrawerOpen('invoice');
                navigate(`/balance/invoice/${record.id}${window.location.search}`);
              } else if (!isInteractiveElement) {
                setPayment(record as PaymentHydrated);
                setDrawerOpen('payment');
                setShouldUpdateBalanceView(true);
                navigate(`/balance/payment/${record.id}${window.location.search}`);
              }
            }
          })}
        />
      </TableContainer>
    </ConfigProvider>
  );
};
