import React, { useEffect } from 'react';
import { CONTROL_SIZE } from 'consts/common';
import { StyledCollapse } from 'components/common/StyledCollapse';
import { CollapseProps, Flex } from 'antd';
import { StyledBadge } from 'components/common/StyledBadge';
import { blue } from '@ant-design/colors';
import { InvoiceServicesView } from './InvoiceServicesView';
import { useInvoiceStore } from 'store/invoiceStore';

export const InvoiceServicesBlock = ({
  setAddServiceDrawerOpen
}: {
  setAddServiceDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { current, setServices, services } = useInvoiceStore(
    ({ current, setServices, services }) => ({
      current,
      setServices,
      services
    })
  );

  useEffect(() => {
    setServices(current?.orderServices || []);

    return () => {
      setServices([]);
    };
  }, [current?.orderServices]);

  const collapseContent: CollapseProps['items'] = [
    {
      key: 'items',
      label: (
        <Flex gap={4} align="center">
          Services
          <StyledBadge count={services.length} color={blue[0]} />
        </Flex>
      ),
      children: current && (
        <InvoiceServicesView
          services={services}
          setAddServiceDrawerOpen={setAddServiceDrawerOpen}
        />
      )
    }
  ];

  return (
    <StyledCollapse items={collapseContent} expandIconPosition="end" size={CONTROL_SIZE} ghost />
  );
};
