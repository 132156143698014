import { Form, Input, Select } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { DictionaryName } from '../../models';
import { FormContext } from '../DictionaryDetails';
import { LocationType, OrderType } from 'services/api/data-contracts';
import { capitalize } from 'lodash';
import styled from 'styled-components';
import { getSelectOptions } from 'helpers';
import { BooleanRadioGroup } from 'components/common/BooleanRadioGroup';
import { ServiceNameInput } from 'components/ServiceNameInput/ServiceNameInput';

const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
`;

const ORDER_TYPES_LENGTH_ERROR = 'Please select at least one order type';

export const ServiceDictionaryForm = () => {
  const { dictionaryName } = useParams<{ dictionaryName: DictionaryName }>();
  const form = useContext(FormContext);

  if (!form) {
    return null;
  }

  return (
    <Form
      name={dictionaryName}
      layout="vertical"
      form={form}
      initialValues={{ isActive: true, isDefault: false, locationType: LocationType.Everywhere }}
      colon={false}
      requiredMark="optional"
      className="styled-form">
      <RowWrapper>
        <Form.Item name="isActive" label="Active" rules={[{ required: true }]}>
          <BooleanRadioGroup
            testId="Active"
            onChange={(value) => form?.setFieldValue('isActive', value)}
            value={form.getFieldValue('isActive')}
          />
        </Form.Item>

        <Form.Item name="isDefault" label="Add by default" rules={[{ required: true }]}>
          <BooleanRadioGroup
            testId="Add by default"
            onChange={(value) => form?.setFieldValue('isDefault', value)}
            value={form.getFieldValue('isDefault')}
          />
        </Form.Item>
      </RowWrapper>

      <RowWrapper>
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input size={CONTROL_SIZE} autoFocus />
        </Form.Item>

        <ServiceNameInput name="serviceNameId" label="Default service name" required />
      </RowWrapper>

      <RowWrapper>
        <Form.Item
          name="orderTypes"
          label="Order types"
          rules={[
            {
              required: true,
              validator(_, value) {
                if (!value?.length) {
                  return Promise.reject(ORDER_TYPES_LENGTH_ERROR);
                }

                return Promise.resolve();
              }
            }
          ]}>
          <Select
            size={CONTROL_SIZE}
            mode="multiple"
            options={getSelectOptions(Object.values(OrderType))}
            maxTagCount="responsive"
          />
        </Form.Item>

        <Form.Item name="locationType" label="Location type" rules={[{ required: true }]}>
          <Select
            size={CONTROL_SIZE}
            options={Object.values(LocationType).map((i) => ({ value: i, label: capitalize(i) }))}
          />
        </Form.Item>
      </RowWrapper>
    </Form>
  );
};
