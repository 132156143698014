import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { InvoiceHydrated } from 'services/api/data-contracts';
import { useInvoiceStore } from 'store/invoiceStore';
import { useDrawerStore } from 'store/drawerStore';

export const useInvoiceDrawerRouting = () => {
  const { setCurrent } = useInvoiceStore(({ setCurrent }) => ({
    setCurrent
  }));
  const { setDrawerOpen } = useDrawerStore(({ setDrawerOpen }) => ({ setDrawerOpen }));

  const { entityType, entityId } = useParams();

  useEffect(() => {
    if (entityType !== 'invoice') return;
    if (!entityId) return;

    if (entityType === 'invoice' && !isNaN(+entityId)) {
      setCurrent({ id: +entityId } as unknown as InvoiceHydrated);
      setDrawerOpen('invoice');
    }
  }, []);
};
