import { useQueryClient } from '@tanstack/react-query';
import { messagesApi } from 'services/emailEngineApi';
import { ATTACHMENT_QUERY_KEY, ATTACHMENT_TIMEOUT } from './consts';
import { settingsStore } from 'services/settings/SettingsStore';
import { MESSAGE_DURATION } from 'consts/common';
import { message } from 'antd';

export const fetchAttachmentStream = async (id: string) => {
  try {
    return await Promise.race([
      messagesApi.getV1AccountAccountAttachmentAttachment(
        settingsStore.getMailboxSettings()?.mailbox_name || '',
        id,
        { format: 'arraybuffer' }
      ),
      new Promise((_, reject) =>
        setTimeout(() => reject(new Error('Request timed out')), ATTACHMENT_TIMEOUT)
      )
    ]);
  } catch (error) {
    message.error('Attachment loading failed.', MESSAGE_DURATION);
    console.error('Error fetching attachment:', error);
    throw error;
  }
};

export const useGetAttachment = () => {
  const queryClient = useQueryClient();

  const refetchFile = (id: string) => {
    return queryClient.fetchQuery([ATTACHMENT_QUERY_KEY, id], () => fetchAttachmentStream(id));
  };

  return {
    refetchFile
  };
};
