import { useQuery } from '@tanstack/react-query';
import { invoiceItemsApi } from 'services/api';
import { QUERY_KEY } from './consts';
import { useInvoiceStore } from 'store/invoiceStore';

const fetchItem = async (id: number) =>
  await invoiceItemsApi.getInvoiceItemById(id, { withRolledUp: true });

export const useGetInvoiceItem = (id?: number) => {
  const { setCurrentInvoiceItem } = useInvoiceStore(({ setCurrentInvoiceItem }) => ({
    setCurrentInvoiceItem
  }));

  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => {
      if (id) {
        return await fetchItem(id);
      }
    },
    onSuccess: (response) => {
      setCurrentInvoiceItem(response?.data);
    }
  });
};
