import React, { useContext } from 'react';
import { useOrderStore } from 'store/orderStore';
import { AdditionalServicesOrder, OrderType } from 'services/api/data-contracts';
import { Flex, Form } from 'antd';
import { GeneralInfoBlock, OrderDetailsBlock, OrderServicesBlock } from './accordions';
import { FormInstance } from 'antd/es/form';
import { OrderStatusSelect } from './common';
import { FormContext } from '../OrderDrawer';
import { AddOrderInputWithDayJs } from '../../../DispatcherOrdersWidget/components/utils/addOrderUtils';
import { useFormUpdate } from '../../../DispatcherOrdersWidget/helpers/useFormUpdate';

export const ADDOrderDetails = () => {
  const { form: formInstance } = useContext(FormContext);
  const form = formInstance as FormInstance<AddOrderInputWithDayJs>;

  const { current } = useOrderStore(({ current }) => ({
    current: current as AdditionalServicesOrder
  }));

  const handleUpdateForm = useFormUpdate<AddOrderInputWithDayJs>(form, OrderType.ADD);

  return (
    <Form
      style={{ justifyContent: 'center' }}
      layout="vertical"
      requiredMark="optional"
      form={form}
      autoComplete="off"
      colon={false}
      onValuesChange={handleUpdateForm}
      className="styled-form">
      <Flex gap={8} vertical>
        <OrderStatusSelect />
        <GeneralInfoBlock orderType={OrderType.ADD} />
        <OrderDetailsBlock orderType={OrderType.ADD} />
        <OrderServicesBlock order={current} orderType={OrderType.ADD} />
      </Flex>
    </Form>
  );
};
