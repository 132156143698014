import { ColumnsType } from 'antd/es/table';
import { Counterparty } from 'services/api/data-contracts';
import { booleanSorter, stringSorter } from '../utils/tableSorters';
import { Checkbox, Tooltip } from 'antd';
import React from 'react';

export const getCounterpartyColumns = (): ColumnsType<Counterparty> => [
  {
    title: 'Short name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => stringSorter(a.name, b.name),
    defaultSortOrder: 'ascend'
  },
  {
    title: 'Full name',
    dataIndex: 'fullName',
    key: 'fullName',
    sorter: (a, b) => stringSorter(a.fullName, b.fullName),
    ellipsis: true
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
    render: (_, record) => record.country?.name || '-',
    sorter: (a, b) => stringSorter(a.country?.name || '', b.country?.name || '')
  },
  {
    title: 'Registration №',
    dataIndex: 'registrationNumber',
    key: 'registrationNumber',
    sorter: (a, b) => stringSorter(a.registrationNumber || '', b.registrationNumber || ''),
    ellipsis: true
  },
  {
    title: 'Active',
    dataIndex: 'isActive',
    key: 'isActive',
    render: (value) => (
      <Tooltip title={value ? 'Active' : 'Inactive'}>
        <Checkbox checked={value} disabled />
      </Tooltip>
    ),
    sorter: (a, b) => booleanSorter(a.isActive, b.isActive)
  }
];
