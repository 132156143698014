import React from 'react';
import { Button, Flex, Popover, Tooltip, Typography } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { AxiosError, isAxiosError } from 'axios';
import { handleError } from 'helpers';
import { DownloadOutlined } from '@ant-design/icons';

type IProps = {
  children: React.ReactElement;
};

type IState = {
  hasError: boolean;
  error?: AxiosError;
  isOpen: boolean;
};

export class PdfPopoverErrorBoundary extends React.Component<IProps, IState> {
  static handledErrors = new Set();

  constructor(props: IProps) {
    super(props);
    this.state = {
      hasError: false,
      error: undefined,
      isOpen: false
    };
  }

  static getDerivedStateFromError(error: Error) {
    if (PdfPopoverErrorBoundary.handledErrors.has(error) || !isAxiosError(error)) return;

    if (error.response?.status === 401) {
      return handleError(error);
    }

    return {
      hasError: true,
      error: error.response?.data,
      isOpen: true
    };
  }

  componentDidCatch(error: Error) {
    PdfPopoverErrorBoundary.handledErrors.add(error);
  }

  render() {
    if (this.state.hasError) {
      const currentHref = window.location.href;

      const onClose = () => {
        window.location.href = currentHref;
      };

      return (
        <Popover
          trigger="click"
          title="The document generation service is not available"
          content={
            <Flex vertical gap={24} style={{ width: 450 }}>
              <Typography.Text style={{ whiteSpace: 'pre-wrap' }}>
                Please send the error details to the application administrator and try again later
              </Typography.Text>

              {this.state.error && (
                <Typography.Text
                  type="secondary"
                  copyable={{ tooltips: ['Copy to clipboard', 'Copied'] }}
                  style={{ whiteSpace: 'pre-wrap' }}>
                  {JSON.stringify(this.state.error).split(',').join(`,\n`)}
                </Typography.Text>
              )}

              <Flex justify="end" gap={8}>
                <Button size={CONTROL_SIZE} onClick={onClose}>
                  Cancel
                </Button>
              </Flex>
            </Flex>
          }
          open={this.state.isOpen}
          placement="bottomLeft">
          <Tooltip title="Export details to...">
            <Button icon={<DownloadOutlined />} size={CONTROL_SIZE} onClick={onClose} />
          </Tooltip>
        </Popover>
      );
    }

    return this.props.children;
  }
}
