import { CONTROL_SIZE } from 'consts/common';
import { Radio } from 'antd';
import React from 'react';

interface IProps {
  testId: string;
  onChange: (value: boolean) => void;
  value: boolean;
  disabled?: boolean;
}

export const BooleanRadioGroup = ({ testId, onChange, value, disabled }: IProps) => (
  <Radio.Group
    disabled={disabled}
    size={CONTROL_SIZE}
    optionType="button"
    buttonStyle="solid"
    data-testid={testId}
    value={value}
    onChange={(e) => onChange && onChange(e.target.value)}>
    <Radio.Button value={true}>Yes</Radio.Button>
    <Radio.Button value={false}>No</Radio.Button>
  </Radio.Group>
);
