/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { City, CityInput, CityListResponse, Error, ValidationError } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Cities<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Create a new city
   *
   * @tags cities
   * @name AddCity
   * @summary Create a new city
   * @request POST:/cities
   * @secure
   */
  addCity = (data: CityInput, params: RequestParams = {}) =>
    this.http.request<City, ValidationError | Error>({
      path: `/cities`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Get a list of cities
   *
   * @tags cities
   * @name GetCities
   * @summary Get a list of cities
   * @request GET:/cities
   * @secure
   */
  getCities = (
    query?: {
      /**
       * The current page number
       * @format int32
       * @min 1
       * @max 10000
       * @default 1
       */
      page?: number;
      /**
       * The number of items to return per page
       * @format int32
       * @min 1
       * @max 10000
       * @default 10
       */
      pageSize?: number;
      /** Filter by city name. Ex - Paris */
      name?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<CityListResponse, Error>({
      path: `/cities`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Get a specific city
   *
   * @tags cities
   * @name GetCityById
   * @summary Get a specific city
   * @request GET:/cities/{cityId}
   * @secure
   */
  getCityById = (cityId: number, params: RequestParams = {}) =>
    this.http.request<City, Error>({
      path: `/cities/${cityId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Update a specific city
   *
   * @tags cities
   * @name UpdateCity
   * @summary Update a specific city
   * @request PUT:/cities/{cityId}
   * @secure
   */
  updateCity = (cityId: number, data: CityInput, params: RequestParams = {}) =>
    this.http.request<City, ValidationError | Error>({
      path: `/cities/${cityId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Delete a specific city
   *
   * @tags cities
   * @name DeleteCity
   * @summary Delete a specific city
   * @request DELETE:/cities/{cityId}
   * @secure
   */
  deleteCity = (cityId: number, params: RequestParams = {}) =>
    this.http.request<void, ValidationError | Error>({
      path: `/cities/${cityId}`,
      method: 'DELETE',
      secure: true,
      ...params
    });
}
