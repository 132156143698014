import { PermitOrder, PermitOrderInput, PermitType } from 'services/api/data-contracts';
import { getUTCDate } from 'helpers';
import { Dayjs } from 'dayjs';
import { capitalize } from 'lodash';
import { SERVER_DATE_TIME_FORMAT } from 'consts/common';

export type PermitOrderInputWithDayJs = Omit<
  PermitOrderInput,
  'createdAt' | 'startDatetime' | 'endDatetime' | 'validFrom' | 'validTill' | 'permitType'
> & {
  createdAt?: Dayjs;
  startEndDate: Dayjs[];
  validityPeriod?: (Dayjs | undefined)[];
  permitType?: string;
};

export const transformForRender = (order: PermitOrder): PermitOrderInputWithDayJs => ({
  ...order,
  createdAt: order.createdAt ? getUTCDate(order.createdAt) : undefined,
  validityPeriod: [
    order.validFrom ? getUTCDate(order.validFrom) : undefined,
    order.validTill ? getUTCDate(order.validTill) : undefined
  ],
  startEndDate: [getUTCDate(order.startDatetime), getUTCDate(order.endDatetime)],
  permitType: order.permitType ? capitalize(order.permitType) : undefined
});

export const transformForRequest = (
  order: PermitOrderInputWithDayJs,
  orderId?: number
): PermitOrderInput & { id?: number } => ({
  ...order,
  startDatetime: order.startEndDate[0]?.format(SERVER_DATE_TIME_FORMAT),
  endDatetime: order.startEndDate[1]?.format(SERVER_DATE_TIME_FORMAT),
  validFrom: order.validityPeriod?.length
    ? order.validityPeriod[0]?.format(SERVER_DATE_TIME_FORMAT)
    : undefined,
  validTill: order.validityPeriod?.length
    ? order.validityPeriod[1]?.format(SERVER_DATE_TIME_FORMAT)
    : undefined,
  permitType: order.permitType ? (order.permitType?.toUpperCase() as PermitType) : undefined,
  ...(orderId ? { id: orderId } : {})
});
