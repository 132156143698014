import React from 'react';
import { Row, Form, Select } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { getSelectOptions } from 'helpers';
import { orderStatuses } from 'consts/order';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { useOrderStore } from 'store/orderStore';
import { useSelectAutoFocus } from 'helpers';

export const OrderStatusSelect = () => {
  const { isOrderEditMode } = useOrdersDashboardStore(({ isOrderEditMode }) => ({
    isOrderEditMode
  }));
  const { current } = useOrderStore(({ current }) => ({ current }));

  const ref = useSelectAutoFocus(isOrderEditMode);

  if (!isOrderEditMode || !current?.id) {
    return null;
  }

  return (
    <Row>
      <Form.Item name="status" style={{ width: '120px' }}>
        <Select
          options={getSelectOptions(orderStatuses)}
          size={CONTROL_SIZE}
          placeholder="Status"
          ref={ref}
        />
      </Form.Item>
    </Row>
  );
};
