import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Segmented, Select } from 'antd';
import { StyledDatePicker } from 'components/common/StyledDatePicker';
import { contractTypes } from 'consts/contract';
import { CounterpartyInput } from 'components/CounterpartyInput/CounterpartyInput';
import { ContractType, DueDateCondition, PaymentTerm } from 'services/api/data-contracts';
import { ContractCurrenciesInput } from 'components/CurrencyInput/ContractCurrenciesInput';
import React from 'react';
import { FormInstance } from 'antd/es/form';
import { ContractWithDayJs } from 'store/contractStore';
import { getSelectOptions } from 'helpers';

interface IProps {
  onCancel?: () => void;
  onSubmit: () => void;
  isLoading: boolean;
  form: FormInstance<ContractWithDayJs>;
  data?: Partial<ContractWithDayJs>;
  isEditMode?: boolean;
}

export const ContractForm = ({ form, isEditMode, isLoading, onCancel, onSubmit }: IProps) => {
  const type = Form.useWatch('type', form);
  const paymentTerm = Form.useWatch(
    'paymentTerm',
    form as unknown as FormInstance<ContractWithDayJs>
  );
  const startDate = Form.useWatch('startDate', form as unknown as FormInstance<ContractWithDayJs>);

  return (
    <Form
      name="order"
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 40 }}
      style={{ justifyContent: 'center' }}
      layout="vertical"
      form={form}
      autoComplete="off"
      colon={false}
      className="styled-form"
      onValuesChange={(updated, formData) => {
        const { currencies, startDate, number, supplierId, customerId } =
          formData as ContractWithDayJs;

        if (currencies.length && startDate) {
          form.setFieldValue(
            'description',
            `${number || '""'}-${currencies.join('/')} from ${startDate.format(DATE_FORMAT)}`
          );
        }

        if (updated.type) {
          form.setFieldsValue({ supplierId: customerId, customerId: supplierId });
        }

        if (updated.paymentTerm === PaymentTerm.Prepayment) {
          form.setFieldsValue({ dueDateCount: 0, dueDateCondition: undefined });
        }
      }}>
      <Row justify="space-between">
        <Col span={8}>
          <Form.Item label="#" name="id" labelCol={{ span: 9 }} className="mr">
            <Input disabled size={CONTROL_SIZE} placeholder="Will be assigned automatically" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Created" name="createdAt" className="mr">
            <DatePicker
              style={{ width: '100%' }}
              disabled
              size={CONTROL_SIZE}
              format={DATE_FORMAT}
              placeholder="Will be assigned automatically"
            />
          </Form.Item>
        </Col>
        <Col span={8} style={{ display: 'flex', justifyContent: 'end' }}>
          <Form.Item name="type" label=" ">
            <Segmented options={contractTypes} size={CONTROL_SIZE} />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={8}>
          <Form.Item label="Number" name="number" labelCol={{ span: 9 }} className="mr">
            <Input size={CONTROL_SIZE} placeholder="Input number" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Start date"
            name="startDate"
            rules={[{ required: true }]}
            className="mr">
            <StyledDatePicker
              id="startDate"
              format={DATE_FORMAT}
              placeholder="Select start date"
              onSetDate={(value) => {
                form && form.setFieldValue('startDate', value);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="End date"
            name="endDate"
            rules={[
              {
                validator: async (_, value) => {
                  if (value && startDate && value <= startDate) {
                    return Promise.reject('End date should be later than a start date');
                  }
                }
              }
            ]}>
            <StyledDatePicker
              id="endDate"
              format={DATE_FORMAT}
              placeholder="Select end date"
              onSetDate={(value) => {
                form && form.setFieldValue('endDate', value);
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="space-between">
        <Col span={12}>
          <CounterpartyInput
            label="Customer"
            name="customerId"
            labelSpan={0}
            isSelf={type === ContractType.WithSupplier}
            disabled={type === ContractType.WithCustomer}
            className="mr"
            required
            onChange={(counterparty) => form?.setFieldValue('customerId', counterparty?.id)}
          />
        </Col>
        <Col span={12}>
          <CounterpartyInput
            label="Supplier"
            name="supplierId"
            labelSpan={6}
            isSelf={type === ContractType.WithCustomer}
            disabled={type === ContractType.WithSupplier}
            required
            onChange={(counterparty) => form?.setFieldValue('supplierId', counterparty?.id)}
          />
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <ContractCurrenciesInput labelSpan={0} className="mr" />
        </Col>
        <Col span={12}>
          <Form.Item
            label="Description"
            name="description"
            labelCol={{ span: 6 }}
            rules={[{ required: true }]}>
            <Input size={CONTROL_SIZE} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="Agent fee"
        name="agentFee"
        labelCol={{ span: 6 }}
        hidden={type !== ContractType.WithCustomer}>
        <InputNumber type="number" min={0} max={100} size={CONTROL_SIZE} precision={2} />
      </Form.Item>

      <Row justify="space-between">
        <Col span={8}>
          <Form.Item
            label="Payment term"
            name="paymentTerm"
            labelCol={{ span: 15 }}
            className="mr"
            rules={[{ required: true }]}>
            <Select options={getSelectOptions(Object.values(PaymentTerm))} size={CONTROL_SIZE} />
          </Form.Item>
        </Col>
        <Col span={16} style={{ display: 'flex', justifyContent: 'end' }}>
          <Form.Item
            label=" "
            name="dueDateCount"
            labelCol={{ span: 6 }}
            rules={[
              { min: 0, type: 'integer', message: 'Days count should be a positive number' },
              {
                validator: async (_, value) => {
                  if (!value && paymentTerm === PaymentTerm.Postpayment) {
                    return Promise.reject('Due date count is required for postpayment contract');
                  }
                }
              }
            ]}>
            <InputNumber
              type="number"
              addonBefore="Due date"
              size={CONTROL_SIZE}
              disabled={paymentTerm === PaymentTerm.Prepayment}
              addonAfter={
                <Form.Item
                  name="dueDateCondition"
                  noStyle
                  rules={[
                    {
                      validator: async (_, value) => {
                        if (!value && paymentTerm === PaymentTerm.Postpayment) {
                          return Promise.reject(
                            'Due date condition is required for postpayment contract'
                          );
                        }
                      }
                    }
                  ]}>
                  <Select
                    style={{ width: '260px' }}
                    disabled={paymentTerm === PaymentTerm.Prepayment}
                    options={Object.values(DueDateCondition).map((value) => ({
                      label: `days after ${value.toLowerCase()}`,
                      value
                    }))}
                  />
                </Form.Item>
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="end">
        {onCancel && (
          <Button size={CONTROL_SIZE} onClick={onCancel} loading={isLoading}>
            Cancel
          </Button>
        )}
        <Button
          size={CONTROL_SIZE}
          type="primary"
          ghost
          style={{ marginLeft: 12 }}
          onClick={onSubmit}
          loading={isLoading}>
          {isEditMode ? 'Update' : 'Save'}
        </Button>
      </Row>
    </Form>
  );
};
