import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { ordersApi } from 'services/api';
import { OrderType, PermitOrderInput } from 'services/api/data-contracts';
import { ENTITY_NAME, PMT_ORDER_QUERY_KEY } from './consts';
import { useOrderStore } from 'store/orderStore';
import { WIDGET_QUERY_KEY } from '../consts';
import { MESSAGE_DURATION } from 'consts/common';

const createItem = async (data: PermitOrderInput) => await ordersApi.addPermitOrder(data);

export const useCreatePermitOrder = (
  onSuccess?: (orderType: OrderType, orderId: number) => void
) => {
  const { setCurrent } = useOrderStore(({ setCurrent }) => ({
    setCurrent
  }));
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createItem,
    onSuccess: async (response) => {
      await queryClient.invalidateQueries({ queryKey: [PMT_ORDER_QUERY_KEY] });
      await queryClient.refetchQueries({ queryKey: [WIDGET_QUERY_KEY] });

      message.success(
        `${ENTITY_NAME} #${response.data.number} has been successfully created`,
        MESSAGE_DURATION
      );

      setCurrent(
        response.data
          ? {
              ...response.data,
              flightIds: response.data.flights?.map(({ id }) => id),
              type: OrderType.PMT
            }
          : undefined
      );

      onSuccess && onSuccess(OrderType.PMT, response.data.id);
    }
  });
};
