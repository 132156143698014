import { CreditNoteStatus, InvoiceStatus } from 'services/api/data-contracts';

export const getDocumentStatusTag = (
  isPosted?: boolean,
  status?: InvoiceStatus | CreditNoteStatus | null
) => {
  if (status) {
    return status;
  }

  return isPosted ? 'P' : 'NP';
};
