import { LINK_STATUS, NOTIFICATION } from './constants';

export const validateLinkingStatus = (status: string) => {
  if (status === LINK_STATUS.SELECT_MESSAGE) {
    throw new Error(NOTIFICATION.NO_MESSAGES_SELECTED);
  }

  if (status === LINK_STATUS.INVALID_LINKS_COMBINATION) {
    throw new Error(NOTIFICATION.SOME_LINKS_EXIST);
  }
};
