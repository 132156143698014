import React, { useState } from 'react';
import { Button, Checkbox, Divider, Form, InputNumber, Select, Space } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { useInvoiceStore } from 'store/invoiceStore';
import { InvoiceType } from 'services/api/data-contracts';
import { CheckOutlined } from '@ant-design/icons';
import { getAgentFeeOption, OptionType } from './InvoiceItemsForm';
import { isNumber, sortBy, uniqBy } from 'lodash';
import { BaseOptionType } from 'antd/es/select';

interface IProps {
  getLabel: (label: string) => string;
  formListItemKey: number;
  agentFeeOptions: OptionType[];
  setAgentFeeOptions: React.Dispatch<React.SetStateAction<OptionType[]>>;
  disabled?: boolean;
}

export const AgentFeeInput = ({
  getLabel,
  formListItemKey,
  setAgentFeeOptions,
  agentFeeOptions,
  disabled
}: IProps) => {
  const { isIssuedInvoice } = useInvoiceStore(({ current }) => ({
    isIssuedInvoice: current?.type === InvoiceType.Issued
  }));

  const [newAgentFee, setNewAgentFee] = useState<number | null>(null);

  const addAgentFeeValue = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault();

    if (isNumber(newAgentFee)) {
      const newAgentFeeOptions = uniqBy(
        [...agentFeeOptions, getAgentFeeOption(newAgentFee)],
        'value'
      );
      setAgentFeeOptions(sortBy(newAgentFeeOptions, ['value']));
    }

    setNewAgentFee(null);
  };

  if (isIssuedInvoice) {
    const renderOption = (option: BaseOptionType) => (
      <span aria-label={option.data.label}>{option.data.description}</span>
    );

    const renderDropdown = (menu: React.ReactNode) => (
      <>
        {menu}
        <Divider style={{ margin: '8px 0' }} />
        <Space style={{ padding: '0 8px 4px' }}>
          <InputNumber
            type="number"
            min={0}
            max={100}
            size={CONTROL_SIZE}
            value={newAgentFee}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={setNewAgentFee}
          />
          <Button
            type="text"
            icon={<CheckOutlined />}
            onClick={addAgentFeeValue}
            size={CONTROL_SIZE}
          />
        </Space>
      </>
    );

    return (
      <Form.Item
        label={getLabel('Own p..')}
        name={[formListItemKey, 'ownPrice_agentFee']}
        style={{ flexBasis: 74 }}>
        <Select
          size={CONTROL_SIZE}
          options={agentFeeOptions}
          popupMatchSelectWidth={false}
          optionRender={renderOption}
          dropdownRender={renderDropdown}
          disabled={disabled}
        />
      </Form.Item>
    );
  }

  return (
    <Form.Item label={getLabel('O')} name={[formListItemKey, 'isOwnPrice']} valuePropName="checked">
      <Checkbox style={{ marginRight: formListItemKey === 0 ? 0 : 8 }} disabled={disabled} />
    </Form.Item>
  );
};
