import React, { useMemo, useState } from 'react';
import { StyledCollapse } from 'components/common/StyledCollapse';
import { CollapseProps, Flex } from 'antd';
import { CONTROL_SIZE, PAGE_SIZE_50 } from 'consts/common';
import { CreditNotesView } from './CreditNotesView';
import { useInvoiceStore } from 'store/invoiceStore';
import { blue } from '@ant-design/colors';
import { StyledBadge } from 'components/common/StyledBadge';
import { useGetPayments } from 'queries/payment';

export const CreditNotesBlock = () => {
  const [isCreditNotesCreationMode, setCreditNotesCreationMode] = useState(false);
  const [page, setPage] = useState(1);

  const { current } = useInvoiceStore(({ current }) => ({
    current
  }));

  const { data, isFetching } = useGetPayments({
    correctedInvoiceId: current?.id,
    pageSize: PAGE_SIZE_50,
    page
  });

  const creditNotes = useMemo(() => data?.data.items || [], [data]);

  const collapseContent: CollapseProps['items'] = [
    {
      key: 'creditNotes',
      label: (
        <Flex gap={4} align="center">
          Credit note: Invoice correction <StyledBadge count={data?.data.total} color={blue[0]} />
        </Flex>
      ),
      children: (
        <CreditNotesView
          creditNotes={creditNotes}
          onSetCreationMode={setCreditNotesCreationMode}
          isCreditNotesCreationMode={isCreditNotesCreationMode}
          isLoading={isFetching}
          total={data?.data.total}
          page={page}
          setPage={setPage}
        />
      )
    }
  ];

  return (
    <StyledCollapse items={collapseContent} expandIconPosition="end" size={CONTROL_SIZE} ghost />
  );
};
