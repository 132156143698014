import { useState, useCallback } from 'react';

export const useHoverMessage = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(isDropdownOpen);
  }, [isDropdownOpen]);

  const handleClick = useCallback(() => {
    setIsDropdownOpen((prev) => !prev);
  }, []);

  return { isHovered, isDropdownOpen, handleMouseEnter, handleMouseLeave, handleClick };
};
