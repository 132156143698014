import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { currencyRatesApi } from 'services/api';
import { ENTITY_NAME, QUERY_KEY } from './consts';
import { SERVER_DATE_FORMAT_CURRENCY_RATE, MESSAGE_DURATION } from 'consts/common';
import { getUTCDate } from 'helpers';

const deleteItem = async (date: string) =>
  await currencyRatesApi.deleteCurrencyRates({
    date: getUTCDate(date).format(SERVER_DATE_FORMAT_CURRENCY_RATE)
  });

export const useDeleteCurrencyRate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteItem,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });

      message.success(`${ENTITY_NAME} has been successfully removed`, MESSAGE_DURATION);
    }
  });
};
