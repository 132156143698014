import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { RebateHydrated, RebateType } from 'services/api/data-contracts';
import { Dayjs } from 'dayjs';
import { getUTCDate } from 'helpers';

export const RebateTypeMap = {
  [RebateType.AgentFeeDiscount]: 'Discount on agent fee',
  [RebateType.AgentFeeExtra]: 'Extra cost for agent fee',
  [RebateType.InvoiceItemDiscount]: 'Discount on invoice item',
  [RebateType.InvoiceItemExtra]: 'Extra cost for invoice item'
};

export const isAgentFeeRebate = (type?: RebateType) => type?.includes('AgentFee');
export const isDiscountRebate = (type?: RebateType) => type?.includes('Discount');
export const getRebateSign = (type?: RebateType) => (isDiscountRebate(type) ? '-' : '+');

export type RebateHydratedWithDayJs = Omit<
  RebateHydrated,
  'endDate' | 'startDate' | 'createdAt' | 'updatedAt'
> & {
  endDate: Dayjs | null;
  startDate: Dayjs | null;
  createdAt: Dayjs | null;
  updatedAt: Dayjs | null;
};

type RebateStore = {
  current?: Partial<RebateHydratedWithDayJs>;
  setCurrent: (current?: Partial<RebateHydrated>) => void;
  list: RebateHydrated[];
  setList: (data: { items: RebateHydrated[]; total: number }) => void;
  page: number;
  setPage: (page: number) => void;
  total: number;
};

export const useRebateStore = create<RebateStore>()(
  devtools((set) => ({
    current: undefined,
    setCurrent: (curr) => {
      const current = curr
        ? {
            ...curr,
            endDate: curr?.endDate ? getUTCDate(curr?.endDate) : null,
            startDate: curr?.startDate ? getUTCDate(curr?.startDate) : null,
            createdAt: curr?.createdAt ? getUTCDate(curr?.createdAt) : null,
            updatedAt: curr?.updatedAt ? getUTCDate(curr?.updatedAt) : null
          }
        : undefined;
      set({ current });
    },
    list: [],
    setList: ({ items: list, total }) => {
      set({ list, total });
    },
    page: 1,
    setPage: (page) => {
      set({ page });
    },
    total: 0
  }))
);
