/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Error,
  InvoiceItemBaseInput,
  InvoiceItemFromServiceInput,
  InvoiceItemHydratedListResponse,
  InvoiceItemHydratedWithRolledUp,
  InvoiceItemUpdateInput,
  ValidationError
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class InvoiceItems<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Get a list of Invoice Item
   *
   * @tags invoice items
   * @name GetAllInvoiceItems
   * @summary Get a list of Invoice Item
   * @request GET:/invoice-items
   * @secure
   */
  getAllInvoiceItems = (params: RequestParams = {}) =>
    this.http.request<InvoiceItemHydratedListResponse, Error>({
      path: `/invoice-items`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Create a new Invoice Items
   *
   * @tags invoice items
   * @name AddInvoiceItems
   * @summary Create a new Invoice Items
   * @request POST:/invoice-items
   * @secure
   */
  addInvoiceItems = (
    data: (InvoiceItemBaseInput | InvoiceItemFromServiceInput)[],
    params: RequestParams = {}
  ) =>
    this.http.request<InvoiceItemHydratedListResponse, ValidationError | Error>({
      path: `/invoice-items`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Update a Invoice items in batch
   *
   * @tags invoice items
   * @name UpdateInvoiceItems
   * @summary Update a Invoice items in batch
   * @request PUT:/invoice-items
   * @secure
   */
  updateInvoiceItems = (data: InvoiceItemUpdateInput[], params: RequestParams = {}) =>
    this.http.request<InvoiceItemHydratedListResponse, ValidationError | Error>({
      path: `/invoice-items`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Get a specific Invoice item by id
   *
   * @tags invoice items
   * @name GetInvoiceItemById
   * @summary Get a specific Invoice item
   * @request GET:/invoice-items/{invoiceItemId}
   * @secure
   */
  getInvoiceItemById = (
    invoiceItemId: number,
    query?: {
      /** Include rolledUp invoice items */
      withRolledUp?: boolean;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<InvoiceItemHydratedWithRolledUp, Error>({
      path: `/invoice-items/${invoiceItemId}`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Delete a specific Invoice item by id
   *
   * @tags invoice items
   * @name DeleteInvoiceItem
   * @summary Delete a specific Invoice item
   * @request DELETE:/invoice-items/{invoiceItemId}
   * @secure
   */
  deleteInvoiceItem = (invoiceItemId: number, params: RequestParams = {}) =>
    this.http.request<void, ValidationError | Error>({
      path: `/invoice-items/${invoiceItemId}`,
      method: 'DELETE',
      secure: true,
      ...params
    });
}
