import { useContext, useMemo } from 'react';
import { MenuProps, message } from 'antd';
import { useOptimisticUpdateMessage } from 'queries/messages/useOptimisticUpdateMessage';
import { DropdownItemKeys } from './consts';
import { FLAG } from '../../consts';
import { LinkActionTypes, LinkedEntityTypes } from 'services/api/data-contracts';
import { useFlightsDashboardStore } from 'store/flightsDashboardStore';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { getSourceLinks, SourceInput } from './helpers/getSourceLinks';
import { useGetLinks } from 'queries/links/useGetLinks';
import { useLinkAction } from '../../helpers/useLinkAction';
import { getDropdownItems } from './helpers/getDropdownItems';
import { FlagList } from 'services/emailEngineApi/data-contracts';
import { useCustomTagUpdate } from './useCustomTagUpdate';
import { useMailboxConfig } from '../../helpers/useMailboxConfig';
import { getColoredTags } from '../../helpers/getColoredTags';
import { map } from 'lodash';
import { DashboardContext, DashboardType } from '../../../../consts';
import { useUpdateMessageLinks } from 'queries/links/useUpdateMessageLinks';
import { useSettlementDashboardStore } from 'store/settlementDashboardStore';
import { useFlightProgramStore } from 'store/flightProgramStore';

export const useDropdownItemClick = (
  messageId: string,
  isMessageSeen: boolean,
  isMessageDone: boolean,
  isDropdownOpen: boolean,
  flags?: FlagList
) => {
  const { type: dashboardType } = useContext(DashboardContext);
  const { customFlags } = useMailboxConfig();
  const coloredTags = getColoredTags(customFlags);

  const { selectedFlightIds } = useFlightsDashboardStore(({ selectedFlights }) => ({
    selectedFlightIds: map(selectedFlights, 'id')
  }));
  const { selectedDispatcherOrderNumbers } = useOrdersDashboardStore(({ selectedOrders }) => ({
    selectedDispatcherOrderNumbers: map(selectedOrders, 'number')
  }));
  const { selectedProgramIds } = useFlightProgramStore(({ selectedPrograms }) => ({
    selectedProgramIds: map(selectedPrograms, 'id')
  }));
  const { selectedSettlementOrderNumbers } = useSettlementDashboardStore(({ selectedOrders }) => ({
    selectedSettlementOrderNumbers: selectedOrders
  }));

  const selectedOrderNumbers =
    dashboardType === DashboardType.Dispatcher
      ? selectedDispatcherOrderNumbers
      : selectedSettlementOrderNumbers;

  const {
    data: linksResponse,
    isError,
    isFetching
  } = useGetLinks({ messageIds: messageId }, isDropdownOpen);

  const linkedFlightIds = map(linksResponse?.data.flights || [], 'id');
  const linkedOrderNumbers = map(linksResponse?.data.orders || [], 'number');
  const linkedProgramIds = map(linksResponse?.data.programs || [], 'id');
  const linkedInvoiceIds = map(linksResponse?.data.invoices || [], 'id');

  const flagMutation = useOptimisticUpdateMessage();
  const linkMutation = useUpdateMessageLinks();
  const toggleFlag = useCustomTagUpdate(messageId, flags);

  const linkAction = useLinkAction({
    isDropdownOpen,
    isError,
    isFetching,
    linkedFlightIds,
    linkedOrderNumbers,
    linkedProgramIds,
    linkedInvoiceIds,
    selectedFlightIds,
    selectedOrderNumbers,
    selectedProgramIds
  });

  const dropdownItems = useMemo(
    () => getDropdownItems(linkAction, isMessageSeen, isMessageDone, coloredTags),
    [linkAction, isMessageSeen, isMessageDone, coloredTags]
  );

  const onClickDropdownItem: MenuProps['onClick'] = async (e) => {
    e.domEvent.stopPropagation();
    if (!messageId) return;

    const toggleMessageSeenFlag = () => {
      flagMutation.mutate({
        messageId,
        data: { flags: { [isMessageSeen ? 'delete' : 'add']: [FLAG.SEEN] } }
      });
    };

    const updateMessageLinks = async () => {
      if (!linkAction || !['link-selected', 'unlink-selected', 'unlink-all'].includes(linkAction))
        return;

      let action: LinkActionTypes = LinkActionTypes.Link;
      let sourceParams: SourceInput = {
        flightIds: selectedFlightIds,
        orderNumbers: selectedOrderNumbers,
        programIds: selectedProgramIds
      };

      if (linkAction === 'unlink-selected') {
        action = LinkActionTypes.Unlink;
      } else if (linkAction === 'unlink-all') {
        action = LinkActionTypes.Unlink;
        sourceParams =
          dashboardType === DashboardType.Dispatcher
            ? {
                flightIds: linkedFlightIds,
                orderNumbers: linkedOrderNumbers,
                programIds: selectedProgramIds
              }
            : { invoiceIds: linkedInvoiceIds, orderNumbers: linkedOrderNumbers };
      }

      const source = getSourceLinks(sourceParams);

      message.loading(`${action}ing in progress...`);
      await linkMutation.mutateAsync({
        action,
        source,
        target: [{ entityType: LinkedEntityTypes.Message, entityId: messageId }]
      });
    };

    const toggleMessageDoneFlag = () => {
      flagMutation.mutate({
        messageId,
        data: { flags: { [isMessageDone ? 'delete' : 'add']: [FLAG.DONE] } }
      });
    };

    switch (e.key) {
      case DropdownItemKeys.Link:
        await updateMessageLinks();
        break;
      case DropdownItemKeys.Read:
        toggleMessageSeenFlag();
        break;
      case DropdownItemKeys.Done:
        toggleMessageDoneFlag();
        break;
      case DropdownItemKeys.LabelAs:
        break;
      default:
        await toggleFlag(e.key);
    }
  };

  return { onClickDropdownItem, dropdownItems };
};
