import { AxiosResponse } from 'axios';
import { useGetAttachment } from 'queries/messages/useGetAttachment';
import { Model4 as Attachment } from 'services/emailEngineApi/data-contracts';

export const useDownloadFile = (attachment: Attachment) => {
  const { refetchFile } = useGetAttachment();

  const downloadFileOrThrowError = async ({ isNewTab = false } = {}) => {
    try {
      const response = (await refetchFile(attachment.id as string)) as AxiosResponse<
        BlobPart,
        unknown
      >;
      if (response.data) {
        const blob = new Blob([response.data], { type: attachment.contentType });
        const url = window.URL.createObjectURL(blob);

        if (isNewTab) {
          window.open(url, '_blank');
          setTimeout(() => {
            window.URL.revokeObjectURL(url);
          }, 100);
        } else {
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', attachment.filename as string);
          link.click();

          window.URL.revokeObjectURL(url);
        }
      }
    } catch (error) {
      console.error('Error fetching binary:', error);
      throw error;
    }
  };

  return {
    downloadFileOrThrowError
  };
};
