import { InvoiceType, SettlementOrder } from 'services/api/data-contracts';
import { invoiceInitialData } from 'consts/invoice';
import { InvoiceHydratedWithAdditionalData } from 'store/invoiceStore';

export const getInvoiceData = (data: {
  type: InvoiceType;
  isSomeServiceSelected?: boolean;
  orders?: SettlementOrder[];
}) => {
  const counterparty =
    data.type === InvoiceType.Received
      ? {
          supplierId: data.orders?.[0]?.orderServices?.[0].supplierId
        }
      : {
          payerId: data.orders?.[0]?.payer?.id
        };

  return {
    ...invoiceInitialData,
    ...data,
    ...counterparty
  } as unknown as InvoiceHydratedWithAdditionalData;
};
