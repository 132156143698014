import { ConfigProvider, Empty, Form, Select } from 'antd';
import React from 'react';
import { CONTROL_SIZE } from 'consts/common';
import { useVatRateStore } from 'store/vatRateStore';
import { useGetVatRates } from 'queries/vatRate';

interface IProps {
  name: string | (string | number)[];
  label?: string;
  disabled?: boolean;
  required?: boolean;
}

export const VatRateInput = ({ disabled, label, name, required }: IProps) => {
  const { list } = useVatRateStore(({ list }) => ({ list }));

  const { isLoading } = useGetVatRates();

  const options = list.map(({ name, id, isActive }) => ({
    label: name,
    value: id,
    disabled: !isActive
  }));

  return (
    <ConfigProvider
      renderEmpty={() => <Empty description="No data" image={Empty.PRESENTED_IMAGE_SIMPLE} />}>
      <Form.Item
        name={name}
        label={label}
        rules={[{ required, message: 'Is required' }]}
        style={{ margin: 0 }}>
        <Select
          allowClear
          style={{ width: '100%' }}
          placeholder="Select VAT rate"
          options={options}
          size={CONTROL_SIZE}
          disabled={disabled}
          showSearch
          optionFilterProp="label"
          loading={isLoading}
          popupMatchSelectWidth={false}
        />
      </Form.Item>
    </ConfigProvider>
  );
};
