import React from 'react';
import { omit } from 'lodash';
import { getDateTagValue } from 'helpers';
import { ProfileOutlined } from '@ant-design/icons';
import { getJoinedProps, OptionPropName } from 'helpers/dataTransformations/getJoinedProps';
import { FlightsFilter } from 'store/flightsDashboardStore';
import { FilterTag } from 'pages/components/FilterTag';

export const getAppliedFlightFilters = (
  filter: FlightsFilter,
  setFilter: (filter: FlightsFilter) => void,
  setFilterCallback: (filter: FlightsFilter) => void
) =>
  Object.keys(filter)
    .filter((key) => filter[key as keyof FlightsFilter] !== undefined)
    .map((filterKey) => {
      const key = filterKey as keyof FlightsFilter;
      let value: string | undefined;

      switch (key) {
        case 'airport':
          if (filter.airport) {
            value = filter.airport.code;
          }
          break;
        case 'estimatedTimeDeparture':
          if (filter.estimatedTimeDeparture) {
            value = getDateTagValue(filter.estimatedTimeDeparture);
          }
          break;
        case 'operators':
        case 'flightsAircrafts':
          if (filter[key]) {
            value = getJoinedProps(filter[key], OptionPropName.Label);
          }
          break;
        default:
          value = (filter[key] as unknown)?.toString();
      }

      if (!value) {
        return null;
      }

      const onClose = () => {
        setFilter(omit(filter, key));
        setFilterCallback(omit(filter, key));
      };

      const icon =
        (key as string).toLowerCase().includes('time') ||
        (key as string).toLowerCase().includes('date') ? (
          <ProfileOutlined style={{ color: '#8C8C8C' }} />
        ) : undefined;

      return (
        <FilterTag
          name={key as string}
          value={value}
          onClose={onClose}
          key={key as string}
          icon={icon}
        />
      );
    });
