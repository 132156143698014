import React from 'react';
import { Space, Button } from 'antd';
import { CloseButton } from 'components/Drawer';
import { CONTROL_SIZE } from 'consts/common';
import { useCtrlEnterShortcut } from 'helpers';

interface DrawerHeaderProps {
  onSubmit: () => Promise<void>;
  onClose: () => void;
  isSubmitDisabled: boolean;
  isLoading?: boolean;
}

export const HeaderButtons = ({
  onSubmit,
  onClose,
  isSubmitDisabled,
  isLoading
}: DrawerHeaderProps) => {
  useCtrlEnterShortcut(onSubmit, !isSubmitDisabled);

  return (
    <Space size="middle">
      <Button
        type="primary"
        onClick={onSubmit}
        disabled={isSubmitDisabled}
        size={CONTROL_SIZE}
        loading={isLoading}>
        Send
      </Button>

      <CloseButton onClick={onClose} />
    </Space>
  );
};
