import { useQuery } from '@tanstack/react-query';
import { PermitOrder, OrderType } from 'services/api/data-contracts';
import { ordersApi } from 'services/api';
import { useOrderStore } from 'store/orderStore';
import { PMT_ORDER_QUERY_KEY } from './consts';

const fetchItem = async (id: number) => await ordersApi.getPermitOrderById(id);

export const useGetPermitOrder = (
  enabled: boolean,
  id?: number,
  onSuccess?: (item?: PermitOrder) => void
) => {
  const { setCurrent } = useOrderStore(({ setCurrent }) => ({
    setCurrent
  }));

  return useQuery({
    queryKey: [PMT_ORDER_QUERY_KEY, id],
    queryFn: async () => {
      if (id) {
        return await fetchItem(id);
      }
    },
    onSuccess: (response) => {
      setCurrent(
        response?.data
          ? {
              ...response.data,
              flightIds: response.data.flights?.map(({ id }) => id),
              type: OrderType.PMT
            }
          : undefined
      );

      onSuccess && onSuccess(response?.data);
    },
    enabled
  });
};
