import { Descriptions, DescriptionsProps } from 'antd';
import React, { useContext } from 'react';
import { DataContext } from '../DictionaryDetails';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import { CurrencyRateWithId } from 'store/currencyRateStore';

export const CurrencyRateDictionaryView = () => {
  const data = useContext(DataContext) as CurrencyRateWithId;

  if (!data) {
    return null;
  }

  const getRates = () => {
    const result = [];

    for (const currency in data.exchangeRates) {
      result.push(`${currency} ${data.exchangeRates[currency]}`);
    }

    return result.join(`\n`);
  };

  const descriptions: DescriptionsProps['items'] = [
    {
      label: 'Date',
      children: data.date.format(DATE_FORMAT)
    },
    {
      label: `1 ${data.sourceCurrency} =`,
      children: <span style={{ whiteSpace: 'pre-line' }}>{getRates()}</span>
    }
  ];

  return <Descriptions size={CONTROL_SIZE} items={descriptions} column={2} bordered />;
};
