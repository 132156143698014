import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, ConfigProvider, Empty, Flex, Popover, Radio, Select, Spin, Tooltip } from 'antd';
import { DownloadOutlined, MailOutlined } from '@ant-design/icons';
import { CONTROL_SIZE } from 'consts/common';
import {
  Error,
  InvoiceTemplatesListResponse,
  PaymentTemplatesListResponse,
  ReportFormat
} from 'services/api/data-contracts';
import { DashboardContext, DashboardType } from '../../../consts';
import { capitalize } from 'lodash';
import { AxiosError, AxiosResponse } from 'axios';
import { getSelectOptions, handleError } from 'helpers';
import { SIGNATURE_NAME } from 'queries/messages/consts';
import { useMessageStore } from 'store/messageStore';
import { UseQueryResult } from '@tanstack/react-query';

interface IProps {
  entityName: 'invoice' | 'payment' | 'credit note';
  templatesQueryResult: UseQueryResult<
    AxiosResponse<InvoiceTemplatesListResponse | PaymentTemplatesListResponse, unknown>,
    Error
  >;
  format: ReportFormat;
  onSetFormat: React.Dispatch<React.SetStateAction<ReportFormat>>;
  isOpen: boolean;
  onSetOpen: React.Dispatch<React.SetStateAction<boolean>>;
  templateId?: string;
  onSetTemplateId: React.Dispatch<React.SetStateAction<string | undefined>>;
  isEntityLoading: boolean;
  onFetchReport: () => Promise<{ blob: Blob; filename: string; contentType: string }>;
  onAttachToMessage: (filename: string, contentType: string) => void;
}

export const ExportPdfPopover = ({
  entityName,
  templatesQueryResult,
  format,
  onSetFormat,
  isOpen,
  onSetOpen,
  templateId,
  onSetTemplateId,
  isEntityLoading,
  onFetchReport,
  onAttachToMessage
}: IProps) => {
  const [isReportLoading, setReportLoading] = useState(false);
  const [isAttachInProgress, setAttachInProgress] = useState(false);
  const { setAttachmentsBlobData, setCurrentMessage, signatures } = useMessageStore();
  const { type: dashboardType } = useContext(DashboardContext);

  useEffect(() => () => onSetTemplateId(undefined), []);

  const catchReportError = async (err: AxiosError | TypeError | unknown) => {
    if (err instanceof AxiosError && err.response) {
      const decodedResponse = new TextDecoder('utf-8').decode(
        (err.response as AxiosResponse<BufferSource>).data
      );

      await handleError(
        new AxiosError(err.message, err.code, err.config, err.request, {
          data: JSON.parse(decodedResponse)
        } as AxiosResponse<BufferSource>)
      );
    }
  };

  const handleDownload = async () => {
    setReportLoading(true);

    try {
      const { blob, filename } = await onFetchReport();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      await catchReportError(err);
    } finally {
      setReportLoading(false);
      onSetTemplateId(undefined);
      onSetOpen(false);
    }
  };

  const handleAttachToMessage = async () => {
    setAttachInProgress(true);
    setReportLoading(true);
    try {
      const { blob, filename, contentType } = await onFetchReport();
      setAttachmentsBlobData([{ blob, filename, contentType }]);

      const signature = signatures?.find(({ name }) => name === SIGNATURE_NAME.NEW);
      if (signature) setCurrentMessage({ body: signature.text });

      onAttachToMessage(filename, contentType);
    } catch (err) {
      await catchReportError(err);
    } finally {
      setReportLoading(false);
      setAttachInProgress(false);
      onSetTemplateId(undefined);
      onSetOpen(false);
    }
  };

  const selectOptions = useMemo(
    () =>
      (templatesQueryResult.data?.data.items || []).map(({ id, name }) => ({
        value: id,
        label: capitalize(name)
      })),
    [templatesQueryResult]
  );

  const popoverBody = isReportLoading ? (
    <Spin />
  ) : (
    <Flex gap={8} style={{ width: '100%' }}>
      <div>
        <div>Format</div>
        <Radio.Group
          options={getSelectOptions(Object.values(ReportFormat))}
          onChange={({ target: { value } }) => onSetFormat(value)}
          value={format}
          optionType="button"
          buttonStyle="solid"
          size={CONTROL_SIZE}
        />
      </div>

      <div style={{ flexGrow: 1 }}>
        <div>{capitalize(entityName)} template</div>
        <ConfigProvider
          renderEmpty={() => (
            <Empty description="Templates not found" image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}>
          <Select
            placeholder="Please select"
            options={selectOptions}
            size={CONTROL_SIZE}
            style={{ width: '100%' }}
            onChange={onSetTemplateId}
            loading={templatesQueryResult.isLoading || isEntityLoading}
          />
        </ConfigProvider>
      </div>
    </Flex>
  );

  const isLoading =
    templatesQueryResult.isLoading || isEntityLoading || (!isAttachInProgress && isReportLoading);

  const popoverContent = (
    <>
      <Flex align="center" justify="center" style={{ width: 450, height: 46 }}>
        {popoverBody}
      </Flex>

      <Flex justify="end" gap={8} style={{ marginTop: '24px' }}>
        <Button size={CONTROL_SIZE} onClick={() => onSetOpen(false)}>
          Cancel
        </Button>

        <Tooltip title={templateId ? '' : 'Select a template to download'}>
          <Button
            size={CONTROL_SIZE}
            icon={<DownloadOutlined />}
            disabled={!templateId || isReportLoading}
            onClick={handleDownload}
            loading={isLoading}>
            Download
          </Button>
        </Tooltip>

        {dashboardType !== DashboardType.Balance && (
          <Tooltip title={templateId ? '' : 'Select a template to attach'}>
            <Button
              size={CONTROL_SIZE}
              icon={<MailOutlined />}
              disabled={!templateId || isReportLoading}
              onClick={handleAttachToMessage}
              loading={isLoading}>
              Attach to a message
            </Button>
          </Tooltip>
        )}
      </Flex>
    </>
  );

  return (
    <Popover
      trigger="click"
      title="Export details"
      content={popoverContent}
      open={isOpen}
      placement="bottomLeft"
      onOpenChange={onSetOpen}>
      <Tooltip title="Export details to...">
        <Button
          icon={<DownloadOutlined />}
          size={CONTROL_SIZE}
          loading={isEntityLoading}
          onClick={() => onSetOpen(true)}
        />
      </Tooltip>
    </Popover>
  );
};
