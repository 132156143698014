import { Button, Flex, Popover } from 'antd';
import React, { useEffect, useState } from 'react';
import { useForm } from 'antd/es/form/Form';
import { OrderService } from 'services/api/data-contracts';
import { CONTROL_SIZE } from 'consts/common';
import { isEmpty, omitBy } from 'lodash';
import { TooltipPlacement } from 'antd/es/tooltip';
import { useFlightProgramStore } from 'store/flightProgramStore';
import { useUpdateFlightProgramServices } from 'queries/flightProgram/useUpdateFlightProgramServices';
import { ServicesBulkUpdateForm } from './ServicesBulkUpdateForm';
import { useDrawerStore } from 'store/drawerStore';

interface IProps {
  placement?: TooltipPlacement;
  align?: { offset?: [number, number] };
}

export const ProgramServicesBulkOperationsPopover = (popoverProps: IProps) => {
  const [isOpen, setOpen] = useState(false);

  const [form] = useForm<OrderService>();

  const { current, selectedServices, setSelectedServices } = useFlightProgramStore();
  const { isProgramDrawerOpen } = useDrawerStore(({ openDrawers }) => ({
    isProgramDrawerOpen: openDrawers.includes('flightProgram')
  }));

  useEffect(() => {
    setOpen(selectedServices.length >= 1);
  }, [selectedServices]);

  useEffect(() => {
    if (!isProgramDrawerOpen) {
      setSelectedServices([]);
    }
  }, [isProgramDrawerOpen]);

  useEffect(() => {
    if (!isOpen) {
      form.resetFields();
      form.setFieldsValue({});
    }
  }, [isOpen]);

  const handleCancel = () => {
    setOpen(false);
  };

  const updateMutation = useUpdateFlightProgramServices();

  const handleSubmit = async () => {
    try {
      const dataToUpdate = omitBy(form.getFieldsValue(), (value) => value === undefined);

      if (isEmpty(dataToUpdate) || !current?.id) {
        return handleCancel();
      }

      await updateMutation.mutateAsync({
        programId: current.id,
        serviceIds: current.nearestOrder.orderServices
          .filter(({ id }) => selectedServices.includes(id))
          .map((ordersService) => ordersService.service.id),
        dataToUpdate
      });
    } finally {
      setSelectedServices([]);
    }
  };

  return (
    <Popover
      open={isOpen}
      title={`Change for ${selectedServices.length} selected...`}
      arrow={false}
      getPopupContainer={(node) => node}
      {...popoverProps}
      content={
        <>
          <ServicesBulkUpdateForm form={form} selectedServices={selectedServices} />

          <Flex justify="end" gap={8}>
            <Button size={CONTROL_SIZE} onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" size={CONTROL_SIZE} onClick={handleSubmit}>
              Change
            </Button>
          </Flex>
        </>
      }
    />
  );
};
