import React from 'react';
import { usePaymentPurposeStore } from 'store/paymentPurposeStore';
import { PaymentPurposesTable } from './components/PaymentPurposesTable/PaymentPurposesTable';
import { CONTROL_SIZE } from 'consts/common';
import { CollapseProps } from 'antd/es/collapse';
import { AddPaymentForm } from './components/AddPaymentForm/AddPaymentForm';
import { useInvoiceStore } from 'store/invoiceStore';
import { ConfirmActionMessage } from './components/ConfirmActionMessage/ConfirmActionMessage';
import { StyledCollapse } from 'components/common/StyledCollapse';
import { StyledBadge } from 'components/common/StyledBadge';
import { blue } from '@ant-design/colors';
import { Flex } from 'antd';

export const PaymentPurposeBlock = () => {
  const { isCreationMode, showRevertActionPopUp, total } = usePaymentPurposeStore(
    ({ isCreationMode, showRevertActionPopUp, total }) => ({
      isCreationMode,
      showRevertActionPopUp,
      total
    })
  );

  const { current: invoice } = useInvoiceStore(({ current }) => ({
    current
  }));

  const collapseContent: CollapseProps['items'] = [
    {
      key: 'general',
      forceRender: true,
      label: (
        <Flex gap={4} align="center">
          Payment details
          <StyledBadge count={total} color={blue[0]} />
        </Flex>
      ),
      children: (
        <>
          <PaymentPurposesTable />
          {isCreationMode && invoice?.amountDue !== 0 && <AddPaymentForm />}
        </>
      )
    }
  ];

  return (
    <>
      <StyledCollapse items={collapseContent} expandIconPosition="end" size={CONTROL_SIZE} ghost />
      {showRevertActionPopUp && <ConfirmActionMessage />}
    </>
  );
};
