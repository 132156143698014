import React, { useMemo } from 'react';
import { Empty, Form, Select } from 'antd';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import { Invoice } from 'services/api/data-contracts';
import { getAmountValue, getUTCDate } from 'helpers';
import { useGetLinkableInvoices } from 'queries/invoice/useGetLinkableInvoices';
import { useInvoiceStore } from 'store/invoiceStore';

interface IProps {
  name: string;
  paymentId?: number;
  onChange: (value: number, option: Invoice) => void;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
}

export const LinkableInvoicesInput = ({
  paymentId,
  onChange,
  disabled,
  label,
  name,
  placeholder,
  required
}: IProps) => {
  const { linkableInvoices } = useInvoiceStore(({ linkableInvoices }) => ({
    linkableInvoices
  }));
  const { isFetching } = useGetLinkableInvoices(paymentId);

  const getLabel = (invoice: Invoice) => (
    <span>
      {[
        invoice.invoiceNumber,
        invoice.currency,
        getAmountValue(invoice.invoiceTotal),
        getAmountValue(invoice.amountDue),
        invoice.invoiceDate && getUTCDate(invoice.invoiceDate).format(DATE_FORMAT)
      ]
        .filter((i) => !!i)
        .join(' | ')}
    </span>
  );

  const options = useMemo(
    () =>
      linkableInvoices.map((linkableInvoice) => ({
        label: getLabel(linkableInvoice),
        value: linkableInvoice.id
      })),
    [linkableInvoices]
  );

  return (
    <Form.Item name={name} label={label} rules={[{ required }]}>
      <Select
        allowClear
        placeholder={placeholder}
        options={options}
        size={CONTROL_SIZE}
        disabled={disabled}
        loading={isFetching}
        popupMatchSelectWidth={false}
        style={{ minWidth: 332 }}
        notFoundContent={
          <Empty description="Linkable invoices not found" image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }
        onChange={(value) => {
          const invoice = linkableInvoices?.find((item) => item.id === value);
          onChange(value, invoice as Invoice);
        }}
      />
    </Form.Item>
  );
};
