import { Form, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { CONTROL_SIZE, PAGE_SIZE_50 } from 'consts/common';
import { useGetCountries } from 'queries/country';
import { Country } from 'services/api/data-contracts';
import { useDebounce } from 'helpers';
import { unionBy } from 'lodash';

interface IProps {
  name: string;
  label: string;
  placeholder?: string;
  labelSpan?: number;
  disabled?: boolean;
  isMultiple?: boolean;
  required?: boolean;
  className?: string;
  initialFilter?: string;
  initialList?: Country[];
  onChange?: (value: number) => void;
}

export const CountriesInput = ({
  labelSpan,
  disabled,
  isMultiple,
  label,
  name,
  placeholder,
  required,
  className,
  initialFilter = '',
  initialList = [],
  onChange
}: IProps) => {
  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(1);
  const [list, setList] = useState<Country[]>([]);
  const [total, setTotal] = useState(0);

  const debouncedFilter = useDebounce<string>(filter, 200);

  useEffect(() => {
    setFilter(initialFilter);
  }, [initialFilter]);

  const { isFetching, data } = useGetCountries({
    page,
    name: debouncedFilter,
    pageSize: PAGE_SIZE_50
  });

  useEffect(() => {
    const newList = data?.data.items || [];

    if (page > 1) {
      newList.unshift(...list);
    }

    const listWithInitial = unionBy(newList, initialList, 'id');

    setList(listWithInitial);
    setTotal((data?.data.total || 0) + initialList?.length);
  }, [data]);

  const options = list.map(({ name, id }) => ({ label: name, value: id }));

  const handleBlur = () => {
    if (options.length === 1 && onChange) {
      onChange(options[0].value);
    }
  };

  return (
    <Form.Item
      name={name}
      label={label}
      rules={[{ required }]}
      className={className}
      {...(labelSpan && { labelCol: { span: labelSpan, offset: 0 } })}>
      <Select
        {...(isMultiple && { mode: 'multiple' })}
        allowClear
        style={{ width: '100%' }}
        placeholder={placeholder}
        options={options}
        size={CONTROL_SIZE}
        disabled={disabled}
        showSearch
        optionFilterProp="label"
        loading={isFetching}
        maxTagCount="responsive"
        onSearch={(value) => {
          setFilter(value);
          setPage(1);
        }}
        onClear={() => {
          setFilter('');
          setPage(1);
        }}
        onPopupScroll={(e) => {
          e.persist();
          const target = e.target as HTMLDivElement;

          if (
            target.scrollTop + target.offsetHeight === target.scrollHeight &&
            list.length < total
          ) {
            setPage(page + 1);
          }
        }}
        onBlur={handleBlur}
      />
    </Form.Item>
  );
};
