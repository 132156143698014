import { useQuery } from '@tanstack/react-query';
import { Country } from 'services/api/data-contracts';
import { countriesApi } from 'services/api';
import { useCountryStore } from 'store/countryStore';
import { QUERY_KEY } from './consts';

const fetchItem = async (id: number) => await countriesApi.getCountryById(id);

export const useGetCountry = (
  enabled: boolean,
  id?: number,
  onSuccess?: (item?: Country) => void
) => {
  const { setCurrent } = useCountryStore(({ setCurrent }) => ({
    setCurrent
  }));

  return useQuery({
    queryKey: [QUERY_KEY, id],
    queryFn: async () => {
      if (id) {
        return await fetchItem(id);
      }
    },
    onSuccess: (response) => {
      setCurrent(response?.data);

      onSuccess && onSuccess(response?.data);
    },
    enabled
  });
};
