import React, { useEffect, useMemo, useState } from 'react';
import { Button, Flex, Popover, Select, Tooltip, Typography } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { RiShareForwardLine } from 'react-icons/ri';
import { useGetCounterparties } from 'queries/counterparty';
import { getOrderForTemplate } from './getOrderForTemplate';
import { MailOutlined } from '@ant-design/icons';
import { useMessageStore } from 'store/messageStore';
import { useOrderStore } from 'store/orderStore';
import Handlebars from 'handlebars';
import { useGetMessageTemplate } from 'queries/messages/useGetMessageTemplate';
import { capitalize } from 'lodash';
import { LinkedEntityTypes } from 'services/api/data-contracts';
import { idTokenStore } from 'services/auth/IdTokenStore';
import { useFlightProgramStore } from 'store/flightProgramStore';
import { getProgramForTemplate } from './getProgramForTemplate';

interface IProps {
  isLoading?: boolean;
  isProgramView?: boolean;
}

export const ExportPopover = ({ isLoading, isProgramView }: IProps) => {
  const [isOpen, setOpen] = useState(false);
  const [selectedTemplateId, selectTemplateId] = useState<string | undefined>(undefined);
  const [templateError, setTemplateError] = useState<Record<string, unknown> | undefined>(
    undefined
  );

  const { userName } = idTokenStore.getUserData();

  const { setNewMessageMode, setCurrentMessage, setPendingEntityToLinkFromTemplate } =
    useMessageStore(
      ({ setNewMessageMode, setCurrentMessage, setPendingEntityToLinkFromTemplate }) => ({
        setNewMessageMode,
        setCurrentMessage,
        setPendingEntityToLinkFromTemplate
      })
    );
  const { current: order, services } = useOrderStore(({ current, services }) => ({
    current,
    services
  }));
  const { current: program, orders: programOrders } = useFlightProgramStore();
  const { templates } = useMessageStore(({ templates }) => ({
    templates
  }));

  useEffect(() => {
    selectTemplateId(undefined);
    setTemplateError(undefined);
  }, [isOpen]);

  const { data: selfCounterparty, isLoading: isCounterpartyLoading } = useGetCounterparties(
    { isSelf: true },
    isOpen
  );

  const {
    data: template,
    isFetching: isTemplateLoading,
    isError
  } = useGetMessageTemplate(selectedTemplateId);

  const selectedTemplate = useMemo(() => template?.data, [template]);

  const handleCreateMessage = () => {
    if (!selectedTemplate) {
      setOpen(false);
      return;
    }

    let data: Record<string, unknown> = {};
    let entityId: string | undefined = undefined;
    let entityType: LinkedEntityTypes | undefined = undefined;

    if (isProgramView && program) {
      data = {
        ...getProgramForTemplate(program, programOrders),
        selfCounterparty: selfCounterparty?.data.items[0],
        userName
      };
    } else if (order) {
      data = {
        ...getOrderForTemplate(order, services),
        selfCounterparty: selfCounterparty?.data.items[0],
        userName
      };
      entityId = order.number;
      entityType = LinkedEntityTypes.Order;
    }

    if (data) {
      console.log(data);

      try {
        const subjectTemplate = Handlebars.compile(selectedTemplate.content?.subject);
        const subject = subjectTemplate(data).toUpperCase();

        const bodyTemplate = Handlebars.compile(selectedTemplate.content?.text);
        const body = bodyTemplate(data).toUpperCase();

        setCurrentMessage({ subject, body });
        setNewMessageMode('fromOrder');
      } catch (e) {
        setTemplateError({
          templateName: selectedTemplate.name,
          ...(e as Record<string, unknown>)
        });
        return;
      }
    }

    // TODO: Implement when the linking to programs will be available
    if (entityId && entityType) {
      setPendingEntityToLinkFromTemplate({
        entityType,
        entityId
      });
    }

    setOpen(false);
  };

  const selectOptions = useMemo(
    () =>
      (templates || [])
        .filter((template) => template.name.startsWith('order'))
        .map((template) => {
          const templateName = template.name.replace('order_', '');

          return {
            value: template.id,
            label: capitalize(templateName.split('_').join(' '))
          };
        }),
    [templates]
  );

  const popoverContent = (
    <>
      <div>Order template</div>
      <Select
        options={selectOptions}
        size={CONTROL_SIZE}
        style={{ width: '450px' }}
        onChange={selectTemplateId}
        loading={!templates.length}
      />

      <Flex justify="end" gap={8} style={{ marginTop: '24px' }}>
        <Button size={CONTROL_SIZE} onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          size={CONTROL_SIZE}
          icon={<MailOutlined />}
          disabled={!selectedTemplateId}
          onClick={handleCreateMessage}
          loading={!templates.length || isTemplateLoading || isCounterpartyLoading}>
          Add to a message
        </Button>
      </Flex>
    </>
  );

  const errorContent = (
    <Flex vertical gap={24} style={{ width: 450 }}>
      <Typography.Text style={{ whiteSpace: 'pre-wrap' }}>
        {!!templateError
          ? `Message template is invalid. Please send the error details to the application administrator and try again later.`
          : 'Email service is currently unavailable. Please, try again later.'}
      </Typography.Text>

      {templateError && (
        <Typography.Text
          type="secondary"
          copyable={{ tooltips: ['Copy to clipboard', 'Copied'] }}
          style={{ whiteSpace: 'pre-wrap' }}>
          {JSON.stringify(templateError).split(',').join(`,\n`)}
        </Typography.Text>
      )}

      <Flex justify="end" gap={8}>
        <Button size={CONTROL_SIZE} onClick={() => setOpen(false)}>
          Cancel
        </Button>
      </Flex>
    </Flex>
  );

  const isTemplatesLoadingError = isError || templates.length === 0;

  return (
    <Popover
      trigger="click"
      title={
        isTemplatesLoadingError
          ? 'Template request failed'
          : templateError
            ? 'Template compilation failed'
            : 'Export details'
      }
      content={isTemplatesLoadingError || templateError ? errorContent : popoverContent}
      open={isOpen}
      placement={'bottomLeft'}
      onOpenChange={(newState) => setOpen(newState)}>
      <Tooltip title="Export details to a message...">
        <Button
          size={CONTROL_SIZE}
          icon={
            <RiShareForwardLine
              style={{ height: '15px', width: '15px', position: 'relative', top: '2px' }}
            />
          }
          onClick={() => setOpen(true)}
          style={{ bottom: '-1px' }}
          loading={isLoading}
          type="dashed"
        />
      </Tooltip>
    </Popover>
  );
};
