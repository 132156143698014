import { useQuery } from '@tanstack/react-query';
import { MESSAGES_QUERY_KEY } from './consts';
import { settingsStore } from 'services/settings/SettingsStore';
import { MailboxSpecialUse } from 'services/emailEngineApi/data-contracts';
import { useMessageStore } from 'store/messageStore';

import { messagesApi } from 'services/emailEngineApi';

const getAccountMailboxes = async (account: string) => {
  try {
    const response = await messagesApi.getV1AccountAccountMailboxes(account);

    return response.data;
  } catch (error) {
    console.error('Error during getting message account path:', error);
    throw error;
  }
};

export const useGetAllMailPath = () => {
  const account = settingsStore.getMailboxSettings()?.mailbox_name || '';

  const { setAllMailPath } = useMessageStore(({ setAllMailPath }) => ({
    setAllMailPath
  }));
  return useQuery({
    queryKey: [MESSAGES_QUERY_KEY, account],
    queryFn: () => getAccountMailboxes(account),
    onSuccess: async (response) => {
      const allMailBox = response?.mailboxes?.find(
        (mailbox) => mailbox.specialUse === (`\\${MailboxSpecialUse.ValueAll}` as string)
      );
      /* language gmail account settings specific All mail path */
      allMailBox?.path && setAllMailPath(allMailBox?.path);
    },
    enabled: !!account
  });
};
