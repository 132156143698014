/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Error,
  UnitOfMeasure,
  UnitOfMeasureInput,
  UnitOfMeasureListResponse,
  ValidationError
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class UnitOfMeasures<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Get a list of UOM records
   *
   * @tags unit of measures
   * @name GetAllUnitOfMeasures
   * @summary Get a list of UOM records
   * @request GET:/unit-of-measures
   * @secure
   */
  getAllUnitOfMeasures = (params: RequestParams = {}) =>
    this.http.request<UnitOfMeasureListResponse, Error>({
      path: `/unit-of-measures`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Create a new UOM record
   *
   * @tags unit of measures
   * @name AddUnitOfMeasure
   * @summary Create a new UOM
   * @request POST:/unit-of-measures
   * @secure
   */
  addUnitOfMeasure = (data: UnitOfMeasureInput, params: RequestParams = {}) =>
    this.http.request<UnitOfMeasure, ValidationError | Error>({
      path: `/unit-of-measures`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Get a specific UOM by id
   *
   * @tags unit of measures
   * @name GetUnitOfMeasureById
   * @summary Get a specific UOM
   * @request GET:/unit-of-measures/{unitOfMeasureId}
   * @secure
   */
  getUnitOfMeasureById = (unitOfMeasureId: number, params: RequestParams = {}) =>
    this.http.request<UnitOfMeasure, Error>({
      path: `/unit-of-measures/${unitOfMeasureId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Update a specific UOM by id
   *
   * @tags unit of measures
   * @name UpdateUnitOfMeasure
   * @summary Update a specific UOM
   * @request PUT:/unit-of-measures/{unitOfMeasureId}
   * @secure
   */
  updateUnitOfMeasure = (
    unitOfMeasureId: number,
    data: UnitOfMeasureInput,
    params: RequestParams = {}
  ) =>
    this.http.request<UnitOfMeasure, ValidationError | Error>({
      path: `/unit-of-measures/${unitOfMeasureId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Delete a specific UOM record by id
   *
   * @tags unit of measures
   * @name DeleteUnitOfMeasure
   * @summary Delete a specific UOM
   * @request DELETE:/unit-of-measures/{unitOfMeasureId}
   * @secure
   */
  deleteUnitOfMeasure = (unitOfMeasureId: number, params: RequestParams = {}) =>
    this.http.request<void, ValidationError | Error>({
      path: `/unit-of-measures/${unitOfMeasureId}`,
      method: 'DELETE',
      secure: true,
      ...params
    });
}
