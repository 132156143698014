import { CustomerStatus } from 'services/api/data-contracts';
import { Tag, Tooltip } from 'antd';
import React from 'react';
import { blue, gold, green, lime, purple, red, yellow } from '@ant-design/colors';

const customerStatusTagColors: Record<CustomerStatus, string> = {
  [CustomerStatus.PRE]: blue[0],
  [CustomerStatus.NN]: '#d9d9d9',
  [CustomerStatus.NRQS]: red[5],
  [CustomerStatus.CUST]: purple[5],
  [CustomerStatus.RQSR]: yellow[1],
  [CustomerStatus.AINF]: gold[4],
  [CustomerStatus.CHG]: blue[3],
  [CustomerStatus.CNLR]: '#595959',
  [CustomerStatus.CNFD]: lime[4],
  [CustomerStatus.DONE]: green[6],
  [CustomerStatus.SCND]: '#d9d9d9',
  [CustomerStatus.MIST]: '#d9d9d9',
  [CustomerStatus.INVD]: purple[0],
  [CustomerStatus.NFI]: red[0],
  [CustomerStatus.IAS]: '#fafafa'
};

const customerStatusBorderColors: Record<CustomerStatus, string> = {
  [CustomerStatus.PRE]: blue[3],
  [CustomerStatus.NN]: '#595959',
  [CustomerStatus.NRQS]: red[7],
  [CustomerStatus.CUST]: purple[7],
  [CustomerStatus.RQSR]: yellow[4],
  [CustomerStatus.AINF]: gold[6],
  [CustomerStatus.CHG]: blue[6],
  [CustomerStatus.CNLR]: '#141414',
  [CustomerStatus.CNFD]: lime[7],
  [CustomerStatus.DONE]: green[7],
  [CustomerStatus.SCND]: '#595959',
  [CustomerStatus.MIST]: '#595959',
  [CustomerStatus.INVD]: purple[4],
  [CustomerStatus.NFI]: red[2],
  [CustomerStatus.IAS]: '#8c8c8c'
};

const customerStatusFontColors: Record<CustomerStatus, string> = {
  [CustomerStatus.PRE]: 'inherit',
  [CustomerStatus.NN]: 'inherit',
  [CustomerStatus.NRQS]: '#FFF',
  [CustomerStatus.CUST]: '#FFF',
  [CustomerStatus.RQSR]: 'inherit',
  [CustomerStatus.AINF]: 'inherit',
  [CustomerStatus.CHG]: 'inherit',
  [CustomerStatus.CNLR]: '#FFF',
  [CustomerStatus.CNFD]: 'inherit',
  [CustomerStatus.DONE]: '#FFF',
  [CustomerStatus.SCND]: 'inherit',
  [CustomerStatus.MIST]: 'inherit',
  [CustomerStatus.INVD]: purple[4],
  [CustomerStatus.NFI]: red[5],
  [CustomerStatus.IAS]: '#8c8c8c'
};

export const customerStatusTitles: Record<CustomerStatus, string> = {
  [CustomerStatus.PRE]: 'Preliminary request received',
  [CustomerStatus.NN]: 'Not needed',
  [CustomerStatus.NRQS]: 'Not requested by Customer',
  [CustomerStatus.CUST]: 'Customer arranges on its own',
  [CustomerStatus.RQSR]: 'Request (info) received',
  [CustomerStatus.AINF]: 'Additional information requested from Customer',
  [CustomerStatus.CHG]: 'Change received',
  [CustomerStatus.CNLR]: 'Cancellation received',
  [CustomerStatus.CNFD]: 'Confirmed to Customer',
  [CustomerStatus.DONE]: 'Service is provided',
  [CustomerStatus.SCND]: 'Service is cancelled',
  [CustomerStatus.MIST]: 'Mistake',
  [CustomerStatus.INVD]: 'Invoiced',
  [CustomerStatus.NFI]: 'Not for invoicing',
  [CustomerStatus.IAS]: 'Invoiced with other service'
};

interface IProps {
  status: CustomerStatus;
}

export const CustomerStatusTag = ({ status }: IProps) => (
  <Tooltip title={customerStatusTitles[status]}>
    <Tag
      color={customerStatusTagColors[status]}
      style={{
        color: customerStatusFontColors[status],
        borderColor: customerStatusBorderColors[status]
      }}>
      {status}
    </Tag>
  </Tooltip>
);
