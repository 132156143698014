import { settingsStore } from 'services/settings/SettingsStore';

export const useMailboxConfig = () => {
  const config = settingsStore.getMailboxSettings();

  const account = config?.mailbox_name || '';
  const customFlags = config?.mailbox_flags || {
    'Important/Urgent': '#cf1322',
    'New/Revision/In_Process': '#ff7a45',
    Fuel: '#FADB14',
    'Confirmation/Slots': '#73d13d',
    Documents: '#722ED1',
    'Cancellation/Reject': '#1f1f1f',
    OpsInCopy: '#8C8C8C',
    General_Inquiry: '#13c2c2',
    'Pricing/Quotes': '#1677ff',
    'Accounts/Invoice': '#237804'
  };

  return { account, customFlags };
};
