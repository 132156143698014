import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { rebatesApi } from 'services/api';
import { RebateHydrated } from 'services/api/data-contracts';
import { ENTITY_NAME, QUERY_KEY } from './consts';
import { transformForRequest } from './helpers';
import { MESSAGE_DURATION } from 'consts/common';

const createItem = async (data: RebateHydrated) =>
  await rebatesApi.addRebate(transformForRequest(data));

export const useCreateRebate = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createItem,
    onSuccess: async (response) => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      message.success(
        `${ENTITY_NAME} "${response.data.name}" has been successfully created`,
        MESSAGE_DURATION
      );
      onSuccess && onSuccess();
    }
  });
};
