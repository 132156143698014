import styled from 'styled-components';
import { Collapse } from 'antd';

export const StyledCollapse = styled(Collapse)`
  .ant-collapse-header-text {
    margin-left: -8px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .ant-collapse-expand-icon {
    margin-right: -8px;
  }

  .ant-collapse-content-box {
    padding: 0 0 12px !important;

    .ant-descriptions-header {
      margin: 8px 0 0;

      .ant-descriptions-title {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
      }
    }

    th,
    td {
      padding: 8px 4px !important;
    }
  }
`;
