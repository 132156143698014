import { faker } from '@faker-js/faker';
import { MessagesFilter } from 'store/messageStore';
import { getUTCDate } from 'helpers';
import { FILTER_LINKS } from 'pages/widgets/MessagesWidget/consts';

const pastDate = getUTCDate(faker.date.past());
const recentDate = getUTCDate(faker.date.recent());

export const getMockMessageFilters = (): MessagesFilter => {
  return {
    recipientOrSender: faker.internet.email(),
    emailSubject: faker.lorem.sentence(),
    emailBody: faker.lorem.sentence(),
    messageSentDate: [pastDate, recentDate],
    withAttachment: 'withAttachment',
    links: FILTER_LINKS.WITH_LINKS
  };
};
