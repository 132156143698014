import React from 'react';
import { StyledCollapse } from 'components/common/StyledCollapse';
import { CollapseProps } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { useFlightStore } from 'store/flightStore';
import { FlightDetailsView } from './FlightDetailsView';
import { Flight } from 'services/api/data-contracts';
import { FlightDetailsForm } from './FlightDetailsForm';
import { useFlightsDashboardStore } from 'store/flightsDashboardStore';

export const FlightDetailsBlock = () => {
  const { current } = useFlightStore(({ current }) => ({ current: current as Flight }));
  const { isFlightEditMode } = useFlightsDashboardStore(({ isFlightEditMode }) => ({
    isFlightEditMode
  }));

  if (!current) {
    return null;
  }

  const collapseContent: CollapseProps['items'] = [
    {
      key: 'general',
      label: 'Flight details',
      children: isFlightEditMode ? (
        <FlightDetailsForm flight={current} />
      ) : (
        <FlightDetailsView flight={current} />
      )
    }
  ];

  return (
    <StyledCollapse
      items={collapseContent}
      expandIconPosition="end"
      size={CONTROL_SIZE}
      defaultActiveKey="general"
      ghost
    />
  );
};
