import { Button, Popconfirm } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { DeleteOutlined } from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';
import { FiEdit3 } from 'react-icons/fi';

interface IProps {
  deleteMessage: string;
  onDelete: () => void;
  onEdit?: () => void;
}

const ActionsContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CenteredButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ActionsCell = ({ deleteMessage, onDelete, onEdit }: IProps) => (
  <ActionsContainer>
    {onEdit && (
      <CenteredButton type="link" size={CONTROL_SIZE} icon={<FiEdit3 />} onClick={onEdit} />
    )}
    <Popconfirm title={deleteMessage} onConfirm={onDelete}>
      <Button type="link" size={CONTROL_SIZE} danger icon={<DeleteOutlined />} />
    </Popconfirm>
  </ActionsContainer>
);
