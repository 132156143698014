import { Col, Form, Input, Row, Switch } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DictionaryName } from '../../models';
import { FormContext } from '../DictionaryDetails';
import { CountriesInput } from 'components/CountriesInput/CountriesInput';
import { useCounterpartyStore } from 'store/counterpartyStore';

export const CounterpartyDictionaryForm = () => {
  const [isOperator, setIsOperator] = useState(false);

  const { dictionaryName } = useParams<{ dictionaryName: DictionaryName }>();
  const form = useContext(FormContext);
  const { current } = useCounterpartyStore(({ current }) => ({ current }));

  useEffect(() => {
    setIsOperator(form?.getFieldValue('isOperator'));
  }, [form]);

  if (!form) {
    return null;
  }

  return (
    <Form
      name={dictionaryName}
      layout="horizontal"
      form={form}
      colon={false}
      initialValues={{ isActive: true }}
      className="styled-form"
      onValuesChange={(updated) => {
        if ('isOperator' in updated) {
          setIsOperator(updated.isOperator);
        }
      }}>
      <Form.Item label="Name" name="name" labelCol={{ span: 6 }} rules={[{ required: true }]}>
        <Input size={CONTROL_SIZE} placeholder="Input short name" autoFocus />
      </Form.Item>

      <Form.Item
        label="Full name"
        name="fullName"
        labelCol={{ span: 6 }}
        rules={[{ required: true }]}>
        <Input size={CONTROL_SIZE} placeholder="Input full name" />
      </Form.Item>

      <CountriesInput
        name="countryId"
        label="REG CNTR"
        placeholder="Select registration country"
        labelSpan={6}
        initialFilter={current?.country?.name}
        onChange={(value) => form.setFieldsValue({ countryId: value })}
      />

      <Form.Item label="Legal form" name="legalForm" labelCol={{ span: 6 }}>
        <Input size={CONTROL_SIZE} placeholder="Input legal form" />
      </Form.Item>

      <Form.Item label="REG NUM" name="registrationNumber" labelCol={{ span: 6 }}>
        <Input size={CONTROL_SIZE} placeholder="Input registration number" />
      </Form.Item>

      <Row>
        <Col span={12}>
          <Form.Item
            label="Operator"
            name="isOperator"
            valuePropName="checked"
            labelCol={{ span: 12 }}>
            <Switch size={CONTROL_SIZE} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Active" name="isActive" valuePropName="checked" labelCol={{ span: 12 }}>
            <Switch size={CONTROL_SIZE} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name="icao"
        label="ICAO"
        rules={[{ min: 3 }, { max: 3 }]}
        normalize={(value) => value.toUpperCase().replace(/ /g, '')}
        labelCol={{ span: 6 }}>
        <Input size={CONTROL_SIZE} placeholder="Input ICAO code" disabled={!isOperator} />
      </Form.Item>

      <Form.Item
        name="iata"
        label="IATA"
        rules={[{ min: 2 }, { max: 2 }]}
        normalize={(value) => value.toUpperCase().replace(/ /g, '')}
        labelCol={{ span: 6 }}>
        <Input size={CONTROL_SIZE} placeholder="Input IATA code" disabled={!isOperator} />
      </Form.Item>

      <Form.Item name="legalAddress" label="Legal address" labelCol={{ span: 6 }}>
        <Input.TextArea size={CONTROL_SIZE} placeholder="Input legal address" />
      </Form.Item>

      <Form.Item name="actualAddress" label="Actual address" labelCol={{ span: 6 }}>
        <Input.TextArea size={CONTROL_SIZE} placeholder="Input actual address" />
      </Form.Item>

      <Form.Item name="notes" label="Notes" labelCol={{ span: 6 }}>
        <Input.TextArea
          size={CONTROL_SIZE}
          placeholder="Input any additional important information"
          autoSize={{ minRows: 10, maxRows: 20 }}
        />
      </Form.Item>
    </Form>
  );
};
