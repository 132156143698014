import { useQuery } from '@tanstack/react-query';
import { contractsApi } from 'services/api';
import { QUERY_KEY } from './consts';
import { useContractStore } from 'store/contractStore';

const fetchList = async (counterpartyId: number) =>
  await contractsApi.getContracts({ counterpartyId });

export const useGetContractsByCounterparty = (counterpartyId?: number) => {
  const { setList } = useContractStore(({ setList }) => ({
    setList
  }));

  return useQuery({
    queryKey: [QUERY_KEY, counterpartyId],
    queryFn: async () => {
      if (counterpartyId) {
        return await fetchList(counterpartyId);
      }
    },
    onSuccess: (response) => {
      setList(response?.data.items || []);
    },
    enabled: !!counterpartyId
  });
};
