import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { ordersApi } from 'services/api';
import { OrderServiceInput, OrderType } from 'services/api/data-contracts';
import { ENTITY_NAME, QUERY_KEY } from './consts';
import { WIDGET_QUERY_KEY } from '../orders/consts';
import { MESSAGE_DURATION } from 'consts/common';
import { SETTLEMENT_WIDGET_QUERY_KEY } from '../settlement/consts';

const createItem = async (data: OrderServiceInput, orderType?: OrderType, orderId?: number) => {
  if (orderId && orderType) {
    return await ordersApi.addOrderService(orderId, orderType, data);
  }
};

export const useCreateOrderService = (
  orderType?: OrderType,
  orderId?: number,
  onSuccess?: () => void
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: OrderServiceInput) => await createItem(data, orderType, orderId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      await queryClient.invalidateQueries({ queryKey: [WIDGET_QUERY_KEY] });
      await queryClient.invalidateQueries({ queryKey: [SETTLEMENT_WIDGET_QUERY_KEY] });

      message.success(`${ENTITY_NAME} has been successfully created`, MESSAGE_DURATION);

      onSuccess && onSuccess();
    }
  });
};
