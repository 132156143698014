import React, { useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import { CONTROL_SIZE, PAGE_SIZE_50 } from 'consts/common';
import { useLocationStore } from 'store/locationStore';
import { useGetLocations } from 'queries/locations';
import { useDebounce } from 'helpers';

interface IProps {
  name: string | (string | number)[];
  label: string;
  labelSpan?: number;
  disabled?: boolean;
  required?: boolean;
  className?: string;
  initialFilter?: string;
  isNumberValue?: boolean;
  onChange?: (value: string | number) => void;
}

export const LocationInput = ({
  labelSpan,
  disabled,
  label,
  name,
  required,
  className,
  initialFilter = '',
  isNumberValue,
  onChange
}: IProps) => {
  const [filter, setFilter] = useState('');
  const debouncedFilter = useDebounce<string>(filter, 200);

  const { list, page, setPage, total } = useLocationStore(({ list, page, setPage, total }) => ({
    list,
    page,
    setPage,
    total
  }));

  useEffect(() => {
    setFilter(initialFilter);
  }, [initialFilter]);

  const { isFetching } = useGetLocations({
    page,
    name: debouncedFilter,
    pageSize: PAGE_SIZE_50,
    sortBy: 'name'
  });

  const options = list.map(({ name, id, type, number }) => ({
    label: type === 'airport' ? name.split(', ')[0] : name,
    value: isNumberValue ? number : `${type}#${id}`
  }));

  const handleBlur = () => {
    if (options.length === 1 && onChange) {
      onChange(options[0].value);
    }
  };

  return (
    <Form.Item
      name={name}
      label={label}
      rules={[{ required }]}
      className={className}
      {...(labelSpan && { labelCol: { span: labelSpan } })}>
      <Select
        allowClear
        style={{ width: '100%' }}
        placeholder="Select location"
        options={options}
        size={CONTROL_SIZE}
        disabled={disabled}
        showSearch
        optionFilterProp="label"
        loading={isFetching}
        onSearch={(value) => {
          setFilter(value);
          setPage(1);
        }}
        onClear={() => {
          setFilter('');
          setPage(1);
        }}
        onPopupScroll={(e) => {
          e.persist();
          const target = e.target as HTMLDivElement;

          if (
            target.scrollTop + target.offsetHeight === target.scrollHeight &&
            list.length < total
          ) {
            setPage(page + 1);
          }
        }}
        onBlur={handleBlur}
      />
    </Form.Item>
  );
};
