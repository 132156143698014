import { create } from 'zustand';
import {
  GroundHandlingOrder,
  OrderService,
  PermitOrder,
  OrderType,
  FuelOrder,
  AdditionalServicesOrder,
  Flight,
  FlightWidgetItem
} from 'services/api/data-contracts';
import { devtools } from 'zustand/middleware';
import { sortByDate } from './helpers';

type AdditionalProps = {
  type?: OrderType;
  shouldAddFlight?: boolean;
  sourceArrivalFlight?: Flight | FlightWidgetItem;
  sourceDepartureFlight?: Flight | FlightWidgetItem;
};

export type TypedCommonOrder =
  | (GroundHandlingOrder & AdditionalProps)
  | (FuelOrder & AdditionalProps)
  | (AdditionalServicesOrder & AdditionalProps);

export type TypedOrder = (PermitOrder & AdditionalProps) | TypedCommonOrder;

export type EnrichedOrderService = OrderService & {
  orderId: number;
  orderType: OrderType;
};

type OrderStore = {
  current?: Partial<TypedOrder>;
  setCurrent: (current?: Partial<TypedOrder>) => void;
  list: TypedOrder[];
  setList: (list: TypedOrder[]) => void;

  service?: Partial<EnrichedOrderService>;
  setService: (service?: Partial<EnrichedOrderService>) => void;

  services: EnrichedOrderService[];
  setServices: (services: EnrichedOrderService[]) => void;
  selectedServices: number[];
  setSelectedServices: (selectedServices?: number[]) => void;
};

export const useOrderStore = create<OrderStore>()(
  devtools((set) => ({
    current: undefined,
    setCurrent: (current) => {
      set({ current });
    },
    list: [],
    setList: (list) => {
      const sortedList = sortByDate(list, 'createdAt');
      set({ list: sortedList });
    },
    service: undefined,
    setService: (service) => {
      set({ service });
    },
    services: [],
    setServices: (services) => {
      set({ services });
    },
    selectedServices: [],
    setSelectedServices: (selectedServices) => {
      set({ selectedServices });
    }
  }))
);
