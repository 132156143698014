/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Contract,
  ContractInput,
  ContractListResponse,
  Error,
  SupplierService,
  SupplierServiceInput,
  SupplierServiceListResponse,
  ValidationError
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Contracts<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Get a list of contracts
   *
   * @tags contracts
   * @name GetContracts
   * @summary Get a list of contracts
   * @request GET:/contracts
   * @secure
   */
  getContracts = (
    query?: {
      /**
       * Parameter to search by one or more values in used endpoint entities
       * @pattern ^[^%]*$
       */
      search?: string;
      counterpartyId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<ContractListResponse, Error>({
      path: `/contracts`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Create a new contract
   *
   * @tags contracts
   * @name AddContract
   * @summary Create a new contract
   * @request POST:/contracts
   * @secure
   */
  addContract = (data: ContractInput, params: RequestParams = {}) =>
    this.http.request<Contract, ValidationError | Error>({
      path: `/contracts`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Get a specific contract
   *
   * @tags contracts
   * @name GetContractById
   * @summary Get a specific contract
   * @request GET:/contracts/{contractId}
   * @secure
   */
  getContractById = (contractId: number, params: RequestParams = {}) =>
    this.http.request<Contract, Error>({
      path: `/contracts/${contractId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Update a specific contract
   *
   * @tags contracts
   * @name UpdateContract
   * @summary Update a specific contract
   * @request PUT:/contracts/{contractId}
   * @secure
   */
  updateContract = (contractId: number, data: ContractInput, params: RequestParams = {}) =>
    this.http.request<Contract, ValidationError | Error>({
      path: `/contracts/${contractId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Delete a specific contract
   *
   * @tags contracts
   * @name DeleteContract
   * @summary Delete a specific contract
   * @request DELETE:/contracts/{contractId}
   * @secure
   */
  deleteContract = (contractId: number, params: RequestParams = {}) =>
    this.http.request<void, ValidationError | Error>({
      path: `/contracts/${contractId}`,
      method: 'DELETE',
      secure: true,
      ...params
    });
  /**
   * @description Get a list of supplier services
   *
   * @tags contracts
   * @name GetContractServices
   * @summary Get a list of supplier services
   * @request GET:/contracts/{contractId}/services
   * @secure
   */
  getContractServices = (contractId: number, params: RequestParams = {}) =>
    this.http.request<SupplierServiceListResponse, Error>({
      path: `/contracts/${contractId}/services`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Create a new supplier service
   *
   * @tags contracts
   * @name AddContractService
   * @summary Create a new supplier service
   * @request POST:/contracts/{contractId}/services
   * @secure
   */
  addContractService = (
    contractId: number,
    data: SupplierServiceInput,
    params: RequestParams = {}
  ) =>
    this.http.request<SupplierService, ValidationError | Error>({
      path: `/contracts/${contractId}/services`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Update a specific supplier service
   *
   * @tags contracts
   * @name UpdateContractService
   * @summary Update a specific supplier service
   * @request PUT:/contracts/{contractId}/services/{supplierServiceId}
   * @secure
   */
  updateContractService = (
    contractId: number,
    supplierServiceId: number,
    data: SupplierServiceInput,
    params: RequestParams = {}
  ) =>
    this.http.request<SupplierService, ValidationError | Error>({
      path: `/contracts/${contractId}/services/${supplierServiceId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Delete a specific supplier service
   *
   * @tags contracts
   * @name DeleteContractService
   * @summary Delete a specific supplier service
   * @request DELETE:/contracts/{contractId}/services/{supplierServiceId}
   * @secure
   */
  deleteContractService = (
    contractId: number,
    supplierServiceId: number,
    params: RequestParams = {}
  ) =>
    this.http.request<void, ValidationError | Error>({
      path: `/contracts/${contractId}/services/${supplierServiceId}`,
      method: 'DELETE',
      secure: true,
      ...params
    });
}
