import { Descriptions, DescriptionsProps } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import React, { useContext } from 'react';
import { DataContext } from '../DictionaryDetails';
import { Service } from 'services/api/data-contracts';
import { capitalize } from 'lodash';

export const ServiceDictionaryView = () => {
  const data = useContext(DataContext) as Service;

  if (!data) {
    return null;
  }

  const descriptions: DescriptionsProps['items'] = [
    {
      label: 'Active',
      children: data.isActive ? 'Yes' : 'No'
    },
    {
      label: 'Add by default',
      children: data.isDefault ? 'Yes' : 'No'
    },
    {
      label: 'Name',
      children: data.name
    },
    {
      label: 'Default service name',
      children: data.serviceName
        ? [data.serviceName.name, data.serviceName.unitOfMeasure?.shortName].join(' | ')
        : '-'
    },
    {
      label: 'Order types',
      children: data.orderTypes.join(', ')
    },
    {
      label: 'Location type',
      children: capitalize(data.locationType)
    }
  ];

  return <Descriptions size={CONTROL_SIZE} items={descriptions} column={2} bordered />;
};
