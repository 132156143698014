export const NOTIFICATION = {
  NO_MESSAGES_SELECTED: 'Please select some messages to link',
  SOME_LINKS_EXIST: 'All selected messages have to be either linked or unlinked to invoice'
};

export const LINK_STATUS = {
  SELECT_MESSAGE: 'Select message to link',
  LOADING: 'Loading existing links...',
  LINK_INVOICE: 'Link invoice to selected',
  UNLINK_INVOICE: 'Unlink invoice from selected',
  INVALID_LINKS_COMBINATION: 'Invalid combination for linking'
};
