import { create } from 'zustand';
import { Order, Program, ProgramById } from 'services/api/data-contracts';
import { devtools } from 'zustand/middleware';

type FlightProgramStore = {
  current?: ProgramById;
  setCurrent: (current?: ProgramById) => void;
  list: (Program & {
    mainOrder?: Order;
  })[];
  setList: (
    list: (Program & {
      mainOrder?: Order;
    })[]
  ) => void;
  openProgramId?: number;
  setOpenProgramId: (openProgramId?: number) => void;
  orders: Order[];
  setOrders: (orders: Order[]) => void;
  isOrdersCloneMode: boolean;
  setOrdersCloneMode: (isOrdersCloneMode: boolean) => void;
  selectedPrograms: Program[];
  setSelectedPrograms: (selectedPrograms?: Program[]) => void;
  selectedServices: number[];
  setSelectedServices: (selectedServices?: number[]) => void;
};

export const useFlightProgramStore = create<FlightProgramStore>()(
  devtools((set) => ({
    current: undefined,
    setCurrent: (current) => {
      set({ current });
    },
    list: [],
    setList: (list) => {
      set({ list });
    },
    openProgramId: undefined,
    setOpenProgramId: (openProgramId) => {
      set({ openProgramId });
    },
    orders: [],
    setOrders: (orders) => {
      set({ orders });
    },
    isOrdersCloneMode: false,
    setOrdersCloneMode: (isOrdersCloneMode) => {
      set({ isOrdersCloneMode });
    },
    selectedPrograms: [],
    setSelectedPrograms: (selectedPrograms) => {
      set({ selectedPrograms });
    },
    selectedServices: [],
    setSelectedServices: (selectedServices) => {
      set({ selectedServices });
    }
  }))
);
