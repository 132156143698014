import { useQuery } from '@tanstack/react-query';
import { messagesApi } from 'services/emailEngineApi';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const getTemplate = async (id?: string) => await messagesApi.getV1TemplatesTemplateTemplate(id!);

export const useGetMessageTemplate = (id?: string) =>
  useQuery({
    queryKey: ['message_template', id],
    queryFn: async () => getTemplate(id),
    enabled: !!id
  });
