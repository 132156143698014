import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { PaymentPurposeHydrated } from 'services/api/data-contracts';
import { PaymentPurposeWithContract } from '../pages/widgets/components/PaymentDrawer/PaymentDetails/PaymentPurposeBlock/columns';

export enum PaymentPurposeEditModeBase {
  PAYMENT = 'payment',
  INVOICE = 'invoice'
}

type PaymentPurposeStore = {
  current?: PaymentPurposeHydrated | PaymentPurposeWithContract;
  setCurrent: (current?: PaymentPurposeHydrated | PaymentPurposeWithContract) => void;
  list: PaymentPurposeHydrated[];
  setList: (data: { items: PaymentPurposeHydrated[]; total: number }) => void;
  page: number;
  setPage: (page: number) => void;
  total: number;
  isCreationMode: boolean;
  setCreationMode: (isCreationMode: boolean) => void;
  showRevertActionPopUp: false | 'link' | 'unlink';
  setShowRevertActionPopUp: (showRevertActionPopUp: false | 'link' | 'unlink') => void;
  editModeBase?: PaymentPurposeEditModeBase;
  setEditModeBase: (editModeBase?: PaymentPurposeEditModeBase) => void;
};

export const usePaymentPurposeStore = create<PaymentPurposeStore>()(
  devtools((set) => ({
    current: undefined,
    setCurrent: (current) => {
      set({ current });
    },
    list: [],
    setList: ({ items: list, total }) => {
      set({ list, total });
    },
    page: 1,
    setPage: (page) => {
      set({ page });
    },
    total: 0,
    isCreationMode: false,
    setCreationMode: (isCreationMode) => {
      set({ isCreationMode });
    },
    showRevertActionPopUp: false,
    setShowRevertActionPopUp: (showRevertActionPopUp: false | 'link' | 'unlink') => {
      set({ showRevertActionPopUp });
    },
    editModeBase: undefined,
    setEditModeBase: (editModeBase) => {
      set({ editModeBase });
    }
  }))
);
