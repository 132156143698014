import { Dayjs } from 'dayjs';
import { DATE_FORMAT_SHORT } from 'consts/common';

export const getOrderTerm = (startDate: Dayjs, endDate: Dayjs) => {
  const isSameMonth = startDate.get('month') === endDate.get('month');
  const formattedStartDate = isSameMonth
    ? startDate.format('DD')
    : startDate.format(DATE_FORMAT_SHORT);

  return formattedStartDate + ' – ' + endDate.format(DATE_FORMAT_SHORT);
};
