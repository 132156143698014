import React, { useEffect } from 'react';
import { ConfigProvider } from 'antd';
import { validateMessages } from 'consts/common';
import { RouterProvider } from 'react-router-dom';
import { router } from './routing/router';
import { useOfflineHandler } from 'helpers';
import { customTheme } from 'customTheme';

function App() {
  useOfflineHandler();

  useEffect(() => {
    document.title = ['Shannon Air', process.env.REACT_APP_ENVIRONMENT]
      .filter((i) => !!i)
      .join(' | ');
  }, []);

  return (
    <ConfigProvider form={{ validateMessages }} theme={customTheme}>
      <RouterProvider router={router} />
    </ConfigProvider>
  );
}

export default App;
