/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AdditionalServicesOrder,
  AdditionalServicesOrderInput,
  AdditionalServicesOrderListResponse,
  Error,
  FuelOrder,
  FuelOrderInput,
  FuelOrderListResponse,
  GroundHandlingOrder,
  GroundHandlingOrderInput,
  GroundHandlingOrderListResponse,
  Order,
  OrderService,
  OrderServiceInput,
  OrderServicesByOrderListResponse,
  OrderType,
  PermitOrder,
  PermitOrderInput,
  PermitOrderListResponse,
  ValidationError
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Orders<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Get a list of orders
   *
   * @tags orders
   * @name GetOrders
   * @summary Get a list of orders
   * @request GET:/orders/widget
   * @secure
   */
  getOrders = (
    query?: {
      /**
       * Comma-separated list of fields to sort by, prefix with '-' for descending order. Example - https://api.epam.com/examples?sortBy=field1,-field2
       * @pattern ^(startDatetime|endDatetime,-startDatetime|,-endDatetime)?$
       */
      sortBy?: string;
      /** Filter by a list of flight ids separated by comma. Example - flightIds=1,2,3 */
      flightIds?: string;
      /** Filter by a list of messageIds separated by comma. Example - messageIds=AAAAAQAAGwY,AAAAAQAAAC4 etc. */
      messageIds?: string;
      /** Filter by a list of aircraft tail numbers separated by comma. Example - aircraftTailNumbers=UP-HA003,UP-EM019 */
      aircraftTailNumbers?: string;
      /**
       * Filter by a list of aircraft ids separated by comma.
       * @example "1,2,3"
       */
      aircraftIds?: string;
      /** Filter by a list of location names separated by comma. Example - locationNames=New, Cape town */
      locationNames?: string;
      /** Filter by a list of customer names separated by comma. Example - customerNames=New, Cape town */
      customerNames?: string;
      /**
       * Filter by a list of location ids separated by comma.
       * @example "city_12,airport_17,country_70"
       */
      locationIds?: string;
      /**
       * Filter by a list of customer ids separated by comma.
       * @example "1,2,3"
       */
      customerIds?: string;
      /** Filter by a list of provider names separated by comma. Example - providerNames=New, Cape town */
      providerNames?: string;
      /**
       * Filter by a list of provider ids separated by comma.
       * @example "1,2,3"
       */
      providerIds?: string;
      /** Filter by a list of supplier names separated by comma. Example - supplierNames=New, Cape town */
      supplierNames?: string;
      /**
       * Filter by a list of supplier ids separated by comma.
       * @example "1,2,3"
       */
      supplierIds?: string;
      /** Filter by a list of orderNumbers separated by comma. Example - orderNumbers=51,12,22 etc. */
      orderNumbers?: string;
      /** Filter by a list of order types separated by comma. Example - types=GH, ADD */
      types?: string;
      /** Filter by a list of order statuses separated by comma. Example - orderStatuses=ESTD, DONE */
      orderStatuses?: string;
      /** Filter by a list of payer names separated by comma. Example - payerNames=Payer1, Payer2 */
      payerNames?: string;
      /**
       * Filter by a list of payer ids separated by comma.
       * @example "1,2,3"
       */
      payerIds?: string;
      /** Filter by startDatetime range, format as 'start,end'. Example - startDatetime=2023-10-26, 2023-10-27 */
      startDatetime?: string;
      /** Filter by programId */
      programId?: number;
      /** Filter by endDatetime range, format as 'start,end'. Example - endDatetime=2023-10-26, 2023-10-27 */
      endDatetime?: string;
      /**
       * Filter by isInProgram property
       * @example true
       */
      isInProgram?: boolean;
      /**
       * The limit on number of items to return
       * @format int32
       * @min 1
       * @max 10000
       * @default 10
       */
      limit?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<
      {
        items?: Order[];
        /** @format int64 */
        total?: number;
      },
      any
    >({
      path: `/orders/widget`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Get a list of ground handling orders
   *
   * @tags orders
   * @name GetGhOrders
   * @summary Get a list of ground handling orders
   * @request GET:/orders/ground-handling
   * @secure
   */
  getGhOrders = (params: RequestParams = {}) =>
    this.http.request<GroundHandlingOrderListResponse, Error>({
      path: `/orders/ground-handling`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Create a new ground handling order
   *
   * @tags orders
   * @name AddGhOrder
   * @summary Create a new ground handling order
   * @request POST:/orders/ground-handling
   * @secure
   */
  addGhOrder = (data: GroundHandlingOrderInput, params: RequestParams = {}) =>
    this.http.request<GroundHandlingOrder, ValidationError | Error>({
      path: `/orders/ground-handling`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Get a specific ground handling order
   *
   * @tags orders
   * @name GetGhOrderById
   * @summary Get a specific ground handling order
   * @request GET:/orders/ground-handling/{orderId}
   * @secure
   */
  getGhOrderById = (orderId: number, params: RequestParams = {}) =>
    this.http.request<GroundHandlingOrder, Error>({
      path: `/orders/ground-handling/${orderId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Update a specific ground handling order
   *
   * @tags orders
   * @name UpdateGhOrder
   * @summary Update a specific ground handling order
   * @request PUT:/orders/ground-handling/{orderId}
   * @secure
   */
  updateGhOrder = (orderId: number, data: GroundHandlingOrderInput, params: RequestParams = {}) =>
    this.http.request<GroundHandlingOrder, ValidationError | Error>({
      path: `/orders/ground-handling/${orderId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Delete a specific ground handling order
   *
   * @tags orders
   * @name DeleteGhOrder
   * @summary Delete a specific ground handling order
   * @request DELETE:/orders/ground-handling/{orderId}
   * @secure
   */
  deleteGhOrder = (orderId: number, params: RequestParams = {}) =>
    this.http.request<void, ValidationError | Error>({
      path: `/orders/ground-handling/${orderId}`,
      method: 'DELETE',
      secure: true,
      ...params
    });
  /**
   * @description Get a list of fuel orders
   *
   * @tags orders
   * @name GetFuelOrders
   * @summary Get a list of fuel orders
   * @request GET:/orders/fuel
   * @secure
   */
  getFuelOrders = (params: RequestParams = {}) =>
    this.http.request<FuelOrderListResponse, Error>({
      path: `/orders/fuel`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Create a new fuel order
   *
   * @tags orders
   * @name AddFuelOrder
   * @summary Create a new fuel order
   * @request POST:/orders/fuel
   * @secure
   */
  addFuelOrder = (data: FuelOrderInput, params: RequestParams = {}) =>
    this.http.request<FuelOrder, ValidationError | Error>({
      path: `/orders/fuel`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Get a specific fuel order
   *
   * @tags orders
   * @name GetFuelOrderById
   * @summary Get a specific fuel order
   * @request GET:/orders/fuel/{orderId}
   * @secure
   */
  getFuelOrderById = (orderId: number, params: RequestParams = {}) =>
    this.http.request<FuelOrder, Error>({
      path: `/orders/fuel/${orderId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Update a specific fuel order
   *
   * @tags orders
   * @name UpdateFuelOrder
   * @summary Update a specific fuel order
   * @request PUT:/orders/fuel/{orderId}
   * @secure
   */
  updateFuelOrder = (orderId: number, data: FuelOrderInput, params: RequestParams = {}) =>
    this.http.request<FuelOrder, ValidationError | Error>({
      path: `/orders/fuel/${orderId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Delete a specific fuel order
   *
   * @tags orders
   * @name DeleteFuelOrder
   * @summary Delete a specific fuel order
   * @request DELETE:/orders/fuel/{orderId}
   * @secure
   */
  deleteFuelOrder = (orderId: number, params: RequestParams = {}) =>
    this.http.request<void, ValidationError | Error>({
      path: `/orders/fuel/${orderId}`,
      method: 'DELETE',
      secure: true,
      ...params
    });
  /**
   * @description Get a list of additional services orders
   *
   * @tags orders
   * @name GetAddOrders
   * @summary Get a list of additional services orders
   * @request GET:/orders/additional-services
   * @secure
   */
  getAddOrders = (params: RequestParams = {}) =>
    this.http.request<AdditionalServicesOrderListResponse, Error>({
      path: `/orders/additional-services`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Create a new additional services order
   *
   * @tags orders
   * @name AddAddOrder
   * @summary Create a new additional services order
   * @request POST:/orders/additional-services
   * @secure
   */
  addAddOrder = (data: AdditionalServicesOrderInput, params: RequestParams = {}) =>
    this.http.request<AdditionalServicesOrder, ValidationError | Error>({
      path: `/orders/additional-services`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Get a specific additional services order
   *
   * @tags orders
   * @name GetAddOrderById
   * @summary Get a specific additional services order
   * @request GET:/orders/additional-services/{orderId}
   * @secure
   */
  getAddOrderById = (orderId: number, params: RequestParams = {}) =>
    this.http.request<AdditionalServicesOrder, Error>({
      path: `/orders/additional-services/${orderId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Update a specific additional services order
   *
   * @tags orders
   * @name UpdateAddOrder
   * @summary Update a specific additional services order
   * @request PUT:/orders/additional-services/{orderId}
   * @secure
   */
  updateAddOrder = (
    orderId: number,
    data: AdditionalServicesOrderInput,
    params: RequestParams = {}
  ) =>
    this.http.request<AdditionalServicesOrder, ValidationError | Error>({
      path: `/orders/additional-services/${orderId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Delete a specific additional services order
   *
   * @tags orders
   * @name DeleteAddOrder
   * @summary Delete a specific additional services order
   * @request DELETE:/orders/additional-services/{orderId}
   * @secure
   */
  deleteAddOrder = (orderId: number, params: RequestParams = {}) =>
    this.http.request<void, ValidationError | Error>({
      path: `/orders/additional-services/${orderId}`,
      method: 'DELETE',
      secure: true,
      ...params
    });
  /**
   * @description Get a list of permit orders
   *
   * @tags orders
   * @name GetPermitOrders
   * @summary Get a list of permit orders
   * @request GET:/orders/permit
   * @secure
   */
  getPermitOrders = (params: RequestParams = {}) =>
    this.http.request<PermitOrderListResponse, Error>({
      path: `/orders/permit`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Create a new permit order
   *
   * @tags orders
   * @name AddPermitOrder
   * @summary Create a new permit order
   * @request POST:/orders/permit
   * @secure
   */
  addPermitOrder = (data: PermitOrderInput, params: RequestParams = {}) =>
    this.http.request<PermitOrder, ValidationError | Error>({
      path: `/orders/permit`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Get a specific permit order
   *
   * @tags orders
   * @name GetPermitOrderById
   * @summary Get a specific permit order
   * @request GET:/orders/permit/{permitOrderId}
   * @secure
   */
  getPermitOrderById = (permitOrderId: number, params: RequestParams = {}) =>
    this.http.request<PermitOrder, Error>({
      path: `/orders/permit/${permitOrderId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Delete a specific permit order
   *
   * @tags orders
   * @name DeletePermitOrder
   * @summary Delete a specific permit order
   * @request DELETE:/orders/permit/{permitOrderId}
   * @secure
   */
  deletePermitOrder = (permitOrderId: number, params: RequestParams = {}) =>
    this.http.request<void, ValidationError | Error>({
      path: `/orders/permit/${permitOrderId}`,
      method: 'DELETE',
      secure: true,
      ...params
    });
  /**
   * @description Update a specific permit order
   *
   * @tags orders
   * @name UpdatePermitOrder
   * @summary Update a specific permit order
   * @request PUT:/orders/permit/{permitOrderId}
   * @secure
   */
  updatePermitOrder = (permitOrderId: number, data: PermitOrderInput, params: RequestParams = {}) =>
    this.http.request<PermitOrder, ValidationError | Error>({
      path: `/orders/permit/${permitOrderId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Get a list of order services
   *
   * @tags orders
   * @name GetOrderServicesByOrder
   * @summary Get a list of order services
   * @request GET:/orders/{orderType}/{orderId}/services
   * @secure
   */
  getOrderServicesByOrder = (orderId: number, orderType: OrderType, params: RequestParams = {}) =>
    this.http.request<OrderServicesByOrderListResponse, Error>({
      path: `/orders/${orderType}/${orderId}/services`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Create a new order service
   *
   * @tags orders
   * @name AddOrderService
   * @summary Create a new order service
   * @request POST:/orders/{orderType}/{orderId}/services
   * @secure
   */
  addOrderService = (
    orderId: number,
    orderType: OrderType,
    data: OrderServiceInput,
    params: RequestParams = {}
  ) =>
    this.http.request<OrderService, ValidationError | Error>({
      path: `/orders/${orderType}/${orderId}/services`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Update a specific order service
   *
   * @tags orders
   * @name UpdateOrderService
   * @summary Update a specific order service
   * @request PUT:/orders/services/{orderServiceId}
   * @secure
   */
  updateOrderService = (
    orderServiceId: number,
    data: OrderServiceInput,
    params: RequestParams = {}
  ) =>
    this.http.request<OrderService, ValidationError | Error>({
      path: `/orders/services/${orderServiceId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
}
