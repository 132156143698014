import React from 'react';
import { Filter } from 'pages/Dictionaries/Dictionaries';
import { Dayjs } from 'dayjs';
import { OwnPriceFilter } from './OwnPriceFilter';
import { CurrencyRateFilter } from './CurrencyRateFilter';
import { DictionaryName } from 'pages/Dictionaries/models';
import { RebatesFilter } from './RebatesFilter';

interface IProps {
  filter: Filter;
  onFilter: (name: string, value: string | Dayjs | boolean | undefined | null) => void;
  dictionary?: DictionaryName;
}

export const DictionaryFilter = ({ dictionary, filter, onFilter }: IProps) => {
  const filterProps = {
    filter,
    onFilter
  };

  const renderFilterComponent = () => {
    switch (dictionary) {
      case 'currency-rate':
        return <CurrencyRateFilter {...filterProps} />;
      case 'own-price':
        return <OwnPriceFilter {...filterProps} />;
      case 'rebate':
        return <RebatesFilter {...filterProps} />;
      default:
        return null;
    }
  };

  return renderFilterComponent();
};
