import React, { useMemo } from 'react';
import { CONTROL_SIZE } from 'consts/common';
import { OrderStatusTag } from '../OrderStatusTag';
import styled from 'styled-components';
import { Skeleton } from 'antd';
import { BsCalendarWeek } from 'react-icons/bs';
import { useFlightProgramStore } from 'store/flightProgramStore';
import { orderTypeFullName } from 'consts/order';

const TitleWrapper = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const ProgramDrawerTitle = () => {
  const { current } = useFlightProgramStore();

  return useMemo(() => {
    if (!current) return <Skeleton.Input size={CONTROL_SIZE} style={{ width: 250 }} />;

    return (
      <TitleWrapper>
        <OrderStatusTag status={current.status} />
        <BsCalendarWeek style={{ color: '#00000073', marginLeft: '10px' }} />
        <span>
          {orderTypeFullName[current.mainOrder.type]} Program {current.id}
        </span>
      </TitleWrapper>
    );
  }, [current]);
};
