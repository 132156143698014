import { CollapseProps } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import React from 'react';
import { useOrderStore } from 'store/orderStore';
import { GroundHandlingOrder, OrderType } from 'services/api/data-contracts';
import { OrderDetailsForm } from './OrderDetailsForm';
import { OrderDetailsView } from './OrderDetailsView';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { StyledCollapse } from 'components/common/StyledCollapse';
import { orderTypeFullName } from 'consts/order';

interface IProps {
  orderType: OrderType;
}

export const OrderDetailsBlock = ({ orderType }: IProps) => {
  const { current } = useOrderStore(({ current }) => ({ current: current as GroundHandlingOrder }));
  const { isOrderEditMode } = useOrdersDashboardStore(({ isOrderEditMode }) => ({
    isOrderEditMode
  }));

  const collapseContent: CollapseProps['items'] = [
    {
      key: 'order',
      label: `${orderTypeFullName[orderType]} details`,
      children: isOrderEditMode ? (
        <OrderDetailsForm orderType={orderType} order={current} />
      ) : (
        <OrderDetailsView order={current} />
      )
    }
  ];

  return (
    <StyledCollapse
      items={collapseContent}
      expandIconPosition="end"
      size={CONTROL_SIZE}
      defaultActiveKey="order"
      ghost
    />
  );
};
