import { useQuery } from '@tanstack/react-query';
import { paymentsApi } from 'services/api';
import { PAYMENT_QUERY_KEY } from './consts';
import { TransactionType } from '../../services/api/data-contracts';

const fetchList = async (filter: { forCreditNotes?: boolean }) =>
  await paymentsApi.getPaymentTemplates(filter);

export const useGetPaymentTemplates = (transactionType?: TransactionType, enabled?: boolean) =>
  useQuery({
    queryKey: [PAYMENT_QUERY_KEY],
    queryFn: async () =>
      fetchList({ forCreditNotes: transactionType === TransactionType.CreditNote }),
    keepPreviousData: true,
    enabled
  });
