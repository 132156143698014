import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FileViewer from 'react-file-viewer';
import { Model4 as Attachment } from 'services/emailEngineApi/data-contracts';
import { useFileUrl } from './helpers/useFileUrl';
import { Flex, Typography, Spin, Button, message, Empty } from 'antd';
import {
  DownloadOutlined as Download,
  CloseOutlined as Close,
  ZoomOutOutlined as ZoomOut,
  ZoomInOutlined as ZoomIn,
  RotateLeftOutlined as RotateLeft
} from '@ant-design/icons';
import { useDownloadFile } from '../downloadHooks/useDownloadFile';
import { useMessageStore } from 'store/messageStore';
import { UnsupportedFileMessage } from './UnsupportedFileMessage';
import { isExtensionSupportedForPreviewer } from './helpers/extensions';
import { getTransformOrigin } from './helpers/getTransformOrigin';
import { ROTATE_ANGLE, ROTATE_INCREMENT } from './constants';
import { MESSAGE_DURATION } from 'consts/common';
import { PdfViewer } from './PdfViewer';

const { Text } = Typography;

interface StyledFlex {
  $rotateAngle?: number;
}

const StyledFlex = styled(Flex)<StyledFlex>`
  height: 100%;
  border-radius: 8px;
  border: 1px solid #d9d9d9;

  .photo-viewer-container {
    width: unset !important;
  }

  .pg-viewer {
    transform-origin: ${(props) => getTransformOrigin(props.$rotateAngle)};

    .unsupported-message {
      height: 100%;
    }
  }
`;

const StyledActionsRow = styled(Flex)`
  background-color: #fafafa;
  padding: 4px 12px;
  z-index: 10;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
`;

const StyledButton = styled(Button)`
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.45);
  border: none;
`;

const StyledText = styled(Text)`
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-weight: 500;
`;

const StyledZoomPreview = styled(Text)`
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  background-color: #f0f0f0;
  margin: 0 6px;
  padding: 0 12px;
`;

export const AttachmentViewer = ({ attachment }: { attachment: Attachment }) => {
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [showWarning, setShowWarning] = useState(false);
  const [showDownloadError, setDownloadError] = useState(false);

  const { url, extension, hasError } = useFileUrl(attachment);
  const { downloadFileOrThrowError } = useDownloadFile(attachment);
  const [messageApi, contextHolder] = message.useMessage();

  const isSupportedExtension = isExtensionSupportedForPreviewer(extension);
  let isInfoMessageShown = false;

  useEffect(() => {
    const viewer = document.querySelector('.pg-viewer') as HTMLElement;
    if (viewer) {
      viewer.style.transform = `scale(${scale}) rotate(${rotate}deg)`;
    }
  }, [scale, rotate]);

  useEffect(() => {
    if (showWarning) {
      messageApi.open({
        key: 'warning-min-scale-file',
        type: 'warning',
        content: 'Minimum scale of 20% is reached.'
      });
      setShowWarning(false);
    }
  }, [showWarning, messageApi]);

  useEffect(() => {
    if (showDownloadError) {
      messageApi.open({
        key: 'error-loading-file',
        type: 'warning',
        content: 'Error in loading attachment',
        duration: MESSAGE_DURATION
      });
      setDownloadError(false);
    }
  }, [showDownloadError, messageApi]);

  useEffect(() => {
    if (extension === 'docx' && !isInfoMessageShown) {
      messageApi.open({
        key: 'warning-msword-file',
        type: 'warning',
        content: 'Please download MSWord file to see the full styling.',
        duration: MESSAGE_DURATION
      });
      isInfoMessageShown = true;
    }
  }, [extension]);

  const { setCurrentViewedAttachment } = useMessageStore(({ setCurrentViewedAttachment }) => ({
    setCurrentViewedAttachment
  }));

  const handleCloseFileViewer = () => {
    setCurrentViewedAttachment(null);
  };
  const handleDownloadFile = async () => {
    try {
      await downloadFileOrThrowError();
    } catch (error) {
      setDownloadError(true);
    }
  };

  const handleZoomOut = () => {
    setScale((prevScale) => {
      if (Math.floor(prevScale * 100) <= 20) {
        setShowWarning(true);
        return prevScale;
      } else {
        return prevScale - 0.2;
      }
    });
  };

  const handleZoomIn = () => {
    setScale((scale) => scale + 0.2);
  };

  const handleRotate = () => {
    setRotate((prev) => {
      const incremented = prev + ROTATE_INCREMENT;
      return incremented >= ROTATE_ANGLE.FULL ? ROTATE_ANGLE.DEFAULT : incremented;
    });
  };

  const actionsHeader = (
    <StyledActionsRow gap={8} align="center" justify="space-between">
      <StyledText>{attachment.filename}</StyledText>
      {isSupportedExtension && (
        <Flex>
          <Flex style={{ paddingRight: '16px', borderRight: '1px solid rgba(0, 0, 0, 0.25)' }}>
            <StyledButton onClick={handleZoomOut} size="middle" icon={<ZoomOut />} />
            <StyledZoomPreview>{Math.floor(scale * 100)}%</StyledZoomPreview>
            <StyledButton onClick={handleZoomIn} size="middle" icon={<ZoomIn />} />
          </Flex>

          <StyledButton
            onClick={handleRotate}
            size="middle"
            icon={<RotateLeft />}
            style={{ marginLeft: '16px' }}
          />
        </Flex>
      )}
      <Flex>
        <StyledButton onClick={handleDownloadFile} size="middle" icon={<Download />} />
        <StyledButton onClick={handleCloseFileViewer} size="middle" icon={<Close />} />
      </Flex>
    </StyledActionsRow>
  );

  if (!isSupportedExtension) {
    return (
      <StyledFlex vertical gap="8px">
        {actionsHeader}
        <UnsupportedFileMessage handleDownload={handleDownloadFile} />
      </StyledFlex>
    );
  }

  const unsupportedFileMessage = () => (
    <UnsupportedFileMessage handleDownload={handleDownloadFile} />
  );

  return (
    <>
      {contextHolder}
      <StyledFlex vertical gap="8px" $rotateAngle={rotate}>
        {actionsHeader}
        {hasError ? (
          <Empty description="Failed to load attachment" image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : url ? (
          extension === 'pdf' ? (
            <PdfViewer url={url} scale={scale} rotate={rotate} messageApi={messageApi} />
          ) : (
            <FileViewer
              fileType={extension}
              filePath={url}
              unsupportedComponent={unsupportedFileMessage}
              errorComponent={unsupportedFileMessage}
            />
          )
        ) : (
          <Flex style={{ height: '100%' }} justify="center" align="center">
            <Spin />
          </Flex>
        )}
      </StyledFlex>
    </>
  );
};
