import { useQuery } from '@tanstack/react-query';
import { Flight } from 'services/api/data-contracts';
import { flightsApi } from 'services/api';
import { useFlightStore } from 'store/flightStore';
import { QUERY_KEY } from './consts';

const fetchItem = async (id: number) => await flightsApi.getFlightById(id);

export const useGetFlight = (enabled: boolean, id: number, onSuccess?: (item?: Flight) => void) => {
  const { setCurrent } = useFlightStore(({ setCurrent }) => ({
    setCurrent
  }));

  return useQuery({
    queryKey: [QUERY_KEY, id],
    queryFn: async () => await fetchItem(id),
    onSuccess: (response) => {
      setCurrent(response.data);
      onSuccess && onSuccess(response.data);
    },
    enabled
  });
};
