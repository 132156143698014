import { ColumnsType } from 'antd/es/table';
import { Country } from 'services/api/data-contracts';

export const getCountryColumns = (): ColumnsType<Country> => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true
  },
  {
    title: 'Full name',
    dataIndex: 'fullName',
    key: 'fullName',
    ellipsis: true
  },
  {
    title: 'Code 2',
    dataIndex: 'code2',
    key: 'code2',
    width: 64
  },
  {
    title: 'Code 3',
    dataIndex: 'code3',
    key: 'code3',
    width: 64
  }
];
