import { useEffect, useRef } from 'react';

export const useCheckboxGroupAutoFocus = () => {
  const checkboxGroupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkboxGroupElement = checkboxGroupRef.current;
    if (checkboxGroupElement) {
      const firstCheckbox = checkboxGroupElement.querySelector(
        'label input[type="checkbox"]'
      ) as HTMLInputElement;

      // second condition is for jsdom environment
      if (firstCheckbox && typeof firstCheckbox.focus === 'function') {
        setTimeout(() => {
          firstCheckbox.focus();
        }, 0);
      }
    }
  }, []);

  return checkboxGroupRef;
};
