import { ColumnsType } from 'antd/es/table';
import { DATE_FORMAT } from 'consts/common';
import React from 'react';
import { ActionsCell } from 'components/ActionsCell/ActionsCell';
import { dateSorter, stringSorter } from 'pages/Dictionaries/utils/tableSorters';
import { Tag } from 'antd';
import { Contract, ContractType } from 'services/api/data-contracts';
import { getUTCDate } from 'helpers';

export const getColumns = (onDelete: (id: number) => void): ColumnsType<Contract> => [
  {
    title: 'Number',
    dataIndex: 'number',
    key: 'number',
    render: (value) => value || '-',
    sorter: (a, b) => stringSorter(a.number || '-', b.number || '-')
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (value) => (
      <Tag color={value === ContractType.WithSupplier ? 'gold' : 'blue'}>
        {value === ContractType.WithSupplier ? 'Supplier' : 'Customer'}
      </Tag>
    ),
    sorter: (a, b) => stringSorter(a.type, b.type)
  },
  {
    title: 'Start date',
    dataIndex: 'startDate',
    key: 'startDate',
    render: (value) => getUTCDate(value).format(DATE_FORMAT),
    sorter: (a, b) => dateSorter(a.startDate, b.startDate),
    defaultSortOrder: 'descend'
  },
  {
    title: 'End date',
    dataIndex: 'endDate',
    key: 'endDate',
    render: (value) => (value ? getUTCDate(value).format(DATE_FORMAT) : '-'),
    sorter: (a, b) => dateSorter(a.endDate || '', b.endDate || '')
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    width: 70,
    render: (_, record) => (
      <ActionsCell
        onDelete={() => record.id && onDelete(+record.id)}
        deleteMessage="Are you sure to remove the contract?"
      />
    )
  }
];
