/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Error, OrderServicesListResponse, OrderServicesWidgetResponse } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class OrderServices<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Get a list of filtered order services grouped by orders
   *
   * @tags order services
   * @name GetOrderServices
   * @summary Get a list of order services
   * @request GET:/order-services
   * @secure
   */
  getOrderServices = (
    query?: {
      /** Filter by a list of location names separated by comma. Example - locationNames=New, Cape town */
      locationNames?: string;
      /** Filter by a list of customer names separated by comma. Example - customerNames=New, Cape town */
      customerNames?: string;
      /** Filter by a list of provider names separated by comma. Example - providerNames=New, Cape town */
      providerNames?: string;
      /** Filter by a list of supplier names separated by comma. Example - supplierNames=New, Cape town */
      supplierNames?: string;
      /** Filter by a list of order types separated by comma. Example - types=GH, ADD */
      types?: string;
      /** Filter by a list of payer names separated by comma. Example - payerNames=Payer1, Payer2 */
      payerNames?: string;
      /** Filter by startDatetime range, format as 'start,end'. Example - startDatetime=2023-10-26, 2023-10-27 */
      startDatetime?: string;
      /** Filter by endDatetime range, format as 'start,end'. Example - endDatetime=2023-10-26, 2023-10-27 */
      endDatetime?: string;
      /**
       * Filter by id of Invoice
       * @format int32
       * @min 1
       * @max 1000000
       */
      invoiceId?: number;
      /**
       * The current page number
       * @format int32
       * @min 1
       * @max 10000
       * @default 1
       */
      page?: number;
      /**
       * The number of items to return per page
       * @format int32
       * @min 1
       * @max 10000
       * @default 10
       */
      pageSize?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<OrderServicesListResponse, Error>({
      path: `/order-services`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Get a list of filtered order services for dispatcher widget
   *
   * @tags order services
   * @name GetOrderServicesWidget
   * @summary Get a list of order services
   * @request GET:/order-services/widget
   * @secure
   */
  getOrderServicesWidget = (
    query?: {
      /**
       * Comma-separated list of fields to sort by, prefix with '-' for descending order. Example - https://api.epam.com/examples?sortBy=field1,-field2
       * @pattern ^(startDatetime|endDatetime,-startDatetime|,-endDatetime)?$
       */
      sortBy?: string;
      /** Filter by a list of flight ids separated by comma. Example - flightIds=1,2,3 */
      flightIds?: string;
      /** Filter by a list of messageIds separated by comma. Example - messageIds=AAAAAQAAGwY,AAAAAQAAAC4 etc. */
      messageIds?: string;
      /** Filter by a list of aircraft tail numbers separated by comma. Example - aircraftTailNumbers=UP-HA003,UP-EM019 */
      aircraftTailNumbers?: string;
      /** Filter by a list of location names separated by comma. Example - locationNames=New, Cape town */
      locationNames?: string;
      /** Filter by a list of customer names separated by comma. Example - customerNames=New, Cape town */
      customerNames?: string;
      /** Filter by a list of provider names separated by comma. Example - providerNames=New, Cape town */
      providerNames?: string;
      /** Filter by a list of supplier names separated by comma. Example - supplierNames=New, Cape town */
      supplierNames?: string;
      /** Filter by a list of orderNumbers separated by comma. Example - orderNumbers=51,12,22 etc. */
      orderNumbers?: string;
      /** Filter by a list of order types separated by comma. Example - types=GH, ADD */
      types?: string;
      /** Filter by a list of order statuses separated by comma. Example - orderStatuses=ESTD, DONE */
      orderStatuses?: string;
      /** Filter by a list of payer names separated by comma. Example - payerNames=Payer1, Payer2 */
      payerNames?: string;
      /** Filter by startDatetime range, format as 'start,end'. Example - startDatetime=2023-10-26, 2023-10-27 */
      startDatetime?: string;
      /** Filter by endDatetime range, format as 'start,end'. Example - endDatetime=2023-10-26, 2023-10-27 */
      endDatetime?: string;
      /**
       * The limit on number of items to return
       * @format int32
       * @min 1
       * @max 10000
       * @default 10
       */
      limit?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<OrderServicesWidgetResponse, any>({
      path: `/order-services/widget`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
}
