import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { ActionsCell } from 'components/ActionsCell/ActionsCell';
import { Rate } from 'antd';
import { numericSorter, stringSorter } from '../../../../utils/tableSorters';
import { Airport, City, Country, SupplierService } from 'services/api/data-contracts';

const getLocationName = (location: Airport | Country | City) => {
  if ('code' in location) {
    return location.code;
  }

  return location.name;
};

export const getColumns = (onDelete: (id: number) => void): ColumnsType<SupplierService> => [
  {
    title: 'Service name',
    dataIndex: 'name',
    key: 'name',
    render: (value, record) => record.service?.name,
    sorter: (a, b) => stringSorter(a.service?.name || '-', b.service?.name || '-')
  },
  {
    title: 'Locations',
    dataIndex: 'locations',
    key: 'locations',
    render: (value) => (value || []).map(getLocationName).join(', '),
    sorter: (a, b) =>
      stringSorter(
        a.locations?.map(getLocationName).join(', ') || '',
        b.locations?.map(getLocationName).join(', ') || ''
      )
  },
  {
    title: 'Priority',
    dataIndex: 'priority',
    key: 'priority',
    render: (value) => <Rate disabled value={value} style={{ fontSize: 18 }} />,
    sorter: (a, b) => numericSorter(a.priority, b.priority)
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    width: 70,
    render: (_, record) => (
      <ActionsCell
        onDelete={() => record.id && onDelete(+record.id)}
        deleteMessage="Are you sure to remove the service?"
      />
    )
  }
];
