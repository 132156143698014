import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { VatRate } from 'services/api/data-contracts';

type VatRateStore = {
  current?: VatRate;
  setCurrent: (current?: VatRate) => void;
  list: VatRate[];
  setList: (current: VatRate[]) => void;
};

export const useVatRateStore = create<VatRateStore>()(
  devtools((set) => ({
    current: undefined,
    setCurrent: (current) => {
      set({ current });
    },
    list: [],
    setList: (list) => {
      set({ list });
    }
  }))
);
