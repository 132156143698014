import { Descriptions, DescriptionsProps } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import React from 'react';
import { TypedOrder } from 'store/orderStore';

interface IProps {
  order: TypedOrder;
}

export const GeneralInfoView = ({ order }: IProps) => {
  const descriptions: DescriptionsProps['items'] = [
    {
      label: 'Customer',
      children: order.customer?.name
    },
    {
      label: 'Operator',
      children: order.operator?.name
    },
    {
      label: 'Payer',
      children: order.payer?.name,
      span: 2
    },
    {
      label: 'Aircraft',
      children: order.aircraft ? (
        <span>
          <span>{order.aircraft?.tailNumber}</span>
          <span style={{ color: '#bfbfbf' }}>
            {' | '}
            {[
              order.aircraft?.type,
              order.aircraft?.maxTakeoffWeight + ' kg',
              order.aircraft?.country?.name
            ]
              .filter((i) => !!i)
              .join(' | ')}
          </span>
        </span>
      ) : (
        '-'
      )
    }
  ];

  return <Descriptions items={descriptions} size={CONTROL_SIZE} column={2} bordered />;
};
