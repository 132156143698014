import React, { useMemo } from 'react';
import { ConfigProvider, Empty, Form, Select } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { useUnitOfMeasureStore } from 'store/unitOfMeasureStore';
import { useGetUnitsOfMeasure } from 'queries/unitOfMeasure';
import { UOM_TAGS } from 'consts/order';
import { sortBy } from 'lodash';

interface IProps {
  name: string | (string | number)[];
  label: string;
  disabled?: boolean;
  required?: boolean;
  isFuel?: boolean;
  currentUOM?: string;
  onChange?: (id: number, unitOfMeasureShortName?: string) => void;
}

export const UnitsOfMeasureInput = ({
  disabled,
  label,
  name,
  required,
  isFuel,
  currentUOM,
  onChange
}: IProps) => {
  const { list } = useUnitOfMeasureStore(({ list }) => ({ list }));

  const { isLoading } = useGetUnitsOfMeasure();

  const options = useMemo(
    () =>
      isFuel
        ? sortBy(list, (o) => [o.name])
            .filter((uom) => Object.keys(UOM_TAGS).includes(uom.shortName as UOM_TAGS))
            .map(({ name, id, isActive, shortName }) => ({
              label: name,
              value: id,
              disabled: !isActive || shortName === currentUOM
            }))
        : list.map(({ shortName, id, isActive }) => ({
            label: shortName,
            value: id,
            disabled: !isActive
          })),
    [isFuel, list]
  );

  return (
    <ConfigProvider
      renderEmpty={() => <Empty description="No data" image={Empty.PRESENTED_IMAGE_SIMPLE} />}>
      <Form.Item
        name={name}
        label={label}
        rules={[{ required, message: 'Is required' }]}
        style={{ flexBasis: 220 }}>
        <Select
          allowClear
          style={{ width: '100%' }}
          placeholder="Select unit of measurement"
          options={options}
          size={CONTROL_SIZE}
          disabled={disabled}
          showSearch
          optionFilterProp="label"
          loading={isLoading}
          popupMatchSelectWidth={false}
          onChange={(id) => {
            const unitOfMeasureShortName = list.find((uom) => uom.id === id)?.shortName;
            onChange && onChange(id, unitOfMeasureShortName);
          }}
        />
      </Form.Item>
    </ConfigProvider>
  );
};
