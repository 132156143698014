import { useMemo } from 'react';
import {
  AdditionalServicesOrder,
  FuelOrder,
  GroundHandlingOrder,
  OrderStatus,
  OrderType,
  PermitOrder
} from 'services/api/data-contracts';
import {
  GHOrderInputWithDayJs,
  transformForRender as transformGh
} from '../../DispatcherOrdersWidget/components/utils/ghOrderUtils';
import { transformForRender as transformAdd } from '../../DispatcherOrdersWidget/components/utils/addOrderUtils';
import { transformForRender as transformFuel } from '../../DispatcherOrdersWidget/components/utils/fuelOrderUtils';
import { transformForRender as transformPmt } from '../../DispatcherOrdersWidget/components/utils/permitOrderUtils';
import { useOrderStore } from 'store/orderStore';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';

export const useOrderInitialData = () => {
  const { current } = useOrderStore(({ current }) => ({ current }));
  const { openOrder } = useOrdersDashboardStore(({ openOrder }) => ({ openOrder }));

  return useMemo(() => {
    switch (openOrder?.type) {
      case OrderType.GH:
        return current ? transformGh(current as GroundHandlingOrder) : {};
      case OrderType.ADD:
        return current ? transformAdd(current as AdditionalServicesOrder) : {};
      case OrderType.FUEL:
        return current ? transformFuel(current as FuelOrder) : {};
      case OrderType.PMT:
        return current ? transformPmt(current as PermitOrder) : {};
      default:
        return (current as GHOrderInputWithDayJs) || { status: OrderStatus.ESTD };
    }
  }, [current, openOrder?.type]);
};
