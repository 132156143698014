import { DictionaryName } from 'pages/Dictionaries/models';

export const getPlaceholder = (dictionary: DictionaryName) => {
  switch (dictionary) {
    case 'airport':
      return 'Search by airport name';
    case 'city':
      return 'Search by city name';
    case 'country':
      return 'Search by country name';
    case 'counterparty':
      return 'Search by short name, full name or registration number';
    case 'contract':
      return 'Search by contract, supplier or customer name';
    case 'own-price':
      return 'Search by own price name';
    case 'rebate':
      return 'Search by name, customer or service';
    case 'aircraft':
      return 'Search by tail number';
    default:
      return 'Search';
  }
};
