import React, { useEffect, useState } from 'react';
import { Button, Flex, Form, Popover, Select } from 'antd';
import { TypedOrder, useOrderStore } from 'store/orderStore';
import { CONTROL_SIZE } from 'consts/common';
import { OrderStatus } from 'services/api/data-contracts';
import { useForm } from 'antd/es/form/Form';
import { isEmpty, omitBy } from 'lodash';
import { EditOutlined } from '@ant-design/icons';
import { getSelectOptions, getUTCDate } from 'helpers';
import { useOrderMutations } from '../../useOrderMutations';

export const ChangeStatusPopover = () => {
  const [isOpen, setOpen] = useState(false);
  const { current } = useOrderStore(({ current }) => ({
    current
  }));

  const [form] = useForm<{ status: OrderStatus }>();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({ status: current?.status });
  }, [isOpen]);

  const handleCancel = () => {
    setOpen(false);
  };

  const { onSubmit, isMutationLoading } = useOrderMutations(handleCancel);

  const handleSubmit = async () => {
    const data = omitBy(form.getFieldsValue(), (value) => value === undefined);

    if (isEmpty(data)) {
      return handleCancel();
    }

    await onSubmit(
      {
        ...current,
        startEndDate: [getUTCDate(current?.startDatetime), getUTCDate(current?.endDatetime)],
        ...data
      } as unknown as TypedOrder,
      current
    );
  };

  const popoverContent = (
    <Form
      form={form}
      style={{ width: 215 }}
      layout="vertical"
      colon={false}
      className="styled-form">
      <Flex gap={8}>
        <Form.Item name="status">
          <Select
            loading={isMutationLoading}
            size={CONTROL_SIZE}
            options={getSelectOptions(Object.values(OrderStatus))}
            showSearch
            optionFilterProp="label"
            popupMatchSelectWidth={false}
            placeholder="Select status"
            style={{
              width: 98
            }}
          />
        </Form.Item>
      </Flex>

      <Flex justify="end" gap={8}>
        <Button size={CONTROL_SIZE} onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          type="primary"
          size={CONTROL_SIZE}
          onClick={handleSubmit}
          loading={isMutationLoading}>
          Change
        </Button>
      </Flex>
    </Form>
  );

  return (
    <Popover
      trigger="click"
      title={`Change status for ${current?.type} ${current?.number}`}
      content={popoverContent}
      open={isOpen}
      placement={'bottomLeft'}
      onOpenChange={(newState) => setOpen(newState)}>
      <Button
        size={CONTROL_SIZE}
        icon={<EditOutlined />}
        onClick={() => setOpen(true)}
        loading={isMutationLoading}>
        Change the status
      </Button>
    </Popover>
  );
};
