import { PaymentPurposeInput } from 'services/api/data-contracts';

export const transformForRequest = (
  paymentId: number,
  invoiceId: number,
  formData: Pick<PaymentPurposeInput, 'paidAmount' | 'paidAmountInInvoiceCurrency'>
): PaymentPurposeInput => ({
  paymentId,
  invoiceId,
  paidAmount: formData.paidAmount,
  ...(formData.paidAmountInInvoiceCurrency && {
    paidAmountInInvoiceCurrency: formData.paidAmountInInvoiceCurrency
  })
});
