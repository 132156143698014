/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Location } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class Locations<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Get a list of locations
   *
   * @tags locations
   * @name GetLocations
   * @summary Get a list of locations
   * @request GET:/locations
   * @secure
   */
  getLocations = (
    query?: {
      /**
       * The current page number
       * @format int32
       * @min 1
       * @max 10000
       * @default 1
       */
      page?: number;
      /**
       * The number of items to return per page
       * @format int32
       * @min 1
       * @max 10000
       * @default 10
       */
      pageSize?: number;
      /** Filter by location name */
      name?: string;
      /**
       * Comma-separated list of fields to sort by, prefix with '-' for descending order. Example - https://api.epam.com/examples?sortBy=field1,-field2
       * @pattern ^(id|type|name,-id|-type|-name)?$
       */
      sortBy?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<
      {
        items: Location[];
        /** @format int64 */
        total: number;
      },
      any
    >({
      path: `/locations`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
}
