import { Form, Input, InputNumber, Segmented, Select } from 'antd';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { DictionaryName } from '../../models';
import { FormContext } from '../DictionaryDetails';
import { Contract, ContractType, DueDateCondition, PaymentTerm } from 'services/api/data-contracts';
import { StyledDatePicker } from 'components/common/StyledDatePicker';
import { contractTypes } from 'consts/contract';
import { CounterpartyInput } from 'components/CounterpartyInput/CounterpartyInput';
import { ContractCurrenciesInput } from 'components/CurrencyInput/ContractCurrenciesInput';
import { getSelectOptions } from 'helpers';
import { ContractWithDayJs } from 'store/contractStore';
import { FormInstance } from 'antd/es/form';

export const ContractDictionaryForm = () => {
  const { dictionaryName } = useParams<{ dictionaryName: DictionaryName }>();
  const form = useContext(FormContext);

  const type = Form.useWatch('type', form as unknown as FormInstance<ContractWithDayJs>);
  const paymentTerm = Form.useWatch(
    'paymentTerm',
    form as unknown as FormInstance<ContractWithDayJs>
  );
  const startDate = Form.useWatch('startDate', form as unknown as FormInstance<ContractWithDayJs>);

  if (!form) {
    return null;
  }

  return (
    <Form<Contract>
      name={dictionaryName}
      layout="horizontal"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      form={form}
      colon={false}
      initialValues={{ type: ContractType.WithCustomer, paymentTerm: PaymentTerm.Prepayment }}
      className="styled-form"
      onValuesChange={(updated, formData) => {
        const { currencies, startDate, number, supplierId, customerId } =
          formData as unknown as ContractWithDayJs;

        if (currencies?.length && startDate) {
          form.setFieldValue(
            'description',
            `${number || '""'}-${currencies.join('/')} from ${startDate.format(DATE_FORMAT)}`
          );
        }

        if (updated.type) {
          form.setFieldsValue({ supplierId: customerId, customerId: supplierId });
        }

        if (updated.paymentTerm === PaymentTerm.Prepayment) {
          form.setFieldsValue({ dueDateCount: 0, dueDateCondition: undefined });
        }
      }}>
      <Form.Item name="type" label=" " labelCol={{ span: 6 }}>
        <Segmented options={contractTypes} size={CONTROL_SIZE} />
      </Form.Item>

      <Form.Item label="Number" name="number" labelCol={{ span: 6 }}>
        <Input size={CONTROL_SIZE} placeholder="Input number" autoFocus />
      </Form.Item>

      <Form.Item
        label="Start date"
        name="startDate"
        rules={[{ required: true }]}
        labelCol={{ span: 6 }}>
        <StyledDatePicker
          id="startDate"
          format={DATE_FORMAT}
          placeholder="Select start date"
          onSetDate={(value) => {
            form && form.setFieldValue('startDate', value);
          }}
        />
      </Form.Item>

      <Form.Item
        label="End date"
        name="endDate"
        labelCol={{ span: 6 }}
        rules={[
          {
            validator: async (_, value) => {
              if (value && startDate && value <= startDate) {
                return Promise.reject('End date should be later than a start date');
              }
            }
          }
        ]}>
        <StyledDatePicker
          id="endDate"
          format={DATE_FORMAT}
          placeholder="Select end date"
          onSetDate={(value) => {
            form && form.setFieldValue('endDate', value);
          }}
        />
      </Form.Item>

      <CounterpartyInput
        label="Customer"
        name="customerId"
        labelSpan={6}
        isSelf={type === ContractType.WithSupplier}
        required
        onChange={(counterparty) => form?.setFieldValue('customerId', counterparty?.id)}
      />

      <CounterpartyInput
        label="Supplier"
        name="supplierId"
        labelSpan={6}
        isSelf={type === ContractType.WithCustomer}
        required
        onChange={(counterparty) => form?.setFieldValue('supplierId', counterparty?.id)}
      />

      <ContractCurrenciesInput labelSpan={6} />

      <Form.Item
        label="Description"
        name="description"
        labelCol={{ span: 6 }}
        rules={[{ required: true }]}>
        <Input size={CONTROL_SIZE} />
      </Form.Item>

      <Form.Item
        label="Agent fee"
        name="agentFee"
        labelCol={{ span: 6 }}
        hidden={type !== ContractType.WithCustomer}>
        <InputNumber type="number" min={0} max={100} size={CONTROL_SIZE} precision={2} />
      </Form.Item>

      <Form.Item
        label="Payment term"
        name="paymentTerm"
        labelCol={{ span: 6 }}
        rules={[{ required: true }]}>
        <Select options={getSelectOptions(Object.values(PaymentTerm))} size={CONTROL_SIZE} />
      </Form.Item>

      <Form.Item
        label="Due date"
        name="dueDateCount"
        labelCol={{ span: 6 }}
        rules={[
          { min: 0, type: 'integer', message: 'Days count should be a positive number' },
          {
            validator: async (_, value) => {
              if (!value && paymentTerm === PaymentTerm.Postpayment) {
                return Promise.reject('Due date count is required for postpayment contract');
              }
            }
          }
        ]}>
        <InputNumber
          type="number"
          size={CONTROL_SIZE}
          disabled={paymentTerm === PaymentTerm.Prepayment}
          addonAfter={
            <Form.Item
              name="dueDateCondition"
              noStyle
              rules={[
                {
                  validator: async (_, value) => {
                    if (!value && paymentTerm === PaymentTerm.Postpayment) {
                      return Promise.reject(
                        'Due date condition is required for postpayment contract'
                      );
                    }
                  }
                }
              ]}>
              <Select
                style={{ width: '260px' }}
                disabled={paymentTerm === PaymentTerm.Prepayment}
                options={Object.values(DueDateCondition).map((value) => ({
                  label: `days after ${value.toLowerCase()}`,
                  value
                }))}
              />
            </Form.Item>
          }
        />
      </Form.Item>
    </Form>
  );
};
