import { useQuery } from '@tanstack/react-query';
import { flightPurposesApi } from 'services/api';
import { useFlightPurposeStore } from 'store/flightPurposeStore';
import { QUERY_KEY } from './consts';

const fetchList = async () => await flightPurposesApi.getFlightPurposes();

export const useGetFlightPurposes = () => {
  const { setList } = useFlightPurposeStore(({ setList }) => ({
    setList
  }));

  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: fetchList,
    onSuccess: (response) => {
      setList(response.data.items || []);
    }
  });
};
