import { BaseFlightWithAirportCodes, Order, OrderStatus } from 'services/api/data-contracts';
import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { OrderStatusTag } from '../../OrderStatusTag';
import { DATE_TIME_FORMAT } from 'consts/common';
import styled from 'styled-components';
import { getUTCDate } from 'helpers';

interface DecoratedText {
  $isCancelled: boolean;
}

const DecoratedText = styled('span')<DecoratedText>`
  text-decoration: ${(props) => (props.$isCancelled ? 'line-through' : 'none')};
  color: ${(props) => (props.$isCancelled ? '#00000073' : 'inherit')};
`;

export const getColumns = (): ColumnsType<Order> => [
  {
    title: '',
    dataIndex: 'status',
    key: 'status',
    width: 60,
    render: (value) => <OrderStatusTag status={value} />
  },
  {
    title: 'N',
    dataIndex: 'number',
    key: 'number',
    render: (value, record) => (
      <DecoratedText $isCancelled={record.status === OrderStatus.CNLD}>{value}</DecoratedText>
    )
  },
  {
    title: 'Flights',
    dataIndex: 'flights',
    key: 'flights',
    render: (_, record) => {
      const flights = [
        record.arrivalFlight,
        record.departureFlight,
        ...(record.flights || [])
      ].filter(Boolean) as BaseFlightWithAirportCodes[];

      return (
        <DecoratedText $isCancelled={record.status === OrderStatus.CNLD}>
          {flights.map((flight) => flight.flightNumber).join(', ')}
        </DecoratedText>
      );
    }
  },
  {
    title: 'Date',
    dataIndex: 'dates',
    key: 'dates',
    render: (_, record) => (
      <DecoratedText
        $isCancelled={
          record.status === OrderStatus.CNLD
        }>{`${getUTCDate(record.startDatetime).format(DATE_TIME_FORMAT)} - ${getUTCDate(record.endDatetime).format(DATE_TIME_FORMAT)}`}</DecoratedText>
    )
  }
];
