import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { contractsApi } from 'services/api';
import { SupplierService } from 'services/api/data-contracts';
import { SERVICE_ENTITY_NAME, SERVICE_QUERY_KEY } from './consts';
import { MESSAGE_DURATION } from 'consts/common';

const updateItem = async (data: SupplierService) =>
  await contractsApi.updateContractService(data.contractId, data.id, data);

export const useUpdateContractService = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: SupplierService) => await updateItem(data),
    onSuccess: async (response) => {
      if (response.data) {
        await queryClient.invalidateQueries({ queryKey: [SERVICE_QUERY_KEY] });

        message.success(`${SERVICE_ENTITY_NAME} has been successfully updated`, MESSAGE_DURATION);

        onSuccess && onSuccess();
      }
    }
  });
};
