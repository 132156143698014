import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { serviceNamesApi } from 'services/api';
import { ServiceName } from 'services/api/data-contracts';
import { ENTITY_NAME, QUERY_KEY } from './consts';
import { MESSAGE_DURATION } from 'consts/common';

const updateItem = async (data: ServiceName, id: number) =>
  await serviceNamesApi.updateServiceName(id, data);

export const useUpdateServiceName = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ServiceName) => await updateItem(data, data.id),
    onSuccess: async (response) => {
      if (response.data) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEY, response.data.id] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });

        message.success(
          `${ENTITY_NAME} "${response.data.name}" has been successfully updated`,
          MESSAGE_DURATION
        );
      }
    }
  });
};
