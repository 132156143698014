import { Links } from '../../../components/Links/Links';
import { Form, Spin } from 'antd';
import { FlightDetails } from './FlightDetails';
import React, { useContext } from 'react';
import { useGetFlight } from 'queries/flight';
import { useGetLinks } from 'queries/links/useGetLinks';
import { useFlightStore } from 'store/flightStore';
import { Flight, LinkedEntityTypes } from 'services/api/data-contracts';
import { useFlightsDashboardStore } from 'store/flightsDashboardStore';
import styled from 'styled-components';
import { FormContext } from './FlightDrawer';
import { FlightWithDayJs } from './utils';
import { FormInstance } from 'antd/es/form';

const SpinWrapper = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinksWrapper = styled('div')`
  box-shadow:
    0 6px 6px 0 #00000014,
    0 3px 6px -4px #0000001f;
  margin: -12px -8px 8px;
  width: calc(100% + 16px);
  padding: 0 8px 4px;
`;

export const FlightDrawerContent = ({ isLoading }: { isLoading: boolean }) => {
  const { flight } = useFlightStore(({ current }) => ({
    flight: current as Flight
  }));
  const { isFlightEditMode, openFlightId } = useFlightsDashboardStore(
    ({ isFlightEditMode, openFlightId }) => ({
      isFlightEditMode,
      openFlightId
    })
  );

  const { form: formInstance, isTouched, setTouched } = useContext(FormContext);
  const form = formInstance as FormInstance<FlightWithDayJs>;

  const { isFetching } = useGetFlight(
    !!openFlightId && openFlightId !== flight?.id,
    Number(openFlightId)
  );
  const linksQuery = useGetLinks({ flightIds: flight?.id?.toString() }, !!flight?.id);

  const hasLinks =
    [...(linksQuery.data?.data.orders || []), ...(linksQuery.data?.data.messages || [])].length > 0;

  return (
    <>
      {!isFlightEditMode && hasLinks && (
        <LinksWrapper>
          <Links
            targetEntityType={LinkedEntityTypes.Flight}
            targetEntityId={flight?.id}
            isLoading={linksQuery.isFetching}
            linkedOrders={linksQuery.data?.data.orders}
            linkedMessages={linksQuery.data?.data.messages}
          />
        </LinksWrapper>
      )}

      {isLoading || isFetching ? (
        <SpinWrapper>
          <Spin />
        </SpinWrapper>
      ) : (
        <Form
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ justifyContent: 'center' }}
          layout="vertical"
          requiredMark="optional"
          form={form}
          autoComplete="off"
          colon={false}
          className="styled-form"
          onValuesChange={() => {
            const didChange = form.isFieldsTouched() !== isTouched;

            if (didChange) {
              setTouched && setTouched(form.isFieldsTouched());
            }
          }}>
          <FlightDetails />
        </Form>
      )}
    </>
  );
};
