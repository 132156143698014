import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDrawerStore } from 'store/drawerStore';
import { useFlightProgramStore } from 'store/flightProgramStore';

export const useFlightProgramDrawerRouting = () => {
  const { setOpenProgramId } = useFlightProgramStore(({ setOpenProgramId }) => ({
    setOpenProgramId
  }));
  const { setDrawerOpen } = useDrawerStore(({ setDrawerOpen }) => ({ setDrawerOpen }));

  const { entityType, entityId } = useParams();

  useEffect(() => {
    if (entityType !== 'flightProgram') return;
    if (!entityId) return;

    if (entityType === 'flightProgram' && !isNaN(+entityId)) {
      setOpenProgramId(+entityId);
      setDrawerOpen('flightProgram');
    }
  }, []);
};
