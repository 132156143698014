/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Error,
  FlightType,
  FlightTypeInput,
  FlightTypeListResponse,
  ValidationError
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class FlightTypes<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Get a list of flight types
   *
   * @tags flight types
   * @name GetFlightTypes
   * @summary Get a list of flight types
   * @request GET:/flight-types
   * @secure
   */
  getFlightTypes = (params: RequestParams = {}) =>
    this.http.request<FlightTypeListResponse, Error>({
      path: `/flight-types`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Create a new flight type
   *
   * @tags flight types
   * @name AddFlightType
   * @summary Create a new flight type
   * @request POST:/flight-types
   * @secure
   */
  addFlightType = (data: FlightTypeInput, params: RequestParams = {}) =>
    this.http.request<FlightType, ValidationError | Error>({
      path: `/flight-types`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Get a specific flight type
   *
   * @tags flight types
   * @name GetFlightTypeById
   * @summary Get a specific flight type
   * @request GET:/flight-types/{flightTypeId}
   * @secure
   */
  getFlightTypeById = (flightTypeId: number, params: RequestParams = {}) =>
    this.http.request<FlightType, Error>({
      path: `/flight-types/${flightTypeId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Update a specific flight type
   *
   * @tags flight types
   * @name UpdateFlightType
   * @summary Update a specific flight type
   * @request PUT:/flight-types/{flightTypeId}
   * @secure
   */
  updateFlightType = (flightTypeId: number, data: FlightTypeInput, params: RequestParams = {}) =>
    this.http.request<FlightType, ValidationError | Error>({
      path: `/flight-types/${flightTypeId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Delete a specific flight type
   *
   * @tags flight types
   * @name DeleteFlightType
   * @summary Delete a specific flight type
   * @request DELETE:/flight-types/{flightTypeId}
   * @secure
   */
  deleteFlightType = (flightTypeId: number, params: RequestParams = {}) =>
    this.http.request<void, ValidationError | Error>({
      path: `/flight-types/${flightTypeId}`,
      method: 'DELETE',
      secure: true,
      ...params
    });
}
