import { useQuery } from '@tanstack/react-query';
import { ordersApi } from 'services/api';
import { WIDGET_QUERY_KEY } from './consts';
import { useDashboardStore } from 'store/dashboardStore';
import { AxiosResponse } from 'axios';
import { useFlightsDashboardStore } from 'store/flightsDashboardStore';
import { OrdersFilter, useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { DEFAULT_LIMIT, SERVER_DATE_FORMAT } from 'consts/common';
import { useMessageStore } from 'store/messageStore';
import { map } from 'lodash';
import { getJoinedProps, OptionPropName } from 'helpers/dataTransformations/getJoinedProps';

interface FetchListProps {
  filter: OrdersFilter;
  flightIds?: string;
  messageIds?: string;
}

const fetchList = async ({ filter, messageIds, flightIds }: FetchListProps) =>
  await ordersApi.getOrders({
    flightIds,
    messageIds,
    sortBy: 'startDatetime',
    types: filter.orderTypes?.join(','),
    startDatetime: filter.orderStartDate?.map((date) => date?.format(SERVER_DATE_FORMAT)).join(','),
    endDatetime: filter.orderEndDate?.map((date) => date?.format(SERVER_DATE_FORMAT)).join(','),
    orderStatuses: filter.orderStatuses?.join(','),
    orderNumbers: filter.orderNumbers,
    customerIds: getJoinedProps(filter.customers, OptionPropName.Value),
    payerIds: getJoinedProps(filter.payers, OptionPropName.Value),
    locationIds: getJoinedProps(filter.locations, OptionPropName.Value),
    supplierIds: getJoinedProps(filter.suppliers, OptionPropName.Value),
    providerIds: getJoinedProps(filter.providers, OptionPropName.Value),
    aircraftIds: getJoinedProps(filter.ordersAircrafts, OptionPropName.Value),
    isInProgram: filter.isNotInProgram ? false : undefined,
    limit: DEFAULT_LIMIT
  });

export const useGetOrdersWidget = () => {
  const { linkFilters } = useDashboardStore(({ linkFilters }) => ({ linkFilters }));
  const { setOrdersList, ordersFilter } = useOrdersDashboardStore(
    ({ setOrdersList, ordersFilter }) => ({ setOrdersList, ordersFilter })
  );
  const { flightIds } = useFlightsDashboardStore(({ selectedFlights }) => ({
    flightIds: map(selectedFlights, 'id')
  }));
  const { messageIds } = useMessageStore(({ selectedMessages }) => ({
    messageIds: map(selectedMessages, 'id')
  }));

  const flightsFilterLength = linkFilters.includes('flight-order') ? flightIds.length : 0;
  const messagesFilterLength = linkFilters.includes('message-order') ? messageIds.length : 0;

  return useQuery({
    queryKey: [WIDGET_QUERY_KEY, ordersFilter, flightsFilterLength, messagesFilterLength],
    queryFn: () =>
      fetchList({
        filter: ordersFilter,
        flightIds: flightsFilterLength ? flightIds.join(',') : undefined,
        messageIds: messagesFilterLength ? messageIds.join(',') : undefined
      }),
    onSuccess: async (response) => {
      setOrdersList((response as AxiosResponse).data?.items || []);
    }
  });
};
