import { useQuery } from '@tanstack/react-query';
import { vatRatesApi } from 'services/api';
import { QUERY_KEY } from './consts';
import { useVatRateStore } from 'store/vatRateStore';

const fetchList = async () => await vatRatesApi.getAllVatRates();

export const useGetVatRates = () => {
  const { setList } = useVatRateStore(({ setList }) => ({
    setList
  }));

  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: () => fetchList(),
    onSuccess: async (response) => {
      setList(response.data.items);
    },
    keepPreviousData: true
  });
};
