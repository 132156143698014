import React, { useMemo } from 'react';
import { Select } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { sortBy } from 'lodash';
import { useContractStore } from 'store/contractStore';
import { useGetContractsByCounterparty } from 'queries/contract';

interface IProps {
  counterpartyId?: number;
  onChange: (contractIds?: string) => void;
  disabled?: boolean;
  value?: string;
}

export const BalanceContractInput = ({
  counterpartyId,
  onChange,
  disabled = false,
  value
}: IProps) => {
  const { list } = useContractStore(({ list }) => ({ list }));

  const { isLoading } = useGetContractsByCounterparty(counterpartyId);

  const options = useMemo(
    () =>
      sortBy(list, (o) => [!o.description]).map(({ id, description }) => ({
        label: description,
        value: id.toString()
      })),
    [list]
  );

  return (
    <Select
      style={{ width: '400px' }}
      placeholder="All contracts"
      mode="tags"
      maxTagCount="responsive"
      options={options}
      size={CONTROL_SIZE}
      loading={isLoading}
      showSearch
      optionFilterProp="label"
      disabled={disabled}
      onChange={(values) => onChange(values.join(','))}
      value={value?.split(',')}
    />
  );
};
