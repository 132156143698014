import { useQuery } from '@tanstack/react-query';
import { flightsApi } from 'services/api';
import { WIDGET_QUERY_KEY } from './consts';
import { PAGE_SIZE_20 } from 'consts/common';
import { FlightsFilter, useFlightsDashboardStore } from 'store/flightsDashboardStore';
import { useDashboardStore } from 'store/dashboardStore';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { useMessageStore } from 'store/messageStore';
import { map } from 'lodash';
import { getJoinedProps, OptionPropName } from 'helpers/dataTransformations/getJoinedProps';
import { useFlightProgramStore } from 'store/flightProgramStore';

interface FetchListProps {
  orderNumbers?: string;
  messageIds?: string;
  programIds?: string;
  filter: FlightsFilter;
  page: number;
}

const fetchList = async ({ orderNumbers, filter, messageIds, programIds, page }: FetchListProps) =>
  await flightsApi.getFlightsWidget({
    sortBy: 'estimatedTimeDeparture',
    pageSize: PAGE_SIZE_20,
    page,
    estimatedTimeDeparture: filter.estimatedTimeDeparture
      ? filter.estimatedTimeDeparture.map((date) => date?.format()).join(',')
      : undefined,
    flightNumber: filter.flightNumber,
    aircraftIds: getJoinedProps(filter.flightsAircrafts, OptionPropName.Value),
    operatorIds: getJoinedProps(filter.operators, OptionPropName.Value),
    airportId: filter.airport ? filter.airport?.id : undefined,
    orderNumbers,
    messageIds,
    programIds
  });

export const useGetFlightsWidget = (page: number) => {
  const { linkFilters } = useDashboardStore(({ linkFilters }) => ({ linkFilters }));
  const { setFlightsList, loadedData, setFlightsListItemsCount, flightsFilter } =
    useFlightsDashboardStore(
      ({ setFlightsList, flightsList, setFlightsListItemsCount, flightsFilter }) => ({
        setFlightsList,
        loadedData: flightsList,
        setFlightsListItemsCount,
        flightsFilter
      })
    );
  const { orderNumbers } = useOrdersDashboardStore(({ selectedOrders }) => ({
    orderNumbers: map(selectedOrders, 'number')
  }));
  const { messageIds } = useMessageStore(({ selectedMessages }) => ({
    messageIds: map(selectedMessages, 'id')
  }));
  const { programIds } = useFlightProgramStore(({ selectedPrograms }) => ({
    programIds: map(selectedPrograms, 'id')
  }));

  const ordersFilterLength = linkFilters.includes('order-flight') ? orderNumbers.length : 0;
  const messagesFilterLength = linkFilters.includes('message-flight') ? messageIds.length : 0;
  const programsFilterLength = linkFilters.includes('program-flight') ? programIds.length : 0;

  return useQuery({
    queryKey: [
      WIDGET_QUERY_KEY,
      flightsFilter,
      page,
      messagesFilterLength,
      ordersFilterLength,
      programsFilterLength
    ],
    queryFn: () =>
      fetchList({
        orderNumbers: ordersFilterLength ? orderNumbers.join(',') : undefined,
        messageIds: messagesFilterLength ? messageIds.join(',') : undefined,
        programIds: programsFilterLength ? programIds.join(',') : undefined,
        filter: flightsFilter,
        page
      }),
    onSuccess: async (response) => {
      const pageData = response.data.items || [];
      setFlightsList(page === 1 ? pageData : [...loadedData, ...pageData]);

      setFlightsListItemsCount(response.data.total);
    }
  });
};
