import React, { useContext } from 'react';
import { Descriptions, DescriptionsProps, Flex } from 'antd';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import { DataContext } from '../DictionaryDetails';
import { OwnPriceHydratedWithDayJs, useOwnPriceStore } from 'store/ownPriceStore';
import { getAmountValue } from 'helpers';
import { SpecialParametersView } from './components/SpecialParameters/SpecialParametersView';

export const OwnPriceDictionaryView = () => {
  const formData = useContext(DataContext) as unknown as OwnPriceHydratedWithDayJs;

  const { current: data } = useOwnPriceStore(({ current }) => ({
    current
  }));

  if (!data || !formData) {
    return null;
  }

  const descriptions: DescriptionsProps['items'] = [
    {
      label: 'Name',
      span: 2,
      children: data.name
    },
    {
      label: 'Service name',
      children: data.serviceName?.name,
      span: 2
    },
    {
      label: 'Cur',
      children: data.currency || '-'
    },
    {
      label: 'Price',
      children: getAmountValue(data.price)
    },
    {
      label: 'UOM',
      children: data.unitOfMeasure?.shortName || '-'
    },
    {
      label: 'Special price',
      children: data.isSpecialPrice ? 'Yes' : 'No'
    },
    {
      label: 'Start date',
      children: data.startDate ? data.startDate.format(DATE_FORMAT) : '-'
    },
    {
      label: 'End date',
      children: data.endDate ? data.endDate.format(DATE_FORMAT) : '-'
    },
    {
      label: 'Notes',
      span: 2,
      children: <span style={{ whiteSpace: 'pre-line' }}>{data.notes || '-'}</span>
    }
  ];

  return (
    <Flex vertical gap={28}>
      <Descriptions size={CONTROL_SIZE} items={descriptions} column={2} bordered />
      {data.isSpecialPrice && (
        <SpecialParametersView specialParameters={data.specialParameters || []} />
      )}
    </Flex>
  );
};
