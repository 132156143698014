import React, { useContext } from 'react';
import { Button, CollapseProps, Flex, Form, FormInstance, Input, InputNumber } from 'antd';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import { useParams } from 'react-router-dom';
import { DictionaryName } from '../../models';
import { FormContext } from '../DictionaryDetails';
import { UnitsOfMeasureInput } from 'components/UnitsOfMeasureInput/UnitsOfMeasureInput';
import styled from 'styled-components';
import { BooleanRadioGroup } from 'components/common/BooleanRadioGroup';
import { CurrencyInput } from 'components/CurrencyInput/CurrencyInput';
import { ServiceNameInput } from 'components/ServiceNameInput/ServiceNameInput';
import { OwnPriceHydrated, SpecialParameterAlias } from 'services/api/data-contracts';
import { StyledBadge } from 'components/common/StyledBadge';
import { SpecialParameterForm } from './components/SpecialParameters/SpecialParameterForm';
import { PlusOutlined } from '@ant-design/icons';
import { StyledCollapse } from 'components/common/StyledCollapse';
import { blue } from '@ant-design/colors';
import { StyledDatePicker } from 'components/common/StyledDatePicker';

const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
`;

export const OwnPriceDictionaryForm = () => {
  const { dictionaryName } = useParams<{ dictionaryName: DictionaryName }>();
  const form = useContext(FormContext);
  const isSpecialPrice = Form.useWatch('isSpecialPrice', form as FormInstance<OwnPriceHydrated>);
  const startDate = Form.useWatch('startDate', form as FormInstance<OwnPriceHydrated>);
  const specialParameters = Form.useWatch(
    'specialParameters',
    form as FormInstance<OwnPriceHydrated>
  );
  const isOrderSpecialParameterSelected = !!specialParameters?.length
    ? specialParameters?.some((item) => item?.alias === SpecialParameterAlias.Order)
    : false;

  if (!form) {
    return null;
  }

  const collapseContent: CollapseProps['items'] = [
    {
      key: 'specialParameters',
      label: (
        <Flex gap={4} align="center">
          Special parameters
          <StyledBadge count={specialParameters?.filter(Boolean).length} color={blue[0]} />
        </Flex>
      ),
      children: (
        <Form.List name="specialParameters">
          {(list, { add, remove }) => (
            <Flex vertical>
              {list?.map((item) => (
                <SpecialParameterForm
                  listItemKey={item.name}
                  specialParameters={specialParameters}
                  key={item.key}
                  remove={remove}
                />
              ))}

              {!isOrderSpecialParameterSelected && (
                <Button
                  type="dashed"
                  size={CONTROL_SIZE}
                  onClick={() => add()}
                  style={{ width: '30%' }}
                  icon={<PlusOutlined />}>
                  Add parameter
                </Button>
              )}
            </Flex>
          )}
        </Form.List>
      )
    }
  ];

  return (
    <Form
      name={dictionaryName}
      layout="vertical"
      form={form}
      initialValues={{ isSpecialPrice: false }}
      colon={false}
      requiredMark={false}
      className="styled-form">
      <RowWrapper>
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input size={CONTROL_SIZE} autoFocus />
        </Form.Item>
        <ServiceNameInput name={['serviceNameId']} label="Service name" required />
      </RowWrapper>

      <RowWrapper>
        <Flex gap={8}>
          <CurrencyInput required />
          <Form.Item
            name="price"
            label="Price"
            rules={[
              {
                required: true
              }
            ]}
            style={{ flexGrow: 1 }}>
            <InputNumber
              type="number"
              size={CONTROL_SIZE}
              min={0}
              style={{ width: '100%' }}
              precision={2}
            />
          </Form.Item>
        </Flex>

        <UnitsOfMeasureInput name="unitOfMeasureId" label="UOM" required />
      </RowWrapper>

      <RowWrapper>
        <Flex gap={8}>
          <Form.Item label="Start date" name="startDate" rules={[{ required: true }]}>
            <StyledDatePicker
              format={DATE_FORMAT}
              placeholder="Select start date"
              id="startDate"
              onSetDate={(value) => form?.setFieldValue('startDate', value)}
            />
          </Form.Item>
          <Form.Item
            label="End date"
            name="endDate"
            rules={[
              {
                validator: async (_, value) => {
                  if (value && startDate && value <= startDate) {
                    return Promise.reject('End date should be later than a start date');
                  }
                }
              }
            ]}>
            <StyledDatePicker
              format={DATE_FORMAT}
              placeholder="Select end date"
              id="endDate"
              onSetDate={(value) => form?.setFieldValue('endDate', value)}
            />
          </Form.Item>
        </Flex>
        <Form.Item
          name="isSpecialPrice"
          label="Special price"
          rules={[
            { required: true },
            {
              validator: async (_, value) => {
                if (value && (specialParameters == null || specialParameters?.length === 0)) {
                  return Promise.reject('Please add a special parameter');
                }
              }
            }
          ]}>
          <BooleanRadioGroup
            testId="SpecialPrice"
            onChange={(value) => {
              form?.setFieldValue('isSpecialPrice', value);
            }}
            value={form.getFieldValue('isSpecialPrice')}
          />
        </Form.Item>
      </RowWrapper>

      <Form.Item name="notes" label="Notes">
        <Input.TextArea size={CONTROL_SIZE} autoSize={{ minRows: 2, maxRows: 20 }} />
      </Form.Item>

      {isSpecialPrice && (
        <StyledCollapse
          items={collapseContent}
          expandIconPosition="end"
          size={CONTROL_SIZE}
          defaultActiveKey="specialParameters"
          ghost
        />
      )}
    </Form>
  );
};
