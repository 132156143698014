import { create } from 'zustand';
import { FlightType } from 'services/api/data-contracts';
import { devtools } from 'zustand/middleware';

type FlightTypeStore = {
  current?: FlightType;
  setCurrent: (current?: FlightType) => void;
  list: FlightType[];
  setList: (list: FlightType[]) => void;
};

export const useFlightTypeStore = create<FlightTypeStore>()(
  devtools((set) => ({
    current: undefined,
    setCurrent: (current) => {
      set({ current });
    },
    list: [],
    setList: (list) => {
      set({ list });
    }
  }))
);
