import { useQuery } from '@tanstack/react-query';
import { UnitOfMeasure } from 'services/api/data-contracts';
import { unitsOfMeasureApi } from 'services/api';
import { useUnitOfMeasureStore } from 'store/unitOfMeasureStore';
import { QUERY_KEY } from './consts';

const fetchItem = async (id: number) => await unitsOfMeasureApi.getUnitOfMeasureById(id);

export const useGetUnitOfMeasure = (
  enabled: boolean,
  id?: number,
  onSuccess?: (item?: UnitOfMeasure) => void
) => {
  const { setCurrent } = useUnitOfMeasureStore(({ setCurrent }) => ({
    setCurrent
  }));

  return useQuery({
    queryKey: [QUERY_KEY, id],
    queryFn: async () => {
      if (id) {
        return await fetchItem(id);
      }
    },
    onSuccess: (response) => {
      setCurrent(response?.data);

      onSuccess && onSuccess(response?.data);
    },
    enabled
  });
};
