import { Descriptions, DescriptionsProps } from 'antd';
import { CONTROL_SIZE, DATE_TIME_FORMAT } from 'consts/common';
import React from 'react';
import { getUTCDate } from 'helpers';
import { PermitOrder } from 'services/api/data-contracts';
import { green, red } from '@ant-design/colors';
import { rgba } from 'polished';
import { capitalize } from 'lodash';
import { computeValidity } from '../../../../../DispatcherOrdersWidget/helpers/compute';

interface IProps {
  order: PermitOrder;
}

export const PermitDetailsView = ({ order }: IProps) => {
  const validity =
    order.validity || computeValidity(getUTCDate(order.validFrom), getUTCDate(order.validTill));
  const validityUnit = order.validity && order.validityUnit ? order.validityUnit : 'hours';

  const descriptions: DescriptionsProps['items'] = [
    {
      label: 'Country',
      span: 2,
      children: order.location?.name
    },
    {
      label: 'Start D&T',
      children: getUTCDate(order.startDatetime).format(DATE_TIME_FORMAT)
    },
    {
      label: 'End D&T',
      children: getUTCDate(order.endDatetime).format(DATE_TIME_FORMAT)
    },
    {
      label: 'Permit type',
      children: order.permitType ? capitalize(order.permitType) : '-'
    },
    {
      label: 'Permit №',
      children: order.permitNumber || '-',
      contentStyle: {
        backgroundColor: order.permitNumber ? rgba(green[1], 0.3) : rgba(red[1], 0.3)
      }
    },
    {
      label: 'Validity',
      span: 2,
      children: validity && validityUnit ? validity + ' ' + validityUnit : '-'
    },
    {
      label: 'From',
      children: order.validFrom ? getUTCDate(order.validFrom).format(DATE_TIME_FORMAT) : '-'
    },
    {
      label: 'To',
      children: order.validTill ? getUTCDate(order.validTill).format(DATE_TIME_FORMAT) : '-'
    },
    {
      label: 'Route',
      span: 2,
      children: order.route
    }
  ];

  return (
    <Descriptions
      items={descriptions}
      size={CONTROL_SIZE}
      labelStyle={{ whiteSpace: 'nowrap' }}
      column={2}
      bordered
    />
  );
};
