import React, { useMemo } from 'react';
import { useOrderStore } from 'store/orderStore';
import { LegsForm } from './LegsForm';
import { LegsView } from './LegsView';
import { PermitOrder } from 'services/api/data-contracts';
import { CollapseProps } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { blue } from '@ant-design/colors';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { StyledBadge } from 'components/common/StyledBadge';
import { StyledCollapse } from 'components/common/StyledCollapse';
import { sortBy } from 'lodash';

interface IProps {
  isLoading: boolean;
  selectedFlights: number[];
}

export const LegsBlock = ({ isLoading, selectedFlights }: IProps) => {
  const { current } = useOrderStore(({ current }) => ({ current: current as PermitOrder }));
  const { isOrderEditMode } = useOrdersDashboardStore(({ isOrderEditMode }) => ({
    isOrderEditMode
  }));

  const legs = useMemo(
    () => (current?.flights ? sortBy(current.flights, 'estimatedTimeDeparture') : []),
    [current]
  );

  const collapseContent: CollapseProps['items'] = [
    {
      key: 'general',
      label: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          Legs <StyledBadge count={legs.length} color={blue[0]} />
        </div>
      ),
      children: isOrderEditMode ? (
        <LegsForm legs={legs} selectedFlights={selectedFlights} />
      ) : (
        <LegsView legs={legs} isLoading={isLoading} />
      )
    }
  ];

  return (
    <StyledCollapse
      items={collapseContent}
      expandIconPosition="end"
      size={CONTROL_SIZE}
      defaultActiveKey="general"
      ghost
    />
  );
};
