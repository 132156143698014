import React, { useEffect } from 'react';
import { Button, Flex, Typography, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { PaymentPurposeInput } from 'services/api/data-contracts';
import { useDeletePaymentPurpose } from 'queries/paymentPurpose/useDeletePaymentPurpose';
import { CONTROL_SIZE } from 'consts/common';
import { usePaymentPurposeStore } from 'store/paymentPurposeStore';
import {
  SettlementEntityType,
  useCreatePaymentPurpose
} from 'queries/paymentPurpose/useCreatePaymentPurpose';

export const ConfirmActionMessage = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const deleteMutation = useDeletePaymentPurpose();
  const createMutation = useCreatePaymentPurpose(undefined, SettlementEntityType.INVOICE);

  const {
    current: paymentPurpose,
    setShowRevertActionPopUp,
    showRevertActionPopUp,
    setCurrent
  } = usePaymentPurposeStore(
    ({ current, setShowRevertActionPopUp, setCurrent, showRevertActionPopUp }) => ({
      current,
      setShowRevertActionPopUp,
      setCurrent,
      showRevertActionPopUp
    })
  );

  const isLinkAction = showRevertActionPopUp === 'link';
  const action = showRevertActionPopUp === 'link' ? 'link' : 'unlink';

  const close = () => {
    setShowRevertActionPopUp(false);
    setCurrent(undefined);
    messageApi.destroy();
  };

  const unlinkPaymentPurpose = async () => {
    await deleteMutation.mutate(paymentPurpose?.id as number);
    message.success('The link is cancelled.');
    setShowRevertActionPopUp(false);
    setCurrent(undefined);
    messageApi.destroy();
  };

  const restorePaymentPurpose = async () => {
    const data = {
      paymentId: paymentPurpose?.paymentId,
      invoiceId: paymentPurpose?.invoiceId,
      paidAmount: paymentPurpose?.paidAmount,
      ...(paymentPurpose?.paidAmountInInvoiceCurrency && {
        paidAmountInInvoiceCurrency: paymentPurpose?.paidAmountInInvoiceCurrency
      })
    };
    await createMutation.mutate(data as PaymentPurposeInput);
    message.success('The link is restored.');
    setShowRevertActionPopUp(false);
    setCurrent(undefined);
    messageApi.destroy();
  };

  const content = (
    <div
      style={{ display: 'inline-block', margin: '10px' }}
      data-testid="confirm-payment-purpose-action-popup">
      <Flex>
        <Flex vertical style={{ width: '220px' }}>
          <Typography.Text
            style={{
              textAlign: 'left'
            }}>{`Payment ${paymentPurpose?.paymentId} has been ${action}ed to the invoice`}</Typography.Text>
          <Button
            type="dashed"
            size={CONTROL_SIZE}
            onClick={isLinkAction ? unlinkPaymentPurpose : restorePaymentPurpose}
            style={{ width: '130px', marginTop: '20px' }}>
            {isLinkAction ? 'Cancel the link' : 'Restore the link'}
          </Button>
        </Flex>
        <CloseOutlined
          style={{ margin: '6px', cursor: 'pointer', alignSelf: 'flex-start' }}
          onClick={close}
        />
      </Flex>
    </div>
  );

  useEffect(() => {
    messageApi.success({ content, key: 'confirmPaymentPurposeLink', duration: 0 });
  }, [messageApi, content]);

  return contextHolder;
};
