import { Dayjs } from 'dayjs';
import { getUTCDate } from 'helpers';

export const isTransformableToDate = (value: string) => value?.length >= 2 && !value?.includes('.');

export const getDateFromInput = (value: string): Dayjs => {
  const [date, time = ''] = value.trim().split(' ');

  const day = +date.slice(0, 2);

  const monthValue = +date.slice(2, 4);
  const month = monthValue ? monthValue - 1 : getUTCDate().get('month');

  const yearValue = +date.slice(4, 6);
  const year = yearValue ? +`20${yearValue}` : getUTCDate().get('year');

  const hoursValue = +time.slice(0, 2);
  const hours = hoursValue || 0;

  const minutesValue = +time.slice(2, 4);
  const minutes = minutesValue || 0;

  return getUTCDate()
    .set('year', year)
    .set('month', month)
    .set('date', day)
    .set('hours', hours)
    .set('minutes', minutes)
    .set('seconds', 0)
    .set('milliseconds', 0);
};
