import { Form, Input } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { DictionaryName } from '../../models';
import { FormContext } from '../DictionaryDetails';
import { UnitsOfMeasureInput } from 'components/UnitsOfMeasureInput/UnitsOfMeasureInput';
import styled from 'styled-components';
import { BooleanRadioGroup } from 'components/common/BooleanRadioGroup';

const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
`;

export const ServiceNameDictionaryForm = () => {
  const { dictionaryName } = useParams<{ dictionaryName: DictionaryName }>();
  const form = useContext(FormContext);

  if (!form) {
    return null;
  }

  return (
    <Form
      name={dictionaryName}
      layout="vertical"
      form={form}
      initialValues={{ isActive: true }}
      colon={false}
      requiredMark="optional"
      className="styled-form">
      <Form.Item name="isActive" label="Active" rules={[{ required: true }]}>
        <BooleanRadioGroup
          testId="Active"
          onChange={(value) => form?.setFieldValue('isActive', value)}
          value={form.getFieldValue('isActive')}
        />
      </Form.Item>

      <RowWrapper>
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input size={CONTROL_SIZE} autoFocus />
        </Form.Item>

        <UnitsOfMeasureInput name="unitOfMeasureId" label="Unit" required />
      </RowWrapper>

      <Form.Item name="notes" label="Notes">
        <Input.TextArea size={CONTROL_SIZE} autoSize={{ minRows: 10, maxRows: 20 }} />
      </Form.Item>
    </Form>
  );
};
