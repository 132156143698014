import { useMutation, useQueryClient } from '@tanstack/react-query';
import { paymentPurposesApi } from 'services/api';
import { QUERY_KEY } from './consts';
import { INVOICE_QUERY_KEY } from '../invoice/consts';
import { PAYMENT_QUERY_KEY } from 'queries/payment/consts';
import { BALANCE_WIDGET_QUERY_KEY } from '../balance/consts';
import { useBalanceDashboardStore } from 'store/balanceDashboardStore';
import { useInvoiceStore } from 'store/invoiceStore';
import { usePaymentStore } from 'store/paymentStore';

const deleteItem = async (id: number) => await paymentPurposesApi.deletePaymentPurpose(id);

export const useDeletePaymentPurpose = (onSuccess?: () => void, drawerType = 'invoice') => {
  const queryClient = useQueryClient();

  const { shouldUpdateBalanceView, setShouldUpdateBalanceView, filter } = useBalanceDashboardStore(
    ({ shouldUpdateBalanceView, setShouldUpdateBalanceView, filter }) => ({
      shouldUpdateBalanceView,
      setShouldUpdateBalanceView,
      filter
    })
  );
  const { current: invoice } = useInvoiceStore(({ current }) => ({ current }));
  const { current: payment } = usePaymentStore(({ current }) => ({ current }));

  return useMutation({
    mutationFn: deleteItem,
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEY]);

      drawerType === 'invoice' &&
        (await queryClient.refetchQueries([INVOICE_QUERY_KEY, invoice?.id]));

      drawerType === 'payment' &&
        (await queryClient.refetchQueries([PAYMENT_QUERY_KEY, payment?.id]));

      shouldUpdateBalanceView &&
        (await queryClient.refetchQueries([BALANCE_WIDGET_QUERY_KEY, filter]));

      onSuccess && onSuccess();

      shouldUpdateBalanceView && setShouldUpdateBalanceView(false);
    }
  });
};
