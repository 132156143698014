import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from './consts';
import { fetchList } from './useGetAircrafts';

export const useGetAircraftsForDropdown = () => {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: () => fetchList({})
  });
};
