import { Button, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { CONTROL_SIZE } from 'consts/common';
import React from 'react';

interface IProps {
  onClick: () => void;
  isLoading?: boolean;
  dataTestId?: string;
  isDisabled?: boolean;
}

export const EditButton = ({ onClick, isLoading, isDisabled, dataTestId }: IProps) => (
  <Tooltip title="Close">
    <Button
      size={CONTROL_SIZE}
      icon={<EditOutlined />}
      loading={isLoading}
      data-testid={dataTestId}
      onClick={onClick}
      disabled={isDisabled}>
      Edit
    </Button>
  </Tooltip>
);
