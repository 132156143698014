import { Flight } from 'services/api/data-contracts';
import { getUTCDate } from 'helpers';
import { Dayjs } from 'dayjs';
import { SERVER_DATE_TIME_FORMAT } from 'consts/common';

export type FlightWithDayJs = Omit<
  Flight,
  'estimatedTimeDeparture' | 'actualTimeDeparture' | 'estimatedTimeArrival' | 'actualTimeArrival'
> & {
  estimatedTimeDeparture: Dayjs;
  actualTimeDeparture?: Dayjs | null;
  estimatedTimeArrival: Dayjs;
  actualTimeArrival?: Dayjs | null;
};

export const transformForRender = (flight: Flight): FlightWithDayJs => ({
  ...flight,
  estimatedTimeDeparture: getUTCDate(flight.estimatedTimeDeparture),
  actualTimeDeparture: flight.actualTimeDeparture ? getUTCDate(flight.actualTimeDeparture) : null,
  estimatedTimeArrival: getUTCDate(flight.estimatedTimeArrival),
  actualTimeArrival: flight.actualTimeArrival ? getUTCDate(flight.actualTimeArrival) : null,
  overflightCountryIds: flight.overflightCountryIds || undefined,
  alternativeAirportIds: flight.alternativeAirportIds || undefined
});

export const transformForRequest = (flight: FlightWithDayJs): Flight => ({
  ...flight,
  estimatedTimeDeparture: flight.estimatedTimeDeparture.format(SERVER_DATE_TIME_FORMAT),
  actualTimeDeparture: flight.actualTimeDeparture
    ? flight.actualTimeDeparture.format(SERVER_DATE_TIME_FORMAT)
    : undefined,
  estimatedTimeArrival: flight.estimatedTimeArrival.format(SERVER_DATE_TIME_FORMAT),
  actualTimeArrival: flight.actualTimeArrival
    ? flight.actualTimeArrival.format(SERVER_DATE_TIME_FORMAT)
    : undefined
});
