import React from 'react';
import { omit } from 'lodash';
import { OrdersFilter, OrdersWidgetMode } from 'store/ordersDashboardStore';
import { getDateTagValue } from 'helpers';
import { ProfileOutlined } from '@ant-design/icons';
import { getJoinedProps, OptionPropName } from 'helpers/dataTransformations/getJoinedProps';
import { FilterTag } from 'pages/components/FilterTag';
import { orderTypes } from 'consts/order';
import { getFilterTagName } from './getFilterTagName';

export type FilterCallbackSetter = (filter: OrdersFilter) => void;

export const getAppliedOrderWidgetTabsFilters = (
  filter: OrdersFilter,
  widgetMode: OrdersWidgetMode,
  setFilter: (filter: OrdersFilter) => void,
  setFilterCallback: FilterCallbackSetter
) => {
  return Object.keys(filter)
    .filter((key) => filter[key as keyof typeof filter] !== undefined)
    .map((filterKey) => {
      const key = filterKey as keyof typeof filter;
      let value: string | undefined;

      switch (key) {
        case 'orderStartDate':
        case 'orderEndDate':
          const date = filter[key];
          if (date !== null && date !== undefined) {
            value = getDateTagValue(date);
          }
          break;
        case 'orderTypes':
          if (filter.orderTypes && filter.orderTypes?.length !== orderTypes?.length) {
            value = filter.orderTypes?.join(', ');
          }
          break;
        case 'orderStatuses':
          value = filter[key]?.join(', ');
          break;
        case 'orderNumbers':
          value = filter.orderNumbers?.toString();
          break;
        case 'locations':
          const mappedLocations = filter.locations?.map((location) => location.label.split(',')[0]);
          value = mappedLocations?.join(', ');
          break;
        default:
          const filterValue = filter[key];
          if (Array.isArray(filterValue)) {
            value = getJoinedProps(filterValue, OptionPropName.Label);
          }
          break;
      }

      if (!value) {
        return null;
      }

      const onClose = () => {
        setFilter(omit(filter, key));
        setFilterCallback(omit(filter, key));
      };

      const icon =
        (key as string).toLowerCase().includes('time') ||
        (key as string).toLowerCase().includes('date') ? (
          <ProfileOutlined style={{ color: '#8C8C8C' }} />
        ) : undefined;

      return (
        <FilterTag
          name={getFilterTagName(key, widgetMode)}
          value={value}
          onClose={onClose}
          key={key}
          icon={icon}
        />
      );
    });
};
