import { Form, Input } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { DictionaryName } from '../../models';
import { FormContext } from '../DictionaryDetails';
import styled from 'styled-components';

const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
`;

export const CountryDictionaryForm = () => {
  const { dictionaryName } = useParams<{ dictionaryName: DictionaryName }>();
  const form = useContext(FormContext);

  if (!form) {
    return null;
  }

  return (
    <Form
      name={dictionaryName}
      layout="vertical"
      form={form}
      colon={false}
      requiredMark="optional"
      className="styled-form">
      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input size={CONTROL_SIZE} autoFocus />
      </Form.Item>

      <Form.Item name="fullName" label="Full name" rules={[{ required: true }]}>
        <Input size={CONTROL_SIZE} />
      </Form.Item>

      <RowWrapper>
        <Form.Item
          name="code2"
          label="Code 2"
          rules={[{ min: 2 }, { max: 2 }]}
          normalize={(value) => value.toUpperCase()}>
          <Input size={CONTROL_SIZE} />
        </Form.Item>

        <Form.Item
          name="code3"
          label="Code 3"
          rules={[{ min: 3 }, { max: 3 }]}
          normalize={(value) => value.toUpperCase()}>
          <Input size={CONTROL_SIZE} />
        </Form.Item>
      </RowWrapper>
    </Form>
  );
};
