import { CollapseProps } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import React from 'react';
import { useOrderStore } from 'store/orderStore';
import { PermitOrder } from 'services/api/data-contracts';
import { PermitDetailsForm } from './PermitDetailsForm';
import { PermitDetailsView } from './PermitDetailsView';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { StyledCollapse } from 'components/common/StyledCollapse';

export const PermitDetailsBlock = () => {
  const { current } = useOrderStore(({ current }) => ({ current: current as PermitOrder }));
  const { isOrderEditMode } = useOrdersDashboardStore(({ isOrderEditMode }) => ({
    isOrderEditMode
  }));

  const collapseContent: CollapseProps['items'] = [
    {
      key: 'order',
      label: 'Permit details',
      children: isOrderEditMode ? (
        <PermitDetailsForm order={current} />
      ) : (
        <PermitDetailsView order={current} />
      )
    }
  ];

  return (
    <StyledCollapse
      items={collapseContent}
      expandIconPosition="end"
      size={CONTROL_SIZE}
      defaultActiveKey="order"
      ghost
    />
  );
};
