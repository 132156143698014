import { Col, Row } from 'antd';
import React, { useContext } from 'react';
import { CounterpartyInput } from 'components/CounterpartyInput/CounterpartyInput';
import { AircraftInput } from 'components/AircraftInput/AircraftInput';
import { ContractType, OrderType } from 'services/api/data-contracts';
import { useWatch } from 'antd/es/form/Form';
import { FormInstance } from 'antd/es/form';
import { FormContext } from '../../../OrderDrawer';

interface IProps {
  orderType?: OrderType;
}

export const GeneralInfoForm = ({ orderType }: IProps) => {
  const { form: formInstance } = useContext(FormContext);
  const form = formInstance as FormInstance;

  const operatorId = useWatch('operatorId', form);

  return (
    <>
      <Row>
        <Col span={12}>
          <CounterpartyInput
            label="Customer"
            name="customerId"
            contractType={ContractType.WithCustomer}
            className="mr"
            required
            onChange={(counterparty) => form?.setFieldValue('customerId', counterparty?.id)}
          />
        </Col>
        <Col span={12}>
          <CounterpartyInput
            label="Operator"
            name="operatorId"
            required
            isOperator
            onChange={(counterparty) => form?.setFieldValue('operatorId', counterparty?.id)}
          />
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <CounterpartyInput
            label="Payer"
            name="payerId"
            contractType={ContractType.WithCustomer}
            className="mr"
            required
            onChange={(counterparty) => form?.setFieldValue('payerId', counterparty?.id)}
          />
        </Col>
      </Row>

      <Row>
        <AircraftInput
          required={orderType !== OrderType.ADD}
          initialAircraftId={form?.getFieldValue('aircraftId')}
          withExtraInfo
          onChange={(value) => form?.setFieldValue('aircraftId', value)}
          apiFilter={{ operatorId }}
        />
      </Row>
    </>
  );
};
