import { faker } from '@faker-js/faker';
import { FlightsFilter } from 'store/flightsDashboardStore';
import { getMockAirport, getUTCDate } from 'helpers';

const futureDate = getUTCDate(faker.date.future());

export const getMockFlightsFilters = (): Required<FlightsFilter> => {
  return {
    operators: [{ value: 1, label: faker.company.buzzNoun() }],
    flightsAircrafts: [{ value: 1, label: faker.airline.airplane().name }],
    flightNumber: faker.airline.flightNumber(),
    estimatedTimeDeparture: [futureDate, futureDate],
    airport: getMockAirport()
  };
};
