import { Button, Space } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import React from 'react';

interface IProps {
  isLoading: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

export const HeaderButtons = ({ isLoading, onSubmit, onClose }: IProps) => (
  <Space>
    <Button size={CONTROL_SIZE} loading={isLoading} onClick={onClose}>
      Cancel changes
    </Button>
    <Button type="primary" size={CONTROL_SIZE} loading={isLoading} onClick={onSubmit}>
      Save
    </Button>
  </Space>
);
