/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * Account type
 * @example "outlook"
 */
export enum Type {
  Imap = 'imap',
  Gmail = 'gmail',
  GmailService = 'gmailService',
  Outlook = 'outlook',
  MailRu = 'mailRu',
  Oauth2 = 'oauth2'
}

/**
 * Account state
 * @example "connected"
 */
export enum State {
  Init = 'init',
  Syncing = 'syncing',
  Connecting = 'connecting',
  Connected = 'connected',
  AuthenticationError = 'authenticationError',
  ConnectError = 'connectError',
  Unset = 'unset',
  Disconnected = 'disconnected'
}

/**
 * Lifetime event counters
 * @example {"messageNew":30,"messageDeleted":5}
 */
export type AcountCountersEvents = object;

export interface AccountCounters {
  /** Lifetime event counters */
  events?: AcountCountersEvents;
}

/**
 * Requested grant type
 * @example "refresh_token"
 */
export enum Grant {
  RefreshToken = 'refresh_token',
  AuthorizationCode = 'authorization_code'
}

/** List of requested OAuth2 scopes */
export type OauthScopes = string[];

/**
 * Server response
 * @example {"error":"invalid_grant","error_description":"Bad Request"}
 */
export type Response = object;

/** OAuth2 error info if token request failed */
export interface TokenRequest {
  /** Requested grant type */
  grant?: Grant;
  /**
   * OAuth2 provider
   * @maxLength 256
   * @example "gmail"
   */
  provider?: string;
  /**
   * HTTP status code for the OAuth2 request
   * @example 400
   */
  status?: number;
  /**
   * OAuth2 client ID used to authenticate this request
   * @example "1023289917884-h3nu00e9cb7h252e24c23sv19l8k57ah.apps.googleusercontent.com"
   */
  clientId?: string;
  /** List of requested OAuth2 scopes */
  scopes?: OauthScopes;
  /** Server response */
  response?: Response;
}

export interface AccountErrorEntry {
  /** @example "Token request failed" */
  response?: string;
  /** @example "OauthRenewError" */
  serverResponseCode?: string;
  /** OAuth2 error info if token request failed */
  tokenRequest?: TokenRequest;
}

export interface AccountResponseItem {
  /**
   * Account ID
   * @maxLength 256
   * @example "example"
   */
  account: string;
  /**
   * Display name for the account
   * @maxLength 256
   * @example "My Email Account"
   */
  name?: string;
  /**
   * Default email address of the account
   * @example "user@example.com"
   */
  email?: string;
  /** Account type */
  type: Type;
  /**
   * OAuth2 application ID
   * @maxLength 256
   * @example "AAABhaBPHscAAAAH"
   */
  app?: string;
  /** Account state */
  state: State;
  /**
   * Account-specific webhook URL
   * @example "https://myservice.com/imap/webhooks"
   */
  webhooks?: string;
  /**
   * Proxy URL
   * @example "socks://proxy.example.com:1080"
   */
  proxy?: string;
  /**
   * Hostname to use for the SMTP EHLO/HELO greeting
   * @example "my.proxy.tld"
   */
  smtpEhloName?: string;
  counters?: AccountCounters;
  /**
   * Last sync time
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  syncTime?: string;
  lastError?: AccountErrorEntry;
}

export type AccountEntries = AccountResponseItem[];

export interface AccountsFilterResponse {
  /**
   * How many matching entries
   * @example 120
   */
  total?: number;
  /**
   * Current page (0-based index)
   * @example 0
   */
  page?: number;
  /**
   * Total page count
   * @example 24
   */
  pages?: number;
  accounts?: AccountEntries;
}

export interface DetectedAuthenticationInfo {
  /**
   * Account username
   * @maxLength 256
   * @example "myuser@gmail.com"
   */
  user?: string;
}

export interface ResolvedServerSettings {
  auth?: DetectedAuthenticationInfo;
  /**
   * Hostname to connect to
   * @example "imap.gmail.com"
   */
  host: string;
  /**
   * Service port number
   * @min 1
   * @max 65536
   * @example 993
   */
  port: number;
  /**
   * Should connection use TLS. Usually true for port 993
   * @default false
   * @example true
   */
  secure?: boolean;
}

export interface DiscoveredServerSettings {
  auth?: DetectedAuthenticationInfo;
  /**
   * Hostname to connect to
   * @example "imap.gmail.com"
   */
  host: string;
  /**
   * Service port number
   * @min 1
   * @max 65536
   * @example 993
   */
  port: number;
  /**
   * Should connection use TLS. Usually true for port 993
   * @default false
   * @example true
   */
  secure?: boolean;
}

export interface DiscoveredEmailSettings {
  imap?: ResolvedServerSettings;
  smtp?: DiscoveredServerSettings;
  /**
   * Source for the detected info
   * @example "srv"
   */
  _source?: string;
}

export interface BlocklistsResponseItem {
  /**
   * List ID
   * @maxLength 256
   * @example "example"
   */
  listId: string;
  /**
   * Count of blocked addresses in this list
   * @example 12
   */
  count?: number;
}

export type BlocklistsEntries = BlocklistsResponseItem[];

export interface BlocklistsResponse {
  /**
   * How many matching entries
   * @example 120
   */
  total?: number;
  /**
   * Current page (0-based index)
   * @example 0
   */
  page?: number;
  /**
   * Total page count
   * @example 24
   */
  pages?: number;
  blocklists?: BlocklistsEntries;
}

export interface GatewayResponseItem {
  /**
   * Gateway ID
   * @maxLength 256
   * @example "example"
   */
  gateway: string;
  /**
   * Display name for the gateway
   * @maxLength 256
   * @example "My Email Gateway"
   */
  name?: string;
  /**
   * Count of email deliveries using this gateway
   * @example 100
   */
  deliveries?: number;
  /**
   * Last delivery time
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  lastUse?: string;
  lastError?: AccountErrorEntry;
}

export type GatewayEntries = GatewayResponseItem[];

export interface GatewaysFilterResponse {
  /**
   * How many matching entries
   * @example 120
   */
  total?: number;
  /**
   * Current page (0-based index)
   * @example 0
   */
  page?: number;
  /**
   * Total page count
   * @example 24
   */
  pages?: number;
  gateways?: GatewayEntries;
}

export enum LicenseDetails {
  False = 'false'
}

export interface LicenseResponse {
  /**
   * Is there an active license registered
   * @example true
   */
  active?: boolean;
  /**
   * Active license type
   * @example "EmailEngine License"
   */
  type?: string;
  details?: LicenseDetails;
  /**
   * Are email connections closed
   * @example false
   */
  suspended?: boolean;
}

/**
 * OAuth2 provider
 * @example "gmail"
 */
export enum Provider {
  Gmail = 'gmail',
  GmailService = 'gmailService',
  Outlook = 'outlook',
  MailRu = 'mailRu'
}

export interface OAuth2ResponseItem {
  /**
   * OAuth2 application ID
   * @maxLength 256
   * @example "AAABhaBPHscAAAAH"
   */
  id: string;
  /**
   * Display name for the app
   * @maxLength 256
   * @example "My OAuth2 App"
   */
  name?: string;
  /**
   * OAuth2 application description
   * @maxLength 1024
   * @example "App description"
   */
  description?: string;
  /**
   * Title for the application button
   * @maxLength 256
   * @example "App title"
   */
  title?: string;
  /** OAuth2 provider */
  provider: Provider;
  /**
   * Is the application enabled
   * @example true
   */
  enabled?: boolean;
  /**
   * `true` for older OAuth2 apps set via the settings endpoint
   * @example true
   */
  legacy?: boolean;
  /**
   * The time this entry was added
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  created: string;
  /**
   * The time this entry was updated
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  updated?: string;
  /**
   * Is the application listed in the hosted authentication form
   * @example true
   */
  includeInListing?: boolean;
  /**
   * Client or Application ID for 3-legged OAuth2 applications
   * @example "4f05f488-d858-4f2c-bd12-1039062612fe"
   */
  clientId?: string;
  /**
   * Client secret for 3-legged OAuth2 applications. Actual value is not revealed.
   * @example "******"
   */
  clientSecret?: string;
  /**
   * Authorization tenant value for Outlook OAuth2 applications
   * @example "common"
   */
  authority?: string;
  /**
   * Redirect URL for 3-legged OAuth2 applications
   * @example "https://myservice.com/oauth"
   */
  redirectUrl?: string;
  /**
   * Service client ID for 2-legged OAuth2 applications
   * @example "9103965568215821627203"
   */
  serviceClient?: string;
  /**
   * PEM formatted service secret for 2-legged OAuth2 applications. Actual value is not revealed.
   * @example "******"
   */
  serviceKey?: string;
  lastError?: AccountErrorEntry;
}

export type OAuth2Entries = OAuth2ResponseItem[];

export interface OAuth2FilterResponse {
  /**
   * How many matching entries
   * @example 120
   */
  total?: number;
  /**
   * Current page (0-based index)
   * @example 0
   */
  page?: number;
  /**
   * Total page count
   * @example 24
   */
  pages?: number;
  apps?: OAuth2Entries;
}

/**
 * How this message was added to the queue
 * @example "smtp"
 */
export enum Source {
  Smtp = 'smtp',
  Api = 'api'
}

export type To = string[];

/** SMTP envelope */
export interface Envelope {
  /** @example "sender@example.com" */
  from?: string;
  to?: To;
}

/**
 * Current state of the sending
 * @example "queued"
 */
export enum Status {
  Queued = 'queued',
  Processing = 'processing',
  Submitted = 'submitted',
  Error = 'error'
}

/** Error information if state=error */
export interface OutboxListProgressError {
  /**
   * Error message
   * @example "Authentication failed"
   */
  message?: string;
  /**
   * Error code
   * @example "EAUTH"
   */
  code?: string;
  /**
   * SMTP response code
   * @example 502
   */
  statusCode?: string;
}

export interface OutboxListProgress {
  /** Current state of the sending */
  status?: Status;
  /**
   * Response from the SMTP server. Only if state=processing
   * @example "250 Message Accepted"
   */
  response?: string;
  /** Error information if state=error */
  error?: OutboxListProgressError;
}

export interface OutboxListItem {
  /**
   * Outbox queue ID
   * @example "1869c5692565f756b33"
   */
  queueId?: string;
  /**
   * Account ID
   * @maxLength 256
   * @example "example"
   */
  account: string;
  /** How this message was added to the queue */
  source?: Source;
  /**
   * Message ID
   * @maxLength 996
   * @example "<test123@example.com>"
   */
  messageId?: string;
  /** SMTP envelope */
  envelope?: Envelope;
  /**
   * Message subject
   * @maxLength 10240
   * @example "What a wonderful message"
   */
  subject?: string;
  /**
   * The time this message was queued
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  created?: string;
  /**
   * When this message is supposed to be delivered
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  scheduled?: string;
  /**
   * Next delivery attempt
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  nextAttempt?: string;
  /**
   * How many times EmailEngine has tried to deliver this email
   * @example 3
   */
  attemptsMade?: number;
  /**
   * How many delivery attempts to make until message is considered as failed
   * @example 3
   */
  attempts?: number;
  progress?: OutboxListProgress;
}

export type OutboxListEntries = OutboxListItem[];

export interface OutboxListResponse {
  /**
   * How many matching entries
   * @example 120
   */
  total?: number;
  /**
   * Current page (0-based index)
   * @example 0
   */
  page?: number;
  /**
   * Total page count
   * @example 24
   */
  pages?: number;
  messages?: OutboxListEntries;
}

export type WebhookEvents = string[];

export interface WebhooksCustomHeader {
  /** @maxLength 1024 */
  key: string;
  /**
   * @maxLength 10240
   * @default ""
   */
  value?: string;
}

export type WebhooksCustomHeaders = WebhooksCustomHeader[];

export type NotifyHeaders = string[];

/** Gmail OAuth2 Extra Scopes (deprecated) */
export type GmailExtraScopes = string[];

/**
 * Outlook OAuth2 authority (deprecated)
 * @example "consumers"
 */
export enum OutlookAuthority {
  Consumers = 'consumers',
  Organizations = 'organizations',
  Common = 'common'
}

/** Outlook OAuth2 Extra Scopes (deprecated) */
export type OutlookExtraScopes = string[];

/** Mail.ru OAuth2 Extra Scopes (deprecated) */
export type MailRuExtraScopes = string[];

/** OAuth2 Service Extra Scopes (deprecated) */
export type ServiceExtraScopes = string[];

export interface LogSettings {
  /**
   * Enable logs for all accounts
   * @default false
   * @example false
   */
  all?: boolean;
  /**
   * @min 0
   * @max 1000000
   * @default 10000
   */
  maxLogLines?: number;
}

/** How to select local IP address for IMAP connections */
export enum ImapStrategy {
  Default = 'default',
  Dedicated = 'dedicated',
  Random = 'random'
}

/** How to select local IP address for SMTP connections */
export enum SmtpStrategy {
  Default = 'default',
  Dedicated = 'dedicated',
  Random = 'random'
}

/** A list of pooled local IP addresses that can be used for IMAP and SMTP connections */
export type LocalAddresses = string[];

/**
 * Default locale identifier
 * @maxLength 100
 * @example "fr"
 */
export enum Locale {
  En = 'en',
  Et = 'et',
  Fr = 'fr',
  De = 'de'
}

export interface SettingsQueryResponse {
  /** If false then do not emit webhooks */
  webhooksEnabled?: boolean;
  /**
   * Webhook URL
   * @example "https://myservice.com/imap/webhooks"
   */
  webhooks?: string;
  webhookEvents?: WebhookEvents;
  webhooksCustomHeaders?: WebhooksCustomHeaders;
  notifyHeaders?: NotifyHeaders;
  /**
   * Base URL of EmailEngine
   * @example "https://emailengine.example.com"
   */
  serviceUrl?: string;
  /** If true, then rewrite html links in sent emails to track opens and clicks */
  trackSentMessages?: boolean;
  /** If true, then resolve the category tab for incoming emails */
  resolveGmailCategories?: boolean;
  /** If true, then allow insecure certificates for IMAP/SMTP */
  ignoreMailCertErrors?: boolean;
  /** If true, then extracts reply text using OpenAI ChatGPT */
  generateEmailSummary?: boolean;
  /** (deprecated, not used) */
  generateRiskAssessment?: boolean;
  /**
   * OpenAI API key
   * @example "verysecr8t"
   */
  openAiAPIKey?: string;
  /**
   * OpenAI API Model
   * @example "gpt-3.5-turbo"
   */
  openAiModel?: string;
  /**
   * OpenAI API URL
   * @example "https://api.openai.com"
   */
  openAiAPIUrl?: string;
  /**
   * OpenAI API Model for chat
   * @example "gpt-3.5-turbo"
   */
  documentStoreChatModel?: string;
  /**
   * OpenAI Temperature
   * @min 0
   * @max 2
   * @example 0.8
   */
  openAiTemperature?: number;
  /**
   * Top-p sampling
   * @min 0
   * @max 1
   * @example 0.1
   */
  openAiTopP?: number;
  /**
   * Prompt to send to LLM for analyzing emails
   * @maxLength 6144
   * @example "You are an assistant scanning incoming emails..."
   */
  openAiPrompt?: string;
  /** If true, then generates vector embeddings for the email */
  openAiGenerateEmbeddings?: boolean;
  /** If true, then send "New Email" webhooks for incoming emails only */
  inboxNewOnly?: boolean;
  /**
   * HMAC secret for signing public requests
   * @example "verysecr8t"
   */
  serviceSecret?: string;
  /**
   * URL to fetch authentication data from
   * @example "https://myservice.com/authentication"
   */
  authServer?: string;
  /** Is the global proxy enabled or not */
  proxyEnabled?: boolean;
  /**
   * Proxy URL
   * @example "socks://proxy.example.com:1080"
   */
  proxyUrl?: string;
  /**
   * Hostname to use for the SMTP EHLO/HELO greeting
   * @example "my.proxy.tld"
   */
  smtpEhloName?: string;
  /** Include message text in webhook notification */
  notifyText?: boolean;
  /** Pre-process HTML in webhook notification to be web safe */
  notifyWebSafeHtml?: boolean;
  /** @min 0 */
  notifyTextSize?: number;
  /** Include calendar events in webhook notification */
  notifyCalendarEvents?: boolean;
  /** If true then do not show Gmail account option (deprecated) */
  gmailEnabled?: boolean;
  /**
   * Gmail OAuth2 Client ID (deprecated)
   * @maxLength 256
   */
  gmailClientId?: string;
  /**
   * Gmail OAuth2 Client Secret (deprecated)
   * @maxLength 256
   */
  gmailClientSecret?: string;
  /** Gmail OAuth2 Callback URL (deprecated) */
  gmailRedirectUrl?: string;
  /** Gmail OAuth2 Extra Scopes (deprecated) */
  gmailExtraScopes?: GmailExtraScopes;
  /** If true then do not show Outlook account option (deprecated) */
  outlookEnabled?: boolean;
  /**
   * Outlook OAuth2 Client ID (deprecated)
   * @maxLength 256
   */
  outlookClientId?: string;
  /**
   * Outlook OAuth2 Client Secret (deprecated)
   * @maxLength 256
   */
  outlookClientSecret?: string;
  /** Outlook OAuth2 Callback URL (deprecated) */
  outlookRedirectUrl?: string;
  /** Outlook OAuth2 authority (deprecated) */
  outlookAuthority?: OutlookAuthority;
  /** Outlook OAuth2 Extra Scopes (deprecated) */
  outlookExtraScopes?: OutlookExtraScopes;
  /** If true then do not show Mail.ru account option (deprecated) */
  mailRuEnabled?: boolean;
  /**
   * Mail.ru OAuth2 Client ID (deprecated)
   * @maxLength 256
   */
  mailRuClientId?: string;
  /**
   * Mail.ru OAuth2 Client Secret (deprecated)
   * @maxLength 256
   */
  mailRuClientSecret?: string;
  /** Mail.ru OAuth2 Callback URL (deprecated) */
  mailRuRedirectUrl?: string;
  /** Mail.ru OAuth2 Extra Scopes (deprecated) */
  mailRuExtraScopes?: MailRuExtraScopes;
  /**
   * OAuth2 Service Client ID (deprecated)
   * @maxLength 256
   */
  serviceClient?: string;
  /**
   * OAuth2 Secret Service Key (deprecated)
   * @maxLength 102400
   */
  serviceKey?: string;
  /** OAuth2 Service Extra Scopes (deprecated) */
  serviceExtraScopes?: ServiceExtraScopes;
  logs?: LogSettings;
  /** How to select local IP address for IMAP connections */
  imapStrategy?: ImapStrategy;
  /** How to select local IP address for SMTP connections */
  smtpStrategy?: SmtpStrategy;
  /** A list of pooled local IP addresses that can be used for IMAP and SMTP connections */
  localAddresses?: LocalAddresses;
  /** Enable SMTP Interface */
  smtpServerEnabled?: boolean;
  /**
   * SMTP Interface Port
   * @min 0
   * @max 65536
   */
  smtpServerPort?: number;
  /** SMTP Host to bind to */
  smtpServerHost?: string;
  /** Enable PROXY Protocol for SMTP server */
  smtpServerProxy?: boolean;
  /** Enable SMTP authentication */
  smtpServerAuthEnabled?: boolean;
  /**
   * SMTP client password. Set to null to disable.
   * @maxLength 1024
   */
  smtpServerPassword?: string;
  /** Enable TLS for the SMTP interface. Requires a valid certificate. */
  smtpServerTLSEnabled?: boolean;
  /** Enable IMAP Proxy Interface */
  imapProxyServerEnabled?: boolean;
  /**
   * IMAP Proxy Interface Port
   * @min 0
   * @max 65536
   */
  imapProxyServerPort?: number;
  /** IMAP Proxy Host to bind to */
  imapProxyServerHost?: string;
  /** Enable PROXY Protocol for the IMAP proxy server */
  imapProxyServerProxy?: boolean;
  /**
   * IMAP proxy client password. Set to null to disable.
   * @maxLength 1024
   */
  imapProxyServerPassword?: string;
  /** Enable TLS for the IMAP proxy interface. Requires a valid certificate. */
  imapProxyServerTLSEnabled?: boolean;
  /**
   * How many completed or failed queue entries to keep
   * @min 0
   */
  queueKeep?: number;
  /**
   * How many times to retry an email sending before it is considered as failing
   * @min 0
   */
  deliveryAttempts?: number;
  /**
   * HTML code displayed on the top of public pages like the hosted authentication form
   * @maxLength 1048576
   */
  templateHeader?: string;
  /**
   * JSON object to be used as the `env` variable in pre-processing scripts
   * @maxLength 1048576
   * @example "{"key": "value"}"
   */
  scriptEnv?: string;
  /** Enable support for reverse proxies */
  enableApiProxy?: boolean;
  /** Enable Document Store syncing */
  documentStoreEnabled?: boolean;
  /**
   * Document Store URL
   * @example "https://localhost:9200"
   */
  documentStoreUrl?: string;
  /**
   * Document Store index name
   * @maxLength 1024
   */
  documentStoreIndex?: string;
  /** Enable Document Store authentication */
  documentStoreAuthEnabled?: boolean;
  /**
   * Document Store username
   * @maxLength 1024
   */
  documentStoreUsername?: string;
  /**
   * Document Store password
   * @maxLength 1024
   */
  documentStorePassword?: string;
  /** If true, then generates vector embeddings for the email and stores these in the Document Store */
  documentStoreGenerateEmbeddings?: boolean;
  /** Enable Document Store pre-processing */
  documentStorePreProcessingEnabled?: boolean;
  /** Default locale identifier */
  locale?: Locale;
  /**
   * Default timezone identifier
   * @maxLength 100
   * @example "Europe/Tallinn"
   */
  timezone?: string;
  /**
   * Filter function for LLM pre-processing (JavaScript)
   * @maxLength 524288
   * @example "return true; // passes all emails"
   */
  openAiPreProcessingFn?: string;
  /**
   * Filter function for Document Store pre-processing (JavaScript)
   * @maxLength 524288
   * @example "return true; // passes all emails"
   */
  documentStorePreProcessingFn?: string;
  /**
   * Mapping function for Document Store pre-processing (JavaScript)
   * @maxLength 524288
   * @example "return payload; // returns unmodified data"
   */
  documentStorePreProcessingMap?: string;
}

/** Counts of accounts in different connection states */
export interface ConnectionsStats {
  /**
   * Accounts not yet initialized
   * @example 2
   */
  init?: number;
  /**
   * Successfully connected accounts
   * @example 8
   */
  connected?: number;
  /**
   * Connection is being established
   * @example 7
   */
  connecting?: number;
  /**
   * Authentication failed
   * @example 3
   */
  authenticationError?: number;
  /**
   * Connection failed due to technical error
   * @example 5
   */
  connectError?: number;
  /**
   * Accounts without valid IMAP settings
   * @example 0
   */
  unset?: number;
  /**
   * IMAP connection was closed
   * @example 1
   */
  disconnected?: number;
}

export type CounterStats = object;

export interface SettingsResponse {
  /**
   * EmailEngine version number
   * @example "2.39.2"
   */
  version?: string;
  /**
   * EmailEngine license
   * @example "LICENSE_EMAILENGINE"
   */
  license?: string;
  /**
   * Number of registered accounts
   * @example 26
   */
  accounts?: number;
  /**
   * Node.js Version
   * @example "16.10.0"
   */
  node?: string;
  /**
   * Redis Version
   * @example "6.2.4"
   */
  redis?: string;
  /** Counts of accounts in different connection states */
  connections?: ConnectionsStats;
  counters?: CounterStats;
}

/**
 * Markup language for HTML ("html", "markdown" or "mjml")
 * @default "html"
 */
export enum Format {
  Html = 'html',
  Mjml = 'mjml',
  Markdown = 'markdown'
}

export interface AccountTemplate {
  /**
   * Template ID
   * @maxLength 256
   * @example "AAABgS-UcAYAAAABAA"
   */
  id: string;
  /**
   * Name of the template
   * @maxLength 256
   * @example "Transaction receipt"
   */
  name: string;
  /**
   * Optional description of the template
   * @maxLength 1024
   * @example "Something about the template"
   */
  description?: string;
  /** Markup language for HTML ("html", "markdown" or "mjml") */
  format?: Format;
  /**
   * The time this template was created
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  created?: string;
  /**
   * The time this template was last updated
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  updated?: string;
}

export type AccountTemplatesList = AccountTemplate[];

export interface AccountTemplatesResponse {
  /**
   * Account ID
   * @maxLength 256
   * @example "example"
   */
  account: string;
  /**
   * How many matching entries
   * @example 120
   */
  total?: number;
  /**
   * Current page (0-based index)
   * @example 0
   */
  page?: number;
  /**
   * Total page count
   * @example 24
   */
  pages?: number;
  templates?: AccountTemplatesList;
}

export interface RootTokensItem {
  /**
   * Account ID
   * @maxLength 256
   * @example "example"
   */
  account: string;
  /**
   * Token description
   * @maxLength 1024
   * @example "Token description"
   */
  description: string;
  /**
   * Related metadata in JSON format
   * @maxLength 1048576
   * @example "{"example": "value"}"
   */
  metadata?: string;
  /**
   * IP address of the requestor
   * @example "127.0.0.1"
   */
  ip?: string;
}

export type RootTokensEntries = RootTokensItem[];

export interface RootTokensResponse {
  tokens?: RootTokensEntries;
}

export interface WebhookRoutesListEntry {
  /**
   * Webhook ID
   * @maxLength 256
   * @example "AAABgS-UcAYAAAABAA"
   */
  id: string;
  /**
   * Name of the route
   * @maxLength 256
   * @example "Send to Slack"
   */
  name: string;
  /**
   * Optional description of the webhook route
   * @maxLength 1024
   * @example "Something about the route"
   */
  description?: string;
  /**
   * The time this route was created
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  created?: string;
  /**
   * The time this route was last updated
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  updated?: string;
  /**
   * Is the route enabled
   * @example true
   */
  enabled?: boolean;
  /**
   * Webhook URL
   * @example "https://myservice.com/imap/webhooks"
   */
  targetUrl?: string;
  /**
   * How many times this route has been applied
   * @example 123
   */
  tcount?: number;
}

export type WebhookRoutesList = WebhookRoutesListEntry[];

export interface WebhookRoutesListResponse {
  /**
   * How many matching entries
   * @example 120
   */
  total?: number;
  /**
   * Current page (0-based index)
   * @example 0
   */
  page?: number;
  /**
   * Total page count
   * @example 24
   */
  pages?: number;
  webhooks?: WebhookRoutesList;
}

/**
 * An array of mailbox paths. If set, then EmailEngine opens additional IMAP connections against these paths to detect changes faster. NB! connection counts are usually highly limited.
 * @example ["[Gmail]/Spam","\\Sent"]
 */
export type SubconnectionPaths = string[];

/**
 * Access token for OAuth2
 * @maxLength 16384
 * @example false
 */
export enum AccessToken {
  False = 'false'
}

/** Authentication info */
export enum Authentication {
  False = 'false'
}

/** Optional TLS configuration */
export interface TLS {
  /**
   * How to treat invalid certificates
   * @default true
   * @example true
   */
  rejectUnauthorized?: boolean;
  /**
   * Minimal TLS version
   * @maxLength 256
   * @example "TLSv1.2"
   */
  minVersion?: string;
}

/** IMAP configuration */
export interface IMAPResponse {
  /** Authentication info */
  auth?: Authentication;
  /**
   * Set to true to use authentication server instead of username/password
   * @example false
   */
  useAuthServer?: boolean;
  /**
   * Hostname to connect to
   * @example "imap.gmail.com"
   */
  host?: string;
  /**
   * Service port number
   * @min 1
   * @max 65536
   * @example 993
   */
  port?: number;
  /**
   * Should connection use TLS. Usually true for port 993
   * @default false
   * @example true
   */
  secure?: boolean;
  /** Optional TLS configuration */
  tls?: TLS;
  /**
   * Full resync delay in seconds
   * @default 900
   * @example 900
   */
  resyncDelay?: number;
  /**
   * Set to true to disable IMAP handling
   * @example false
   */
  disabled?: boolean;
  /**
   * Upload sent message to this folder. By default the account's Sent Mail folder is used. Set to `null` to unset.
   * @maxLength 1024
   * @example "Sent Mail"
   */
  sentMailPath?: string;
  /**
   * Folder for drafts. By default the account's Draft Mail folder is used. Set to `null` to unset.
   * @maxLength 1024
   * @example "Drafts"
   */
  draftsMailPath?: string;
  /**
   * Folder for spam. By default the account's Junk Mail folder is used. Set to `null` to unset.
   * @maxLength 1024
   * @example "Junk"
   */
  junkMailPath?: string;
  /**
   * Folder for deleted emails. By default the account's Trash folder is used. Set to `null` to unset.
   * @maxLength 1024
   * @example "Trash"
   */
  trashMailPath?: string;
  /**
   * Folder for archived emails. By default the account's Archive folder is used. Set to `null` to unset.
   * @maxLength 1024
   * @example "Archive"
   */
  archiveMailPath?: string;
}

/** SMTP configuration */
export interface SMTPResponse {
  /** Authentication info */
  auth?: Authentication;
  /**
   * Set to true to use authentication server instead of username/password
   * @example false
   */
  useAuthServer?: boolean;
  /**
   * Hostname to connect to
   * @example "smtp.gmail.com"
   */
  host: string;
  /**
   * Service port number
   * @min 1
   * @max 65536
   * @example 587
   */
  port: number;
  /**
   * Should connection use TLS. Usually true for port 465
   * @default false
   * @example false
   */
  secure?: boolean;
  /** Optional TLS configuration */
  tls?: TLS;
}

export interface Auth {
  /**
   * Account username
   * @maxLength 256
   * @example "user@oulook.com"
   */
  user: string;
  /**
   * Shared mailbox username (MS365 only)
   * @maxLength 256
   * @example "shared.mailbox@oulook.com"
   */
  delegatedUser?: string;
}

/** OAuth2 configuration */
export interface Oauth2Response {
  /**
   * Return a redirect link to the OAuth2 consent screen
   * @example false
   */
  authorize?: boolean;
  /**
   * The user will be redirected to this URL after returning from the OAuth2 consent screen (only valid if `authorize=true`
   * @example "https://myapp/account/settings.php"
   */
  redirectUrl?: string;
  /**
   * OAuth provider
   * @maxLength 256
   * @example "gmail"
   */
  provider?: string;
  auth?: Auth;
  /**
   * @maxLength 16384
   * @example "ya29.a0ARrdaM8a..."
   */
  accessToken?: string;
  /**
   * @maxLength 16384
   * @example "1//09Ie3CtORQYm..."
   */
  refreshToken?: string;
  /**
   * Outloook account type. Either 'consumers', 'organizations', 'common' or an organizartion ID
   * @maxLength 1024
   * @example "consumers"
   */
  authority?: string;
  /**
   * Token expiration date
   * @format date-time
   * @example "2021-03-22T13:13:31.000Z"
   */
  expires?: string;
}

/**
 * Informational account state
 * @example "connected"
 */
export enum AccountInfoState {
  Init = 'init',
  Syncing = 'syncing',
  Connecting = 'connecting',
  Connected = 'connected',
  AuthenticationError = 'authenticationError',
  ConnectError = 'connectError',
  Unset = 'unset',
  Disconnected = 'disconnected'
}

/** Information about the last SMTP connection attempt */
export type SMTPInfoStatus = object;

export interface AccountResponse {
  /**
   * Account ID
   * @maxLength 256
   * @example "example"
   */
  account: string;
  /**
   * Display name for the account
   * @maxLength 256
   * @example "My Email Account"
   */
  name: string;
  /**
   * Default email address of the account
   * @example "user@example.com"
   */
  email?: string;
  /**
   * Copy submitted messages to Sent folder
   * @example true
   */
  copy?: boolean;
  /**
   * Store recent logs
   * @example false
   */
  logs?: boolean;
  /**
   * Send webhooks for messages starting from provided date. The default is the account creation date.
   * @format date-time
   * @example "2021-07-08T07:06:34.336Z"
   */
  notifyFrom?: string;
  /**
   * Sync messages to document store starting from provided date. If not set, all emails are synced.
   * @format date-time
   * @example "2021-07-08T07:06:34.336Z"
   */
  syncFrom?: string;
  /**
   * Check changes only on selected path
   * @maxLength 1024
   * @default "*"
   * @example "INBOX"
   */
  path?: string;
  /** An array of mailbox paths. If set, then EmailEngine opens additional IMAP connections against these paths to detect changes faster. NB! connection counts are usually highly limited. */
  subconnections?: SubconnectionPaths;
  /**
   * Account-specific webhook URL
   * @example "https://myservice.com/imap/webhooks"
   */
  webhooks?: string;
  /**
   * Proxy URL
   * @example "socks://proxy.example.com:1080"
   */
  proxy?: string;
  /**
   * Hostname to use for the SMTP EHLO/HELO greeting
   * @example "my.proxy.tld"
   */
  smtpEhloName?: string;
  /** IMAP configuration */
  imap?: IMAPResponse;
  /** SMTP configuration */
  smtp?: SMTPResponse;
  /** OAuth2 configuration */
  oauth2?: Oauth2Response;
  /** Informational account state */
  state?: AccountInfoState;
  /** Information about the last SMTP connection attempt */
  smtpStatus?: SMTPInfoStatus;
  /**
   * Optional locale
   * @maxLength 100
   * @example "fr"
   */
  locale?: string;
  /**
   * Optional timezone
   * @maxLength 100
   * @example "Europe/Tallinn"
   */
  tz?: string;
  /** Account type */
  type: Type;
  /**
   * OAuth2 application ID
   * @maxLength 256
   * @example "AAABhaBPHscAAAAH"
   */
  app?: string;
  counters?: AccountCounters;
  /**
   * Last sync time
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  syncTime?: string;
  lastError?: AccountErrorEntry;
}

export interface BlocklistListResponseItem {
  /**
   * Listed email address
   * @example "user@example.com"
   */
  recipient: string;
  /**
   * Account ID
   * @maxLength 256
   * @example "example"
   */
  account: string;
  /**
   * Message ID
   * @example "<test123@example.com>"
   */
  messageId?: string;
  /**
   * Which mechanism was used to add the entry
   * @example "api"
   */
  source?: string;
  /**
   * Why this entry was added
   * @example "api"
   */
  reason?: string;
  /** Which IP address triggered the entry */
  remoteAddress?: string;
  /**
   * Which user agent triggered the entry
   * @example "Mozilla/5.0 (Macintosh)"
   */
  userAgent?: string;
  /**
   * The time this entry was added or updated
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  created: string;
}

export type BlocklistListEntries = BlocklistListResponseItem[];

export interface BlocklistListResponse {
  /**
   * List ID
   * @maxLength 256
   * @example "example"
   */
  listId: string;
  /**
   * How many matching entries
   * @example 120
   */
  total?: number;
  /**
   * Current page (0-based index)
   * @example 0
   */
  page?: number;
  /**
   * Total page count
   * @example 24
   */
  pages?: number;
  addresses?: BlocklistListEntries;
}

export interface GatewayResponse {
  /**
   * Gateway ID
   * @maxLength 256
   * @example "example"
   */
  gateway: string;
  /**
   * Display name for the gateway
   * @maxLength 256
   * @example "My Email Gateway"
   */
  name: string;
  /**
   * Count of email deliveries using this gateway
   * @example 100
   */
  deliveries?: number;
  /**
   * Last delivery time
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  lastUse?: string;
  /** @maxLength 1024 */
  user?: string;
  /** @maxLength 1024 */
  pass?: string;
  /**
   * Hostname to connect to
   * @example "smtp.gmail.com"
   */
  host?: string;
  /**
   * Service port number
   * @min 1
   * @max 65536
   * @example 465
   */
  port?: number;
  /**
   * Should connection use TLS. Usually true for port 465
   * @default false
   * @example true
   */
  secure?: boolean;
  lastError?: AccountErrorEntry;
}

export interface ApplicationResponse {
  /**
   * OAuth2 application ID
   * @maxLength 256
   * @example "AAABhaBPHscAAAAH"
   */
  id: string;
  /**
   * Display name for the app
   * @maxLength 256
   * @example "My OAuth2 App"
   */
  name?: string;
  /**
   * OAuth2 application description
   * @maxLength 1024
   * @example "App description"
   */
  description?: string;
  /**
   * Title for the application button
   * @maxLength 256
   * @example "App title"
   */
  title?: string;
  /** OAuth2 provider */
  provider: Provider;
  /**
   * Is the application enabled
   * @example true
   */
  enabled?: boolean;
  /**
   * `true` for older OAuth2 apps set via the settings endpoint
   * @example true
   */
  legacy?: boolean;
  /**
   * The time this entry was added
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  created: string;
  /**
   * The time this entry was updated
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  updated?: string;
  /**
   * Is the application listed in the hosted authentication form
   * @example true
   */
  includeInListing?: boolean;
  /**
   * Client or Application ID for 3-legged OAuth2 applications
   * @example "4f05f488-d858-4f2c-bd12-1039062612fe"
   */
  clientId?: string;
  /**
   * Client secret for 3-legged OAuth2 applications. Actual value is not revealed.
   * @example "******"
   */
  clientSecret?: string;
  /**
   * Authorization tenant value for Outlook OAuth2 applications
   * @example "common"
   */
  authority?: string;
  /**
   * Redirect URL for 3-legged OAuth2 applications
   * @example "https://myservice.com/oauth"
   */
  redirectUrl?: string;
  /**
   * Service client ID for 2-legged OAuth2 applications
   * @example "9103965568215821627203"
   */
  serviceClient?: string;
  /**
   * PEM formatted service secret for 2-legged OAuth2 applications. Actual value is not revealed.
   * @example "******"
   */
  serviceKey?: string;
  /**
   * The number of accounts registered with this application. Not available for legacy apps.
   * @example 12
   */
  accounts?: number;
  lastError?: AccountErrorEntry;
}

export interface AccountTokenResponse {
  /**
   * Account ID
   * @maxLength 256
   * @example "example"
   */
  account: string;
  /**
   * Username
   * @maxLength 256
   * @example "user@example.com"
   */
  user: string;
  /**
   * Access Token
   * @maxLength 256
   * @example "aGVsbG8gd29ybGQ="
   */
  accessToken: string;
  /**
   * OAuth2 provider
   * @maxLength 256
   * @example "gmail"
   */
  provider?: string;
}

/** @example {"name":"From Me","address":"sender@example.com"} */
export interface FromAddress {
  /**
   * @maxLength 256
   * @example "Some Name"
   */
  name?: string;
  /** @example "user@example.com" */
  address: string;
}

export interface Model1 {
  /**
   * @maxLength 256
   * @example "Some Name"
   */
  name?: string;
  /** @example "user@example.com" */
  address: string;
}

/**
 * List of addresses
 * @example [{"address":"recipient@example.com"}]
 */
export type AddressList = Model1[];

/** List of addresses */
export type Model2 = Model1[];

/** List of addresses */
export type Model3 = Model1[];

/** IMAP flags */
export type FlagList = string[];

/** Gmail labels */
export type LabelList = string[];

export interface Model4 {
  /**
   * Attachment ID
   * @example "AAAAAgAACrIyLjI"
   */
  id?: string;
  /**
   * Mime type of the attachment
   * @example "image/gif"
   */
  contentType?: string;
  /**
   * Encoded size of the attachment. Actual file size is usually smaller depending on the encoding
   * @example 48
   */
  encodedSize?: number;
  /**
   * Is this image used in HTML img tag
   * @example true
   */
  embedded?: boolean;
  /**
   * Should this file be included in the message preview somehow
   * @example true
   */
  inline?: boolean;
  /**
   * Usually used only for embedded images
   * @example "<unique-image-id@localhost>"
   */
  contentId?: string;
  /**
   * The file name of the attachment
   * @example "image.png"
   */
  filename?: string;
  /**
   * Calendar event method if this is an ical event attachment
   * @example "REQUEST"
   */
  method?: string;
}

/** List of attachments */
export type AttachmentList = Model4[];

/** Encoded message part sizes */
export interface EncodedSize {
  /**
   * How many bytes for plain text
   * @example 1013
   */
  plain?: number;
  /**
   * How many bytes for html content
   * @example 1013
   */
  html?: number;
}

export interface TextInfo {
  /**
   * Pointer to message text content
   * @example "AAAAAgAACqiTkaExkaEykA"
   */
  id?: string;
  /** Encoded message part sizes */
  encodedSize?: EncodedSize;
}

export interface MessageListEntry {
  /**
   * Message ID
   * @example "AAAAAgAACrI"
   */
  id?: string;
  /**
   * UID of the message
   * @example 12345
   */
  uid?: number;
  /**
   * Globally unique ID (if server supports it)
   * @example "1694937972638499881"
   */
  emailId?: string;
  /**
   * Thread ID (if server supports it)
   * @example "1694936993596975454"
   */
  threadId?: string;
  /**
   * Date (internal)
   * @format date-time
   * @example "2021-03-22T13:13:31.000Z"
   */
  date?: string;
  /**
   * Is this message marked as a draft
   * @example false
   */
  draft?: boolean;
  /**
   * Is this message unseen
   * @example true
   */
  unseen?: boolean;
  /**
   * Is this message marked as flagged
   * @example true
   */
  flagged?: boolean;
  /**
   * Message size in bytes
   * @example 1040
   */
  size?: number;
  /**
   * Message subject (decoded into unicode, applies to other string values as well)
   * @example "What a wonderful message"
   */
  subject?: string;
  from?: FromAddress;
  /** List of addresses */
  replyTo?: AddressList;
  /** List of addresses */
  to?: AddressList;
  /** List of addresses */
  cc?: Model2;
  /** List of addresses */
  bcc?: Model3;
  /**
   * Message ID
   * @example "<test123@example.com>"
   */
  messageId?: string;
  /**
   * Replied Message ID
   * @example "<7JBUMt0WOn+_==MOkaCOQ@mail.gmail.com>"
   */
  inReplyTo?: string;
  /** IMAP flags */
  flags?: FlagList;
  /** Gmail labels */
  labels?: LabelList;
  /** List of attachments */
  attachments?: AttachmentList;
  text?: TextInfo;
  /** Text preview for messages loaded from Document Store */
  preview?: string;
}

export type PageMessages = MessageListEntry[];

export interface MessageList {
  /**
   * How many matching entries
   * @example 120
   */
  total?: number;
  /**
   * Current page (0-based index)
   * @example 0
   */
  page?: number;
  /**
   * Total page count
   * @example 24
   */
  pages?: number;
  messages?: PageMessages;
}

/**
 * Special use flag of the mailbox
 * @example "\Sent"
 */
export enum MailboxSpecialUse {
  ValueAll = 'All',
  ValueArchive = 'Archive',
  ValueDrafts = 'Drafts',
  ValueFlagged = 'Flagged',
  ValueJunk = 'Junk',
  ValueSent = 'Sent',
  ValueTrash = 'Trash',
  ValueInbox = 'Inbox'
}

/**
 * Where did EmailEngine get the specialUse flag. The source could be `"user"` if it was set through an account creation or update API call, `"extension"` if it was provided by the email server, or `"name"` if EmailEngine determined it based on the folder's name.
 * @example "extension"
 */
export enum MailboxSpecialUseSource {
  User = 'user',
  Extension = 'extension',
  Name = 'name'
}

/** Optional counters info */
export interface MailboxResponseStatus {
  /**
   * Count of messages in mailbox as reported by the STATUS command
   * @example 120
   */
  messages?: number;
  /**
   * Count of unseen messages in mailbox as reported by the STATUS command
   * @example 120
   */
  unseen?: number;
}

export interface MailboxResponseItem {
  /**
   * Full path to mailbox
   * @example "Kalender/S&APw-nnip&AOQ-evad"
   */
  path: string;
  /** @example "/" */
  delimiter?: string;
  /**
   * Full path to parent mailbox
   * @example "Kalender"
   */
  parentPath: string;
  /**
   * Maibox name
   * @example "Sünnipäevad"
   */
  name: string;
  /**
   * Was the mailbox found from the output of LIST command
   * @example true
   */
  listed?: boolean;
  /**
   * Was the mailbox found from the output of LSUB command
   * @example true
   */
  subscribed?: boolean;
  /** Special use flag of the mailbox */
  specialUse?: MailboxSpecialUse;
  /** Where did EmailEngine get the specialUse flag. The source could be `"user"` if it was set through an account creation or update API call, `"extension"` if it was provided by the email server, or `"name"` if EmailEngine determined it based on the folder's name. */
  specialUseSource?: MailboxSpecialUseSource;
  /**
   * If true, then adding subfolders is forbidden
   * @example false
   */
  noInferiors?: boolean;
  /**
   * Count of messages in mailbox
   * @example 120
   */
  messages?: number;
  /**
   * Next expected UID
   * @example 121
   */
  uidNext?: number;
  /** Optional counters info */
  status?: MailboxResponseStatus;
}

export type Mailboxes = MailboxResponseItem[];

export interface MailboxesFilterResponse {
  mailboxes?: Mailboxes;
}

/** DKIM results */
export type Dkim = object;

/** SPF results */
export type Spf = object;

/** DMARC results */
export type Dmarc = object;

/** BIMI results */
export type Bimi = object;

/** ARC results */
export type Arc = object;

/** Primary DKIM signature. `status.aligned` should be set, otherwise DKIM check should not be considered as passed. */
export type MainSig = object;

export interface DeliveryCheckResponse {
  /**
   * Was the test completed
   * @example true
   */
  success?: boolean;
  /** DKIM results */
  dkim?: Dkim;
  /** SPF results */
  spf?: Spf;
  /** DMARC results */
  dmarc?: Dmarc;
  /** BIMI results */
  bimi?: Bimi;
  /** ARC results */
  arc?: Arc;
  /** Primary DKIM signature. `status.aligned` should be set, otherwise DKIM check should not be considered as passed. */
  mainSig?: MainSig;
}

/**
 * Queue ID
 * @example "notify"
 */
export enum Queue {
  Notify = 'notify',
  Submit = 'submit',
  Documents = 'documents'
}

export interface QueueJobs {
  /**
   * Jobs that are currently being processed
   * @example 123
   */
  active?: number;
  /**
   * Jobs that are processed in the future
   * @example 123
   */
  delayed?: number;
  /**
   * Jobs that would be processed once queue processing is resumed
   * @example 123
   */
  paused?: number;
  /**
   * Jobs that should be processed, but are waiting until there are any free handlers
   * @example 123
   */
  waiting?: number;
}

export interface SettingsQueueResponse {
  /** Queue ID */
  queue: Queue;
  jobs?: QueueJobs;
  /**
   * Is the queue paused or not
   * @example false
   */
  paused?: boolean;
}

export interface RequestTemplateContent {
  /**
   * Message subject
   * @maxLength 10240
   * @example "What a wonderful message"
   */
  subject?: string;
  /**
   * Message Text
   * @maxLength 5242880
   * @example "Hello from myself!"
   */
  text?: string;
  /**
   * Message HTML
   * @maxLength 5242880
   * @example "<p>Hello from myself!</p>"
   */
  html?: string;
  /**
   * Preview text appears in the inbox after the subject line
   * @maxLength 1024
   * @example "Welcome to our newsletter!"
   */
  previewText?: string;
  /** Markup language for HTML ("html", "markdown" or "mjml") */
  format?: Format;
}

export interface AccountTemplateResponse {
  /**
   * Account ID
   * @maxLength 256
   * @example "example"
   */
  account: string;
  /**
   * Template ID
   * @maxLength 256
   * @example "AAABgS-UcAYAAAABAA"
   */
  id: string;
  /**
   * Name of the template
   * @maxLength 256
   * @example "Transaction receipt"
   */
  name: string;
  /**
   * Optional description of the template
   * @maxLength 1024
   * @example "Something about the template"
   */
  description?: string;
  /** Markup language for HTML ("html", "markdown" or "mjml") */
  format?: Format;
  /**
   * The time this template was created
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  created?: string;
  /**
   * The time this template was last updated
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  updated?: string;
  content?: RequestTemplateContent;
}

/**
 * HTTP referrer allowlist for API requests
 * @default false
 * @example ["*web.domain.org/*","*.domain.org/*","https://domain.org/*"]
 */
export enum ReferrerAllowlist {
  False = 'false'
}

/**
 * IP address allowlist
 * @default false
 * @example ["1.2.3.4","5.6.7.8","127.0.0.0/8"]
 */
export enum AddressAllowlist {
  False = 'false'
}

/**
 * Rate limits for the token
 * @default false
 * @example {"maxRequests":20,"timeWindow":2}
 */
export enum AddressRateLimit {
  False = 'false'
}

/** Access restrictions */
export enum TokenRestrictions {
  False = 'false'
}

export interface AccountTokensItem {
  /**
   * Account ID
   * @maxLength 256
   * @example "example"
   */
  account: string;
  /**
   * Token description
   * @maxLength 1024
   * @example "Token description"
   */
  description: string;
  /**
   * Related metadata in JSON format
   * @maxLength 1048576
   * @example "{"example": "value"}"
   */
  metadata?: string;
  /** Access restrictions */
  restrictions?: TokenRestrictions;
  /**
   * IP address of the requestor
   * @example "127.0.0.1"
   */
  ip?: string;
}

export type AccountTokensEntries = AccountTokensItem[];

export interface AccountsTokensResponse {
  tokens?: AccountTokensEntries;
}

export interface WebhookRouteContent {
  /**
   * Filter function
   * @example "return true;"
   */
  fn?: string;
  /**
   * Mapping function
   * @example "payload.ts = Date.now(); return payload;"
   */
  map?: string;
}

export interface WebhookRouteResponse {
  /**
   * Webhook ID
   * @maxLength 256
   * @example "AAABgS-UcAYAAAABAA"
   */
  id: string;
  /**
   * Name of the route
   * @maxLength 256
   * @example "Send to Slack"
   */
  name: string;
  /**
   * Optional description of the webhook route
   * @maxLength 1024
   * @example "Something about the route"
   */
  description?: string;
  /**
   * The time this route was created
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  created?: string;
  /**
   * The time this route was last updated
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  updated?: string;
  /**
   * Is the route enabled
   * @example true
   */
  enabled?: boolean;
  /**
   * Webhook URL
   * @example "https://myservice.com/imap/webhooks"
   */
  targetUrl?: string;
  /**
   * How many times this route has been applied
   * @example 123
   */
  tcount?: number;
  content?: WebhookRouteContent;
}

/** List of addresses */
export type Model5 = Model1[];

/** List of addresses */
export type Model6 = Model1[];

/**
 * Object where header key is object key and value is an array
 * @example {"from":["From Me <sender@example.com>"],"subject":["What a wonderful message"]}
 */
export type MessageHeaders = object;

export interface Model7 {
  /**
   * Pointer to message text content. The value is `null` for messages retrieved from Document Store.
   * @example "AAAAAgAACqiTkaExkaEykA"
   */
  id?: string;
  /** Encoded message part sizes */
  encodedSize?: EncodedSize;
  /**
   * Plaintext content of the message
   * @example "Hello from myself!"
   */
  plain?: string;
  /**
   * HTML content of the message
   * @example "<p>Hello from myself!</p>"
   */
  html?: string;
  /**
   * If partial message content was requested then this value indicates if it includes all the content or there is more
   * @example false
   */
  hasMore?: boolean;
}

export interface BounceResponse {
  /** @example "550 5.1.1 No such user" */
  message?: string;
  /** @example "5.1.1" */
  status?: string;
}

export interface BounceEntry {
  /**
   * Bounce email ID
   * @maxLength 256
   * @example "AAAAAQAACnA"
   */
  message: string;
  /** @example "recipient@example.com" */
  recipient?: string;
  /** @example "failed" */
  action?: string;
  response?: BounceResponse;
  /**
   * Time the bounce was registered by EmailEngine
   * @format date-time
   * @example "2021-03-22T13:13:31.000Z"
   */
  date?: string;
}

export type BounceList = BounceEntry[];

/**
 * Special use flag of the message. Only relevant for messages listed from the All Mail folder in Gmail.
 * @example "\Sent"
 */
export namespace EmailEngineMessage {
  export enum MessageSpecialUse {
    ValueDrafts = 'Drafts',
    ValueJunk = 'Junk',
    ValueSent = 'Sent',
    ValueTrash = 'Trash',
    ValueInbox = 'Inbox'
  }
}

export interface Model8 {
  /**
   * Message ID
   * @example "AAAAAgAACrI"
   */
  id?: string;
  /**
   * UID of the message
   * @example 12345
   */
  uid?: number;
  /**
   * Globally unique ID (if server supports it)
   * @example "1694937972638499881"
   */
  emailId?: string;
  /**
   * Thread ID (if server supports it). Always set for messages retrieved from Document Store.
   * @example "1694936993596975454"
   */
  threadId?: string;
  /**
   * Date (internal)
   * @format date-time
   * @example "2021-03-22T13:13:31.000Z"
   */
  date?: string;
  /**
   * Is this message marked as a draft
   * @example false
   */
  draft?: boolean;
  /**
   * Is this message unseen
   * @example true
   */
  unseen?: boolean;
  /**
   * Is this message marked as flagged
   * @example true
   */
  flagged?: boolean;
  /**
   * Message size in bytes
   * @example 1040
   */
  size?: number;
  /**
   * Message subject (decoded into unicode, applies to other string values as well)
   * @example "What a wonderful message"
   */
  subject?: string;
  from?: FromAddress;
  sender?: FromAddress;
  /** List of addresses */
  to?: AddressList;
  /** List of addresses */
  cc?: Model5;
  /** List of addresses */
  bcc?: Model6;
  /** List of addresses */
  replyTo?: AddressList;
  /**
   * Message ID
   * @example "<test123@example.com>"
   */
  messageId?: string;
  /**
   * Replied Message ID
   * @example "<7JBUMt0WOn+_==MOkaCOQ@mail.gmail.com>"
   */
  inReplyTo?: string;
  /** IMAP flags */
  flags?: FlagList;
  /** Gmail labels */
  labels?: LabelList;
  /** List of attachments */
  attachments?: AttachmentList;
  /** Object where header key is object key and value is an array */
  headers?: MessageHeaders;
  text?: Model7;
  bounces?: BounceList;
  /**
   * True if this message was detected to be an autoreply email like the Out of Office notice
   * @example false
   */
  isAutoReply?: boolean;
  /** Special use flag of the mailbox */
  specialUse?: MailboxSpecialUse;
  /** Special use flag of the message. Only relevant for messages listed from the All Mail folder in Gmail. */
  messageSpecialUse?: MessageSpecialUse;
}

export interface TextResponse {
  /**
   * Plaintext content
   * @example "Hello world"
   */
  plain?: string;
  /**
   * HTML content
   * @example "<p>Hello world</p>"
   */
  html?: string;
  /**
   * Is the current text output capped or not
   * @example false
   */
  hasMore?: boolean;
}

/** IMAP configuration */
export enum ImapConfiguration {
  False = 'false'
}

/** SMTP configuration */
export enum SmtpConfiguration {
  False = 'false'
}

/** OAuth2 configuration */
export enum OAuth2 {
  False = 'false'
}

export interface CreateAccount {
  /**
   * Account ID. If the provided value is `null` then an unique ID will be autogenerated
   * @maxLength 256
   * @example "example"
   */
  account: string;
  /**
   * Display name for the account
   * @maxLength 256
   * @example "My Email Account"
   */
  name: string;
  /**
   * Default email address of the account
   * @example "user@example.com"
   */
  email?: string;
  /**
   * Check changes only on selected path
   * @maxLength 1024
   * @default "*"
   * @example "INBOX"
   */
  path?: string;
  /** An array of mailbox paths. If set, then EmailEngine opens additional IMAP connections against these paths to detect changes faster. NB! connection counts are usually highly limited. */
  subconnections?: SubconnectionPaths;
  /**
   * Account-specific webhook URL
   * @example "https://myservice.com/imap/webhooks"
   */
  webhooks?: string;
  /**
   * Copy submitted messages to Sent folder. Set to `null` to unset and use provider specific default.
   * @example null
   */
  copy?: boolean;
  /**
   * Store recent logs
   * @default false
   * @example false
   */
  logs?: boolean;
  /**
   * Send webhooks for messages starting from provided date. The default is the account creation date.
   * @format date-time
   * @default "now"
   * @example "2021-07-08T07:06:34.336Z"
   */
  notifyFrom?: string;
  /**
   * Sync messages to document store starting from provided date. If not set, all emails are synced.
   * @format date-time
   * @default null
   * @example "2021-07-08T07:06:34.336Z"
   */
  syncFrom?: string;
  /**
   * Proxy URL
   * @example "socks://proxy.example.com:1080"
   */
  proxy?: string;
  /**
   * Hostname to use for the SMTP EHLO/HELO greeting
   * @example "my.proxy.tld"
   */
  smtpEhloName?: string;
  /** IMAP configuration */
  imap?: ImapConfiguration;
  /** SMTP configuration */
  smtp?: SmtpConfiguration;
  /** OAuth2 configuration */
  oauth2?: OAuth2;
  /**
   * Optional locale
   * @maxLength 100
   * @example "fr"
   */
  locale?: string;
  /**
   * Optional timezone
   * @maxLength 100
   * @example "Europe/Tallinn"
   */
  tz?: string;
}

/**
 * Is the account new or updated existing
 * @example "new"
 */
export enum Model9 {
  Existing = 'existing',
  New = 'new'
}

export interface CreateAccountResponse {
  /**
   * Account ID
   * @maxLength 256
   * @example "example"
   */
  account: string;
  /** Is the account new or updated existing */
  state: Model9;
}

export interface CreateGateway {
  /**
   * Gateway ID
   * @maxLength 256
   * @default null
   * @example "sendgun"
   */
  gateway: string;
  /**
   * Account Name
   * @maxLength 256
   * @example "John Smith"
   */
  name: string;
  /**
   * @maxLength 1024
   * @default null
   */
  user?: string;
  /**
   * @maxLength 1024
   * @default null
   */
  pass?: string;
  /**
   * Hostname to connect to
   * @example "smtp.gmail.com"
   */
  host: string;
  /**
   * Service port number
   * @min 1
   * @max 65536
   * @example 465
   */
  port: number;
  /**
   * Should connection use TLS. Usually true for port 465
   * @default false
   * @example true
   */
  secure?: boolean;
}

/**
 * Is the gateway new or updated existing
 * @example "new"
 */
export enum Model10 {
  Existing = 'existing',
  New = 'new'
}

export interface CreateGatewayResponse {
  /**
   * Gateway ID
   * @maxLength 256
   * @example "example"
   */
  gateway: string;
  /** Is the gateway new or updated existing */
  state: Model10;
}

export interface RegisterLicense {
  /**
   * License file
   * @maxLength 10240
   * @example "-----BEGIN LICENSE-----
   * ..."
   */
  license: string;
}

/**
 * OAuth2 provider
 * @maxLength 256
 * @example "gmail"
 */
export enum Model11 {
  Gmail = 'gmail',
  GmailService = 'gmailService',
  Outlook = 'outlook',
  MailRu = 'mailRu'
}

/**
 * OAuth2 Base Scopes
 * @example "imap"
 */
export enum BaseScopes {
  Imap = 'imap'
}

export type ExtraScopes = string[];

export type SkipScopes = string[];

export interface CreateOAuth2App {
  /**
   * Application name
   * @maxLength 256
   * @example "My Gmail App"
   */
  name: string;
  /**
   * Application description
   * @maxLength 1024
   * @example "My cool app"
   */
  description?: string;
  /**
   * Title for the application button
   * @maxLength 256
   * @example "App title"
   */
  title?: string;
  /** OAuth2 provider */
  provider: Model11;
  /**
   * Enable this app
   * @default false
   * @example true
   */
  enabled?: boolean;
  /**
   * Client or Application ID for 3-legged OAuth2 applications
   * @maxLength 256
   * @example "52422112755-3uov8bjwlrullq122rdm6l8ui25ho7qf.apps.googleusercontent.com"
   */
  clientId?: string;
  /**
   * Client secret for 3-legged OAuth2 applications
   * @maxLength 256
   * @example "boT7Q~dUljnfFdVuqpC11g8nGMjO8kpRAv-ZB"
   */
  clientSecret?: string;
  /** OAuth2 Base Scopes */
  baseScopes?: BaseScopes;
  extraScopes?: ExtraScopes;
  skipScopes?: SkipScopes;
  /**
   * Service client ID for 2-legged OAuth2 applications
   * @maxLength 256
   * @example "7103296518315821565203"
   */
  serviceClient: string;
  /**
   * PEM formatted service secret for 2-legged OAuth2 applications
   * @maxLength 102400
   * @example "-----BEGIN PRIVATE KEY-----
   * MIIEvgIBADANBgk..."
   */
  serviceKey: string;
  /**
   * Authorization tenant value for Outlook OAuth2 applications
   * @maxLength 1024
   * @example "common"
   */
  authority: string;
  /**
   * Redirect URL for 3-legged OAuth2 applications
   * @example "https://myservice.com/oauth"
   */
  redirectUrl?: string;
}

export interface CreateAppResponse {
  /**
   * OAuth2 application ID
   * @maxLength 256
   * @example "AAABhaBPHscAAAAH"
   */
  id: string;
  /**
   * Was the app created
   * @default true
   */
  created?: boolean;
}

export interface Settings {
  /** If false then do not emit webhooks */
  webhooksEnabled?: boolean;
  /**
   * Webhook URL
   * @example "https://myservice.com/imap/webhooks"
   */
  webhooks?: string;
  webhookEvents?: WebhookEvents;
  webhooksCustomHeaders?: WebhooksCustomHeaders;
  notifyHeaders?: NotifyHeaders;
  /**
   * Base URL of EmailEngine
   * @example "https://emailengine.example.com"
   */
  serviceUrl?: string;
  /** If true, then rewrite html links in sent emails to track opens and clicks */
  trackSentMessages?: boolean;
  /** If true, then resolve the category tab for incoming emails */
  resolveGmailCategories?: boolean;
  /** If true, then allow insecure certificates for IMAP/SMTP */
  ignoreMailCertErrors?: boolean;
  /** If true, then extracts reply text using OpenAI ChatGPT */
  generateEmailSummary?: boolean;
  /** (deprecated, not used) */
  generateRiskAssessment?: boolean;
  /**
   * OpenAI API key
   * @example "verysecr8t"
   */
  openAiAPIKey?: string;
  /**
   * OpenAI API Model
   * @example "gpt-3.5-turbo"
   */
  openAiModel?: string;
  /**
   * OpenAI API URL
   * @example "https://api.openai.com"
   */
  openAiAPIUrl?: string;
  /**
   * OpenAI API Model for chat
   * @example "gpt-3.5-turbo"
   */
  documentStoreChatModel?: string;
  /**
   * OpenAI Temperature
   * @min 0
   * @max 2
   * @example 0.8
   */
  openAiTemperature?: number;
  /**
   * Top-p sampling
   * @min 0
   * @max 1
   * @example 0.1
   */
  openAiTopP?: number;
  /**
   * Prompt to send to LLM for analyzing emails
   * @maxLength 6144
   * @example "You are an assistant scanning incoming emails..."
   */
  openAiPrompt?: string;
  /** If true, then generates vector embeddings for the email */
  openAiGenerateEmbeddings?: boolean;
  /** If true, then send "New Email" webhooks for incoming emails only */
  inboxNewOnly?: boolean;
  /**
   * HMAC secret for signing public requests
   * @example "verysecr8t"
   */
  serviceSecret?: string;
  /**
   * URL to fetch authentication data from
   * @example "https://myservice.com/authentication"
   */
  authServer?: string;
  /** Is the global proxy enabled or not */
  proxyEnabled?: boolean;
  /**
   * Proxy URL
   * @example "socks://proxy.example.com:1080"
   */
  proxyUrl?: string;
  /**
   * Hostname to use for the SMTP EHLO/HELO greeting
   * @example "my.proxy.tld"
   */
  smtpEhloName?: string;
  /** Include message text in webhook notification */
  notifyText?: boolean;
  /** Pre-process HTML in webhook notification to be web safe */
  notifyWebSafeHtml?: boolean;
  /** @min 0 */
  notifyTextSize?: number;
  /** Include calendar events in webhook notification */
  notifyCalendarEvents?: boolean;
  /** If true then do not show Gmail account option (deprecated) */
  gmailEnabled?: boolean;
  /**
   * Gmail OAuth2 Client ID (deprecated)
   * @maxLength 256
   */
  gmailClientId?: string;
  /**
   * Gmail OAuth2 Client Secret (deprecated)
   * @maxLength 256
   */
  gmailClientSecret?: string;
  /** Gmail OAuth2 Callback URL (deprecated) */
  gmailRedirectUrl?: string;
  /** Gmail OAuth2 Extra Scopes (deprecated) */
  gmailExtraScopes?: GmailExtraScopes;
  /** If true then do not show Outlook account option (deprecated) */
  outlookEnabled?: boolean;
  /**
   * Outlook OAuth2 Client ID (deprecated)
   * @maxLength 256
   */
  outlookClientId?: string;
  /**
   * Outlook OAuth2 Client Secret (deprecated)
   * @maxLength 256
   */
  outlookClientSecret?: string;
  /** Outlook OAuth2 Callback URL (deprecated) */
  outlookRedirectUrl?: string;
  /** Outlook OAuth2 authority (deprecated) */
  outlookAuthority?: OutlookAuthority;
  /** Outlook OAuth2 Extra Scopes (deprecated) */
  outlookExtraScopes?: OutlookExtraScopes;
  /** If true then do not show Mail.ru account option (deprecated) */
  mailRuEnabled?: boolean;
  /**
   * Mail.ru OAuth2 Client ID (deprecated)
   * @maxLength 256
   */
  mailRuClientId?: string;
  /**
   * Mail.ru OAuth2 Client Secret (deprecated)
   * @maxLength 256
   */
  mailRuClientSecret?: string;
  /** Mail.ru OAuth2 Callback URL (deprecated) */
  mailRuRedirectUrl?: string;
  /** Mail.ru OAuth2 Extra Scopes (deprecated) */
  mailRuExtraScopes?: MailRuExtraScopes;
  /**
   * OAuth2 Service Client ID (deprecated)
   * @maxLength 256
   */
  serviceClient?: string;
  /**
   * OAuth2 Secret Service Key (deprecated)
   * @maxLength 102400
   */
  serviceKey?: string;
  /** OAuth2 Service Extra Scopes (deprecated) */
  serviceExtraScopes?: ServiceExtraScopes;
  logs?: LogSettings;
  /** How to select local IP address for IMAP connections */
  imapStrategy?: ImapStrategy;
  /** How to select local IP address for SMTP connections */
  smtpStrategy?: SmtpStrategy;
  /** A list of pooled local IP addresses that can be used for IMAP and SMTP connections */
  localAddresses?: LocalAddresses;
  /** Enable SMTP Interface */
  smtpServerEnabled?: boolean;
  /**
   * SMTP Interface Port
   * @min 0
   * @max 65536
   */
  smtpServerPort?: number;
  /** SMTP Host to bind to */
  smtpServerHost?: string;
  /** Enable PROXY Protocol for SMTP server */
  smtpServerProxy?: boolean;
  /** Enable SMTP authentication */
  smtpServerAuthEnabled?: boolean;
  /**
   * SMTP client password. Set to null to disable.
   * @maxLength 1024
   */
  smtpServerPassword?: string;
  /** Enable TLS for the SMTP interface. Requires a valid certificate. */
  smtpServerTLSEnabled?: boolean;
  /** Enable IMAP Proxy Interface */
  imapProxyServerEnabled?: boolean;
  /**
   * IMAP Proxy Interface Port
   * @min 0
   * @max 65536
   */
  imapProxyServerPort?: number;
  /** IMAP Proxy Host to bind to */
  imapProxyServerHost?: string;
  /** Enable PROXY Protocol for the IMAP proxy server */
  imapProxyServerProxy?: boolean;
  /**
   * IMAP proxy client password. Set to null to disable.
   * @maxLength 1024
   */
  imapProxyServerPassword?: string;
  /** Enable TLS for the IMAP proxy interface. Requires a valid certificate. */
  imapProxyServerTLSEnabled?: boolean;
  /**
   * How many completed or failed queue entries to keep
   * @min 0
   */
  queueKeep?: number;
  /**
   * How many times to retry an email sending before it is considered as failing
   * @min 0
   */
  deliveryAttempts?: number;
  /**
   * HTML code displayed on the top of public pages like the hosted authentication form
   * @maxLength 1048576
   */
  templateHeader?: string;
  /**
   * JSON object to be used as the `env` variable in pre-processing scripts
   * @maxLength 1048576
   * @example "{"key": "value"}"
   */
  scriptEnv?: string;
  /** Enable support for reverse proxies */
  enableApiProxy?: boolean;
  /** Enable Document Store syncing */
  documentStoreEnabled?: boolean;
  /**
   * Document Store URL
   * @example "https://localhost:9200"
   */
  documentStoreUrl?: string;
  /**
   * Document Store index name
   * @maxLength 1024
   */
  documentStoreIndex?: string;
  /** Enable Document Store authentication */
  documentStoreAuthEnabled?: boolean;
  /**
   * Document Store username
   * @maxLength 1024
   */
  documentStoreUsername?: string;
  /**
   * Document Store password
   * @maxLength 1024
   */
  documentStorePassword?: string;
  /** If true, then generates vector embeddings for the email and stores these in the Document Store */
  documentStoreGenerateEmbeddings?: boolean;
  /** Enable Document Store pre-processing */
  documentStorePreProcessingEnabled?: boolean;
  /** Default locale identifier */
  locale?: Locale;
  /**
   * Default timezone identifier
   * @maxLength 100
   * @example "Europe/Tallinn"
   */
  timezone?: string;
  /**
   * Filter function for LLM pre-processing (JavaScript)
   * @maxLength 524288
   * @example "return true; // passes all emails"
   */
  openAiPreProcessingFn?: string;
  /**
   * Filter function for Document Store pre-processing (JavaScript)
   * @maxLength 524288
   * @example "return true; // passes all emails"
   */
  documentStorePreProcessingFn?: string;
  /**
   * Mapping function for Document Store pre-processing (JavaScript)
   * @maxLength 524288
   * @example "return payload; // returns unmodified data"
   */
  documentStorePreProcessingMap?: string;
}

/** List of updated setting keys */
export type Updated = string[];

export interface Model12 {
  /** List of updated setting keys */
  updated?: Updated;
}

export enum Model13 {
  Api = 'api',
  Smtp = 'smtp',
  ImapProxy = 'imap-proxy'
}

/** @default ["api"] */
export type Scopes = Model13[];

export interface CreateToken {
  /**
   * Account ID
   * @maxLength 256
   * @example "example"
   */
  account: string;
  /**
   * Token description
   * @maxLength 1024
   * @example "Token description"
   */
  description: string;
  scopes: Scopes;
  /**
   * Related metadata in JSON format
   * @maxLength 1048576
   * @example "{"example": "value"}"
   */
  metadata?: string;
  /** Access restrictions */
  restrictions?: TokenRestrictions;
  /**
   * IP address of the requestor
   * @example "127.0.0.1"
   */
  ip?: string;
}

export interface CreateTokenResponse {
  /**
   * Access token
   * @example "123456"
   */
  token: string;
}

export interface VerifyAccount {
  /**
   * Include mailbox listing in response
   * @default false
   * @example false
   */
  mailboxes?: boolean;
  /** IMAP configuration */
  imap?: ImapConfiguration;
  /** SMTP configuration */
  smtp?: SmtpConfiguration;
  /**
   * Proxy URL
   * @example "socks://proxy.example.com:1080"
   */
  proxy?: string;
  /**
   * Hostname to use for the SMTP EHLO/HELO greeting
   * @example "my.proxy.tld"
   */
  smtpEhloName?: string;
}

export interface Imap {
  /**
   * Was IMAP account verified
   * @example true
   */
  success?: boolean;
  /**
   * Error messages for IMAP verification. Only present if success=false
   * @example "Something went wrong"
   */
  error?: string;
  /**
   * Error code. Only present if success=false
   * @example "ERR_SSL_WRONG_VERSION_NUMBER"
   */
  code?: string;
}

export interface Smtp {
  /**
   * Was SMTP account verified
   * @example true
   */
  success?: boolean;
  /**
   * Error messages for SMTP verification. Only present if success=false
   * @example "Something went wrong"
   */
  error?: string;
  /**
   * Error code. Only present if success=false
   * @example "ERR_SSL_WRONG_VERSION_NUMBER"
   */
  code?: string;
}

export interface Model14 {
  /**
   * Full path to mailbox
   * @example "Kalender/S&APw-nnip&AOQ-evad"
   */
  path: string;
  /** @example "/" */
  delimiter?: string;
  /**
   * Full path to parent mailbox
   * @example "Kalender"
   */
  parentPath: string;
  /**
   * Maibox name
   * @example "Sünnipäevad"
   */
  name: string;
  /**
   * Was the mailbox found from the output of LIST command
   * @example true
   */
  listed?: boolean;
  /**
   * Was the mailbox found from the output of LSUB command
   * @example true
   */
  subscribed?: boolean;
  /** Special use flag of the mailbox */
  specialUse?: MailboxSpecialUse;
}

export type Model15 = Model14[];

export interface VerifyAccountResponse {
  imap?: Imap;
  smtp?: Smtp;
  mailboxes?: Model15;
}

/**
 * Display the form for the specified account type (either "imap" or an OAuth2 app ID) instead of allowing the user to choose
 * @default false
 * @example "imap"
 */
export enum Model16 {
  False = 'false'
}

export interface RequestAuthForm {
  /**
   * Account ID. If the provided value is `null` then an unique ID will be autogenerated. Using an existing account ID will update settings for that existing account.
   * @maxLength 256
   * @default null
   * @example "example"
   */
  account?: string;
  /**
   * Display name for the account
   * @maxLength 256
   * @example "My Email Account"
   */
  name?: string;
  /**
   * Default email address of the account
   * @example "user@example.com"
   */
  email?: string;
  /**
   * If true then acts as a shared mailbox. Available for MS365 OAuth2 accounts.
   * @default false
   */
  delegated?: boolean;
  /**
   * Sync messages to document store starting from provided date. If not set, all emails are synced.
   * @format date-time
   * @example "2021-07-08T07:06:34.336Z"
   */
  syncFrom?: string;
  /**
   * Send webhooks for messages starting from provided date. The default is the account creation date.
   * @format date-time
   * @example "2021-07-08T07:06:34.336Z"
   */
  notifyFrom?: string;
  /** An array of mailbox paths. If set, then EmailEngine opens additional IMAP connections against these paths to detect changes faster. NB! connection counts are usually highly limited. */
  subconnections?: SubconnectionPaths;
  /**
   * The user will be redirected to this URL after submitting the authentication form
   * @example "https://myapp/account/settings.php"
   */
  redirectUrl: string;
  /** Display the form for the specified account type (either "imap" or an OAuth2 app ID) instead of allowing the user to choose */
  type?: Model16;
}

export interface RequestAuthFormResponse {
  /**
   * Generated URL to the hosted authentication form
   * @example "https://ee.example.com/accounts/new?data=eyJhY2NvdW50IjoiZXhh...L0W_BkFH5HW6Krwmr7c&type=imap"
   */
  url: string;
}

export interface BlocklistListAddPayload {
  /**
   * Account ID
   * @maxLength 256
   * @example "example"
   */
  account: string;
  /**
   * Email address to add to the list
   * @example "user@example.com"
   */
  recipient: string;
  /**
   * Identifier for the blocking reason
   * @default "block"
   */
  reason?: string;
}

export interface BlocklistListAddResponse {
  /**
   * Was the request successful
   * @example true
   */
  success?: boolean;
  /**
   * Was the address added to the list
   * @example true
   */
  added?: boolean;
}

export interface RequestChat {
  /**
   * Chat message to use
   * @maxLength 1024
   * @example "When did Jason last message me?"
   */
  question: string;
}

/**
 * The From address
 * @example {"name":"From Me","address":"sender@example.com"}
 */
export interface Model17 {
  /**
   * @maxLength 256
   * @example "Some Name"
   */
  name?: string;
  /** @example "user@example.com" */
  address: string;
}

/**
 * List of addresses
 * @example [{"address":"recipient@example.com"}]
 */
export type Model18 = Model1[];

/**
 * Special use flag of the message
 * @example "\Sent"
 */
export enum MessageSpecialUse {
  ValueDrafts = 'Drafts',
  ValueJunk = 'Junk',
  ValueSent = 'Sent',
  ValueTrash = 'Trash',
  ValueInbox = 'Inbox'
}

/** Email that best matched the question */
export interface ChatResponseMessage {
  /**
   * Message ID
   * @example "AAAAAgAACrI"
   */
  id?: string;
  /**
   * Folder this message was found from
   * @example "INBOX"
   */
  path?: string;
  /**
   * Date of the email
   * @format date-time
   * @example "2023-09-29T10:03:49.000Z"
   */
  date?: string;
  /** The From address */
  from?: Model17;
  /** List of addresses */
  to?: Model18;
  /**
   * Message subject
   * @example "What a wonderful message"
   */
  subject?: string;
  /** Special use flag of the message */
  messageSpecialUse?: MessageSpecialUse;
}

/** Emails that best matched the question */
export type ChatResponseMessages = ChatResponseMessage[];

export interface ReturnChatResponse {
  /**
   * Was the request successful
   * @example true
   */
  success?: boolean;
  /**
   * Chat response
   * @example "Last tuesday"
   */
  answer: string;
  /** Emails that best matched the question */
  messages?: ChatResponseMessages;
}

export interface CreateTemplateContent {
  /**
   * Message subject
   * @maxLength 10240
   * @example "What a wonderful message"
   */
  subject?: string;
  /**
   * Message Text
   * @maxLength 5242880
   * @example "Hello from myself!"
   */
  text?: string;
  /**
   * Message HTML
   * @maxLength 5242880
   * @example "<p>Hello from myself!</p>"
   */
  html?: string;
  /**
   * Preview text appears in the inbox after the subject line
   * @maxLength 1024
   * @example "Welcome to our newsletter!"
   */
  previewText?: string;
}

export interface CreateTemplate {
  /**
   * Account ID. Use `null` for public templates.
   * @maxLength 256
   * @example "example"
   */
  account: string;
  /**
   * Name of the template
   * @maxLength 256
   * @example "Transaction receipt"
   */
  name: string;
  /**
   * Optional description of the template
   * @maxLength 1024
   * @example "Something about the template"
   */
  description?: string;
  /** Markup language for HTML ("html", "markdown" or "mjml") */
  format?: Format;
  content: CreateTemplateContent;
}

export interface CreateTemplateResponse {
  /** Was the template created or not */
  created?: boolean;
  /**
   * Account ID
   * @maxLength 256
   * @example "example"
   */
  account: string;
  /**
   * Template ID
   * @maxLength 256
   * @example "example"
   */
  id: string;
}

/** Optional list of account ID values */
export type UnifiedSearchAccounts = string[];

/** Optional list of mailbox folder paths or specialUse flags */
export type UnifiedSearchPaths = string[];

/** Headers to match against */
export type Headers = object;

/** Search query to filter messages */
export interface SearchQuery {
  /**
   * Sequence number range. Not allowed with `documentStore`.
   * @maxLength 8192
   */
  seq?: string;
  /** Check if message is answered or not */
  answered?: boolean;
  /** Check if message is marked for being deleted or not */
  deleted?: boolean;
  /** Check if message is a draft */
  draft?: boolean;
  /** Check if message is marked as unseen or not */
  unseen?: boolean;
  /** Check if message is flagged or not */
  flagged?: boolean;
  /** Check if message is marked as seen or not */
  seen?: boolean;
  /**
   * Match From: header
   * @maxLength 256
   */
  from?: string;
  /**
   * Match To: header
   * @maxLength 256
   */
  to?: string;
  /**
   * Match Cc: header
   * @maxLength 256
   */
  cc?: string;
  /**
   * Match Bcc: header
   * @maxLength 256
   */
  bcc?: string;
  /**
   * Match text body
   * @maxLength 256
   */
  body?: string;
  /**
   * Match message subject
   * @maxLength 2560
   */
  subject?: string;
  /**
   * Matches messages larger than value
   * @min 0
   * @max 1073741824
   */
  larger?: number;
  /**
   * Matches messages smaller than value
   * @min 0
   * @max 1073741824
   */
  smaller?: number;
  /**
   * UID range
   * @maxLength 8192
   */
  uid?: string;
  /**
   * Matches messages with modseq higher than value. Not allowed with `documentStore`.
   * @min 0
   */
  modseq?: number;
  /**
   * Matches messages received before date
   * @format date
   */
  before?: string;
  /**
   * Matches messages received after date
   * @format date
   */
  since?: string;
  /**
   * Matches messages sent before date
   * @format date
   */
  sentBefore?: string;
  /**
   * Matches messages sent after date
   * @format date
   */
  sentSince?: string;
  /**
   * Match specific Gmail unique email UD
   * @maxLength 256
   */
  emailId?: string;
  /**
   * Match specific Gmail unique thread UD
   * @maxLength 256
   */
  threadId?: string;
  /** Headers to match against */
  header?: Headers;
  /**
   * Raw Gmail search string. Will return an error if used for other account types.
   * @maxLength 1024
   * @example "has:attachment in:unread"
   */
  gmailRaw?: string;
}

/** Document Store query */
export type DocumentQuery = object;

export interface UnifiedSearchQuery {
  /** Optional list of account ID values */
  accounts?: UnifiedSearchAccounts;
  /** Optional list of mailbox folder paths or specialUse flags */
  paths?: UnifiedSearchPaths;
  /** Search query to filter messages */
  search: SearchQuery;
  /** Document Store query */
  documentQuery?: DocumentQuery;
}

/**
 * @default "reply"
 * @example "reply"
 */
export enum Action {
  Forward = 'forward',
  Reply = 'reply'
}

/** Message reference for a reply or a forward. This is EmailEngine specific ID, not Message-ID header value. */
export interface MessageReference {
  /**
   * Referenced message ID
   * @maxLength 256
   * @example "AAAAAQAACnA"
   */
  message: string;
  action?: Action;
  /**
   * If true, then blockquotes the email that is being replied to
   * @default false
   */
  inline?: boolean;
  /**
   * If true, then includes attachments in forwarded message
   * @default false
   */
  forwardAttachments?: boolean;
  /**
   * If true, then processes the email even if the original message is not available anymore
   * @default false
   */
  ignoreMissing?: boolean;
  /**
   * If enabled then fetch the data from the Document Store instead of IMAP
   * @default false
   */
  documentStore?: boolean;
}

export type Model19 = string[];

/** Optional SMTP envelope. If not set then derived from message headers. */
export interface SMTPEnvelope {
  /** @example "sender@example.com" */
  from?: string;
  to?: Model19;
}

/**
 * The From address
 * @example {"name":"From Me","address":"sender@example.com"}
 */
export interface Model20 {
  /**
   * @maxLength 256
   * @example "Some Name"
   */
  name?: string;
  /** @example "user@example.com" */
  address: string;
}

export interface ReplyToAddress {
  /**
   * @maxLength 256
   * @example "Some Name"
   */
  name?: string;
  /** @example "user@example.com" */
  address: string;
}

/**
 * List of Reply-To addresses
 * @example [{"name":"From Me","address":"sender@example.com"}]
 */
export type ReplyTo = ReplyToAddress[];

export interface ToAddress {
  /**
   * @maxLength 256
   * @example "Some Name"
   */
  name?: string;
  /** @example "user@example.com" */
  address: string;
}

/**
 * List of recipient addresses
 * @example [{"address":"recipient@example.com"}]
 */
export type ToAddressList = ToAddress[];

export interface CcAddress {
  /**
   * @maxLength 256
   * @example "Some Name"
   */
  name?: string;
  /** @example "user@example.com" */
  address: string;
}

/** List of CC addresses */
export type CcAddressList = CcAddress[];

export interface BccAddress {
  /**
   * @maxLength 256
   * @example "Some Name"
   */
  name?: string;
  /** @example "user@example.com" */
  address: string;
}

/** List of BCC addresses */
export type BccAddressList = BccAddress[];

/** An object of variables for the template renderer */
export type RenderValues = object;

/** Template rendering options */
export enum Render {
  False = 'false'
}

export interface MailMergeListEntry {
  to: ToAddress;
  /**
   * Message ID
   * @maxLength 996
   * @example "<test123@example.com>"
   */
  messageId?: string;
  /** An object of variables for the template renderer */
  params?: RenderValues;
  /**
   * Send message at specified time. Overrides message level `sendAt` value.
   * @format date-time
   * @example "2021-07-08T07:06:34.336Z"
   */
  sendAt?: string;
}

/**
 * Mail merge options. A separate email is generated for each recipient. Using mail merge disables `messageId`, `envelope`, `to`, `cc`, `bcc`, `render` keys for the message root.
 * @minItems 1
 */
export type MailMergeList = MailMergeListEntry[];

export enum ContentDisposition {
  Inline = 'inline',
  Attachment = 'attachment'
}

/** @default "base64" */
export enum Encoding {
  Base64 = 'base64'
}

/**
 * Reference an existing attachment ID instead of providing attachment content. If set, then `content` option is not allowed. Otherwise `content` is required.
 * @maxLength 256
 * @example "AAAAAQAACnAcde"
 */
export enum Reference {
  False = 'false'
}

export interface UploadAttachment {
  /**
   * @maxLength 256
   * @example "transparent.gif"
   */
  filename?: string;
  /**
   * Base64 formatted attachment file
   * @maxLength 5242880
   * @example "R0lGODlhAQABAIAAAP///wAAACwAAAAAAQABAAACAkQBADs="
   */
  content: string;
  /**
   * @maxLength 256
   * @example "image/gif"
   */
  contentType?: string;
  contentDisposition?: ContentDisposition;
  /**
   * Content-ID value for embedded images
   * @maxLength 256
   * @example "unique-image-id@localhost"
   */
  cid?: string;
  encoding?: Encoding;
  /** Reference an existing attachment ID instead of providing attachment content. If set, then `content` option is not allowed. Otherwise `content` is required. */
  reference?: Reference;
}

/** List of attachments */
export type UploadAttachmentList = UploadAttachment[];

/** Custom Headers */
export type CustomHeaders = object;

/** Specifies if only headers or the entire body of the message should be included in the response (RET) */
export enum Return {
  Headers = 'headers',
  Full = 'full'
}

export enum NotifyEntry {
  Never = 'never',
  Success = 'success',
  Failure = 'failure',
  Delay = 'delay'
}

/** Defines the conditions under which a DSN response should be sent */
export type Notify = NotifyEntry[];

/** Request DNS notifications */
export interface DSN {
  /**
   * The envelope identifier that would be included in the response (ENVID)
   * @maxLength 256
   */
  id?: string;
  /** Specifies if only headers or the entire body of the message should be included in the response (RET) */
  return: Return;
  /** Defines the conditions under which a DSN response should be sent */
  notify?: Notify;
  /** The email address the DSN should be sent (ORCPT) */
  recipient?: string;
}

export interface SubmitMessage {
  /** Message reference for a reply or a forward. This is EmailEngine specific ID, not Message-ID header value. */
  reference?: MessageReference;
  /** Optional SMTP envelope. If not set then derived from message headers. */
  envelope?: SMTPEnvelope;
  /**
   * Base64 encoded email message in rfc822 format. If you provide other keys as well then these will override the values in the raw message.
   * @maxLength 5242880
   * @example "TUlNRS1WZXJzaW9uOiAxLjANClN1YmplY3Q6IGhlbGxvIHdvcmxkDQoNCkhlbGxvIQ0K"
   */
  raw?: string;
  /** The From address */
  from?: Model20;
  /** List of Reply-To addresses */
  replyTo?: ReplyTo;
  /** List of recipient addresses */
  to?: ToAddressList;
  /** List of CC addresses */
  cc?: CcAddressList;
  /** List of BCC addresses */
  bcc?: BccAddressList;
  /**
   * Message subject
   * @maxLength 10240
   * @example "What a wonderful message"
   */
  subject?: string;
  /**
   * Message Text
   * @maxLength 5242880
   * @example "Hello from myself!"
   */
  text?: string;
  /**
   * Message HTML
   * @maxLength 5242880
   * @example "<p>Hello from myself!</p>"
   */
  html?: string;
  /**
   * Preview text appears in the inbox after the subject line
   * @maxLength 1024
   * @example "Welcome to our newsletter!"
   */
  previewText?: string;
  /**
   * Stored template ID to load the email content from
   * @maxLength 256
   * @example "example"
   */
  template?: string;
  /** Template rendering options */
  render?: Render;
  /** Mail merge options. A separate email is generated for each recipient. Using mail merge disables `messageId`, `envelope`, `to`, `cc`, `bcc`, `render` keys for the message root. */
  mailMerge?: MailMergeList;
  /** List of attachments */
  attachments?: UploadAttachmentList;
  /**
   * Message ID
   * @maxLength 996
   * @example "<test123@example.com>"
   */
  messageId?: string;
  /** Custom Headers */
  headers?: CustomHeaders;
  /**
   * Should EmailEngine track clicks and opens for this message
   * @example false
   */
  trackingEnabled?: boolean;
  /**
   * If set then either copies the message to the Sent Mail folder or not. If not set then uses the account's default setting.
   * @example null
   */
  copy?: boolean;
  /**
   * Upload sent message to this folder. By default the account's Sent Mail folder is used.
   * @maxLength 1024
   * @example "Sent Mail"
   */
  sentMailPath?: string;
  /**
   * Optional locale
   * @maxLength 100
   * @example "fr"
   */
  locale?: string;
  /**
   * Optional timezone
   * @maxLength 100
   * @example "Europe/Tallinn"
   */
  tz?: string;
  /**
   * Send message at specified time
   * @format date-time
   * @example "2021-07-08T07:06:34.336Z"
   */
  sendAt?: string;
  /**
   * How many delivery attempts to make until message is considered as failed
   * @example 10
   */
  deliveryAttempts?: number;
  /**
   * Optional SMTP gateway ID for message routing
   * @maxLength 256
   * @example "example"
   */
  gateway?: string;
  /**
   * List ID for Mail Merge. Must use a subdomain name format. Lists are registered ad-hoc, so a new identifier defines a new list.
   * @example "test-list"
   */
  listId?: string;
  /** Request DNS notifications */
  dsn?: DSN;
  /**
   * Optional base URL for trackers. This URL must point to your EmailEngine instance.
   * @example "https://customer123.myservice.com"
   */
  baseUrl?: string;
  /**
   * If true, then EmailEngine does not send the email and returns an RFC822 formatted email file. Tracking information is not added to the email.
   * @default false
   */
  dryRun?: boolean;
}

/** Reference info if referencing was requested */
export interface ResponseReference {
  /**
   * Referenced message ID
   * @maxLength 256
   * @example "AAAAAQAACnA"
   */
  message: string;
  /**
   * Was the message dat aloaded from the document store
   * @example true
   */
  documentStore?: boolean;
  /**
   * Was the referenced message processed successfully
   * @example true
   */
  success?: boolean;
  /**
   * An error message if referenced message processing failed
   * @example "Referenced message was not found"
   */
  error?: string;
}

export interface ToAddressSingle {
  /**
   * @maxLength 256
   * @example "Some Name"
   */
  name?: string;
  /** @example "user@example.com" */
  address: string;
}

/** Info about skipped message. If this value is set, then the message was not sent */
export interface Skipped {
  /**
   * Why this message was skipped
   * @example "unsubscribe"
   */
  reason?: string;
  /** @example "test-list" */
  listId?: string;
}

/** @example {"success":true,"to":{"name":"Andris 2","address":"andris@ethereal.email"},"messageId":"<19b9c433-d428-f6d8-1d00-d666ebcadfc4@ekiri.ee>","queueId":"1812477338914c8372a","reference":{"message":"AAAAAQAACnA","success":true},"sendAt":"2021-07-08T07:06:34.336Z"} */
export interface BulkResponseEntry {
  /**
   * Was the referenced message processed successfully
   * @example true
   */
  success?: boolean;
  to?: ToAddressSingle;
  /**
   * Message ID
   * @maxLength 996
   * @example "<test123@example.com>"
   */
  messageId?: string;
  /**
   * Queue identifier for scheduled email. Not present for bulk messages.
   * @example "d41f0423195f271f"
   */
  queueId?: string;
  /** Reference info if referencing was requested */
  reference?: ResponseReference;
  /**
   * Send message at specified time. Overrides message level `sendAt` value.
   * @format date-time
   * @example "2021-07-08T07:06:34.336Z"
   */
  sendAt?: string;
  /** Info about skipped message. If this value is set, then the message was not sent */
  skipped?: Skipped;
}

/** Bulk message responses */
export type BulkResponseList = BulkResponseEntry[];

export interface SubmitMessageResponse {
  /** @example "Queued for delivery" */
  response?: string;
  /**
   * Message-ID header value. Not present for bulk messages.
   * @example "<a2184d08-a470-fec6-a493-fa211a3756e9@example.com>"
   */
  messageId?: string;
  /**
   * Queue identifier for scheduled email. Not present for bulk messages.
   * @example "d41f0423195f271f"
   */
  queueId?: string;
  /**
   * Scheduled send time
   * @format date
   * @example "2021-07-08T07:06:34.336Z"
   */
  sendAt?: string;
  /** Reference info if referencing was requested */
  reference?: ResponseReference;
  /**
   * Base64 encoded RFC822 email if a preview was requested
   * @example "Q29udGVudC1UeXBlOiBtdWx0aX..."
   */
  preview?: string;
  /** Bulk message responses */
  mailMerge?: BulkResponseList;
}

/** Document Store query. Only allowed with `documentStore`. */
export type Model21 = object;

export interface Model22 {
  /** Search query to filter messages */
  search: SearchQuery;
  /** Document Store query. Only allowed with `documentStore`. */
  documentQuery?: Model21;
}

/**
 * Mailbox path as an array or a string. If account is namespaced then namespace prefix is added by default.
 * @example ["Parent folder","Subfolder"]
 */
export type MailboxPath = string[];

export interface CreateMailbox {
  /** Mailbox path as an array or a string. If account is namespaced then namespace prefix is added by default. */
  path?: MailboxPath;
}

export interface CreateMailboxResponse {
  /**
   * Full path to mailbox
   * @example "Kalender/S&APw-nnip&AOQ-evad"
   */
  path: string;
  /**
   * Mailbox ID (if server has support)
   * @example "1439876283476"
   */
  mailboxId?: string;
  /**
   * Was the mailbox created
   * @example true
   */
  created?: boolean;
}

/**
 * Message flags
 * @example ["\\Seen","\\Draft"]
 */
export type Flags = string[];

/**
 * The From address
 * @example {"name":"From Me","address":"sender@example.com"}
 */
export interface Model23 {
  /**
   * @maxLength 256
   * @example "Some Name"
   */
  name?: string;
  /** @example "user@example.com" */
  address: string;
}

/**
 * List of addresses
 * @example [{"address":"recipient@example.com"}]
 */
export type Model24 = Model1[];

/** List of addresses */
export type Model25 = Model1[];

/** List of addresses */
export type Model26 = Model1[];

export interface MessageUpload {
  /**
   * Target mailbox folder path
   * @example "INBOX"
   */
  path: string;
  /** Message flags */
  flags?: Flags;
  /**
   * Sets the internal date for this message
   * @format date-time
   * @example "2021-07-08T07:06:34.336Z"
   */
  internalDate?: string;
  /** Message reference for a reply or a forward. This is EmailEngine specific ID, not Message-ID header value. */
  reference?: MessageReference;
  /**
   * Base64 encoded email message in rfc822 format. If you provide other keys as well then these will override the values in the raw message.
   * @maxLength 5242880
   * @example "TUlNRS1WZXJzaW9uOiAxLjANClN1YmplY3Q6IGhlbGxvIHdvcmxkDQoNCkhlbGxvIQ0K"
   */
  raw?: string;
  /** The From address */
  from?: Model23;
  /** List of addresses */
  to?: Model24;
  /** List of addresses */
  cc?: Model25;
  /** List of addresses */
  bcc?: Model26;
  /**
   * Message subject
   * @maxLength 10240
   * @example "What a wonderful message"
   */
  subject?: string;
  /**
   * Message Text
   * @maxLength 5242880
   * @example "Hello from myself!"
   */
  text?: string;
  /**
   * Message HTML
   * @maxLength 5242880
   * @example "<p>Hello from myself!</p>"
   */
  html?: string;
  /** List of attachments */
  attachments?: UploadAttachmentList;
  /**
   * Message ID
   * @maxLength 996
   * @example "<test123@example.com>"
   */
  messageId?: string;
  /** Custom Headers */
  headers?: CustomHeaders;
  /**
   * Optional locale
   * @maxLength 100
   * @example "fr"
   */
  locale?: string;
  /**
   * Optional timezone
   * @maxLength 100
   * @example "Europe/Tallinn"
   */
  tz?: string;
}

/** Reference info if referencing was requested */
export interface Model27 {
  /**
   * Referenced message ID
   * @maxLength 256
   * @example "AAAAAQAACnA"
   */
  message: string;
  /**
   * Was the referenced message processed
   * @example true
   */
  success?: boolean;
  /**
   * If enabled then fetch the data from the Document Store instead of IMAP
   * @default false
   */
  documentStore?: boolean;
  /**
   * An error message if referenced message processing failed
   * @example "Referenced message was not found"
   */
  error?: string;
}

export interface MessageUploadResponse {
  /**
   * Message ID. NB! This and other fields might not be present if server did not provide enough information
   * @example "AAAAAgAACrI"
   */
  id?: string;
  /**
   * Folder this message was uploaded to
   * @example "INBOX"
   */
  path?: string;
  /**
   * UID of uploaded message
   * @example 12345
   */
  uid?: number;
  /**
   * UIDVALIDTITY of the target folder. Numeric value cast as string.
   * @example "12345"
   */
  uidValidity?: string;
  /**
   * Sequence number of uploaded message
   * @example 12345
   */
  seq?: number;
  /**
   * Message ID
   * @maxLength 996
   * @example "<test123@example.com>"
   */
  messageId?: string;
  /** Reference info if referencing was requested */
  reference?: Model27;
}

/**
 * Optional gateway ID
 * @maxLength 256
 * @example false
 */
export enum Gateway {
  False = 'false'
}

export interface DeliveryStartRequest {
  /** Optional gateway ID */
  gateway?: Gateway;
}

export interface DeliveryStartResponse {
  /**
   * Was the test started
   * @example true
   */
  success?: boolean;
  /**
   * Test ID
   * @example "6420a6ad-7f82-4e4f-8112-82a9dad1f34d"
   */
  deliveryTest?: string;
}

export interface EmptyLicenseResponse {
  /** @example false */
  active?: boolean;
  /** @example false */
  details?: boolean;
  /** @example "SSPL-1.0-or-later" */
  type?: string;
}

export interface DeleteRequestResponse {
  /**
   * Account ID
   * @maxLength 256
   * @example "example"
   */
  account: string;
  /**
   * Was the account deleted
   * @default true
   */
  deleted?: boolean;
}

export interface DeleteBlocklistResponse {
  /**
   * Was the address removed from the list
   * @default true
   */
  deleted?: boolean;
}

export interface Model28 {
  /**
   * Gateway ID
   * @maxLength 256
   * @example "example"
   */
  gateway: string;
  /**
   * Was the gateway deleted
   * @default true
   */
  deleted?: boolean;
}

export interface DeleteAppRequestResponse {
  /**
   * OAuth2 application ID
   * @maxLength 256
   * @example "AAABhaBPHscAAAAH"
   */
  id: string;
  /**
   * Was the gateway deleted
   * @default true
   */
  deleted?: boolean;
  /**
   * The number of accounts registered with this application. Not available for legacy apps.
   * @example 12
   */
  accounts?: number;
}

export interface DeleteOutboxEntryResponse {
  /**
   * Was the message deleted
   * @default true
   */
  deleted?: boolean;
}

export interface DeleteTokenRequestResponse {
  /**
   * Was the account deleted
   * @default true
   */
  deleted?: boolean;
}

export interface DeleteMailboxResponse {
  /**
   * Full path to mailbox
   * @example "Kalender/S&APw-nnip&AOQ-evad"
   */
  path: string;
  /**
   * Was the mailbox deleted
   * @example true
   */
  deleted?: boolean;
}

export interface DeleteTemplateRequestResponse {
  /**
   * Was the account flushed
   * @default true
   */
  deleted?: boolean;
  /**
   * Account ID
   * @maxLength 256
   * @example "example"
   */
  account: string;
}

export interface Model29 {
  /**
   * Was the template deleted
   * @default true
   */
  deleted?: boolean;
  /**
   * Account ID
   * @maxLength 256
   * @example "example"
   */
  account: string;
  /**
   * Template ID
   * @maxLength 256
   * @example "AAABgS-UcAYAAAABAA"
   */
  id: string;
}

/** Present if message was moved to Trash */
export interface Moved {
  /**
   * Trash folder path
   * @example "Trash"
   */
  destination: string;
  /**
   * Message ID in Trash
   * @example "AAAAAwAAAWg"
   */
  message: string;
}

export interface MessageDeleteResponse {
  /**
   * Was the delete action executed
   * @example false
   */
  deleted?: boolean;
  /** Present if message was moved to Trash */
  moved?: Moved;
}

/** Optional TLS configuration */
export interface Model30 {
  /**
   * How to treat invalid certificates
   * @example true
   */
  rejectUnauthorized?: boolean;
  /**
   * Minimal TLS version
   * @maxLength 256
   * @example "TLSv1.2"
   */
  minVersion?: string;
}

/** IMAP configuration */
export enum IMAPUpdate {
  False = 'false'
}

/** Optional TLS configuration */
export interface Model31 {
  /**
   * How to treat invalid certificates
   * @example true
   */
  rejectUnauthorized?: boolean;
  /**
   * Minimal TLS version
   * @maxLength 256
   * @example "TLSv1.2"
   */
  minVersion?: string;
}

/** SMTP configuration */
export enum SMTPUpdate {
  False = 'false'
}

/** OAuth2 configuration */
export enum OAuth2Update {
  False = 'false'
}

export interface UpdateAccount {
  /**
   * Display name for the account
   * @maxLength 256
   * @example "My Email Account"
   */
  name?: string;
  /**
   * Default email address of the account
   * @example "user@example.com"
   */
  email?: string;
  /**
   * Check changes only on selected path
   * @maxLength 1024
   * @default "*"
   * @example "INBOX"
   */
  path?: string;
  /** An array of mailbox paths. If set, then EmailEngine opens additional IMAP connections against these paths to detect changes faster. NB! connection counts are usually highly limited. */
  subconnections?: SubconnectionPaths;
  /**
   * Account-specific webhook URL
   * @example "https://myservice.com/imap/webhooks"
   */
  webhooks?: string;
  /**
   * Copy submitted messages to Sent folder. Set to `null` to unset and use provider specific default.
   * @example null
   */
  copy?: boolean;
  /**
   * Store recent logs
   * @example false
   */
  logs?: boolean;
  /**
   * Send webhooks for messages starting from provided date. The default is the account creation date.
   * @format date-time
   * @example "2021-07-08T07:06:34.336Z"
   */
  notifyFrom?: string;
  /**
   * Sync messages to document store starting from provided date. If not set, all emails are synced.
   * @format date-time
   * @example "2021-07-08T07:06:34.336Z"
   */
  syncFrom?: string;
  /**
   * Proxy URL
   * @example "socks://proxy.example.com:1080"
   */
  proxy?: string;
  /**
   * Hostname to use for the SMTP EHLO/HELO greeting
   * @example "my.proxy.tld"
   */
  smtpEhloName?: string;
  /** IMAP configuration */
  imap?: IMAPUpdate;
  /** SMTP configuration */
  smtp?: SMTPUpdate;
  /** OAuth2 configuration */
  oauth2?: OAuth2Update;
  /**
   * Optional locale
   * @maxLength 100
   * @example "fr"
   */
  locale?: string;
  /**
   * Optional timezone
   * @maxLength 100
   * @example "Europe/Tallinn"
   */
  tz?: string;
}

export interface Model32 {
  /**
   * Account ID
   * @maxLength 256
   * @example "example"
   */
  account: string;
}

/**
 * Client or Application ID for 3-legged OAuth2 applications
 * @maxLength 256
 * @example "52422112755-3uov8bjwlrullq122rdm6l8ui25ho7qf.apps.googleusercontent.com"
 */
export enum ClientId {
  False = 'false'
}

/** OAuth2 Extra Scopes */
export type Model33 = string[];

/** OAuth2 scopes to skip from the base set */
export type Model34 = string[];

/**
 * Service client ID for 2-legged OAuth2 applications
 * @maxLength 256
 * @example "7103296518315821565203"
 */
export enum ServiceClient {
  False = 'false'
}

/**
 * Redirect URL for 3-legged OAuth2 applications
 * @example "https://myservice.com/oauth"
 */
export enum RedirectUrl {
  False = 'false'
}

export interface UpdateGateway {
  /**
   * Application name
   * @maxLength 256
   * @example "My Gmail App"
   */
  name: string;
  /**
   * Application description
   * @maxLength 1024
   * @example "My cool app"
   */
  description?: string;
  /**
   * Title for the application button
   * @maxLength 256
   * @example "App title"
   */
  title?: string;
  /**
   * Enable this app
   * @example true
   */
  enabled?: boolean;
  /** Client or Application ID for 3-legged OAuth2 applications */
  clientId?: ClientId;
  /**
   * Client secret for 3-legged OAuth2 applications
   * @maxLength 256
   * @example "boT7Q~dUljnfFdVuqpC11g8nGMjO8kpRAv-ZB"
   */
  clientSecret?: string;
  /** OAuth2 Base Scopes */
  baseScopes?: BaseScopes;
  /** OAuth2 Extra Scopes */
  extraScopes?: Model33;
  /** OAuth2 scopes to skip from the base set */
  skipScopes?: Model34;
  /** Service client ID for 2-legged OAuth2 applications */
  serviceClient?: ServiceClient;
  /**
   * PEM formatted service secret for 2-legged OAuth2 applications
   * @maxLength 102400
   * @example "-----BEGIN PRIVATE KEY-----
   * MIIEvgIBADANBgk..."
   */
  serviceKey?: string;
  /**
   * Authorization tenant value for Outlook OAuth2 applications
   * @maxLength 1024
   * @example "common"
   */
  authority?: string;
  /** Redirect URL for 3-legged OAuth2 applications */
  redirectUrl?: RedirectUrl;
}

export interface UpdateGatewayResponse {
  /**
   * Gateway ID
   * @maxLength 256
   * @example "example"
   */
  gateway: string;
}

export interface RequestFlush {
  /**
   * Only flush the account if true
   * @default false
   */
  flush?: boolean;
  /**
   * Send webhooks for messages starting from provided date. The default is the account creation date.
   * @format date-time
   * @default "now"
   * @example "2021-07-08T07:06:34.336Z"
   */
  notifyFrom?: string;
  /**
   * Sync messages to document store starting from provided date. If not set, all emails are synced.
   * @format date-time
   * @example "2021-07-08T07:06:34.336Z"
   */
  syncFrom?: string;
}

export interface RequestFlushResponse {
  /**
   * Flush status
   * @default false
   */
  flush?: boolean;
}

export interface RequestReconnect {
  /**
   * Only reconnect if true
   * @default false
   */
  reconnect?: boolean;
}

export interface RequestReconnectResponse {
  /**
   * Reconnection status
   * @default false
   */
  reconnect?: boolean;
}

export interface RequestSync {
  /**
   * Only sync if true
   * @default false
   */
  sync?: boolean;
}

export interface RequestSyncResponse {
  /**
   * Sync status
   * @default false
   */
  sync?: boolean;
}

/**
 * New mailbox path as an array or a string. If account is namespaced then namespace prefix is added by default.
 * @example ["Parent folder","Subfolder"]
 */
export type TargetMailboxPath = string[];

export interface RenameMailbox {
  /**
   * Mailbox folder path to rename
   * @example "Previous Mail"
   */
  path: string;
  /** New mailbox path as an array or a string. If account is namespaced then namespace prefix is added by default. */
  newPath?: TargetMailboxPath;
}

export interface RenameMailboxResponse {
  /**
   * Mailbox folder path to rename
   * @example "Previous Mail"
   */
  path: string;
  /**
   * Full path to mailbox
   * @example "Kalender/S&APw-nnip&AOQ-evad"
   */
  newPath: string;
  /**
   * Was the mailbox renamed
   * @example true
   */
  renamed?: boolean;
}

/**
 * Add new flags
 * @example ["\\Seen"]
 */
export type AddFlags = string[];

/**
 * Delete specific flags
 * @example ["\\Flagged"]
 */
export type DeleteFlags = string[];

/**
 * Override all flags
 * @example ["\\Seen","\\Flagged"]
 */
export type SetFlags = string[];

/** Flag updates */
export interface FlagUpdate {
  /** Add new flags */
  add?: AddFlags;
  /** Delete specific flags */
  delete?: DeleteFlags;
  /** Override all flags */
  set?: SetFlags;
}

/**
 * Add new labels
 * @example ["Some label"]
 */
export type AddLabels = string[];

/**
 * Delete specific labels
 * @example ["Some label"]
 */
export type DeleteLabels = string[];

/**
 * Override all labels
 * @example ["First label","Second label"]
 */
export type SetLabels = string[];

/** Label updates */
export interface LabelUpdate {
  /** Add new labels */
  add?: AddLabels;
  /** Delete specific labels */
  delete?: DeleteLabels;
  /** Override all labels */
  set?: SetLabels;
}

export interface MessageUpdate {
  /** Flag updates */
  flags?: FlagUpdate;
  /** Label updates */
  labels?: LabelUpdate;
}

export interface MessagesUpdateRequest {
  /** Search query to filter messages */
  search: SearchQuery;
  update?: MessageUpdate;
}

export interface FlagResponse {
  /** @example true */
  add?: boolean;
  /** @example false */
  delete?: boolean;
  /** @example false */
  set?: boolean;
}

export interface MessageUpdateResponse {
  flags?: FlagResponse;
  labels?: FlagResponse;
}

export interface Model35 {
  /**
   * Account Name
   * @maxLength 256
   * @example "John Smith"
   */
  name?: string;
  /** @maxLength 1024 */
  user?: string;
  /** @maxLength 1024 */
  pass?: string;
  /**
   * Hostname to connect to
   * @example "smtp.gmail.com"
   */
  host?: string;
  /**
   * Service port number
   * @min 1
   * @max 65536
   * @example 465
   */
  port?: number;
  /**
   * Should connection use TLS. Usually true for port 465
   * @example true
   */
  secure?: boolean;
}

export interface SettingsPutQueuePayload {
  /**
   * Set queue state to paused
   * @example false
   */
  paused?: boolean;
}

export interface SettingsPutQueueResponse {
  /** Queue ID */
  queue: Queue;
  /**
   * Is the queue paused or not
   * @example false
   */
  paused?: boolean;
}

export interface UpdateTemplateContent {
  /**
   * Message subject
   * @maxLength 10240
   * @example "What a wonderful message"
   */
  subject?: string;
  /**
   * Message Text
   * @maxLength 5242880
   * @example "Hello from myself!"
   */
  text?: string;
  /**
   * Message HTML
   * @maxLength 5242880
   * @example "<p>Hello from myself!</p>"
   */
  html?: string;
  /**
   * Preview text appears in the inbox after the subject line
   * @maxLength 1024
   * @example "Welcome to our newsletter!"
   */
  previewText?: string;
}

export interface UpdateTemplate {
  /**
   * Name of the template
   * @maxLength 256
   * @example "Transaction receipt"
   */
  name?: string;
  /**
   * Optional description of the template
   * @maxLength 1024
   * @example "Something about the template"
   */
  description?: string;
  /** Markup language for HTML ("html", "markdown" or "mjml") */
  format?: Format;
  content?: UpdateTemplateContent;
}

export interface UpdateTemplateResponse {
  /** Was the template updated or not */
  updated?: boolean;
  /**
   * Account ID
   * @maxLength 256
   * @example "example"
   */
  account: string;
  /**
   * Template ID
   * @maxLength 256
   * @example "example"
   */
  id: string;
}

export interface MessagesDeleteRequest {
  /** Search query to filter messages */
  search: SearchQuery;
}

export type Model36 = string[];

/**
 * An optional map of source and target ID values, if the server provided this info
 * @example [["AAAAAQAACnA","AAAAAwAAAD4"]]
 */
export type IdMap = Model36[];

/** Present if messages were moved to Trash */
export interface Model37 {
  /**
   * Trash folder path
   * @example "Trash"
   */
  destination: string;
  /** An optional map of source and target ID values, if the server provided this info */
  idMap?: IdMap;
}

export interface MessagesDeleteResponse {
  /**
   * Was the delete action executed
   * @example false
   */
  deleted?: boolean;
  /** Present if messages were moved to Trash */
  moved?: Model37;
}

export interface MessagesMoveRequest {
  /** Search query to filter messages */
  search: SearchQuery;
  /**
   * Target mailbox folder path
   * @example "INBOX"
   */
  path: string;
}

export type Model38 = string[];

/**
 * An optional map of source and target ID values, if the server provided this info
 * @example [["AAAAAQAACnA","AAAAAwAAAD4"]]
 */
export type Model39 = Model38[];

export interface MessagesMoveResponse {
  /**
   * Target mailbox folder path
   * @example "INBOX"
   */
  path: string;
  /** An optional map of source and target ID values, if the server provided this info */
  idMap?: Model39;
}

export interface MessageMove {
  /**
   * Target mailbox folder path
   * @example "INBOX"
   */
  path: string;
}

export interface MessageMoveResponse {
  /**
   * Target mailbox folder path
   * @example "INBOX"
   */
  path: string;
  /**
   * Message ID
   * @maxLength 256
   * @example "AAAAAQAACnA"
   */
  id: string;
  /**
   * UID of moved message
   * @example 12345
   */
  uid?: number;
}
