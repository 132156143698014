import {
  Model8 as Message,
  MessageReference,
  SubmitMessage,
  Action,
  ToAddressList,
  CcAddressList,
  UploadAttachment
} from 'services/emailEngineApi/data-contracts';
import { NewMessageMode } from 'store/messageStore';
import { ACTION } from '../constants';
import { Attachment } from '../NewMessage';
import { transformDate } from '../components/AnsweredMessage/helpers/transformDate';

export type NewMessageFormData = {
  to: string[];
  copy?: string[];
  subject: string;
  body?: string;
};

const replaceNewLinesWithBreaks = (input: string) => {
  return input.replace(/\r?\n/g, '<br />');
};

const removeStyleAttributes = (html: string) => {
  return html.replace(/ style="[^"]*"/g, '');
};

export const convertPlainTextToHtml = (
  body: string,
  newMessageMode: NewMessageMode,
  currentMessage?: Message
) => {
  let text = replaceNewLinesWithBreaks(body);

  // since we handle ourself add and delete of attachments for forward, we need to handle also forwarded text
  if (newMessageMode === ACTION.FORWARD && currentMessage && currentMessage?.text?.html) {
    const name = currentMessage?.from?.name ?? '';
    const address = currentMessage?.from?.address ?? '';
    const subject = currentMessage?.subject ?? '';
    const createdAt = transformDate(currentMessage?.date as string);
    const referenceMessageHtml = removeStyleAttributes(currentMessage?.text?.html);
    let toAddress = '';
    if (currentMessage.to) {
      toAddress = currentMessage.to
        .map((email, index, arr) => `${email.address}${index < arr.length - 1 ? ', ' : ''}`)
        .join('');
    }

    text += `<br><br> &gt; Begin forwarded message:<br>&gt;
    From: ${name}&lt;${address}&gt;<br>
    Subject: ${subject}<br>
    Date: ${createdAt}<br>
    To: ${toAddress}<br><br>   
    ${referenceMessageHtml}<br>`;
  }

  return text;
};

export const transformForRequest = (
  formData: NewMessageFormData,
  files: Attachment[],
  newMessageMode: NewMessageMode,
  currentMessage?: Message
): SubmitMessage => {
  const toAddressList: ToAddressList = formData.to.map((email) => ({ address: email }));
  const ccAddressList: CcAddressList | undefined = formData.copy?.map((email) => ({
    address: email
  }));
  const body = convertPlainTextToHtml(formData.body || '', newMessageMode, currentMessage);

  const newMessageData = {
    to: toAddressList,
    subject: formData.subject
  };

  const filesData = files.map((file) => ({
    contentType: file.contentType,
    filename: file.name,
    ...(file.content ? { content: file.content } : {}),
    ...(file.reference ? { reference: file.reference } : {})
  })) as UploadAttachment[];

  const additionalData = {
    html: body,
    attachments: filesData,
    ...(formData.copy
      ? {
          cc: ccAddressList
        }
      : {})
  };

  if (newMessageMode === ACTION.REPLY || newMessageMode === ACTION.REPLY_ALL) {
    const reference: MessageReference = {
      message: currentMessage?.id as string,
      inline: true,
      action: Action.Reply,
      documentStore: true
    };

    return {
      reference,
      ...additionalData,
      to: toAddressList,
      subject: formData.subject
    };
  }

  if (newMessageMode === ACTION.FORWARD) {
    const reference: MessageReference = {
      message: currentMessage?.id as string,
      action: Action.Forward,
      forwardAttachments: false,
      documentStore: true
    };

    return {
      reference,
      ...additionalData,
      to: toAddressList,
      subject: formData.subject
    };
  }

  return {
    ...newMessageData,
    ...additionalData
  };
};
