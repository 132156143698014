import React, { ForwardedRef, forwardRef, ReactNode, useMemo, useState } from 'react';
import { Form, Select } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { useGetCounterpartiesForDropdown } from 'queries/counterparty';
import { sortBy } from 'lodash';
import { ContractType, Counterparty } from 'services/api/data-contracts';

interface IProps {
  label: string;
  labelSpan?: number;
  name: string | (string | number)[];
  isSelf?: boolean;
  isOperator?: boolean;
  contractType?: ContractType;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  onChange?: (counterparty?: Counterparty) => void;
  onClear?: () => void;
  suffixIcon?: ReactNode | null;
  placeholder?: string;
}

export const CounterpartyInput = forwardRef(
  (
    {
      className,
      label,
      labelSpan,
      name,
      isSelf,
      isOperator,
      contractType,
      disabled,
      required,
      onChange,
      onClear,
      suffixIcon = null,
      placeholder
    }: IProps,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: ForwardedRef<any>
  ) => {
    const [filter, setFilter] = useState('');

    const { isLoading, data } = useGetCounterpartiesForDropdown({
      isSelf,
      isOperator,
      contractType
    });

    const options = useMemo(
      () =>
        sortBy(data?.data.items, (o) => [!o.isActive, o.name]).map(
          ({ name, id, isActive, isSelf }) => ({
            label: name + (isSelf ? ' (self)' : ''),
            value: id,
            disabled: !isActive
          })
        ),
      [data]
    );

    const handleBlur = () => {
      const filteredOptions = options.filter((o) =>
        o.label.toLowerCase().includes(filter.toLowerCase())
      );

      if (filteredOptions.length === 1 && onChange) {
        onChange(data?.data.items?.find(({ id }) => id === filteredOptions[0].value));
      }
    };

    return (
      <Form.Item
        name={name}
        label={label}
        labelCol={labelSpan ? { span: labelSpan } : {}}
        rules={[{ required }]}
        className={className}>
        <Select
          ref={ref}
          style={{ width: '100%' }}
          placeholder={placeholder || `Select ${label.toLowerCase()}`}
          options={options}
          size={CONTROL_SIZE}
          loading={isLoading}
          showSearch
          optionFilterProp="label"
          onSearch={setFilter}
          onBlur={handleBlur}
          data-testid="counterparty-select"
          disabled={disabled}
          allowClear={!required && !suffixIcon}
          suffixIcon={suffixIcon}
          popupMatchSelectWidth={false}
          onChange={(value) => {
            if (onChange) {
              onChange(data?.data.items?.find(({ id }) => id === value));
            }

            if (!value && onClear) {
              onClear();
            }
          }}
        />
      </Form.Item>
    );
  }
);

CounterpartyInput.displayName = 'CounterpartyInput';
