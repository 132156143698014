import { EMAIL_TEMPLATE_DATE_TIME_FORMAT } from 'consts/common';
import {
  BaseFlightWithAirportCodes,
  CustomerStatus,
  Order,
  OrderType,
  ProgramById
} from 'services/api/data-contracts';
import { getUTCDate, getOrderTerm } from 'helpers';

export const getProgramForTemplate = (program: ProgramById, orders: Order[]) => {
  const flights: BaseFlightWithAirportCodes[] = [];

  orders.forEach((order) => {
    if ('flights' in order && order.flights?.length)
      flights.push(...(order.flights as BaseFlightWithAirportCodes[]));

    if ('arrivalFlight' in order) flights.push(order.arrivalFlight as BaseFlightWithAirportCodes);

    if ('departureFlight' in order)
      flights.push(order.departureFlight as BaseFlightWithAirportCodes);
  });

  const mappedFlights = flights.map((flight) =>
    flight
      ? {
          ...flight,
          estimatedTimeDeparture: getUTCDate(flight.estimatedTimeDeparture).format(
            EMAIL_TEMPLATE_DATE_TIME_FORMAT
          ),
          estimatedTimeArrival: getUTCDate(flight.estimatedTimeArrival).format(
            EMAIL_TEMPLATE_DATE_TIME_FORMAT
          )
        }
      : undefined
  );

  const location =
    program.mainOrder.location &&
    ('code' in program.mainOrder.location || program.mainOrder.type === OrderType.PMT)
      ? program.mainOrder.location
      : flights[0]?.arrivalAirport;

  const filteredServices = program.nearestOrder.orderServices.filter(
    ({ customerStatus, supplierStatus }) =>
      !!supplierStatus &&
      (!customerStatus || ![CustomerStatus.MIST, CustomerStatus.NN].includes(customerStatus))
  );

  return {
    ...program.mainOrder,
    isProgram: true,
    location,
    flights: mappedFlights.filter((i) => !!i),
    services: filteredServices,
    orderTerm: getOrderTerm(getUTCDate(program.startDatetime), getUTCDate(program.endDatetime))
  };
};
