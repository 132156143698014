import React from 'react';
import { MessagesFilter, useMessageStore } from 'store/messageStore';
import { FILTER_LABEL } from '../../widgets/MessagesWidget/consts';
import { omit } from 'lodash';
import { FilterTag } from '../FilterTag';
import { getDateTagValue } from 'helpers';
import { MailOutlined } from '@ant-design/icons';

interface IProps {
  filter: MessagesFilter;
  setFilter: (filter: MessagesFilter) => void;
}

export const AppliedMessageFilters = ({ filter, setFilter }: IProps) => {
  const { messagesFilter, setMessagesFilter } = useMessageStore(
    ({ messagesFilter, setMessagesFilter }) => ({
      messagesFilter,
      setMessagesFilter
    })
  );

  const appliedFilters = Object.keys(messagesFilter);

  const getFilterTag = (filterKey: string) => {
    let value: string | undefined;
    const key = filterKey as keyof MessagesFilter;
    const name = key === 'flags' ? 'label' : key;

    if (key === 'messageSentDate' && messagesFilter.messageSentDate) {
      value = getDateTagValue(messagesFilter.messageSentDate);
    } else if (
      key === 'flags' &&
      messagesFilter.flags &&
      !messagesFilter.flags.includes(FILTER_LABEL.ALL_LABELS)
    ) {
      value = messagesFilter[key]?.join(', ');
    } else if (
      key === 'flags' &&
      messagesFilter.flags &&
      messagesFilter.flags.includes(FILTER_LABEL.ALL_LABELS)
    ) {
      value = FILTER_LABEL.ALL_LABELS;
    } else if (key === 'withAttachment' && messagesFilter.withAttachment) {
      value = 'Attachment';
    } else if (key === 'showDone') {
      return;
    } else {
      value = messagesFilter[key]?.toString();
    }

    const onClose = () => {
      setFilter(omit(filter, key));
      setMessagesFilter(omit(messagesFilter, key));
    };

    const icon =
      key.toLowerCase().includes('time') || key.toLowerCase().includes('date') ? (
        <MailOutlined style={{ color: '#8C8C8C' }} />
      ) : undefined;

    return value ? (
      <FilterTag name={name} value={value} onClose={onClose} key={key} icon={icon} />
    ) : null;
  };

  return <>{appliedFilters.map(getFilterTag)}</>;
};
