import { useQuery } from '@tanstack/react-query';
import { rebatesApi } from 'services/api';
import { QUERY_KEY } from './consts';
import { useRebateStore } from 'store/rebateStore';

export enum RebateQueryType {
  Extra = 'extra',
  Discount = 'discount'
}

interface IFilter {
  page: number;
  pageSize?: number;
  name?: string;
  type?: RebateQueryType;
}

const fetchList = async (filter: IFilter) => await rebatesApi.getAllRebates(filter);

export const useGetRebates = (filter: IFilter) => {
  const { setList } = useRebateStore(({ setList }) => ({
    setList
  }));

  return useQuery({
    queryKey: [QUERY_KEY, filter],
    queryFn: () => fetchList(filter),
    onSuccess: async (response) => {
      setList(response.data);
    },
    keepPreviousData: true
  });
};
