import { useMutation, useQueryClient } from '@tanstack/react-query';
import { messagesApi } from 'services/emailEngineApi';
import { MessageUpdate } from 'services/emailEngineApi/data-contracts';
import { settingsStore } from 'services/settings/SettingsStore';
import { MESSAGES_QUERY_KEY } from './consts';

export const updateMessage = async ({
  messageId,
  data
}: {
  messageId: string;
  data: MessageUpdate;
}) => {
  try {
    const response = await messagesApi.putV1AccountAccountMessageMessage(
      settingsStore.getMailboxSettings()?.mailbox_name || '',
      messageId,
      data
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching message:', error);
    throw error;
  }
};

export const useUpdateMessage = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateMessage,
    onSuccess: async () => {
      setTimeout(async () => await queryClient.invalidateQueries([MESSAGES_QUERY_KEY]), 1000);
    }
  });
};
