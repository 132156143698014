import { useQuery } from '@tanstack/react-query';
import { rebatesApi } from 'services/api';
import { QUERY_KEY } from './consts';

interface IFilter {
  serviceNameId: number;
  orderServiceId?: number;
  invoiceSupplyDate?: string;
  invoicePayerId: number;
}

const fetchList = async (filter: IFilter) => await rebatesApi.getAllRebatesForInvoiceItem(filter);

export const useGetRebatesForInvoiceItem = (filter: IFilter, enabled?: boolean) =>
  useQuery({
    queryKey: [
      QUERY_KEY,
      filter.invoiceSupplyDate,
      filter.orderServiceId,
      filter.serviceNameId,
      filter.invoicePayerId
    ],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: async () => fetchList(filter),
    enabled
  });
