import { Checkbox, Descriptions, DescriptionsProps, Flex, Tabs, TabsProps } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import React, { useContext } from 'react';
import { DataContext } from '../DictionaryDetails';
import { Counterparty } from 'services/api/data-contracts';
import { CounterpartyContracts } from './components/CounterpartyContracts/CounterpartyContracts';
import { CounterpartyAircrafts } from './components/CounterpartyAircrafts/CounterpartyAircrafts';

export const CounterpartyDictionaryView = () => {
  const data = useContext(DataContext) as Counterparty;

  const descriptions: DescriptionsProps['items'] = [
    {
      label: 'Name',
      children: data.name
    },
    {
      label: 'Is active',
      children: <Checkbox disabled checked={data.isActive} />
    },
    {
      label: 'Full name',
      span: 2,
      children: data.fullName
    },
    {
      label: 'Legal form',
      children: data.legalForm || '-'
    },
    {
      label: 'Country',
      children: data.country?.name || '-'
    },
    {
      label: 'Registration №',
      children: data.registrationNumber || '-'
    },
    {
      label: 'Is operator',
      children: <Checkbox disabled checked={data.isOperator} />
    },
    {
      label: 'ICAO',
      children: data.icao || '-'
    },
    {
      label: 'IATA',
      children: data.iata || '-'
    },
    {
      label: 'Legal address',
      span: 2,
      children: data.legalAddress || '-'
    },
    {
      label: 'Actual address',
      span: 2,
      children: data.actualAddress || '-'
    },
    {
      label: 'Notes',
      span: 2,
      children: <span style={{ whiteSpace: 'pre-line' }}>{data.notes || '-'}</span>
    }
  ];

  const tabs: TabsProps['items'] = [
    {
      key: '1',
      label: 'Contracts',
      children: <CounterpartyContracts counterpartyId={data.id} />
    },
    ...(data.isOperator
      ? [
          {
            key: '2',
            label: 'Aircrafts',
            children: <CounterpartyAircrafts counterpartyId={data.id} />
          }
        ]
      : [])
  ];

  if (!data) {
    return null;
  }

  return (
    <Flex vertical gap={20}>
      <Descriptions size={CONTROL_SIZE} items={descriptions} column={2} bordered />

      <Tabs defaultActiveKey="1" items={tabs} style={{ marginBottom: 20 }} size={CONTROL_SIZE} />
    </Flex>
  );
};
