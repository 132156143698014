import React from 'react';
import { useFlightProgramStore } from 'store/flightProgramStore';
import { useGetFlightProgram } from 'queries/flightProgram';
import { ProgramDetails } from './ProgramDetails';
import { ProgramServices } from './ProgramServices';
import { ProgramOrders } from './ProgramOrders';
import { NearestOrderDetails } from './NearestOrderDetails';
import { Links } from '../../../../components/Links/Links';
import { LinkedEntityTypes } from 'services/api/data-contracts';
import { useGetLinks } from 'queries/links/useGetLinks';
import styled from 'styled-components';

const LinksWrapper = styled('div')`
  box-shadow:
    0 6px 6px 0 #00000014,
    0 3px 6px -4px #0000001f;
  margin: -12px -8px 8px;
  width: calc(100% + 16px);
  padding: 0 8px 4px;
`;

export const ProgramDrawerContent = () => {
  const { openProgramId } = useFlightProgramStore();

  const { isLoading } = useGetFlightProgram(openProgramId);
  const linksQuery = useGetLinks({ programIds: openProgramId?.toString() }, !!openProgramId);

  const hasLinks =
    [...(linksQuery.data?.data.flights || []), ...(linksQuery.data?.data.messages || [])].length >
    0;

  return (
    <>
      {hasLinks && (
        <LinksWrapper>
          <Links
            targetEntityType={LinkedEntityTypes.Program}
            targetEntityId={openProgramId}
            isLoading={linksQuery.isFetching}
            linkedFlights={linksQuery.data?.data.flights}
            linkedMessages={linksQuery.data?.data.messages}
          />
        </LinksWrapper>
      )}

      <ProgramDetails />
      <NearestOrderDetails />
      <ProgramServices isLoading={isLoading} />

      {openProgramId && <ProgramOrders programId={openProgramId} />}
    </>
  );
};
