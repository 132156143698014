import React, { useContext } from 'react';
import { Button, Space } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { OrderType } from 'services/api/data-contracts';
import { useCloseOnEsc, useCtrlEnterShortcut, useCtrlSShortcut } from 'helpers';
import { useOrderStore } from 'store/orderStore';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { ExportPopover } from './ExportPopover';
import { CancelButtonWithConfirm, CloseButton, EditButton } from 'components/Drawer';
import { useMessageStore } from 'store/messageStore';
import { useDrawerStore } from 'store/drawerStore';
import { CreateBasedOnButton } from './CreateBasedOnButton';
import { DashboardContext, DashboardType } from '../../../../../consts';
import { ChangeStatusPopover } from './ChangeStatusPopover';

interface IProps {
  isLoading: boolean;
  onSubmit: () => Promise<void>;
  onClose: () => void;
  orderType?: OrderType;
  isFormTouched?: boolean;
}

export const HeaderButtons = ({
  isLoading,
  onSubmit,
  onClose,
  orderType,
  isFormTouched
}: IProps) => {
  const { setOrderEditMode, isOrderEditMode } = useOrdersDashboardStore(
    ({ setOrderEditMode, isOrderEditMode }) => ({
      setOrderEditMode,
      isOrderEditMode
    })
  );
  const { current, service } = useOrderStore(({ current, service }) => ({
    current,
    service
  }));
  const { isFlightDrawerOpen } = useDrawerStore(({ openDrawers }) => ({
    isFlightDrawerOpen: openDrawers.includes('flight')
  }));
  const { newMessageMode } = useMessageStore(({ newMessageMode }) => ({ newMessageMode }));

  const { type: dashboardType } = useContext(DashboardContext);

  useCtrlEnterShortcut(() => handleSubmit(true), !newMessageMode);
  useCtrlSShortcut(() => handleSubmit(false), !newMessageMode);
  useCloseOnEsc(onClose, !service && !isFlightDrawerOpen && (!isOrderEditMode || !isFormTouched));

  const handleClose = () => {
    setOrderEditMode(false);
    onClose();
  };

  const handleSubmit = async (shouldClose?: boolean) => {
    try {
      await onSubmit();
      shouldClose && handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  if (isOrderEditMode) {
    return (
      <Space>
        <CancelButtonWithConfirm
          onConfirm={handleClose}
          isLoading={isLoading}
          isFormTouched={isFormTouched}
        />

        <Button size={CONTROL_SIZE} loading={isLoading} onClick={() => handleSubmit(false)}>
          {current?.id ? 'Save' : 'Create'}
        </Button>

        <Button
          type="primary"
          size={CONTROL_SIZE}
          loading={isLoading}
          onClick={() => handleSubmit(true)}>
          {current?.id ? 'Save' : 'Create'} and close
        </Button>
      </Space>
    );
  }

  if (dashboardType === DashboardType.Settlement) {
    return (
      <Space>
        <ChangeStatusPopover />
        <CloseButton onClick={onClose} />
      </Space>
    );
  }

  return (
    <Space>
      <ExportPopover isLoading={isLoading} />
      <CreateBasedOnButton isLoading={isLoading} orderType={orderType} />
      <EditButton onClick={() => setOrderEditMode(true)} isLoading={isLoading} />
      <CloseButton onClick={onClose} />
    </Space>
  );
};
