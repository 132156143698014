import { Flex, Form, Select } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import {
  ContractType,
  CustomerStatus,
  OrderService,
  PaymentType,
  SupplierStatus
} from 'services/api/data-contracts';
import {
  customerStatusTitles,
  paymentTypeTitles,
  supplierStatusTitle
} from '../OrderServiceDrawer';
import { CounterpartyInput } from 'components/CounterpartyInput/CounterpartyInput';
import React from 'react';
import Tooltip from 'antd/es/tooltip';
import { CloseOutlined } from '@ant-design/icons';
import { useWatch } from 'antd/es/form/Form';
import { FormInstance } from 'antd/es/form';

type Value = null | undefined | string | boolean | number;

interface IProps {
  form: FormInstance<OrderService>;
  selectedServices: number[];
}

export const ServicesBulkUpdateForm = ({ form, selectedServices }: IProps) => {
  const customerStatus = useWatch('customerStatus', form);
  const supplierStatus = useWatch('supplierStatus', form);
  const isOwnService = useWatch('isOwnService', form);
  const providerId = useWatch('providerId', form);
  const supplierId = useWatch('supplierId', form);

  const clearIcon = (fieldName: string, currentValue: Value) => {
    if (currentValue === null) {
      return null;
    }

    return (
      <Tooltip title="Clear for all">
        <CloseOutlined
          onClick={() => {
            form.setFieldsValue({ [fieldName]: null });
          }}
        />
      </Tooltip>
    );
  };

  const initialPlaceholder = `Change all ${selectedServices.length} to...`;

  const getPlaceholder = (currentValue: Value, placeholder: string) =>
    currentValue === undefined ? initialPlaceholder : placeholder;

  return (
    <Form
      form={form}
      labelCol={{ span: 5 }}
      style={{ width: 330 }}
      layout="vertical"
      colon={false}
      className="styled-form">
      <Flex gap={8}>
        <Form.Item name="customerStatus" label="Customer status" labelCol={{ span: 24 }}>
          <Select
            size={CONTROL_SIZE}
            options={Object.values(CustomerStatus).map((status) => ({
              value: status,
              label: customerStatusTitles[status]
            }))}
            showSearch
            optionFilterProp="label"
            popupMatchSelectWidth={false}
            placeholder={getPlaceholder(customerStatus, 'Select status')}
            style={{
              width: 112
            }}
            suffixIcon={clearIcon('customerStatus', customerStatus)}
          />
        </Form.Item>

        <div style={{ flexGrow: 1, maxWidth: 210 }}>
          <CounterpartyInput
            label="Supplier"
            name="supplierId"
            contractType={ContractType.WithSupplier}
            suffixIcon={clearIcon('supplierId', supplierId)}
            placeholder={getPlaceholder(supplierId, 'Select supplier')}
            onChange={(counterparty) => form.setFieldValue('supplierId', counterparty?.id)}
          />
        </div>
      </Flex>

      <Flex gap={8}>
        <Form.Item name="paymentType" label="Payment type" labelCol={{ span: 24 }}>
          <Select
            size={CONTROL_SIZE}
            options={Object.values(PaymentType).map((type) => ({
              value: type,
              label: paymentTypeTitles[type]
            }))}
            showSearch
            optionFilterProp="label"
            popupMatchSelectWidth={false}
            placeholder="Select payment type"
            style={{ width: 112 }}
          />
        </Form.Item>

        <div style={{ flexGrow: 1, maxWidth: 133 }}>
          <CounterpartyInput
            label="Provider"
            name="providerId"
            contractType={ContractType.WithSupplier}
            onChange={(counterparty) => form.setFieldValue('providerId', counterparty?.id)}
            suffixIcon={clearIcon('providerId', providerId)}
            placeholder={getPlaceholder(providerId, 'Select provider')}
          />
        </div>

        <Form.Item name="supplierStatus" label="Status" labelCol={{ span: 24 }}>
          <Select
            size={CONTROL_SIZE}
            options={Object.values(SupplierStatus).map((status) => ({
              value: status,
              label: supplierStatusTitle[status]
            }))}
            showSearch
            optionFilterProp="label"
            popupMatchSelectWidth={false}
            style={{ width: 70 }}
            suffixIcon={clearIcon('supplierStatus', supplierStatus)}
            placeholder={getPlaceholder(supplierStatus, 'Select status')}
          />
        </Form.Item>
      </Flex>

      <Form.Item name="isOwnService" label="Own price" labelCol={{ span: 24 }}>
        <Select
          size={CONTROL_SIZE}
          options={[
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ]}
          style={{ width: 226 }}
          suffixIcon={clearIcon('isOwnService', isOwnService)}
          placeholder={getPlaceholder(isOwnService, 'Select own price status')}
        />
      </Form.Item>
    </Form>
  );
};
