import { OrderRow, RowState } from 'store/settlementDashboardStore';

export const resetSelectedRowAndServices = (row: OrderRow) => ({
  ...row,
  state: RowState.EMPTY,
  services: row.services.map((service) => ({
    ...service,
    state: RowState.EMPTY
  }))
});
