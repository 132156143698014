import { useQuery } from '@tanstack/react-query';
import { airportsApi } from 'services/api';
import { QUERY_KEY } from './consts';
import { useAirportStore } from 'store/airportStore';

interface IFilter {
  page: number;
  pageSize?: number;
  name?: string;
}

const fetchList = async (filter: IFilter) => await airportsApi.getAirports(filter);

export const useGetAirports = (filter: IFilter, shouldUpdateStore?: boolean) => {
  const { setList } = useAirportStore(({ setList }) => ({ setList }));

  return useQuery({
    queryKey: [QUERY_KEY, filter],
    queryFn: () => fetchList(filter),
    onSuccess: (response) => {
      if (shouldUpdateStore) {
        setList(response.data);
      }
    }
  });
};
