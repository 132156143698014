import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { ordersApi } from 'services/api';
import {
  AdditionalServicesOrder,
  AdditionalServicesOrderInput,
  OrderType
} from 'services/api/data-contracts';
import { ENTITY_NAME } from './consts';
import { useOrderStore } from 'store/orderStore';
import { WIDGET_QUERY_KEY } from '../consts';
import { MESSAGE_DURATION } from 'consts/common';
import { SETTLEMENT_WIDGET_QUERY_KEY } from '../../settlement/consts';
import { PROGRAM_ORDERS_QUERY_KEY } from '../../flightProgram/consts';

export type AdditionalServicesOrderInputWithId = AdditionalServicesOrderInput & { id: number };

const updateItem = async (data: AdditionalServicesOrderInput & { id: number }) =>
  await ordersApi.updateAddOrder(data.id, data);

export const useUpdateAddOrder = (onSuccess?: (orderType: OrderType, orderId: number) => void) => {
  const { setCurrent, current } = useOrderStore(({ setCurrent, current }) => ({
    setCurrent,
    current: current as AdditionalServicesOrder
  }));
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateItem,
    onSuccess: async (response) => {
      if (response.data) {
        await queryClient.invalidateQueries({ queryKey: [WIDGET_QUERY_KEY] });
        await queryClient.invalidateQueries({ queryKey: [SETTLEMENT_WIDGET_QUERY_KEY] });
        await queryClient.invalidateQueries({ queryKey: [PROGRAM_ORDERS_QUERY_KEY] });

        message.success(
          `${ENTITY_NAME} #${response.data.number} has been successfully updated`,
          MESSAGE_DURATION
        );

        setCurrent(response.data ? { ...current, ...response.data, type: OrderType.ADD } : current);

        onSuccess && onSuccess(OrderType.ADD, response.data.id);
      }
    }
  });
};
