import React from 'react';
import { green, red } from '@ant-design/colors';
import { Tooltip } from 'antd';
import styled from 'styled-components';

const Pie = styled.div`
  position: relative;
  flex-shrink: 0;
  height: 14px;
  width: 14px;
  border-radius: 50%;

  .slice {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 50%;
    transform-origin: 0 100%;
  }

  .slice:first-child {
    transform: rotate(293deg) skewY(-22.5deg);
  }

  .slice-contents {
    position: absolute;
    left: -100%;
    width: 200%;
    height: 200%;
    border-radius: 50%;
    background: ${green[2]};
  }
  .slice:first-child .slice-contents {
    transform: skewY(22.5deg);
  }
`;

interface IProps {
  isExpired?: boolean;
  amountDue?: number;
  total?: number;
}

export const InvoiceStatus = ({ isExpired, amountDue = 0, total = 0 }: IProps) => {
  let title = '';
  let backgroundColor: string = green[3];

  switch (true) {
    case amountDue === 0:
      title = 'Paid';
      backgroundColor = green[3];
      break;
    case isExpired:
      title = 'Overdue';
      backgroundColor = red[3];
      break;
    case amountDue >= total:
      backgroundColor = 'transparent';
      break;
    case amountDue > 0:
      title = 'Partially paid';
      backgroundColor = green[4];
      break;
  }

  return (
    <Tooltip title={title}>
      <Pie
        style={{
          border: `1px solid ${backgroundColor}`,
          backgroundColor
        }}
        role="figure">
        {!isExpired && total > amountDue && amountDue > 0 && (
          <div className="slice">
            <div className="slice-contents"></div>
          </div>
        )}
      </Pie>
    </Tooltip>
  );
};
