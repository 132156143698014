import { Button, ConfigProvider, Empty, Flex, Table } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import React, { useMemo, useState } from 'react';
import { CustomerStatus, OrderService } from 'services/api/data-contracts';
import styled from 'styled-components';
import { sortBy } from 'lodash';
import { DownOutlined } from '@ant-design/icons';
import { getColumns } from './servicesTableColumns';
import { useFlightProgramStore } from 'store/flightProgramStore';

const StyledTableWrapper = styled('div')`
  .ant-spin-container {
    height: fit-content !important;
  }

  .ant-table-tbody {
    padding: 6px !important;
  }

  .ant-table-tbody > tr {
    height: 35px;
  }

  .ant-table-tbody > tr > td {
    padding: 6px !important;
  }

  .ant-table-thead > tr > th {
    padding: 6px !important;
  }

  .ant-table-cell-row-hover #actions {
    display: block;
  }

  .ant-table-footer {
    background: white !important;
    padding: 8px 0 !important;
  }
`;

interface IProps {
  isLoading: boolean;
}

export const ProgramServicesTable = ({ isLoading }: IProps) => {
  const [showAllServices, setShowAllServices] = useState(false);

  const { current, selectedServices, setSelectedServices } = useFlightProgramStore();

  const columns = useMemo(() => getColumns(), []);

  const servicesWithOrder = useMemo(() => {
    return (current?.nearestOrder?.orderServices || []).map(
      (service) =>
        ({
          ...service,
          key: service.id,
          order: { ...current?.nearestOrder, orderServices: [] }
        }) as OrderService
    );
  }, [current?.nearestOrder]);

  const rows = useMemo(() => {
    return sortBy(servicesWithOrder, 'createdAt').filter((service) => {
      if (!showAllServices) {
        return (
          !service.customerStatus ||
          ![CustomerStatus.MIST, CustomerStatus.NN].includes(service.customerStatus)
        );
      }
      return true;
    });
  }, [servicesWithOrder, showAllServices]);

  const hiddenServices = servicesWithOrder.filter(
    (service) =>
      service.customerStatus &&
      [CustomerStatus.MIST, CustomerStatus.NN].includes(service.customerStatus)
  );

  const footer = () => (
    <Flex align="center" justify="space-between">
      {hiddenServices.length > 0 && (
        <Button
          type="link"
          size={CONTROL_SIZE}
          style={{ color: 'black' }}
          onClick={() => setShowAllServices((prevState) => !prevState)}>
          {showAllServices ? 'Hide' : 'Show'} MIST and NN ({hiddenServices.length})
          <DownOutlined style={{ transform: 'rotate(270deg)' }} />
        </Button>
      )}
    </Flex>
  );

  return (
    <ConfigProvider
      renderEmpty={() => (
        <Empty description="Services not found" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}>
      <StyledTableWrapper>
        <Table
          columns={columns}
          dataSource={rows}
          size={CONTROL_SIZE}
          loading={isLoading}
          pagination={false}
          rowSelection={{
            onSelect: ({ id }, isSelected) => {
              setSelectedServices(
                isSelected
                  ? [...selectedServices, id]
                  : selectedServices.filter((serviceId) => serviceId !== id)
              );
            },
            selectedRowKeys: selectedServices,
            hideSelectAll: true
          }}
          footer={footer}
        />
      </StyledTableWrapper>
    </ConfigProvider>
  );
};
