import React, { useEffect, useState } from 'react';
import { Button, Divider, Flex, Popover, Space } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import { CONTROL_SIZE } from 'consts/common';
import { difference, omit } from 'lodash';
import { RangeValue } from 'store/helpers/types';
import { OrdersFilter, useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { OrderFilters } from './OrderFilters';
import { OrderType } from 'services/api/data-contracts';
import { AppliedSettlementFilters } from './AppliedSettlementFilters';
import { useDashboardStore } from 'store/dashboardStore';
import { MessagesFilter, useMessageStore } from 'store/messageStore';
import { MessageFilters } from '../../components/MessageFilters/MessageFilters';
import { defaultOrdersFilterForSettlement } from 'consts/order';
import { InvoiceFilter, useSettlementDashboardStore } from 'store/settlementDashboardStore';
import { InvoiceFilters } from './InvoiceFilters';
import { useOrderStore } from 'store/orderStore';
import { useCtrlEnterShortcut } from 'helpers';

type Filter = OrdersFilter & MessagesFilter & InvoiceFilter;

export const SettlementFilters = () => {
  const [isOpen, setOpen] = useState(false);

  const { setLinkFilters } = useDashboardStore();
  const { ordersFilter, setOrdersFilter } = useOrdersDashboardStore();
  const { setSelectedServices } = useOrderStore();
  const { setSelectedMessages, messagesFilter, setMessagesFilter } = useMessageStore();
  const { setPage, invoiceFilter, setInvoiceFilter, clearOrderRowsState } =
    useSettlementDashboardStore();

  const [filter, setFilter] = useState<Filter>({
    ...ordersFilter,
    ...messagesFilter
  });

  useEffect(() => {
    setOrdersFilter(defaultOrdersFilterForSettlement);
  }, []);

  useEffect(() => {
    setFilter({ ...ordersFilter, ...messagesFilter });
  }, [ordersFilter, messagesFilter]);

  const handleChangeFilter = (
    name: string,
    value:
      | string
      | RangeValue
      | OrderType[]
      | boolean
      | string[]
      | { value: number | string; label: string }[]
      | undefined
  ) => {
    if (value) {
      setFilter((filter) => ({ ...filter, [name]: value }));
    } else {
      setFilter((filter) => omit(filter, name));
    }
  };

  const handleApplyFilter = () => {
    const isFilterUpdated = (prev: Partial<Filter>, curr: Partial<Filter>) => {
      const prevFilterValues = Object.values(prev).filter((i) => !!i);
      const currFilterValues = Object.values(curr).filter((i) => !!i);

      return (
        difference(currFilterValues, prevFilterValues).length > 0 ||
        difference(prevFilterValues, currFilterValues).length > 0
      );
    };

    const updatedOrdersFilter = {
      orderStartDate: filter.orderStartDate,
      orderEndDate: filter.orderEndDate,
      orderTypes: filter.orderTypes,
      orderNumbers: filter.orderNumbers,
      customer: filter.customer,
      locations: filter.locations,
      payers: filter.payers,
      suppliers: filter.suppliers,
      orderStatuses: filter.orderStatuses,
      ordersAircrafts: filter.ordersAircrafts,
      flightNumbers: filter.flightNumbers,
      services: filter.services,
      isNotInProgram: filter.isNotInProgram
    };

    if (isFilterUpdated(ordersFilter, updatedOrdersFilter)) {
      setOrdersFilter(updatedOrdersFilter);
      clearOrderRowsState();
      setSelectedServices([]);
      setPage(1);
    }

    const updatedInvoiceFilter = {
      invoiceNumbers: filter.invoiceNumbers
    };

    if (isFilterUpdated(invoiceFilter, updatedInvoiceFilter)) {
      setInvoiceFilter(updatedInvoiceFilter);
      clearOrderRowsState();
      setSelectedServices([]);
      setPage(1);
    }

    const updatedMessagesFilter = {
      recipientOrSender: filter.recipientOrSender,
      emailSubject: filter.emailSubject,
      emailBody: filter.emailBody,
      messageSentDate: filter.messageSentDate,
      withAttachment: filter.withAttachment,
      flags: filter.flags,
      links: filter.links
    };

    if (isFilterUpdated(messagesFilter, updatedMessagesFilter)) {
      setMessagesFilter({ showDone: messagesFilter.showDone, ...updatedMessagesFilter });
      setSelectedMessages([]);
    }

    setOpen(false);
  };

  useCtrlEnterShortcut(handleApplyFilter, isOpen);

  const handleResetFilter = () => {
    setFilter(defaultOrdersFilterForSettlement);

    setLinkFilters([]);

    setMessagesFilter({
      showDone: messagesFilter.showDone
    });
    setSelectedMessages([]);

    setOrdersFilter(defaultOrdersFilterForSettlement);
    clearOrderRowsState();
    setSelectedServices([]);
  };

  const popoverContent = (
    <div style={{ marginBottom: '18px' }} data-testid="filters-popover">
      <Flex justify="flex-end" align="center">
        <Space>
          <Button size={CONTROL_SIZE} type="primary" onClick={handleApplyFilter}>
            Apply
          </Button>
        </Space>
      </Flex>

      <Divider orientation="left">Messages</Divider>
      <MessageFilters onChange={handleChangeFilter} filter={filter} />

      <Divider orientation="left">Invoices</Divider>
      <InvoiceFilters onChange={handleChangeFilter} filter={filter} />

      <Divider orientation="left">Orders and Programs</Divider>
      <OrderFilters onChange={handleChangeFilter} filter={filter} />
    </div>
  );

  return (
    <Flex style={{ margin: '12px 0' }} justify="flex-start" align="center" gap={8}>
      <Popover
        content={popoverContent}
        placement="bottomRight"
        open={isOpen}
        trigger="click"
        onOpenChange={() => setOpen(false)}
        overlayInnerStyle={{
          width: '1134px'
        }}>
        <Button
          icon={<FilterOutlined />}
          size={CONTROL_SIZE}
          title="DispatcherFilters"
          onClick={() => setOpen((isOpen) => !isOpen)}
        />
      </Popover>

      <AppliedSettlementFilters filter={filter} setFilter={setFilter} />

      <Button ghost type="primary" size={CONTROL_SIZE} onClick={handleResetFilter}>
        Reset all
      </Button>
    </Flex>
  );
};
