import { Button, ConfigProvider, Empty, Flex, Table } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import React, { useMemo, useState } from 'react';
import { BaseOrder, CustomerStatus, OrderService, PaymentType } from 'services/api/data-contracts';
import { useOrderStore } from 'store/orderStore';
import { getColumns } from './columns';
import styled from 'styled-components';
import { sortBy } from 'lodash';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { useDrawerStore } from 'store/drawerStore';

const StyledTableWrapper = styled('div')`
  .ant-spin-container {
    height: fit-content !important;
  }

  .ant-table-tbody {
    padding: 6px !important;
  }

  .ant-table-tbody > tr {
    height: 35px;
  }

  .ant-table-tbody > tr > td {
    padding: 6px !important;
  }

  .ant-table-thead > tr > th {
    padding: 6px !important;
  }

  #actions {
    display: none;
    position: absolute;
    right: 0;
  }

  #actions > button {
    margin-right: 4px;
  }

  .ant-table-cell-row-hover #actions {
    display: block;
  }

  .ant-table-footer {
    background: white !important;
    padding: 8px 0 !important;
  }
`;

interface IProps {
  dataSource: OrderService[];
  isLoading: boolean;
  isWidgetView?: boolean;
}

export const ServicesTable = ({ dataSource, isLoading, isWidgetView }: IProps) => {
  const [showAllServices, setShowAllServices] = useState(false);

  const { setService, current, selectedServices, setSelectedServices } = useOrderStore(
    ({ setService, current, selectedServices, setSelectedServices }) => ({
      setService,
      current,
      selectedServices,
      setSelectedServices
    })
  );
  const { setDrawerOpen } = useDrawerStore(({ setDrawerOpen }) => ({ setDrawerOpen }));

  const handleAdd = () => {
    setService({
      paymentType: PaymentType.CREDIT,
      orderId: current?.id,
      orderType: current?.type,
      order: current as BaseOrder
    });

    setDrawerOpen('orderService');
  };

  const handleOpenDetails = (service: OrderService) => {
    setService(service);
    setDrawerOpen('orderService');
  };

  const columns = useMemo(() => getColumns(handleOpenDetails, isWidgetView), []);
  const rows = useMemo(
    () =>
      sortBy(dataSource, 'createdAt').filter((service) => {
        if (!showAllServices) {
          return (
            !service.customerStatus ||
            ![CustomerStatus.MIST, CustomerStatus.NN].includes(service.customerStatus)
          );
        }

        return true;
      }),
    [dataSource, showAllServices]
  );

  const hiddenServices = dataSource.filter(
    (service) =>
      service.customerStatus &&
      [CustomerStatus.MIST, CustomerStatus.NN].includes(service.customerStatus)
  );

  const footer = () => (
    <Flex align="center" justify="space-between">
      {isWidgetView ? (
        <span />
      ) : (
        <Button type="link" size={CONTROL_SIZE} onClick={handleAdd} icon={<PlusOutlined />}>
          Add a service
        </Button>
      )}
      {hiddenServices.length > 0 && (
        <Button
          type="link"
          size={CONTROL_SIZE}
          style={{ color: 'black' }}
          onClick={() => setShowAllServices((prevState) => !prevState)}>
          {showAllServices ? 'Hide' : 'Show'} MIST and NN ({hiddenServices.length})
          <DownOutlined style={{ transform: 'rotate(270deg)' }} />
        </Button>
      )}
    </Flex>
  );

  return (
    <ConfigProvider
      renderEmpty={() => (
        <Empty description="Services not found" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}>
      <StyledTableWrapper>
        <Table
          columns={columns}
          dataSource={rows}
          size={CONTROL_SIZE}
          loading={isLoading}
          pagination={false}
          rowSelection={{
            onSelect: ({ id }, isSelected) => {
              setSelectedServices(
                isSelected
                  ? [...selectedServices, id]
                  : selectedServices.filter((serviceId) => serviceId !== id)
              );
            },
            selectedRowKeys: selectedServices,
            hideSelectAll: true
          }}
          footer={footer}
        />
      </StyledTableWrapper>
    </ConfigProvider>
  );
};
