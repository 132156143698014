import { SupplierStatus } from 'services/api/data-contracts';
import { Tag, Tooltip } from 'antd';
import React from 'react';
import { blue, gold, green, lime, red, yellow } from '@ant-design/colors';

const supplierStatusTagColors: Record<SupplierStatus, string> = {
  [SupplierStatus.PRE]: blue[0],
  [SupplierStatus.NRQS]: red[5],
  [SupplierStatus.RQSS]: yellow[1],
  [SupplierStatus.AINF]: gold[4],
  [SupplierStatus.RJCT]: '#595959',
  [SupplierStatus.CNFD]: lime[4],
  [SupplierStatus.CNLS]: '#d9d9d9',
  [SupplierStatus.INVR]: green[6],
  [SupplierStatus.NFI]: red[0],
  [SupplierStatus.IAS]: '#fafafa'
};

const supplierStatusBorderColors: Record<SupplierStatus, string> = {
  [SupplierStatus.PRE]: blue[3],
  [SupplierStatus.NRQS]: red[7],
  [SupplierStatus.RQSS]: yellow[4],
  [SupplierStatus.AINF]: gold[6],
  [SupplierStatus.RJCT]: '#141414',
  [SupplierStatus.CNFD]: lime[7],
  [SupplierStatus.CNLS]: '#595959',
  [SupplierStatus.INVR]: green[7],
  [SupplierStatus.NFI]: red[2],
  [SupplierStatus.IAS]: '#8c8c8c'
};

const supplierStatusFontColors: Record<SupplierStatus, string> = {
  [SupplierStatus.PRE]: 'inherit',
  [SupplierStatus.NRQS]: '#FFF',
  [SupplierStatus.RQSS]: 'inherit',
  [SupplierStatus.AINF]: 'inherit',
  [SupplierStatus.RJCT]: '#FFF',
  [SupplierStatus.CNFD]: 'inherit',
  [SupplierStatus.CNLS]: 'inherit',
  [SupplierStatus.INVR]: '#FFF',
  [SupplierStatus.NFI]: red[5],
  [SupplierStatus.IAS]: '#8c8c8c'
};

export const supplierStatusTitle: Record<SupplierStatus, string> = {
  [SupplierStatus.PRE]: 'Preliminary request sent',
  [SupplierStatus.NRQS]: 'Not requested from Supplier',
  [SupplierStatus.RQSS]: 'Request sent',
  [SupplierStatus.AINF]: 'Additional information required',
  [SupplierStatus.RJCT]: 'Rejected',
  [SupplierStatus.CNFD]: 'Confirmed',
  [SupplierStatus.CNLS]: 'Request cancellation is sent to Supplier',
  [SupplierStatus.INVR]: 'Invoice received',
  [SupplierStatus.NFI]: 'Not for invoicing',
  [SupplierStatus.IAS]: 'Invoiced with other service'
};

interface IProps {
  status: SupplierStatus;
}

export const SupplierStatusTag = ({ status }: IProps) => (
  <Tooltip title={supplierStatusTitle[status]}>
    <Tag
      color={supplierStatusTagColors[status]}
      style={{
        color: supplierStatusFontColors[status],
        borderColor: supplierStatusBorderColors[status]
      }}>
      {status}
    </Tag>
  </Tooltip>
);
