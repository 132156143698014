import { Form, Input } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { DictionaryName } from '../../models';
import { FormContext } from '../DictionaryDetails';
import { useFlightPurposeStore } from 'store/flightPurposeStore';
import { BooleanRadioGroup } from 'components/common/BooleanRadioGroup';

export const FlightPurposeDictionaryForm = () => {
  const { dictionaryName } = useParams<{ dictionaryName: DictionaryName }>();
  const form = useContext(FormContext);

  const { list, current } = useFlightPurposeStore(({ list, current }) => ({
    list,
    current
  }));

  const isNotUniqueName = (value: string) =>
    list.filter(({ name, id }) => name === value && id !== current?.id).length;

  if (!form) {
    return null;
  }

  return (
    <Form
      name={dictionaryName}
      layout="vertical"
      form={form}
      initialValues={{ isActive: true }}
      colon={false}
      requiredMark="optional"
      className="styled-form">
      <Form.Item name="isActive" label="Active" rules={[{ required: true }]}>
        <BooleanRadioGroup
          testId="Active"
          onChange={(value) => form?.setFieldValue('isActive', value)}
          value={form.getFieldValue('isActive')}
        />
      </Form.Item>

      <Form.Item
        name="name"
        label="Name"
        rules={[
          { required: true },
          {
            message: 'Name should be unique',
            validator: (_, value) => (isNotUniqueName(value) ? Promise.reject() : Promise.resolve())
          }
        ]}>
        <Input size={CONTROL_SIZE} autoFocus />
      </Form.Item>
    </Form>
  );
};
