import React, { useEffect, useMemo } from 'react';
import { CONTROL_SIZE } from 'consts/common';
import { Table } from 'antd';
import { useGetAircrafts } from 'queries/aircraft';
import { useAircraftStore } from 'store/aircraftStore';
import { getColumns } from './columns';

interface IProps {
  counterpartyId: number;
}

export const CounterpartyAircrafts = ({ counterpartyId }: IProps) => {
  const { list, setList } = useAircraftStore(({ list, setList }) => ({ list, setList }));

  useEffect(() => {
    return () => setList([]);
  }, []);

  const { isLoading } = useGetAircrafts({ operatorId: counterpartyId });

  const columns = useMemo(() => getColumns(), []);
  const dataSource = useMemo(
    () =>
      list.map((aircraft) => ({
        ...aircraft,
        key: aircraft.id
      })),
    [list]
  );

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      size={CONTROL_SIZE}
      loading={isLoading}
      pagination={false}
    />
  );
};
