import React, { useEffect, useMemo } from 'react';
import { Button, Flex, Input, Tooltip } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { DictionaryName } from '../../models';
import { hasCurrentUserRole, UserRole } from 'services/auth/hasUserRole';
import { settingsStore } from 'services/settings/SettingsStore';
import { Filter } from '../../Dictionaries';
import { Dayjs } from 'dayjs';
import { DictionaryFilter } from './DictionaryFilter';
import { getPlaceholder } from './helpers/getPlaceholder';

interface IProps {
  isLoading: boolean;
  showFilter?: boolean;
  showSearch?: boolean;
  filter: Filter;
  onFilter: (name: string, value: string | Dayjs | boolean | undefined | null) => void;
  onAdd: () => void;
  dictionary?: DictionaryName;
}

export const DictionaryHeader = ({
  showFilter,
  onFilter,
  filter,
  showSearch,
  onAdd,
  isLoading,
  dictionary
}: IProps) => {
  useEffect(() => () => onFilter('name', undefined), []);

  const currentTenant = settingsStore.getCurrentTenant();

  const placeholder = useMemo(() => getPlaceholder(dictionary as DictionaryName), [dictionary]);

  const isCreationAvailable = useMemo(() => {
    switch (dictionary) {
      case 'airport':
      case 'country':
      case 'city':
      case 'flight-purpose':
      case 'flight-type':
      case 'unit-of-measure':
        return hasCurrentUserRole([UserRole.TenantManager]);
      case 'aircraft':
        return hasCurrentUserRole([]);
      case 'counterparty':
      case 'contract':
      case 'currency-rate':
      case 'own-price':
      case 'rebate':
      case 'service-name':
      case 'vat-rate':
        return hasCurrentUserRole([UserRole.Settlement]);
      case 'service':
        return hasCurrentUserRole([UserRole.Dispatcher]);
      default:
        return false;
    }
  }, [dictionary, currentTenant.id]);

  if (!showSearch && !showFilter && !isCreationAvailable) {
    return null;
  }

  return (
    <Flex justify="space-between" style={{ marginBottom: '16px' }} gap={12}>
      {showFilter ? (
        <DictionaryFilter dictionary={dictionary} filter={filter} onFilter={onFilter} />
      ) : (
        <span />
      )}

      <Flex justify="end" gap={12}>
        {showSearch && (
          <Tooltip title={placeholder}>
            <Input.Search
              size={CONTROL_SIZE}
              loading={isLoading}
              value={filter.name}
              style={{ width: '204px' }}
              onChange={({ target: { value } }) => onFilter('name', value)}
              placeholder={placeholder}
            />
          </Tooltip>
        )}

        {isCreationAvailable && (
          <Button size={CONTROL_SIZE} onClick={onAdd}>
            Add a new one
          </Button>
        )}
      </Flex>
    </Flex>
  );
};
