import { useMutation, useQueryClient } from '@tanstack/react-query';
import { paymentPurposesApi } from 'services/api';
import { PaymentPurposeHydrated, PaymentPurposeUpdateInput } from 'services/api/data-contracts';
import { BALANCE_WIDGET_QUERY_KEY } from 'queries/balance/consts';
import { useBalanceDashboardStore } from 'store/balanceDashboardStore';
import { INVOICE_QUERY_KEY } from 'queries/invoice/consts';
import { PAYMENT_QUERY_KEY } from 'queries/payment/consts';
import { QUERY_KEY } from './consts';
import { useInvoiceStore } from 'store/invoiceStore';
import { usePaymentStore } from 'store/paymentStore';

const updateItem = async (data: PaymentPurposeUpdateInput) =>
  await paymentPurposesApi.updatePaymentPurposes(data);

export const useUpdatePaymentPurpose = (
  onSuccess?: (data: PaymentPurposeHydrated[]) => void,
  drawerType = 'invoice'
) => {
  const queryClient = useQueryClient();

  const { shouldUpdateBalanceView, setShouldUpdateBalanceView, filter } = useBalanceDashboardStore(
    ({ shouldUpdateBalanceView, setShouldUpdateBalanceView, filter }) => ({
      shouldUpdateBalanceView,
      setShouldUpdateBalanceView,
      filter
    })
  );
  const { current: invoice } = useInvoiceStore(({ current }) => ({ current }));
  const { current: payment } = usePaymentStore(({ current }) => ({ current }));

  return useMutation({
    mutationFn: updateItem,
    onSuccess: async (response) => {
      await queryClient.invalidateQueries([QUERY_KEY]);

      drawerType === 'invoice' &&
        (await queryClient.refetchQueries([INVOICE_QUERY_KEY, invoice?.id]));

      drawerType === 'payment' &&
        (await queryClient.refetchQueries([PAYMENT_QUERY_KEY, payment?.id]));

      onSuccess && onSuccess(response.data);

      shouldUpdateBalanceView &&
        (await queryClient.refetchQueries([BALANCE_WIDGET_QUERY_KEY, filter]));

      shouldUpdateBalanceView && setShouldUpdateBalanceView(false);
    }
  });
};
