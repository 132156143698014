import React from 'react';
import { Button, Flex, Result } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { isAxiosError } from 'axios';
import { handleError } from 'helpers';
import { ResultStatusType } from 'antd/es/result';

type IProps = {
  onGoBack: () => void;
  children: React.ReactElement;
};

type IState = {
  hasError: boolean;
  errorStatus?: number;
  errorCode?: string;
  errorMessage?: string;
};

export class ErrorBoundary extends React.Component<IProps, IState> {
  static handledErrors = new Set();

  constructor(props: IProps) {
    super(props);
    this.state = {
      hasError: false,
      errorCode: undefined,
      errorMessage: undefined,
      errorStatus: undefined
    };
  }

  static getDerivedStateFromError(error: Error) {
    if (ErrorBoundary.handledErrors.has(error) || !isAxiosError(error)) return;

    // Alfresco is down
    if (error.response?.status === 503) return;

    if (error.response?.status === 401) {
      return handleError(error);
    }

    return {
      hasError: true,
      errorStatus: error.response?.status,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      errorMessage: error.response?.data?.message || error.message,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      errorCode: error.response?.headers?.['X-Amzn-Trace-Id'] || error.response?.data?.correlationId
    };
  }

  componentDidCatch(error: Error) {
    ErrorBoundary.handledErrors.add(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Flex align="center" justify="center" style={{ flexGrow: 1 }}>
          <Result
            status={this.state.errorStatus as ResultStatusType}
            title={this.state.errorStatus}
            subTitle={
              <>
                <span>
                  {this.state.errorStatus === 403
                    ? 'Sorry, you are not authorized to access this page'
                    : this.state.errorMessage}
                </span>
                <br />
                <span>{this.state.errorCode?.replace('Root=', '')}</span>
              </>
            }
            extra={
              this.state.errorStatus === 403 && (
                <Button
                  type="primary"
                  size={CONTROL_SIZE}
                  onClick={() => {
                    this.props.onGoBack();
                    this.setState({
                      hasError: false,
                      errorCode: undefined,
                      errorMessage: undefined,
                      errorStatus: undefined
                    });
                  }}>
                  Back Home
                </Button>
              )
            }
          />
        </Flex>
      );
    }

    return this.props.children;
  }
}
