import { InvoiceItemHydratedWithRolledUp, Service } from 'services/api/data-contracts';
import { UOM_TAGS } from 'consts/order';
import {
  convertGallonsToKg,
  convertKgToGallons,
  convertKgToLitres,
  convertKgToTonnes,
  convertLitresToKg,
  convertTonnesToKg
} from 'helpers';

export const getUOMConvertionPreview = (
  selectedUnitOfMeasure: string,
  fuelService?: Service,
  currentInvoiceItem?: InvoiceItemHydratedWithRolledUp
) => {
  if (!fuelService || !currentInvoiceItem) {
    return '';
  }

  const currentUOM = currentInvoiceItem.unitOfMeasure?.shortName;
  const quantity = currentInvoiceItem.quantity;
  const density = currentInvoiceItem.orderService?.additionalData?.density || 0.8;

  if (currentUOM && selectedUnitOfMeasure && quantity && density) {
    let currentInKg = quantity;

    switch (currentUOM) {
      case UOM_TAGS.MT:
        currentInKg = convertTonnesToKg(quantity);
        break;
      case UOM_TAGS.LTR:
        currentInKg = convertLitresToKg(quantity, density);
        break;
      case UOM_TAGS.USG:
        currentInKg = convertGallonsToKg(quantity, density);
        break;
      default:
        break;
    }

    switch (selectedUnitOfMeasure) {
      case UOM_TAGS.MT:
        return convertKgToTonnes(currentInKg);
      case UOM_TAGS.LTR:
        return convertKgToLitres(currentInKg, density, 2);
      case UOM_TAGS.USG:
        return convertKgToGallons(currentInKg, density);
      case UOM_TAGS.KG:
        return currentInKg;
      default:
        return '';
    }
  }
  return '';
};
