import React, { useMemo } from 'react';
import { blue } from '@ant-design/colors';
import { CollapseProps, Flex, Table, Spin } from 'antd';
import { StyledBadge } from 'components/common/StyledBadge';
import { StyledCollapse } from 'components/common/StyledCollapse';
import { CONTROL_SIZE } from 'consts/common';
import { getSpecialParametersColumns } from 'pages/Dictionaries/columnConfigs/ownPrice';
import { useUpdateOwnPrice } from 'queries/ownPrice';
import { SpecialParameter } from 'services/api/data-contracts';

interface IProps {
  specialParameters: SpecialParameter[];
}

export const SpecialParametersView = ({ specialParameters }: IProps) => {
  const updateMutation = useUpdateOwnPrice();

  const specialParametersColumns = getSpecialParametersColumns();

  const dataSource = useMemo(
    () =>
      specialParameters.map((p) => ({
        ...p,
        key: p.id
      })),
    [specialParameters]
  );

  const collapseContent: CollapseProps['items'] = [
    {
      key: 'specialParameters',
      label: (
        <Flex gap={4} align="center">
          Special parameters
          <StyledBadge count={specialParameters.length} color={blue[0]} />
        </Flex>
      ),
      children: (
        <Table
          columns={specialParametersColumns}
          dataSource={dataSource}
          size={CONTROL_SIZE}
          pagination={false}
        />
      )
    }
  ];

  const isLoading = updateMutation.isLoading;

  return (
    <>
      {isLoading ? (
        <div
          style={{
            height: '200px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <Spin spinning={isLoading} />
        </div>
      ) : (
        <StyledCollapse
          items={collapseContent}
          expandIconPosition="end"
          size={CONTROL_SIZE}
          defaultActiveKey="specialParameters"
          ghost
        />
      )}
    </>
  );
};
