import { useQuery } from '@tanstack/react-query';
import { flightsApi } from 'services/api';
import { useFlightStore } from 'store/flightStore';
import { QUERY_KEY } from './consts';

export interface FlightsApiFilter {
  operatorId?: number;
  aircraftId?: number;
}

const fetchList = async (filter: FlightsApiFilter) => await flightsApi.getFlights(filter);

export const useGetFlights = (filter?: FlightsApiFilter) => {
  const { setList } = useFlightStore(({ setList }) => ({
    setList
  }));

  return useQuery({
    queryKey: [QUERY_KEY, filter],
    queryFn: () => fetchList(filter || {}),
    onSuccess: (response) => {
      setList(response.data.items || []);
    }
  });
};
