import { Flex, Table } from 'antd';
import React from 'react';
import { CONTROL_SIZE } from 'consts/common';

export const RebateTooltip = () => {
  const dataSource = [
    {
      description: '',
      key: '1',
      extra: (
        <Flex align="start" vertical>
          Extra cost
          <br />
          <span style={{ color: '#00000073' }}>+ 10.00</span>
        </Flex>
      ),
      discount: (
        <Flex align="start" vertical>
          Discount
          <br />
          <span style={{ color: '#00000073' }}>- 10.00</span>
        </Flex>
      )
    },
    {
      description: (
        <span>
          Calculated in the
          <br />
          invoice item total
        </span>
      ),
      key: '2',
      extra: 'Yes',
      discount: 'No'
    },
    {
      description: (
        <span>
          Displayed on the
          <br />
          invoice print form
        </span>
      ),
      key: '3',
      extra: 'No',
      discount: 'No'
    },
    {
      description: (
        <span>
          Calculated in a
          <br />
          credit note
        </span>
      ),
      key: '4',
      extra: 'Yes',
      discount: 'Yes'
    },
    {
      description: '',
      key: '5',
      extra: (
        <span>
          affects the current
          <br />
          invoice amount
        </span>
      ),
      discount: (
        <span>
          reduces the next invoice
          <br />
          amount for the customer
        </span>
      )
    }
  ];

  const columns = [
    {
      title: '',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Extra cost',
      dataIndex: 'extra',
      key: 'extra'
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      key: 'discount'
    }
  ];

  return (
    <Table
      size={CONTROL_SIZE}
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      showHeader={false}
    />
  );
};
