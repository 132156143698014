import { SERVER_DATE_TIME_FORMAT } from 'consts/common';
import { RebateHydrated } from 'services/api/data-contracts';
import { Dayjs } from 'dayjs';

export const transformForRequest = (data: RebateHydrated) => {
  const startDate = (data.startDate as unknown as Dayjs)
    .set('hour', 0)
    .set('minutes', 0)
    .set('seconds', 0)
    .format(SERVER_DATE_TIME_FORMAT);
  const endDate = data.endDate
    ? (data.endDate as unknown as Dayjs)
        .set('hour', 23)
        .set('minutes', 59)
        .set('seconds', 59)
        .format(SERVER_DATE_TIME_FORMAT)
    : undefined;

  return {
    ...data,
    startDate,
    endDate,
    isActive: true,
    specialParameters: data.specialParameters
      ? data.specialParameters
          .filter((item) => item)
          .map((item) => ({
            alias: item.alias,
            entityId: +item.entityId,
            notes: item.notes
          }))
      : []
  };
};
