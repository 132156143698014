import React from 'react';
import { StyledList } from '../../DispatcherOrdersWidget';
import { useFlightProgramStore } from 'store/flightProgramStore';
import { useGetFlightPrograms } from 'queries/flightProgram';
import { ProgramsTable } from './ProgramsTable';

interface IProps {
  header: React.ReactNode;
  footer: React.ReactNode;
  headerHeight: number;
}

export const ProgramsView = ({ footer, header, headerHeight = 0 }: IProps) => {
  const { list } = useFlightProgramStore();

  const { isFetching } = useGetFlightPrograms();

  return (
    <StyledList
      dataSource={list}
      header={header}
      footer={footer}
      bordered
      data-testid="programs-view">
      <ProgramsTable isLoading={isFetching} headerHeight={headerHeight} />
    </StyledList>
  );
};
