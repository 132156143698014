import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Drawer, Form, Skeleton } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { useFlightStore } from 'store/flightStore';
import { useCreateFlight, useUpdateFlight } from 'queries/flight';
import { CONTROL_SIZE } from 'consts/common';
import { FlightStatusTag } from './FlightStatusTag';
import { blue } from '@ant-design/colors';
import styled from 'styled-components';
import { Flight } from 'services/api/data-contracts';
import { HeaderButtons } from './common/HeaderButtons';
import { FlightWithDayJs, transformForRender, transformForRequest } from './utils';
import { useFlightsDashboardStore } from 'store/flightsDashboardStore';
import { FormInstance } from 'antd/es/form';
import { TypedOrder, useOrderStore } from 'store/orderStore';
import { DashboardContext, DashboardType } from 'pages/consts';
import { FlightDrawerContent } from './FlightDrawerContent';
import { DetailsErrorBoundary } from 'components/ErrorBoundary/DetailsErrorBoundary';
import { useParams } from 'react-router-dom';
import { useDrawerStore } from 'store/drawerStore';

const StyledDrawer = styled(Drawer)`
  .ant-drawer-header,
  .ant-drawer-body {
    padding: 8px;
  }

  .ant-drawer-header {
    border: none;
  }
`;

const TitleWrapper = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const FormContext = createContext<{
  form: FormInstance<FlightWithDayJs> | null;
  isTouched: boolean;
  setTouched?: React.Dispatch<boolean>;
}>({ form: null, isTouched: false });

export const FlightDrawer = () => {
  const [form] = Form.useForm<FlightWithDayJs>();
  const [isTouched, setTouched] = useState(form.isFieldsTouched());

  const { entityId } = useParams();

  const { flight, setCurrent } = useFlightStore(({ current, setCurrent }) => ({
    flight: current as Flight,
    setCurrent
  }));
  const { setFlightEditMode, isFlightEditMode, setOpenFlightId } = useFlightsDashboardStore(
    ({ setFlightEditMode, isFlightEditMode, setOpenFlightId }) => ({
      setFlightEditMode,
      isFlightEditMode,
      setOpenFlightId
    })
  );
  const { setDrawerClosed, isFlightDrawerOpen } = useDrawerStore(
    ({ setDrawerClosed, openDrawers }) => ({
      setDrawerClosed,
      isFlightDrawerOpen: openDrawers.includes('flight')
    })
  );
  const { current: orderToUpdate } = useOrderStore(({ current }) => ({ current }));

  const { type: dashboardType } = useContext(DashboardContext);

  const formInitialData = flight?.id ? transformForRender(flight) : flight;

  useEffect(() => {
    if (flight) {
      form.setFieldsValue(formInitialData);
    }
  }, [flight]);

  const updateMutation = useUpdateFlight(() => {
    setFlightEditMode(false);
  });
  const createMutation = useCreateFlight(() => {
    setFlightEditMode(false);
  });

  const handleSubmit = async () => {
    await form.validateFields();
    const data = transformForRequest(form.getFieldsValue() as FlightWithDayJs);

    if (flight?.id) {
      updateMutation.mutate({ ...data, id: flight.id });
    } else {
      createMutation.mutate({ flight: data, orderToUpdate: orderToUpdate as TypedOrder });
    }
  };

  const handleCloseDrawer = () => {
    setCurrent(undefined);
    setDrawerClosed('flight');
    setOpenFlightId(undefined);
    setFlightEditMode(false);
    form.setFieldsValue({});
  };

  const isLoading = createMutation.isLoading || updateMutation.isLoading;

  const title = useMemo(() => {
    if (!flight || isLoading) {
      return <Skeleton.Input size={CONTROL_SIZE} style={{ width: 250 }} />;
    }

    if (isFlightEditMode) {
      return (
        <TitleWrapper>
          <SendOutlined style={{ color: blue[5] }} />
          <span>Flight {flight?.id ? 'editing' : 'creation'}</span>
        </TitleWrapper>
      );
    }

    return (
      <TitleWrapper>
        <SendOutlined style={{ color: blue[5] }} />
        <FlightStatusTag status={flight.status} />
        <span>{flight.number}</span>
        <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>V{flight.version}</span>
      </TitleWrapper>
    );
  }, [flight, isFlightEditMode, isLoading]);

  const isRenderInContainer = dashboardType === DashboardType.Dispatcher;

  return (
    <StyledDrawer
      open={isFlightDrawerOpen}
      title={title}
      closable={false}
      width={488}
      className="drawer"
      extra={
        <HeaderButtons
          isLoading={isLoading}
          onSubmit={handleSubmit}
          onClose={handleCloseDrawer}
          isFormTouched={isTouched}
        />
      }
      {...(isRenderInContainer ? { getContainer: false } : {})}>
      <DetailsErrorBoundary
        message="Please check the provided URL or select the flight in widget"
        key={entityId}>
        <FormContext.Provider value={{ form, isTouched, setTouched }}>
          <FlightDrawerContent isLoading={isLoading} />
        </FormContext.Provider>
      </DetailsErrorBoundary>
    </StyledDrawer>
  );
};
