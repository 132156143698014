import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import { Descriptions, Flex, Spin } from 'antd';
import React from 'react';
import {
  InvoiceItemHydratedWithRolledUp,
  Rebate,
  UnitOfMeasure
} from 'services/api/data-contracts';
import { getAmountValue, getUTCDate } from 'helpers';
import { getRebateSign, isAgentFeeRebate, RebateTypeMap } from 'store/rebateStore';

interface IProps {
  invoiceItem: InvoiceItemHydratedWithRolledUp;
  rebate: Rebate & {
    unitOfMeasure?: UnitOfMeasure;
  };
  isLoading: boolean;
}

export const RebateDescriptions = ({ rebate, isLoading, invoiceItem }: IProps) => {
  if (isLoading || !rebate) {
    return (
      <Flex style={{ width: '100%', height: 100 }} align="center" justify="center">
        <Spin />
      </Flex>
    );
  }

  const rebateSign = getRebateSign(rebate.type);
  const isAgentFee = isAgentFeeRebate(rebate.type);

  const descriptions = [
    { label: 'Name', children: rebate.name },
    { label: 'Type', children: RebateTypeMap[rebate.type] },
    { label: 'Start date', children: getUTCDate(rebate.startDate).format(DATE_FORMAT) },
    {
      label: 'End date',
      children: rebate.endDate ? getUTCDate(rebate.endDate).format(DATE_FORMAT) : '-'
    },
    {
      label: 'Amount',
      children: rebateSign + getAmountValue(rebate.value) + (isAgentFee ? '%' : '')
    },
    ...(!isAgentFee
      ? [
          { label: 'Currency', children: rebate.currency || '-' },
          { label: 'Quantity', children: getAmountValue(invoiceItem.quantity, 4) },
          { label: 'UOM', children: rebate.unitOfMeasure?.shortName || '-' }
        ]
      : []),
    { label: 'Total', children: rebateSign + getAmountValue(invoiceItem.rebateTotal, 2) }
  ];

  return (
    <Descriptions
      style={{ marginTop: 12 }}
      items={descriptions}
      size={CONTROL_SIZE}
      column={2}
      bordered
    />
  );
};
