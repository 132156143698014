import { MessageTag } from 'store/messageStore';
import { LinkActionTypes } from 'services/api/data-contracts';

type LinkActionAbility = LinkActionTypes | false;

export const getLinkAction = (
  links: string[],
  selectedMessages: MessageTag[]
): LinkActionAbility => {
  if (
    links.length === 0 ||
    selectedMessages.every((message) => !links?.includes(message.id as string))
  ) {
    return LinkActionTypes.Link;
  }

  if (selectedMessages.every((message) => links?.includes(message.id as string))) {
    return LinkActionTypes.Unlink;
  }

  return false;
};
