import React from 'react';
import { Button, Flex, Form } from 'antd';
import { FlightInput } from '../../common';
import { PlusOutlined } from '@ant-design/icons';
import { CONTROL_SIZE } from 'consts/common';
import { PermitOrder } from 'services/api/data-contracts';
import { red } from '@ant-design/colors';
import { FormInstance } from 'antd/es/form';
import { FlightsApiFilter } from 'queries/flight';

interface IProps {
  existingFlights: number[];
  selectedFlights: Array<number | undefined>;
  apiFilter?: FlightsApiFilter;
  form?: FormInstance<PermitOrder>;
}

export const Legs = ({ existingFlights, selectedFlights, apiFilter, form }: IProps) => (
  <div data-testid="legs-form">
    <Form.List
      name="flightIds"
      rules={[
        {
          validator: async (_, flightIds) => {
            if (!flightIds || flightIds.length < 1) {
              return Promise.reject(new Error('At least one flight should be assigned'));
            } else if (flightIds.some((flightId?: number) => !flightId)) {
              return Promise.reject(new Error('Please specify the flight or remove the row'));
            }
          }
        }
      ]}>
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map(({ key, name }) => (
            <FlightInput
              key={key}
              name={name.toString()}
              label={key === 0 ? 'Legs' : ' '}
              initialFlightId={existingFlights[key]}
              onRemove={() => remove(name)}
              existingFlights={existingFlights}
              selectedFlights={selectedFlights}
              multiple={true}
              apiFilter={apiFilter}
              labelSpan={0}
              onChange={(id) => {
                form?.setFieldValue(['flightIds', key], id);
              }}
            />
          ))}

          <Flex align="middle">
            <Form.Item label="" required>
              <Button
                type="dashed"
                onClick={() => add()}
                icon={<PlusOutlined />}
                size={CONTROL_SIZE}
                disabled={selectedFlights?.includes(undefined)}>
                Add flight
              </Button>
              <span style={{ color: red[5], display: 'inline-block', marginLeft: 16 }}>
                <Form.ErrorList errors={errors} />
              </span>
            </Form.Item>
          </Flex>
        </>
      )}
    </Form.List>
  </div>
);
