import { useEffect } from 'react';

export const useCtrlSShortcut = (callback: () => Promise<void>, condition: boolean) => {
  useEffect(() => {
    const handleKeyDown = async (e: KeyboardEvent) => {
      if (condition && e.ctrlKey && e.key === 's') {
        await callback();
      }
    };

    if (condition) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [callback, condition]);
};
