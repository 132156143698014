import { Form, Select } from 'antd';
import React from 'react';
import { CONTROL_SIZE } from 'consts/common';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import lib from '@blossomfinance/iso-4217-currencies';
import { settingsStore } from 'services/settings/SettingsStore';

interface IProps {
  labelSpan?: number;
  className?: string;
}

export const ContractCurrenciesInput = ({ className, labelSpan }: IProps) => {
  const { allowedCurrencies = [] } = settingsStore.getCurrentTenant();

  const options = allowedCurrencies.map((curr) => {
    const currency = lib.currency(curr);

    return {
      label: `${currency.code} (${currency.name})`,
      value: curr
    };
  });

  return (
    <Form.Item
      name="currencies"
      label="Currency"
      {...(labelSpan && { labelCol: { span: labelSpan } })}
      rules={[
        {
          required: true,
          message: 'Select at least one currency'
        },
        {
          validator: async (_, value) => {
            if (value?.length > 3) {
              return Promise.reject('Number of selected currencies should be not more than 3');
            }

            return Promise.resolve();
          }
        }
      ]}
      className={className}>
      <Select
        mode="multiple"
        allowClear
        style={{ width: '100%' }}
        placeholder="Select from 1 to 3 currencies"
        options={options}
        size={CONTROL_SIZE}
        showSearch
        optionFilterProp="label"
        maxTagCount="responsive"
      />
    </Form.Item>
  );
};
