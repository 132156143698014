import React from 'react';
import { omit } from 'lodash';
import { OrdersFilter } from 'store/ordersDashboardStore';
import { FilterTag } from '../../../components/FilterTag';
import { getDateTagValue } from 'helpers';
import { getJoinedProps, OptionPropName } from 'helpers/dataTransformations/getJoinedProps';
import { ProfileOutlined } from '@ant-design/icons';
import { orderTypes } from 'consts/order';

export const getAppliedOrderFilters = (
  filter: OrdersFilter,
  setFilter: (filter: OrdersFilter) => void,
  setFilterCallback: (filter: OrdersFilter) => void
) => {
  return Object.keys(filter)
    .filter((key) => !!filter[key as keyof typeof filter])
    .map((filterKey) => {
      const key = filterKey as keyof typeof filter;
      let value: string | undefined;

      switch (key) {
        case 'orderStartDate':
        case 'orderEndDate':
          const date = filter[key];
          if (date !== null && date !== undefined) {
            value = getDateTagValue(date);
          }
          break;
        case 'orderTypes':
          if (filter.orderTypes && filter.orderTypes?.length !== orderTypes?.length) {
            value = filter.orderTypes?.join(', ');
          }
          break;
        case 'orderStatuses':
          value = filter[key]?.join(', ');
          break;
        case 'isNotInProgram':
          value = filter[key] ? 'Programs are hidden' : undefined;
          break;
        case 'orderNumbers':
        case 'flightNumbers':
          value = filter[key]?.toString();
          break;
        case 'locations':
          const mappedLocations = filter.locations?.map((location) => location.label.split(',')[0]);
          value = mappedLocations?.join(', ');
          break;
        default:
          const filterValue = filter[key];
          if (Array.isArray(filterValue) && filterValue.length) {
            value = getJoinedProps(filterValue, OptionPropName.Label);
          }
          break;
      }

      if (!value) {
        return null;
      }

      const onClose = () => {
        setFilter(omit(filter, key));
        setFilterCallback(omit(filter, key));
      };

      const icon =
        key.toLowerCase().includes('time') || key.toLowerCase().includes('date') ? (
          <ProfileOutlined style={{ color: '#8C8C8C' }} />
        ) : undefined;

      return <FilterTag name={key} value={value} onClose={onClose} key={key} icon={icon} />;
    });
};
