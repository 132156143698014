import React from 'react';
import { Result } from 'antd';
import { isAxiosError } from 'axios';

type IProps = {
  dictionaryName: string;
  entityId?: string;
  children: React.ReactElement;
};

type IState = {
  hasError: boolean;
  message?: string;
  errorCode?: string;
};

export class DictionaryDetailsErrorBoundary extends React.Component<IProps, IState> {
  static handledErrors = new Set();

  constructor(props: IProps) {
    super(props);
    this.state = { hasError: false, message: undefined };
  }

  static getDerivedStateFromError(error: Error) {
    if (!DictionaryDetailsErrorBoundary.handledErrors.has(error) && isAxiosError(error)) {
      return {
        hasError: true,
        errorCode:
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          error.response?.headers?.['X-Amzn-Trace-Id'] || error.response?.data?.correlationId,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        message: error.response?.data?.message
      };
    }
  }

  componentDidCatch(error: Error) {
    DictionaryDetailsErrorBoundary.handledErrors.add(error);
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if (
      prevProps.dictionaryName !== this.props.dictionaryName ||
      prevProps.entityId !== this.props.entityId
    ) {
      this.setState({ hasError: false });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Result
          status="404"
          title={this.state.message}
          subTitle={
            <>
              <span>Please check your URL or select the item in a table</span>
              <br />
              <span>{this.state.errorCode?.replace('Root=', '')}</span>
            </>
          }
        />
      );
    }

    return this.props.children;
  }
}
