import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Dropdown, MenuProps, message, Tag } from 'antd';
import { useDownloadFile } from '../downloadHooks/useDownloadFile';
import { Model4 as Attachment } from 'services/emailEngineApi/data-contracts';
import { ATTACHMENT_NOTIFICATION } from '../../consts';
import { MessageInstance } from 'antd/es/message/interface';
import { renderFileIcon } from './utils';
import { supportedExtensionsToOpenInNewTab } from '../AttachmentViewer/constants';
import { MESSAGE_DURATION } from 'consts/common';
import { getFileExtension } from '../AttachmentViewer/helpers/extensions';

interface IProps {
  attachment: Attachment;
  messageApi: MessageInstance;
}

const StyledTag = styled(Tag)`
  justify-content: flex-end;
  background-color: white;
  font-size: 12px;
  max-width: 78px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px dashed #d9d9d9;
  &:last-child {
    margin-inline-end: 0;
  }
`;

export const AttachmentTag = ({ attachment, messageApi }: IProps) => {
  const [isDownloadInProcess, setDownloadInProcess] = useState(false);

  const { downloadFileOrThrowError } = useDownloadFile(attachment);

  const extension = useMemo(
    () => getFileExtension(attachment.filename as string),
    [attachment.filename]
  );

  const openLoadingMessage = () => {
    messageApi.open({
      key: 'loading',
      type: 'loading',
      content: ATTACHMENT_NOTIFICATION.IS_LOADING
    });
  };

  const handleDownloadFile = async () => {
    openLoadingMessage();
    setDownloadInProcess(true);
    try {
      await downloadFileOrThrowError();
      message.destroy('loading');
    } catch (error) {
      setDownloadInProcess(false);
      message.destroy('loading');
      messageApi.error(ATTACHMENT_NOTIFICATION.IS_ERROR, MESSAGE_DURATION);
    } finally {
      message.destroy('loading-attachment');
      setDownloadInProcess(false);
    }
  };

  const handleOpenFileInNewTab = async () => {
    if (!supportedExtensionsToOpenInNewTab.includes(extension)) {
      messageApi.warning(
        `File extension ${extension} is not supported to be opened in browser tab. Please download instead.`,
        MESSAGE_DURATION
      );
      return;
    }

    openLoadingMessage();

    try {
      await downloadFileOrThrowError({ isNewTab: true });
      message.destroy('loading');
    } catch (error) {
      message.destroy('loading');
      messageApi.error(ATTACHMENT_NOTIFICATION.IS_ERROR);
    } finally {
      message.destroy('loading');
    }
  };

  const items: MenuProps['items'] = [
    {
      label: 'Download',
      key: 'Download',
      onClick: () => {
        handleDownloadFile();
      },
      disabled: isDownloadInProcess
    },
    {
      label: 'Open in a new tab',
      key: 'Open',
      onClick: () => {
        handleOpenFileInNewTab();
      }
    }
  ];

  return (
    <Dropdown menu={{ items, onClick: (e) => e.domEvent.stopPropagation() }} trigger={['click']}>
      <StyledTag data-testid="attachment-tag">
        {renderFileIcon(attachment.filename as string)}
        <span style={{ marginRight: '2px' }}>{attachment.filename}</span>
      </StyledTag>
    </Dropdown>
  );
};
