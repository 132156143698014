import { useFlightProgramStore } from 'store/flightProgramStore';
import { CollapseProps, Flex } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { StyledCollapse } from 'components/common/StyledCollapse';
import React from 'react';
import { StyledBadge } from 'components/common/StyledBadge';
import { blue } from '@ant-design/colors';
import { ProgramServicesTable } from './ProgramServicesTable';

export const ProgramServices = ({ isLoading }: { isLoading: boolean }) => {
  const { current } = useFlightProgramStore();

  const collapseContent: CollapseProps['items'] = [
    {
      key: 'services',
      label: (
        <Flex gap={4} align="center">
          Services
          <StyledBadge count={current?.nearestOrder?.orderServices.length} color={blue[0]} />
        </Flex>
      ),
      children: <ProgramServicesTable isLoading={isLoading} />
    }
  ];

  return (
    <StyledCollapse
      items={collapseContent}
      expandIconPosition="end"
      size={CONTROL_SIZE}
      defaultActiveKey="services"
      ghost
    />
  );
};
