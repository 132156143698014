import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { UnitOfMeasure } from 'services/api/data-contracts';

type UnitOfMeasureStore = {
  current?: UnitOfMeasure;
  setCurrent: (current?: UnitOfMeasure) => void;
  list: UnitOfMeasure[];
  setList: (current: UnitOfMeasure[]) => void;
};

export const useUnitOfMeasureStore = create<UnitOfMeasureStore>()(
  devtools((set) => ({
    current: undefined,
    setCurrent: (current) => {
      set({ current });
    },
    list: [],
    setList: (list) => {
      set({ list });
    }
  }))
);
