import { FILTER_LABEL, FILTER_LINKS, FLAG } from 'pages/widgets/MessagesWidget/consts';
import { MessagesFilter } from 'store/messageStore';

export const getStartOfDay = (timestamp: string) => {
  const date = new Date(timestamp);
  date.setHours(0, 0, 0, 0);
  return date.toISOString();
};

export const getEndOfDay = (timestamp: string) => {
  const date = new Date(timestamp);
  date.setHours(23, 59, 59, 999);
  return date.toISOString();
};

export const buildDocumentQuery = (
  filter: MessagesFilter,
  linkedMessagesIdsFilter: string[] | null,
  flags: Record<string, string> = {}
) => {
  const filters = [];
  const {
    flags: filterFlags,
    showDone,
    recipientOrSender,
    emailSubject,
    emailBody,
    messageSentDate,
    withAttachment,
    links
  } = filter;

  const hasFilterWithoutTag = filterFlags?.includes(FILTER_LABEL.WITHOUT_LABELS);
  const hasFilterAllTags = filterFlags?.includes(FILTER_LABEL.ALL_LABELS);
  const allCustomTagsTerm = Object.keys(flags).map((flag) => ({ term: { flags: flag } }));

  if (hasFilterWithoutTag) {
    if (filterFlags?.length === 1) {
      filters.push({ bool: { must_not: allCustomTagsTerm } });
    } else if (filterFlags && filterFlags?.length > 1) {
      const otherTags = filterFlags?.filter((flag) => flag !== FILTER_LABEL.WITHOUT_LABELS);
      filters.push({
        bool: {
          must_not: allCustomTagsTerm.filter((term) => !otherTags?.includes(term.term.flags))
        }
      });
    }
  } else if (hasFilterAllTags) {
    filters.push({ bool: { should: allCustomTagsTerm } });
  } else if (filterFlags) {
    const tagsTerm = filterFlags.map((flag) => ({ term: { flags: flag } }));
    filters.push({ bool: { should: tagsTerm } });
  }

  if (!showDone) {
    filters.push({ bool: { must_not: [{ term: { flags: FLAG.DONE } }] } });
  }

  if (recipientOrSender) {
    filters.push({
      bool: {
        should: [
          {
            match_phrase_prefix: {
              'to.address': recipientOrSender
            }
          },
          {
            match_phrase_prefix: {
              'from.address': recipientOrSender
            }
          }
        ]
      }
    });
  }

  if (emailSubject) {
    filters.push({ match_phrase: { subject: emailSubject } });
  }

  if (emailBody) {
    filters.push({ match_phrase: { 'text.plain': emailBody } });
  }

  if (messageSentDate?.[0] && messageSentDate?.[1]) {
    filters.push({
      range: {
        date: {
          gte: getStartOfDay(messageSentDate[0].format()),
          lte: getEndOfDay(messageSentDate[1].format())
        }
      }
    });
  }

  if (withAttachment) {
    filters.push({
      nested: {
        path: 'attachments',
        query: { bool: { must: [{ exists: { field: 'attachments.id' } }] } }
      }
    });
  }

  if (linkedMessagesIdsFilter?.length) {
    filters.push({ terms: { id: [...new Set(linkedMessagesIdsFilter)] } });
  }

  if (links) {
    if (filter.links === FILTER_LINKS.WITHOUT_LINKS) {
      filters.push({
        bool: {
          must_not: [{ term: { flags: FLAG.LINKED } }]
        }
      });
    }

    if (filter.links === FILTER_LINKS.WITH_LINKS) {
      filters.push({
        bool: {
          must: [{ term: { flags: FLAG.LINKED } }]
        }
      });
    }
  }

  return filters.length ? { documentQuery: { bool: { filter: filters } } } : null;
};
