import React from 'react';
import { ColumnsType } from 'antd/es/table';
import {
  CustomerStatus,
  OrderService,
  OrderServiceWithInvoiceItems,
  PaymentType,
  SupplierStatus
} from 'services/api/data-contracts';
import { Button, Checkbox, Flex, Table, Tooltip } from 'antd';
import {
  CustomerStatusTag,
  SupplierStatusTag,
  PaymentTypeIcon
} from '../../components/OrderServiceDrawer';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { numericSorter, stringSorter } from '../../../Dictionaries/utils/tableSorters';
import { getAmountValue } from 'helpers';
import { CONTROL_SIZE } from 'consts/common';
import { EditFilled } from '@ant-design/icons';
import { green, red } from '@ant-design/colors';

export const getColumns = (
  isSelected: (serviceId: number) => boolean,
  onSelect: (e: CheckboxChangeEvent) => void,
  onOpen: (service: OrderService) => void
): ColumnsType<OrderServiceWithInvoiceItems> => [
  {
    dataIndex: 'checkbox',
    key: 'checkbox',
    width: 40,
    render: (_, record) => (
      <Checkbox checked={isSelected(record.id)} onChange={onSelect} value={record.id} />
    ),
    align: 'right'
  },
  Table.EXPAND_COLUMN,
  {
    title: 'CusSt',
    dataIndex: 'customerStatus',
    key: 'customerStatus',
    width: 70,
    render: (value) => value && <CustomerStatusTag status={value as CustomerStatus} />,
    sorter: (a, b) =>
      stringSorter((a.customerStatus || '').toString(), (b.customerStatus || '').toString())
  },
  {
    title: 'Service',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    render: (_, record) => <Tooltip title={record.service?.name}>{record.service?.name}</Tooltip>
  },
  {
    title: 'Supplier',
    dataIndex: 'supplier',
    key: 'supplier',
    ellipsis: true,
    render: (_, record) => <Tooltip title={record.supplier?.name}>{record.supplier?.name}</Tooltip>
  },
  {
    title: 'Sale',
    dataIndex: 'salesAmount',
    key: 'salesAmount',
    ellipsis: true,
    align: 'end',
    render: (_, record) =>
      record.salesAmount && record.salesCurrency
        ? getAmountValue(record.salesAmount, 2, record.salesCurrency)
        : '-',
    sorter: (a, b) => numericSorter(a.salesAmount || 0, b.salesAmount || 0)
  },
  {
    title: 'Cost',
    dataIndex: 'costInSalesCurrency',
    key: 'costInSalesCurrency',
    ellipsis: true,
    align: 'end',
    render: (_, record) => {
      const value =
        record.costInSalesCurrency && record.salesCurrency
          ? record.costInSalesCurrency
          : record.costAmount;

      if (!value) {
        return '-';
      }

      return getAmountValue(value, 2, record.salesCurrency || record.costCurrency);
    },
    sorter: (a, b) => numericSorter(a.costInSalesCurrency || 0, b.costInSalesCurrency || 0)
  },
  {
    title: 'Profit',
    dataIndex: 'profit',
    key: 'profit',
    ellipsis: true,
    align: 'end',
    render: (_, record) =>
      record.profit ? (
        <span style={{ fontWeight: 500, color: record.profit > 0 ? green[6] : red[5] }}>
          {getAmountValue(record.profit, 2, record.salesCurrency || undefined)}
        </span>
      ) : (
        '-'
      ),
    sorter: (a, b) => numericSorter(a.profit || 0, b.profit || 0)
  },
  {
    title: 'SupSt',
    dataIndex: 'supplierStatus',
    key: 'supplierStatus',
    width: 70,
    render: (value) => value && <SupplierStatusTag status={value as SupplierStatus} />,
    sorter: (a, b) =>
      stringSorter((a.supplierStatus || '').toString(), (b.supplierStatus || '').toString())
  },
  {
    title: '$',
    dataIndex: 'paymentType',
    key: 'paymentType',
    width: 32,
    render: (value) => <PaymentTypeIcon paymentType={value as PaymentType} />,
    align: 'center'
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'notes',
    render: (value, record) => (
      <Flex align="center" style={{ overflow: 'hidden' }}>
        <Tooltip title={value}>
          <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
            {value}
          </span>
        </Tooltip>

        <div id="actions">
          <Button
            size={CONTROL_SIZE}
            type="primary"
            shape="circle"
            data-testid="edit-btn"
            icon={<EditFilled />}
            onClick={() => onOpen(record)}
          />
        </div>
      </Flex>
    )
  }
];
