import { capitalize } from 'lodash';
import { DictionaryName, DictionaryTypesMap } from '../models';
import {
  Aircraft,
  Airport,
  City,
  Contract,
  Counterparty,
  Country,
  FlightPurpose,
  FlightType,
  OwnPrice,
  RebateHydrated,
  Service,
  ServiceName,
  UnitOfMeasure,
  VatRate
} from 'services/api/data-contracts';
import { DATE_FORMAT } from 'consts/common';
import { CurrencyRateWithId } from 'store/currencyRateStore';

export const getTitle = (name?: string) => capitalize(name?.split('-').join(' '));

export const getItemTitle = (name?: string, record?: DictionaryTypesMap[DictionaryName]) => {
  if (!record || !name) {
    return ' ';
  }

  switch (name) {
    case 'aircraft':
      return (record as Aircraft).tailNumber;
    case 'contract':
      return (record as Contract).description;
    case 'currency-rate':
      return `${(record as CurrencyRateWithId).sourceCurrency} cross rate from ${(
        record as CurrencyRateWithId
      )?.date.format(DATE_FORMAT)}`;
    case 'airport':
    case 'city':
    case 'counterparty':
    case 'country':
    case 'flight-purpose':
    case 'flight-type':
    case 'service':
    case 'service-name':
    case 'unit-of-measure':
    case 'vat-rate':
      return (
        record as
          | Airport
          | City
          | Counterparty
          | Country
          | FlightPurpose
          | FlightType
          | Service
          | ServiceName
          | UnitOfMeasure
          | VatRate
      ).name;
    case 'own-price':
      return (record as OwnPrice).name;
    case 'rebate':
      return (record as RebateHydrated).serviceName.name;
  }
};
