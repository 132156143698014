import React, { useMemo, useState } from 'react';
import { Select } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { sortBy } from 'lodash';
import { useGetCounterpartiesForDropdown } from 'queries/counterparty';
import { ContractType } from 'services/api/data-contracts';

interface IProps {
  label?: string;
  isSelf?: boolean;
  isOperator?: boolean;
  contractType?: ContractType;
  placeholder?: string;
  listHeight?: number;
  values?: { value: number; label: string }[];
  onChange: (values?: { value: number; label: string }[]) => void;
  dataTestId?: string;
}

export const CounterpartyMultipleInput = ({
  label,
  isSelf,
  isOperator,
  contractType,
  placeholder,
  listHeight = 256,
  values,
  onChange,
  dataTestId
}: IProps) => {
  const [filter, setFilter] = useState('');

  const { isLoading, data } = useGetCounterpartiesForDropdown({
    isSelf,
    isOperator,
    contractType
  });

  const options = useMemo(
    () =>
      sortBy(data?.data.items, (o) => [!o.isActive, o.name]).map(
        ({ name, id, isActive, isSelf }) => ({
          label: name + (isSelf ? ' (self)' : ''),
          value: id,
          disabled: !isActive
        })
      ),
    [data]
  );

  const handleBlur = () => {
    const filteredOptions = options.filter((o) =>
      o.label.toLowerCase().includes(filter.toLowerCase())
    );

    if (filteredOptions.length === 1) {
      const singleAutoSetOption = data?.data.items?.find(
        ({ id }) => id === filteredOptions[0].value
      );

      const newOption = {
        value: singleAutoSetOption?.id as number,
        label: singleAutoSetOption?.name as string
      };

      if (!Array.isArray(values) || !values.some((option) => option.value === newOption.value)) {
        onChange([...(Array.isArray(values) ? values : []), newOption]);
      }
    }

    setFilter('');
  };

  return (
    <Select
      style={{ width: '100%' }}
      listHeight={listHeight}
      placeholder={placeholder || `Select ${label?.toLowerCase()}`}
      options={options}
      size={CONTROL_SIZE}
      loading={isLoading}
      showSearch
      allowClear
      optionFilterProp="label"
      onSearch={setFilter}
      popupMatchSelectWidth={false}
      mode="multiple"
      labelInValue
      maxTagCount="responsive"
      value={values}
      onBlur={handleBlur}
      onChange={(value) => onChange(value)}
      data-testid={dataTestId}
    />
  );
};
