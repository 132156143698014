import { LinkEntityItem, LinkedEntityTypes } from 'services/api/data-contracts';
import { IFilter } from '../useGetLinks';

export const getEntityIdFromTarget = (target: LinkEntityItem) => {
  const entityId = target.entityId?.toString();

  if (target.entityType === LinkedEntityTypes.Order) {
    return { orderNumbers: entityId };
  }

  return { [`${target.entityType}Ids`]: entityId };
};

export const getEntityIdsFromSource = (source: LinkEntityItem[]) => {
  const filter = source.reduce(
    (filter: IFilter, item: LinkEntityItem) => {
      switch (item.entityType) {
        case 'order':
          filter.orderNumbers = filter.orderNumbers
            ? `${filter.orderNumbers},${item.entityId}`
            : `${item.entityId}`;
          break;
        case 'message':
          filter.messageIds = filter.messageIds
            ? `${filter.messageIds},${item.entityId}`
            : `${item.entityId}`;
          break;
        case 'invoice':
          filter.invoiceIds = filter.invoiceIds
            ? `${filter.invoiceIds},${item.entityId}`
            : `${item.entityId}`;
          break;
        case 'flight':
          filter.flightIds = filter.flightIds
            ? `${filter.flightIds},${item.entityId}`
            : `${item.entityId}`;
          break;
      }
      return filter;
    },
    { orderNumbers: '', flightIds: '', invoiceIds: '', messageIds: '' }
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return Object.fromEntries(Object.entries(filter).filter(([_, value]) => value !== ''));
};
