import React, { useEffect, useState } from 'react';
import { Form, InputNumber, Row, Col, Button, Space, Tooltip } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import {
  SettlementEntityType,
  useCreatePaymentPurpose
} from 'queries/paymentPurpose/useCreatePaymentPurpose';
import { Invoice, PaymentFullyHydrated } from 'services/api/data-contracts';
import { RuleObject } from 'antd/es/form';
import { usePaymentPurposeStore } from 'store/paymentPurposeStore';
import { LinkableInvoicesInput } from './LinkableInvoicesInput';
import { usePaymentStore } from 'store/paymentStore';
import { InfoCircleOutlined } from '@ant-design/icons';
import { getAmountValue } from 'helpers';

export const PaymentPurposeForm = () => {
  const [selectedInvoice, setSelectedInvoice] = useState<undefined | Invoice>();
  const [formInitialData, setFormInitialData] = useState({});
  const [validationMessage, setValidationMessage] = useState('');

  const { current: payment } = usePaymentStore(({ current }) => ({
    current: current as PaymentFullyHydrated
  }));

  useEffect(() => {
    if (selectedInvoice) {
      form.setFieldsValue({
        ...(isDifferentCurrency && {
          paidAmountInInvoiceCurrency: selectedInvoice?.amountDue
        }),
        ...(!isDifferentCurrency && {
          paidAmount:
            selectedInvoice?.amountDue < payment.advance
              ? selectedInvoice?.amountDue
              : payment.advance
        })
      });
    } else setFormInitialData({});
  }, [selectedInvoice]);

  const { setCurrent, setCreationMode } = usePaymentPurposeStore(
    ({ setCurrent, setCreationMode }) => ({
      setCurrent,
      setCreationMode
    })
  );

  const [form] = Form.useForm();
  const isDifferentCurrency = selectedInvoice && selectedInvoice?.currency !== payment?.currency;

  const paymentPurposeCreateMutation = useCreatePaymentPurpose((response) => {
    setCurrent(response);
  }, SettlementEntityType.PAYMENT);

  const handleSelectChange = (_: number, option: Invoice) => {
    form.resetFields(['paidAmount', 'paidAmountInInvoiceCurrency']);

    form.setFieldsValue({
      ...(option.currency !== payment.currency && {
        paidAmountInInvoiceCurrency: option?.amountDue,
        paidAmount: payment.advance
      }),
      ...(option.currency === payment.currency && {
        paidAmount: option?.amountDue < payment.advance ? option?.amountDue : payment.advance
      })
    });

    setSelectedInvoice(option);
    setValidationMessage('');
  };

  const handleSubmit = async () => {
    try {
      await form.validateFields();

      if (selectedInvoice?.id && payment?.id) {
        const data = form.getFieldsValue();
        paymentPurposeCreateMutation.mutate({
          paymentId: payment?.id,
          invoiceId: selectedInvoice?.id,
          ...(data.paidAmountInInvoiceCurrency && {
            paidAmountInInvoiceCurrency: data.paidAmountInInvoiceCurrency
          }),
          ...(data.paidAmount && {
            paidAmount: data.paidAmount
          })
        });
        setCreationMode(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const validateAmountInInvoiceCurrency = (_: RuleObject, value: number) => {
    if (selectedInvoice && value > selectedInvoice.amountDue) {
      return Promise.reject(`Limit is ${getAmountValue(selectedInvoice.amountDue)}`);
    }
    return Promise.resolve();
  };

  const validatePaidAmount = (rule: RuleObject, value: number) => {
    if (
      payment &&
      selectedInvoice &&
      !isDifferentCurrency &&
      payment.advance &&
      value > payment.advance &&
      payment.advance < selectedInvoice.amountDue
    ) {
      setValidationMessage('Сan be equal to or less than payment advance');
      return Promise.reject(`Limit is ${getAmountValue(payment.advance)}`);
    }
    if (!isDifferentCurrency && selectedInvoice && value > selectedInvoice.amountDue) {
      setValidationMessage('Сan be equal to or less than invoice amount due');
      return Promise.reject(`Limit is ${getAmountValue(selectedInvoice.amountDue)}`);
    }
    if (
      payment &&
      selectedInvoice &&
      isDifferentCurrency &&
      payment.advance &&
      value > payment.advance
    ) {
      setValidationMessage('Сan be equal to or less than payment advance');
      return Promise.reject(`Limit is ${getAmountValue(payment.advance)}`);
    }
    setValidationMessage('');
    return Promise.resolve();
  };

  return (
    <Form
      form={form}
      name="paymentPurpose"
      layout="vertical"
      autoComplete="off"
      colon={false}
      validateTrigger="onBlur"
      requiredMark={false}
      initialValues={formInitialData}
      data-testid="add-payment-purpose-form"
      className="styled-form">
      <Row gutter={10} align="top">
        <Col span={12}>
          <LinkableInvoicesInput
            name="invoice"
            label="Invoice"
            placeholder="Select the invoice"
            onChange={handleSelectChange}
            paymentId={payment?.id}
          />
        </Col>

        {isDifferentCurrency && (
          <Col span={isDifferentCurrency ? 4 : 5.5}>
            <Form.Item
              name="paidAmountInInvoiceCurrency"
              data-testid="paid-amount-in-invoice-currency"
              label={
                <>
                  <span
                    style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                    Paid Amount
                  </span>
                  <Tooltip title="Paid amount in invoice currency can be equal or less than invoice amount">
                    <InfoCircleOutlined style={{ marginLeft: '6px', cursor: 'pointer' }} />
                  </Tooltip>
                </>
              }
              rules={[
                { required: true, message: 'Is required' },
                {
                  validator: validateAmountInInvoiceCurrency
                }
              ]}>
              <InputNumber
                type="number"
                style={{ width: '100%' }}
                addonBefore={isDifferentCurrency ? selectedInvoice.currency : false}
                size={CONTROL_SIZE}
                min={0}
                precision={2}
              />
            </Form.Item>
          </Col>
        )}

        <Col span={4}>
          <Form.Item
            name="paidAmount"
            label={
              <>
                <span
                  style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                  {isDifferentCurrency ? 'In payment currency' : 'Paid Amount'}
                </span>
                <Tooltip
                  title={
                    validationMessage ||
                    (isDifferentCurrency
                      ? 'Paid amount in payment currency can be equal or less than payment advance'
                      : 'Paid amount in payment currency can be equal or less than invoice amount due or payment advance')
                  }>
                  <InfoCircleOutlined style={{ marginLeft: '6px', cursor: 'pointer' }} />
                </Tooltip>
              </>
            }
            rules={[
              {
                required: true,
                message: 'Is required'
              },
              {
                validator: validatePaidAmount
              }
            ]}>
            <InputNumber
              type="number"
              addonBefore={payment?.currency ?? ' - '}
              min={0}
              size={CONTROL_SIZE}
              precision={2}
            />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Space style={{ marginTop: '26px', marginLeft: 8 }}>
            <Button size={CONTROL_SIZE} onClick={() => setCreationMode(false)}>
              Cancel
            </Button>
            <Button
              disabled={payment?.advance === 0 || !selectedInvoice?.id || !payment?.id}
              type="primary"
              size={CONTROL_SIZE}
              onClick={handleSubmit}>
              Add
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};
