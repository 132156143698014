import { useQuery } from '@tanstack/react-query';
import { paymentPurposesApi } from 'services/api';
import { QUERY_KEY } from './consts';
import { usePaymentPurposeStore } from 'store/paymentPurposeStore';

interface IFilter {
  invoiceId?: number;
  paymentId?: number;
  page?: number;
  pageSize?: number;
}

const fetchList = async (filter: IFilter) => await paymentPurposesApi.getAllPaymentPurposes(filter);

export const useGetPaymentPurposes = (filter: IFilter) => {
  const { setList } = usePaymentPurposeStore(({ setList }) => ({
    setList
  }));

  return useQuery({
    queryKey: [QUERY_KEY, filter],
    queryFn: () => fetchList(filter),
    onSuccess: (response) => {
      setList(response.data);
    },
    enabled: !!filter.invoiceId
  });
};
