import React from 'react';
import { TagFilled } from '@ant-design/icons';

export type CustomTag = {
  key: string;
  label: string;
  color: string;
  icon: React.ReactNode;
};

export const getColoredTags = (flags: Record<string, string>): CustomTag[] =>
  Object.keys(flags).map((key) => ({
    key,
    label: key,
    color: flags[key],
    icon: <TagFilled style={{ color: flags[key] }} />
  }));
