import { Invoice, Payment } from 'services/api/data-contracts';

export const isInvoice = (entity: Payment | Invoice) => entity.hasOwnProperty('invoiceNumber');

export const hasSameCurrencyEntitiesToLink = (entities: (Payment | Invoice)[]) => {
  if (entities.length === 0) {
    return false;
  }

  const payments = entities.filter((entity) => !isInvoice(entity));

  if (payments.length === 0 || payments.length > 1) {
    return false;
  }

  const invoices = entities.filter((entity) => isInvoice(entity));

  if (invoices.length === 0) {
    return false;
  }

  return invoices.every((invoice) => invoice.currency === payments[0].currency);
};

export const sortInvoicesByDateAndAmountDue = (invoices: Invoice[], advance: number) =>
  invoices.sort((a, b) => {
    if (a.amountDue === advance && b.amountDue !== advance) {
      return -1;
    } else if (a.amountDue !== advance && b.amountDue === advance) {
      return 1;
    } else {
      return new Date(a.invoiceDate).getTime() - new Date(b.invoiceDate).getTime();
    }
  });
