import { Flex, Form } from 'antd';
import { StyledDatePicker } from 'components/common/StyledDatePicker';
import { DATE_FORMAT } from 'consts/common';
import React from 'react';
import { Dayjs } from 'dayjs';
import { StyledSkeleton } from './CalculatorDrawer';

interface IProps {
  onSetDate: (value: Dayjs) => void;
  invoiceCurrency?: string;
  sourceCurrency?: string;
  amount?: string;
  isLoading: boolean;
  rate?: number;
  disabled?: boolean;
}

export const RateInput = ({
  onSetDate,
  invoiceCurrency,
  amount,
  sourceCurrency,
  isLoading,
  rate,
  disabled
}: IProps) => (
  <Flex justify="space-between">
    <Flex gap={12} style={{ flexGrow: 1 }}>
      <Form.Item name="rateDate" label="Rate from" style={{ flexBasis: 220 }}>
        <StyledDatePicker
          onSetDate={onSetDate}
          format={DATE_FORMAT}
          placeholder="Select rate date"
          id="rateDate"
          disabled={disabled}
        />
      </Form.Item>

      <StyledSkeleton loading={isLoading} paragraph={false}>
        <span style={{ marginTop: 4, fontFamily: 'PingFang SC', color: 'rgba(0, 0, 0, 0.45)' }}>
          {rate && `${sourceCurrency || ''} ${rate || ''}`}
        </span>
      </StyledSkeleton>
    </Flex>

    <span style={{ fontWeight: 500, marginTop: 4 }}>{`${invoiceCurrency} ${amount}`}</span>
  </Flex>
);
