import React, { useRef } from 'react';
import { ConfigProvider, Empty, Flex, List, Radio, Switch, Typography } from 'antd';
import styled from 'styled-components';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CONTROL_SIZE } from 'consts/common';
import { Footer } from '../components/Footer';
import { useDashboardStore } from 'store/dashboardStore';
import { OrdersView } from './views/OrdersView/OrdersView';
import { ServicesView } from './views/ServicesView/ServicesView';
import { HeaderWithRef } from '../components/Header';
import { useMessageStore } from 'store/messageStore';
import { useFlightsDashboardStore } from 'store/flightsDashboardStore';
import { OrdersWidgetMode, useOrdersDashboardStore, views } from 'store/ordersDashboardStore';
import { useFlightProgramDrawerRouting, useOrderDrawerRouting } from 'routing';
import { ProgramsView } from './views/ProgramsView/ProgramsView';
import { useFlightProgramStore } from 'store/flightProgramStore';
import { LinkedEntityTypes } from 'services/api/data-contracts';

export const StyledList = styled(List)`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  overflow: hidden;
  height: 100%;

  .ant-spin-nested-loading {
    flex-grow: 1;
    overflow-y: scroll;
  }

  .ant-spin-container.ant-spin-blur > div {
    min-height: 0 !important;
  }

  .ant-list-header {
    padding: 0 12px 8px !important;
  }

  .ant-list-footer {
    background-color: #fafafa;
    border-top: 1px solid #d9d9d9;
    z-index: 100;
    padding: 8px 16px;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const DispatcherOrdersWidget = () => {
  const { selectedMessages } = useMessageStore(({ selectedMessages }) => ({ selectedMessages }));
  const { selectedFlights } = useFlightsDashboardStore(({ selectedFlights }) => ({
    selectedFlights
  }));
  const {
    ordersList,
    servicesListItemsCount,
    selectedOrders,
    setSelectedOrders,
    ordersListItemsCount,
    widgetMode,
    setWidgetMode,
    ordersFilter,
    setOrdersFilter
  } = useOrdersDashboardStore();
  const { isFilterByFlights, isFilterByMessages } = useDashboardStore(({ linkFilters }) => ({
    isFilterByFlights: linkFilters.includes('flight-order') && selectedFlights.length > 0,
    isFilterByMessages: linkFilters.includes('message-order') && selectedMessages.length > 0
  }));
  const { programsListItemsCount, setSelectedPrograms, programList, selectedPrograms } =
    useFlightProgramStore(({ list, setSelectedPrograms, selectedPrograms }) => ({
      programsListItemsCount: list.length,
      programList: list,
      setSelectedPrograms,
      selectedPrograms
    }));

  useOrderDrawerRouting();
  useFlightProgramDrawerRouting();

  const handleSelectAll = ({ target: { checked } }: CheckboxChangeEvent) => {
    widgetMode === OrdersWidgetMode.Orders && setSelectedOrders(checked ? ordersList : []);
    widgetMode === OrdersWidgetMode.Programs && setSelectedPrograms(checked ? programList : []);
  };

  const handleChangeWidgetMode = (mode: OrdersWidgetMode) => {
    !!selectedOrders.length && setSelectedOrders([]);
    !!selectedPrograms.length && setSelectedPrograms([]);
    setWidgetMode(mode);
  };

  const headerRef = useRef<HTMLDivElement | null>(null);
  const headerHeight = headerRef.current?.clientHeight ? headerRef.current.clientHeight + 19 : 0;

  const header = (
    <HeaderWithRef
      /* todo: remove widgetMode !== OrdersWidgetMode.Programs when footer program checkbox is enabled */
      showFlightTags={isFilterByFlights && widgetMode !== OrdersWidgetMode.Programs}
      showMessageTags={isFilterByMessages && widgetMode !== OrdersWidgetMode.Programs}
      total={ordersListItemsCount}
      ref={headerRef}
      slot={
        <Flex align="center" justify="flex-end" style={{ marginTop: '8px' }}>
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            defaultValue={widgetMode}
            onChange={(e) => handleChangeWidgetMode(e.target.value)}
            size={CONTROL_SIZE}>
            {views.map((view) => (
              <Radio.Button key={view} value={view} id={`order-widget-${view}-view-selector`}>
                {view}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Flex>
      }
    />
  );

  const footer = (
    <Footer
      selectedItemsCount={
        widgetMode === OrdersWidgetMode.Orders ? selectedOrders.length : selectedPrograms.length
      }
      totalItemsCount={
        widgetMode === OrdersWidgetMode.Orders
          ? ordersListItemsCount
          : widgetMode === OrdersWidgetMode.Programs
            ? programsListItemsCount
            : servicesListItemsCount
      }
      onSelectAll={handleSelectAll}
      widgetType={
        widgetMode === OrdersWidgetMode.Orders ? LinkedEntityTypes.Order : LinkedEntityTypes.Program
      }
      showSelected={
        widgetMode === OrdersWidgetMode.Orders || widgetMode === OrdersWidgetMode.Programs
      }>
      {widgetMode === 'Orders' && (
        <>
          <Switch
            size={CONTROL_SIZE}
            value={ordersFilter.isNotInProgram}
            style={{ bottom: 2 }}
            onChange={(checked) => {
              setOrdersFilter({ ...ordersFilter, isNotInProgram: checked || undefined });
            }}
          />
          <Typography.Text style={{ marginLeft: 8 }}>
            Programs are {ordersFilter.isNotInProgram ? 'hidden' : 'included'}
          </Typography.Text>
        </>
      )}
    </Footer>
  );

  const emptyListPlaceholder = `${widgetMode} not found`;

  return (
    <ConfigProvider
      renderEmpty={() => (
        <Empty description={emptyListPlaceholder} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}>
      {widgetMode === OrdersWidgetMode.Orders ? (
        <OrdersView header={header} footer={footer} headerHeight={headerHeight} />
      ) : widgetMode === OrdersWidgetMode.Programs ? (
        <ProgramsView header={header} footer={footer} headerHeight={headerHeight} />
      ) : (
        <ServicesView header={header} footer={footer} />
      )}
    </ConfigProvider>
  );
};
