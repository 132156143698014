import React, { useState } from 'react';
import { Alert, Flex, Tooltip, Upload } from 'antd';
import {
  CloseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  PaperClipOutlined
} from '@ant-design/icons';
import { RcFile, UploadFile } from 'antd/es/upload';
import { UploadFileStatus } from 'antd/es/upload/interface';
import { Attachment } from '../NewMessage';
import { getBase64 } from '../helpers/getBase64';
import { StyledButton } from '../NewMessage.styles';
import { CONTROL_SIZE } from 'consts/common';
import { gray, red } from '@ant-design/colors';
import { convertBytesToReadableSize } from '../helpers/convertBytesToReadableSize';
import { FILE_SIZE_LIMIT } from 'pages/widgets/MessagesWidget/consts';
import { IoMdAttach } from 'react-icons/io';
import styled from 'styled-components';

interface AttachmentUploadProps {
  files: Attachment[];
  setFiles: React.Dispatch<React.SetStateAction<Attachment[]>>;
  uploadKey: number;
}

interface StyledAttachmentProps {
  $isError: boolean;
}

const StyledAttachment = styled(Flex)<StyledAttachmentProps>`
  background-color: white;
  font-size: 14px;
  justify-content: space-between;
  border: ${({ $isError }) => ($isError ? '1px solid #ff4d4f' : 'none')};
  padding: 4px 5px;
  border-radius: ${({ $isError }) => ($isError ? '8px' : '0')};
  margin-bottom: 4px;

  .action-icons {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  &:hover {
    background-color: #fafafa;
    .action-icons {
      color: ${({ $isError }) => ($isError ? '#ff4d4f' : 'rgba(0, 0, 0, 0.45)')};
      opacity: 1;

      .icon {
        &:hover {
          color: ${({ $isError }) => ($isError ? '#a8071a' : 'rgba(0, 0, 0, 0.9)')};
        }
      }
    }
  }
`;

export const AttachmentUpload = ({ files, setFiles, uploadKey }: AttachmentUploadProps) => {
  const [totalSize, setTotalSize] = useState(0);
  const [fileSizeError, setFileSizeError] = useState(false);

  const handleCloseAlert = () => {
    const filesInsideSizeLimit = files.filter((file) => file.status !== 'error');
    const newTotalSize = filesInsideSizeLimit.reduce((acc, file) => acc + (file?.size || 0), 0);

    setFiles(filesInsideSizeLimit);
    setTotalSize(newTotalSize);

    if (newTotalSize <= FILE_SIZE_LIMIT) {
      setFileSizeError(false);
    }
  };

  const beforeUpload = async (file: RcFile): Promise<boolean> => {
    const totalUpdatedsize = totalSize + file.size;
    const isTotalSizeExceedLimit = totalUpdatedsize > FILE_SIZE_LIMIT;

    const newFile = {
      uid: file.uid,
      name: file.name,
      contentType: file.type,
      size: file.size,
      ...(isTotalSizeExceedLimit
        ? {
            status: 'error' as UploadFileStatus
          }
        : {
            content: await getBase64(file),
            status: 'done' as UploadFileStatus
          })
    };

    setTotalSize(totalUpdatedsize);
    setFiles((prev) => [...prev, newFile]);

    if (isTotalSizeExceedLimit) {
      setFileSizeError(true);
    }

    return false;
  };

  const handleRemoveAttachment = (file: UploadFile) => {
    const size = file.size || 0;
    setFiles((prev) => prev.filter((f) => f.uid !== file.uid));
    setTotalSize((prev) => prev - size);

    if (totalSize - size <= FILE_SIZE_LIMIT) {
      setFileSizeError(false);
    }
  };

  const handleDownloadFile = (file: UploadFile) => {
    const attachment = files.find((f) => f.uid === file.uid);

    if (attachment) {
      let fileData;
      try {
        fileData = atob((attachment as Attachment)?.content as string);
      } catch (e) {
        console.error('Error decoding base64 string', e);
        return;
      }
      const array = [];
      // TextEncoder is not supported in IE
      for (let i = 0; i < fileData.length; i++) {
        array.push(fileData.charCodeAt(i));
      }
      const blob = new Blob([new Uint8Array(array)], { type: file.type });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <>
      <Upload
        beforeUpload={beforeUpload}
        onRemove={handleRemoveAttachment}
        key={uploadKey}
        fileList={files}
        itemRender={(_, file) => {
          const isError = file.status === 'error';
          return (
            <StyledAttachment $isError={isError}>
              <Flex
                style={{
                  maxWidth: '90%'
                }}>
                <PaperClipOutlined
                  style={{
                    color: file.status === 'error' ? red[5] : 'rgba(0, 0, 0, 0.45)',
                    marginRight: '6px'
                  }}
                />

                <div
                  style={{
                    maxWidth: '90%',
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '10px'
                  }}>
                  <span
                    style={{
                      flex: 1,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      color: isError ? red[5] : '#1890FF'
                    }}>
                    {file.name}
                  </span>

                  {file.size && (
                    <span style={{ color: gray[1], marginLeft: '10px' }}>
                      ({convertBytesToReadableSize(file.size)})
                    </span>
                  )}
                </div>
              </Flex>
              <Flex gap={10} className="action-icons">
                {!isError && (
                  <Tooltip title="Download">
                    <DownloadOutlined className="icon" onClick={() => handleDownloadFile(file)} />
                  </Tooltip>
                )}
                <Tooltip title="Delete">
                  <DeleteOutlined onClick={() => handleRemoveAttachment(file)} className="icon" />
                </Tooltip>
              </Flex>
            </StyledAttachment>
          );
        }}
        data-testid="upload-input">
        <Tooltip title="Attach files up to 25MB">
          <StyledButton size={CONTROL_SIZE} icon={<IoMdAttach />} />
        </Tooltip>
      </Upload>
      {fileSizeError && (
        <Alert
          style={{ padding: '9px 16px' }}
          description={
            <div>
              The file limit for attachments is 25MB. Possible options:
              <br />
              <ul>
                <li>using a disc drive to send a link to the file</li>
                <li>selecting another file</li>
              </ul>
            </div>
          }
          type="error"
          closable
          onClose={handleCloseAlert}
          closeIcon={
            <Tooltip title="Remove files not fitting the limit of 25 Mb">
              <CloseOutlined />
            </Tooltip>
          }
        />
      )}
    </>
  );
};
