import { FuelOrder, FuelOrderInput } from 'services/api/data-contracts';
import { Dayjs } from 'dayjs';
import { computeEstimatedParkingTime } from '../../helpers/compute';
import { getUTCDate } from 'helpers';
import { SERVER_DATE_TIME_FORMAT } from 'consts/common';

export type FuelOrderInputWithDayJs = Omit<
  FuelOrderInput,
  'createdAt' | 'startDatetime' | 'endDatetime'
> & {
  createdAt?: Dayjs;
  startEndDate: Dayjs[];
  estimatedParkingTime?: string;
};

export const transformForRender = (order: FuelOrder): FuelOrderInputWithDayJs => ({
  ...order,
  createdAt: order.createdAt ? getUTCDate(order.createdAt) : undefined,
  startEndDate: [getUTCDate(order.startDatetime), getUTCDate(order.endDatetime)],
  estimatedParkingTime: computeEstimatedParkingTime(
    getUTCDate(order.startDatetime),
    getUTCDate(order.endDatetime)
  )
});

export const transformForRequest = (
  order: FuelOrderInputWithDayJs,
  orderId?: number
): FuelOrderInput & { id?: number } => ({
  ...order,
  startDatetime: order.startEndDate[0].format(SERVER_DATE_TIME_FORMAT),
  endDatetime: order.startEndDate[1].format(SERVER_DATE_TIME_FORMAT),
  ...(orderId ? { id: orderId } : {})
});
