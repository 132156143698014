import React from 'react';
import { Button, Dropdown } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { EllipsisOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useDropdownItemClick } from './useDropdownItemClick';
import { FlagList } from 'services/emailEngineApi/data-contracts';

const StyledDiv = styled.div`
  min-width: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IProps {
  id: string;
  isOpen: boolean;
  onOpenChange:
    | ((
        open: boolean,
        info: {
          source: 'menu' | 'trigger';
        }
      ) => void)
    | undefined;
  isMessageSeen: boolean;
  isMessageDone: boolean;
  flags?: FlagList;
}

export const ActionsDropdown = ({
  isOpen,
  onOpenChange,
  id,
  isMessageSeen,
  isMessageDone,
  flags
}: IProps) => {
  const { onClickDropdownItem, dropdownItems } = useDropdownItemClick(
    id,
    isMessageSeen,
    isMessageDone,
    isOpen,
    flags
  );

  return (
    <StyledDiv>
      <Dropdown
        menu={{ items: dropdownItems, onClick: onClickDropdownItem }}
        placement="bottomRight"
        open={isOpen}
        onOpenChange={onOpenChange}
        trigger={['click']}>
        <Button size={CONTROL_SIZE} type="primary" shape="circle" icon={<EllipsisOutlined />} />
      </Dropdown>
    </StyledDiv>
  );
};
