/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { BalanceViewResponse, Error } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class Balance<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Get balance view info
   *
   * @tags balance
   * @name GetBalance
   * @summary Get balance view info
   * @request GET:/balance
   * @secure
   */
  getBalance = (
    query: {
      currency?: string;
      /** @format date */
      startDate: string;
      /** @format date */
      endDate: string;
      counterpartyId: number;
      /** Filter by a list of contractIds separated by comma. Example - contractIds=15,72 etc. */
      contractIds?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<BalanceViewResponse, Error>({
      path: `/balance`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
}
