import { Descriptions, DescriptionsProps } from 'antd';
import { CONTROL_SIZE, DATE_TIME_FORMAT } from 'consts/common';
import React from 'react';
import { TypedCommonOrder } from 'store/orderStore';
import { Airport, City, Country } from 'services/api/data-contracts';
import { getUTCDate, getFlightLabel } from 'helpers';
import { computeEstimatedParkingTime } from '../../../../../DispatcherOrdersWidget/helpers/compute';

interface IProps {
  order: TypedCommonOrder;
}

export const OrderDetailsView = ({ order }: IProps) => {
  let location;
  let locationProps: (string | undefined)[] = [];
  let locationType = 'Airport';

  if (!order.location) {
    location = undefined;
  } else if ('code' in order.location) {
    location = order.location as Airport;
    locationProps = [location.code, location.city?.name, location.city?.country?.name];
  } else if ('country' in order.location) {
    locationType = 'City';
    location = order.location as City;
    locationProps = [location.name, location.country?.name];
  } else {
    locationType = 'Country';
    location = order.location as Country;
    locationProps = [location.name];
  }

  const descriptions: DescriptionsProps['items'] = [
    {
      label: locationType,
      span: 2,
      children: location ? locationProps.filter((i) => !!i).join(', ') : '-'
    },
    {
      label: 'Start D&T',
      children: getUTCDate(order.startDatetime).format(DATE_TIME_FORMAT)
    },
    {
      label: 'End D&T',
      children: getUTCDate(order.endDatetime).format(DATE_TIME_FORMAT)
    },
    {
      label: 'Est park time',
      span: 2,
      children: computeEstimatedParkingTime(
        getUTCDate(order.startDatetime),
        getUTCDate(order.endDatetime)
      )
    },
    {
      label: 'Arrival flt',
      span: 2,
      children: order.arrivalFlight ? getFlightLabel(order.arrivalFlight) : '-'
    },
    {
      label: 'Departure flt',
      span: 2,
      children: order.departureFlight ? getFlightLabel(order.departureFlight) : '-'
    }
  ];

  return (
    <Descriptions
      items={descriptions}
      size={CONTROL_SIZE}
      labelStyle={{ whiteSpace: 'nowrap' }}
      column={2}
      bordered
    />
  );
};
