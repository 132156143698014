import { DictionaryName, DictionaryTypesMap, IFilter, TDictionaryConfig } from '../models';
import { useDictionaryStore } from './useDictionaryStore';
import { useDictionaryQueries } from './useDictionaryQueries';
import { useDictionaryColumns } from './useDictionaryColumns';

export const useDictionaryConfig = (
  name: DictionaryName,
  filter: IFilter
): TDictionaryConfig<DictionaryTypesMap[DictionaryName]> => {
  const store = useDictionaryStore(name);
  const queries = useDictionaryQueries(name, filter);
  const columns = useDictionaryColumns(name, filter.name);

  return {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    columns,
    ...store,
    ...queries
  };
};
