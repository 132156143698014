import { create } from 'zustand';
import { Order, OrderServicesWidgetResponse, OrderType } from 'services/api/data-contracts';
import { devtools } from 'zustand/middleware';
import { RangeValue } from 'store/helpers/types';

export interface OrdersFilter {
  location?: string;
  customer?: string;
  payer?: string;
  provider?: string;
  supplier?: string;
  locations?: { value: string; label: string }[];
  customers?: { value: number; label: string }[];
  payers?: { value: number; label: string }[];
  providers?: { value: number; label: string }[];
  suppliers?: { value: number; label: string }[];
  orderTypes?: OrderType[];
  orderNumbers?: string;
  orderStartDate?: RangeValue;
  orderEndDate?: RangeValue;
  orderStatuses?: string[];
  aircraftTailNumbers?: string;
  ordersAircrafts?: { value: number; label: string }[];
  flightNumbers?: string;
  services?: { value: number; label: string }[];
  isNotInProgram?: boolean;
}

export enum OrdersWidgetMode {
  Programs = 'Programs',
  Orders = 'Orders',
  Services = 'Services'
}

export const views = Object.values(OrdersWidgetMode);

export type ServicesGroup = OrderServicesWidgetResponse['items'][number];

type OrdersDashboardStore = {
  ordersList: Order[];
  setOrdersList: (ordersList: Order[]) => void;
  ordersListItemsCount: number;
  selectedOrders: Order[];
  setSelectedOrders: (selectedOrders: Order[]) => void;
  isOrderEditMode: boolean;
  setOrderEditMode: (isOrderEditMode: boolean) => void;
  ordersFilter: OrdersFilter;
  setOrdersFilter: (ordersFilter?: OrdersFilter) => void;
  servicesList: ServicesGroup[];
  setServicesList: (servicesList: ServicesGroup[]) => void;
  servicesListItemsCount: number;
  openOrder?: { id: number; type: OrderType };
  setOpenOrder: (openOrder?: { id: number; type: OrderType }) => void;
  widgetMode: OrdersWidgetMode;
  setWidgetMode: (widgetMode?: OrdersWidgetMode) => void;
};

export const useOrdersDashboardStore = create<OrdersDashboardStore>()(
  devtools((set) => ({
    ordersList: [],
    setOrdersList: (ordersList) => {
      set({
        ordersList,
        ordersListItemsCount: ordersList.length
      });
    },
    ordersListItemsCount: 0,
    selectedOrders: [],
    setSelectedOrders: (selectedOrders) => {
      set({ selectedOrders });
    },
    isOrderEditMode: false,
    setOrderEditMode: (isOrderEditMode) => {
      set({ isOrderEditMode });
    },
    ordersFilter: {},
    setOrdersFilter: (ordersFilter) => {
      set({ ordersFilter });
    },
    servicesList: [],
    setServicesList: (servicesList) => {
      set({
        servicesList,
        servicesListItemsCount: servicesList
          .map((group) => group.orderServices)
          .reduce((acc, group) => acc.concat(group), []).length
      });
    },
    servicesListItemsCount: 0,
    openOrder: undefined,
    setOpenOrder: (openOrder) => {
      set({ openOrder });
    },
    widgetMode: OrdersWidgetMode.Orders,
    setWidgetMode: (widgetMode) => {
      set({ widgetMode });
    }
  }))
);
