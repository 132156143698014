import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ConfigProvider, Empty, List } from 'antd';
import styled from 'styled-components';
import { useGetFlightsWidget } from 'queries/flight';
import { CONTROL_SIZE } from 'consts/common';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import VirtualList from 'rc-virtual-list';
import { Footer } from '../components/Footer';
import { FlightListItem } from './FlightListItem';
import { blue } from '@ant-design/colors';
import { debounce } from 'lodash';
import { useDashboardStore } from 'store/dashboardStore';
import { HeaderWithRef } from '../components/Header';
import { useMessageStore } from 'store/messageStore';
import { useFlightsDashboardStore } from 'store/flightsDashboardStore';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { DASHBOARD_TOP_PADDING } from '../../consts';
import { useFlightDrawerRouting } from 'routing';
import { useNavigate } from 'react-router-dom';
import { useDrawerStore } from 'store/drawerStore';
import { LinkedEntityTypes } from 'services/api/data-contracts';
import { useFlightProgramStore } from 'store/flightProgramStore';

const StyledList = styled(List)`
  min-width: 262px;
  max-width: 262px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  overflow: hidden;

  .ant-spin-container.ant-spin-blur {
    height: calc(100vh - 169px);
  }

  .ant-spin-nested-loading:has(.ant-spin-container > .ant-list-empty-text) {
    flex-grow: 1;
  }

  .ant-list-header {
    padding: 0 12px 8px !important;
  }

  .ant-list-footer {
    background-color: #fafafa;
    border-top: 1px solid #d9d9d9;
    z-index: 100;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const FlightsWidget = () => {
  const [containerHeight, setContainerHeight] = useState(
    window.innerHeight - DASHBOARD_TOP_PADDING
  );

  const { setOpenFlightId } = useFlightsDashboardStore(({ setOpenFlightId }) => ({
    setOpenFlightId
  }));
  const { setDrawerOpen } = useDrawerStore(({ setDrawerOpen }) => ({ setDrawerOpen }));

  const { selectedMessages } = useMessageStore(({ selectedMessages }) => ({ selectedMessages }));
  const {
    flightsList,
    selectedFlights,
    setSelectedFlights,
    flightsListItemsCount,
    flightsPageNumber,
    setFlightsPageNumber,
    setFlightEditMode
  } = useFlightsDashboardStore(
    ({
      flightsList,
      selectedFlights,
      setSelectedFlights,
      flightsListItemsCount,
      flightsPageNumber,
      setFlightsPageNumber,
      setFlightEditMode
    }) => ({
      flightsList,
      selectedFlights,
      setSelectedFlights,
      flightsListItemsCount,
      flightsPageNumber,
      setFlightsPageNumber,
      setFlightEditMode
    })
  );
  const { selectedOrders } = useOrdersDashboardStore(({ selectedOrders }) => ({ selectedOrders }));
  const { selectedPrograms } = useFlightProgramStore(({ selectedPrograms }) => ({
    selectedPrograms
  }));
  const { isFilterByOrders, isFilterByMessages, isFilterByPrograms } = useDashboardStore(
    ({ linkFilters }) => ({
      selectedOrders,
      isFilterByOrders: linkFilters.includes('order-flight') && selectedOrders.length > 0,
      isFilterByMessages: linkFilters.includes('message-flight') && selectedMessages.length > 0,
      isFilterByPrograms: linkFilters.includes('program-flight') && selectedPrograms.length > 0
    })
  );

  const { isFetching } = useGetFlightsWidget(flightsPageNumber);

  const navigate = useNavigate();

  useFlightDrawerRouting();

  const headerRef = useRef<HTMLDivElement | null>(null);

  const setListHeight = () => {
    const headerHeight = headerRef.current?.clientHeight ? headerRef.current?.clientHeight + 9 : 0;
    setContainerHeight(window.innerHeight - DASHBOARD_TOP_PADDING - headerHeight);
  };

  useEffect(() => {
    const resize = debounce(setListHeight, 100);
    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, []);

  useEffect(() => {
    setListHeight();
  }, [selectedMessages, selectedOrders, isFilterByOrders, isFilterByMessages, isFilterByPrograms]);

  const handleSelectAll = ({ target: { checked } }: CheckboxChangeEvent) => {
    setSelectedFlights(checked ? flightsList : []);
  };

  const handleOpenFlight = (flightId: number) => {
    navigate(`/dashboard/dispatcher/flight/${flightId}${window.location.search}`);
    setOpenFlightId(flightId);
    setFlightEditMode(false);
    setDrawerOpen('flight');
  };

  const handleScroll = ({
    currentTarget: { scrollHeight, scrollTop }
  }: React.UIEvent<HTMLElement, UIEvent>) => {
    const isOnBottom = scrollHeight - scrollTop === containerHeight;
    const allDataLoaded = flightsList.length >= flightsListItemsCount;

    if (isOnBottom && !allDataLoaded && !isFetching) {
      setFlightsPageNumber(flightsPageNumber + 1);
    }
  };

  const footer = (
    <Footer
      showSelected
      selectedItemsCount={selectedFlights.length}
      totalItemsCount={flightsListItemsCount}
      onSelectAll={handleSelectAll}
      widgetType={LinkedEntityTypes.Flight}
    />
  );

  const header =
    isFilterByMessages || isFilterByOrders || isFilterByPrograms ? (
      <HeaderWithRef
        ref={headerRef}
        showMessageTags={isFilterByMessages}
        showOrderTags={isFilterByOrders}
        showProgramTags={isFilterByPrograms}
        total={flightsListItemsCount}
        isSmallExpandButton
      />
    ) : null;

  const listComponent = useMemo(() => {
    if (!flightsList.length) {
      return (
        <ConfigProvider
          renderEmpty={() => (
            <Empty description="Flights not found" image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}>
          <StyledList
            dataSource={flightsList}
            size={CONTROL_SIZE}
            header={header}
            footer={footer}
            bordered
            loading={isFetching}
            data-testid="list"
          />
        </ConfigProvider>
      );
    }

    return (
      <StyledList
        size={CONTROL_SIZE}
        header={header}
        footer={footer}
        bordered
        loading={isFetching}
        data-testid="list">
        <VirtualList
          data={flightsList}
          height={containerHeight}
          itemHeight={82}
          itemKey="id"
          onScroll={handleScroll}>
          {(flight) => (
            <List.Item
              style={{
                backgroundColor: selectedFlights.find(({ id }) => id === flight.id)
                  ? blue[0]
                  : 'inherit',
                padding: 0
              }}
              key={flight.id}>
              <FlightListItem flight={flight} onClick={handleOpenFlight} />
            </List.Item>
          )}
        </VirtualList>
      </StyledList>
    );
  }, [
    flightsList,
    isFilterByMessages,
    isFilterByOrders,
    isFetching,
    containerHeight,
    selectedFlights
  ]);

  return <>{listComponent}</>;
};
