import { Form, Input, InputNumber } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { DictionaryName } from '../../models';
import { FormContext } from '../DictionaryDetails';
import { CountriesInput } from 'components/CountriesInput/CountriesInput';
import styled from 'styled-components';
import { useAircraftStore } from 'store/aircraftStore';
import { CounterpartyInput } from 'components/CounterpartyInput/CounterpartyInput';

const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
`;

export const AircraftDictionaryForm = () => {
  const { dictionaryName } = useParams<{ dictionaryName: DictionaryName }>();
  const form = useContext(FormContext);
  const { current } = useAircraftStore(({ current }) => ({ current }));

  if (!form) {
    return null;
  }

  return (
    <Form
      name={dictionaryName}
      layout="vertical"
      form={form}
      colon={false}
      requiredMark="optional"
      className="styled-form">
      <RowWrapper>
        <Form.Item
          name="tailNumber"
          label="Tail number"
          rules={[{ required: true }, { max: 12 }]}
          normalize={(val) => val.toUpperCase().replace(/ /g, '')}>
          <Input size={CONTROL_SIZE} placeholder="Enter tail number" autoFocus />
        </Form.Item>

        <CountriesInput
          name="countryId"
          label="Reg country"
          placeholder="Select registration country"
          initialFilter={current?.country?.name}
          onChange={(value) => form.setFieldsValue({ countryId: value })}
        />
      </RowWrapper>

      <RowWrapper>
        <Form.Item
          name="type"
          label="Type"
          rules={[{ required: true }, { max: 7 }]}
          normalize={(val) => val.toUpperCase()}>
          <Input size={CONTROL_SIZE} placeholder="Enter tail number" />
        </Form.Item>

        <Form.Item
          name="maxTakeoffWeight"
          label="MTOW"
          rules={[
            {
              validator: async (_, value) => {
                if (+value < 0) {
                  return Promise.reject('MTOW should be positive');
                }
              }
            }
          ]}>
          <InputNumber
            type="number"
            size={CONTROL_SIZE}
            placeholder="Enter MTOW"
            style={{ width: '100%' }}
            addonAfter="kg"
          />
        </Form.Item>
      </RowWrapper>

      <CounterpartyInput label="Operator" name="operatorId" isOperator required={false} />

      <Form.Item name="modification" label="Modification">
        <Input size={CONTROL_SIZE} placeholder="Enter modification" />
      </Form.Item>

      <RowWrapper>
        <Form.Item name="manufacturer" label="Manufacturer">
          <Input size={CONTROL_SIZE} placeholder="Enter manufacturer" />
        </Form.Item>

        <Form.Item name="serialNumber" label="Serial №">
          <Input size={CONTROL_SIZE} placeholder="Enter serial number" />
        </Form.Item>
      </RowWrapper>
    </Form>
  );
};
