import {
  BaseFlightWithAirportCodes,
  Order,
  OrderType,
  SettlementOrder
} from 'services/api/data-contracts';
import { getAmountValue, getUTCDate } from 'helpers';
import React from 'react';
import styled from 'styled-components';
import { DATE_TIME_FORMAT } from 'consts/common';

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 62px 1fr;
  grid-column-gap: 8px;
`;

const Description = styled('span')`
  color: rgba(0, 0, 0, 0.45);
  font-weight: 500;
`;

const getBaseFlightInfo = (flight: BaseFlightWithAirportCodes, airportCodes: string[]) =>
  [
    flight.flightNumber,
    airportCodes.join(' - '),
    `${getUTCDate(flight.actualTimeDeparture || flight.estimatedTimeDeparture).format(
      DATE_TIME_FORMAT
    )} - ${getUTCDate(flight.actualTimeArrival || flight.estimatedTimeArrival).format(DATE_TIME_FORMAT)}`
  ]
    .filter((i) => !!i?.trim())
    .join(' | ');

interface IProps {
  order: SettlementOrder | Order;
}

export const OrderPopoverContent = ({ order }: IProps) => (
  <Grid>
    <Description>Aircraft</Description>
    <span>{order.aircraftTailNumber}</span>

    {order.type === OrderType.PMT ? (
      (order.flights || []).map((flight, idx) => (
        <React.Fragment key={idx}>
          <Description>LEG</Description>
          <span>
            {getBaseFlightInfo(flight, [flight.departureAirport.code, flight.arrivalAirport.code])}
          </span>
        </React.Fragment>
      ))
    ) : (
      <>
        {order.arrivalFlight && (
          <>
            <Description>Arrival</Description>
            <span>
              {getBaseFlightInfo(order.arrivalFlight, [order.arrivalFlight.departureAirport.code])}
            </span>
          </>
        )}

        {order.departureFlight && (
          <>
            <Description>Departure</Description>
            <span>
              {getBaseFlightInfo(order.departureFlight, [
                order.departureFlight.arrivalAirport.code
              ])}
            </span>
          </>
        )}
      </>
    )}

    <Description>Customer</Description>
    <span>{order.customer?.name}</span>

    {order.location && (
      <>
        <Description>Location</Description>
        <span>{order.location?.searchName}</span>
      </>
    )}

    {'totalSales' in order && order.totalSales.length > 0 && (
      <>
        <Description>Currency</Description>
        <span>{order.totalSales.map(({ currency }) => currency).join(', ')}</span>

        <Description>Sale</Description>
        <span>
          {order.totalSales
            .map(({ currency, sale }) => `${getAmountValue(sale)} ${currency}`)
            .join(' | ')}
        </span>

        <Description>Cost</Description>
        <span>
          {order.totalSales
            .map(({ currency, cost }) => `${getAmountValue(cost)} ${currency}`)
            .join(' | ')}
        </span>

        <Description>Profit</Description>
        <span>
          {order.totalSales
            .map(({ currency, profit }) => `${getAmountValue(profit)} ${currency}`)
            .join(' | ')}
        </span>
      </>
    )}
  </Grid>
);
