import React from 'react';
import { Flex, Skeleton, Tooltip } from 'antd';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import { AuditOutlined } from '@ant-design/icons';
import { cyan } from '@ant-design/colors';
import { capitalize } from 'lodash';
import { getUTCDate } from 'helpers';
import { ArrowIcon } from '../../ArrowIcon/ArrowIcon';
import { InvoiceType } from 'services/api/data-contracts';
import styled from 'styled-components';
import { useInvoiceStore } from 'store/invoiceStore';
import { DocumentStatusTag } from 'pages/widgets/components/DocumentStatusTag/DocumentStatusTag';
import { getDocumentStatusTag } from 'helpers/dataTransformations/getDocumentStatusTag';

const TitleWrapper = styled(Flex)`
  gap: 8px;
  align-items: center;
`;

const Divider = styled('span')`
  margin: 0 8px;
`;

export const InvoiceDrawerTitle = () => {
  const { current, isInvoiceEditMode } = useInvoiceStore(({ current, isInvoiceEditMode }) => ({
    current,
    isInvoiceEditMode
  }));

  if (!current || (!isInvoiceEditMode && !current.invoiceNumber)) {
    return (
      <div data-testid="skeleton">
        <Skeleton.Input size={CONTROL_SIZE} style={{ width: 250 }} />
      </div>
    );
  }

  if (isInvoiceEditMode) {
    return (
      <TitleWrapper>
        <AuditOutlined style={{ color: cyan[5], height: '20px', width: '20px' }} />
        <span>
          {`${capitalize(current?.type)} invoice ${current?.id ? 'editing' : 'creation'}`}
        </span>
      </TitleWrapper>
    );
  }

  const invoiceData = [
    `Invoice ${current?.invoiceNumber}`,
    current?.isPosted && 'Posted',
    getUTCDate(current?.invoiceDate).format(DATE_FORMAT),
    current?.isDisputed && 'Disputed'
  ].filter((prop) => !!prop);

  return (
    <TitleWrapper>
      <ArrowIcon type={current?.type as InvoiceType} />

      <DocumentStatusTag status={getDocumentStatusTag(current.isPosted, current.status)} />

      <Tooltip title={invoiceData.join(' | ')}>
        <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
          {invoiceData.map((i, idx) => (
            <span key={i?.toString()}>
              {i}
              {idx !== invoiceData.length - 1 && <Divider>|</Divider>}
            </span>
          ))}
        </span>
      </Tooltip>
    </TitleWrapper>
  );
};
