import { useCallback } from 'react';
import {
  useCreateGHOrder,
  useCreatePermitOrder,
  useCreateFuelOrder,
  useCreateAddOrder,
  useUpdateGHOrder,
  useUpdatePermitOrder,
  useUpdateFuelOrder,
  useUpdateAddOrder,
  GroundHandlingOrderInputWithId,
  PermitOrderInputWithId,
  FuelOrderInputWithId,
  AdditionalServicesOrderInputWithId
} from 'queries/orders';
import {
  PermitOrderInputWithDayJs,
  transformForRequest as transformPmtForRequest
} from '../../DispatcherOrdersWidget/components/utils/permitOrderUtils';
import {
  GHOrderInputWithDayJs,
  transformForRequest as transformGhForRequest
} from '../../DispatcherOrdersWidget/components/utils/ghOrderUtils';
import {
  FuelOrderInputWithDayJs,
  transformForRequest as transformFuelForRequest
} from '../../DispatcherOrdersWidget/components/utils/fuelOrderUtils';
import {
  AddOrderInputWithDayJs,
  transformForRequest as transformAddForRequest
} from '../../DispatcherOrdersWidget/components/utils/addOrderUtils';
import { OrderType } from 'services/api/data-contracts';
import { TypedOrder } from 'store/orderStore';

export const useOrderMutations = (
  handleCancelEdit: (orderType: OrderType, orderId: number) => void
) => {
  const ghCreateMutation = useCreateGHOrder(handleCancelEdit);
  const pmtCreateMutation = useCreatePermitOrder(handleCancelEdit);
  const fuelCreateMutation = useCreateFuelOrder(handleCancelEdit);
  const addCreateMutation = useCreateAddOrder(handleCancelEdit);

  const ghUpdateMutation = useUpdateGHOrder(handleCancelEdit);
  const pmtUpdateMutation = useUpdatePermitOrder(handleCancelEdit);
  const fuelUpdateMutation = useUpdateFuelOrder(handleCancelEdit);
  const addUpdateMutation = useUpdateAddOrder(handleCancelEdit);

  const isMutationLoading =
    ghCreateMutation.isLoading ||
    pmtCreateMutation.isLoading ||
    fuelCreateMutation.isLoading ||
    addCreateMutation.isLoading ||
    ghUpdateMutation.isLoading ||
    pmtUpdateMutation.isLoading ||
    fuelUpdateMutation.isLoading ||
    addUpdateMutation.isLoading;

  const onSubmit = useCallback(
    async (formData: TypedOrder, current?: Partial<TypedOrder>) => {
      if (current?.type === OrderType.GH) {
        const data = transformGhForRequest(
          formData as unknown as GHOrderInputWithDayJs,
          current.id
        );

        current?.id
          ? ghUpdateMutation.mutate(data as GroundHandlingOrderInputWithId)
          : await ghCreateMutation.mutateAsync(data);
      } else if (current?.type === OrderType.PMT) {
        const data = transformPmtForRequest(
          formData as unknown as PermitOrderInputWithDayJs,
          current.id
        );

        current?.id
          ? pmtUpdateMutation.mutate(data as PermitOrderInputWithId)
          : await pmtCreateMutation.mutateAsync(data);
      } else if (current?.type === OrderType.FUEL) {
        const data = transformFuelForRequest(
          formData as unknown as FuelOrderInputWithDayJs,
          current.id
        );

        current?.id
          ? fuelUpdateMutation.mutate(data as FuelOrderInputWithId)
          : await fuelCreateMutation.mutateAsync(data);
      } else if (current?.type === OrderType.ADD) {
        const data = transformAddForRequest(
          formData as unknown as AddOrderInputWithDayJs,
          current.id
        );

        current?.id
          ? addUpdateMutation.mutate(data as AdditionalServicesOrderInputWithId)
          : await addCreateMutation.mutateAsync(data);
      }
    },
    [
      ghCreateMutation,
      pmtCreateMutation,
      fuelCreateMutation,
      addCreateMutation,
      ghUpdateMutation,
      pmtUpdateMutation,
      fuelUpdateMutation,
      addUpdateMutation
    ]
  );

  return { onSubmit, isMutationLoading };
};
