import React, { useContext } from 'react';
import { useOrderStore } from 'store/orderStore';
import { Flight, OrderType, PermitOrder } from 'services/api/data-contracts';
import { Flex, Form } from 'antd';
import { GeneralInfoBlock, LegsBlock, OrderServicesBlock, PermitDetailsBlock } from './accordions';
import { useWatch } from 'antd/es/form/Form';
import { FormInstance } from 'antd/es/form';
import { OrderStatusSelect } from './common';
import { FormContext } from '../OrderDrawer';
import { PermitOrderInputWithDayJs } from '../../../DispatcherOrdersWidget/components/utils/permitOrderUtils';
import { usePermitFormUpdate } from '../../../DispatcherOrdersWidget/helpers/usePermitFormUpdate';

interface IProps {
  isLoading: boolean;
}

export const PermitOrderDetails = ({ isLoading }: IProps) => {
  const { form: formInstance } = useContext(FormContext);
  const form = formInstance as FormInstance<PermitOrderInputWithDayJs>;

  const flightIds = (useWatch('flights', form) || []).map((flight: Flight) => flight.id);

  const { current } = useOrderStore(({ current }) => ({
    current: current as PermitOrder
  }));

  const handleUpdateForm = usePermitFormUpdate(form, OrderType.PMT);

  return (
    <Form
      style={{ justifyContent: 'center' }}
      layout="vertical"
      requiredMark="optional"
      form={form}
      autoComplete="off"
      colon={false}
      onValuesChange={handleUpdateForm}
      className="styled-form">
      <Flex gap={8} vertical>
        <OrderStatusSelect />
        <GeneralInfoBlock />
        <PermitDetailsBlock />
        <LegsBlock isLoading={isLoading} selectedFlights={flightIds || []} />
        <OrderServicesBlock order={current} orderType={OrderType.PMT} />
      </Flex>
    </Form>
  );
};
