import React from 'react';
import { Form, Select } from 'antd';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import { PaymentPurposeHydrated, TransactionType } from 'services/api/data-contracts';
import { PaymentPurposeEditModeBase, usePaymentPurposeStore } from 'store/paymentPurposeStore';
import { usePaymentStore } from 'store/paymentStore';
import { getUTCDate } from 'helpers';
import { PaymentPurposeWithContract } from '../PaymentDrawer/PaymentDetails/PaymentPurposeBlock/columns';

interface IProps {
  onChange: (value: number, option: PaymentPurposeHydrated | PaymentPurposeWithContract) => void;
  editModeBase: PaymentPurposeEditModeBase;
  name?: string;
  disabled?: boolean;
  required?: boolean;
}

export const LinkedPaymentPurposeInput = ({
  onChange,
  editModeBase,
  name = 'paymentPurpose',
  disabled,
  required
}: IProps) => {
  const { paymentPurposes } = usePaymentPurposeStore(({ list }) => ({
    paymentPurposes: list
  }));

  const { current: payment } = usePaymentStore(({ current }) => ({
    current
  }));

  const data =
    editModeBase === PaymentPurposeEditModeBase.INVOICE
      ? (paymentPurposes as unknown as PaymentPurposeHydrated[])
      : (payment?.paymentPurposes as unknown as PaymentPurposeWithContract[]);

  const getLabelFromPayment = ({ payment, createdAt }: PaymentPurposeHydrated) => (
    <span>
      {[
        payment.creditNoteNumber || payment.id,
        createdAt && getUTCDate(createdAt).format(DATE_FORMAT),
        payment.currency,
        payment.type,
        payment.status,
        payment.transactionType === TransactionType.CreditNote && 'Credit note'
      ]
        .filter((i) => !!i)
        .join(' | ')}
    </span>
  );

  const getLabelFromInvoice = (paymentPurpose: PaymentPurposeWithContract) => (
    <span>
      {[
        paymentPurpose.invoice?.contract?.number,
        paymentPurpose.invoice?.invoiceNumber,
        paymentPurpose.invoice?.isPosted ? 'Posted' : 'Not posted',
        paymentPurpose.invoice &&
          paymentPurpose.invoice.invoiceDate &&
          getUTCDate(paymentPurpose.invoice.invoiceDate).format(DATE_FORMAT),
        paymentPurpose.invoice?.currency
      ]
        .filter((i) => !!i)
        .join(' | ')}
    </span>
  );

  const options = data.map((paymentPurpose) => {
    return {
      label:
        editModeBase === PaymentPurposeEditModeBase.INVOICE
          ? getLabelFromPayment(paymentPurpose as PaymentPurposeHydrated)
          : getLabelFromInvoice(paymentPurpose),
      value: paymentPurpose.id
    };
  });

  return (
    <Form.Item
      name={name}
      label={`Payment ${
        editModeBase === PaymentPurposeEditModeBase.INVOICE ? 'purpose' : 'details'
      }`}
      rules={[{ required }]}
      style={{ flexBasis: '100%' }}>
      <Select
        allowClear
        style={{ width: '100%' }}
        options={options}
        size={CONTROL_SIZE}
        disabled={disabled}
        showSearch
        placeholder="Please select"
        onChange={(value) => {
          const paymentPurpose = (data as PaymentPurposeHydrated[])?.find(
            (item) => item.id === value
          );
          onChange(value, paymentPurpose as PaymentPurposeHydrated | PaymentPurposeWithContract);
        }}
      />
    </Form.Item>
  );
};
