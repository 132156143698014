import { Button, Space } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import React from 'react';

interface IProps {
  onCancel: () => void;
  onSubmit: () => void;
  isLoading: boolean;
}

export const HeaderButtons = ({ onSubmit, onCancel, isLoading }: IProps) => (
  <Space>
    <Button size={CONTROL_SIZE} onClick={onCancel} loading={isLoading}>
      Cancel changes
    </Button>
    <Button type="primary" size={CONTROL_SIZE} onClick={onSubmit} loading={isLoading}>
      Add
    </Button>
  </Space>
);
