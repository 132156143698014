import { OrderType } from 'services/api/data-contracts';
import { ProfileOutlined } from '@ant-design/icons';
import React from 'react';

export const getOrderIcon = (orderType: OrderType, color?: string) => {
  switch (orderType) {
    case OrderType.GH:
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.33333 2.66667C4.97971 2.66667 4.64057 2.80714 4.39052 3.05719C4.14048 3.30724 4 3.64638 4 4V8C4 8.35362 4.14048 8.69276 4.39052 8.94281C4.64057 9.19286 4.97971 9.33333 5.33333 9.33333H6.66667C7.02029 9.33333 7.35943 9.19286 7.60948 8.94281C7.85952 8.69276 8 8.35362 8 8V5.33333H6.66667V8H5.33333V4H8V2.66667H5.33333ZM1.33333 0H10.6667C11.0203 0 11.3594 0.140476 11.6095 0.390524C11.8595 0.640573 12 0.979711 12 1.33333V10.6667C12 11.0203 11.8595 11.3594 11.6095 11.6095C11.3594 11.8595 11.0203 12 10.6667 12H1.33333C0.979711 12 0.640573 11.8595 0.390524 11.6095C0.140476 11.3594 0 11.0203 0 10.6667V1.33333C0 0.979711 0.140476 0.640573 0.390524 0.390524C0.640573 0.140476 0.979711 0 1.33333 0Z"
            fill={color}
          />
        </svg>
      );
    case OrderType.PMT:
      return (
        <svg
          width="10"
          height="15"
          viewBox="0 0 10 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.33325 0.333374H1.66659C1.31296 0.333374 0.973825 0.47385 0.723776 0.723898C0.473728 0.973947 0.333252 1.31309 0.333252 1.66671V11C0.333252 11.3537 0.473728 11.6928 0.723776 11.9429C0.973825 12.1929 1.31296 12.3334 1.66659 12.3334H3.66659V14.3334L4.99992 13.6667L6.33325 14.3334V12.3334H8.33325C8.68687 12.3334 9.02601 12.1929 9.27606 11.9429C9.52611 11.6928 9.66659 11.3537 9.66659 11V3.66671M6.33325 9.00004V10.3334L4.99992 9.66671L3.66659 10.3334V9.00004L2.33325 8.33337L3.66659 7.66671V6.33337L4.99992 7.00004L6.33325 6.33337V7.66671L7.66659 8.33337M5.66659 4.33337V1.33337L8.66659 4.33337H5.66659Z"
            fill={color}
          />
        </svg>
      );
    case OrderType.FUEL:
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.0001 4.66667C9.82327 4.66667 9.6537 4.59643 9.52868 4.4714C9.40365 4.34638 9.33342 4.17681 9.33342 4C9.33342 3.82319 9.40365 3.65362 9.52868 3.5286C9.6537 3.40357 9.82327 3.33333 10.0001 3.33333C10.1769 3.33333 10.3465 3.40357 10.4715 3.5286C10.5965 3.65362 10.6667 3.82319 10.6667 4C10.6667 4.17681 10.5965 4.34638 10.4715 4.4714C10.3465 4.59643 10.1769 4.66667 10.0001 4.66667ZM6.00008 4.66667H2.00008V1.33333H6.00008M11.1801 2.82L11.1867 2.81333L8.70675 0.333333L8.00008 1.04L9.40675 2.44667C8.78008 2.66667 8.33342 3.28667 8.33342 4C8.33342 4.21887 8.37652 4.4356 8.46028 4.63781C8.54404 4.84002 8.66681 5.02375 8.82157 5.17851C9.13413 5.49107 9.55805 5.66667 10.0001 5.66667C10.2401 5.66667 10.4601 5.61333 10.6667 5.52667V10.3333C10.6667 10.5101 10.5965 10.6797 10.4715 10.8047C10.3465 10.9298 10.1769 11 10.0001 11C9.82327 11 9.6537 10.9298 9.52868 10.8047C9.40365 10.6797 9.33342 10.5101 9.33342 10.3333V7.33333C9.33342 6.59333 8.73341 6 8.00008 6H7.33342V1.33333C7.33342 0.593333 6.73342 0 6.00008 0H2.00008C1.26008 0 0.666748 0.593333 0.666748 1.33333V12H7.33342V7H8.33342V10.3333C8.33342 10.7754 8.50901 11.1993 8.82157 11.5118C9.13413 11.8244 9.55805 12 10.0001 12C10.219 12 10.4357 11.9569 10.6379 11.8731C10.8401 11.7894 11.0238 11.6666 11.1786 11.5118C11.3334 11.3571 11.4561 11.1733 11.5399 10.9711C11.6236 10.7689 11.6667 10.5522 11.6667 10.3333V4C11.6667 3.54 11.4801 3.12 11.1801 2.82Z"
            fill={color}
          />
        </svg>
      );
    case OrderType.ADD:
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.64192 0.222168V5.35797H11.7777V0.222168H6.64192ZM0.222168 11.7777H5.35797V6.64192H0.222168V11.7777ZM0.222168 0.222168V5.35797H5.35797V0.222168H0.222168ZM6.64192 8.56785H8.56785V6.64192H9.8518V8.56785H11.7777V9.8518H9.8518V11.7777H8.56785V9.8518H6.64192V8.56785Z"
            fill={color}
          />
        </svg>
      );
    default:
      return <ProfileOutlined style={{ color }} />;
  }
};
