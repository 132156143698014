import { useQuery } from '@tanstack/react-query';
import { locationsApi } from 'services/api';
import { QUERY_KEY } from './consts';
import { useLocationStore } from 'store/locationStore';

interface IFilter {
  page: number;
  pageSize?: number;
  name?: string;
  sortBy?: string;
}

const fetchList = async (filter: IFilter) => await locationsApi.getLocations(filter);

export const useGetLocations = (filter: IFilter) => {
  const { setList, list } = useLocationStore(({ setList, list }) => ({
    setList,
    list
  }));

  return useQuery({
    queryKey: [QUERY_KEY, filter],
    queryFn: () => fetchList(filter),
    onSuccess: (response) => {
      if (filter.page > 1) {
        response.data.items = [...list, ...response.data.items];
      }
      setList(response.data);
    }
  });
};
