import { CollapseProps } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import React from 'react';
import { TypedOrder, useOrderStore } from 'store/orderStore';
import { blue } from '@ant-design/colors';
import { OrderType } from 'services/api/data-contracts';
import { OrderServices } from './OrderServices';
import { StyledBadge } from 'components/common/StyledBadge';
import { StyledCollapse } from 'components/common/StyledCollapse';

interface IProps {
  order: TypedOrder;
  orderType: OrderType;
}

export const OrderServicesBlock = ({ order, orderType }: IProps) => {
  const { services } = useOrderStore(({ services }) => ({ services }));

  const collapseContent: CollapseProps['items'] = [
    {
      key: 'services',
      label: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          Services <StyledBadge count={services ? services.length : 0} color={blue[0]} />
        </div>
      ),
      children: <OrderServices order={order} orderId={order.id} orderType={orderType} />
    }
  ];

  return (
    <StyledCollapse
      items={collapseContent}
      expandIconPosition="end"
      size={CONTROL_SIZE}
      defaultActiveKey="services"
      ghost
    />
  );
};
