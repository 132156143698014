import React, { useEffect, useState } from 'react';
import { Button, Flex, Typography, message } from 'antd';
import { Invoice, PaymentPurposeHydrated, PaymentPurposeInput } from 'services/api/data-contracts';
import { CONTROL_SIZE, MESSAGE_DURATION } from 'consts/common';
import {
  SettlementEntityType,
  useCreatePaymentPurpose
} from 'queries/paymentPurpose/useCreatePaymentPurpose';
import { useBalanceDashboardStore } from 'store/balanceDashboardStore';
import { usePaymentStore } from 'store/paymentStore';
import { isInvoice, sortInvoicesByDateAndAmountDue } from '../utils/helpers';
import { getAmountValue } from 'helpers';
import { UseMutationResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { green, red } from '@ant-design/colors';

interface IProps {
  onClose: () => void;
  onClearRowsSelection: () => void;
}

export const PaymentLinkConfirmPopup = ({ onClose, onClearRowsSelection }: IProps) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [displayColor, setDisplayColor] = useState('#000');
  const [isLinkingInProcess, setLinkingInProcess] = useState(false);

  const createMutation = useCreatePaymentPurpose(undefined, SettlementEntityType.BALANCE);

  const { current: payment, setCurrent } = usePaymentStore(({ setCurrent, current }) => ({
    setCurrent,
    current
  }));
  const { setShouldUpdateBalanceView, selectedEntities } = useBalanceDashboardStore(
    ({ setShouldUpdateBalanceView, selectedEntities }) => ({
      setShouldUpdateBalanceView,
      selectedEntities
    })
  );

  const handleClose = () => {
    setLinkingInProcess(false);
    setCurrent(undefined);
    onClose();
    messageApi.destroy();
  };

  const invoices = selectedEntities.filter((entity) => isInvoice(entity));
  const invoicesTotalSum = invoices.reduce((sum, entity) => sum + (entity as Invoice).amountDue, 0);
  const paymentRemainingAdvance = (payment?.advance || 0) - invoicesTotalSum;

  const processInvoice = async (
    invoice: Invoice,
    advance: number,
    paymentId: number,
    createMutation: UseMutationResult<
      AxiosResponse<PaymentPurposeHydrated, unknown>,
      unknown,
      PaymentPurposeInput,
      unknown
    >,
    paidInvoices: string[]
  ) => {
    const data = {
      paymentId: paymentId,
      invoiceId: invoice.id,
      paidAmount: advance < invoice.amountDue ? advance : invoice.amountDue
    };

    try {
      await createMutation.mutateAsync(data as PaymentPurposeInput);
      advance = advance - invoice.invoiceTotal;
      // in actual API response has advance field, update in swagger needed
      // TODO: use BE advance from response instead of FE calculation
      // advance = response.data.payment.advance;

      paidInvoices.push(invoice.invoiceNumber as string);
      return advance;
    } catch {
      const linkedInvocesMessage =
        paidInvoices.length > 0
          ? `Payment was linked to invoice: ${paidInvoices.join(', ')}.`
          : 'No invoices were linked.';
      throw new Error(
        `Failed to link payment ${payment?.id} to invoice ${invoice.invoiceNumber}. Linking process is stopped. ${
          linkedInvocesMessage
        }`
      );
    }
  };

  const handlePaymentLink = async () => {
    setLinkingInProcess(true);
    let advance = payment?.advance as number;
    const paidInvoices: string[] = [];

    const sortedInvoices = sortInvoicesByDateAndAmountDue(invoices as Invoice[], advance);

    for (const invoice of sortedInvoices) {
      if (advance > 0 && invoice.amountDue !== 0) {
        const lastInvoiceToLink =
          sortedInvoices.length === 1 || sortedInvoices.length - 1 === paidInvoices.length;

        if (lastInvoiceToLink) {
          setShouldUpdateBalanceView(true);
        }

        try {
          advance = await processInvoice(
            invoice,
            advance,
            payment?.id as number,
            createMutation,
            paidInvoices
          );
        } catch (error: unknown) {
          if (error instanceof Error) {
            message.error(error.message, MESSAGE_DURATION);
          }
          break;
        }

        if (advance === 0) {
          break;
        }
      }
    }

    !!paidInvoices.length &&
      message.success(
        `Payment ${payment?.id} was successfully linked to Invoices: ${paidInvoices.join(', ')}`,
        MESSAGE_DURATION
      );
    onClearRowsSelection();
    handleClose();
  };

  useEffect(() => {
    if (paymentRemainingAdvance > 0) {
      setDisplayColor(green[6]);
    } else if (paymentRemainingAdvance < 0) {
      setDisplayColor(red[5]);
    }
  }, [paymentRemainingAdvance]);

  const content = (
    <div
      style={{ display: 'inline-block', margin: '10px', height: '130px', width: '332px' }}
      data-testid="confirm-payment-link-popup">
      <Flex vertical style={{ height: '100%' }}>
        <Flex vertical justify="space-between" style={{ height: '100%' }}>
          <Flex>
            <Typography.Text
              style={{
                textAlign: 'left',
                fontWeight: '600'
              }}>
              Linking
              {isLinkingInProcess ? ' in progress...' : ` Invoices to Payment ${payment?.id}`}
            </Typography.Text>
          </Flex>

          <Flex vertical>
            {invoicesTotalSum === 0 && (
              <Typography.Text
                style={{
                  textAlign: 'left'
                }}>
                Selected invoices amount due is 0, please choose other invoices.
              </Typography.Text>
            )}
            {invoicesTotalSum > 0 && (
              <Typography.Text
                style={{
                  textAlign: 'left'
                }}>{`Amount to be paid for selected invoices: ${getAmountValue(invoicesTotalSum)}`}</Typography.Text>
            )}
            <Typography.Text
              style={{
                textAlign: 'left'
              }}>{`Payment advance: ${getAmountValue(payment?.advance)}`}</Typography.Text>
            <Typography.Text
              style={{
                textAlign: 'left'
              }}>
              Payment remains:{' '}
              <span style={{ color: displayColor, fontWeight: '500' }}>
                {paymentRemainingAdvance > 0 ? '+' : ''}
                {getAmountValue(paymentRemainingAdvance)}
              </span>
            </Typography.Text>
          </Flex>
        </Flex>

        <Flex gap={8} justify="flex-end">
          <Button size={CONTROL_SIZE} onClick={handleClose} loading={isLinkingInProcess}>
            Cancel
          </Button>
          <Button
            size={CONTROL_SIZE}
            type="primary"
            onClick={handlePaymentLink}
            disabled={invoicesTotalSum === 0}
            loading={isLinkingInProcess}>
            Link
          </Button>
        </Flex>
      </Flex>
    </div>
  );

  useEffect(() => {
    messageApi.warning({
      content,
      key: 'confirmPaymentLink',
      duration: 0,
      style: {
        marginTop: '25vh'
      }
    });
  }, [messageApi, content]);

  return contextHolder;
};
