/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Country,
  CountryInput,
  CountryListResponse,
  Error,
  ValidationError
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Countries<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Get a list of countries
   *
   * @tags countries
   * @name GetCountries
   * @summary Get a list of countries
   * @request GET:/countries
   * @secure
   */
  getCountries = (
    query?: {
      /**
       * The current page number
       * @format int32
       * @min 1
       * @max 10000
       * @default 1
       */
      page?: number;
      /**
       * The number of items to return per page
       * @format int32
       * @min 1
       * @max 10000
       * @default 10
       */
      pageSize?: number;
      /** Filter by country name. Ex - Italy */
      name?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<CountryListResponse, Error>({
      path: `/countries`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Create a new country
   *
   * @tags countries
   * @name AddCountry
   * @summary Create a new country
   * @request POST:/countries
   * @secure
   */
  addCountry = (data: CountryInput, params: RequestParams = {}) =>
    this.http.request<Country, ValidationError | Error>({
      path: `/countries`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Get a specific country
   *
   * @tags countries
   * @name GetCountryById
   * @summary Get a specific country
   * @request GET:/countries/{countryId}
   * @secure
   */
  getCountryById = (countryId: number, params: RequestParams = {}) =>
    this.http.request<Country, Error>({
      path: `/countries/${countryId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Update a specific country
   *
   * @tags countries
   * @name UpdateCountry
   * @summary Update a specific country
   * @request PUT:/countries/{countryId}
   * @secure
   */
  updateCountry = (countryId: number, data: CountryInput, params: RequestParams = {}) =>
    this.http.request<Country, ValidationError | Error>({
      path: `/countries/${countryId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Delete a specific country
   *
   * @tags countries
   * @name DeleteCountry
   * @summary Delete a specific country
   * @request DELETE:/countries/{countryId}
   * @secure
   */
  deleteCountry = (countryId: number, params: RequestParams = {}) =>
    this.http.request<void, ValidationError | Error>({
      path: `/countries/${countryId}`,
      method: 'DELETE',
      secure: true,
      ...params
    });
}
