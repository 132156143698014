import { bake_cookie, read_cookie, delete_cookie } from 'sfcookies';
import { ITokenStore, TokenStore } from './TokenStore';
import { UserRole } from './hasUserRole';
import { settingsStore } from '../settings/SettingsStore';
import { sortBy } from 'lodash';

export interface ITenant {
  id: string;
  roles: UserRole[];
}

interface IIdTokenStore extends ITokenStore {
  setUserData: () => void;
  getUserData: () => {
    userName: string;
    userShortName: string;
    userEmail: string;
    availableTenants: ITenant[];
  };
}

const extractTenantData = (cognitoGroup: string) => {
  const [id, ...roles] = cognitoGroup
    .split('_')
    .filter((item) => Object.values(UserRole).includes(item as UserRole) || item);

  if (!roles.length || !id) return;

  return { id, roles };
};

const mergeTenantRoles = (tenants: ITenant[]): ITenant[] => {
  const tenantMap: { [key: string]: ITenant } = {};

  tenants.forEach(({ id, roles }) => {
    if (tenantMap[id]) {
      tenantMap[id].roles = Array.from(new Set([...tenantMap[id].roles, ...roles]));
    } else {
      tenantMap[id] = { id, roles: [...roles] };
    }
  });

  return Object.values(tenantMap);
};

class IdTokenStore extends TokenStore implements IIdTokenStore {
  constructor() {
    super('id_token');
  }

  private parseJwt(token: string) {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  }

  setUserData() {
    const token = this.getToken();

    if (token) {
      const parsedToken = this.parseJwt(token);

      const userName =
        parsedToken.name && parsedToken['family_name']
          ? parsedToken.name + ' ' + parsedToken['family_name']
          : '';

      const tenantsData = (parsedToken['cognito:groups'] || [])
        .map(extractTenantData)
        .filter(Boolean);
      const availableTenants = mergeTenantRoles(tenantsData);

      bake_cookie('userName', userName);
      bake_cookie(
        'userShortName',
        userName
          ?.split(' ')
          .map((word) => word[0])
          .join('')
          .toUpperCase()
      );
      bake_cookie('userEmail', parsedToken.email);
      bake_cookie('availableTenants', JSON.stringify(sortBy(availableTenants, 'id')));

      if (availableTenants) {
        settingsStore.setAvailableTenants(availableTenants);
      }
    }
  }

  setToken(token: string) {
    super.setToken(token);
    this.setUserData();
  }

  getUserData() {
    const availableTenants = read_cookie('availableTenants');

    return {
      userName: read_cookie('userName').toString(),
      userShortName: read_cookie('userShortName').toString(),
      userEmail: read_cookie('userEmail').toString(),
      availableTenants:
        typeof availableTenants === 'string' ? (JSON.parse(availableTenants) as ITenant[]) : []
    };
  }

  clearCookies() {
    this.clearToken();
    delete_cookie('userName');
    delete_cookie('userShortName');
    delete_cookie('userEmail');
    delete_cookie('availableTenants');
  }
}
export const idTokenStore = new IdTokenStore();
