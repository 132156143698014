import { DictionaryName, DictionaryTypesMap, TDictionaryStore } from '../models';
import { useFlightTypeStore } from 'store/flightTypeStore';
import { useFlightPurposeStore } from 'store/flightPurposeStore';
import { useServiceStore } from 'store/serviceStore';
import { useCityStore } from 'store/cityStore';
import { useCountryStore } from 'store/countryStore';
import { useAirportStore } from 'store/airportStore';
import { useUnitOfMeasureStore } from 'store/unitOfMeasureStore';
import { useServiceNameStore } from 'store/serviceNameStore';
import { useAircraftStore } from 'store/aircraftStore';
import { useCounterpartyStore } from 'store/counterpartyStore';
import { useContractStore } from 'store/contractStore';
import { useCurrencyRateStore } from 'store/currencyRateStore';
import { useVatRateStore } from 'store/vatRateStore';
import { useOwnPriceStore } from 'store/ownPriceStore';
import { useRebateStore } from 'store/rebateStore';

export const useDictionaryStore = (name: DictionaryName) => {
  switch (name) {
    case 'aircraft': {
      const store = useAircraftStore();
      return store as TDictionaryStore<DictionaryTypesMap['aircraft']>;
    }

    case 'airport': {
      const store = useAirportStore();
      return store as TDictionaryStore<DictionaryTypesMap['airport']>;
    }

    case 'city': {
      const store = useCityStore();
      return store as TDictionaryStore<DictionaryTypesMap['city']>;
    }

    case 'contract': {
      const store = useContractStore();
      return store as TDictionaryStore<DictionaryTypesMap['contract']>;
    }

    case 'counterparty': {
      const store = useCounterpartyStore();
      return store as TDictionaryStore<DictionaryTypesMap['counterparty']>;
    }

    case 'country': {
      const store = useCountryStore();
      return store as TDictionaryStore<DictionaryTypesMap['country']>;
    }

    case 'currency-rate': {
      const store = useCurrencyRateStore();
      return store as TDictionaryStore<DictionaryTypesMap['currency-rate']>;
    }

    case 'flight-type': {
      const store = useFlightTypeStore();
      return store as TDictionaryStore<DictionaryTypesMap['flight-type']>;
    }

    case 'flight-purpose': {
      const store = useFlightPurposeStore();
      return store as TDictionaryStore<DictionaryTypesMap['flight-purpose']>;
    }

    case 'own-price': {
      const store = useOwnPriceStore();
      return store as TDictionaryStore<DictionaryTypesMap['own-price']>;
    }

    case 'rebate': {
      const store = useRebateStore();
      return store as TDictionaryStore<DictionaryTypesMap['rebate']>;
    }

    case 'service': {
      const store = useServiceStore();
      return store as TDictionaryStore<DictionaryTypesMap['service']>;
    }

    case 'service-name': {
      const store = useServiceNameStore();
      return store as TDictionaryStore<DictionaryTypesMap['service-name']>;
    }

    case 'unit-of-measure': {
      const store = useUnitOfMeasureStore();
      return store as TDictionaryStore<DictionaryTypesMap['unit-of-measure']>;
    }

    case 'vat-rate': {
      const store = useVatRateStore();
      return store as TDictionaryStore<DictionaryTypesMap['vat-rate']>;
    }
  }
};
