import React, { useEffect } from 'react';
import { Contract } from 'services/api/data-contracts';
import { Drawer, Form } from 'antd';
import { useCreateContract, useUpdateContract } from 'queries/contract';
import { ContractWithDayJs, useContractStore } from 'store/contractStore';
import { ContractForm } from './ContractForm';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CounterpartyContractView = ({ isOpen, onClose }: IProps) => {
  const [form] = Form.useForm<ContractWithDayJs>();
  const { current } = useContractStore(({ current }) => ({
    current
  }));

  useEffect(() => {
    if (current) {
      form.setFieldsValue(current);
    } else {
      form.resetFields();
    }
  }, [current]);

  const createMutation = useCreateContract();
  const updateMutation = useUpdateContract();

  const handleSubmit = () =>
    form
      .validateFields()
      .then(() => {
        const data = form.getFieldsValue() as unknown as Contract;

        if (!current?.id) {
          createMutation.mutate(data);
        } else {
          updateMutation.mutate(data);
        }
      })
      .then(() => {
        onCancel();
      })
      .catch((err) => err);

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  const isLoading = createMutation.isLoading || updateMutation.isLoading;

  return (
    <Drawer
      title={current?.id ? 'Update contract' : 'New contract'}
      open={isOpen}
      size="large"
      onClose={onCancel}
      maskClosable={false}>
      <ContractForm
        form={form}
        isLoading={isLoading}
        onSubmit={handleSubmit}
        onCancel={onCancel}
        isEditMode={!!current?.id}
      />
    </Drawer>
  );
};
