/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Error,
  PaymentPurposeHydrated,
  PaymentPurposeInput,
  PaymentPurposeListResponse,
  PaymentPurposeUpdateInput,
  ValidationError
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class PaymentPurposes<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Get a list of PaymentPurpose records
   *
   * @tags payment purposes
   * @name GetAllPaymentPurposes
   * @summary Get a list of PaymentPurpose records
   * @request GET:/payment-purposes
   * @secure
   */
  getAllPaymentPurposes = (
    query?: {
      /**
       * The current page number
       * @format int32
       * @min 1
       * @max 10000
       * @default 1
       */
      page?: number;
      /**
       * The number of items to return per page
       * @format int32
       * @min 1
       * @max 10000
       * @default 10
       */
      pageSize?: number;
      /**
       * Filter by id of Invoice
       * @format int32
       * @min 1
       * @max 1000000
       */
      invoiceId?: number;
      /**
       * Filter by id of a Payment
       * @format int32
       * @min 1
       * @max 1000000
       */
      paymentId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PaymentPurposeListResponse, Error>({
      path: `/payment-purposes`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Create a new PaymentPurpose record
   *
   * @tags payment purposes
   * @name AddPaymentPurpose
   * @summary Create a new PaymentPurpose record
   * @request POST:/payment-purposes
   * @secure
   */
  addPaymentPurpose = (data: PaymentPurposeInput, params: RequestParams = {}) =>
    this.http.request<PaymentPurposeHydrated, ValidationError | Error>({
      path: `/payment-purposes`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Update a list of PaymentPurpose documents or create record if id is not provided
   *
   * @tags payment purposes
   * @name UpdatePaymentPurposes
   * @summary Update a list of PaymentPurpose documents
   * @request PUT:/payment-purposes
   * @secure
   */
  updatePaymentPurposes = (data: PaymentPurposeUpdateInput, params: RequestParams = {}) =>
    this.http.request<PaymentPurposeHydrated[], ValidationError | Error>({
      path: `/payment-purposes`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Get a specific PaymentPurpose record by id
   *
   * @tags payment purposes
   * @name GetPaymentPurposeById
   * @summary Get a specific PaymentPurpose record
   * @request GET:/payment-purposes/{paymentPurposeId}
   * @secure
   */
  getPaymentPurposeById = (paymentPurposeId: number, params: RequestParams = {}) =>
    this.http.request<PaymentPurposeHydrated, Error>({
      path: `/payment-purposes/${paymentPurposeId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Delete a specific PaymentPurpose record by id
   *
   * @tags payment purposes
   * @name DeletePaymentPurpose
   * @summary Delete a specific PaymentPurpose record
   * @request DELETE:/payment-purposes/{paymentPurposeId}
   * @secure
   */
  deletePaymentPurpose = (paymentPurposeId: number, params: RequestParams = {}) =>
    this.http.request<void, ValidationError | Error>({
      path: `/payment-purposes/${paymentPurposeId}`,
      method: 'DELETE',
      secure: true,
      ...params
    });
}
