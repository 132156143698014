import {
  AddressList,
  FromAddress,
  Model2 as CCAddress
} from 'services/emailEngineApi/data-contracts';
import { ACTION, AUTO_PREFIX, AutoPrefix, FORM_FIELD_NAMES } from '../constants';
import { MessageWithBody, NewMessageMode } from 'store/messageStore';
import { LABELS } from 'queries/messages/consts';
import { SIGNATURE_DELIMITER } from 'pages/widgets/MessagesWidget/consts';

export const getRecipientOptions = (recipients: AddressList | FromAddress | CCAddress) =>
  Array.isArray(recipients)
    ? recipients.map((recipient) => recipient.address)
    : [recipients.address];

const getSubjectWithPrefix = (prefix: AutoPrefix, subject?: string) =>
  subject && subject.toLowerCase().startsWith(prefix.toLowerCase())
    ? subject
    : `${prefix} ${subject || ''}`;

export const getInitialFormValues = (
  newMessageMode: NewMessageMode,
  currentMessage?: MessageWithBody
) => {
  if (!currentMessage) return {};

  const baseValues = {
    [FORM_FIELD_NAMES.body]: `
${SIGNATURE_DELIMITER}
${currentMessage.body}`
  };

  const isSent = currentMessage.labels?.includes(LABELS.SENT);
  const recipients = isSent ? currentMessage.to : currentMessage.from;
  const recipientsOptions = recipients ? getRecipientOptions(recipients) : [];

  switch (newMessageMode) {
    case 'fromOrder':
      return currentMessage;
    case ACTION.NEW:
      return currentMessage.body ? baseValues : {};
    case ACTION.REPLY:
      return {
        [FORM_FIELD_NAMES.to]: recipientsOptions,
        [FORM_FIELD_NAMES.subject]: getSubjectWithPrefix(AUTO_PREFIX.REPLY, currentMessage.subject),
        ...baseValues
      };
    case ACTION.REPLY_ALL:
      return {
        [FORM_FIELD_NAMES.to]: recipientsOptions,
        [FORM_FIELD_NAMES.copy]: currentMessage.cc?.length
          ? getRecipientOptions(currentMessage.cc)
          : [],
        [FORM_FIELD_NAMES.subject]: getSubjectWithPrefix(AUTO_PREFIX.REPLY, currentMessage.subject),
        ...baseValues
      };
    case ACTION.FORWARD:
      return {
        [FORM_FIELD_NAMES.subject]: getSubjectWithPrefix(
          AUTO_PREFIX.FORWARD,
          currentMessage.subject
        ),
        ...baseValues
      };
    case ACTION.WITH_INVOICE_ATTACHMENT:
    case ACTION.WITH_PAYMENT_ATTACHMENT:
      return {
        [FORM_FIELD_NAMES.subject]: currentMessage.subject,
        ...baseValues
      };
    default:
      return {};
  }
};
