import { Menu, MenuProps } from 'antd';
import { DictionaryName } from '../models';
import { hasCurrentUserRole, UserRole } from 'services/auth/hasUserRole';
import { Link } from 'react-router-dom';
import React, { useMemo } from 'react';
import { settingsStore } from 'services/settings/SettingsStore';
import { MenuDividerType } from 'antd/es/menu/interface';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(label: string, children?: MenuItem[], type?: 'group'): MenuItem {
  const key = label.toLowerCase().split(' ').join('-');

  return {
    key,
    children,
    label: <Link to={`/dictionaries/${key}`}>{label}</Link>,
    type
  } as MenuItem;
}

export const DictionariesMenu = ({ dictionary }: { dictionary: DictionaryName }) => {
  const currentTenant = settingsStore.getCurrentTenant();

  const items: MenuProps['items'] = useMemo(() => {
    const isNotTenantManager = !hasCurrentUserRole([UserRole.TenantManager]);
    const isSettlementUser = hasCurrentUserRole([UserRole.Settlement]);

    return [
      getItem('Airport' as DictionaryName),
      getItem('Country' as DictionaryName),
      getItem('City' as DictionaryName),

      { type: 'divider' },

      ...(isNotTenantManager ? [getItem('Counterparty' as DictionaryName)] : []),
      ...(isNotTenantManager ? [getItem('Contract' as DictionaryName)] : []),

      ...(isNotTenantManager ? [{ type: 'divider' } as MenuDividerType] : []),

      ...(isNotTenantManager ? [getItem('Service' as DictionaryName)] : []),
      ...(isNotTenantManager ? [getItem('Aircraft' as DictionaryName)] : []),
      getItem('Flight purpose' as DictionaryName),
      getItem('Flight type' as DictionaryName),

      { type: 'divider' },

      ...(isNotTenantManager ? [getItem('Service name' as DictionaryName)] : []),
      getItem('Unit of measure' as DictionaryName),
      ...(isSettlementUser ? [getItem('Vat rate' as DictionaryName)] : []),
      ...(isSettlementUser ? [getItem('Currency rate' as DictionaryName)] : []),
      ...(isSettlementUser ? [getItem('Own price' as DictionaryName)] : []),
      ...(isSettlementUser ? [getItem('Rebate' as DictionaryName)] : [])
    ];
  }, [currentTenant]);

  return (
    <Menu
      style={{ width: 158, overflow: 'scroll', flexShrink: 0 }}
      selectedKeys={[dictionary]}
      mode="inline"
      items={items}
    />
  );
};
