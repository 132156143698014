import { Flights } from './Flights';
import { Orders } from './Orders';
import { FlightTypes } from './FlightTypes';
import { FlightPurposes } from './FlightPurposes';
import { Services } from './Services';
import { Counterparties } from './Counterparties';
import { Contracts } from './Contracts';
import { Aircrafts } from './Aircrafts';
import { Countries } from './Countries';
import { Cities } from './Cities';
import { Airports } from './Airports';
import { ApiConfig, HttpClient } from './http-client';
import { UnitOfMeasures } from './UnitOfMeasures';
import { ServiceNames } from './ServiceNames';
import { Locations } from './Locations';
import { Links } from './Links';
import { Payments } from './Payments';
import { Invoices } from './Invoices';
import { CurrencyRates } from './CurrencyRates';
import { VatRates } from './VatRates';
import { InvoiceItems } from './InvoiceItems';
import { PaymentPurposes } from './PaymentPurposes';
import { Settlement } from './Settlement';
import { OrderServices } from './OrderServices';
import { Balance } from './Balance';
import { OwnPrices } from './OwnPrices';
import { Profile } from './Profile';
import { Programs } from './Programs';
import { Rebates } from './Rebates';

const config: ApiConfig = {
  baseURL: process.env.REACT_APP_API_PATH
};

const client = new HttpClient(config);

export const flightsApi = new Flights(client);
export const flightProgramsApi = new Programs(client);
export const ordersApi = new Orders(client);
export const orderServicesApi = new OrderServices(client);
export const linksApi = new Links(client);

export const profileApi = new Profile(client);

// Dictionaries
export const aircraftsApi = new Aircrafts(client);
export const airportsApi = new Airports(client);
export const citiesApi = new Cities(client);
export const contractsApi = new Contracts(client);
export const counterpartiesApi = new Counterparties(client);
export const countriesApi = new Countries(client);
export const currencyRatesApi = new CurrencyRates(client);
export const flightPurposesApi = new FlightPurposes(client);
export const flightTypesApi = new FlightTypes(client);
export const locationsApi = new Locations(client);
export const servicesApi = new Services(client);
export const serviceNamesApi = new ServiceNames(client);
export const unitsOfMeasureApi = new UnitOfMeasures(client);
export const vatRatesApi = new VatRates(client);
export const ownPricesApi = new OwnPrices(client);
export const rebatesApi = new Rebates(client);

// Settlement
export const settlementApi = new Settlement(client);
export const paymentsApi = new Payments(client);
export const invoicesApi = new Invoices(client);
export const invoiceItemsApi = new InvoiceItems(client);
export const paymentPurposesApi = new PaymentPurposes(client);
export const balanceApi = new Balance(client);
