import React, { useMemo } from 'react';
import { FlightsFilter, useFlightsDashboardStore } from 'store/flightsDashboardStore';
import {
  OrdersFilter,
  OrdersWidgetMode,
  useOrdersDashboardStore
} from 'store/ordersDashboardStore';
import { MessagesFilter } from 'store/messageStore';
import { AppliedMessageFilters } from '../../components/MessageFilters/AppliedMessageFilters';
import {
  FilterCallbackSetter,
  getAppliedOrderWidgetTabsFilters
} from './helpers/getAppliedOrderWidgetTabsFilters';
import { getAppliedFlightFilters } from './helpers/getAppliedFlightFilters';
import { AppliedFiltersContainer } from '../../components/AppliedFiltersContainer';

interface IProps {
  filter: FlightsFilter & OrdersFilter & MessagesFilter;
  setFilter: (filter: FlightsFilter & OrdersFilter & MessagesFilter) => void;
  ordersWidgetMode: OrdersWidgetMode;
}

export const AppliedDispatcherFilters = ({ filter, setFilter, ordersWidgetMode }: IProps) => {
  const { flightsFilter, setFlightsFilter } = useFlightsDashboardStore(
    ({ flightsFilter, setFlightsFilter }) => ({
      flightsFilter,
      setFlightsFilter
    })
  );
  const { ordersFilter, setOrdersFilter } = useOrdersDashboardStore(
    ({ ordersFilter, setOrdersFilter }) => ({
      ordersFilter,
      setOrdersFilter
    })
  );

  const appliedFlightFilters = useMemo(
    () => getAppliedFlightFilters(flightsFilter, setFilter, setFlightsFilter),
    [flightsFilter, setFilter, setFlightsFilter]
  );

  const appliedOrderWidgetTabsFilters = useMemo(
    () =>
      getAppliedOrderWidgetTabsFilters(
        ordersFilter,
        ordersWidgetMode,
        setFilter,
        setOrdersFilter as FilterCallbackSetter
      ),
    [ordersFilter, setFilter, setOrdersFilter, ordersWidgetMode]
  );

  return (
    <AppliedFiltersContainer>
      <AppliedMessageFilters filter={filter} setFilter={setFilter} />
      {appliedFlightFilters}
      {appliedOrderWidgetTabsFilters}
    </AppliedFiltersContainer>
  );
};
