import { MailboxSettings, ProfileResponse, TenantSettings } from 'services/api/data-contracts';
import { DashboardType } from 'pages/consts';
import { ITenant } from '../auth/IdTokenStore';
import { UserRole } from '../auth/hasUserRole';
import { queryClient } from 'queries';

type ApiVersion =
  | {
      gitTag: string;
      gitSha: string;
    }
  | undefined;

export interface ISettingsStore {
  setAppSettings: (data: ProfileResponse) => void;
  getApiVersion: () => ApiVersion;
  setCurrentDashboard: (type?: DashboardType) => void;
  getMailboxSettings: () => MailboxSettings | undefined;
  getTenantSettings: () => TenantSettings[];
  setAvailableTenants: (availableTenants: ITenant[]) => void;
  getCurrentTenantId: () => string | null;
  setCurrentTenantId: (tenantId: string) => void;
  getCurrentTenantRoles: () => UserRole[];
  getCurrentTenant: () => ITenant & Partial<TenantSettings>;
}

export class SettingsStore implements ISettingsStore {
  apiVersion: ApiVersion = undefined;

  currentDashboard: DashboardType | undefined = undefined;

  tenantSettings: TenantSettings[] = [];

  setAppSettings(data: ProfileResponse) {
    this.apiVersion = data.apiVersion;
    this.tenantSettings = data.profile.settings.tenants;
  }

  getApiVersion() {
    return this.apiVersion;
  }

  setCurrentDashboard(dashboardType?: DashboardType) {
    this.currentDashboard = dashboardType;
  }

  getMailboxSettings() {
    const currentTenantSettings = this.tenantSettings.find(
      ({ id }) => id === this.getCurrentTenantId()
    );

    if (this.currentDashboard === DashboardType.Dispatcher) {
      return currentTenantSettings?.widgets.dispatcherMessages;
    }

    if (this.currentDashboard === DashboardType.Settlement) {
      return currentTenantSettings?.widgets.settlementMessages;
    }
  }

  getTenantSettings() {
    return this.tenantSettings;
  }

  setAvailableTenants(availableTenants: ITenant[]) {
    localStorage.setItem('availableTenants', JSON.stringify(availableTenants));
    this.setCurrentTenantId(availableTenants[0].id);
  }

  setCurrentTenantId(tenantId: string) {
    localStorage.setItem('currentTenantId', tenantId);
    queryClient.invalidateQueries();
  }

  getCurrentTenantId() {
    return localStorage.getItem('currentTenantId');
  }

  getCurrentTenantRoles() {
    const availableTenants: ITenant[] =
      JSON.parse(`${localStorage.getItem('availableTenants')}`) || [];

    return availableTenants.find(({ id }) => id === this.getCurrentTenantId())?.roles || [];
  }

  getCurrentTenant() {
    const settings = this.tenantSettings.find(({ id }) => id === this.getCurrentTenantId());

    return {
      ...settings,
      exchangeCurrencies: (settings?.allowedCurrencies || []).filter(
        (curr) => curr !== settings?.mainCurrency
      ),
      roles: this.getCurrentTenantRoles(),
      id: this.getCurrentTenantId() as string
    };
  }
}

export const settingsStore = new SettingsStore();
