import { Form, Input, InputNumber } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { DictionaryName } from '../../models';
import { FormContext } from '../DictionaryDetails';
import styled from 'styled-components';
import { BooleanRadioGroup } from 'components/common/BooleanRadioGroup';
import { useVatRateStore } from 'store/vatRateStore';

const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
`;

export const VatRateDictionaryForm = () => {
  const { dictionaryName } = useParams<{ dictionaryName: DictionaryName }>();
  const form = useContext(FormContext);

  const { list, current } = useVatRateStore(({ list, current }) => ({
    list,
    current
  }));

  const isNotUniqueName = (value: string) =>
    list.filter(({ name, id }) => name === value && id !== current?.id).length;

  if (!form) {
    return null;
  }

  return (
    <Form
      name={dictionaryName}
      colon={false}
      requiredMark="optional"
      layout="vertical"
      form={form}
      initialValues={{ isActive: true, rate: 0 }}
      className="styled-form">
      <Form.Item rules={[{ required: true }]} name="isActive" label="Active">
        <BooleanRadioGroup
          testId="Active"
          onChange={(value) => form?.setFieldValue('isActive', value)}
          value={form.getFieldValue('isActive')}
        />
      </Form.Item>

      <RowWrapper>
        <Form.Item
          rules={[
            { required: true },
            {
              message: 'Name should be unique',
              validator: (_, value) =>
                isNotUniqueName(value) ? Promise.reject() : Promise.resolve()
            }
          ]}
          name="name"
          label="Name">
          <Input size={CONTROL_SIZE} autoFocus />
        </Form.Item>

        <Form.Item
          name="rate"
          label="Rate"
          rules={[{ min: 0, type: 'number' }, { required: true }]}>
          <InputNumber type="number" size={CONTROL_SIZE} min={0} />
        </Form.Item>
      </RowWrapper>

      <Form.Item label="Notes" name="notes">
        <Input.TextArea size={CONTROL_SIZE} autoSize={{ minRows: 10, maxRows: 20 }} />
      </Form.Item>
    </Form>
  );
};
