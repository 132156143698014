import { useFlightProgramStore } from 'store/flightProgramStore';
import { CollapseProps, Descriptions, DescriptionsProps, Spin } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { StyledCollapse } from 'components/common/StyledCollapse';
import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { OrderType, PermitOrder } from 'services/api/data-contracts';
import { useDrawerStore } from 'store/drawerStore';
import { getFlightLabel } from 'helpers';

const SpinWrapper = styled('div')`
  height: 126px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NearestOrderDetails = () => {
  const navigate = useNavigate();

  const { current } = useFlightProgramStore();
  const { setOpenOrder } = useOrdersDashboardStore();
  const { setDrawerOpen } = useDrawerStore();

  const descriptions: DescriptionsProps['items'] = [
    {
      label: 'Arrival flight',
      children: current?.nearestOrder?.arrivalFlight
        ? getFlightLabel(current?.nearestOrder?.arrivalFlight)
        : '-'
    },
    {
      label: 'Departure flight',
      children: current?.nearestOrder?.departureFlight
        ? getFlightLabel(current?.nearestOrder?.departureFlight)
        : '-'
    }
  ];

  const permitDescriptions: DescriptionsProps['items'] = (
    (current?.nearestOrder as unknown as PermitOrder)?.flights || []
  ).map((flight) => ({
    label: 'LEG',
    children: flight ? getFlightLabel(flight) : '-'
  }));

  const collapseContent: CollapseProps['items'] = [
    {
      key: 'general',
      label: 'Nearest order',
      extra: (
        <a
          style={{ color: '#00000073', textDecoration: 'underline' }}
          onClick={(e) => {
            e.stopPropagation();

            const { type, number, id } = current?.nearestOrder || {};

            navigate(`/dashboard/dispatcher/order/${type}-${number}${window.location.search}`);
            setOpenOrder({ type: type as OrderType, id: id as number });
            setDrawerOpen('order');
          }}>
          Go to order
        </a>
      ),
      children: current ? (
        <Descriptions
          size={CONTROL_SIZE}
          items={current.nearestOrder.type === OrderType.PMT ? permitDescriptions : descriptions}
          bordered
          column={1}
        />
      ) : (
        <SpinWrapper>
          <Spin />
        </SpinWrapper>
      )
    }
  ];

  return (
    <StyledCollapse
      items={collapseContent}
      expandIconPosition="end"
      size={CONTROL_SIZE}
      defaultActiveKey="general"
      ghost
    />
  );
};
