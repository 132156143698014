import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { Flex, Tooltip } from 'antd';
import { CreditNoteItemHydrated } from 'services/api/data-contracts';
import { getAmountValue } from 'helpers';

export const getColumns = (): ColumnsType<CreditNoteItemHydrated> => [
  {
    title: 'Item',
    dataIndex: 'service',
    key: 'service',
    ellipsis: true,
    render: (_, record) => (
      <Tooltip title={record.invoiceItem.serviceName.name}>
        {record.invoiceItem.serviceName.name}
      </Tooltip>
    )
  },
  {
    title: 'UOM',
    dataIndex: 'uom',
    key: 'uom',
    render: (_, record) => record.invoiceItem.unitOfMeasure.shortName
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    width: 70,
    render: (value) => <Flex justify="end">{getAmountValue(value, 4)}</Flex>
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    render: (value) => (
      <div style={{ display: 'flex', overflow: 'hidden', justifyContent: 'end' }}>
        <Tooltip title={getAmountValue(value)}>
          <span
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            }}>
            {getAmountValue(value)}
          </span>
        </Tooltip>
      </div>
    ),
    width: 100,
    align: 'end'
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (value) => (
      <div
        style={{
          display: 'flex',
          overflow: 'hidden',
          justifyContent: 'end'
        }}>
        <Tooltip title={getAmountValue(value)}>
          <span
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            }}>
            {getAmountValue(value)}
          </span>
        </Tooltip>
      </div>
    ),
    width: 100,
    align: 'end'
  },
  {
    title: 'Total',
    dataIndex: 'totalAmount',
    key: 'totalAmount',
    render: (value) => (
      <div
        style={{
          display: 'flex',
          overflow: 'hidden',
          justifyContent: 'end'
        }}>
        <Tooltip title={getAmountValue(value)}>
          <span
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            }}>
            {getAmountValue(value)}
          </span>
        </Tooltip>
      </div>
    ),
    width: 100,
    align: 'end'
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'notes',
    ellipsis: true
  }
];
