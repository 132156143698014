const isBrowserEnv = () => typeof window !== 'undefined' && typeof document !== 'undefined';

export const saveToStorage = <T>(key: string, value: T): void => {
  isBrowserEnv() && localStorage.setItem(key, JSON.stringify(value));
};

export const getFromStorage = <T>(key: string): T | null => {
  const data = isBrowserEnv() && localStorage.getItem(key);
  return data ? JSON.parse(data) : null;
};

export enum LocalStorageKey {
  NEW_MESSAGES_IDS = 'newMessages'
}
