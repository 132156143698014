import { Button, Space } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import React from 'react';
import { useCtrlEnterShortcut } from 'helpers';
import { useMessageStore } from 'store/messageStore';

interface IProps {
  onCancel: () => void;
  onSubmit: () => Promise<void>;
  isLoading: boolean;
}

export const HeaderButtons = ({ onSubmit, onCancel, isLoading }: IProps) => {
  const { newMessageMode } = useMessageStore(({ newMessageMode }) => ({ newMessageMode }));

  useCtrlEnterShortcut(onSubmit, !newMessageMode);

  return (
    <Space>
      <Button size={CONTROL_SIZE} onClick={onCancel} loading={isLoading}>
        Close
      </Button>
      <Button type="primary" size={CONTROL_SIZE} onClick={onSubmit} loading={isLoading}>
        Save and close
      </Button>
    </Space>
  );
};
