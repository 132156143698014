import { create } from 'zustand';
import { City } from 'services/api/data-contracts';
import { devtools } from 'zustand/middleware';

type CityStore = {
  current?: City;
  setCurrent: (current?: City) => void;
  list: City[];
  setList: (data: { items: City[]; total: number }) => void;
  page: number;
  setPage: (page: number) => void;
  total: number;
};

export const useCityStore = create<CityStore>()(
  devtools((set) => ({
    current: undefined,
    setCurrent: (current) => {
      set({ current });
    },
    list: [],
    setList: ({ items: list, total }) => {
      set({ list, total });
    },
    page: 1,
    setPage: (page) => {
      set({ page });
    },
    total: 0
  }))
);
