import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { contractsApi } from 'services/api';
import { Contract } from 'services/api/data-contracts';
import { ENTITY_NAME, QUERY_KEY } from './consts';
import { SERVER_DATE_FORMAT, MESSAGE_DURATION } from 'consts/common';
import { Dayjs } from 'dayjs';

const updateItem = async (data: Contract, id: number) =>
  await contractsApi.updateContract(id, {
    ...data,
    startDate: (data.startDate as unknown as Dayjs).format(SERVER_DATE_FORMAT),
    endDate: data.endDate
      ? (data.endDate as unknown as Dayjs).format(SERVER_DATE_FORMAT)
      : undefined
  });

export const useUpdateContract = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Contract) => await updateItem(data, data.id),
    onSuccess: async (response) => {
      if (response.data) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEY, response.data.id] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });

        message.success(`${ENTITY_NAME} has been successfully updated`, MESSAGE_DURATION);

        onSuccess && onSuccess();
      }
    }
  });
};
