import React from 'react';
import { Flex, Form, Input, Select } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import {
  CustomerStatus,
  SupplierStatus,
  ContractType,
  PaymentType
} from 'services/api/data-contracts';
import { CounterpartyInput } from 'components/CounterpartyInput/CounterpartyInput';
import { BooleanRadioGroup } from 'components/common/BooleanRadioGroup';
import { FormInstance } from 'antd/es/form';
import { customerStatusTitles } from './CustomerStatusTag';
import { supplierStatusTitle } from './SupplierStatusTag';
import { paymentTypeTitles } from './PaymentTypeIcon';
import { sortBy } from 'lodash';
import { useServiceStore } from 'store/serviceStore';
import { EnrichedOrderService } from 'store/orderStore';
import { useSelectAutoFocus } from 'helpers';

interface ServiceDetailsFormProps {
  form: FormInstance;
  service?: Partial<EnrichedOrderService>;
  isLoading?: boolean;
}

export const ServiceDetailsForm = ({ form, service, isLoading }: ServiceDetailsFormProps) => {
  const { dictionaryServices } = useServiceStore(({ list }) => ({
    dictionaryServices: list.filter((i) => {
      const orderType = service?.order?.type;
      return orderType && i.orderTypes.includes(orderType);
    })
  }));

  const selectServiceRef = useSelectAutoFocus();

  const serviceOptions = sortBy(dictionaryServices, (o) => [!o.isActive, o.name]).map(
    ({ id, name, isActive }) => ({
      value: id,
      label: name,
      disabled: !isActive
    })
  );

  return (
    <>
      <Flex gap={8}>
        <Form.Item
          name="serviceId"
          label="Service"
          rules={[{ required: true }]}
          style={{ flexGrow: 1 }}>
          <Select
            size={CONTROL_SIZE}
            options={serviceOptions}
            showSearch
            optionFilterProp="label"
            placeholder="Select service"
            style={{ width: '100%' }}
            loading={isLoading}
            ref={selectServiceRef}
          />
        </Form.Item>

        <Form.Item name="isOwnService" label="Own service">
          <BooleanRadioGroup
            testId="isOwnService"
            onChange={(value) => form?.setFieldValue('isOwnService', value)}
            value={form.getFieldValue('isOwnService')}
          />
        </Form.Item>
      </Flex>

      <Flex gap={8}>
        <Form.Item label="Customer" style={{ flexGrow: 1 }}>
          <Input disabled value={service?.order?.customer?.name} size={CONTROL_SIZE} />
        </Form.Item>

        <Form.Item
          name="customerStatus"
          label="Status"
          style={{ width: 125 }}
          data-testid="customer-status-select">
          <Select
            size={CONTROL_SIZE}
            allowClear
            onChange={(value) => {
              if (!value) {
                form.setFieldValue('customerStatus', null);
              }
            }}
            options={Object.values(CustomerStatus).map((status) => ({
              value: status,
              label: customerStatusTitles[status]
            }))}
            showSearch
            optionFilterProp="label"
            popupMatchSelectWidth={false}
            placeholder="Select status"
          />
        </Form.Item>
      </Flex>

      <Flex gap={8}>
        <div style={{ flexGrow: 1 }}>
          <CounterpartyInput
            label="Supplier"
            name="supplierId"
            contractType={ContractType.WithSupplier}
            onClear={() => form.setFieldValue('supplierId', null)}
            onChange={(counterparty) => form.setFieldValue('supplierId', counterparty?.id)}
          />
        </div>

        <Form.Item name="supplierStatus" label="Status" style={{ width: 125 }}>
          <Select
            size={CONTROL_SIZE}
            allowClear
            onChange={(value) => {
              if (!value) {
                form.setFieldValue('supplierStatus', null);
              }
            }}
            options={Object.values(SupplierStatus).map((status) => ({
              value: status,
              label: supplierStatusTitle[status]
            }))}
            showSearch
            optionFilterProp="label"
            popupMatchSelectWidth={false}
            placeholder="Select status"
          />
        </Form.Item>
      </Flex>

      <Flex gap={8}>
        <div style={{ flexGrow: 1 }}>
          <CounterpartyInput
            label="Provider"
            name="providerId"
            contractType={ContractType.WithSupplier}
            onClear={() => form.setFieldValue('providerId', null)}
            onChange={(counterparty) => form.setFieldValue('providerId', counterparty?.id)}
          />
        </div>

        <Form.Item
          name="paymentType"
          label="Payment type"
          rules={[{ required: true }]}
          style={{ width: 125 }}>
          <Select
            size={CONTROL_SIZE}
            options={Object.values(PaymentType).map((type) => ({
              value: type,
              label: paymentTypeTitles[type]
            }))}
            popupMatchSelectWidth={false}
            placeholder="Select payment type"
          />
        </Form.Item>
      </Flex>
    </>
  );
};
