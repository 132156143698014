import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { contractsApi } from 'services/api';
import { SERVICE_ENTITY_NAME, SERVICE_QUERY_KEY } from './consts';
import { MESSAGE_DURATION } from 'consts/common';

const deleteItem = async (id: number, contractId: number) =>
  await contractsApi.deleteContractService(contractId, id);

export const useDeleteContractService = (contractId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => deleteItem(id, contractId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [SERVICE_QUERY_KEY] });

      message.success(`${SERVICE_ENTITY_NAME} has been successfully removed`, MESSAGE_DURATION);
    }
  });
};
