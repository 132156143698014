import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { Service } from 'services/api/data-contracts';

type ServiceStore = {
  current?: Service;
  setCurrent: (current?: Service) => void;
  list: Service[];
  setList: (list: Service[]) => void;
};

export const useServiceStore = create<ServiceStore>()(
  devtools((set) => ({
    current: undefined,
    setCurrent: (current) => {
      set({ current });
    },
    list: [],
    setList: (list) => {
      set({ list });
    }
  }))
);
