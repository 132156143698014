/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CurrencyRate,
  CurrencyRateInput,
  CurrencyRateListResponse,
  Error,
  ValidationError
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class CurrencyRates<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Get a paginated list of currency rates for specifis currency. Currency rate date can also be provided as additional filter
   *
   * @tags currency rates
   * @name GetCurrencyRates
   * @summary Get list of currency rates
   * @request GET:/currency-rates
   * @secure
   */
  getCurrencyRates = (
    query?: {
      /**
       * The current page number
       * @format int32
       * @min 1
       * @max 10000
       * @default 1
       */
      page?: number;
      /**
       * The number of items to return per page
       * @format int32
       * @min 1
       * @max 10000
       * @default 10
       */
      pageSize?: number;
      /**
       * Source currency that exchange rates should be returned in regards. If empty, main currency will be used as source
       * @example "EUR"
       */
      sourceCurrency?: string;
      /**
       * Target currency
       * @example "EUR"
       */
      targetCurrency?: string;
      /**
       * Used to make operations with currency rates in this date
       * @format date-time
       */
      date?: string;
      /** to change currencyRateDate filter logic from =someDate to <=someDate */
      includeNearestDates?: boolean;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<CurrencyRateListResponse, Error>({
      path: `/currency-rates`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Create new currency rates for a given date
   *
   * @tags currency rates
   * @name AddCurrencyRates
   * @summary Create new currency rates
   * @request POST:/currency-rates
   * @secure
   */
  addCurrencyRates = (data: CurrencyRateInput, params: RequestParams = {}) =>
    this.http.request<CurrencyRate, ValidationError | Error>({
      path: `/currency-rates`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Update currency rates for specific date
   *
   * @tags currency rates
   * @name UpdateCurrencyRates
   * @summary Update currency rates
   * @request PUT:/currency-rates
   * @secure
   */
  updateCurrencyRates = (data: CurrencyRateInput, params: RequestParams = {}) =>
    this.http.request<CurrencyRate, ValidationError | Error>({
      path: `/currency-rates`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Delete currency rates for specific date
   *
   * @tags currency rates
   * @name DeleteCurrencyRates
   * @summary Delete currency rates
   * @request DELETE:/currency-rates
   * @secure
   */
  deleteCurrencyRates = (
    query: {
      /**
       * Used to make operations with currency rates in this date
       * @format date-time
       */
      date: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<void, ValidationError | Error>({
      path: `/currency-rates`,
      method: 'DELETE',
      query: query,
      secure: true,
      ...params
    });
}
