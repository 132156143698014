export const MESSAGES_QUERY_KEY = 'messages';
export const MESSAGE_QUERY_KEY = 'message';
export const ATTACHMENT_QUERY_KEY = 'attachment';

export const LABELS = {
  INBOX: '\\Inbox',
  SENT: '\\Sent'
};

export const REFETCH_INTERVAL = 10000;
export const MESSAGES_UI_UPDATE_DELAY = 1000;

export const GMAIL_RAW_SEARCH = 'in:inbox OR in:sent';

export enum SIGNATURE_NAME {
  NEW = 'signature_new',
  RE = 'signature_re',
  FW = 'signature_fw'
}

export const SIGNATURES = Object.values(SIGNATURE_NAME);

export const ATTACHMENT_TIMEOUT = 60000;
