import { FlagUpdate } from 'services/emailEngineApi/data-contracts';
import { uniq } from 'lodash';

export const updateMessageFlags = (originalFlags: string[] = [], newFlags?: FlagUpdate) => {
  if (!newFlags) return originalFlags;

  let updatedFlags = [...originalFlags];

  if (newFlags.add && newFlags.add?.length > 0) {
    updatedFlags = uniq([...updatedFlags, ...newFlags.add]);
  }

  if (newFlags.delete && newFlags.delete?.length > 0) {
    updatedFlags = updatedFlags.filter((f) => !newFlags.delete?.includes(f));
  }

  return updatedFlags;
};
