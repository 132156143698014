import { RowState, useSettlementDashboardStore } from 'store/settlementDashboardStore';

export const useSelectedOrdersAndServicesData = (isServiceSelected: boolean) => {
  const { orderRowsState, orders } = useSettlementDashboardStore(({ orderRowsState, list }) => ({
    orderRowsState,
    orders: list
  }));

  if (!isServiceSelected) return [];

  const selectedRows = orderRowsState.filter(
    (row) => row.state === RowState.INDETERMINATE || row.state === RowState.SELECTED
  );

  return selectedRows.map((row) => {
    const order = orders.find((order) => order.number === row.number);

    if (order) {
      return {
        ...order,
        orderServices: order.orderServices.filter((service) =>
          row.services.some(
            (selectedService) =>
              selectedService.id === service.id && selectedService.state === RowState.SELECTED
          )
        )
      };
    }
  });
};
