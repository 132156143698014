import React, { useEffect, useRef } from 'react';

type ShadowComponentProps = {
  dangerousHTML: string;
  isLoading: boolean;
};

export const ShadowRenderedMessage = ({ dangerousHTML }: ShadowComponentProps) => {
  const root = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (root.current && !root.current.shadowRoot) {
      const shadowRoot = root.current.attachShadow({ mode: 'open' });
      const style = document.createElement('style');
      style.textContent = `
          :host {
            width: 100%;
            height: 100%;
            display: block;

            ::-webkit-scrollbar {
              -webkit-appearance: none;
            }
          
            ::-webkit-scrollbar:horizontal {    
              height: 12px;
            }
          
         -   ::-webkit-scrollbar-thumb {
              border-radius: 8px;
              border: 2px solid white;
              background-color: rgba(0, 0, 0, .5);
            }
            ::-webkit-scrollbar-track { 
              background-color: #fff; 
              border-radius: 8px; 
            }
          }
        `;

      shadowRoot.appendChild(style);

      const content = document.createElement('div');
      content.innerHTML = dangerousHTML;

      shadowRoot.appendChild(content);
    }
  }, [dangerousHTML]);

  return <div ref={root} />;
};
