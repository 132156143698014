import { Table } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import React from 'react';
import styled from 'styled-components';
import { Flight, PermitOrder } from 'services/api/data-contracts';
import { getNextFlight } from 'helpers';
import { useFlightsDashboardStore } from 'store/flightsDashboardStore';
import { useFlightStore } from 'store/flightStore';
import { getColumns } from './utils';
import { useOrderStore } from 'store/orderStore';
import { useDrawerStore } from 'store/drawerStore';

const StyledTableWrapper = styled('div')`
  .ant-spin-container {
    height: fit-content !important;
  }

  .ant-table-tbody {
    padding: 6px !important;
  }

  .ant-table-tbody > tr > td {
    padding: 6px !important;
    line-height: 24px;
  }

  .ant-table-thead > tr > th {
    padding: 6px !important;
  }

  #actions {
    display: none;
  }

  .ant-table-cell-row-hover #actions {
    display: block;
  }
`;

interface IProps {
  legs: Flight[];
  isLoading: boolean;
}

export const LegsView = ({ legs, isLoading }: IProps) => {
  const { current, setCurrent } = useOrderStore(({ current, setCurrent }) => ({
    current: current as PermitOrder,
    setCurrent
  }));
  const { setFlightEditMode } = useFlightsDashboardStore(({ setFlightEditMode }) => ({
    setFlightEditMode
  }));
  const { setCurrent: setFlight } = useFlightStore(({ setCurrent }) => ({
    setCurrent
  }));
  const { setDrawerOpen } = useDrawerStore(({ setDrawerOpen }) => ({ setDrawerOpen }));

  const dataSource = legs.map((flight) => ({ ...flight, key: flight.id }));

  const handleCreateNextFlight = (flight: Flight) => {
    setCurrent({ ...current, shouldAddFlight: true });
    setFlight(getNextFlight(flight));
    setFlightEditMode(true);
    setDrawerOpen('flight');
  };

  const columns = getColumns(handleCreateNextFlight);

  return (
    <StyledTableWrapper>
      <Table
        columns={columns}
        dataSource={dataSource}
        size={CONTROL_SIZE}
        loading={isLoading}
        pagination={false}
        showHeader={false}
      />
    </StyledTableWrapper>
  );
};
