/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Error,
  Service,
  ServiceInput,
  ServiceListResponse,
  ValidationError
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Services<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Get a list of services
   *
   * @tags services
   * @name GetServices
   * @summary Get a list of services
   * @request GET:/services
   * @secure
   */
  getServices = (params: RequestParams = {}) =>
    this.http.request<ServiceListResponse, Error>({
      path: `/services`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Create a new service
   *
   * @tags services
   * @name AddService
   * @summary Create a new service
   * @request POST:/services
   * @secure
   */
  addService = (data: ServiceInput, params: RequestParams = {}) =>
    this.http.request<Service, ValidationError | Error>({
      path: `/services`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Get a specific service
   *
   * @tags services
   * @name GetServiceById
   * @summary Get a specific service
   * @request GET:/services/{serviceId}
   * @secure
   */
  getServiceById = (serviceId: number, params: RequestParams = {}) =>
    this.http.request<Service, Error>({
      path: `/services/${serviceId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Update a specific service
   *
   * @tags services
   * @name UpdateService
   * @summary Update a specific service
   * @request PUT:/services/{serviceId}
   * @secure
   */
  updateService = (serviceId: number, data: ServiceInput, params: RequestParams = {}) =>
    this.http.request<Service, ValidationError | Error>({
      path: `/services/${serviceId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Delete a specific service
   *
   * @tags services
   * @name DeleteService
   * @summary Delete a specific service
   * @request DELETE:/services/{serviceId}
   * @secure
   */
  deleteService = (serviceId: number, params: RequestParams = {}) =>
    this.http.request<void, ValidationError | Error>({
      path: `/services/${serviceId}`,
      method: 'DELETE',
      secure: true,
      ...params
    });
}
