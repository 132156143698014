import { useContext } from 'react';
import { DashboardContext, DashboardType } from '../../../consts';

export type LinkAction =
  | 'loading'
  | 'disabled'
  | 'link-selected'
  | 'unlink-selected'
  | 'unlink-all'
  | null;

interface ILinkActionProps {
  isDropdownOpen: boolean;
  isError: boolean;
  isFetching: boolean;
  linkedFlightIds: number[];
  linkedOrderNumbers: string[];
  linkedProgramIds: number[];
  linkedInvoiceIds: number[];
  selectedFlightIds: number[];
  selectedOrderNumbers: string[];
  selectedProgramIds: number[];
}

export const useLinkAction = ({
  isDropdownOpen,
  isError,
  isFetching,
  linkedFlightIds,
  linkedOrderNumbers,
  linkedProgramIds,
  linkedInvoiceIds,
  selectedFlightIds,
  selectedOrderNumbers,
  selectedProgramIds
}: ILinkActionProps): LinkAction => {
  const { type: dashboardType } = useContext(DashboardContext);

  const selectedEntities = [...selectedFlightIds, ...selectedOrderNumbers, ...selectedProgramIds];
  const linkedEntities =
    dashboardType === DashboardType.Dispatcher
      ? [...linkedFlightIds, ...linkedOrderNumbers, ...linkedProgramIds]
      : [...linkedInvoiceIds, ...linkedOrderNumbers];

  if (!isDropdownOpen || isFetching) return 'loading';

  if (isError || (!selectedEntities.length && !linkedEntities.length)) return 'disabled';

  const allSelectedLinked =
    selectedFlightIds.every((id) => linkedFlightIds.includes(id)) &&
    selectedOrderNumbers.every((id) => linkedOrderNumbers.includes(id)) &&
    selectedProgramIds.every((id) => linkedProgramIds.includes(id));

  const allSelectedNotLinked =
    selectedFlightIds.every((id) => !linkedFlightIds.includes(id)) &&
    selectedOrderNumbers.every((id) => !linkedOrderNumbers.includes(id)) &&
    selectedProgramIds.every((id) => !linkedProgramIds.includes(id));

  if (selectedEntities.length > 0 && allSelectedLinked) return 'unlink-selected';

  if (selectedEntities.length > 0 && allSelectedNotLinked) return 'link-selected';

  if (linkedEntities.length > 0) return 'unlink-all';

  return null;
};
