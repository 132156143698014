import React from 'react';
import { Button, Space } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { useFlightStore } from 'store/flightStore';
import { Flight } from 'services/api/data-contracts';
import { useFlightsDashboardStore } from 'store/flightsDashboardStore';
import { CancelButtonWithConfirm, CloseButton, EditButton } from 'components/Drawer';
import { CreateBasedOnButton } from './CreateBasedOnButton';
import { useMessageStore } from 'store/messageStore';
import { useCloseOnEsc, useCtrlEnterShortcut, useCtrlSShortcut } from 'helpers';

interface IProps {
  isLoading: boolean;
  onSubmit: () => Promise<void>;
  onClose: () => void;
  isFormTouched?: boolean;
}

export const HeaderButtons = ({ isLoading, onSubmit, onClose, isFormTouched }: IProps) => {
  const { setFlightEditMode, isFlightEditMode } = useFlightsDashboardStore(
    ({ setFlightEditMode, isFlightEditMode }) => ({
      setFlightEditMode,
      isFlightEditMode
    })
  );
  const { flight } = useFlightStore(({ current }) => ({
    flight: current as Flight
  }));
  const { newMessageMode } = useMessageStore(({ newMessageMode }) => ({ newMessageMode }));

  useCtrlEnterShortcut(() => handleSubmit(true), !newMessageMode);
  useCtrlSShortcut(() => handleSubmit(false), !newMessageMode);
  useCloseOnEsc(onClose, !isFlightEditMode || !isFormTouched);

  const handleClose = () => {
    setFlightEditMode(false);
    onClose();
  };

  const handleSubmit = async (shouldClose?: boolean) => {
    try {
      await onSubmit();
      shouldClose && handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  if (isFlightEditMode) {
    return (
      <Space>
        <CancelButtonWithConfirm
          onConfirm={handleClose}
          isLoading={isLoading}
          isFormTouched={isFormTouched}
        />

        <Button size={CONTROL_SIZE} loading={isLoading} onClick={() => handleSubmit(false)}>
          {flight?.id ? 'Save' : 'Create'}
        </Button>

        <Button
          type="primary"
          size={CONTROL_SIZE}
          loading={isLoading}
          onClick={() => handleSubmit(true)}>
          {flight?.id ? 'Save' : 'Create'} and close
        </Button>
      </Space>
    );
  }

  return (
    <Space>
      <CreateBasedOnButton isLoading={isLoading} />
      <EditButton onClick={() => setFlightEditMode(true)} isLoading={isLoading} />
      <CloseButton onClick={onClose} />
    </Space>
  );
};
