import { useQuery } from '@tanstack/react-query';
import { Payment } from 'services/api/data-contracts';
import { paymentsApi } from 'services/api';
import { PAYMENT_QUERY_KEY } from './consts';
import { usePaymentStore } from 'store/paymentStore';

const fetchItem = async (id: number) => await paymentsApi.getPaymentById(id);

export const useGetPayment = (id?: number, onSuccess?: (item?: Payment) => void) => {
  const { setCurrent } = usePaymentStore(({ setCurrent }) => ({
    setCurrent
  }));

  return useQuery({
    queryKey: [PAYMENT_QUERY_KEY, id],
    queryFn: async () => {
      if (id) {
        return await fetchItem(id);
      }
    },
    onSuccess: (response) => {
      setCurrent(response?.data);

      onSuccess && onSuccess(response?.data);
    },
    enabled: !!id
  });
};
