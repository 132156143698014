import { useQuery } from '@tanstack/react-query';
import { GroundHandlingOrder, OrderType } from 'services/api/data-contracts';
import { ordersApi } from 'services/api';
import { GH_ORDER_QUERY_KEY } from './consts';
import { useOrderStore } from 'store/orderStore';

const fetchItem = async (id: number) => await ordersApi.getGhOrderById(id);

export const useGetGHOrder = (
  enabled: boolean,
  id?: number,
  onSuccess?: (item?: GroundHandlingOrder) => void
) => {
  const { setCurrent } = useOrderStore(({ setCurrent }) => ({
    setCurrent
  }));

  return useQuery({
    queryKey: [GH_ORDER_QUERY_KEY, id],
    queryFn: async () => {
      if (id) {
        return await fetchItem(id);
      }
    },
    onSuccess: (response) => {
      setCurrent(response?.data ? { ...response.data, type: OrderType.GH } : undefined);

      onSuccess && onSuccess(response?.data);
    },
    enabled
  });
};
