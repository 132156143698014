import {
  AdditionalServicesOrder,
  AdditionalServicesOrderInput,
  LocationType
} from 'services/api/data-contracts';
import { Dayjs } from 'dayjs';
import { computeEstimatedParkingTime } from '../../helpers/compute';
import { getUTCDate } from 'helpers';
import { SERVER_DATE_TIME_FORMAT } from 'consts/common';

export type AddOrderInputWithDayJs = Omit<
  AdditionalServicesOrderInput,
  'createdAt' | 'startDatetime' | 'endDatetime' | 'locationTypeAndId'
> & {
  createdAt?: Dayjs;
  startEndDate: Dayjs[];
  estimatedParkingTime?: string;
  locationTypeAndId?: string;
};

export const transformForRender = (order: AdditionalServicesOrder): AddOrderInputWithDayJs => ({
  ...order,
  createdAt: order.createdAt ? getUTCDate(order.createdAt) : undefined,
  startEndDate: [getUTCDate(order.startDatetime), getUTCDate(order.endDatetime)],
  estimatedParkingTime: computeEstimatedParkingTime(
    getUTCDate(order.startDatetime),
    getUTCDate(order.endDatetime)
  ),
  locationTypeAndId:
    order.locationType && order.locationId ? `${order.locationType}#${order.locationId}` : undefined
});

export const transformForRequest = (
  order: AddOrderInputWithDayJs,
  orderId?: number
): AdditionalServicesOrderInput & { id?: number } => ({
  ...order,
  startDatetime: order.startEndDate[0].format(SERVER_DATE_TIME_FORMAT),
  endDatetime: order.startEndDate[1].format(SERVER_DATE_TIME_FORMAT),
  locationType: order.locationTypeAndId?.split('#')[0] as LocationType,
  locationId: order.locationTypeAndId ? +order.locationTypeAndId.split('#')[1] : 0,
  ...(orderId ? { id: orderId } : {})
});
