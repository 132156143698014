import { useQuery } from '@tanstack/react-query';
import { currencyRatesApi } from 'services/api';
import { QUERY_KEY } from './consts';
import { useCurrencyRateStore } from 'store/currencyRateStore';

interface IFilter {
  page?: number;
  pageSize?: number;
  sourceCurrency?: string;
  date?: string;
  includeNearestDates?: boolean;
  targetCurrency?: string;
}

const fetchList = async (filter: IFilter) => await currencyRatesApi.getCurrencyRates(filter);

export const useGetCurrencyRates = (filter: IFilter, enabled = true) => {
  const { setList } = useCurrencyRateStore(({ setList }) => ({
    setList
  }));

  return useQuery({
    queryKey: [QUERY_KEY, filter],
    queryFn: () => fetchList(filter),
    onSuccess: (response) => {
      setList(response.data);
    },
    enabled
  });
};
