import React from 'react';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { geekblue, magenta } from '@ant-design/colors';
import { MessageType } from '../../consts';
import { Tooltip } from 'antd';

const arrowColors = {
  [MessageType.INCOMING]: magenta[6],
  [MessageType.OUTGOING]: geekblue[3]
};

export const ArrowIcon = ({ type }: { type: MessageType | null }) => {
  if (!type) {
    return null;
  }

  const IconComponent = type === MessageType.INCOMING ? ArrowRightOutlined : ArrowLeftOutlined;

  return (
    <Tooltip title={type === MessageType.INCOMING ? 'Incoming' : 'Outgoing'}>
      <IconComponent
        style={{ color: arrowColors[type], fontSize: '16px', width: '16px', height: '16px' }}
      />
    </Tooltip>
  );
};
