import React, { useEffect, useState } from 'react';
import { getUTCDate } from 'helpers';

export const Clock = () => {
  const [time, setTime] = useState(getUTCDate());

  useEffect(() => {
    const timer = setInterval(() => setTime(getUTCDate()), 1000);

    return () => {
      clearInterval(timer);
    };
  });

  return <span style={{ color: 'white' }}>{time.format('HH:mm')} UTC</span>;
};
