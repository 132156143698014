import { useQuery } from '@tanstack/react-query';
import { messagesApi } from 'services/emailEngineApi';
import { settingsStore } from 'services/settings/SettingsStore';
import { useMessageStore } from 'store/messageStore';

const getTemplates = async () =>
  await messagesApi.getV1Templates({
    account: settingsStore.getMailboxSettings()?.mailbox_name || ''
  });

export const useGetMessageTemplates = () => {
  const { setTemplates } = useMessageStore(({ setTemplates }) => ({
    setTemplates
  }));

  return useQuery({
    queryKey: ['message_templates', settingsStore.getMailboxSettings()?.mailbox_name],
    queryFn: getTemplates,
    onSuccess: (response) => {
      response.data.templates && setTemplates(response.data.templates);
    }
  });
};
