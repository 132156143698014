import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type DashboardStore = {
  linkFilters: string[];
  setLinkFilters: (linkFilters: string[]) => void;
};

export const useDashboardStore = create<DashboardStore>()(
  devtools((set) => ({
    linkFilters: [],
    setLinkFilters: (linkFilters) => {
      set({ linkFilters });
    }
  }))
);
