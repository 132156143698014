import React, { useEffect, useMemo, useState } from 'react';
import { Airport, City, Country, LocationType, SupplierService } from 'services/api/data-contracts';
import { Button, Drawer, Form, Rate, Row, Select } from 'antd';
import { useCreateContractService, useUpdateContractService } from 'queries/contract';
import { useContractStore } from 'store/contractStore';
import { useServiceStore } from 'store/serviceStore';
import { CONTROL_SIZE } from 'consts/common';
import { sortBy } from 'lodash';
import { useGetServices } from 'queries/service';
import { AirportsInput } from 'components/AirportsInput/AirportsInput';
import { CitiesInput } from 'components/CitiesInput/CitiesInput';
import { CountriesInput } from 'components/CountriesInput/CountriesInput';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ContractServiceView = ({ isOpen, onClose }: IProps) => {
  const [form] = Form.useForm<SupplierService>();
  const { service } = useContractStore(({ service }) => ({
    service
  }));
  const { list: dictionaryServices } = useServiceStore(({ list }) => ({ list }));

  const [serviceId, setServiceId] = useState<number | undefined>(undefined);
  const selectedService = useMemo(
    () => dictionaryServices.find((service) => service.id === serviceId),
    [serviceId]
  );

  useEffect(() => {
    if (service) {
      form.setFieldsValue(service);
      setServiceId(service.serviceId);
    } else {
      form.resetFields();
    }

    return () => setServiceId(undefined);
  }, [service]);

  const createMutation = useCreateContractService();
  const updateMutation = useUpdateContractService();
  const serviceDictionaryQuery = useGetServices();

  const handleSubmit = () =>
    form
      .validateFields()
      .then(() => {
        const data = form.getFieldsValue();

        if (!service?.id) {
          createMutation.mutate({ ...service, ...data });
        } else {
          updateMutation.mutate({ ...service, ...data });
        }
      })
      .then(() => {
        onCancel();
      })
      .catch((err) => err);

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  const isLoading = createMutation.isLoading || updateMutation.isLoading;

  const options = sortBy(dictionaryServices, (o) => [!o.isActive, o.name]).map(
    ({ id, name, isActive }) => ({
      value: id,
      label: name,
      disabled: !isActive
    })
  );

  return (
    <Drawer
      title={service?.id ? 'Update service' : 'New service'}
      open={isOpen}
      onClose={onCancel}
      maskClosable={false}>
      <Form
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 40 }}
        style={{ justifyContent: 'center' }}
        layout="vertical"
        form={form}
        autoComplete="off"
        colon={false}
        className="styled-form">
        <Form.Item name="serviceId" label="Service" rules={[{ required: true }]}>
          <Select
            size={CONTROL_SIZE}
            options={options}
            onChange={(value) => setServiceId(value)}
            showSearch
            optionFilterProp="label"
            loading={serviceDictionaryQuery.isLoading}
          />
        </Form.Item>

        {selectedService?.locationType === LocationType.Airport && (
          <AirportsInput
            name="locationIds"
            label="Locations"
            required
            initialList={service?.locations as Airport[]}
          />
        )}

        {selectedService?.locationType === LocationType.City && (
          <CitiesInput
            name="locationIds"
            label="Locations"
            required
            isMultiple
            initialList={service?.locations as City[]}
          />
        )}

        {selectedService?.locationType === LocationType.Country && (
          <CountriesInput
            name="locationIds"
            label="Locations"
            required
            isMultiple
            placeholder="Select countries"
            initialList={service?.locations as Country[]}
            onChange={(value) => form.setFieldsValue({ locationIds: [value] })}
          />
        )}

        <Form.Item name="priority" label="Priority" rules={[{ required: true }]}>
          <Rate style={{ fontSize: 18 }} />
        </Form.Item>

        <Row justify="end">
          {onCancel && (
            <Button size={CONTROL_SIZE} onClick={onCancel} loading={isLoading}>
              Cancel
            </Button>
          )}
          <Button
            size={CONTROL_SIZE}
            type="primary"
            ghost
            style={{ marginLeft: 12 }}
            onClick={handleSubmit}
            loading={isLoading}>
            {service?.id ? 'Update' : 'Save'}
          </Button>
        </Row>
      </Form>
    </Drawer>
  );
};
