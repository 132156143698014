import { ColumnsType } from 'antd/es/table';
import { Checkbox, Tag, Tooltip } from 'antd';
import React from 'react';
import { OrderType, Service } from 'services/api/data-contracts';
import { PresetColorType } from 'antd/es/_util/colors';
import { capitalize } from 'lodash';
import { stringSorter } from '../utils/tableSorters';
import { getOrderIcon } from 'helpers';
import { cyan, geekblue, orange, purple, red } from '@ant-design/colors';

export const tagColors: Record<OrderType, PresetColorType> = {
  [OrderType.ADD]: 'purple',
  [OrderType.FUEL]: 'red',
  [OrderType.GH]: 'geekblue',
  [OrderType.NAV]: 'cyan',
  [OrderType.PMT]: 'orange'
};

export const tagIconColors: Record<OrderType, string[]> = {
  [OrderType.ADD]: purple,
  [OrderType.FUEL]: red,
  [OrderType.GH]: geekblue,
  [OrderType.NAV]: cyan,
  [OrderType.PMT]: orange
};

export const getServiceColumns = (): ColumnsType<Service> => [
  {
    title: '',
    dataIndex: 'isActive',
    key: 'isActive',
    render: (value) => (
      <Tooltip title={value ? 'Active' : 'Inactive'}>
        <Checkbox checked={value} disabled />
      </Tooltip>
    ),
    width: 32
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => stringSorter(a.name, b.name),
    ellipsis: true
  },
  {
    title: 'Order types',
    key: 'orderTypes',
    render: (_, { orderTypes }) =>
      orderTypes.map((type: OrderType) => (
        <Tag
          color={tagColors[type]}
          key={type}
          icon={getOrderIcon(type, tagIconColors[type][6])}
          style={{ display: 'inline-flex', alignItems: 'center', gap: 6 }}>
          {type.toUpperCase()}
        </Tag>
      )),
    width: 360
  },
  {
    title: 'Location',
    dataIndex: 'locationType',
    key: 'locationType',
    render: (value) => capitalize(value),
    width: 92
  },
  {
    title: 'Default',
    dataIndex: 'isDefault',
    key: 'isDefault',
    align: 'end',
    render: (value) => <Checkbox checked={value} disabled />,
    width: 65
  }
];
