import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { invoicesApi } from 'services/api';
import { Invoice } from 'services/api/data-contracts';
import { ENTITY_NAME, QUERY_KEY } from './consts';
import { useInvoiceStore } from 'store/invoiceStore';
import { SETTLEMENT_WIDGET_QUERY_KEY } from 'queries/settlement/consts';
import { MESSAGE_DURATION } from 'consts/common';
import { BALANCE_WIDGET_QUERY_KEY } from 'queries/balance/consts';
import { useBalanceDashboardStore } from 'store/balanceDashboardStore';

const updateItem = async (data: Invoice) => await invoicesApi.updateInvoice(data.id, data);

export const useUpdateInvoice = (
  onSuccess?: () => void,
  isSettlementDashboard = false,
  isBalanceDashboard = false
) => {
  const { setCurrent } = useInvoiceStore(({ setCurrent }) => ({ setCurrent }));
  const { filter } = useBalanceDashboardStore(({ filter }) => ({
    filter
  }));

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Invoice) => await updateItem(data),
    onSuccess: async (response) => {
      if (response.data) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });

        setCurrent(response.data);

        message.success(
          `${ENTITY_NAME} "${response.data.invoiceNumber}" has been successfully updated`,
          MESSAGE_DURATION
        );

        isSettlementDashboard && (await queryClient.refetchQueries([SETTLEMENT_WIDGET_QUERY_KEY]));
        isBalanceDashboard &&
          (await queryClient.refetchQueries([BALANCE_WIDGET_QUERY_KEY, filter]));
        onSuccess && onSuccess();
      }
    }
  });
};
