import { Descriptions, DescriptionsProps } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import React, { useContext } from 'react';
import { DataContext } from '../DictionaryDetails';
import { City } from 'services/api/data-contracts';

export const CityDictionaryView = () => {
  const data = useContext(DataContext) as City;

  if (!data) {
    return null;
  }

  const descriptions: DescriptionsProps['items'] = [
    {
      label: 'Name',
      children: data.name,
      span: 2
    },
    {
      label: 'Country',
      children: data.country?.name,
      span: 2
    },
    {
      label: 'IATA',
      children: data.iata || '-'
    },
    {
      label: 'Time zone',
      children: data.timeZone || '-'
    },
    {
      label: 'Latitude',
      children: data.latitude || '-'
    },
    {
      label: 'Longitude',
      children: data.longitude || '-'
    }
  ];

  return <Descriptions size={CONTROL_SIZE} items={descriptions} column={2} bordered />;
};
