import { ConfigProvider, Empty, Form, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { CONTROL_SIZE } from 'consts/common';
import { useAircraftStore } from 'store/aircraftStore';
import { AircraftsApiFilter, useGetAircrafts } from 'queries/aircraft';
import { Aircraft } from 'services/api/data-contracts';

interface IProps {
  disabled?: boolean;
  required?: boolean;
  initialAircraftId?: number;
  label?: string;
  withExtraInfo?: boolean;
  name?: string | (string | number)[];
  onChange?: (value: number) => void;
  apiFilter?: AircraftsApiFilter;
}

export const AircraftInput = ({
  disabled,
  required,
  initialAircraftId,
  label = 'Aircraft',
  withExtraInfo,
  name = 'aircraftId',
  onChange,
  apiFilter = {}
}: IProps) => {
  const [aircraftId, setAircraftId] = useState<number | undefined>(undefined);
  const [aircraft, setAircraft] = useState<Aircraft | undefined>(undefined);
  const [filter, setFilter] = useState('');

  const { list } = useAircraftStore(({ list }) => ({ list }));

  const { isLoading } = useGetAircrafts(apiFilter);

  const options = list.map(({ tailNumber, id }) => ({
    label: tailNumber,
    value: id
  }));

  useEffect(() => {
    initialAircraftId && setAircraftId(initialAircraftId);
  }, [initialAircraftId]);

  useEffect(() => {
    if (aircraftId && list.length > 0) {
      setAircraft(list.find((aircraft) => aircraft.id === aircraftId));
    }
  }, [aircraftId, list]);

  useEffect(() => {
    if (list.length === 0) {
      setAircraft(undefined);
    }
  }, [list]);

  const extraInfo = [
    aircraft?.type,
    aircraft?.maxTakeoffWeight ? aircraft?.maxTakeoffWeight + ' kg' : undefined,
    aircraft?.country?.name
  ].filter((i) => !!i);

  const handleBlur = () => {
    const filteredOptions = options.filter((o) =>
      o.label.toLowerCase().includes(filter.toLowerCase())
    );

    if (filteredOptions.length === 1 && onChange) {
      onChange(filteredOptions[0].value);
    }
  };

  return (
    <>
      <ConfigProvider
        renderEmpty={() => (
          <Empty description="Aircrafts not found" image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}>
        <Form.Item
          name={name}
          label={label === null || label === '' ? null : label || 'Aircraft'}
          rules={[{ required }]}
          style={{ width: '100%', marginBottom: 0 }}>
          <Select
            placeholder="Select an aircraft"
            options={options}
            size={CONTROL_SIZE}
            disabled={disabled}
            showSearch
            optionFilterProp="label"
            onSearch={setFilter}
            onBlur={handleBlur}
            loading={isLoading}
            value={aircraftId}
            onChange={(value) => {
              setAircraftId(value);

              if (!value) {
                setAircraftId(undefined);
                setAircraft(undefined);
              }
            }}
          />
        </Form.Item>
      </ConfigProvider>

      {withExtraInfo && extraInfo.length > 0 && (
        <div
          style={{
            color: '#bfbfbf',
            lineHeight: '14px',
            marginBottom: '8px',
            marginLeft: 0
          }}>
          {extraInfo.join(' | ')}
        </div>
      )}
    </>
  );
};
