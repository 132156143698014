import { Form, Select } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { CONTROL_SIZE } from 'consts/common';
import { sortBy } from 'lodash';
import { useContractStore } from 'store/contractStore';
import { useGetContracts } from 'queries/contract';

interface IProps {
  labelSpan?: number;
  name: string | [number, string];
  counterpartyId?: number;
  onSetContract?: (contractId?: number) => void;
  label?: string;
  counterpartyType?: 'customer' | 'supplier';
  onChange?: (contractId?: number) => void;
}

export const ContractInput = ({
  labelSpan,
  name,
  counterpartyId,
  onSetContract,
  label = '#',
  counterpartyType = 'customer',
  onChange
}: IProps) => {
  const { list } = useContractStore(({ list }) => ({ list }));

  const { isLoading } = useGetContracts();

  const data = useMemo(
    () =>
      list.filter((contract) => {
        if (counterpartyType === 'customer') {
          return contract.customerId === counterpartyId;
        }

        return contract.supplierId === counterpartyId;
      }),
    [list, counterpartyId, counterpartyType]
  );

  useEffect(() => {
    if (data.length === 1) {
      onSetContract && onSetContract(data[0].id);
      onChange && onChange(data[0].id);
    }
  }, [data]);

  const options = useMemo(
    () =>
      sortBy(data, (o) => [!o.description]).map(({ id, description }) => ({
        label: description,
        value: id
      })),
    [data]
  );

  return (
    <Form.Item
      name={name}
      label={label}
      labelCol={labelSpan ? { span: labelSpan } : {}}
      rules={[{ required: true, message: 'Contract is required' }]}>
      <Select
        style={{ width: '100%' }}
        placeholder="Select contract"
        options={options}
        size={CONTROL_SIZE}
        loading={isLoading}
        showSearch
        optionFilterProp="label"
        disabled={!counterpartyId}
        onChange={onChange}
      />
    </Form.Item>
  );
};
