import { useQueryClient } from '@tanstack/react-query';
import { ATTACHMENT_QUERY_KEY } from './consts';
import { fetchAttachmentStream } from './useGetAttachment';

export const useGetAttachments = () => {
  const queryClient = useQueryClient();

  const refetchFiles = async (ids: string[]) => {
    const results = [];
    for (const id of ids) {
      const fileData = await queryClient.fetchQuery([ATTACHMENT_QUERY_KEY, id], () =>
        fetchAttachmentStream(id)
      );
      results.push(fileData);
    }
    return results;
  };

  return {
    refetchFiles
  };
};
