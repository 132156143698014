import React, { useState } from 'react';
import { Button, Popconfirm } from 'antd';
import { CONTROL_SIZE } from 'consts/common';

interface IProps {
  isWarning?: boolean;
  onSubmit: () => Promise<void>;
  title: string;
  isLoading: boolean;
  isPrimaryButton?: boolean;
}

export const ButtonWithConfirm = ({
  isWarning,
  onSubmit,
  title,
  isLoading,
  isPrimaryButton
}: IProps) => {
  const [isConfirmOpen, setConfirmOpen] = useState(false);

  const handleOpenChange = async (newOpen: boolean) => {
    if (!newOpen) {
      setConfirmOpen(newOpen);
      return;
    }

    if (isWarning) {
      setConfirmOpen(newOpen);
    } else {
      await onSubmit();
    }
  };

  const confirmTitle = (
    <span>
      Invoice is not posted and won&apos;t be included
      <br />
      in Sales and Balance calculations.
      <br />
      Are you sure to continue?
    </span>
  );

  return (
    <Popconfirm
      title={confirmTitle}
      onConfirm={onSubmit}
      onCancel={() => setConfirmOpen(false)}
      onOpenChange={handleOpenChange}
      open={isConfirmOpen}>
      <Button size={CONTROL_SIZE} loading={isLoading} {...(isPrimaryButton && { type: 'primary' })}>
        {title}
      </Button>
    </Popconfirm>
  );
};
