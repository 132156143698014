import {
  PaymentPurposeHydrated,
  PaymentPurposeInput,
  PaymentPurposeUpdateInput
} from 'services/api/data-contracts';
import { PaymentPurposeWithContract } from '../../PaymentDrawer/PaymentDetails/PaymentPurposeBlock/columns';

export const transformForRequest = (
  paymentPurpose: PaymentPurposeHydrated | PaymentPurposeWithContract,
  formData: Pick<PaymentPurposeInput, 'paidAmount' | 'paidAmountInInvoiceCurrency'> & {
    paymentPurpose: number;
  }
): PaymentPurposeUpdateInput => [
  {
    id: formData.paymentPurpose,
    paymentId: paymentPurpose.paymentId,
    invoiceId: paymentPurpose.invoiceId,
    paidAmount: formData.paidAmount,
    ...(formData.paidAmountInInvoiceCurrency && {
      paidAmountInInvoiceCurrency: formData.paidAmountInInvoiceCurrency
    })
  }
];
