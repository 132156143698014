import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { invoicesApi } from 'services/api';
import { InvoiceInput } from 'services/api/data-contracts';
import { ENTITY_NAME, QUERY_KEY } from './consts';
import { useInvoiceStore } from 'store/invoiceStore';
import { SETTLEMENT_WIDGET_QUERY_KEY } from 'queries/settlement/consts';
import { useSettlementDashboardStore } from 'store/settlementDashboardStore';
import { resetSelectedRowAndServices } from 'pages/SettlementDashboard/helpers/resetSelectedRowAndServices';
import { useOrderStore } from 'store/orderStore';
import { MESSAGE_DURATION } from 'consts/common';

const createItem = async (data: InvoiceInput) => await invoicesApi.addInvoice(data);

export const useCreateInvoice = (onSuccess?: () => void) => {
  const { setCurrent } = useInvoiceStore(({ setCurrent }) => ({ setCurrent }));

  const { areServicesSelected, setOrderRowsState, orderRowsState } = useSettlementDashboardStore(
    ({ selectedServicesIds, setOrderRowsState, orderRowsState }) => ({
      areServicesSelected: selectedServicesIds.length > 0,
      setOrderRowsState,
      orderRowsState
    })
  );
  const { setSelectedServices } = useOrderStore(({ setSelectedServices }) => ({
    setSelectedServices
  }));

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createItem,
    onSuccess: async (response) => {
      if (response.data) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });

        if (areServicesSelected) {
          setOrderRowsState(orderRowsState.map(resetSelectedRowAndServices));
          await queryClient.refetchQueries([SETTLEMENT_WIDGET_QUERY_KEY]);
        }

        setSelectedServices([]);

        setCurrent(response.data);

        message.success(
          `${ENTITY_NAME} "${response.data.invoiceNumber}" has been successfully created`,
          MESSAGE_DURATION
        );
        onSuccess && onSuccess();
      }
    }
  });
};
