import React, { useMemo } from 'react';
import { CreditNoteItemHydrated, PaymentHydrated } from 'services/api/data-contracts';
import { Button, Empty, Table } from 'antd';
import { CONTROL_SIZE, PAGE_SIZE_50 } from 'consts/common';
import { sortBy } from 'lodash';
import styled from 'styled-components';
import { getColumns } from './columns';
import { useDeletePayment } from 'queries/payment';
import { useInvoiceStore } from 'store/invoiceStore';
import { PlusOutlined } from '@ant-design/icons';
import { useDrawerStore } from 'store/drawerStore';
import { CreditNoteItemsTable } from './CreditNoteItemsTable/CreditNoteItemsTable';

const StyledTable = styled(Table<PaymentHydrated & { key: number }>)`
  #actions {
    display: none;
    position: absolute;
    right: 0;
  }

  #actions > button {
    margin-right: 10px;
  }

  .ant-table-cell-row-hover #actions {
    display: block;
  }
`;

interface IProps {
  creditNotes: PaymentHydrated[];
  onSetCreationMode: (isCreationMode: boolean) => void;
  isCreditNotesCreationMode: boolean;
  setPage: (page: number) => void;
  page: number;
  isLoading?: boolean;
  total?: number;
}

export const CreditNotesView = ({
  creditNotes,
  isCreditNotesCreationMode,
  setPage,
  page,
  isLoading,
  total
}: IProps) => {
  const { current: invoice, setCurrentCreditNote } = useInvoiceStore(
    ({ current, setCurrentCreditNote }) => ({ current, setCurrentCreditNote })
  );
  const { setDrawerOpen } = useDrawerStore(({ setDrawerOpen }) => ({
    setDrawerOpen
  }));

  const deleteMutation = useDeletePayment();

  const dataSource = useMemo(() => {
    if (creditNotes) {
      return creditNotes.map((creditNote) => ({
        ...creditNote,
        key: creditNote.id
      }));
    } else {
      return [];
    }
  }, [creditNotes]);

  const handleOpen = (creditNote: PaymentHydrated) => {
    setCurrentCreditNote(creditNote);
    setDrawerOpen('creditNote');
  };

  const columns = useMemo(
    () => getColumns(deleteMutation.mutate, handleOpen),
    [deleteMutation.mutate]
  );
  const rows = sortBy(dataSource, 'receivedDate');

  const addNewButton = (type: 'link' | 'default') => {
    const hideLinkAndDefaultButtons = invoice?.invoiceTotal === 0;

    if (
      hideLinkAndDefaultButtons ||
      (type === 'link' && (isCreditNotesCreationMode || invoice?.amountDue === 0))
    ) {
      return null;
    }

    return (
      <Button
        type={type}
        size={CONTROL_SIZE}
        onClick={() => {
          setCurrentCreditNote({
            creditNoteItems: invoice?.invoiceItems.map(
              (item) =>
                ({
                  invoiceItemId: item.id,
                  quantity: item.quantity,
                  price: item.price,
                  amount: item.amount
                }) as CreditNoteItemHydrated
            )
          });
          setDrawerOpen('creditNote');
        }}
        icon={type === 'link' && <PlusOutlined />}>
        Add a new one
      </Button>
    );
  };

  if (!creditNotes.length && isCreditNotesCreationMode) {
    return null;
  }

  if (!creditNotes.length) {
    return (
      <Empty description="No credit note have been added yet" image={Empty.PRESENTED_IMAGE_SIMPLE}>
        {addNewButton('default')}
      </Empty>
    );
  }

  return (
    <StyledTable
      dataSource={rows}
      columns={columns}
      size={CONTROL_SIZE}
      pagination={
        total && total > PAGE_SIZE_50
          ? {
              size: CONTROL_SIZE,
              total,
              pageSize: PAGE_SIZE_50,
              showSizeChanger: false,
              onChange: setPage,
              current: page,
              position: ['bottomCenter']
            }
          : false
      }
      loading={isLoading}
      expandable={{
        defaultExpandAllRows: true,
        expandedRowRender: (record: PaymentHydrated) => (
          <CreditNoteItemsTable items={record.creditNoteItems as CreditNoteItemHydrated[]} />
        ),
        rowExpandable: (record: PaymentHydrated) =>
          !!record.creditNoteItems && record.creditNoteItems?.length > 0,
        columnWidth: 32
      }}
      summary={() => (
        <Table.Summary fixed="bottom">
          <Table.Summary.Row>
            <Table.Summary.Cell colSpan={9} index={0}>
              {addNewButton('link')}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        </Table.Summary>
      )}
    />
  );
};
