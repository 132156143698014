import React from 'react';
import { Button, Flex } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import {
  InvoiceDetailsBlock,
  InvoiceItemsBlock,
  PaymentPurposeBlock,
  CreditNotesBlock,
  InvoiceServicesBlock
} from './accordions';
import { useInvoiceStore } from 'store/invoiceStore';
import { PaperClipOutlined } from '@ant-design/icons';

interface IProps {
  setAddServiceDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InvoiceDetails = ({ setAddServiceDrawerOpen }: IProps) => {
  const { isInvoiceEditMode } = useInvoiceStore(({ isInvoiceEditMode }) => ({
    isInvoiceEditMode
  }));

  return (
    <Flex gap={8} vertical>
      {isInvoiceEditMode && (
        <div>
          <Button
            size={CONTROL_SIZE}
            style={{ marginBottom: '16px' }}
            type="dashed"
            icon={<PaperClipOutlined style={{ transform: 'rotate(135deg)' }} />}
            disabled>
            Upload a document
          </Button>
        </div>
      )}

      <InvoiceDetailsBlock />

      {!isInvoiceEditMode && (
        <>
          <InvoiceItemsBlock />
          <InvoiceServicesBlock setAddServiceDrawerOpen={setAddServiceDrawerOpen} />
          <PaymentPurposeBlock />
          <CreditNotesBlock />
        </>
      )}
    </Flex>
  );
};
