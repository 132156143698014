import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { currencyRatesApi } from 'services/api';
import { ENTITY_NAME, QUERY_KEY } from './consts';
import { CurrencyRateWithId } from 'store/currencyRateStore';
import { SERVER_DATE_FORMAT_CURRENCY_RATE, MESSAGE_DURATION } from 'consts/common';

const updateItem = async (data: CurrencyRateWithId) =>
  await currencyRatesApi.updateCurrencyRates({
    ...data,
    date: data.date.format(SERVER_DATE_FORMAT_CURRENCY_RATE)
  });

export const useUpdateCurrencyRate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateItem,
    onSuccess: async (response) => {
      if (response.data) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEY, response.data.date] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });

        message.success(`${ENTITY_NAME} has been successfully updated`, MESSAGE_DURATION);
      }
    }
  });
};
