import { Col, Flex, Form, Row, Select } from 'antd';
import React from 'react';
import { CONTROL_SIZE } from 'consts/common';
import { FlightDetailsBlock, MoreDetailsBlock } from './accordions';
import { getSelectOptions } from 'helpers';
import { flightStatuses } from 'consts/flight';
import { useFlightsDashboardStore } from 'store/flightsDashboardStore';
import { useSelectAutoFocus } from 'helpers';

export const FlightDetails = () => {
  const { isFlightEditMode } = useFlightsDashboardStore(({ isFlightEditMode }) => ({
    isFlightEditMode
  }));

  const selectStatusRef = useSelectAutoFocus(isFlightEditMode);

  return (
    <Flex gap={8} vertical>
      {isFlightEditMode && (
        <Row>
          <Col span={6}>
            <Form.Item name="status" rules={[{ required: true }]}>
              <Select
                options={getSelectOptions(flightStatuses)}
                size={CONTROL_SIZE}
                placeholder="Status"
                ref={selectStatusRef}
              />
            </Form.Item>
          </Col>
        </Row>
      )}

      <FlightDetailsBlock />
      <MoreDetailsBlock />
    </Flex>
  );
};
