import { RefSelectProps } from 'antd/lib/select';
import { useRef, useEffect } from 'react';

export const useSelectAutoFocus = (shouldRefocus?: boolean) => {
  const selectRef = useRef<RefSelectProps>(null);

  useEffect(() => {
    const focusAfterCurrentFrameRender = () => {
      const select = selectRef.current;

      // second condition is for jsdom environment
      if (select && typeof select.focus === 'function') {
        select.focus();
      }
    };

    requestAnimationFrame(focusAfterCurrentFrameRender);
  }, [shouldRefocus]);

  return selectRef;
};
