import { LocalStorageKey, getFromStorage, saveToStorage } from 'helpers';
import { LinkEntityItem } from 'services/api/data-contracts';
import { MessageListEntry } from 'services/emailEngineApi/data-contracts';

type QueuedMessage = { messageId: string; referenceId?: string; source: LinkEntityItem[] };

export const addQueuedMessageToStorage = (newMessage: QueuedMessage): void => {
  const queuedMessages = getFromStorage<QueuedMessage[]>(LocalStorageKey.NEW_MESSAGES_IDS) || [];
  const updatedQueue = [...queuedMessages, newMessage];
  saveToStorage(LocalStorageKey.NEW_MESSAGES_IDS, updatedQueue);
};

export const removeLinkedMessagesFromStorage = (linked: MessageListEntry[]): void => {
  const queuedMessages = getFromStorage<QueuedMessage[]>(LocalStorageKey.NEW_MESSAGES_IDS) || [];
  const updatedQueue = queuedMessages.filter(
    (queued) => !linked.some((linkedMessage) => linkedMessage.messageId === queued.messageId)
  );
  saveToStorage(LocalStorageKey.NEW_MESSAGES_IDS, updatedQueue);
};

export const getQueuedMessagesFromStorage = (): QueuedMessage[] => {
  return getFromStorage<QueuedMessage[]>(LocalStorageKey.NEW_MESSAGES_IDS) || [];
};
