import React, { useState } from 'react';
import { Button, Popconfirm } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { DeleteOutlined } from '@ant-design/icons';

interface IProps {
  isFormTouched?: boolean;
  onConfirm: () => void;
  isLoading: boolean;
}

export const CancelButtonWithConfirm = ({ isFormTouched, onConfirm, isLoading }: IProps) => {
  const [isConfirmOpen, setConfirmOpen] = useState(false);

  const handleOpenChange = async (newOpen: boolean) => {
    if (!newOpen) {
      setConfirmOpen(newOpen);
      return;
    }

    if (isFormTouched) {
      setConfirmOpen(newOpen);
    } else {
      onConfirm();
    }
  };

  return (
    <Popconfirm
      title="Close without saving?"
      onConfirm={onConfirm}
      onCancel={() => setConfirmOpen(false)}
      onOpenChange={handleOpenChange}
      open={isConfirmOpen}>
      <Button size={CONTROL_SIZE} loading={isLoading} icon={<DeleteOutlined />}>
        Close
      </Button>
    </Popconfirm>
  );
};
