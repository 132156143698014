import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { Button, Tooltip } from 'antd';
import {
  OwnPriceHydrated,
  SpecialParameter,
  SpecialParameterAlias
} from 'services/api/data-contracts';
import { numericSorter, stringSorter } from '../utils/tableSorters';
import { CheckOutlined, ProfileOutlined } from '@ant-design/icons';
import { getEntityDisplayValue } from '../components/forms/components/SpecialParameters/helpers/getEntityDisplayValue';
import { getAmountValue } from 'helpers';
import { CONTROL_SIZE } from 'consts/common';

export const getOwnPriceColumns = (): ColumnsType<OwnPriceHydrated> => [
  {
    title: 'Sp',
    dataIndex: 'isSpecialPrice',
    key: 'isSpecialPrice',
    render: (value, record) => {
      const firstSpecialParameter = record.specialParameters?.[0];

      if (!value || !firstSpecialParameter) return null;

      const title =
        firstSpecialParameter.alias === SpecialParameterAlias.Order
          ? 'Go to order'
          : 'Special price';

      const handleGoToOrder = () => {
        const url = new URL(window.location.origin + '/dashboard/dispatcher');
        const params = new URLSearchParams({
          orderNumbers: firstSpecialParameter.entityId.toString()
        });
        url.search = params.toString();

        window.open(url.toString(), '_blank');
      };

      const icon =
        firstSpecialParameter.alias === SpecialParameterAlias.Order ? (
          <Button
            type="link"
            icon={<ProfileOutlined style={{ color: '#000000D9' }} />}
            size={CONTROL_SIZE}
            onClick={handleGoToOrder}
          />
        ) : (
          <CheckOutlined style={{ marginLeft: '6px', color: '#000000D9' }} />
        );

      return <Tooltip title={title}>{icon}</Tooltip>;
    },
    width: 40,
    ellipsis: true
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => stringSorter(a.name, b.name),
    width: 200,
    ellipsis: true
  },
  {
    title: 'Service name',
    dataIndex: 'service',
    key: 'service',
    render: (_, record) => record.serviceName?.name || '-',
    sorter: (a, b) => stringSorter(a.serviceName?.name, b.serviceName?.name),
    ellipsis: true
  },
  {
    title: 'Cur',
    dataIndex: 'currency',
    key: 'currency',
    width: 50
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    sorter: (a, b) => numericSorter(a.price, b.price),
    render: (value) => getAmountValue(value) || '-'
  },
  {
    title: 'UOM',
    dataIndex: 'unitOfMeasure',
    key: 'unitOfMeasure',
    render: (_, record) => record.unitOfMeasure?.shortName || '-',
    width: 52,
    ellipsis: true
  }
];

export const getSpecialParametersColumns = (): ColumnsType<SpecialParameter> => [
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (_, record) => record.alias
  },
  {
    title: 'Values',
    dataIndex: 'values',
    key: 'values',
    render: (_, record) => getEntityDisplayValue(record.entityName, record),
    width: 220,
    ellipsis: true
  },
  {
    title: 'Note',
    dataIndex: 'note',
    key: 'note',
    render: (_, record) => record.notes || '-',
    ellipsis: true
  }
];
