import React from 'react';
import { NewMessage } from './components/NewMessage/NewMessage';
import { MessagesList } from './MessagesList';
import { NewMessageButton } from './components/NewMessage/components/NewMessageButton/NewMessageButton';
import { AttachmentViewer } from './components/AttachmentViewer/AttachmentViewer';
import { useMessageStore } from 'store/messageStore';
import styled from 'styled-components';
import { SIGNATURE_NAME } from 'queries/messages/consts';
import { MessagesErrorBoundary } from './MessagesErrorBoundary';

const StyledDiv = styled.div`
  height: 100%;

  .ant-drawer-content-wrapper {
    height: 100% !important;
  }
`;

export const MessagesWidget = () => {
  const {
    currentViewedAttachment,
    setNewMessageMode,
    newMessageMode,
    setCurrentMessage,
    signatures
  } = useMessageStore(
    ({
      currentViewedAttachment,
      setNewMessageMode,
      newMessageMode,
      setCurrentMessage,
      signatures
    }) => ({
      currentViewedAttachment,
      setNewMessageMode,
      newMessageMode,
      setCurrentMessage,
      signatures
    })
  );

  const handleNewMessage = () => {
    const signature = signatures.find(({ name }) => name === SIGNATURE_NAME.NEW);

    if (signature) {
      setCurrentMessage({
        body: signature.text
      });
    }

    setNewMessageMode('new');
  };

  const handleCloseDrawer = () => {
    setNewMessageMode(false);
  };

  if (currentViewedAttachment) {
    return <AttachmentViewer attachment={currentViewedAttachment} />;
  }

  return (
    <StyledDiv>
      {!!newMessageMode && (
        <NewMessage newMessageMode={newMessageMode} onClose={handleCloseDrawer} />
      )}

      <MessagesErrorBoundary>
        <>
          <MessagesList isDrawerVisible={!!newMessageMode} />
          <NewMessageButton onOpenNewMessageDrawer={handleNewMessage} />
        </>
      </MessagesErrorBoundary>
    </StyledDiv>
  );
};
