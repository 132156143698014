import { useMutation, useQueryClient } from '@tanstack/react-query';
import { paymentPurposesApi } from 'services/api';
import { PaymentPurposeHydrated, PaymentPurposeInput } from 'services/api/data-contracts';
import { BALANCE_WIDGET_QUERY_KEY } from 'queries/balance/consts';
import { useBalanceDashboardStore } from 'store/balanceDashboardStore';
import { INVOICE_QUERY_KEY } from 'queries/invoice/consts';
import { PAYMENT_QUERY_KEY } from 'queries/payment/consts';
import { QUERY_KEY } from './consts';
import { useInvoiceStore } from 'store/invoiceStore';
import { usePaymentStore } from 'store/paymentStore';

export enum SettlementEntityType {
  INVOICE = 'invoice',
  PAYMENT = 'payment',
  BALANCE = 'balance'
}

const createItem = async (data: PaymentPurposeInput) =>
  await paymentPurposesApi.addPaymentPurpose(data);

export const useCreatePaymentPurpose = (
  onSuccess?: (data: PaymentPurposeHydrated) => void,
  entityType?: SettlementEntityType
) => {
  const queryClient = useQueryClient();

  const { shouldUpdateBalanceView, setShouldUpdateBalanceView, filter } = useBalanceDashboardStore(
    ({ shouldUpdateBalanceView, setShouldUpdateBalanceView, filter }) => ({
      shouldUpdateBalanceView,
      setShouldUpdateBalanceView,
      filter
    })
  );
  const { current: invoice } = useInvoiceStore(({ current }) => ({ current }));
  const { current: payment } = usePaymentStore(({ current }) => ({ current }));

  return useMutation({
    mutationFn: createItem,
    onSuccess: async (response) => {
      onSuccess && onSuccess(response.data);
      await queryClient.invalidateQueries([QUERY_KEY]);

      entityType === SettlementEntityType.INVOICE &&
        (await queryClient.refetchQueries([INVOICE_QUERY_KEY, invoice?.id]));

      entityType === SettlementEntityType.PAYMENT &&
        (await queryClient.refetchQueries([PAYMENT_QUERY_KEY, payment?.id]));

      onSuccess && onSuccess(response.data);

      shouldUpdateBalanceView &&
        (await queryClient.refetchQueries([BALANCE_WIDGET_QUERY_KEY, filter]));
      shouldUpdateBalanceView && setShouldUpdateBalanceView(false);
    },
    onError: async () => {
      entityType === SettlementEntityType.BALANCE &&
        (await queryClient.refetchQueries([BALANCE_WIDGET_QUERY_KEY, filter]));
    }
  });
};
