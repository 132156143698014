import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { geekblue, magenta } from '@ant-design/colors';
import { InvoiceType } from 'services/api/data-contracts';
import { Tooltip } from 'antd';

export const arrowColors = {
  [InvoiceType.Received]: magenta[6],
  [InvoiceType.Issued]: geekblue[3]
};

export const ArrowIcon = ({ type, hideTooltip }: { type: InvoiceType; hideTooltip?: boolean }) => {
  const icon = (
    <ArrowLeftOutlined
      style={{
        color: arrowColors[type],
        fontSize: '16px',
        width: '16px',
        height: '16px',
        transform: `rotate(${type === InvoiceType.Received ? '180' : '0'}deg)`
      }}
    />
  );

  if (hideTooltip) {
    return icon;
  }

  return <Tooltip title={type}>{icon}</Tooltip>;
};
