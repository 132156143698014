import { useQuery } from '@tanstack/react-query';
import { contractsApi } from 'services/api';
import { SERVICE_QUERY_KEY } from './consts';
import { useContractStore } from 'store/contractStore';

const fetchList = async (contractId: number) => await contractsApi.getContractServices(contractId);

export const useGetContractServices = (contractId: number) => {
  const { setServices } = useContractStore(({ setServices }) => ({
    setServices
  }));

  return useQuery({
    queryKey: [SERVICE_QUERY_KEY, contractId],
    queryFn: () => fetchList(contractId),
    onSuccess: (response) => {
      setServices(
        response.data.items.map((service) => ({
          ...service,
          locationIds: service.locations?.map(({ id }) => id) || []
        })) || []
      );
    }
  });
};
