import { useQuery } from '@tanstack/react-query';
import { servicesApi } from 'services/api';
import { useServiceStore } from 'store/serviceStore';
import { QUERY_KEY } from './consts';

const fetchList = async () => await servicesApi.getServices();

export const useGetServices = () => {
  const { setList } = useServiceStore(({ setList }) => ({
    setList
  }));

  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: () => fetchList(),
    onSuccess: (response) => {
      setList(response.data.items);
    },
    keepPreviousData: true
  });
};
