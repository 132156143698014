import { useQuery } from '@tanstack/react-query';
import { ownPricesApi } from 'services/api';
import { QUERY_KEY } from './consts';
import { useOwnPriceStore } from 'store/ownPriceStore';

interface IFilter {
  page: number;
  pageSize?: number;
  name?: string;
  isSpecialPrice?: boolean;
  activeDate?: string;
}

const fetchList = async (filter: IFilter) => await ownPricesApi.getAllOwnPrices(filter);

export const useGetOwnPrices = (filter: IFilter) => {
  const { setList } = useOwnPriceStore(({ setList }) => ({
    setList
  }));

  return useQuery({
    queryKey: [QUERY_KEY, filter],
    queryFn: () => fetchList(filter),
    onSuccess: async (response) => {
      setList(response.data);
    },
    keepPreviousData: true
  });
};
