import { AliasToken } from 'antd/es/theme/internal';

export const customTheme = {
  token: {
    screenXLMin: 1366,
    screenXL: 1366, // default 1200
    screenXLMax: 1535, // default 1599
    screenXXLMin: 1536,
    screenXXL: 1536 // default is 1600
  }
};

export type CustomTokenBreakpoints = Pick<
  AliasToken,
  'screenXLMin' | 'screenXL' | 'screenXLMax' | 'screenXXLMin' | 'screenXXL'
>;
