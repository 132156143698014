import { useQuery } from '@tanstack/react-query';
import { citiesApi } from 'services/api';
import { QUERY_KEY } from './consts';
import { useCityStore } from 'store/cityStore';

interface IFilter {
  page: number;
  pageSize?: number;
  name?: string;
}

const fetchList = async (filter: IFilter) => await citiesApi.getCities(filter);

export const useGetCities = (filter: IFilter, shouldUpdateStore?: boolean) => {
  const { setList } = useCityStore(({ setList }) => ({ setList }));

  return useQuery({
    queryKey: [QUERY_KEY, filter],
    queryFn: () => fetchList(filter),
    onSuccess: (response) => {
      if (shouldUpdateStore) {
        setList(response.data);
      }
    }
  });
};
