import { useQuery } from '@tanstack/react-query';
import { balanceApi } from 'services/api';
import { BALANCE_WIDGET_QUERY_KEY } from './consts';
import { SERVER_DATE_FORMAT } from 'consts/common';
import { BalanceFilter, useBalanceDashboardStore } from 'store/balanceDashboardStore';
import { Dayjs } from 'dayjs';

const fetchList = async (filter: BalanceFilter) => {
  const date = filter.contractDate;
  const startDate = date ? (date[0] as unknown as Dayjs)?.format(SERVER_DATE_FORMAT) : undefined;
  const endDate = date ? (date[1] as unknown as Dayjs)?.format(SERVER_DATE_FORMAT) : undefined;

  const filters = {
    startDate: startDate as string,
    endDate: endDate as string,
    counterpartyId: filter?.counterpartyId as number,
    ...(filter.contractIds && { contractIds: filter?.contractIds }),
    ...(filter.currency && { currency: filter?.currency })
  };

  if (filters.startDate && filters.endDate && filters.counterpartyId) {
    return await balanceApi.getBalance(filters);
  }
};

export const useGetBalanceWidget = () => {
  const { filter, setData } = useBalanceDashboardStore(({ filter, setData }) => ({
    filter,
    setData
  }));

  return useQuery({
    queryKey: [BALANCE_WIDGET_QUERY_KEY, filter],
    queryFn: () => fetchList(filter),
    onSuccess: async (response) => {
      setData(response?.data);
    },
    enabled: !!filter?.counterpartyId
  });
};
