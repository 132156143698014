import React, { useEffect } from 'react';
import styled from 'styled-components';
import { List } from 'antd';
import { StyledList } from '../../DispatcherOrdersWidget';
import { getUTCDate, getOrderTerm } from 'helpers';
import { ServicesTable } from '../../components/OrderServices/ServicesTable';
import { ServicesGroup, useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { useOrderStore } from 'store/orderStore';
import { useGetOrderServicesWidget } from 'queries/orderServices';

const StyledListItem = styled(List.Item)`
  padding: 8px 16px 16px !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  gap: 8px;
  border: none !important;
`;

const ItemHeader = styled('div')`
  font-weight: 500;

  span:first-of-type {
    color: rgba(0, 0, 0, 0.45);
    margin-right: 8px;
  }
`;

interface IProps {
  header: React.ReactNode;
  footer: React.ReactNode;
}

export const ServicesView = ({ footer, header }: IProps) => {
  const { servicesList } = useOrdersDashboardStore(({ servicesList }) => ({
    servicesList
  }));
  const { setSelectedServices } = useOrderStore(({ setSelectedServices }) => ({
    setSelectedServices
  }));

  const { isFetching } = useGetOrderServicesWidget();

  useEffect(() => () => setSelectedServices([]), []);

  return (
    <StyledList
      dataSource={servicesList}
      header={header}
      footer={footer}
      loading={isFetching}
      bordered
      data-testid="services-view"
      renderItem={(item) => {
        const serviceGroup = item as ServicesGroup;

        const props = `${serviceGroup.location.searchName} ${getOrderTerm(
          getUTCDate(serviceGroup.startDatetime),
          getUTCDate(serviceGroup.endDatetime)
        )}`;

        const dataSource = serviceGroup.orderServices.filter(Boolean).map((service) => ({
          ...service,
          key: service.id
        }));

        return (
          <StyledListItem>
            <ItemHeader>
              <span>{serviceGroup.type}</span>
              <span>{props}</span>
            </ItemHeader>

            <ServicesTable dataSource={dataSource} isLoading={isFetching} isWidgetView />
          </StyledListItem>
        );
      }}
    />
  );
};
