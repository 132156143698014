import { Dropdown, FloatButton, MenuProps } from 'antd';
import { PlusOutlined, ProfileOutlined, SendOutlined } from '@ant-design/icons';
import React from 'react';
import { flightInitialData } from 'consts/flight';
import { OrderStatus } from 'services/api/data-contracts';
import { useFlightStore } from 'store/flightStore';
import { useOrderStore } from 'store/orderStore';
import { useFlightsDashboardStore } from 'store/flightsDashboardStore';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { useDrawerStore } from 'store/drawerStore';

export const AddNewButton = () => {
  const { setCurrent: setFlight } = useFlightStore(({ setCurrent }) => ({ setCurrent }));
  const { setCurrent: setOrder } = useOrderStore(({ setCurrent }) => ({ setCurrent }));
  const { setFlightEditMode } = useFlightsDashboardStore(({ setFlightEditMode }) => ({
    setFlightEditMode
  }));
  const { setDrawerOpen } = useDrawerStore(({ setDrawerOpen }) => ({
    setDrawerOpen
  }));
  const { setOrderEditMode } = useOrdersDashboardStore(({ setOrderEditMode }) => ({
    setOrderEditMode
  }));

  const items: MenuProps['items'] = [
    {
      label: 'Flight',
      key: 'Flight',
      icon: <SendOutlined />,
      onClick: () => {
        setFlight(flightInitialData);
        setFlightEditMode(true);
        setDrawerOpen('flight');
      }
    },
    {
      label: 'Order',
      key: 'Order',
      icon: <ProfileOutlined />,
      onClick: () => {
        setOrder({ status: OrderStatus.PRCG });
        setOrderEditMode(true);
        setDrawerOpen('order');
      }
    }
  ];

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <FloatButton
        type="primary"
        style={{ zIndex: 800, bottom: 70, right: 30 }}
        icon={<PlusOutlined />}
        data-testid="add-new-btn"
      />
    </Dropdown>
  );
};
