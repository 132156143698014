import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { OrderType } from 'services/api/data-contracts';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { useDrawerStore } from 'store/drawerStore';

export const useOrderDrawerRouting = () => {
  const { setOpenOrder } = useOrdersDashboardStore(({ setOpenOrder }) => ({ setOpenOrder }));
  const { setDrawerOpen } = useDrawerStore(({ setDrawerOpen }) => ({ setDrawerOpen }));

  const { entityType, entityId } = useParams();

  useEffect(() => {
    if (entityType !== 'order') return;
    if (!entityId) return;

    const [type, id] = entityId?.split('-');

    if (
      entityType === 'order' &&
      Object.values(OrderType).includes(type as OrderType) &&
      !isNaN(+id)
    ) {
      setOpenOrder({ id: +id, type: type as OrderType });
      setDrawerOpen('order');
    }
  }, []);
};
