import React, { useEffect, useMemo, useState } from 'react';
import { CONTROL_SIZE } from 'consts/common';
import { Button, Row, Table } from 'antd';
import { Contract, ContractType, PaymentTerm } from 'services/api/data-contracts';
import { useContractStore } from 'store/contractStore';
import { useDeleteContract, useGetContractsByCounterparty } from 'queries/contract';
import { CounterpartyContractView } from './CounterpartyContractView';
import { useGetCounterparties } from 'queries/counterparty';
import { getColumns } from './columns';
import { PlusOutlined } from '@ant-design/icons';
import { getUTCDate } from 'helpers';

interface IProps {
  counterpartyId: number;
}

export const CounterpartyContracts = ({ counterpartyId }: IProps) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const {
    list: contracts,
    setCurrent,
    setList
  } = useContractStore(({ list, setCurrent, setList }) => ({
    list,
    setCurrent,
    setList
  }));

  useEffect(() => {
    return () => setList([]);
  }, []);

  const contractListQuery = useGetContractsByCounterparty(counterpartyId);
  const counterpartyListQuery = useGetCounterparties({});
  const deleteMutation = useDeleteContract();

  const columns = useMemo(() => getColumns(deleteMutation.mutate), [deleteMutation.mutate]);
  const dataSource = useMemo(
    () =>
      contracts.map((contract) => ({
        ...contract,
        key: contract.id
      })),
    [contracts]
  );

  const handleAdd = () => {
    setCurrent({
      type: ContractType.WithSupplier,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      startDate: getUTCDate(),
      currencies: [],
      supplierId: counterpartyId,
      paymentTerm: PaymentTerm.Prepayment,
      dueDateCount: 0
    });
    setDrawerOpen(true);
  };

  const handleOpenDetails = (contract: Contract) => {
    setCurrent(contract);
    setDrawerOpen(true);
  };

  const handleCloseModal = () => {
    setDrawerOpen(false);
    setCurrent(undefined);
  };

  return (
    <>
      <Row justify="end" style={{ marginBottom: 12 }}>
        <Button
          size={CONTROL_SIZE}
          type="dashed"
          onClick={handleAdd}
          loading={contractListQuery.isLoading || counterpartyListQuery.isLoading}
          icon={<PlusOutlined />}>
          Add
        </Button>
      </Row>
      <Table
        columns={columns}
        dataSource={dataSource}
        onRow={(record) => ({
          onClick: ({ target }) => {
            const isButton = (target as HTMLElement).closest('button');

            if (!isButton) {
              handleOpenDetails(record);
            }
          }
        })}
        size={CONTROL_SIZE}
        loading={
          contractListQuery.isLoading || counterpartyListQuery.isLoading || deleteMutation.isLoading
        }
        pagination={false}
      />

      <CounterpartyContractView isOpen={isDrawerOpen} onClose={handleCloseModal} />
    </>
  );
};
