import { blue } from '@ant-design/colors';

export const getBackgroundColor = (isChecked: boolean, isLinked: boolean) => {
  if (isLinked && isChecked) {
    return '#b7eb8f';
  } else if (isChecked) {
    return blue[0];
  } else if (isLinked) {
    return '#d9f7be';
  } else {
    return 'inherit';
  }
};
