import { ColumnsType } from 'antd/es/table';
import {
  Aircraft,
  Airport,
  City,
  Contract,
  Counterparty,
  Country,
  FlightPurpose,
  FlightType,
  OwnPriceHydrated,
  RebateHydrated,
  Service,
  ServiceName,
  UnitOfMeasure,
  VatRate
} from 'services/api/data-contracts';
import { QueryObserverLoadingResult, UseMutateFunction } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { CurrencyRateWithId } from 'store/currencyRateStore';
import { Dayjs } from 'dayjs';
import { REBATE_TYPE_OPTIONS } from './components/DictionaryHeader/constants/rebates';

export const DICTIONARIES = [
  'aircraft',
  'airport',
  'city',
  'contract',
  'counterparty',
  'country',
  'currency-rate',
  'flight-type',
  'flight-purpose',
  'own-price',
  'rebate',
  'service',
  'service-name',
  'unit-of-measure',
  'vat-rate'
] as const;

type DictionaryTuple = typeof DICTIONARIES;
export type DictionaryName = DictionaryTuple[number];

export type DictionaryTypesMap = {
  aircraft: Aircraft;
  airport: Airport;
  city: City;
  contract: Contract;
  counterparty: Counterparty;
  country: Country;
  'currency-rate': CurrencyRateWithId;
  'flight-type': FlightType;
  'flight-purpose': FlightPurpose;
  'own-price': OwnPriceHydrated;
  rebate: RebateHydrated;
  service: Service;
  'service-name': ServiceName;
  'unit-of-measure': UnitOfMeasure;
  'vat-rate': VatRate;
};

export type TDictionaryStore<T> = {
  current?: T;
  list: T[];
  setCurrent: (value?: T) => void;
  total?: number;
};

export type TDictionaryQueries<T> = {
  onDelete: UseMutateFunction<void, unknown, number | string, unknown>;
  onCreate: UseMutateFunction<AxiosResponse<T>, unknown, T, unknown>;
  onUpdate: UseMutateFunction<AxiosResponse<T>, unknown, T, unknown>;
  isLoading: boolean;
  getItem: (enabled: boolean, id: string) => QueryObserverLoadingResult<AxiosResponse<T, unknown>>;
};
export type TDictionaryColumns<T> = ColumnsType<T>;

export type TDictionaryConfig<T> = TDictionaryStore<T> &
  TDictionaryQueries<T> & { columns: TDictionaryColumns<T> };

export interface IFilter {
  page: number;
  pageSize: number;
  name?: string;
  isSpecialPrice?: string;
  activeDate?: Dayjs;
  rebateType?: REBATE_TYPE_OPTIONS;
}
