import React, { useContext, useMemo } from 'react';
import { ConfigProvider, Drawer, Flex, Form, InputNumber, Tooltip } from 'antd';
import styled from 'styled-components';
import { useInvoiceStore } from 'store/invoiceStore';
import { PaymentPurposeHydrated } from 'services/api/data-contracts';
import { DashboardContext, DashboardType } from '../../../consts';
import { CONTROL_SIZE } from 'consts/common';
import { InfoCircleOutlined } from '@ant-design/icons';
import { HeaderButtons } from '../HeaderButtons';
import { useUpdatePaymentPurpose } from 'queries/paymentPurpose/useUpdatePaymentPurpose';
import { PaymentPurposeEditModeBase, usePaymentPurposeStore } from 'store/paymentPurposeStore';
import { transformForRender } from './helpers/transformForRender';
import { LinkedPaymentPurposeInput } from './LinkedPaymentPurposeInput';
import { getTitle } from './helpers/getTitle';
import { transformForRequest } from './helpers/transformForRequest';
import { usePaymentStore } from 'store/paymentStore';
import { areCurrenciesDifferent } from './helpers/areCurrenciesDifferent';
import { getAmountValue } from 'helpers';
import { RuleObject } from 'antd/es/form';
import { PaymentPurposeWithContract } from '../PaymentDrawer/PaymentDetails/PaymentPurposeBlock/columns';

const StyledDrawer = styled(Drawer)`
  .ant-drawer-header {
    box-shadow:
      0 6px 6px 0 #00000014,
      0 3px 6px -4px #0000001f;
    padding: 8px;
  }

  .ant-drawer-body {
    padding: 24px 8px 8px;
  }

  .ant-drawer-header {
    border: none;
  }
`;

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  isFullScreenMode?: boolean;
}

export const PaymentPurposeDrawer = ({ isOpen, onClose, isFullScreenMode }: IProps) => {
  const [form] = Form.useForm();

  const {
    current: paymentPurpose,
    editModeBase,
    setCurrent,
    nonCurrentInInvoiceDrawer
  } = usePaymentPurposeStore(({ current, editModeBase, setCurrent, list }) => ({
    current,
    editModeBase,
    setCurrent,
    nonCurrentInInvoiceDrawer: list.filter((payment) => payment.id !== current?.id)
  }));

  const { current: invoice } = useInvoiceStore(({ current }) => ({
    current
  }));

  const { current: payment, nonCurrentInPaymentDrawer } = usePaymentStore(({ current }) => ({
    current,
    nonCurrentInPaymentDrawer: current?.paymentPurposes?.filter(
      (payment) => payment.id !== paymentPurpose?.id
    )
  }));

  const { type: dashboardType } = useContext(DashboardContext);

  const formInitialData = paymentPurpose && transformForRender(paymentPurpose);

  const isRenderInContainer = dashboardType === DashboardType.Settlement && !isFullScreenMode;

  const handleSelectChange = (
    _: number,
    paymentPurpose: PaymentPurposeHydrated | PaymentPurposeWithContract
  ) => {
    form.resetFields(['paidAmount', 'paidAmountInInvoiceCurrency']);
    setCurrent(paymentPurpose);
  };

  const isDifferentCurrency = useMemo(
    () =>
      areCurrenciesDifferent(
        paymentPurpose as PaymentPurposeHydrated | PaymentPurposeWithContract,
        editModeBase as PaymentPurposeEditModeBase,
        invoice,
        payment
      ),
    [paymentPurpose, editModeBase]
  );

  const isOpenedInInvoice = editModeBase === PaymentPurposeEditModeBase.INVOICE;
  const isOpenedInPayment = editModeBase === PaymentPurposeEditModeBase.PAYMENT;
  const drawerDataToUpdate = isOpenedInInvoice ? 'invoice' : 'payment';

  const totalAmountOfNonCurrentInInvoice = useMemo(() => {
    if (nonCurrentInInvoiceDrawer) {
      return nonCurrentInInvoiceDrawer.reduce((total, paymentPurpose) => {
        if (isOpenedInInvoice && paymentPurpose.payment.currency !== invoice?.currency) {
          const amount = paymentPurpose.paidAmountInInvoiceCurrency ?? 0;
          return total + amount;
        }

        return total + paymentPurpose.paidAmount;
      }, 0);
    }
    return 0;
  }, [nonCurrentInInvoiceDrawer]);

  const totalAmountOfNonCurrentInPayment = useMemo(() => {
    if (nonCurrentInPaymentDrawer) {
      return nonCurrentInPaymentDrawer.reduce((total, paymentPurpose) => {
        return total + paymentPurpose.paidAmount;
      }, 0);
    }
    return 0;
  }, [nonCurrentInPaymentDrawer]);

  const updateMutation = useUpdatePaymentPurpose(() => {
    onClose();
  }, drawerDataToUpdate);

  const handleSubmit = async () => {
    try {
      await form.validateFields();

      if (paymentPurpose?.id) {
        const data = transformForRequest(paymentPurpose, form.getFieldsValue());
        updateMutation.mutate(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const validateAmount = (_: RuleObject, value: number) => {
    const currentTotal = isOpenedInInvoice
      ? totalAmountOfNonCurrentInInvoice + value
      : totalAmountOfNonCurrentInPayment + value;

    if (
      isOpenedInInvoice &&
      !isDifferentCurrency &&
      invoice &&
      invoice.invoiceTotal &&
      currentTotal > invoice.invoiceTotal
    ) {
      return Promise.reject(
        `Limit is ${getAmountValue(invoice.invoiceTotal - totalAmountOfNonCurrentInInvoice)}`
      );
    }

    if (isOpenedInPayment && payment && payment.amount && currentTotal > payment.amount) {
      return Promise.reject(
        `Limit is ${getAmountValue(payment.amount - totalAmountOfNonCurrentInPayment)}`
      );
    }
    return Promise.resolve();
  };

  const validateAmountInInvoiceCurrency = (_: RuleObject, value: number) => {
    const currentTotal = isOpenedInInvoice
      ? totalAmountOfNonCurrentInInvoice + value
      : totalAmountOfNonCurrentInPayment + value;

    if (
      isOpenedInInvoice &&
      invoice &&
      invoice.invoiceTotal &&
      currentTotal > invoice.invoiceTotal
    ) {
      return Promise.reject(
        `Limit is ${getAmountValue(invoice.invoiceTotal - totalAmountOfNonCurrentInInvoice)}`
      );
    }
    return Promise.resolve();
  };

  return (
    <>
      <StyledDrawer
        open={isOpen}
        onClose={() => onClose()}
        width={700}
        title={getTitle(
          editModeBase as PaymentPurposeEditModeBase,
          paymentPurpose as PaymentPurposeHydrated | PaymentPurposeWithContract
        )}
        closable={false}
        extra={
          <HeaderButtons
            onCancel={() => onClose()}
            onSubmit={handleSubmit}
            isLoading={updateMutation.isLoading}
          />
        }
        {...(isRenderInContainer ? { getContainer: false } : {})}>
        <ConfigProvider form={{ validateMessages: { required: 'Is required' } }}>
          <Form
            form={form}
            colon={false}
            size={CONTROL_SIZE}
            requiredMark={false}
            layout="vertical"
            initialValues={formInitialData}
            autoComplete="off"
            className="styled-form">
            <Flex gap={8} wrap="nowrap">
              <div style={{ flexBasis: 190, overflow: 'hidden', flexGrow: 1 }}>
                <LinkedPaymentPurposeInput
                  onChange={handleSelectChange}
                  editModeBase={editModeBase as PaymentPurposeEditModeBase}
                />
              </div>

              <Form.Item
                name="paidAmount"
                label={
                  <>
                    <span
                      style={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden'
                      }}>
                      Paid Amount
                    </span>
                    <Tooltip
                      title={
                        isDifferentCurrency
                          ? 'Can be equal or less than payment advance'
                          : 'Can be equal or less than payment advance or invoice amount due'
                      }>
                      <InfoCircleOutlined style={{ marginLeft: '6px', cursor: 'pointer' }} />
                    </Tooltip>
                  </>
                }
                rules={[
                  { required: true },
                  {
                    validator: validateAmount
                  },
                  { min: 0, type: 'number' }
                ]}
                style={{ flexBasis: '20%' }}>
                <InputNumber
                  type="number"
                  addonAfter={
                    isDifferentCurrency
                      ? payment?.currency ||
                        (paymentPurpose as PaymentPurposeHydrated)?.payment?.currency
                      : false
                  }
                  size={CONTROL_SIZE}
                  min={0}
                  style={{ width: '100%' }}
                  data-testid="amount-input"
                  precision={2}
                />
              </Form.Item>

              {isDifferentCurrency && (
                <Form.Item
                  name="paidAmountInInvoiceCurrency"
                  data-testid="paid-amount-in-invoice-currency"
                  label={
                    <>
                      <span
                        style={{
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden'
                        }}>
                        In invoice currency
                      </span>
                      <Tooltip title="Can be equal or less than invoice amount due">
                        <InfoCircleOutlined style={{ marginLeft: '6px', cursor: 'pointer' }} />
                      </Tooltip>
                    </>
                  }
                  rules={[
                    { required: true },
                    {
                      validator: validateAmountInInvoiceCurrency
                    },
                    { min: 0, type: 'number' }
                  ]}
                  style={{ flexBasis: '20%' }}>
                  <InputNumber
                    type="number"
                    addonAfter={paymentPurpose?.invoice?.currency ?? ' - '}
                    min={0}
                    size={CONTROL_SIZE}
                    style={{ width: '100%' }}
                    precision={2}
                  />
                </Form.Item>
              )}
            </Flex>
          </Form>
        </ConfigProvider>
      </StyledDrawer>
    </>
  );
};
