import { PaymentPurposeHydrated } from 'services/api/data-contracts';
import { PaymentPurposeWithContract } from '../../PaymentDrawer/PaymentDetails/PaymentPurposeBlock/columns';

export const transformForRender = (
  paymentPurpose: PaymentPurposeHydrated | PaymentPurposeWithContract
) => ({
  paymentPurpose: paymentPurpose.id,
  paidAmount: paymentPurpose.paidAmount,
  ...(paymentPurpose.paidAmountInInvoiceCurrency
    ? { paidAmountInInvoiceCurrency: paymentPurpose.paidAmountInInvoiceCurrency }
    : {})
});
