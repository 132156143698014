import React from 'react';
import { Radio, Skeleton } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { Dayjs } from 'dayjs';
import { settingsStore } from 'services/settings/SettingsStore';

interface IProps {
  onFilter: (name: string, value: string | Dayjs | boolean | undefined | null) => void;
}

export const CurrencyRateFilter = ({ onFilter }: IProps) => {
  const currentTenant = settingsStore.getCurrentTenant();

  if (!currentTenant.allowedCurrencies || !currentTenant.mainCurrency) {
    return <Skeleton.Input size={CONTROL_SIZE} style={{ width: 100 }} />;
  }

  return (
    <Radio.Group
      onChange={({ target: { value } }) => onFilter('name', value)}
      defaultValue={currentTenant.mainCurrency || ''}
      size={CONTROL_SIZE}
      buttonStyle="solid">
      {(currentTenant.allowedCurrencies || []).map((curr) => (
        <Radio.Button value={curr} key={curr}>
          {curr}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};
