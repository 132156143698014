import { useQuery } from '@tanstack/react-query';
import { serviceNamesApi } from 'services/api';
import { QUERY_KEY } from './consts';
import { useServiceNameStore } from 'store/serviceNameStore';

const fetchList = async () => await serviceNamesApi.getAllServiceNames();

export const useGetServiceNames = () => {
  const { setList } = useServiceNameStore(({ setList }) => ({
    setList
  }));

  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: () => fetchList(),
    onSuccess: async (response) => {
      setList(response.data.items);
    },
    keepPreviousData: true
  });
};
