import { getUTCDate } from 'helpers';
import { CreditNoteDocument, DocumentItem, InvoiceDocument, isInvoice } from '../columns';
import { DATE_FORMAT_SHORT } from 'consts/common';
import { ReportFormat } from 'services/api/data-contracts';

export const getFilename = (
  file: DocumentItem,
  format: ReportFormat,
  counterpartyName?: string
) => {
  const extension = format === ReportFormat.Pdf ? 'pdf' : 'docx';
  if (isInvoice(file)) {
    const invoice = file as InvoiceDocument;
    return `Invoice_${invoice.invoiceNumber || ''}_${getUTCDate(invoice.createdAt)?.format(DATE_FORMAT_SHORT)}_${counterpartyName || ''}.${extension}`;
  } else {
    const creditNote = file as CreditNoteDocument;
    return `Credit_note_${creditNote.creditNoteNumber || ''}_${getUTCDate(creditNote.createdAt)?.format(DATE_FORMAT_SHORT)}_${counterpartyName || ''}.${extension}`;
  }
};
