/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Airport,
  AirportInput,
  AirportListResponse,
  Error,
  ValidationError
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Airports<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Create a new airport
   *
   * @tags airports
   * @name AddAirport
   * @summary Create a new airport
   * @request POST:/airports
   * @secure
   */
  addAirport = (data: AirportInput, params: RequestParams = {}) =>
    this.http.request<Airport, ValidationError | Error>({
      path: `/airports`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Get a list of airports
   *
   * @tags airports
   * @name GetAirports
   * @summary Get a list of airports
   * @request GET:/airports
   * @secure
   */
  getAirports = (
    query?: {
      /**
       * The current page number
       * @format int32
       * @min 1
       * @max 10000
       * @default 1
       */
      page?: number;
      /**
       * The number of items to return per page
       * @format int32
       * @min 1
       * @max 10000
       * @default 10
       */
      pageSize?: number;
      /** Filter by aiport name. Ex - Kempegowda International Airport */
      name?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<AirportListResponse, Error>({
      path: `/airports`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Get a specific airport
   *
   * @tags airports
   * @name GetAirportById
   * @summary Get a specific airport
   * @request GET:/airports/{airportId}
   * @secure
   */
  getAirportById = (airportId: number, params: RequestParams = {}) =>
    this.http.request<Airport, Error>({
      path: `/airports/${airportId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Update a specific airport
   *
   * @tags airports
   * @name UpdateAirport
   * @summary Update a specific airport
   * @request PUT:/airports/{airportId}
   * @secure
   */
  updateAirport = (airportId: number, data: AirportInput, params: RequestParams = {}) =>
    this.http.request<Airport, ValidationError | Error>({
      path: `/airports/${airportId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Delete a specific airport
   *
   * @tags airports
   * @name DeleteAirport
   * @summary Delete a specific airport
   * @request DELETE:/airports/{airportId}
   * @secure
   */
  deleteAirport = (airportId: number, params: RequestParams = {}) =>
    this.http.request<void, ValidationError | Error>({
      path: `/airports/${airportId}`,
      method: 'DELETE',
      secure: true,
      ...params
    });
}
