import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { flightsApi } from 'services/api';
import { Flight } from 'services/api/data-contracts';
import { ENTITY_NAME, LINKS_QUERY_KEY, QUERY_KEY } from './consts';
import { useFlightStore } from 'store/flightStore';
import { useFlightsDashboardStore } from 'store/flightsDashboardStore';
import { ADD_ORDER_QUERY_KEY } from '../orders/addOrder/consts';
import { FUEL_ORDER_QUERY_KEY } from '../orders/fuelOrder/consts';
import { GH_ORDER_QUERY_KEY } from '../orders/ghOrder/consts';
import { PMT_ORDER_QUERY_KEY } from '../orders/permitOrder/consts';
import { WIDGET_QUERY_KEY } from '../orders/consts';
import { useOrderStore } from 'store/orderStore';
import { MESSAGE_DURATION } from 'consts/common';

const updateItem = async (data: Flight, id: number) => await flightsApi.updateFlight(id, data);

export const useUpdateFlight = (onSuccess?: () => void) => {
  const { setCurrent } = useFlightStore(({ setCurrent }) => ({
    setCurrent
  }));
  const { flightsList, setFlightsList } = useFlightsDashboardStore(
    ({ flightsList, setFlightsList }) => ({
      flightsList,
      setFlightsList
    })
  );
  const { order } = useOrderStore(({ current }) => ({ order: current }));

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Flight) => await updateItem(data, data.id),
    onSuccess: async (response) => {
      if (response.data) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEY, response.data.id] });
        await queryClient.invalidateQueries({ queryKey: [LINKS_QUERY_KEY] });

        await queryClient.invalidateQueries({ queryKey: [ADD_ORDER_QUERY_KEY, order?.id] });
        await queryClient.invalidateQueries({ queryKey: [FUEL_ORDER_QUERY_KEY, order?.id] });
        await queryClient.invalidateQueries({ queryKey: [GH_ORDER_QUERY_KEY, order?.id] });
        await queryClient.invalidateQueries({ queryKey: [PMT_ORDER_QUERY_KEY, order?.id] });

        await queryClient.invalidateQueries({ queryKey: [WIDGET_QUERY_KEY] });

        message.success(
          `${ENTITY_NAME} #${response.data.number} has been successfully updated`,
          MESSAGE_DURATION
        );

        setCurrent(response.data);

        const updatedList = flightsList.map((flight) =>
          flight.id === response.data.id ? { ...flight, ...response.data } : flight
        );
        setFlightsList(updatedList);

        onSuccess && onSuccess();
      }
    }
  });
};
