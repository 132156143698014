import { useQuery } from '@tanstack/react-query';
import { orderServicesApi } from 'services/api';
import { WIDGET_QUERY_KEY } from './consts';
import { useDashboardStore } from 'store/dashboardStore';
import { AxiosResponse } from 'axios';
import { useFlightsDashboardStore } from 'store/flightsDashboardStore';
import { OrdersFilter, useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { DEFAULT_LIMIT, SERVER_DATE_FORMAT } from 'consts/common';
import { useMessageStore } from 'store/messageStore';
import { map } from 'lodash';

interface FetchListProps {
  filter: OrdersFilter;
  flightIds?: string;
  messageIds?: string;
}

const fetchList = async ({ filter, messageIds, flightIds }: FetchListProps) =>
  await orderServicesApi.getOrderServicesWidget({
    flightIds,
    messageIds,
    sortBy: 'startDatetime',
    locationNames: filter.location,
    customerNames: filter.customer,
    providerNames: filter.provider,
    supplierNames: filter.supplier,
    orderNumbers: filter.orderNumbers,
    types: filter.orderTypes?.join(','),
    payerNames: filter.payer,
    startDatetime: filter.orderStartDate?.map((date) => date?.format(SERVER_DATE_FORMAT)).join(','),
    endDatetime: filter.orderEndDate?.map((date) => date?.format(SERVER_DATE_FORMAT)).join(','),
    orderStatuses: filter.orderStatuses?.join(','),
    aircraftTailNumbers: filter.aircraftTailNumbers,
    limit: DEFAULT_LIMIT
  });

export const useGetOrderServicesWidget = () => {
  const { linkFilters } = useDashboardStore(({ linkFilters }) => ({ linkFilters }));
  const { setServicesList, ordersFilter } = useOrdersDashboardStore(
    ({ setServicesList, ordersFilter }) => ({ setServicesList, ordersFilter })
  );
  const { flightIds } = useFlightsDashboardStore(({ selectedFlights }) => ({
    flightIds: map(selectedFlights, 'id')
  }));
  const { messageIds } = useMessageStore(({ selectedMessages }) => ({
    messageIds: map(selectedMessages, 'id')
  }));

  const flightsFilterLength = linkFilters.includes('flight-order') ? flightIds.length : 0;
  const messagesFilterLength = linkFilters.includes('message-order') ? messageIds.length : 0;

  return useQuery({
    queryKey: [WIDGET_QUERY_KEY, ordersFilter, flightsFilterLength, messagesFilterLength],
    queryFn: () =>
      fetchList({
        filter: ordersFilter,
        flightIds: flightsFilterLength ? flightIds.join(',') : undefined,
        messageIds: messagesFilterLength ? messageIds.join(',') : undefined
      }),
    onSuccess: async (response) => {
      setServicesList((response as AxiosResponse).data?.items || []);
    }
  });
};
