import { create } from 'zustand';
import { Airport, FlightWidgetItem } from 'services/api/data-contracts';
import { devtools } from 'zustand/middleware';
import { defaultFlightsFilter } from 'consts/flight';
import { RangeValue } from 'store/helpers/types';

export interface FlightsFilter {
  operators?: { value: number; label: string }[];
  flightsAircrafts?: { value: number; label: string }[];
  flightNumber?: string;
  estimatedTimeDeparture?: RangeValue;
  airport?: Airport;
}

type FlightsDashboardStore = {
  flightsList: FlightWidgetItem[];
  setFlightsList: (flightsList: FlightWidgetItem[]) => void;
  flightsListItemsCount: number;
  setFlightsListItemsCount: (flightsListItemsCount?: number) => void;
  flightsFilter: FlightsFilter;
  setFlightsFilter: (flightsFilter?: FlightsFilter) => void;
  flightsPageNumber: number;
  setFlightsPageNumber: (flightsPageNumber?: number) => void;
  selectedFlights: FlightWidgetItem[];
  setSelectedFlights: (selectedFlights: FlightWidgetItem[]) => void;
  isFlightEditMode: boolean;
  setFlightEditMode: (isFlightEditMode: boolean) => void;
  openFlightId?: number;
  setOpenFlightId: (openFlightId?: number) => void;
};

export const useFlightsDashboardStore = create<FlightsDashboardStore>()(
  devtools((set) => ({
    flightsList: [],
    setFlightsList: (flightsList) => {
      set({ flightsList });
    },
    flightsListItemsCount: 0,
    setFlightsListItemsCount: (flightsListItemsCount = 0) => {
      set({ flightsListItemsCount });
    },
    flightsFilter: defaultFlightsFilter,
    setFlightsFilter: (flightsFilter) => {
      set({ flightsFilter });
    },
    flightsPageNumber: 1,
    setFlightsPageNumber: (flightsPageNumber = 1) => {
      set({ flightsPageNumber });
    },
    selectedFlights: [],
    setSelectedFlights: (selectedFlights) => {
      set({ selectedFlights });
    },
    isFlightEditMode: false,
    setFlightEditMode: (isFlightEditMode) => {
      set({ isFlightEditMode });
    },
    openFlightId: undefined,
    setOpenFlightId: (openFlightId) => {
      set({ openFlightId });
    }
  }))
);
