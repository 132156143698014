import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { flightProgramsApi } from 'services/api';
import { ProgramInput } from 'services/api/data-contracts';
import { ENTITY_NAME, QUERY_KEY } from './consts';
import { MESSAGE_DURATION } from 'consts/common';
import { WIDGET_QUERY_KEY } from '../orders/consts';

const createItem = async (data: ProgramInput) => await flightProgramsApi.addProgram(data);

export const useCreateFlightProgram = (onSuccess?: (id: number) => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createItem,
    onSuccess: async (response) => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      await queryClient.invalidateQueries({ queryKey: [WIDGET_QUERY_KEY] });

      message.success(
        `${ENTITY_NAME} #${response.data.programNumber} has been successfully created`,
        MESSAGE_DURATION
      );

      onSuccess && onSuccess(response.data.id);
    }
  });
};
