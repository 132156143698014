import React, { useMemo } from 'react';
import { ConfigProvider, Empty, Form, Select } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { useGetOrdersWidget } from 'queries/orders';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { getOrderTerm, getUTCDate } from 'helpers';

interface IProps {
  name: string | (string | number)[];
  label: string;
  disabled?: boolean;
  required?: boolean;
}

export const OrderInput = ({ disabled, label, name, required }: IProps) => {
  const { ordersList } = useOrdersDashboardStore(({ ordersList }) => ({ ordersList }));

  const { isLoading } = useGetOrdersWidget();

  const options = useMemo(
    () =>
      ordersList.map((order) => {
        const label = [
          order.type,
          order.location?.name,
          getOrderTerm(getUTCDate(order.startDatetime), getUTCDate(order.endDatetime)),
          order.customer?.name
        ]
          .filter((i) => !!i)
          .join(' | ');

        return {
          label,
          value: order.number
        };
      }),
    [ordersList]
  );

  return (
    <ConfigProvider
      renderEmpty={() => <Empty description="No data" image={Empty.PRESENTED_IMAGE_SIMPLE} />}>
      <Form.Item name={name} label={label} rules={[{ required }]}>
        <Select
          allowClear
          style={{ width: '100%' }}
          placeholder="Select order"
          options={options}
          size={CONTROL_SIZE}
          disabled={disabled}
          showSearch
          optionFilterProp="label"
          loading={isLoading}
          popupMatchSelectWidth={false}
        />
      </Form.Item>
    </ConfigProvider>
  );
};
