import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { usePaymentStore } from 'store/paymentStore';
import { useDrawerStore } from 'store/drawerStore';

export const usePaymentDrawerRouting = () => {
  const { setCurrent } = usePaymentStore(({ setCurrent }) => ({
    setCurrent
  }));
  const { setDrawerOpen } = useDrawerStore(({ setDrawerOpen }) => ({ setDrawerOpen }));

  const { entityType, entityId } = useParams();

  useEffect(() => {
    if (entityType !== 'payment') return;
    if (!entityId) return;

    if (entityType === 'payment' && !isNaN(+entityId)) {
      setCurrent({ id: +entityId });
      setDrawerOpen('payment');
    }
  }, []);
};
