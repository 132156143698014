import React from 'react';
import { StyledCollapse } from 'components/common/StyledCollapse';
import { CollapseProps } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { useFlightStore } from 'store/flightStore';
import { MoreDetailsView } from './MoreDetailsView';
import { Flight } from 'services/api/data-contracts';
import { MoreDetailsForm } from './MoreDetailsForm';
import { useFlightsDashboardStore } from 'store/flightsDashboardStore';

export const MoreDetailsBlock = () => {
  const { current } = useFlightStore(({ current }) => ({ current: current as Flight }));
  const { isFlightEditMode } = useFlightsDashboardStore(({ isFlightEditMode }) => ({
    isFlightEditMode
  }));

  if (!current) {
    return null;
  }

  const collapseContent: CollapseProps['items'] = [
    {
      key: 'more',
      label: 'More details',
      children: isFlightEditMode ? <MoreDetailsForm /> : <MoreDetailsView flight={current} />
    }
  ];

  return (
    <StyledCollapse
      items={collapseContent}
      expandIconPosition="end"
      size={CONTROL_SIZE}
      defaultActiveKey="more"
      ghost
    />
  );
};
