import { DictionaryName } from '../models';
import React, { ReactNode } from 'react';
import {
  AircraftDictionaryForm,
  AirportDictionaryForm,
  CityDictionaryForm,
  ContractDictionaryForm,
  CounterpartyDictionaryForm,
  CountryDictionaryForm,
  CurrencyRateDictionaryForm,
  FlightPurposeDictionaryForm,
  FlightTypeDictionaryForm,
  OwnPriceDictionaryForm,
  RebateDictionaryForm,
  ServiceDictionaryForm,
  ServiceNameDictionaryForm,
  UnitOfMeasureDictionaryForm,
  VatRateDictionaryForm
} from './forms';
import {
  AircraftDictionaryView,
  AirportDictionaryView,
  CityDictionaryView,
  ContractDictionaryView,
  CounterpartyDictionaryView,
  CountryDictionaryView,
  CurrencyRateDictionaryView,
  FlightPurposeDictionaryView,
  FlightTypeDictionaryView,
  OwnPriceDictionaryView,
  RebateDictionaryView,
  ServiceDictionaryView,
  ServiceNameDictionaryView,
  UnitOfMeasureDictionaryView,
  VatRateDictionaryView
} from './views';

export const formComponent: Record<DictionaryName, ReactNode> = {
  aircraft: <AircraftDictionaryForm />,
  airport: <AirportDictionaryForm />,
  city: <CityDictionaryForm />,
  contract: <ContractDictionaryForm />,
  counterparty: <CounterpartyDictionaryForm />,
  country: <CountryDictionaryForm />,
  'currency-rate': <CurrencyRateDictionaryForm />,
  'flight-type': <FlightTypeDictionaryForm />,
  'flight-purpose': <FlightPurposeDictionaryForm />,
  'own-price': <OwnPriceDictionaryForm />,
  rebate: <RebateDictionaryForm />,
  service: <ServiceDictionaryForm />,
  'service-name': <ServiceNameDictionaryForm />,
  'unit-of-measure': <UnitOfMeasureDictionaryForm />,
  'vat-rate': <VatRateDictionaryForm />
};

export const viewComponent: Record<DictionaryName, ReactNode> = {
  aircraft: <AircraftDictionaryView />,
  airport: <AirportDictionaryView />,
  city: <CityDictionaryView />,
  contract: <ContractDictionaryView />,
  counterparty: <CounterpartyDictionaryView />,
  country: <CountryDictionaryView />,
  'currency-rate': <CurrencyRateDictionaryView />,
  'flight-type': <FlightTypeDictionaryView />,
  'flight-purpose': <FlightPurposeDictionaryView />,
  'own-price': <OwnPriceDictionaryView />,
  rebate: <RebateDictionaryView />,
  service: <ServiceDictionaryView />,
  'service-name': <ServiceNameDictionaryView />,
  'unit-of-measure': <UnitOfMeasureDictionaryView />,
  'vat-rate': <VatRateDictionaryView />
};
