import React, { useEffect, useMemo } from 'react';
import { TypedOrder, useOrderStore } from 'store/orderStore';
import { useGetOrderServicesByOrder } from 'queries/orderServices';
import { BaseOrder, OrderType as EOrderType } from 'services/api/data-contracts';
import { ServicesTable } from '../../../../../DispatcherOrdersWidget/components/OrderServices/ServicesTable';

interface IProps {
  order?: TypedOrder;
  orderId: number;
  orderType: EOrderType;
}

export const OrderServices = ({ orderType, orderId, order }: IProps) => {
  const { services, setSelectedServices } = useOrderStore(({ services, setSelectedServices }) => ({
    services,
    setSelectedServices
  }));

  useEffect(() => () => setSelectedServices([]), []);

  const listQuery = useGetOrderServicesByOrder(orderId, orderType);

  const dataSource = useMemo(
    () =>
      services.map((orderService) => ({
        ...orderService,
        key: orderService.id,
        order: order as BaseOrder
      })),
    [services, order]
  );

  return <ServicesTable dataSource={dataSource} isLoading={listQuery.isFetching} />;
};
