import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { ownPricesApi } from 'services/api';
import { OwnPriceHydrated, OwnPriceInput } from 'services/api/data-contracts';
import { ENTITY_NAME, QUERY_KEY } from './consts';
import { transformForRequest } from './helpers';
import { MESSAGE_DURATION } from 'consts/common';

const updateItem = async (data: OwnPriceInput, id: number) =>
  await ownPricesApi.updateOwnPrice(id, transformForRequest(data));

export const useUpdateOwnPrice = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: OwnPriceHydrated) => await updateItem(data, data.id),
    onSuccess: async (response) => {
      if (response.data) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEY, response.data.id] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });

        message.success(
          `${ENTITY_NAME} "${response.data.name}" has been successfully updated`,
          MESSAGE_DURATION
        );
      }
    }
  });
};
