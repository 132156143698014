import React from 'react';
import { Form, InputNumber, Typography, Flex } from 'antd';
import { CurrencyInput } from 'components/CurrencyInput/CurrencyInput';
import { CONTROL_SIZE } from 'consts/common';

export const ForecastAndActualFields = () => (
  <>
    <Typography
      style={{
        fontSize: 14,
        fontWeight: 700,
        lineHeight: '22px'
      }}>
      Forecast
    </Typography>

    <Flex gap={8}>
      <div style={{ minWidth: 85 }}>
        <CurrencyInput name="forecastSalesCurrency" />
      </div>

      <Form.Item name="forecastSalesAmount" label="Sale">
        <InputNumber
          type="number"
          size={CONTROL_SIZE}
          style={{ width: '100%' }}
          placeholder="0.00"
        />
      </Form.Item>

      <Form.Item name="forecastCostAmount" label="Cost">
        <InputNumber
          type="number"
          size={CONTROL_SIZE}
          style={{ width: '100%' }}
          placeholder="0.00"
        />
      </Form.Item>

      <Form.Item name="forecastProfit" label="Profit">
        <InputNumber
          type="number"
          size={CONTROL_SIZE}
          style={{ width: '100%' }}
          placeholder="0.00"
          disabled
        />
      </Form.Item>
    </Flex>

    <Typography
      style={{
        fontSize: 14,
        fontWeight: 700,
        lineHeight: '22px'
      }}>
      Actual
    </Typography>

    <Flex gap={8}>
      <div style={{ minWidth: 85 }}>
        <CurrencyInput name="salesCurrency" label="" disabled />
      </div>

      <Form.Item name="salesAmount">
        <InputNumber
          type="number"
          size={CONTROL_SIZE}
          style={{ width: '100%' }}
          disabled
          placeholder="0.00"
        />
      </Form.Item>

      <Form.Item name="costInSalesCurrency">
        <InputNumber
          type="number"
          size={CONTROL_SIZE}
          style={{ width: '100%' }}
          disabled
          placeholder="0.00"
        />
      </Form.Item>

      <Form.Item name="profit">
        <InputNumber
          type="number"
          size={CONTROL_SIZE}
          style={{ width: '100%' }}
          disabled
          placeholder="0.00"
        />
      </Form.Item>
    </Flex>
  </>
);
