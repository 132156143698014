import React, { useMemo } from 'react';
import { FlightsFilter } from 'store/flightsDashboardStore';
import { OrdersFilter, useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { MessagesFilter } from 'store/messageStore';
import { useSettlementDashboardStore } from 'store/settlementDashboardStore';
import { AppliedMessageFilters } from '../../components/MessageFilters/AppliedMessageFilters';
import { getAppliedOrderFilters } from './helpers/getAppliedOrderFilters';
import { getAppliedInvoiceFilters } from './helpers/getAppliedInvoiceFilters';
import { AppliedFiltersContainer } from '../../components/AppliedFiltersContainer';

interface IProps {
  filter: FlightsFilter & OrdersFilter & MessagesFilter;
  setFilter: (filter: FlightsFilter & OrdersFilter & MessagesFilter) => void;
}

export const AppliedSettlementFilters = ({ filter, setFilter }: IProps) => {
  const { ordersFilter, setOrdersFilter } = useOrdersDashboardStore(
    ({ ordersFilter, setOrdersFilter }) => ({
      ordersFilter,
      setOrdersFilter
    })
  );
  const { invoiceFilter, setInvoiceFilter } = useSettlementDashboardStore(
    ({ invoiceFilter, setInvoiceFilter }) => ({
      invoiceFilter,
      setInvoiceFilter
    })
  );

  const appliedOrderFilters = useMemo(
    () => getAppliedOrderFilters(ordersFilter, setFilter, setOrdersFilter),
    [ordersFilter, setFilter, setOrdersFilter]
  );

  const appliedInvoiceFilters = useMemo(
    () => getAppliedInvoiceFilters(invoiceFilter, setFilter, setInvoiceFilter),
    [invoiceFilter, setFilter, setInvoiceFilter]
  );

  return (
    <AppliedFiltersContainer>
      <AppliedMessageFilters filter={filter} setFilter={setFilter} />
      {appliedInvoiceFilters}
      {appliedOrderFilters}
    </AppliedFiltersContainer>
  );
};
