import { useEffect } from 'react';

export const useCloseOnEsc = (onClose: () => void, isClosable: boolean) => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (isClosable && e.key === 'Escape') {
        onClose();
      }
    };

    if (isClosable) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose, isClosable]);
};
