import React from 'react';
import { rgba } from 'polished';
import styled from 'styled-components';
import { AttachmentsPreview } from '../AttachmentsPreview/AttachmentsPreview';
import { AttachmentList, FlagList } from 'services/emailEngineApi/data-contracts';
import { CustomTagsPreview } from '../CustomTagsPreview/CustomTagsPreview';

interface SubjectSpan {
  $unseen: boolean;
}

const SubjectSpan = styled.span<SubjectSpan>`
  color: ${() => rgba('#000', 0.85)};
  font-weight: ${(props) => (props.$unseen ? '600' : 'inherit')};
`;

const MessagePreviewSpan = styled.span`
  color: ${() => rgba('#000', 0.45)};
`;

const FlexDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex: 1;
  overflow: hidden;
`;

const EllipsisDiv = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 2px;
`;

interface IProps {
  envelope: {
    id: string;
    subject?: string;
    messageText?: string;
    messagePreview?: string;
    attachments?: AttachmentList;
    unseen: boolean;
    flags: FlagList;
  };
}

export const SubjectPreviewLine = ({ envelope }: IProps) => {
  const dash = envelope.subject ? ' \u2013 ' : '';

  const withAttachments = envelope.attachments && envelope.attachments.length > 0;
  return (
    <>
      <FlexDiv>
        <EllipsisDiv>
          {!!envelope.subject && (
            <SubjectSpan $unseen={envelope.unseen}>{envelope.subject}</SubjectSpan>
          )}
          {!!envelope.messagePreview && (
            <MessagePreviewSpan
              dangerouslySetInnerHTML={{ __html: dash + envelope.messagePreview }}
            />
          )}
        </EllipsisDiv>
        {withAttachments && (
          <AttachmentsPreview attachments={envelope.attachments as AttachmentList} />
        )}
      </FlexDiv>
      {envelope.flags && envelope.flags.length > 0 && <CustomTagsPreview flags={envelope.flags} />}
    </>
  );
};
