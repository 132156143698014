import {
  InvoiceHydrated,
  PaymentFullyHydrated,
  PaymentPurposeHydrated
} from 'services/api/data-contracts';
import { PaymentPurposeEditModeBase } from 'store/paymentPurposeStore';
import { PaymentPurposeWithContract } from '../../PaymentDrawer/PaymentDetails/PaymentPurposeBlock/columns';

export const areCurrenciesDifferent = (
  paymentPurpose: PaymentPurposeHydrated | PaymentPurposeWithContract,
  editModeBase: PaymentPurposeEditModeBase,
  invoice?: InvoiceHydrated,
  payment?: Partial<PaymentFullyHydrated>
) => {
  switch (editModeBase) {
    case PaymentPurposeEditModeBase.INVOICE:
      const paymentPurposeHydrated = paymentPurpose as PaymentPurposeHydrated;
      return paymentPurposeHydrated.payment.currency !== invoice?.currency;
    case PaymentPurposeEditModeBase.PAYMENT:
      const paymentPurposeWithContract = paymentPurpose as PaymentPurposeWithContract;
      return (
        paymentPurposeWithContract.invoice &&
        paymentPurposeWithContract.invoice?.currency !== payment?.currency
      );
    default:
      return false;
  }
};
