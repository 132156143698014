import React from 'react';
import { Order, OrderStatus } from 'services/api/data-contracts';
import { ColumnsType } from 'antd/es/table';
import { Table, Tooltip } from 'antd';
import { OrderStatusTag } from '../../components/OrderStatusTag';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT } from 'consts/common';
import { getOrderTerm, getUTCDate } from 'helpers';
import styled from 'styled-components';

interface DecoratedText {
  $isCancelled: boolean;
}

const DecoratedText = styled('span')<DecoratedText>`
  text-decoration: ${(props) => (props.$isCancelled ? 'line-through' : 'none')};
  color: ${(props) => (props.$isCancelled ? '#00000073' : 'inherit')};
`;

export const getColumns = (): ColumnsType<Order> => [
  Table.SELECTION_COLUMN,
  {
    title: '',
    dataIndex: 'status',
    key: 'status',
    render: (value) => <OrderStatusTag status={value} />,
    width: 52
  },
  {
    title: '',
    dataIndex: 'type',
    key: 'type',
    width: 36,
    render: (value, record) => (
      <DecoratedText $isCancelled={record.status === OrderStatus.CNLD}>
        {`${value[0]}${record.programId ? 'p' : ''}`}
      </DecoratedText>
    ),
    align: 'center'
  },
  {
    title: 'N',
    dataIndex: 'number',
    key: 'number',
    width: 55,
    render: (value, record) => (
      <DecoratedText $isCancelled={record.status === OrderStatus.CNLD}>{value}</DecoratedText>
    )
  },
  {
    title: 'Aircraft',
    dataIndex: 'aircraft',
    key: 'aircraft',
    render: (_, record) =>
      record.aircraftTailNumber ? (
        <Tooltip title={record.aircraftTailNumber}>
          <DecoratedText $isCancelled={record.status === OrderStatus.CNLD}>
            {record.aircraftTailNumber}
          </DecoratedText>
        </Tooltip>
      ) : (
        '-'
      ),
    ellipsis: true
  },
  {
    title: 'Flight',
    dataIndex: 'flight',
    key: 'flight',
    render: (_, record) => {
      const flights = record.flights || [];

      if ('arrivalFlight' in record && record.arrivalFlight) {
        flights.push(record.arrivalFlight);
      }

      if ('departureFlight' in record && record.departureFlight) {
        flights.push(record.departureFlight);
      }

      if (flights.length === 0) return '-';

      const tooltipContent = (
        <div>
          {flights.map((flight) => (
            <span key={flight.id}>
              {`${flight.flightNumber} | ${flight.departureAirport.code} ${dayjs(
                flight.actualTimeDeparture || flight.estimatedTimeDeparture
              ).format(DATE_TIME_FORMAT)} - ${flight.departureAirport.code} ${dayjs(
                flight.actualTimeArrival || flight.estimatedTimeArrival
              ).format(DATE_TIME_FORMAT)}`}
              <br />
            </span>
          ))}
        </div>
      );

      return (
        <Tooltip title={tooltipContent} placement="topRight">
          <DecoratedText $isCancelled={record.status === OrderStatus.CNLD}>
            {flights.map((f) => f.flightNumber).join('/')}
          </DecoratedText>
        </Tooltip>
      );
    },
    ellipsis: true
  },
  {
    title: 'Customer',
    dataIndex: 'customer',
    key: 'customer',
    render: (_, record) =>
      record.customer.name ? (
        <Tooltip title={record.customer.name}>
          <DecoratedText $isCancelled={record.status === OrderStatus.CNLD}>
            {record.customer.name}
          </DecoratedText>
        </Tooltip>
      ) : (
        '-'
      ),
    ellipsis: true
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (_, record) => {
      const dateRange = getOrderTerm(
        getUTCDate(record.startDatetime),
        getUTCDate(record.endDatetime)
      );

      return (
        <Tooltip title={dateRange}>
          <DecoratedText $isCancelled={record.status === OrderStatus.CNLD}>
            {dateRange}
          </DecoratedText>
        </Tooltip>
      );
    },
    width: 120,
    ellipsis: true
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    render: (_, record) => (
      <Tooltip title={record.location.searchName}>
        <DecoratedText $isCancelled={record.status === OrderStatus.CNLD}>
          {'code' in record.location ? record.location.code : record.location.name}
        </DecoratedText>
      </Tooltip>
    ),
    ellipsis: true
  }
];
