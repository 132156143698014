import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useFlightsDashboardStore } from 'store/flightsDashboardStore';
import { useDrawerStore } from 'store/drawerStore';

export const useFlightDrawerRouting = () => {
  const { setOpenFlightId } = useFlightsDashboardStore(({ setOpenFlightId }) => ({
    setOpenFlightId
  }));
  const { setDrawerOpen } = useDrawerStore(({ setDrawerOpen }) => ({ setDrawerOpen }));

  const { entityType, entityId } = useParams();

  useEffect(() => {
    if (entityType !== 'flight') return;
    if (!entityId) return;

    if (entityType === 'flight' && !isNaN(+entityId)) {
      setOpenFlightId(+entityId);
      setDrawerOpen('flight');
    }
  }, []);
};
