import { useGetAttachment } from 'queries/messages/useGetAttachment';
import { useEffect, useMemo, useState } from 'react';
import { Model4 as Attachment } from 'services/emailEngineApi/data-contracts';
import { getFileExtension } from './extensions';
import { usePrevious } from './usePrevious';
import { AxiosResponse } from 'axios';

export const useFileUrl = (attachment: Attachment) => {
  const [url, setUrl] = useState('');
  const [hasError, setHasError] = useState(false);
  const { refetchFile } = useGetAttachment();
  const prevUrl = usePrevious(url);

  const type = attachment.contentType;
  const extension = useMemo(
    () => getFileExtension(attachment.filename as string),
    [attachment.filename]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = (await refetchFile(attachment.id as string)) as AxiosResponse<
          BlobPart,
          unknown
        >;
        const blob = new Blob([response.data], { type });
        const objectUrl = window.URL.createObjectURL(blob);
        setUrl(objectUrl);
      } catch (error) {
        setHasError(true);
        console.error('Error fetching file: ', error);
      }
    };
    fetchData();

    return () => {
      if (prevUrl) window.URL.revokeObjectURL(prevUrl);
    };
  }, [attachment.id]);

  return {
    url,
    extension,
    hasError
  };
};
