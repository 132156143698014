import React from 'react';
import { Flex, Form, Input, InputNumber, Select } from 'antd';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import { StyledDatePicker } from 'components/common/StyledDatePicker';
import { FormInstance } from 'antd/es/form';
import { ContractPaymentType, PaymentHydrated } from 'services/api/data-contracts';
import { CurrencyInput } from 'components/CurrencyInput/CurrencyInput';
import { getSelectOptions } from 'helpers';
import { CounterpartyInput } from 'components/CounterpartyInput/CounterpartyInput';
import { useWatch } from 'antd/es/form/Form';
import { useSelectAutoFocus } from 'helpers';

interface IProps {
  form: FormInstance<
    Pick<
      PaymentHydrated,
      'type' | 'amount' | 'date' | 'notes' | 'currency' | 'supplierId' | 'payerId'
    >
  >;
}

export const CreditNoteForm = ({ form }: IProps) => {
  const type = useWatch('type', form);
  const isIncoming = type === ContractPaymentType.Incoming;
  const typeRef = useSelectAutoFocus();

  return (
    <Form
      form={form}
      colon={false}
      size={CONTROL_SIZE}
      requiredMark={false}
      layout="vertical"
      autoComplete="off"
      className="styled-form">
      <Flex gap={8}>
        <Form.Item
          name="type"
          label="Type"
          rules={[{ required: true, message: 'Type is required' }]}
          data-testid="type">
          <Select
            options={getSelectOptions(Object.values(ContractPaymentType))}
            size={CONTROL_SIZE}
            style={{ width: '100px' }}
            placeholder="Type"
            ref={typeRef}
          />
        </Form.Item>

        {type && (
          <div style={{ flexGrow: 1 }}>
            <CounterpartyInput
              label={isIncoming ? 'Payer' : 'Supplier'}
              name={isIncoming ? 'payerId' : 'supplierId'}
              required
              onChange={(counterparty) =>
                form.setFieldValue(isIncoming ? 'payerId' : 'supplierId', counterparty?.id)
              }
            />
          </div>
        )}
      </Flex>

      <Flex gap={8}>
        <Form.Item
          label="Received date"
          name="date"
          rules={[{ required: true }]}
          style={{ flexBasis: '40%' }}>
          <StyledDatePicker
            id="date"
            format={DATE_FORMAT}
            placeholder="Select start date"
            onSetDate={(value) => {
              form && form.setFieldValue('date', value);
            }}
          />
        </Form.Item>

        <Form.Item
          label="Amount"
          name="amount"
          style={{ flexBasis: '60%' }}
          rules={[{ required: true }, { min: 0, type: 'number' }]}>
          <InputNumber
            type="number"
            size={CONTROL_SIZE}
            placeholder="0.00"
            style={{ width: '100%' }}
            precision={2}
          />
        </Form.Item>

        <CurrencyInput required />
      </Flex>

      <Flex gap={8} align="center">
        <Form.Item label="Note" name="notes" style={{ flexGrow: 1 }}>
          <Input.TextArea size={CONTROL_SIZE} autoSize={{ minRows: 3, maxRows: 30 }} />
        </Form.Item>
      </Flex>
    </Form>
  );
};
