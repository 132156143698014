import { useQuery } from '@tanstack/react-query';
import { counterpartiesApi } from 'services/api';
import { useCounterpartyStore } from 'store/counterpartyStore';
import { QUERY_KEY } from './consts';
import { ContractType } from 'services/api/data-contracts';

interface IFilter {
  isSelf?: boolean;
  isOperator?: boolean;
  contractType?: ContractType;
  search?: string;
}

export const fetchList = async (filter: IFilter) =>
  await counterpartiesApi.getCounterparties(filter);

export const useGetCounterparties = (filter: IFilter, enabled = true) => {
  const { setList } = useCounterpartyStore(({ setList }) => ({
    setList
  }));

  return useQuery({
    queryKey: [QUERY_KEY, filter],
    queryFn: () => fetchList(filter),
    onSuccess: (response) => {
      setList(response.data.items);
    },
    keepPreviousData: true,
    enabled
  });
};
