import React, { useEffect } from 'react';
import { message } from 'antd';
import { MESSAGE_DURATION } from 'consts/common';

export const useOfflineHandler = () => {
  useEffect(() => {
    function onlineHandler() {
      message?.success("You're back online", MESSAGE_DURATION);
    }

    function offlineHandler() {
      message?.warning(
        <span>
          Something went wrong with your connection. We will proceed <br /> with your request as
          soon as the connection is up.
        </span>,
        MESSAGE_DURATION
      );
    }

    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offlineHandler);

    return () => {
      window.removeEventListener('online', onlineHandler);
      window.removeEventListener('offline', offlineHandler);
    };
  }, []);
};
