import React, { useMemo } from 'react';
import { Select } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { useGetCounterpartiesForDropdown } from 'queries/counterparty';
import { sortBy } from 'lodash';

interface IProps {
  placeholder?: string;
  value?: number;
  onChange?: (counterpartyId?: number) => void;
}

export const BalanceCounterpartyInput = ({ placeholder, value, onChange }: IProps) => {
  const { isLoading, data } = useGetCounterpartiesForDropdown({
    isSelf: false
  });

  const options = useMemo(
    () =>
      sortBy(data?.data.items, (o) => [!o.isActive, o.name]).map(({ name, id, isActive }) => ({
        label: name,
        value: id,
        disabled: !isActive
      })),
    [data]
  );

  return (
    <Select
      style={{ width: '220px' }}
      placeholder={placeholder}
      options={options}
      size={CONTROL_SIZE}
      loading={isLoading}
      showSearch
      optionFilterProp="label"
      onChange={(value) => onChange && onChange(value)}
      value={value}
    />
  );
};
