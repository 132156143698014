import { SERVER_DATE_TIME_FORMAT } from 'consts/common';
import { OwnPriceInput } from 'services/api/data-contracts';
import { Dayjs } from 'dayjs';

export const transformForRequest = (data: OwnPriceInput) => {
  const startDate = (data.startDate as unknown as Dayjs)
    .set('hour', 0)
    .set('minutes', 0)
    .set('seconds', 0)
    .format(SERVER_DATE_TIME_FORMAT);
  const endDate = data.endDate
    ? (data.endDate as unknown as Dayjs)
        .set('hour', 23)
        .set('minutes', 59)
        .set('seconds', 59)
        .format(SERVER_DATE_TIME_FORMAT)
    : undefined;

  const ownPrice: OwnPriceInput = {
    ...data,
    startDate,
    endDate,
    ...(data.isSpecialPrice
      ? {
          specialParameters: data.specialParameters
            ? data.specialParameters
                .filter((item) => item)
                .map((item) => ({
                  alias: item.alias,
                  entityId: +item.entityId,
                  notes: item.notes
                }))
            : []
        }
      : {})
  };

  if (!data.isSpecialPrice) {
    delete ownPrice.specialParameters;
  }

  return ownPrice;
};
