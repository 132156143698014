import React from 'react';
import { TagFilled } from '@ant-design/icons';
import { FlagList } from 'services/emailEngineApi/data-contracts';
import { Flex, Tooltip } from 'antd';
import { useMailboxConfig } from '../../helpers/useMailboxConfig';
import { getColoredTags } from '../../helpers/getColoredTags';

interface IProps {
  flags: FlagList;
}

export const CustomTagsPreview = ({ flags }: IProps) => {
  const { customFlags } = useMailboxConfig();
  const coloredTags = getColoredTags(customFlags);

  const flagsInEmailEngineOrder = flags
    .map((flag) => coloredTags.find((tag) => tag.label === flag))
    .filter(Boolean);

  return (
    <Flex style={{ margin: '0 4px' }}>
      {flagsInEmailEngineOrder.map((tag, index) => (
        <Tooltip title={tag?.label} key={`${tag?.key}-${index}`}>
          <TagFilled style={{ color: tag?.color, width: 12 }} />
        </Tooltip>
      ))}
    </Flex>
  );
};
