import React, { useContext } from 'react';
import { Col, Form, Input, InputNumber, Row, Select } from 'antd';
import { CONTROL_SIZE, DATE_TIME_FORMAT } from 'consts/common';
import { CountriesInput } from 'components/CountriesInput/CountriesInput';
import { getSelectOptions } from 'helpers';
import { permitTypes, validityUnits } from 'consts/order';
import styled from 'styled-components';
import { green, red } from '@ant-design/colors';
import { rgba } from 'polished';
import { capitalize } from 'lodash';
import { Dayjs } from 'dayjs';
import { PermitOrder } from 'services/api/data-contracts';
import { StyledDateRangePicker } from 'components/common/StyledDatePicker';
import { FormInstance } from 'antd/es/form';
import { FormContext } from '../../../OrderDrawer';

const PermitNumberInput = styled(Input)`
  border-color: ${(props) => (props.value ? green[3] : red[3])};
  background-color: ${(props) => (props.value ? rgba(green[1], 0.3) : rgba(red[1], 0.3))};
`;

interface IProps {
  order: PermitOrder;
}

export const PermitDetailsForm = ({ order }: IProps) => {
  const { form: formInstance } = useContext(FormContext);
  const form = formInstance as FormInstance;

  return (
    <>
      <Row>
        <Col span={8}>
          <CountriesInput
            name="locationId"
            label="Country"
            className="mr"
            placeholder="Select country"
            required
            initialFilter={order?.location?.name}
            onChange={(value) => form?.setFieldsValue({ locationId: value })}
          />
        </Col>
        <Col span={16}>
          <Form.Item
            label="Start date and End date"
            name="startEndDate"
            rules={[
              { required: true },
              {
                validator: async (_, value) => {
                  if (value?.some((i: Dayjs | null) => !i)) {
                    return Promise.reject('Start and end date are required');
                  }
                }
              }
            ]}>
            <StyledDateRangePicker
              id="startEndDate"
              format={DATE_TIME_FORMAT}
              showTime
              onSetDate={(value) => {
                form && form.setFieldValue('startEndDate', value);
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Form.Item name="permitType" label="Permit type" className="mr">
            <Select
              options={getSelectOptions(permitTypes.map((type) => capitalize(type)))}
              size={CONTROL_SIZE}
              placeholder="Select type"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="permitNumber" label="Permit №">
            <PermitNumberInput size={CONTROL_SIZE} placeholder="Enter permit document number" />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={8}>
          <Form.Item name="validity" label="Validity" className="mr">
            <InputNumber
              type="number"
              size={CONTROL_SIZE}
              placeholder="12"
              addonAfter={
                <Form.Item name="validityUnit" noStyle>
                  <Select options={getSelectOptions(validityUnits)} />
                </Form.Item>
              }
            />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item label="Validity period" name="validityPeriod">
            <StyledDateRangePicker
              id="validityPeriod"
              format={DATE_TIME_FORMAT}
              showTime
              onSetDate={(value) => {
                form && form.setFieldValue('validityPeriod', value);
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item name="route" label="Route">
        <Input.TextArea size={CONTROL_SIZE} placeholder="Provide the route" />
      </Form.Item>
    </>
  );
};
