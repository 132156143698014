import React, { useEffect, useState } from 'react';
import { getUTCDate, getOrderTerm } from 'helpers';
import styled from 'styled-components';
import { List, Table } from 'antd';
import { OrderService, OrderServicesListResponse } from 'services/api/data-contracts';
import { CONTROL_SIZE, PAGE_SIZE_20 } from 'consts/common';
import { getColumns } from './columns';
import { TableRowSelection } from 'antd/es/table/interface';
import { unionBy } from 'lodash';

const StyledListItem = styled(List.Item)`
  padding: 8px 0 16px !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  gap: 8px;
  border: none !important;

  .not-linkable {
    color: rgba(0, 0, 0, 0.45);
  }
`;

const ItemHeader = styled('div')`
  font-weight: 500;

  span:first-of-type {
    color: rgba(0, 0, 0, 0.45);
    margin-right: 8px;
  }
`;

type OrdersListItem = OrderServicesListResponse['items'][number];

interface IProps {
  data?: OrderServicesListResponse;
  isLoading: boolean;
  onSelectServices: React.Dispatch<React.SetStateAction<OrderService[]>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

export const ServicesList = ({ data, isLoading, onSelectServices, page, setPage }: IProps) => {
  const [total, setTotal] = useState(0);

  const dataSource = data?.items.map((order) => ({ ...order, key: order.number }));
  const columns = getColumns();

  useEffect(() => {
    setTotal(data?.total || 0);
  }, [data?.total]);

  return (
    <List
      dataSource={dataSource}
      loading={isLoading}
      pagination={
        total > PAGE_SIZE_20 && {
          total,
          current: page,
          size: CONTROL_SIZE,
          onChange: (page: number) => setPage(page),
          showSizeChanger: false
        }
      }
      style={{
        overflowY: 'scroll',
        overflowX: 'hidden',
        height: 'calc(100% - 182px)',
        marginTop: '16px'
      }}
      data-testid="services-list"
      renderItem={(order: OrdersListItem) => {
        const props = `${order.location.name} ${getOrderTerm(
          getUTCDate(order.startDatetime),
          getUTCDate(order.endDatetime)
        )}`;

        const dataSource = order.orderServices
          .filter((i) => !!i)
          .map(
            (service) =>
              ({
                ...service,
                key: service.id,
                order: {
                  status: order.status,
                  id: order.id,
                  number: order.number,
                  type: order.type,
                  customer: order.customer,
                  startDatetime: order.startDatetime,
                  endDatetime: order.endDatetime,
                  createdAt: order.createdAt,
                  updatedAt: order.updatedAt
                }
              }) as OrderService & { isAvailableToLink: boolean }
          );

        const rowSelection: TableRowSelection<OrderService & { isAvailableToLink: boolean }> = {
          onChange: (_, selectedRows) => {
            onSelectServices((prevState) => {
              const servicesFromOtherOrders = prevState.filter(
                (service) => service.order.id !== order.id
              );

              return unionBy(servicesFromOtherOrders, selectedRows, 'id');
            });
          },

          getCheckboxProps: (record) => ({
            name: record.id.toString(),
            disabled: !record.isAvailableToLink
          }),
          hideSelectAll: true
        };

        return (
          <StyledListItem>
            <ItemHeader>
              <span>{order.type}</span>
              <span>{props}</span>
            </ItemHeader>

            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={dataSource}
              size={CONTROL_SIZE}
              pagination={false}
              data-testid={`order-${order.number}-services-table`}
              rowClassName={(row) => (row.isAvailableToLink ? '' : 'not-linkable')}
            />
          </StyledListItem>
        );
      }}
    />
  );
};
