import { Button, Dropdown, MenuProps } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { PlusOutlined, SendOutlined } from '@ant-design/icons';
import React from 'react';
import { getNextFlight } from 'helpers';
import { useFlightsDashboardStore } from 'store/flightsDashboardStore';
import { useFlightStore } from 'store/flightStore';
import { Flight, GroundHandlingOrder, OrderStatus, OrderType } from 'services/api/data-contracts';
import { useOrderStore } from 'store/orderStore';
import { useDrawerStore } from 'store/drawerStore';
import { BsCalendarWeek } from 'react-icons/bs';
import { useCreateFlightProgram } from 'queries/flightProgram';
import { useNavigate } from 'react-router-dom';
import { useFlightProgramStore } from 'store/flightProgramStore';

interface IProps {
  isLoading?: boolean;
  orderType?: OrderType;
}

export const CreateBasedOnButton = ({ isLoading, orderType }: IProps) => {
  const { setFlightEditMode } = useFlightsDashboardStore(({ setFlightEditMode }) => ({
    setFlightEditMode
  }));
  const { setDrawerOpen, setDrawerClosed } = useDrawerStore();
  const { setCurrent: setFlight } = useFlightStore(({ setCurrent }) => ({ setCurrent }));
  const { setCurrent, current } = useOrderStore(({ setCurrent, current }) => ({
    setCurrent,
    current
  }));
  const { setOpenProgramId } = useFlightProgramStore(({ setOpenProgramId }) => ({
    setOpenProgramId
  }));

  const navigate = useNavigate();

  const programMutation = useCreateFlightProgram((programId) => {
    setOpenProgramId(programId);
    navigate(`/dashboard/dispatcher/flightProgram/${programId}${window.location.search}`);
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      type: 'group',
      label: 'Create',
      children: [
        {
          key: '1-1',
          label: 'Next flight',
          icon: <SendOutlined />,
          disabled:
            !current ||
            orderType === OrderType.PMT ||
            !('arrivalFlight' in current) ||
            !current.arrivalFlight,
          onClick: () => {
            if (!current || !('arrivalFlight' in current) || !current.arrivalFlight) return;

            setCurrent({ ...current, shouldAddFlight: true });
            setFlight(getNextFlight(current?.arrivalFlight as Flight));
            setFlightEditMode(true);
            setDrawerOpen('flight');
          }
        },
        {
          key: '1-2',
          label: 'Program',
          icon: <BsCalendarWeek />,
          disabled:
            !orderType ||
            ![OrderType.FUEL, OrderType.GH, OrderType.PMT].includes(orderType) ||
            !current ||
            !!(current as GroundHandlingOrder).programId,
          onClick: async () => {
            if (!current || !current.status || !current.number) return;

            await programMutation.mutateAsync({
              status: OrderStatus.PRCG,
              orderNumber: current.number,
              notes: null
            });
            setDrawerClosed('order');
            setDrawerOpen('flightProgram');
          }
        }
      ]
    }
  ];

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <Button
        size={CONTROL_SIZE}
        loading={isLoading || programMutation.isLoading}
        icon={<PlusOutlined />}
      />
    </Dropdown>
  );
};
