import { Form, Input, InputNumber } from 'antd';
import { CONTROL_SIZE, MAX_LATITUDE, MAX_LONGITUDE } from 'consts/common';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { DictionaryName } from '../../models';
import { FormContext } from '../DictionaryDetails';
import { CitiesInput } from 'components/CitiesInput/CitiesInput';
import { useAirportStore } from 'store/airportStore';
import styled from 'styled-components';

const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
`;

export const AirportDictionaryForm = () => {
  const { dictionaryName } = useParams<{ dictionaryName: DictionaryName }>();
  const form = useContext(FormContext);
  const { current } = useAirportStore(({ current }) => ({ current }));

  if (!form) {
    return null;
  }

  return (
    <Form
      name={dictionaryName}
      layout="vertical"
      form={form}
      colon={false}
      requiredMark="optional"
      className="styled-form">
      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input size={CONTROL_SIZE} autoFocus />
      </Form.Item>

      <RowWrapper>
        <Form.Item
          name="iata"
          label="IATA"
          rules={[{ min: 3 }, { max: 3 }]}
          normalize={(value) => value.toUpperCase().replace(/ /g, '')}>
          <Input size={CONTROL_SIZE} />
        </Form.Item>

        <Form.Item
          name="icao"
          label="ICAO"
          rules={[
            { min: 4 },
            { max: 4 },
            ({ getFieldValue }) => ({
              validator: async (_, value) => {
                if (!value && !getFieldValue('localCode')) {
                  return Promise.reject('Please fill ICAO or local code');
                }
              }
            })
          ]}
          normalize={(value) => value.toUpperCase().replace(/ /g, '')}>
          <Input size={CONTROL_SIZE} />
        </Form.Item>
      </RowWrapper>

      <RowWrapper>
        <Form.Item
          name="localCode"
          label="Local code"
          rules={[
            { max: 6 },
            ({ getFieldValue }) => ({
              validator: async (_, value) => {
                if (!value && !getFieldValue('icao')) {
                  return Promise.reject('Please fill ICAO or local code');
                }
              }
            })
          ]}
          normalize={(value) => value.toUpperCase().replace(/ /g, '')}>
          <Input size={CONTROL_SIZE} />
        </Form.Item>

        <CitiesInput name="cityId" label="City" required initialFilter={current?.city?.name} />
      </RowWrapper>

      <RowWrapper>
        <Form.Item
          name="latitude"
          label="Latitude"
          rules={[{ max: MAX_LATITUDE, min: -MAX_LATITUDE, type: 'float' }]}>
          <InputNumber type="number" size={CONTROL_SIZE} style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          name="longitude"
          label="Longitude"
          rules={[{ max: MAX_LONGITUDE, min: -MAX_LONGITUDE, type: 'float' }]}>
          <InputNumber type="number" size={CONTROL_SIZE} style={{ width: '100%' }} />
        </Form.Item>
      </RowWrapper>

      <Form.Item name="workingHours" label="Working hours">
        <Input.TextArea size={CONTROL_SIZE} />
      </Form.Item>
    </Form>
  );
};
