import { useQuery } from '@tanstack/react-query';
import { ordersApi } from 'services/api';
import { PROGRAM_ORDERS_QUERY_KEY } from './consts';
import { DEFAULT_LIMIT } from 'consts/common';
import { useFlightProgramStore } from 'store/flightProgramStore';

const fetchItem = async (programId: number) =>
  await ordersApi.getOrders({
    sortBy: 'startDatetime',
    limit: DEFAULT_LIMIT,
    programId
  });

export const useGetFlightProgramOrders = (programId: number) => {
  const { setOrders } = useFlightProgramStore();

  return useQuery({
    queryKey: [PROGRAM_ORDERS_QUERY_KEY, programId],
    queryFn: async () => await fetchItem(programId),
    onSuccess: (response) => setOrders(response.data.items || [])
  });
};
