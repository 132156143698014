import { useMutation, useQueryClient } from '@tanstack/react-query';
import { flightProgramsApi } from 'services/api';
import { PROGRAM_ORDERS_QUERY_KEY } from './consts';
import { useFlightProgramStore } from 'store/flightProgramStore';
import { WIDGET_QUERY_KEY as DISPATCHER_ORDERS_WIDGET_QUERY_KEY_ } from '../orders/consts';
import { WIDGET_QUERY_KEY as FLIGHTS_WIDGET_QUERY_KEY_ } from '../flight/consts';
import { message } from 'antd';
import { MESSAGE_DURATION } from 'consts/common';
import { ProgramCloneInput } from 'services/api/data-contracts';

const updateMutation = async (data: ProgramCloneInput) =>
  await flightProgramsApi.cloneProgram(data);

export const useCloneFlightProgramOrders = () => {
  const { openProgramId } = useFlightProgramStore();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateMutation,
    onSuccess: async (response) => {
      await queryClient.invalidateQueries({ queryKey: [PROGRAM_ORDERS_QUERY_KEY, openProgramId] });
      await queryClient.invalidateQueries({ queryKey: [DISPATCHER_ORDERS_WIDGET_QUERY_KEY_] });
      await queryClient.invalidateQueries({ queryKey: [FLIGHTS_WIDGET_QUERY_KEY_] });

      const ordersCount = response.data.createdOrderIds.length;

      message.success(
        `${ordersCount} ${ordersCount > 1 ? 'orders have' : 'order has'} been successfully created and linked to the program`,
        MESSAGE_DURATION
      );
    }
  });
};
