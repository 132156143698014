import { Links } from '../../../components/Links/Links';
import { Spin } from 'antd';
import { InvoiceDetails } from './InvoiceDetails';
import React from 'react';
import styled from 'styled-components';
import { useGetLinks } from 'queries/links/useGetLinks';
import { useGetInvoice } from 'queries/invoice';
import { useInvoiceStore } from 'store/invoiceStore';
import { LinkedEntityTypes } from 'services/api/data-contracts';

const SpinWrapper = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinksWrapper = styled('div')`
  box-shadow:
    0 6px 6px 0 #00000014,
    0 3px 6px -4px #0000001f;
  margin: -12px -8px 8px;
  width: calc(100% + 16px);
  padding: 0 8px 4px;
`;

interface IProps {
  isLoading?: boolean;
  setAddServiceDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InvoiceDrawerContent = ({ isLoading, setAddServiceDrawerOpen }: IProps) => {
  const { current, isInvoiceEditMode } = useInvoiceStore(({ current, isInvoiceEditMode }) => ({
    current,
    isInvoiceEditMode
  }));

  const { isFetching } = useGetInvoice(current?.id);
  const linksQuery = useGetLinks({ invoiceIds: current?.id?.toString() }, !!current?.id);

  const hasLinks = (linksQuery.data?.data.messages || []).length > 0;

  return (
    <>
      {!isInvoiceEditMode && hasLinks && (
        <LinksWrapper>
          <Links
            targetEntityType={LinkedEntityTypes.Invoice}
            targetEntityId={current?.id}
            isLoading={linksQuery.isFetching}
            linkedMessages={linksQuery.data?.data.messages}
          />
        </LinksWrapper>
      )}

      {isLoading || isFetching ? (
        <SpinWrapper>
          <Spin />
        </SpinWrapper>
      ) : (
        <InvoiceDetails setAddServiceDrawerOpen={setAddServiceDrawerOpen} />
      )}
    </>
  );
};
