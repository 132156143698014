import { useQuery } from '@tanstack/react-query';
import { currencyRatesApi } from 'services/api';
import { useCurrencyRateStore } from 'store/currencyRateStore';
import { PAGE_SIZE_15 } from 'consts/common';
import { QUERY_KEY } from './consts';
import { settingsStore } from '../../services/settings/SettingsStore';

interface IFilter {
  page?: number;
  pageSize?: number;
  sourceCurrency?: string;
  date?: string;
  includeNearestDates?: boolean;
  targetCurrency?: string;
}

const fetchList = async (filter: IFilter) =>
  await currencyRatesApi.getCurrencyRates({
    page: 1,
    pageSize: PAGE_SIZE_15,
    ...filter
  });

export const useGetCurrencyRate = (filter: IFilter, enabled: boolean) => {
  const { setCurrent } = useCurrencyRateStore(({ setCurrent }) => ({
    setCurrent
  }));

  const { mainCurrency = '' } = settingsStore.getCurrentTenant();

  return useQuery({
    queryKey: [QUERY_KEY, filter.date],
    queryFn: () => fetchList({ ...filter, sourceCurrency: mainCurrency }),
    onSuccess: (response) => {
      setCurrent(response.data.items[0]);
    },
    enabled
  });
};
