import React from 'react';
import { omit } from 'lodash';
import { InvoiceFilter } from 'store/settlementDashboardStore';
import { FilterTag } from '../../../components/FilterTag';
import { AuditOutlined } from '@ant-design/icons';

export const getAppliedInvoiceFilters = (
  invoiceFilter: InvoiceFilter,
  setFilter: (filter: Record<string, unknown>) => void,
  setInvoiceFilter: (filter: InvoiceFilter) => void
) =>
  Object.keys(invoiceFilter)
    .map((filterKey) => {
      const key = filterKey as keyof InvoiceFilter;
      const value = invoiceFilter[key]?.toString();

      const onClose = () => {
        setFilter(omit(invoiceFilter, key));
        setInvoiceFilter(omit(invoiceFilter, key));
      };

      const icon =
        key.toLowerCase().includes('time') || key.toLowerCase().includes('date') ? (
          <AuditOutlined style={{ color: '#8C8C8C' }} />
        ) : undefined;

      return value ? (
        <FilterTag name={key} value={value} onClose={onClose} key={key} icon={icon} />
      ) : null;
    })
    .filter(Boolean);
