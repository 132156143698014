import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { MessageInstance } from 'antd/es/message/interface';

pdfjs.GlobalWorkerOptions.workerSrc =
  'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.4.168/pdf.worker.min.mjs';

interface IProps {
  url: string;
  scale: number;
  rotate: number;
  messageApi: MessageInstance;
}

export const PdfViewer = ({ url, scale, rotate, messageApi }: IProps) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [containerWidth, setContainerWidth] = useState<number>();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    console.log('Document loaded successfully:', numPages);
    setNumPages(numPages);
  };

  const onDocumentLoadError = (error: Error): void => {
    console.log(error);
    messageApi.open({
      key: 'error-pdf-loading',
      type: 'error',
      content: 'Error loading pdf file.'
    });
  };

  const onResize = useCallback<ResizeObserverCallback>((entries) => {
    const [entry] = entries;

    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(onResize);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }
    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, [onResize]);

  return (
    <div
      ref={containerRef}
      style={{ overflow: 'auto', maxHeight: '80vh', maxWidth: '100%', whiteSpace: 'nowrap' }}>
      <Document file={url} onLoadSuccess={onDocumentLoadSuccess} onLoadError={onDocumentLoadError}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            scale={scale}
            rotate={rotate}
            width={containerWidth}
          />
        ))}
      </Document>
    </div>
  );
};
