import { InvoiceItemHydrated, OrderService, VatRate } from 'services/api/data-contracts';
import { isNumber } from 'lodash';
import { round } from 'helpers';
import { FormInstance } from 'antd/es/form';
import { InvoiceItemsFormType } from './InvoiceItemsForm';

type InvoiceItemHelper = (
  invoiceItem: InvoiceItemHydrated,
  changedItem: Partial<InvoiceItemHydrated>
) => InvoiceItemHydrated;

export const setOwnPriceAndAgentFee: InvoiceItemHelper = (invoiceItem, changedItem) => {
  if ('ownPrice_agentFee' in invoiceItem && invoiceItem['ownPrice_agentFee'] === 'isOwnPrice') {
    invoiceItem.agentFee = 0;
    invoiceItem.agentFeeAmount = 0;
    invoiceItem.isOwnPrice = true;
  } else if ('ownPrice_agentFee' in invoiceItem) {
    invoiceItem.agentFee = invoiceItem['ownPrice_agentFee'] as number;
    invoiceItem.isOwnPrice = false;
  }

  if ('isOwnPrice' in changedItem) {
    invoiceItem.agentFee = 0;
    invoiceItem.isOwnPrice = changedItem.isOwnPrice;
  }

  return invoiceItem;
};

export const validatePriceAndAmount = (
  invoiceItem: Partial<InvoiceItemHydrated>,
  changedItem: Partial<
    InvoiceItemHydrated & {
      ownPrice_agentFee: string;
    }
  >,
  itemIdx: number,
  isIssuedInvoice: boolean,
  form: FormInstance<InvoiceItemsFormType>
): InvoiceItemHydrated => {
  if (changedItem.ownPrice_agentFee === 'isOwnPrice' && isIssuedInvoice && invoiceItem.price) {
    form.validateFields([['invoiceItems', itemIdx, 'price']]);
  }

  if (changedItem.ownPrice_agentFee && isIssuedInvoice) {
    form.validateFields([['invoiceItems', itemIdx, 'price']]);
  }

  return invoiceItem as InvoiceItemHydrated;
};

export const calculateAmounts = (
  invoiceItem: InvoiceItemHydrated,
  changedItem: Partial<InvoiceItemHydrated>,
  vatRates: VatRate[]
) => {
  if (invoiceItem.quantity && isNumber(invoiceItem.price) && !('amount' in changedItem)) {
    invoiceItem.amount = round(invoiceItem.quantity * invoiceItem.price);
  }

  if (invoiceItem.amount && invoiceItem.vatRateId) {
    const rate = vatRates.find((rate) => rate.id === invoiceItem.vatRateId);

    if (rate) {
      invoiceItem.vatAmount = round(invoiceItem.amount * (rate.rate / 100));
    }
  }

  if (invoiceItem.amount) {
    invoiceItem.total = invoiceItem.amount + invoiceItem.vatAmount || 0;
  }

  if (invoiceItem.agentFee && invoiceItem.total) {
    invoiceItem.agentFeeAmount = round(invoiceItem.total * (invoiceItem.agentFee / 100));
  }

  return invoiceItem;
};

export const handleQuantityChange = (
  invoiceItem: InvoiceItemHydrated,
  changedItem: Partial<InvoiceItemHydrated>,
  isIssuedInvoice: boolean
) => {
  if ('quantity' in changedItem && changedItem.quantity) {
    const { quantity } = changedItem;

    if (isIssuedInvoice && !invoiceItem.isOwnPrice && isNumber(invoiceItem.amount)) {
      invoiceItem.price = round(invoiceItem.amount / quantity);
    }
  }

  return invoiceItem;
};

export const handleAmountChange: InvoiceItemHelper = (invoiceItem, changedItem) => {
  if ('amount' in changedItem && invoiceItem.quantity && isNumber(changedItem.amount)) {
    invoiceItem.price = round(changedItem.amount / invoiceItem.quantity);
  }

  return invoiceItem;
};

export const handleServiceChange = (
  invoiceItem: InvoiceItemHydrated,
  changedItem: Partial<InvoiceItemHydrated>,
  isIssuedInvoice: boolean,
  invoiceServices: OrderService[]
): InvoiceItemHydrated => {
  if (!('orderServiceId' in changedItem) || isIssuedInvoice) return invoiceItem;

  const service = invoiceServices.find(({ id }) => id === changedItem.orderServiceId);

  if (service) {
    invoiceItem.outNameId = service.service.serviceNameId;
  }

  return invoiceItem;
};
