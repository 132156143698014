import { ColumnsType } from 'antd/es/table';
import { Contract } from 'services/api/data-contracts';
import { dateSorter, stringSorter } from '../utils/tableSorters';
import { DATE_FORMAT } from 'consts/common';
import { getUTCDate } from 'helpers';

export const getContractColumns = (): ColumnsType<Contract> => [
  {
    title: 'Number',
    dataIndex: 'number',
    key: 'number',
    render: (value) => value || '-',
    sorter: (a, b) => stringSorter(a.number || '-', b.number || '-'),
    ellipsis: true
  },
  {
    title: 'Customer',
    dataIndex: 'customer',
    key: 'customer',
    render: (value) => (value ? `${value.name} (${value.fullName})` : '-'),
    sorter: (a, b) => stringSorter(a.customer?.name || '-', b.customer?.name || '-'),
    ellipsis: true
  },
  {
    title: 'Supplier',
    dataIndex: 'supplier',
    key: 'supplier',
    render: (value) => (value ? `${value.name} (${value.fullName})` : '-'),
    sorter: (a, b) => stringSorter(a.supplier?.name || '-', b.supplier?.name || '-'),
    ellipsis: true
  },
  {
    title: 'Start date',
    dataIndex: 'startDate',
    key: 'startDate',
    render: (value) => getUTCDate(value).format(DATE_FORMAT),
    sorter: (a, b) => dateSorter(a.startDate, b.startDate),
    defaultSortOrder: 'descend'
  },
  {
    title: 'End date',
    dataIndex: 'endDate',
    key: 'endDate',
    render: (value) => (value ? getUTCDate(value).format(DATE_FORMAT) : '-'),
    sorter: (a, b) => dateSorter(a.endDate || '', b.endDate || '')
  }
];
