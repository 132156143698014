import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { InvoiceItemHydrated, RebateInput } from 'services/api/data-contracts';
import { CalculatorOutlined, CheckOutlined } from '@ant-design/icons';
import { Button, Descriptions, Flex, Popover, Tooltip } from 'antd';
import { getAmountValue } from 'helpers';
import { CONTROL_SIZE } from 'consts/common';
import { getRebateSign, isAgentFeeRebate, RebateTypeMap } from 'store/rebateStore';
import { styled } from 'styled-components';

const renderOpenCalculationButton = (
  record: InvoiceItemHydrated,
  onOpenDetails: (item: InvoiceItemHydrated) => void
) => {
  const isButtonVisible = !record.isOwnPrice || !!record.ownPriceId;

  return (
    isButtonVisible && (
      <Tooltip title="Open calculation details">
        <Button
          size={CONTROL_SIZE}
          icon={<CalculatorOutlined />}
          shape="circle"
          onClick={() => onOpenDetails(record)}
        />
      </Tooltip>
    )
  );
};

const renderFlexEnd = (value?: number) => <Flex justify="end">{getAmountValue(value)}</Flex>;

const StyledDescriptions = styled(Descriptions)`
  td {
    padding-bottom: 0 !important;
  }
`;

const renderRebateContent = (rebate?: RebateInput, rebateTotal?: number | null) => {
  if (!rebate || !rebateTotal) return <span>-</span>;

  const rebateSign = getRebateSign(rebate.type);
  const descriptions = [
    { key: 'Item', label: 'Item', children: rebate.name },
    { key: 'Type', label: 'Type', children: RebateTypeMap[rebate.type] },
    {
      key: 'Value',
      label: 'Value',
      children: isAgentFeeRebate(rebate.type)
        ? rebateSign + rebate.value + '%'
        : rebate.currency + ' ' + rebateSign + rebate.value
    },
    { key: 'Amount', label: 'Amount', children: getAmountValue(rebateTotal) },
    ...(rebate.notes ? [{ key: 'Note', label: 'Note', children: rebate.notes }] : [])
  ];

  return (
    <Popover
      placement="topLeft"
      content={
        <StyledDescriptions items={descriptions} colon={false} column={1} style={{ width: 250 }} />
      }>
      <Flex
        justify="space-between"
        gap={4}
        style={{ color: '#00000073', minWidth: 0, width: '100%' }}>
        <span style={{ flexGrow: 1 }}>
          {isAgentFeeRebate(rebate.type) ? rebateSign + rebate.value + '%' : rebate.currency}
        </span>
        <span
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          }}>
          {rebateSign + getAmountValue(rebateTotal)}
        </span>
      </Flex>
    </Popover>
  );
};

const renderRebate = (
  record: InvoiceItemHydrated,
  onOpenDetails: (item: InvoiceItemHydrated) => void
) => (
  <Flex align="center" style={{ overflow: 'hidden' }}>
    {renderRebateContent(record.rebate, record.rebateTotal)}
    <div id="actions">{renderOpenCalculationButton(record, onOpenDetails)}</div>
  </Flex>
);

const renderTotal = (
  value: number,
  record: InvoiceItemHydrated,
  onOpenDetails: (item: InvoiceItemHydrated) => void
) => (
  <Flex align="center" justify="end" style={{ overflow: 'hidden' }}>
    <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
      {getAmountValue(value)}
    </span>
    <div id="actions">{renderOpenCalculationButton(record, onOpenDetails)}</div>
  </Flex>
);

export const getInvoiceItemsColumns = (
  onOpenDetails: (item: InvoiceItemHydrated) => void,
  isIssuedInvoice?: boolean
): ColumnsType<InvoiceItemHydrated> => [
  {
    title: 'O',
    dataIndex: 'isOwnPrice',
    key: 'isOwnPrice',
    render: (_, record) =>
      record.isOwnPrice ? (
        <Tooltip title="Own price">
          <CheckOutlined />
        </Tooltip>
      ) : null,
    width: 22
  },
  {
    title: 'Item',
    dataIndex: 'item',
    key: 'item',
    render: (_, record) => record.serviceName?.name,
    ellipsis: true
  },
  {
    title: 'Service',
    dataIndex: 'service',
    key: 'service',
    render: (_, record) => record.orderService?.service.name,
    ellipsis: true,
    width: 80
  },
  {
    title: 'Out name',
    dataIndex: 'outName',
    key: 'outName',
    render: (_, record) => record.outName?.name,
    ellipsis: true
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    render: renderFlexEnd,
    width: 70,
    ellipsis: true
  },
  {
    title: 'UOM',
    dataIndex: 'UOM',
    key: 'UOM',
    render: (_, record) => record.unitOfMeasure.shortName,
    width: 60,
    ellipsis: true
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    render: renderFlexEnd,
    width: 80
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: renderFlexEnd,
    ellipsis: true,
    width: 80
  },
  {
    title: 'VAT, %',
    dataIndex: 'vatRate',
    key: 'vatRate',
    render: (_, record) => renderFlexEnd(record.vatRate?.rate),
    width: 60
  },
  {
    title: 'VAT, Amount',
    dataIndex: 'vatAmount',
    key: 'vatAmount',
    render: renderFlexEnd,
    ellipsis: true,
    width: 70
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'issuedTotal',
    hidden: isIssuedInvoice,
    render: (value, record) => renderTotal(value, record, onOpenDetails),
    width: 80
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'receivedTotal',
    hidden: !isIssuedInvoice,
    render: renderFlexEnd,
    ellipsis: true,
    width: 80
  },
  {
    title: 'Rebate',
    dataIndex: 'rebate',
    key: 'rebate',
    hidden: !isIssuedInvoice,
    render: (_, record) => renderRebate(record, onOpenDetails)
  }
];
