import React, { createContext, useContext, useEffect, useState } from 'react';
import { Drawer, Form } from 'antd';
import styled from 'styled-components';
import { Invoice, InvoiceType } from 'services/api/data-contracts';
import { HeaderButtons } from './common/HeaderButtons';
import { InvoiceWithDayJs, transformForRender, transformForRequest } from './utils';
import { useInvoiceStore } from 'store/invoiceStore';
import { useCreateInvoice, useUpdateInvoice } from 'queries/invoice';
import { usePaymentPurposeStore } from 'store/paymentPurposeStore';
import { useSettlementDashboardStore } from 'store/settlementDashboardStore';
import { DashboardContext, DashboardType } from '../../../../consts';
import { usePaymentStore } from 'store/paymentStore';
import { InvoiceDrawerTitle } from './common/InvoiceDrawerTitle';
import { InvoiceDrawerContent } from './InvoiceDrawerContent';
import { DetailsErrorBoundary } from 'components/ErrorBoundary/DetailsErrorBoundary';
import { useParams } from 'react-router-dom';
import { FormInstance } from 'antd/es/form';
import { useDrawerStore } from 'store/drawerStore';

const StyledDrawer = styled(Drawer)`
  .ant-drawer-header,
  .ant-drawer-body {
    padding: 8px;
  }

  .ant-drawer-header {
    border: none;
    gap: 8px;
  }

  .ant-drawer-title {
    width: 100%;
  }
`;

interface IProps {
  isFullScreenMode?: boolean;
  setAddServiceDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FormContext = createContext<{
  form: FormInstance<InvoiceWithDayJs> | null;
  isTouched: boolean;
  setTouched?: React.Dispatch<boolean>;
}>({ form: null, isTouched: false });

export const InvoiceDrawer = ({ isFullScreenMode, setAddServiceDrawerOpen }: IProps) => {
  const [form] = Form.useForm<InvoiceWithDayJs>();
  const [isTouched, setTouched] = useState(form.isFieldsTouched());

  const isPosted = Form.useWatch('isPosted', form);

  const { entityId } = useParams();

  const { type: dashboardType } = useContext(DashboardContext);

  const { selectedServicesIds: orderServiceIds } = useSettlementDashboardStore(
    ({ selectedServicesIds }) => ({
      selectedServicesIds
    })
  );
  const { current, setCurrent, setInvoiceEditMode } = useInvoiceStore(
    ({ current, setCurrent, setInvoiceEditMode }) => ({
      current,
      setCurrent,
      setInvoiceEditMode
    })
  );
  const { setList: setPaymentPurposes, setCreationMode: setPaymentPurposeCreationMode } =
    usePaymentPurposeStore(({ setList, setCreationMode }) => ({
      setList,
      setCreationMode
    }));
  const { setPaymentCreationMode } = usePaymentStore(({ setPaymentCreationMode }) => ({
    setPaymentCreationMode
  }));
  const { isInvoiceDrawerOpen, setDrawerClosed } = useDrawerStore(
    ({ openDrawers, setDrawerClosed }) => ({
      isInvoiceDrawerOpen: openDrawers.includes('invoice'),
      setDrawerClosed
    })
  );

  const formInitialData = current?.id ? transformForRender(current as Invoice) : current;

  useEffect(() => {
    if (current) {
      form.setFieldsValue(formInitialData || {});
    }

    return () => {
      form.setFieldsValue({});
      form.resetFields();
    };
  }, [current]);

  const updateMutation = useUpdateInvoice(() => {
    setInvoiceEditMode(false);
  }, dashboardType === DashboardType.Settlement);
  const createMutation = useCreateInvoice(() => {
    setInvoiceEditMode(false);
  });

  const handleSubmit = async () => {
    await form.validateFields();
    const data = transformForRequest(form.getFieldsValue() as InvoiceWithDayJs);

    if (current?.id) {
      updateMutation.mutate({
        ...data,
        id: current.id,
        type: current?.type as InvoiceType
      } as Invoice);
    } else {
      createMutation.mutate({ ...data, type: current?.type as InvoiceType, orderServiceIds });
    }
  };

  const handleCloseDrawer = () => {
    setCurrent(undefined);
    setInvoiceEditMode(false);
    form.setFieldsValue({});
    setDrawerClosed('invoice');
    setPaymentPurposes({ items: [], total: 0 });
    setPaymentPurposeCreationMode(false);
    setPaymentCreationMode(undefined);
  };

  const isLoading = createMutation.isLoading || updateMutation.isLoading;

  const isRenderInContainer = dashboardType === DashboardType.Settlement && !isFullScreenMode;

  return (
    <StyledDrawer
      open={isInvoiceDrawerOpen}
      title={<InvoiceDrawerTitle />}
      closable={false}
      width={isRenderInContainer ? '100%' : 976}
      className="invoice-drawer"
      extra={
        <HeaderButtons
          isLoading={isLoading}
          onSubmit={handleSubmit}
          onClose={handleCloseDrawer}
          isWarning={!isPosted}
          isFormTouched={isTouched}
          isFullScreenMode={isFullScreenMode}
        />
      }
      {...(isRenderInContainer ? { getContainer: false } : {})}>
      <DetailsErrorBoundary
        message="Please check the provided URL or select the invoice in widget"
        key={entityId}>
        <FormContext.Provider value={{ form, isTouched, setTouched }}>
          <InvoiceDrawerContent
            isLoading={isLoading}
            setAddServiceDrawerOpen={setAddServiceDrawerOpen}
          />
        </FormContext.Provider>
      </DetailsErrorBoundary>
    </StyledDrawer>
  );
};
