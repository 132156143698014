import React from 'react';
import styled from 'styled-components';
import { Grid, message, Tag } from 'antd';
import { FileOutlined } from '@ant-design/icons';
import {
  FILE_NAME_TAGS_DEFAULT,
  TOTAL_TAGS_DEFAULT,
  TOTAL_TAGS_XL,
  TOTAL_TAGS_XXL
} from './consts';
import { AttachmentList } from 'services/emailEngineApi/data-contracts';
import { AttachmentTag } from './AttachmentTag';

const AttachmentTags = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const StyledTag = styled(Tag)`
  justify-content: flex-end;
  background-color: white;
  font-size: 12px;
  max-width: 78px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px dashed #d9d9d9;
  &:last-child {
    margin-inline-end: 0;
  }
`;

const { useBreakpoint } = Grid;

export const AttachmentsPreview = ({ attachments }: { attachments: AttachmentList }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const screens = useBreakpoint();

  const isXXLDesktop = screens.xxl;
  const isXLDesktop = screens.xl && !screens.xxl;

  const getFilenameTags = (attachments: AttachmentList, max: number) => attachments?.slice(0, max);

  const renderFilenameTags = (maxFilenameTags: number) =>
    getFilenameTags(attachments, maxFilenameTags)?.map((a, index) =>
      a.filename ? (
        <AttachmentTag key={`${a.filename}-${index}`} attachment={a} messageApi={messageApi} />
      ) : null
    );

  const renderFilesQuantityTag = (
    attachments: AttachmentList,
    previewedFilenameTags = FILE_NAME_TAGS_DEFAULT
  ) => (
    <StyledTag>
      <FileOutlined style={{ marginRight: '2px' }} />
      {`+${attachments.length - previewedFilenameTags}...`}
    </StyledTag>
  );

  return (
    <>
      {contextHolder}
      <AttachmentTags>
        {isXXLDesktop ? (
          <>
            {attachments.length > TOTAL_TAGS_XXL ? (
              <>
                {renderFilenameTags(2)}
                {renderFilesQuantityTag(attachments, 2)}
              </>
            ) : (
              renderFilenameTags(TOTAL_TAGS_XXL)
            )}
          </>
        ) : isXLDesktop ? (
          <>
            {attachments.length > TOTAL_TAGS_XL ? (
              <>
                {renderFilenameTags(1)}
                {renderFilesQuantityTag(attachments, 1)}
              </>
            ) : (
              renderFilenameTags(TOTAL_TAGS_XL)
            )}
          </>
        ) : (
          <>
            {attachments.length === TOTAL_TAGS_DEFAULT && renderFilenameTags(TOTAL_TAGS_DEFAULT)}
            {attachments.length > TOTAL_TAGS_DEFAULT && renderFilesQuantityTag(attachments)}
          </>
        )}
      </AttachmentTags>
    </>
  );
};
