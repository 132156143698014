import { useQueries } from '@tanstack/react-query';
import { messagesApi } from 'services/emailEngineApi';
import { useMessageStore } from 'store/messageStore';
import { SIGNATURE_NAME, SIGNATURES } from './consts';

const getTemplate = async (id: string) => await messagesApi.getV1TemplatesTemplateTemplate(id);

export const useGetMessageSignaturesContent = (enabled: boolean) => {
  const { templates } = useMessageStore(({ templates }) => ({
    templates
  }));

  const templatesForSignatures =
    templates?.filter(({ name }) => name && SIGNATURES.includes(name as SIGNATURE_NAME)) || [];

  return useQueries({
    queries: templatesForSignatures
      ? templatesForSignatures.map(({ id }) => ({
          queryKey: ['message_template', id],
          queryFn: async () => getTemplate(id),
          enabled
        }))
      : []
  });
};
