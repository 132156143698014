import { Invoice, InvoiceInput } from 'services/api/data-contracts';
import { getUTCDate, getAmountValue } from 'helpers';
import { Dayjs } from 'dayjs';
import { SERVER_DATE_FORMAT } from 'consts/common';

export type InvoiceWithDayJs = Omit<
  Invoice,
  'invoiceDate' | 'supplyDate' | 'receivingOrSendingDate' | 'dueDate' | 'amountDue' | 'invoiceTotal'
> & {
  invoiceDate: Dayjs;
  supplyDate?: Dayjs;
  receivingOrSendingDate: Dayjs;
  dueDate: Dayjs;
  amountDue?: string | number;
  invoiceTotal?: string | number;
};

export const transformForRender = (invoice: Invoice): InvoiceWithDayJs => ({
  ...invoice,
  invoiceDate: getUTCDate(invoice.invoiceDate),
  supplyDate: invoice.supplyDate ? getUTCDate(invoice.supplyDate) : undefined,
  receivingOrSendingDate: getUTCDate(invoice.receivingOrSendingDate),
  dueDate: getUTCDate(invoice.dueDate),
  amountDue: getAmountValue(invoice.amountDue),
  invoiceTotal: getAmountValue(invoice.invoiceTotal)
});

export const transformForRequest = (invoice: InvoiceWithDayJs): InvoiceInput => ({
  ...invoice,
  invoiceDate: invoice.invoiceDate.format(SERVER_DATE_FORMAT),
  supplyDate: invoice.supplyDate ? invoice.supplyDate.format(SERVER_DATE_FORMAT) : undefined,
  receivingOrSendingDate: invoice.receivingOrSendingDate.format(SERVER_DATE_FORMAT),
  dueDate: invoice.dueDate.format(SERVER_DATE_FORMAT)
});
