import React from 'react';
import { ConfigProvider, Empty, Select } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { useGetServices } from 'queries/service';
import { useServiceStore } from 'store/serviceStore';

interface IProps {
  onChange: (service: { value: number; label: string }[]) => void;
  value?: { value: number; label: string }[] | null;
}

export const ServicesMultipleInput = ({ onChange, value }: IProps) => {
  const { list } = useServiceStore(({ list }) => ({
    list
  }));
  const { isLoading } = useGetServices();

  const options = list.map(({ name, id, isActive }) => ({
    label: name,
    value: id,
    disabled: !isActive
  }));

  return (
    <ConfigProvider
      renderEmpty={() => <Empty description="No data" image={Empty.PRESENTED_IMAGE_SIMPLE} />}>
      <Select
        data-testid="services-input"
        labelInValue
        mode="multiple"
        maxTagCount="responsive"
        allowClear
        style={{ width: '100%' }}
        placeholder="Select order services"
        options={options}
        size={CONTROL_SIZE}
        showSearch
        optionFilterProp="label"
        loading={isLoading}
        popupMatchSelectWidth={false}
        value={value}
        onChange={(services: { value: number; label: string }[]) => {
          onChange(services);
        }}
      />
    </ConfigProvider>
  );
};
