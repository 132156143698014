import { Descriptions, DescriptionsProps } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import React, { useContext } from 'react';
import { DataContext } from '../DictionaryDetails';
import { FlightPurpose } from 'services/api/data-contracts';

export const FlightPurposeDictionaryView = () => {
  const data = useContext(DataContext) as FlightPurpose;

  if (!data) {
    return null;
  }

  const descriptions: DescriptionsProps['items'] = [
    {
      label: 'Active',
      span: 2,
      children: data.isActive ? 'Yes' : 'No'
    },
    {
      label: 'Name',
      span: 2,
      children: data.name
    }
  ];

  return <Descriptions size={CONTROL_SIZE} items={descriptions} column={2} bordered />;
};
