import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { OrderType } from 'services/api/data-contracts';
import { useGetAddOrder, useGetFuelOrder, useGetGHOrder, useGetPermitOrder } from 'queries/orders';

export const useOrderDetails = () => {
  const { openOrder } = useOrdersDashboardStore(({ openOrder }) => ({
    openOrder
  }));

  useGetGHOrder(openOrder?.type === OrderType.GH, openOrder?.id);
  useGetAddOrder(openOrder?.type === OrderType.ADD, openOrder?.id);
  useGetFuelOrder(openOrder?.type === OrderType.FUEL, openOrder?.id);
  useGetPermitOrder(openOrder?.type === OrderType.PMT, openOrder?.id);
};
