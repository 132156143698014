import { InvoiceWithDayJs } from 'pages/widgets/InvoicesWidget/components/InvoiceDetails/utils';
import { getUTCDate } from 'helpers';

export const invoiceInitialData: Partial<InvoiceWithDayJs> = {
  invoiceDate: getUTCDate(),
  receivingOrSendingDate: getUTCDate(),
  amountDue: 0,
  invoiceTotal: 0,
  overdueDays: 0
};
