import React from 'react';
import { Form, Input } from 'antd';
import { CONTROL_SIZE } from 'consts/common';

export const MoreDetailsForm = () => (
  <div data-testid="more-details-form">
    <Form.Item name="crew" label="Crew">
      <Input.TextArea
        size={CONTROL_SIZE}
        placeholder="Input crew data"
        autoSize={{ minRows: 10, maxRows: 20 }}
      />
    </Form.Item>

    <Form.Item name="pax" label="PAX">
      <Input.TextArea
        size={CONTROL_SIZE}
        placeholder="Input passengers data"
        autoSize={{ minRows: 10, maxRows: 20 }}
      />
    </Form.Item>

    <Form.Item name="load" label="Load">
      <Input.TextArea
        size={CONTROL_SIZE}
        placeholder="Input load data"
        autoSize={{ minRows: 10, maxRows: 20 }}
      />
    </Form.Item>
  </div>
);
