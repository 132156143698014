import React from 'react';
import { ConfigProvider, Empty, Form, Select } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { useGetServiceNames } from 'queries/serviceName';
import { useServiceNameStore } from 'store/serviceNameStore';
import { UnitOfMeasure } from 'services/api/data-contracts';

interface IProps {
  name: string | (string | number)[];
  label?: string;
  disabled?: boolean;
  required?: boolean;
  onChange?: (id: number, unitOfMeasure: UnitOfMeasure) => void;
}

export const ServiceNameInput = ({ disabled, label, name, required, onChange }: IProps) => {
  const { list } = useServiceNameStore(({ list }) => ({ list }));

  const { isLoading } = useGetServiceNames();

  const options = list.map(({ name, id, isActive }) => ({
    label: name,
    value: id,
    disabled: !isActive
  }));

  return (
    <ConfigProvider
      renderEmpty={() => <Empty description="No data" image={Empty.PRESENTED_IMAGE_SIMPLE} />}>
      <Form.Item
        name={name}
        label={label}
        rules={[{ required, message: 'Is required' }]}
        style={{ margin: 0 }}>
        <Select
          allowClear
          style={{ width: '100%' }}
          placeholder="Select service name"
          options={options}
          size={CONTROL_SIZE}
          disabled={disabled}
          showSearch
          optionFilterProp="label"
          loading={isLoading}
          popupMatchSelectWidth={false}
          onChange={(id) => {
            const unitOfMeasure = list.find((serviceName) => serviceName.id === id)?.unitOfMeasure;
            onChange && onChange(id, unitOfMeasure as UnitOfMeasure);
          }}
        />
      </Form.Item>
    </ConfigProvider>
  );
};
