import React from 'react';
import { useOrderStore } from 'store/orderStore';
import { GeneralInfoForm } from './GeneralInfoForm';
import { GeneralInfoView } from './GeneralInfoView';
import { GroundHandlingOrder, OrderType } from 'services/api/data-contracts';
import { CollapseProps } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { uniq } from 'lodash';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { StyledCollapse } from 'components/common/StyledCollapse';

interface IProps {
  orderType?: OrderType;
}

export const GeneralInfoBlock = ({ orderType }: IProps) => {
  const { current } = useOrderStore(({ current }) => ({ current: current as GroundHandlingOrder }));
  const { isOrderEditMode } = useOrdersDashboardStore(({ isOrderEditMode }) => ({
    isOrderEditMode
  }));

  const counterparties = uniq([
    current?.customer?.name,
    current?.payer?.name,
    current?.operator?.name
  ]);
  const header = [...counterparties, current?.aircraft?.tailNumber].filter(Boolean).join(', ');

  const collapseContent: CollapseProps['items'] = [
    {
      key: 'general',
      forceRender: true,
      label: isOrderEditMode ? 'General info' : header,
      children: isOrderEditMode ? (
        <GeneralInfoForm orderType={orderType} />
      ) : (
        <GeneralInfoView order={current} />
      )
    }
  ];

  return (
    <StyledCollapse
      items={collapseContent}
      expandIconPosition="end"
      size={CONTROL_SIZE}
      defaultActiveKey={isOrderEditMode ? 'general' : undefined}
      ghost
    />
  );
};
