import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { currencyRatesApi } from 'services/api';
import { ENTITY_NAME, QUERY_KEY } from './consts';
import { CurrencyRateWithId } from 'store/currencyRateStore';
import { SERVER_DATE_FORMAT_CURRENCY_RATE, MESSAGE_DURATION } from 'consts/common';

const createItem = async (data: CurrencyRateWithId) =>
  await currencyRatesApi.addCurrencyRates({
    ...data,
    date: data.date.format(SERVER_DATE_FORMAT_CURRENCY_RATE)
  });

export const useCreateCurrencyRate = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createItem,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });

      message.success(`${ENTITY_NAME} has been successfully created`, MESSAGE_DURATION);

      onSuccess && onSuccess();
    }
  });
};
