import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { InvoicesReportsListResponse } from 'services/api/data-contracts';
import { Tooltip } from 'antd';
import { DATE_FORMAT_SHORT } from 'consts/common';
import { getAmountValue, getUTCDate } from 'helpers';
import { convertBytesToReadableSize } from 'pages/widgets/MessagesWidget/components/NewMessage/helpers/convertBytesToReadableSize';
import { dateSorter } from 'pages/Dictionaries/utils/tableSorters';
import { formatRelativeTime } from 'helpers/dataRendering/formatRelativeTime';

export const isInvoice = (entity: Documents[number]) => entity.hasOwnProperty('invoiceNumber');

export type DocumentItem = InvoiceDocument | CreditNoteDocument;

export interface InvoiceDocument {
  fileId?: string;
  invoiceNumber?: number;
  payer?: {
    name?: string;
  };
  currency?: string;
  total?: number;
  fileSize?: number;
  createdAt?: string;
}

export interface CreditNoteDocument {
  fileId?: string;
  creditNoteNumber?: number;
  payer?: {
    name?: string;
  };
  currency?: string;
  amount?: number;
  fileSize?: number;
  createdAt?: string;
}

export type Documents = InvoicesReportsListResponse['items'];

export const getColumns = (): ColumnsType<Documents[number]> => [
  {
    title: 'Date',
    dataIndex: ['createdAt'],
    key: 'createdAt',
    render: (_, record) => {
      return record.createdAt ? getUTCDate(record.createdAt)?.format(DATE_FORMAT_SHORT) : ' - ';
    }
  },
  {
    title: '№',
    dataIndex: ['number'],
    key: 'number',
    render: (_, record) => {
      const entityNumber = isInvoice(record)
        ? (record as InvoiceDocument).invoiceNumber
        : (record as CreditNoteDocument).creditNoteNumber;

      return entityNumber ? <Tooltip title={entityNumber}>{entityNumber}</Tooltip> : ' - ';
    },
    ellipsis: true,
    width: 100
  },
  {
    title: 'Cur',
    dataIndex: ['currency'],
    width: 50
  },
  {
    title: 'Total',
    dataIndex: ['total'],
    render: (_, record) => {
      return (
        getAmountValue(
          isInvoice(record)
            ? (record as InvoiceDocument).total
            : (record as CreditNoteDocument).amount
        ) || ' - '
      );
    },
    width: 170,
    align: 'end'
  },
  {
    title: 'Size',
    dataIndex: ['fileSize'],
    render: (_, record) => convertBytesToReadableSize(record.fileSize)
  },
  {
    title: 'Generated',
    dataIndex: ['createdAt'],
    sorter: (a, b) => dateSorter(a.createdAt || '', b.createdAt || ''),
    render: (_, record) => {
      return record.createdAt ? formatRelativeTime(record.createdAt) : ' - ';
    },
    ellipsis: true,
    width: 174
  }
];
