export const supportedExtensionsToOpenInNewTab = [
  'jpg',
  'jpeg',
  'gif',
  'bmp',
  'png',
  'pdf',
  'mp3',
  'webm',
  'mp4'
];

export const supportedExtensionsForPreviewer = [
  ...supportedExtensionsToOpenInNewTab,
  'docx',
  'wexbim'
];

export const ROTATE_INCREMENT = 90;

export const ROTATE_ANGLE = {
  DEFAULT: 0,
  QUARTER: 90,
  HALF: 180,
  THREE_QUARTERS: 270,
  FULL: 360
};
