import { settingsStore } from '../settings/SettingsStore';

export enum UserRole {
  Dispatcher = 'Dispatcher',
  Settlement = 'Settlement',
  Admin = 'Admin',
  TenantManager = 'TenantManager'
}

export const tenantManagerEntities = ['snnmgt', 'dvmgt', 'utmgt'];

export const hasCurrentUserRole = (allowedRoles: UserRole[]): boolean => {
  const tenantId = settingsStore.getCurrentTenantId();
  const tenantRoles = settingsStore.getCurrentTenantRoles();

  if (!tenantId || !tenantRoles.length) return false;

  if (allowedRoles.includes(UserRole.TenantManager)) {
    return tenantManagerEntities.includes(tenantId);
  }

  return (
    !tenantManagerEntities.includes(tenantId) &&
    (tenantRoles.includes(UserRole.Admin) ||
      allowedRoles.some((role) => tenantRoles.includes(role)))
  );
};
