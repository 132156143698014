import { Flight, FlightStatus } from 'services/api/data-contracts';
import { RangeValue } from 'store/helpers/types';
import { getUTCDate } from 'helpers';

export const flightStatuses = Object.values(FlightStatus);

export const flightInitialData: Partial<Flight> = {
  version: 0,
  status: FlightStatus.PLND,
  alternativeAirportIds: [],
  overflightCountryIds: [],
  operatorId: undefined,
  flightNumber: undefined,
  aircraftId: undefined,
  departureAirportId: undefined,
  arrivalAirportId: undefined,
  estimatedTimeArrival: undefined,
  estimatedTimeDeparture: undefined,
  route: undefined,
  flightPurposeId: undefined,
  flightTypeId: undefined,
  id: undefined,
  crew: undefined,
  pax: undefined,
  load: undefined
};

export const defaultFlightsFilter = {
  estimatedTimeDeparture: [getUTCDate(), getUTCDate().add(5, 'year')] as RangeValue
};
