import { OrderType } from 'services/api/data-contracts';
import React from 'react';
import { GHOrderDetails } from './GHOrderDetails';
import { ADDOrderDetails } from './ADDOrderDetails';
import { FuelOrderDetails } from './FuelOrderDetails';
import { PermitOrderDetails } from './PermitOrderDetails';
import { TypedOrder } from 'store/orderStore';
import { NewOrderView } from '../../../DispatcherOrdersWidget/components/NewOrderView';

interface IProps {
  order: Partial<TypedOrder>;
  type?: OrderType;
  isLoading: boolean;
}

export const OrderDetails = ({ order, type, isLoading }: IProps) => {
  if (!order.id) {
    return <NewOrderView />;
  }

  switch (type) {
    case OrderType.GH:
      return <GHOrderDetails />;
    case OrderType.ADD:
      return <ADDOrderDetails />;
    case OrderType.FUEL:
      return <FuelOrderDetails />;
    case OrderType.PMT:
      return <PermitOrderDetails isLoading={isLoading} />;
    default:
      return <div style={{ marginTop: '12px' }}>Not implemented</div>;
  }
};
