import { Flight, FlightWidgetItem, OrderStatus } from 'services/api/data-contracts';
import { TypedOrder } from 'store/orderStore';
import { sortBy } from 'lodash';
import { getUTCDate } from '../dataRendering';

export const getOrderFromFlights = (
  flight: Flight,
  selectedFlights: FlightWidgetItem[]
): Partial<
  TypedOrder & {
    sourceArrivalFlight?: Flight | FlightWidgetItem;
    sourceDepartureFlight?: Flight | FlightWidgetItem;
  }
> => {
  // eslint-disable-next-line prefer-const
  let [arrivalFlight, departureFlight] = sortBy(selectedFlights, (flight) =>
    getUTCDate(flight.estimatedTimeArrival)
  );

  if (!departureFlight || arrivalFlight.id === flight.id) {
    arrivalFlight = flight as FlightWidgetItem;
  } else {
    departureFlight = flight as FlightWidgetItem;
  }

  return {
    status: OrderStatus.PRCG,
    operatorId: flight.operatorId,
    aircraftId: flight.aircraftId,
    arrivalFlightId: arrivalFlight?.id,
    departureFlightId: departureFlight?.id,
    flightIds: [arrivalFlight?.id, departureFlight?.id].filter(Boolean),
    sourceArrivalFlight: arrivalFlight,
    sourceDepartureFlight: departureFlight,
    version: 0,
    customerId: undefined,
    payerId: undefined,
    type: undefined,
    isAlternateAirport: false,
    number: undefined
  };
};
