import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { flightsApi, ordersApi } from 'services/api';
import {
  AdditionalServicesOrder,
  FlightInput,
  FuelOrder,
  GroundHandlingOrder,
  OrderType,
  PermitOrder
} from 'services/api/data-contracts';
import { ENTITY_NAME, QUERY_KEY, WIDGET_QUERY_KEY } from './consts';
import { useFlightStore } from 'store/flightStore';
import { TypedOrder, useOrderStore } from 'store/orderStore';
import { orderQueryKeyMap } from '../orders/consts';
import { MESSAGE_DURATION } from 'consts/common';

interface IArgs {
  flight: FlightInput;
  orderToUpdate?: TypedOrder;
}

const createItem = async (data: IArgs) => {
  const response = await flightsApi.addFlight(data.flight);

  if (data.orderToUpdate?.shouldAddFlight && data.orderToUpdate?.type === OrderType.PMT) {
    const order = data.orderToUpdate as PermitOrder;

    await ordersApi.updatePermitOrder(order.id, {
      ...order,
      flightIds: [...(order.flights || []).map((flight) => flight.id), response.data.id]
    });
  } else if (data.orderToUpdate?.shouldAddFlight && data.orderToUpdate?.type === OrderType.GH) {
    const order = data.orderToUpdate as GroundHandlingOrder;

    await ordersApi.updateGhOrder(order.id, {
      ...order,
      departureFlightId: response.data.id
    });
  } else if (data.orderToUpdate?.shouldAddFlight && data.orderToUpdate?.type === OrderType.ADD) {
    const order = data.orderToUpdate as AdditionalServicesOrder;

    await ordersApi.updateAddOrder(order.id, {
      ...order,
      departureFlightId: response.data.id
    });
  } else if (data.orderToUpdate?.shouldAddFlight && data.orderToUpdate?.type === OrderType.FUEL) {
    const order = data.orderToUpdate as FuelOrder;

    await ordersApi.updateFuelOrder(order.id, {
      ...order,
      departureFlightId: response.data.id
    });
  }

  return response;
};

export const useCreateFlight = (onSuccess?: () => void) => {
  const { setCurrent: setFlight } = useFlightStore(({ setCurrent }) => ({ setCurrent }));
  const { setCurrent: setOrder, current: order } = useOrderStore(({ setCurrent, current }) => ({
    setCurrent,
    current
  }));

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createItem,
    onSuccess: async (response) => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      await queryClient.invalidateQueries({ queryKey: [WIDGET_QUERY_KEY] });
      order?.type &&
        (await queryClient.invalidateQueries({ queryKey: [orderQueryKeyMap[order.type]] }));

      setFlight(response.data);
      order && setOrder({ ...order, shouldAddFlight: false });

      message.success(
        `${ENTITY_NAME} #${response.data.number} has been successfully created`,
        MESSAGE_DURATION
      );

      onSuccess && onSuccess();
    }
  });
};
