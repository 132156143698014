import { Flight } from 'services/api/data-contracts';

export type FlightTimeRange = {
  minEstimatedTimeDeparture: string;
  maxEstimatedTimeArrival: string;
};

export const getFlightsMaxTimeRange = (
  flights: Flight[],
  ids: number[]
): FlightTimeRange | undefined => {
  if (!flights || !flights.length || !ids || !ids.length) {
    return;
  }

  const selectedFlights = flights.filter(({ id }) => ids.includes(id));

  const sortedByDeparture = selectedFlights.sort(
    (a, b) =>
      new Date(a.estimatedTimeDeparture).getTime() - new Date(b.estimatedTimeDeparture).getTime()
  );
  const minEstimatedTimeDeparture = sortedByDeparture[0].estimatedTimeDeparture;

  const sortedByArrival = selectedFlights.sort(
    (a, b) =>
      new Date(a.estimatedTimeArrival).getTime() - new Date(b.estimatedTimeArrival).getTime()
  );
  const maxEstimatedTimeArrival = sortedByArrival[sortedByArrival.length - 1].estimatedTimeArrival;

  return {
    minEstimatedTimeDeparture,
    maxEstimatedTimeArrival
  };
};
