import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormInstance } from 'antd/es/form';
import { TypedOrder, useOrderStore } from 'store/orderStore';
import { Drawer, Form } from 'antd';
import { OrdersWidgetMode, useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { OrderDrawerTitle } from './OrderDrawerTitle';
import { useOrderMutations } from './useOrderMutations';
import { OrderDrawerContent } from './OrderDrawerContent';
import { useOrderInitialData } from './useOrderInitialData';
import { DetailsErrorBoundary } from 'components/ErrorBoundary/DetailsErrorBoundary';
import { useNavigate, useParams } from 'react-router-dom';
import { useDrawerStore } from 'store/drawerStore';
import { OrderType } from 'services/api/data-contracts';
import { DashboardContext, DashboardType } from '../../../consts';
import { HeaderButtons } from './OrderDetails/common';
import { useFlightProgramStore } from 'store/flightProgramStore';
import { useDashboardStore } from 'store/dashboardStore';

const StyledDrawer = styled(Drawer)`
  .ant-drawer-header,
  .ant-drawer-body {
    padding: 8px;
  }

  .ant-drawer-header {
    border: none;
  }
`;

export const FormContext = createContext<{
  form: FormInstance | null;
  isTouched: boolean;
  setTouched?: React.Dispatch<boolean>;
}>({ form: null, isTouched: false });

export const OrderDrawer = ({ isFullScreenMode }: { isFullScreenMode?: boolean }) => {
  const [form] = Form.useForm<TypedOrder>();
  const [isTouched, setTouched] = useState(form.isFieldsTouched());

  const { entityId } = useParams();

  const { type: dashboardType } = useContext(DashboardContext);

  const { openOrder, setOrderEditMode, setOpenOrder, setSelectedOrders, widgetMode } =
    useOrdersDashboardStore(
      ({ openOrder, setOrderEditMode, setOpenOrder, setSelectedOrders, widgetMode }) => ({
        openOrder,
        setOrderEditMode,
        setOpenOrder,

        setSelectedOrders,
        widgetMode
      })
    );
  const { current, setCurrent } = useOrderStore(({ current, setCurrent }) => ({
    current,
    setCurrent
  }));
  const { setDrawerClosed, isOrderDrawerOpen, isProgramDrawerOpen } = useDrawerStore(
    ({ setDrawerClosed, openDrawers }) => ({
      setDrawerClosed,
      isOrderDrawerOpen: openDrawers.includes('order'),
      isProgramDrawerOpen: openDrawers.includes('flightProgram')
    })
  );
  const { linkFilters, setLinkFilters } = useDashboardStore(({ linkFilters, setLinkFilters }) => ({
    linkFilters,
    setLinkFilters
  }));
  const { openProgramId, setSelectedPrograms } = useFlightProgramStore();

  const navigate = useNavigate();

  useEffect(() => {
    isOrderDrawerOpen &&
      openOrder?.id &&
      openOrder?.type &&
      navigate(
        `/dashboard/${dashboardType.toString().toLowerCase()}/order/${openOrder.type}-${openOrder.id}`
      );
  }, [isOrderDrawerOpen]);

  useEffect(() => {
    const resetProgramAppliedFilters = () => {
      if (
        isProgramDrawerOpen &&
        isOrderDrawerOpen &&
        dashboardType === DashboardType.Dispatcher &&
        widgetMode !== OrdersWidgetMode.Orders &&
        linkFilters.includes('program-message')
      ) {
        setLinkFilters(linkFilters.filter((f) => f !== 'program-message'));
        setSelectedPrograms([]);
      }
    };

    resetProgramAppliedFilters();
  }, [isProgramDrawerOpen, isOrderDrawerOpen, linkFilters]);

  const handleCancelEdit = (orderType: OrderType, orderId: number) => {
    setOrderEditMode(false);
    form.setFieldsValue(initialData as TypedOrder);

    setOpenOrder({ id: orderId, type: orderType });
    navigate(`/dashboard/dispatcher/order/${orderType}-${orderId}${window.location.search}`);
  };

  const initialData = useOrderInitialData();
  const { onSubmit, isMutationLoading } = useOrderMutations(handleCancelEdit);

  useEffect(() => {
    form.setFieldsValue(initialData);
  }, [initialData]);

  const handleSubmit = async () => {
    await form.validateFields();
    await onSubmit(form.getFieldsValue(), current);
  };

  const resetOrderAppliedFilters = () => {
    if (
      isProgramDrawerOpen &&
      dashboardType === DashboardType.Dispatcher &&
      widgetMode !== OrdersWidgetMode.Orders
    ) {
      setLinkFilters([]);
      setSelectedOrders([]);
    }
  };

  const handleCloseDrawer = useCallback(() => {
    resetOrderAppliedFilters();

    setCurrent(undefined);
    setDrawerClosed('order');
    setOpenOrder(undefined);
    setOrderEditMode(false);

    isProgramDrawerOpen && navigate(`/dashboard/dispatcher/flightProgram/${openProgramId}`);
  }, [
    setCurrent,
    setDrawerClosed,
    setOpenOrder,
    setOrderEditMode,
    isProgramDrawerOpen,
    openProgramId
  ]);

  const isRenderInContainer =
    dashboardType === DashboardType.Dispatcher ||
    (dashboardType === DashboardType.Settlement && !isFullScreenMode);

  return (
    <StyledDrawer
      open={isOrderDrawerOpen}
      title={<OrderDrawerTitle />}
      closable={false}
      width={520}
      className="drawer"
      extra={
        <HeaderButtons
          isLoading={isMutationLoading}
          onSubmit={handleSubmit}
          onClose={handleCloseDrawer}
          orderType={openOrder?.type}
          isFormTouched={isTouched}
        />
      }
      {...(isRenderInContainer ? { getContainer: false } : {})}>
      <DetailsErrorBoundary
        message="Please check the provided URL or select the order in widget"
        key={entityId}>
        <FormContext.Provider value={{ form, isTouched, setTouched }}>
          <OrderDrawerContent isLoading={isMutationLoading} />
        </FormContext.Provider>
      </DetailsErrorBoundary>
    </StyledDrawer>
  );
};
