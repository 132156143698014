import { useState, useEffect } from 'react';
import { useMessageStore } from 'store/messageStore';
import { MessageListEntry } from 'services/emailEngineApi/data-contracts';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

const useBulkSelection = () => {
  const {
    selectedMessages,
    setSelectedMessages,
    list: messages
  } = useMessageStore(({ selectedMessages, setSelectedMessages, list }) => ({
    selectedMessages,
    setSelectedMessages,
    list
  }));
  const [initialSelectedMessage, setInitialSelectedMessage] = useState<MessageListEntry | null>(
    null
  );
  const [isShiftPressed, setIsShiftPressed] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Shift') {
        setIsShiftPressed(true);
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === 'Shift') {
        setIsShiftPressed(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const handleSelectMessage = ({ target: { checked, value } }: CheckboxChangeEvent) => {
    const selectedMessage = messages.find(({ id }) => id === value);

    if (!selectedMessage) return;

    if (isShiftPressed && initialSelectedMessage) {
      const initialIndex = messages.findIndex((msg) => msg.id === initialSelectedMessage.id);
      const currentIndex = messages.findIndex((msg) => msg.id === value);

      const start = Math.min(initialIndex, currentIndex);
      const end = Math.max(initialIndex, currentIndex);

      const newSelectedMessages = messages.slice(start, end + 1);
      setSelectedMessages(newSelectedMessages);
    } else {
      setSelectedMessages(
        checked
          ? [...selectedMessages, selectedMessage]
          : selectedMessages.filter(({ id }) => id !== value)
      );
      setInitialSelectedMessage(selectedMessage);
    }
  };

  return {
    selectedMessages,
    handleSelectMessage
  };
};

export default useBulkSelection;
