import { useQuery } from '@tanstack/react-query';
import { Counterparty } from 'services/api/data-contracts';
import { counterpartiesApi } from 'services/api';
import { useCounterpartyStore } from 'store/counterpartyStore';
import { QUERY_KEY } from './consts';

const fetchItem = async (id: number) => await counterpartiesApi.getCounterpartyById(id);

export const useGetCounterparty = (
  enabled: boolean,
  id?: number,
  onSuccess?: (item?: Counterparty) => void
) => {
  const { setCurrent } = useCounterpartyStore(({ setCurrent }) => ({
    setCurrent
  }));

  return useQuery({
    queryKey: [QUERY_KEY, id],
    queryFn: async () => {
      if (id) {
        return await fetchItem(id);
      }
    },
    onSuccess: (response) => {
      setCurrent(response?.data);

      onSuccess && onSuccess(response?.data);
    },
    enabled
  });
};
