import { Order, OrderType, SettlementOrder } from 'services/api/data-contracts';
import { getOrderTerm, getUTCDate } from 'helpers';

export const getOrderInfo = (order: Order | SettlementOrder) => {
  const type = order.type[0] + (order.programId ? 'p' : '');

  const location = order.location
    ? 'code' in order.location
      ? order.location.code
      : order.location.name
    : undefined;

  const permitFlights = order.flights || [];
  const permitFlightNumber =
    permitFlights[0]?.flightNumber + (permitFlights?.length > 1 ? '...' : '');
  const flightNumber =
    order.type === OrderType.PMT
      ? permitFlightNumber
      : order.arrivalFlight?.flightNumber || order.departureFlight?.flightNumber;

  return [
    type,
    order.aircraftTailNumber,
    flightNumber,
    getOrderTerm(getUTCDate(order.startDatetime), getUTCDate(order.endDatetime)),
    'totalSales' in order ? order.payer?.name : order.customer?.name,
    location
  ].filter((i) => !!i);
};
