import React, { useMemo } from 'react';
import { CONTROL_SIZE } from 'consts/common';
import { Button, message } from 'antd';
import { FLAG } from '../../consts';
import { useMessageStore } from 'store/messageStore';
import { useUpdateMessagesFlagsInBulk } from 'queries/messages/useUpdateMessagesFlagsInBulk';

export const DoneButton = () => {
  const { selectedMessages, setSelectedMessages } = useMessageStore(
    ({ selectedMessages, setSelectedMessages }) => ({
      selectedMessages,
      setSelectedMessages
    })
  );

  const handleCloseProgress = () => {
    setSelectedMessages([]);
    message.destroy('done-in-progress');
  };

  const flagMutation = useUpdateMessagesFlagsInBulk(handleCloseProgress);

  const handleClick = async () => {
    message.loading({
      content: `Moving selected messages to ${areAllSelectedMessagesDone ? 'undone' : 'done'}...`,
      key: 'done-in-progress'
    });

    try {
      await flagMutation.mutateAsync({
        messageIds: selectedMessages.map(({ id }) => id).filter(Boolean) as string[],
        update: {
          flags: {
            [areAllSelectedMessagesDone ? 'delete' : 'add']: [FLAG.DONE]
          }
        }
      });
    } catch (e) {
      handleCloseProgress();
      console.log(e);
    }
  };

  const areAllSelectedMessagesDone = useMemo(
    () => selectedMessages.every((message) => message.flags?.includes(FLAG.DONE)),
    [selectedMessages]
  );
  const areAllSelectedMessagesUndone = useMemo(
    () => selectedMessages.every((message) => !message.flags?.includes(FLAG.DONE)),
    [selectedMessages]
  );

  return (
    <Button
      size={CONTROL_SIZE}
      type="dashed"
      onClick={handleClick}
      disabled={!areAllSelectedMessagesDone && !areAllSelectedMessagesUndone}
      loading={flagMutation.isLoading}>
      Move to {areAllSelectedMessagesDone ? 'Undone' : 'Done'}
    </Button>
  );
};
