import styled from 'styled-components';
import { Button, Drawer, Flex, Form, Input, Select } from 'antd';

const { Item } = Form;
const { TextArea } = Input;

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
    overflow-x: hidden;
  }

  .ant-drawer-header {
    padding: 12px;
    border-bottom: none;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #c1c1c1;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #8e8e8e;
  }

  box-shadow:
    0 6px 6px 0 #00000014,
    0 3px 6px -4px #0000001f;
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

export const StyledInput = styled(Input)`
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0;
  padding-bottom: 8px;
`;

export const StyledFormItem = styled(Item)`
  margin-bottom: 8px;

  .ant-form-item-explain {
    font-size: 10px;
  }
`;

export const StyledEmailBody = styled(Item)`
  margin-bottom: 8px;

  .ant-form-item-explain {
    font-size: 10px;
  }

  .ant-form-item-row,
  .ant-form-item-control-input,
  .ant-form-item-control-input div,
  .ant-input {
    height: 100%;
  }
`;

export const StyledTextArea = styled(TextArea)`
  border: none !important;
  outline: none !important;
  box-shadow: none !important;

  &:focus {
    box-shadow: none !important;
  }
`;

export const StyledHeader = styled(Flex)`
  padding: 8px 12px;
  background-color: white;
  z-index: 10;
  width: 100%;
  box-shadow:
    0 3px 3px 0 #00000014,
    0 3px 6px -4px #0000001f;
`;

export const StyledSelect = styled(Select)`
  .ant-select-selector {
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    padding-bottom: 8px !important;
  }

  .ant-select-selection-item {
    font-size: 12px;
  }
`;

export const StyledButton = styled(Button)`
  border: 0;
  box-shadow: none;
`;
