import React from 'react';
import { StyledList } from '../../DispatcherOrdersWidget';
import { OrderServicesBulkOperationsPopover } from '../../../components/ServicesBulkOperationsPopover/OrderServicesBulkOperationsPopover';
import { OrdersTable } from './OrdersTable';
import { CONTROL_SIZE } from 'consts/common';
import { useGetOrdersWidget } from 'queries/orders';

interface IProps {
  header: React.ReactNode;
  footer: React.ReactNode;
  headerHeight: number;
}

export const OrdersView = ({ header, footer, headerHeight = 0 }: IProps) => {
  const { isFetching } = useGetOrdersWidget();

  return (
    <>
      <OrderServicesBulkOperationsPopover placement="bottomLeft" align={{ offset: [-364, 0] }} />

      <StyledList
        header={header}
        footer={footer}
        bordered
        size={CONTROL_SIZE}
        data-testid="orders-view"
        className="orders-view">
        <OrdersTable isLoading={isFetching} headerHeight={headerHeight} />
      </StyledList>
    </>
  );
};
