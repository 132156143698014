import { OrderType } from 'services/api/data-contracts';
import { ADD_ORDER_QUERY_KEY } from './addOrder/consts';
import { PMT_ORDER_QUERY_KEY } from './permitOrder/consts';
import { FUEL_ORDER_QUERY_KEY } from './fuelOrder/consts';
import { GH_ORDER_QUERY_KEY } from './ghOrder/consts';

export const ENTITY_NAME = 'Order';
export const WIDGET_QUERY_KEY = 'ordersWidget';

export const orderQueryKeyMap = {
  [OrderType.ADD]: ADD_ORDER_QUERY_KEY,
  [OrderType.PMT]: PMT_ORDER_QUERY_KEY,
  [OrderType.FUEL]: FUEL_ORDER_QUERY_KEY,
  [OrderType.GH]: GH_ORDER_QUERY_KEY,
  [OrderType.NAV]: ''
};
