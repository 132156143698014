import React, { useMemo } from 'react';
import { ConfigProvider, Empty, Flex, Table } from 'antd';
import { CONTROL_SIZE, DEFAULT_DOCUMENTS_LIST_LIMIT } from 'consts/common';
import { DocumentItem, getColumns } from './columns';
import { chain } from 'lodash';
import { InvoiceHydratedWithAdditionalData } from 'store/invoiceStore';
import { useGetDocumentsList } from 'queries/documents/useGetDocumentsList';

interface IProps {
  onRowSelect: (record: DocumentItem, isSelected: boolean) => void;
  selectedDocuments: DocumentItem[];
  invoice?: InvoiceHydratedWithAdditionalData;
  invoiceCounterpartyId?: number;
  creditnoteCounterpartyId?: number;
}

export const DrawerContent = ({
  onRowSelect,
  selectedDocuments,
  invoice,
  invoiceCounterpartyId,
  creditnoteCounterpartyId
}: IProps) => {
  const isEnabled = invoice ? !!invoiceCounterpartyId : !!creditnoteCounterpartyId;

  const { data, isFetching } = useGetDocumentsList(
    {
      counterpartyId: invoice
        ? (invoiceCounterpartyId as number)
        : (creditnoteCounterpartyId as number),
      limit: DEFAULT_DOCUMENTS_LIST_LIMIT
    },
    isEnabled
  );
  const columns = useMemo(getColumns, []);

  const rows = useMemo(
    () =>
      chain(data?.data.items)
        .filter((item) => Boolean(item.fileSize))
        .sortBy('createdAt')
        .value(),
    [data?.data.items]
  );

  return (
    <ConfigProvider
      renderEmpty={() => (
        <Empty description="Documents not found" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}>
      <Table
        columns={columns}
        dataSource={rows}
        rowKey="fileId"
        size={CONTROL_SIZE}
        loading={isFetching}
        pagination={false}
        rowSelection={{
          onSelect: onRowSelect,
          hideSelectAll: true,
          selectedRowKeys: selectedDocuments.map(({ fileId }) => fileId as string)
        }}
        summary={(files) => (
          <Table.Summary>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={7}>
                <Flex
                  vertical
                  align="center"
                  justify="center"
                  style={{
                    height: 43,
                    width: '100%',
                    textAlign: 'center',
                    color: '#00000073'
                  }}>
                  {files.length > DEFAULT_DOCUMENTS_LIST_LIMIT && (
                    <div>
                      The last generated {DEFAULT_DOCUMENTS_LIST_LIMIT} files are displayed.
                    </div>
                  )}
                  <div>Files generated in the last 2 days are displayed.</div>
                </Flex>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </ConfigProvider>
  );
};
