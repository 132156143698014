import React from 'react';
import { Descriptions, DescriptionsProps } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { Flight } from 'services/api/data-contracts';

interface IProps {
  flight: Flight;
}

export const MoreDetailsView = ({ flight }: IProps) => {
  const descriptions: DescriptionsProps['items'] = [
    {
      label: 'Crew',
      children: <span style={{ whiteSpace: 'pre-line' }}>{flight.crew || '-'}</span>
    },
    {
      label: 'Pax',
      children: <span style={{ whiteSpace: 'pre-line' }}>{flight.pax || '-'}</span>
    },
    {
      label: 'Load',
      children: <span style={{ whiteSpace: 'pre-line' }}>{flight.load || '-'}</span>
    }
  ];

  return (
    <Descriptions
      items={descriptions}
      size={CONTROL_SIZE}
      column={1}
      bordered
      data-testid="more-details-view"
    />
  );
};
