import { ColumnsType } from 'antd/es/table';
import { InvoiceItemHydrated } from 'services/api/data-contracts';
import { getAmountValue, getUTCDate } from 'helpers';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import { Button, Flex, Tooltip } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import React from 'react';

export const getRolledUpInvoiceItemsColumns = (): ColumnsType<InvoiceItemHydrated> => [
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (value) => getUTCDate(value).format(DATE_FORMAT)
  },
  {
    title: 'Item',
    dataIndex: 'item',
    key: 'item',
    render: (_, record) => record.serviceName?.name,
    ellipsis: true
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    render: (value) => getAmountValue(value, 2),
    width: 94,
    ellipsis: true
  },
  {
    title: 'UOM',
    dataIndex: 'UOM',
    key: 'UOM',
    render: (_, record) => record.unitOfMeasure?.shortName,
    width: 66,
    ellipsis: true
  },
  {
    title: 'Cur',
    dataIndex: 'currency',
    key: 'currency',
    width: 45,
    ellipsis: true
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    align: 'end',
    render: (_, record) => {
      const isButtonVisible = !!record.invoiceId;

      return (
        <Flex align="center" justify="end" style={{ overflow: 'hidden' }}>
          <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
            {getAmountValue(record.total)}
          </span>
          <div id="actions">
            {isButtonVisible && (
              <Tooltip title="Open linked item in a new tab">
                <Button
                  size={CONTROL_SIZE}
                  icon={<ExportOutlined />}
                  shape="circle"
                  type="primary"
                  onClick={() => {
                    window.open(
                      `${window.location.origin}/dashboard/settlement/invoice/${record.invoiceId}`,
                      '_blank'
                    );
                  }}
                />
              </Tooltip>
            )}
          </div>
        </Flex>
      );
    },
    width: 120
  }
];
