import { getUTCDate } from 'helpers';
import {
  ContractPaymentType,
  PaymentHydrated,
  PaymentStatus,
  TransactionType
} from 'services/api/data-contracts';

export const transformForRequest = (
  formData: Pick<
    PaymentHydrated,
    'type' | 'amount' | 'date' | 'notes' | 'currency' | 'supplierId' | 'payerId'
  >
) => {
  const today = getUTCDate().format();

  return {
    type: formData.type,
    amount: formData.amount,
    receivedDate: getUTCDate(formData.date).format(),
    notes: formData.notes,
    currency: formData.currency,
    status: PaymentStatus.DONE,
    plannedDate: today,
    sentDate: today,
    transactionType: TransactionType.CreditNote,
    posted: true,
    supplierId: formData.type === ContractPaymentType.Outgoing ? formData.supplierId : undefined,
    payerId: formData.type === ContractPaymentType.Incoming ? formData.payerId : undefined
  };
};
