import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Col, Form, Input, Row } from 'antd';
import { Airport, Flight, OrderType } from 'services/api/data-contracts';
import { CONTROL_SIZE, DATE_TIME_FORMAT } from 'consts/common';
import { ClockCircleOutlined } from '@ant-design/icons';
import { TypedCommonOrder } from 'store/orderStore';
import { Dayjs } from 'dayjs';
import { FlightInput } from '../../common';
import { LocationInput } from 'components/LocationInput/LocationInput';
import { AirportInput } from 'components/AirportsInput/AirportInput';
import { StyledDateRangePicker } from 'components/common/StyledDatePicker';
import { BooleanRadioGroup } from 'components/common/BooleanRadioGroup';
import { useWatch } from 'antd/es/form/Form';
import { FormInstance } from 'antd/es/form';
import { getUTCDate } from 'helpers';
import { FormContext } from '../../../OrderDrawer';

interface IProps {
  orderType: OrderType;
  order: TypedCommonOrder;
}

export const OrderDetailsForm = ({ order, orderType }: IProps) => {
  const { form: formInstance } = useContext(FormContext);
  const form = formInstance as FormInstance;

  const [arrivalFlightId, setArrivalFlightId] = useState<number | undefined>(undefined);
  const [departureFlightId, setDepartureFlightId] = useState<number | undefined>(undefined);

  const locationTypeAndId = useWatch('locationTypeAndId', form);
  const location = useWatch('location', form);
  const locationId = useWatch('locationId', form);
  const operatorId = useWatch('operatorId', form);
  const aircraftId = useWatch('aircraftId', form);

  useEffect(() => {
    if (form) {
      setArrivalFlightId(form.getFieldValue('arrivalFlightId'));
      setDepartureFlightId(form.getFieldValue('departureFlightId'));
    }
  }, [form]);

  const isAdditionalServices = orderType === OrderType.ADD;

  const filterByLocation = (airport?: Airport) => {
    if (locationId) return airport?.id == locationId;

    const [type, id] = locationTypeAndId?.split('#');

    if (type === 'country') {
      return airport?.city?.country?.id === +id;
    } else if (type === 'city') {
      return airport?.city?.id === +id;
    } else if (type === 'airport') {
      return airport?.id === +id;
    }
  };

  const getAvailableArrivalFlights = useCallback(
    (flights: Flight[]) => {
      if (!locationId && !locationTypeAndId) return flights;

      const filteredByLocation = flights.filter(({ arrivalAirport }) =>
        filterByLocation(arrivalAirport)
      );

      const departureFlight = flights.find(({ id }) => id === departureFlightId);

      if (!departureFlight) return filteredByLocation;

      return filteredByLocation.filter(
        ({ estimatedTimeArrival, actualTimeArrival }) =>
          getUTCDate(
            departureFlight.actualTimeDeparture || departureFlight.estimatedTimeDeparture
          ).diff(actualTimeArrival || estimatedTimeArrival) > 0
      );
    },
    [departureFlightId, locationId, locationTypeAndId]
  );

  const getAvailableDepartureFlights = useCallback(
    (flights: Flight[]) => {
      if (!locationId && !locationTypeAndId) return flights;

      const filteredByLocation = flights.filter(({ departureAirport }) =>
        filterByLocation(departureAirport)
      );

      const arrivalFlight = flights.find(({ id }) => id === arrivalFlightId);

      if (!arrivalFlight) return filteredByLocation;

      return filteredByLocation.filter(
        ({ estimatedTimeDeparture, actualTimeDeparture }) =>
          getUTCDate(arrivalFlight.actualTimeArrival || arrivalFlight.estimatedTimeArrival).diff(
            actualTimeDeparture || estimatedTimeDeparture
          ) < 0
      );
    },
    [arrivalFlightId, locationId, locationTypeAndId]
  );

  return (
    <>
      {isAdditionalServices ? (
        <LocationInput
          name="locationTypeAndId"
          label="Location"
          initialFilter={
            order?.location
              ? 'code' in order.location
                ? order.location.code
                : order.location?.name
              : ''
          }
          required
          onChange={(value) => form?.setFieldValue('locationTypeAndId', value)}
        />
      ) : (
        <Row>
          <Col span={16}>
            <Form.Item name="location" hidden />
            <AirportInput
              name="locationId"
              label="Airport"
              required
              className="mr"
              placeholder="Select airport"
              initialFilter={location?.name}
              onChange={(value) => form?.setFieldValue('locationId', value)}
            />
          </Col>
          <Col span={8}>
            <Form.Item
              name="isAlternateAirport"
              label="Alternative airport"
              rules={[{ required: true }]}>
              <BooleanRadioGroup
                testId="isAlternateAirport"
                onChange={(value) => form?.setFieldValue('isAlternateAirport', value)}
                value={form?.getFieldValue('isAlternateAirport')}
              />
            </Form.Item>
          </Col>
        </Row>
      )}

      <FlightInput
        name="arrivalFlightId"
        label="Arrival flight"
        onFilter={getAvailableArrivalFlights}
        initialFlightId={order?.arrivalFlightId}
        isError={
          !isAdditionalServices &&
          !(form?.getFieldValue('arrivalFlightId') || form?.getFieldValue('departureFlightId'))
        }
        errorMessage="At least one flight should be assigned"
        multiple={false}
        onSetFlight={setArrivalFlightId}
        onChange={(id) => form?.setFieldValue('arrivalFlightId', id)}
      />

      <FlightInput
        name="departureFlightId"
        label="Departure flight"
        onFilter={getAvailableDepartureFlights}
        initialFlightId={order?.departureFlightId}
        isError={
          !isAdditionalServices &&
          !(form?.getFieldValue('arrivalFlightId') || form?.getFieldValue('departureFlightId'))
        }
        errorMessage="At least one flight should be assigned"
        multiple={false}
        onSetFlight={setDepartureFlightId}
        onChange={(id) => form?.setFieldValue('departureFlightId', id)}
        apiFilter={{ operatorId, aircraftId }}
      />

      <Row>
        <Col span={16}>
          <Form.Item
            label="Start date and End date"
            name="startEndDate"
            rules={[
              { required: true },
              {
                validator: async (_, value) => {
                  if (value?.some((i: Dayjs | null) => !i)) {
                    return Promise.reject('Start and end date are required');
                  }
                }
              }
            ]}
            className="mr">
            <StyledDateRangePicker
              id="startEndDate"
              format={DATE_TIME_FORMAT}
              showTime
              onSetDate={(value) => {
                form && form.setFieldValue('startEndDate', value);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="estimatedParkingTime" label="EPT">
            <Input size={CONTROL_SIZE} disabled suffix={<ClockCircleOutlined />} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
