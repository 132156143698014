import { useQuery } from '@tanstack/react-query';
import { invoicesApi } from 'services/api';
import { QUERY_KEY } from './consts';

export interface IFilter {
  counterpartyId: number;
  limit?: number;
}

const fetchList = async (filter: IFilter) =>
  await invoicesApi.invoiceReportsList({
    ...filter
  });

export const useGetDocumentsList = (filter: IFilter, enabled: boolean) =>
  useQuery({
    queryKey: [QUERY_KEY, filter],
    queryFn: () => fetchList(filter),
    enabled
  });
